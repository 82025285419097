<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="(filter_data.fk_student = null), getStudents()"
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_student"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-school"
            :items="students"
            :label="$t('school.reports.select_student')"
            hide-details="auto"
            return-object
            :rules="[$required]"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>

        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  v-for="(header, index) in selectedHeaders"
                  :class="`border px-3 ${index == 1 ? '' : 'text-center'}`"
                  :key="index"
                  :dir="header?.key == 'email' ? 'ltr' : ''"
                >
                  <template v-if="index === 0">
                    {{ key + 1 }}
                  </template>
                  <template v-else>
                    {{ item[header.key] ? item[header.key] : "-" }}
                  </template>
                </td>
              </tr>
              <!-- <tr v-for="(item, index) in items" :key="item.id">
                <td class="text-center border">{{ index + 1 }}</td>
                <td
                  v-for="header in selectedHeaders?.filter(
                    (head) => head.key != 'no'
                  )"
                  :key="header.key"
                  class="text-center border"
                >
                  {{ item[header.key] }}
                </td>
              </tr> -->
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('name')"
                >
                  {{ item.name }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      //   this.$store.dispatch(
      //     "updatePermission",
      //     localStorage.getItem("userinfo")
      //   );
      //   await this.axios
      //     .get(`${this.base_url}school-data/`, {
      //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //     })
      //     .then((response) => {
      //       this.school = response.data;
      //     });
      if (this.getUserRole > 1) {
        await this.getStudents();
      }
      await this.getBrenches({});
      //   await this.getYears();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
    // this.getSubjects()
  },

  data() {
    return {
      students: [],
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   school: [],
      //   alert_message: undefined,
      //   v$: useValidate(),
      exist: false,
      //   student_name: "",
      items: [],
      loading: false,
      //   txt_search: "",
      selectedHead: [
        "no",
        "name",
        "address",
        "email",
        "gov",
        "home_number",
        "job",
        "phone_number",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.parent_name"), key: "name" },
        { title: this.$t("school.reports.phone_number"), key: "phone_number" },
        { title: this.$t("school.reports.home_number"), key: "home_number" },
        { title: this.$t("school.reports.address"), key: "address" },
        { title: this.$t("school.reports.email"), key: "email" },
        { title: this.$t("school.reports.job"), key: "job" },
        { title: this.$t("school.reports.governorate"), key: "gov" },
      ],
      perPage: 25,
      //   length: 0,
      // totalItems: 0,
      //   itemsPerPage: [
      //     {
      //       value: 5,
      //       text: "5",
      //     },
      //     {
      //       value: 10,
      //       text: "10",
      //     },
      //     {
      //       value: 25,
      //       text: "25",
      //     },
      //     {
      //       value: 50,
      //       text: "50",
      //     },
      //     {
      //       value: 75,
      //       text: "75",
      //     },
      //     {
      //       value: 100,
      //       text: "100",
      //     },
      //     {
      //       value: "",
      //       text: "all",
      //     },
      //   ],
      page: 1,
      //   pagination: {},
      filter_data: {
        fk_student: null,
      },
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
    }),
    async getStudents() {
      await this.axios
        .get(`${this.base_url}student-list/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_branch: this.filter_data?.fk_branch?.id,
          },
        })
        .then((response) => {
          this.students = response.data;
        });
    },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getStudentName() {
    //   var temp_id = this.filter_data.fk_student;
    //   var dataStudent = this.students.find(function (obj) {
    //     return obj.student.id === temp_id;
    //   });
    //   return dataStudent.student.name_ar;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/school/report/parent/student`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_student: this.filter_data.fk_student?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = [response.data];
            // this.totalItems = response.data.pagination?.count;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  //   validations() {
  //     return {
  //       filter_data: {
  //         fk_student: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //       },
  //     };
  //   },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
