<template>
  <VCard class="justify-center">
    <VCardText class="my-6 px-5 pt-0">
      <VForm
        v-if="CheckGetPermission('school.add_classstudentyear')"
        ref="form"
      >
        <v-row>
          <v-col cols="8">
            <fieldset class="px-4 border" style="border-radius: 5px">
              <legend class="v-label px-2">
                {{ $t("school.elevation.student_elevate") }}
              </legend>
              <VRow class="my-4">
                <!-- <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                    <VAutocomplete
                        :items="branches"
                        v-model="elevate_data.fk_branch"
                        item-title="name_ar"
                        item-value="id"
                        prepend-inner-icon="mdi-calendar-range"
                        :label="$t('school.branch_name_ar')"
                        persistent-hint
                        density="compact"
                        :error-messages="v$.elevate_data.fk_branch.$errors.map(e=>e.$message)" 
                        @blur="v$.elevate_data.fk_branch.$touch"
                        @update:modelValue="getStudentList"
                    >
                    </VAutocomplete>
                </VCardItem>
            </VCol> -->
                <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                  <VCardItem class="pa-0">
                    <VAutocomplete
                      ref="current_grade"
                      :items="classes"
                      v-model="elevate_data.fk_class"
                      prepend-inner-icon="mdi-google-classroom"
                      item-title="name_ar"
                      item-value="id"
                      :label="$t('school.elevation.current_grade')"
                      persistent-hint
                      density="compact"
                      clearable
                      hide-details="auto"
                      @update:model-value="show = false"
                      :rules="[$required]"
                    >
                      <template v-slot:prepend>
                        <div class="pt-1">من</div>
                      </template>
                    </VAutocomplete>
                  </VCardItem>
                </VCol>
                <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                  <VCardItem class="pa-0">
                    <VAutocomplete
                      ref="next_grade"
                      :items="classes"
                      v-model="elevate_data.new_class"
                      prepend-inner-icon="mdi-format-list-bulleted"
                      item-title="name_ar"
                      item-value="id"
                      :label="$t('school.elevation.next_grade')"
                      persistent-hint
                      density="compact"
                      clearable
                      hide-details="auto"
                      :rules="[$required]"
                      @update:modelValue="fk_division = undefined"
                      ><template v-slot:prepend>
                        <div class="pt-1">إلى</div>
                      </template></VAutocomplete
                    >
                  </VCardItem>
                </VCol>
                <!-- <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                    <VAutocomplete
                        :items="divisions"
                        v-model="elevate_data.fk_division"
                        prepend-inner-icon="mdi-text"
                        item-title="name"
                        item-value="id"
                        :label="$t('division.name')"
                        persistent-hint
                        density="compact"
                        clearable
                        @update:modelValue="getStudentsList(),getStudents()"
                    ></VAutocomplete>
                </VCardItem>
            </VCol> -->
              </VRow>
            </fieldset>
          </v-col>
          <VCol cols="2" class="my-auto pb-4 pt-3">
            <!-- <div class="d-flex justify-end" style="flex: 1"> -->
            <VBtn
              class="bg-indigo mt-2"
              @click="getStudents()"
              size="small"
              :loading="loading"
            >
              <span class="text-white">{{ $t("global.show") }}</span>
              <!-- <VIcon icon="mdi-plus-thick" color="white" end></VIcon> -->
            </VBtn>
            <!-- </div> -->
          </VCol>
        </v-row>
        <!-- <VCard-title>
          <h3 class="d-inline text-grey" style="padding: 0 4px">
            {{ $t("school.elevation.next_grade") }}
          </h3>
        </VCard-title> -->
        <VRow>
          <!-- <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="branches"
                v-model="elevate_data.new_branch"
                item-title="name_ar"
                item-value="id"
                prepend-inner-icon="mdi-calendar-range"
                :label="$t('school.branch_name_ar')"
                persistent-hint
                density="compact"
              >
              </VAutocomplete>
            </VCardItem>
          </VCol> -->

          <!-- <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                    <VAutocomplete
                        :items="divisions"
                        v-model="elevate_data.new_division"
                        prepend-inner-icon="mdi-text"
                        item-title="name"
                        item-value="id"
                        :label="$t('division.name')"
                        persistent-hint
                        density="compact"
                        clearable
                    ></VAutocomplete>
                </VCardItem>
            </VCol> -->
        </VRow>
      </VForm>
    </VCardText>

    <v-expand-transition>
      <div v-show="show">
        <!-- ##########    data Table  ########### -->
        <v-divider class="mt-4" />
        <!-- ######## Header Of Data Table  ####### -->
        <v-card>
          <VCardActions
            v-if="CheckGetPermission('school.add_classstudentyear')"
            class="px-8 justify-end py-0"
          >
            <VCardItem class="pa-0 align-self-end">
              <VBtn
                v-if="CheckGetPermission('school.add_classstudentyear')"
                class="bg-indigo"
                @click="elevateStudents"
                :disabled="!student_list.some((item) => item.elevated == true)"
                size="small"
                :loading="is_loading"
              >
                <span class="text-white">
                  {{ $t("school.elevation.elevate") }}
                </span>
                <VIcon icon="mdi-book-arrow-up" color="white" end></VIcon>
              </VBtn>
            </VCardItem>
          </VCardActions>
          <!-- <div ></div> -->
          <!-- ######## End Header Of Data Table Server ####### -->
          <VCardText class="px-2 py-0">
            <v-form ref="form">
              <v-table dense density="compact">
                <thead>
                  <tr>
                    <th>
                      <v-checkbox
                        color="indigo"
                        v-model="select_all_students"
                        density="compact"
                        hide-details="auto"
                        :indeterminate="select_all_students === null"
                        @change="
                          select_all_students
                            ? selectAllStudents(true)
                            : selectAllStudents(false)
                        "
                      >
                      </v-checkbox>
                    </th>
                    <th>{{ $t("school.student.name") }}</th>
                    <th>{{ $t("school.elevation.status") }}</th>
                    <th>{{ $t("school.elevation.total") }}</th>
                    <th>{{ $t("school.elevation.estimate") }}</th>
                    <th>{{ $t("school.elevation.registeration_mode") }}</th>
                    <th>{{ $t("school.elevation.status") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student, index) in student_list" :key="index">
                    <td>
                      <v-checkbox
                        v-model="student.elevated"
                        color="indigo"
                        density="compact"
                        hide-details="auto"
                        @change="isAllCheck()"
                      >
                      </v-checkbox>
                    </td>
                    <td>{{ student.student }}</td>
                    <td>{{ student.status }}</td>
                    <td>{{ student.total }}</td>
                    <td>{{ student.estimate }}</td>
                    <td>{{ student.registeration_mode }}</td>
                    <td>{{ student.study_mode }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-form>
          </VCardText>
        </v-card>
      </div>
    </v-expand-transition>
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      student_list: [],
      student_select: [],
      elevate_data: {
        fk_class: undefined,
        new_class: undefined,
        student_list: [],
        // fk_branch:undefined,
        // new_branch:undefined,
      },
      select_all_students: false,
      headers: [
        { title: this.$t("global.check"), key: "checkbox" },
        { title: this.$t("school.student_name"), key: "student" },
        { title: this.$t("school.elevation.status"), key: "status" },
        { title: this.$t("school.elevation.total"), key: "total" },
        { title: this.$t("school.elevation.estimate"), key: "estimate" },
        {
          title: this.$t("school.elevation.registeration_mode"),
          key: "registeration_mode",
        },
        { title: this.$t("school.elevation.study_mode"), key: "study_mode" },
      ],
      selectedHead: [
        "checkbox",
        "student",
        "status",
        "total",
        "estimate",
        "registeration_mode",
        "study_mode",
      ],
      txt_search: undefined,
      show: false,
      loading: false,
      is_loading: false,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.classes.length) await this.getClasses();
      // await this.getBrenches()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
    // if(this.divisions.length==0)
    //     this.getDivisions()
    // if(this.class_assign_hall.length==0)
    //     this.getClassAssignHall()
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      // branches: (state) => state.school.branch,
      // divisions: (state) => state.school.divisions,
      // class_assign_hall:(state)=>state.school.class_assign_hall,
      // user: (state) => state.User,
    }),
    ...mapGetters({
      getDivisionsByClass: "school/getClassDivisions",
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    // table_list(){
    //     let list=[]
    //     this.student_list.forEach(element=>{
    //         element.checkbox=false
    //         list.push(element)
    //     })
    //     return list
    // },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      // getDivisions: "school/getDivisions",
      // getClassAssignHall: "school/getClassAssignHall",
      getClasses: "school/getClasses",
      // getBrenches: "school/getBrenches",
    }),
    selectAllStudents(value) {
      this.student_list.forEach((student) => {
        student.elevated = value;
      });
    },
    isAllCheck() {
      this.select_all_students = this.student_list.every(
        (item) => item.elevated === true
      );
      const some_registered = this.student_list.some(
        (item) => item.elevated === true
      );

      this.select_all_students = this.select_all_students
        ? true
        : some_registered
        ? null
        : false;
    },
    async elevateStudents() {
      const valid = await this.$refs.next_grade.validate();
      if (!valid.length) {
        this.is_loading = true;
        this.student_list.forEach((element) => {
          if (element.elevated == true) {
            this.elevate_data.student_list.push(element.id);
          }
        });
        await this.axios
          .put(
            `${this.base_url}student-class/${this.student_list[0].id}/`,
            this.elevate_data,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit(
              "successAlert",
              this.$t("global.success.student_elevated")
            );

            this.elevate_data.new_branch = undefined;
            this.elevate_data.new_class = undefined;
            this.getStudents();
            // this.getStudentList();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.elevate_data.student_list = [];
      }
      this.is_loading = false;
    },
    async getStudents() {
      const valid = await this.$refs.current_grade.validate();
      if (!valid.length) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}student-class/`, {
            params: {
              // fk_branch:this.elevate_data.fk_branch,
              fk_class: this.elevate_data.fk_class,
              fk_student: this.elevate_data.fk_student,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.student_list = response.data;
            this.isAllCheck();
            this.show = true;
          })
          .catch((error) => {
            this.show = false;
          });
      }
      this.loading = false;
    },
    //   async getStudentsList() {
    //     this.elevate_data.fk_student = undefined;
    //     if (this.elevate_data.fk_branch != undefined) {
    //       await this.axios
    //         .get(`${this.base_url}student-list/`, {
    //           params: {
    //             class: this.elevate_data.fk_class,
    //             branch: this.elevate_data.fk_branch,
    //           },
    //           headers: {
    //             Authorization: "Bearer " + localStorage.getItem("token"),
    //           },
    //         })
    //         .then((response) => {
    //           this.student_select = response.data;
    //         });
    //     }
    //   },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
};
</script>
