<style>
/* Add custom CSS styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 5px; /* Add padding to th and td */
}

th.border,
td.border {
  border: 1px solid black; /* Add border to th and td */
}

th.merged-header {
  vertical-align: middle;
}
</style>

<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist && getUserRole < 2">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card
    class="justify-center"
    id="lectures-report"
    v-if="exist || getUserRole > 1"
  >
    <report-header
      :rep_en="getUserRole > 1"
      :data="filter_data"
    ></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        :back_button="getUserRole < 2"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table>
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
              <!-- <th class="border">#</th>
              <th class="border">{{ $t("school.reports.number") }}</th>
              <th class="border">{{ $t("school.reports.type") }}</th>
              <th class="border">{{ $t("school.reports.color") }}</th>
              <th class="border">{{ $t("school.reports.driver_name") }}</th> -->
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="key">
                <td class="text-center border">
                  {{ (page - 1) * perPage + key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-for="(header, index) in selectedHeaders.slice(1)"
                  :key="index"
                >
                  {{ item[header.key] ? item[header.key] : "-" }}
                </td>
              </tr>
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td class="text-center border">{{ key + 1 }}</td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('number')"
                >
                  {{ item.number }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('bus_type')"
                >
                  {{ item.bus_type }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('color')"
                >
                  {{ item.color }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('fk_driver')"
                >
                  {{ getDriverName(item.fk_driver) }}
                </td> -->

              <!-- <td class="text-center border" v-if="selectedHead.includes('job_title')">{{item.job_title}}</td> -->
              <!-- </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="items?.length"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      // await this.getDrivers();
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        this.getData();
      }
      // await this.getYears();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // current_year: "",
      // drivers: [],
      // school: [],
      // parents: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",

      selectedHead: [
        "no",
        "number",
        "branch_name",
        "seat_number",
        "bus_type",
        "color",
        "driver_name",
        "teacher_name",
        "itinerary",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.driver_name"), key: "driver_name" },
        { title: this.$t("school.reports.branch"), key: "branch_name" },
        { title: this.$t("school.reports.supervisor"), key: "teacher_name" },
        { title: this.$t("school.reports.bus_type"), key: "bus_type" },
        { title: this.$t("school.reports.bus_number"), key: "number" },
        { title: this.$t("school.reports.seat_count"), key: "seat_number" },
        { title: this.$t("school.reports.bus_color"), key: "color" },
        { title: this.$t("school.reports.bus"), key: "itinerary" },
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        fk_parent: null,
      },
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
      // user: (state) => state.User,
    }),

    selectedHeaders() {
      let filtered = this.selectedHead.filter((header) => {
        if (header === "branch_name") {
          return localStorage?.getItem("role") < 2
            ? !this.filter_data.fk_branch
            : false;
        }
        return true;
      });
      return this.headers.filter((header) => filtered.includes(header.key));
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
    }),
    // async getDrivers(page = 1) {
    //   await this.axios
    //     .get(`${this.base_url}driver/`, {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //       params: {
    //         page: page,
    //         page_size: this.perPage,
    //       },
    //     })
    //     .then((response) => {
    //       this.drivers = response.data.results;
    //     });
    // },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },

    // getDriverName(driver_id) {
    //   if (driver_id) {
    //     const driver = this.drivers.find((driver) => driver.id === driver_id);
    //     return driver.driver_name;
    //   }
    //   return "===========";
    // },

    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}bus/api/bus/data`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data?.results ?? response.data;
            this.totalItems =
              response.data?.pagination?.count ?? this.items?.length;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       fk_parent: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
