<template>
  <VCard class="justify-center">
    <VForm
      ref="form"
      v-if="
        CheckGetPermission('school.add_branch') ||
        (is_update && CheckGetPermission('school.change_branch')) ||
        (CheckGetPermission('school.view_branch') && getUserRole > 1)
      "
    >
      <!-- <VCard-title>
        <VIcon icon="mdi-store" />
        <h2 class="d-inline text-grey" style="padding: 0 4px">
          {{ $t("school.branch_data.add_branch") }}
        </h2>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </VCard-title> -->
      <VTabs v-model="tab" color="blue" class="px-4">
        <VTab v-for="(value, index) in tabs" :key="index" :value="index">
          {{ value }}
        </VTab>
      </VTabs>
      <v-card-text>
        <v-window v-model="tab">
          <v-window-item :value="0">
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                <VRow>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <!-- :append-inner-icon="
                          branch.name_ar === null || branch.name_ar == '' ? 'mdi-text' : ''
                        " -->
                      <VTextField
                        v-model="branch.name_ar"
                        :label="$t('school.branch_data.branch_name_ar')"
                        :placeholder="
                          $t('school.branch_data.placeholder_branch_name_ar')
                        "
                        density="compact"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :rules="[$required, $max_length(100), $ar_letters_only]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="branch.name_en"
                        :label="$t('school.branch_data.branch_name_en')"
                        :placeholder="
                          $t('school.branch_data.placeholder_branch_name_en')
                        "
                        density="compact"
                        prepend-inner-icon="mdi-alpha-e"
                        :rules="[$required, $max_length(100), $en_letters_only]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="branch.boss"
                        :label="$t('school.branch_data.branch_headmaster')"
                        :placeholder="
                          $t('school.branch_data.placeholder_branch_headmaster')
                        "
                        density="compact"
                        prepend-inner-icon="mdi-account-tie"
                        :rules="[$required, $max_length(100)]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="branch.license_number"
                        :label="$t('school.branch_data.license_number')"
                        :placeholder="
                          $t('school.branch_data.placeholder_license_number')
                        "
                        density="compact"
                        prepend-inner-icon="mdi-numeric"
                        type="number"
                        :rules="[$required, $numeric, $max_length(15)]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" xs="12" sm="6" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VRadioGroup
                        inline
                        :label="$t('school.branch_data.students_type')"
                        v-model="branch.students_type"
                        :rules="[$required]"
                      >
                        <VRadio
                          v-for="item in student_types"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          color="blue"
                        >
                        </VRadio>
                      </VRadioGroup>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" xs="12" sm="6" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VCheckbox
                        v-model="branch.is_main"
                        :label="$t('school.branch_data.is_main_branch')"
                        color="blue"
                        :disabled="!(getUserRole < 2)"
                        hide-details="auto"
                      >
                        <!-- @change="checkIfOnlyOneMain" -->
                      </VCheckbox>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    class="pa-0 ma-0"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                  >
                    <VCardItem class="px-3">
                      <VTextarea
                        v-model="branch.note"
                        clearable
                        :label="$t('global.note')"
                        counter="250"
                        no-resize
                        density="compact"
                        prepend-inner-icon="mdi-note-outline"
                        rows="3"
                        :rules="[$max_length(250)]"
                      ></VTextarea>
                    </VCardItem>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="3" lg="3">
                <ImageInput
                  :reset="image_reset"
                  :image="branch.image"
                  :text="$t('school.branch_data.branch_image')"
                  @ImageSelected="saveBranchImage"
                ></ImageInput>
              </VCol>
              <VCol class="pt-0 px-4" cols="12" xs="12" sm="12" md="3" lg="3">
                <ImageInput
                  :reset="image_reset"
                  :image="branch.license"
                  :text="$t('school.branch_data.license_image')"
                  @ImageSelected="saveLicenseImage"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="1">
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="8" lg="8">
                <VRow>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="countries"
                        v-model="country"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('school.branch_data.country')"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-flag"
                        @update:modelValue="
                          (governorate = undefined),
                            (branch.fk_directorate = undefined)
                        "
                        :rules="[$required]"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="filterGovernorates(country)"
                        v-model="governorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('school.branch_data.governorate')"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-city"
                        @update:modelValue="branch.directorate = undefined"
                        :rules="[$required]"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="filterDirectorates(governorate)"
                        v-model="branch.fk_directorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('school.branch_data.directorate')"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-map-outline"
                        :rules="[$required]"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="branch.place"
                        :label="$t('school.branch_data.branch_place')"
                        :placeholder="
                          $t('school.branch_data.placeholder_branch_place')
                        "
                        density="compact"
                        prepend-inner-icon="mdi-map-marker"
                        :rules="[$required, $max_length(100)]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="branch.coordinates"
                        :label="$t('school.branch_data.branch_coordinates')"
                        :placeholder="
                          $t(
                            'school.branch_data.placeholder_branch_coordinates'
                          )
                        "
                        density="compact"
                        prepend-inner-icon="mdi-map"
                        :rules="[$max_length()]"
                      >
                      </VTextField>
                    </VCardItem>
                  </VCol>
                </VRow>
              </VCol>
              <VCol class="pt-0 px-7" cols="12" xs="12" sm="12" md="4" lg="4">
                <ImageInput
                  :reset="image_reset"
                  :image="branch.coordinates_image"
                  :text="$t('school.branch_data.coordinates_image')"
                  @ImageSelected="saveCoordinatesImage"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="2">
            <VRow
              class=""
              v-for="(contact, index) in branch.contacts"
              :key="index"
            >
              <VCol cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="contact_type_list"
                    v-model="contact.contact_type"
                    item-title="name"
                    item-value="id"
                    :label="$t('school.branch_data.contact_type')"
                    hide-details="auto"
                    persistent-hint
                    density="compact"
                    :prepend-inner-icon="
                      contact.contact_type == 1
                        ? 'mdi mdi-phone'
                        : contact.contact_type == 2
                        ? 'mdi mdi-email'
                        : contact.contact_type == 3
                        ? 'mdi mdi-cellphone'
                        : contact.contact_type == 4
                        ? 'mdi mdi-facebook'
                        : contact.contact_type == 5
                        ? 'mdi mdi-whatsapp'
                        : 'mdi-dialpad'
                    "
                    @update:model-value="contact.contact = null"
                  ></VAutocomplete>
                </VCardItem>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="contact.contact"
                    :label="$t('school.branch_data.contact')"
                    hide-details="auto"
                    density="compact"
                    :prepend-inner-icon="
                      contact.contact_type == 1
                        ? 'mdi mdi-phone'
                        : contact.contact_type == 2
                        ? 'mdi mdi-email'
                        : contact.contact_type == 3
                        ? 'mdi mdi-cellphone'
                        : contact.contact_type == 4
                        ? 'mdi mdi-facebook'
                        : contact.contact_type == 5
                        ? 'mdi mdi-whatsapp'
                        : 'mdi-dialpad'
                    "
                    :rules="
                      contact.contact_type
                        ? [
                            $required,
                            ...(contact.contact_type == 2
                              ? [$email, $max_length(64)]
                              : contact.contact_type == 4
                              ? [$max_length(100)]
                              : [
                                  $numeric,
                                  $max_length(14),
                                  contact.contact_type == 1
                                    ? $min_length(6)
                                    : $min_length(9),
                                ]),
                          ]
                        : ''
                    "
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow class="mt-3 mx-9 d-flex flex-wrap" elevation="4">
              <VBtn
                variant="text"
                size="x-small"
                class="text-success my-2 mx-3"
                icon="mdi-plus-thick"
                @click.prevent="addContact()"
              >
                <!-- icon="mdi-plus-thick" -->
                <v-icon color="success"></v-icon>
              </VBtn>
              <VBtn
                v-if="branch.contacts.length > 1"
                variant="text"
                size="x-small"
                class="text-error my-2 mx-3"
                icon="mdi-minus-thick"
                @click.prevent="removeContact()"
              >
              </VBtn>
            </VRow>
          </v-window-item>
        </v-window>
      </v-card-text>
    </VForm>
    <VCardActions
      v-if="
        CheckGetPermission('school.add_branch') ||
        (is_update && CheckGetPermission('school.change_branch'))
      "
      class="mt-4 px-8"
    >
      <VBtn
        v-if="!is_update && CheckGetPermission('school.add_branch')"
        class="bg-indigo"
        @click="saveBranch()"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && CheckGetPermission('school.change_branch')"
        class="bg-success"
        @click="updateBranch()"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn v-if="getUserRole < 2" class="mx-3" size="small" @click="resetForm">
        {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
          :color="!is_update ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn v-if="getUserRole < 2" class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
    <!-- ##########    data Table  ########### -->
    <v-divider class="my-4" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card v-if="getUserRole < 2" class="pa-2">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <!-- size="small" -->
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>

        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span v-else-if="key == 'image'">
                <button @click="showImage(val)">
                  <VAvatar
                    :image="
                      val != null
                        ? val.search(base_url) != -1
                          ? val
                          : base_url + val
                        : false
                    "
                    :icon="val == null ? 'mdi-image-off-outline' : ''"
                    class=""
                    size="small"
                  ></VAvatar>
                </button>
              </span>
              <span v-else-if="key == 'license'">
                <button @click="showLicenseImage(val)">
                  <VAvatar
                    :image="
                      val != null
                        ? val.search(base_url) != -1
                          ? val
                          : base_url + val
                        : false
                    "
                    :icon="val == null ? 'mdi-image-off-outline' : ''"
                    class=""
                    size="small"
                  ></VAvatar>
                </button>
              </span>
              <span v-else-if="key == 'coordinates_image'">
                <button @click="showCoordinatesImage(val)">
                  <VAvatar
                    :image="
                      val != null
                        ? val.search(base_url) != -1
                          ? val
                          : base_url + val
                        : false
                    "
                    :icon="val == null ? 'mdi-image-off-outline' : ''"
                    class=""
                    size="small"
                  ></VAvatar>
                </button>
              </span>
              <span v-else-if="key == 'contact'">
                <span class="me-1" style="color: #216354; border-radius: 4px">
                  <v-icon
                    @click="showContact(val)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-phone-classic"
                  />
                </span>
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_branch')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editBranch(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_branch')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="(branch_del_id = item.raw.id), (del_dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (branch_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteBranch">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </VCard>
  <VDialog v-model="dialog" max-width="500px" max-height="500px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <VCardItem align="center">
        <VImg :src="dialog_image" max-height="300px"></VImg>
      </VCardItem>
      <VCardText>
        <tr v-for="(item, index) in dialog_text" :key="index">
          <td class="text-grey-darken-1 font-weight-bold">{{ item.type }} :</td>
          <td>{{ item.contact }}</td>
        </tr>
      </VCardText>
      <VCardActions>
        <VBtn class="bg-info" @click="dialog = false">{{
          $t("global.ok")
        }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ImageInput from "@/components/Globals/ImageInput.vue";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      //   v$: useValidate(),
      branch: {
        fk_directorate: undefined,
        place: undefined,
        name_ar: undefined,
        name_en: undefined,
        image: undefined,
        coordinates: undefined,
        coordinates_image: undefined,
        license: undefined,
        license_number: undefined,
        students_type: undefined,
        boss: undefined,
        is_main: undefined,
        note: undefined,
        // data_entry: undefined,
        // updated_by: undefined,
        contacts: [{ contact_type: undefined, contact: undefined }],
      },
      image_reset: 1,
      governorate: undefined,
      country: undefined,
      tab: undefined,
      branch_del_id: undefined,
      del_dialog: false,
      dialog: false,
      dialog_image: undefined,
      dialog_title: undefined,
      dialog_text: undefined,
      process_alert: false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      // branch_ar: localStorage.getItem('branch_ar'),
      // current_branch:null,
      is_update: false,
      is_loading: false,
      tabs: [
        this.$t("school.branch_data.branch_data"),
        this.$t("school.branch_data.location_data"),
        this.$t("school.branch_data.contact_data"),
      ],
      headers: [
        { title: this.$t("school.branch_data.branch_name_ar"), key: "name_ar" },
        { title: this.$t("school.branch_data.branch_name_en"), key: "name_en" },
        { title: this.$t("school.branch_data.branch_headmaster"), key: "boss" },
        { title: this.$t("school.branch_data.branch_image"), key: "image" },
        {
          title: this.$t("school.branch_data.license_number"),
          key: "license_number",
        },
        { title: this.$t("school.branch_data.license_image"), key: "license" },
        {
          title: this.$t("school.branch_data.students_type"),
          key: "students_type",
        },
        { title: this.$t("school.branch_data.is_main_branch"), key: "is_main" },
        { title: this.$t("school.branch_data.country"), key: "country" },
        { title: this.$t("school.branch_data.governorate"), key: "gov" },
        {
          title: this.$t("school.branch_data.directorate"),
          key: "fk_directorate",
        },
        { title: this.$t("school.branch_data.branch_place"), key: "place" },
        {
          title: this.$t("school.branch_data.branch_coordinates"),
          key: "coordinates",
        },
        {
          title: this.$t("school.branch_data.coordinates_image"),
          key: "coordinates_image",
        },
        { title: this.$t("school.branch_data.contact"), key: "contact" },
        { title: this.$t("school.branch_data.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "name_ar",
        "name_en",
        "boss",
        "image",
        "contact",
        "license",
        "actions",
      ],
      perPage: 10,
            // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      txt_search: null,
      search: null,
    };
  },
  components: {
    ImageInput,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.student_types.length) await this.getStudentType();
      if (!this.countries.length) await this.getCountries();
      await this.getBrenches({ all: true });
      if (!this.governorates.length) await this.getGovernorates({});
      if (!this.directorates.length) await this.getDirectorate({});
      // if (!this.contact.length)
      await this.getContact();
      if (!this.contact_type_list.length) await this.getContactTypes();
      // if (!this.student_types.length) await this.getStudentType();
      // this.current_branch = this.branches.find(item => item.name_ar == this.branch_ar );

      if (this.getUserRole >= 2) {
        await this.editBranch(...this.branches);
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      // console.log(error);
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      contact_type_list: (state) => state.globals.contacts_type,
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      branches: (state) => state.school.branch,
      contact: (state) => state.school.contact,
      student_types: (state) => state.globals.student_type,
      user: (state) => state.User,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      let dir = undefined;
      let gov = undefined;
      let con = undefined;
      if (this.branches != undefined && this.branches.length)
        this.branches.forEach((element) => {
          dir = this.directorates.find((d) => d.id == element.fk_directorate);
          if (dir != undefined) {
            gov = this.governorates.find((g) => g.id == dir.fk_governorate);
            if (gov != undefined) {
              con = this.countries.find((c) => c.id == gov.fk_country).name_ar;
              gov = gov.name_ar;
            }
            dir = dir.name_ar;
          }
          let student_type = this.student_types.find(
            (s) => s.id == element.students_type
          );
          if (student_type != undefined) student_type = student_type.name;
          list.push({
            id: element.id,
            name_ar: element.name_ar,
            name_en: element.name_en,
            boss: element.boss,
            image: element.image,
            license_number: element.license_number,
            license: element.license,
            students_type: student_type,
            is_main: element.is_main
              ? this.$t("school.branch_data.main_branch")
              : "",
            country: con,
            gov: gov,
            fk_directorate: dir,
            place: element.place,
            coordinates: element.coordinates,
            coordinates_image: element.coordinates_image,
            contact: element.id,
            note: element.note,
          });
        });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getSchoolData: "school/getSchoolData",
      getBrenches: "school/getBrenches",
      getContact: "school/getContact",
      getContactTypes: "globals/getContactTypes",
      getStudentType: "globals/getStudentType",
    }),
    addContact() {
      this.branch.contacts.push({
        contact_type: undefined,
        contact: undefined,
      });
    },
    removeContact() {
      this.branch.contacts.pop();
    },
    resetForm() {
      if (this.getUserRole < 2) {
        this.$refs.form.reset();
        this.is_update = false;
        this.is_loading = false;
        this.branch.image =
          this.branch.image === "no_img" ? undefined : this.branch.image;
        this.branch.license =
          this.branch.license === "no_img" ? undefined : this.branch.license;
        this.branch.coordinates_image =
          this.branch.coordinates_image === "no_img"
            ? undefined
            : this.branch.coordinates_image;
        this.branch.is_main = false;
        this.image_reset++;
        this.branch.contacts = [
          { contact_type: undefined, contact: undefined },
        ];
      }
      // else {
      this.branch.image = undefined;
      this.branch.license = undefined;
      this.branch.coordinates_image = undefined;
      // this.branch.image = this.branch.image === "no_img" ? undefined : this.branch.image;
      // this.branch.license =  this.branch.license === "no_img" ? undefined : this.branch.license;
      // this.branch.coordinates_image =  this.branch.coordinates_image === "no_img" ? undefined : this.branch.coordinates_image;
      //   this.editBranch(...this.branches)
      // }
    },
    saveLicenseImage(event) {
      this.branch.license = event;
    },
    saveCoordinatesImage(event) {
      console.log(event,'4545454');
      this.branch.coordinates_image = event;
    },
    saveBranchImage(event) {
      this.branch.image = event;
    },
    showImage(value) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1)
        this.dialog_image = value;
      else if (value?.search(this.base_url) == -1)
        this.dialog_image = this.base_url + value;
      else this.dialog_image = false;
      this.dialog_title = this.$t("school.branch_data.branch_image");
      this.dialog = true;
    },
    showLicenseImage(value) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1)
        this.dialog_image = value;
      else if (value?.search(this.base_url) == -1)
        this.dialog_image = this.base_url + value;
      else this.dialog_image = false;
      this.dialog_title = this.$t("school.branch_data.license_image");
      this.dialog = true;
    },
    showCoordinatesImage(value) {
      this.dialog_text = [];
      if (value != null && value?.search(this.base_url) != -1)
        this.dialog_image = value;
      else if (value?.search(this.base_url) == -1)
        this.dialog_image = this.base_url + value;
      else this.dialog_image = false;
      this.dialog_title = this.$t("school.branch_data.branch_coordinates");
      this.dialog = true;
    },
    showContact(value) {
      this.dialog_title = this.$t("school.branch_data.contact");
      this.dialog_text = [];
      this.dialog_image = undefined;
      this.contact.forEach((element) => {
        if (element.fk_branch == value) {
          let type = this.contact_type_list.find(
            (item) => item.id == element.type
          );
          if (type != undefined) type = type.name;
          this.dialog_text.push({
            type: type,
            contact: element.branch_contact,
          });
        }
      });
      this.dialog = true;
    },
    editBranch(data) {
      let branch = this.branches.find((item) => item.id == data.id);
      if (branch != undefined) {
        for (const key in branch) this.branch[key] = branch[key];
        // this.branch.created_at = undefined;
        // this.branch.updated_at = undefined;
        // this.branch.data_entry = undefined;
        // this.branch.updated_by = undefined;
        let dir = this.directorates.find(
          (item) => item.id == this.branch.fk_directorate
        );
        if (dir) {
          this.governorate = this.governorates.find(
            (item) => item.id == dir.fk_governorate
          );
          if (this.governorate) {
            this.country = this.countries.find(
              (item) => item.id == this.governorate.fk_country
            )?.id;
          }
        }
        this.branch.image =
          this.branch.image != null &&
          this.branch.image.search(this.base_url) == -1
            ? this.base_url + this.branch.image
            : this.branch.image;
        this.branch.license =
          this.branch.license != null &&
          this.branch.license.search(this.base_url) == -1
            ? this.base_url + this.branch.license
            : this.branch.license;
        this.branch.coordinates_image =
          this.branch.coordinates_image != null &&
          this.branch.coordinates_image.search(this.base_url) == -1
            ? this.base_url + this.branch.coordinates_image
            : this.branch.coordinates_image;
        this.branch.contacts = [];
        this.contact.forEach((element) => {
          if (element.fk_branch == branch.id) {
            this.branch.contacts.push({
              contact_type: element.type,
              contact: element.branch_contact,
              id: element.id,
            });
          }
        });
        this.is_update = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    // checkContactType(value, type) {
    //   if (value == undefined || value.length == 0)
    //     return this.$t("global.error.validation.required_field");
    //   let number = /^\d+$/;
    //   if (type == 1) {
    //     if (!number.test(value)) return this.$t("globals.must_be_numbers");
    //     if (value.length > 8) return this.$t("globals.max_numbers");
    //     if (value.length < 6) return this.$t("globals.min_numbers");
    //   }
    //   if (type == 2) {
    //     let email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     if (!email.test(value)) return this.$t("errors.email");
    //     if (value.length > 100) return this.$t("globals.max_characters");
    //   }
    //   if (type == 3) {
    //     if (!number.test(value)) return this.$t("globals.must_be_numbers");
    //     if (value.length > 14) return this.$t("globals.max_numbers");
    //     if (value.length < 9) return this.$t("globals.min_numbers");
    //   }
    //   if (type == 4) {
    //     if (value.length > 100) return this.$t("globals.max_characters");
    //   }
    //   if (type == 5) {
    //     if (!number.test(value)) return this.$t("globals.must_be_numbers");
    //     if (value.length > 14) return this.$t("globals.max_numbers");
    //     if (value.length < 7) return this.$t("globals.min_numbers");
    //   }

    //   return true;
    // },
    async saveBranch() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.branch.name_ar && this.country) {
        this.is_loading = true;
        let result = await this.axios
          .post(this.base_url + "/branches/", this.branch, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));
            this.resetForm();
            this.getBrenches({ all: true });
            this.getContact();
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        // this.process_alert = true;
        // setTimeout(() => {
        //   this.process_alert = false;
        // }, 2000);
      } else {
        if (valid && !this.branch.name_ar) {
          this.tab = 0;
        } else if (valid && !this.country) {
          this.tab = 1;
        } else {
          this.$emit(
            "errorAlert",
            this.$t("global.error.failure.error_in_data")
          );
        }
      }
      this.is_loading = false;
    },
    async updateBranch() {
      const { valid } = await this.$refs.form.validate();
      // if (this.branch.image)
      //   if (typeof this.branch.image === "string") this.branch.image = undefined;
      //   else this.branch.image = "no_img";

      //   if (this.branch.license) {
      //   if (typeof this.branch.license === "string") this.branch.license = undefined;
      // } else {
      //   this.branch.license = "no_img";
      // }

      // if (this.branch.coordinates_image)
      //   if (typeof this.branch.coordinates_image === "string")
      //   this.branch.coordinates_image = undefined;
      //   else this.branch.coordinates_image = "no_img";

      // if(!this.branch.license){
      //     this.branch.license ="no_img";
      // }
      // if(!this.branch.coordinates_image){
      //     this.branch.coordinates_image ="no_img";
      // }
      //   this.v$.$validate();
      // && this.branch.id != undefined && false
      if (valid) {
        this.branch.image = this.branch.image
          ? typeof this.branch.image === "string"
            ? undefined
            : this.branch.image
          : "no_img";
        this.branch.license = this.branch.license
          ? typeof this.branch.license === "string"
            ? undefined
            : this.branch.license
          : "no_img";
        this.branch.coordinates_image = this.branch.coordinates_image
          ? typeof this.branch.coordinates_image === "string"
            ? undefined
            : this.branch.coordinates_image
          : "no_img";
        // this.branch.updated_by = this.user;
        this.is_loading = true;
        let result = await this.axios
          .put(`${this.base_url}/branches/${this.branch.id}/`, this.branch, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            if (responce.data == -1) {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.main_branch_must_exist")
              );
            } else {
              this.$emit(
                "successAlert",
                this.$t("global.success.data_updated")
              );
              this.getBrenches({ all: true });
              this.resetForm();
              this.getContact();
            }
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      } else {
        this.$emit("errorAlert", this.$t("global.error.failure.error_in_data"));
      }
      this.is_loading = false;
      // this.$emit("errorAlert", this.$t("global.error.failure.error_in_data"));
    },
    async deleteBranch() {
      if (this.branch_del_id != undefined) {
        await this.axios
          .delete(`${this.base_url}/branches/${this.branch_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.del_dialog = false;
            this.branch_del_id = undefined;
            this.getBrenches({ all: true });
            this.getContact();
          })
          .catch((error) => {
            if (error.response?.status == 418) {
              this.$emit("protectedAlert", error.response?.data?.data);
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.del_dialog = false;
    },
    // checkIfOnlyOneMain() {
    //   let branch = this.branches.find((item) => item.is_main == true);
    //   if (this.branch.id == branch.id && this.branch.is_main == false) {
    //     this.$emit("errorAlert", this.$t("global.error.failure.main_branch_must_exist"));
    //     this.branch.is_main = true;
    //   }
    // },
  },
};
</script>
