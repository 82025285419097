<template>
  <div class="dashboard">
    <v-item-group mandatory class="mt-2">
      <v-row justify="center" v-if="true">
        <v-col cols="6" sm="6" md="3" class="mb-2">
          <!-- v-if="CheckGetPermission('summer_center.view_lecturelog') && role > 3" -->
          <v-item>
            <v-alert
              color="white"
              height="80"
              border="start"
              border-color="indigo-accent-2"
              elevation="2"
              @click="toggle"
            >
              <div class="d-flex justify-space-between align-center">
                <div class="text-center">
                  <span class="text-medium-emphasis">{{
                    $t("school.dashboard.student_count")
                  }}</span>
                  <span class="mt-1 mt-2 mb-1">
                    <h1>{{ school_count["students"] }}</h1>
                  </span>
                </div>
                <div>
                  <v-icon
                    size="50"
                    class="rounded pa-4 bg-indigo-accent-2"
                    color="white"
                    >mdi-school
                  </v-icon>
                </div>
              </div>
            </v-alert>
          </v-item>
        </v-col>
        <v-col cols="6" sm="6" md="3" class="mb-2">
          <!-- v-if="CheckGetPermission('summer_center.view_studentsummer')" -->
          <v-item>
            <v-alert
              color="white"
              height="80"
              border="start"
              border-color="green-accent-2"
              elevation="2"
            >
              <div class="d-flex justify-space-between align-center">
                <div class="text-center">
                  <span class="text-medium-emphasis">{{
                    $t("school.dashboard.student_reg_count")
                  }}</span>
                  <span class="mt-1 mt-2 mb-1">
                    <h1>{{ school_count["registered_students"] }}</h1>
                  </span>
                </div>
                <div>
                  <v-icon
                    size="50"
                    class="rounded pa-4 bg-green-accent-2"
                    color="white"
                    >mdi-human-greeting</v-icon
                  >
                </div>
              </div>
            </v-alert>
          </v-item>
        </v-col>
        <v-col cols="6" sm="6" md="3" class="mb-2">
          <!-- v-if="CheckGetPermission('summer_center.view_employee')" -->
          <v-item>
            <v-alert
              color="white"
              height="80"
              border="start"
              border-color="orange-accent-2"
              elevation="2"
              @click="toggle"
            >
              <div class="d-flex justify-space-between align-center">
                <div class="text-center">
                  <span class="text-medium-emphasis">{{
                    $t("school.dashboard.teacher_count")
                  }}</span>
                  <span class="mt-1 mt-2 mb-1">
                    <h1>{{ school_count["teacher_count"] }}</h1>
                  </span>
                </div>
                <div>
                  <v-icon
                    size="50"
                    class="rounded pa-4 bg-orange-accent-2"
                    color="white"
                    >mdi-account-tie</v-icon
                  >
                </div>
              </div>
            </v-alert>
          </v-item>
        </v-col>
        <!-- <v-col
          cols="6"
          sm="6"
          md="3"
          class="mb-2"
          v-if="CheckGetPermission('summer_center.view_activityoperation')"
        >
          <v-item>
            <v-alert
              color="white"
              height="80"
              border="start"
              border-color="orange-accent-2"
              elevation="2"
              @click="toggle"
            >
              <div class="d-flex justify-space-between align-center">
                <div class="text-center">
                  <span class="text-medium-emphasis">{{
                    $t("summer.activity.excuted-activity")
                  }}</span>
                  <span class="mt-1 mt-2 mb-1">
                    <h1>{{ activities["count"] }}</h1>
                  </span>
                </div>
                <div>
                  <v-icon
                    size="50"
                    class="rounded pa-4 bg-orange-accent-2"
                    color="white"
                    >mdi-file-multiple</v-icon
                  >
                </div>
              </div>
            </v-alert>
          </v-item>
        </v-col> -->
      </v-row>
    </v-item-group>
    <!-- v-if="
        CheckGetPermission('summer_center.view_studentsummer') &&
        CheckGetPermission('summer_center.view_employee')
      " -->
    <PieChart />
    <BarChart :statistics="statistics" />
    <!-- v-if="CheckGetPermission('summer_center.view_studentsummer')" -->
  </div>

  <!-- <GradientStackedAreaChart/> -->
  <!-- <StackedLine/> -->
</template>

<script>
// import PieChart from "./SummerPieChart.vue";
// import BarChart from "./BarChartSummer.vue";
// import GradientCart from "./GradientStackedAreaChart.vue";
// import StackedLine from "./StackedLine.vue";
import PieChart from "@/views/Examples/PieChart.vue";
import BarChart from "@/views/Examples/BarChart.vue";
import StackedLine from "@/views/Examples/StackedLine";
import GradientStackedAreaChart from "@/views/Examples/GradientStackedAreaChart";
import { mapState, mapActions, mapGetters } from "vuex";
// import moment from "moment-hijri";

export default {
  name: "Home",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.getStudentsCount();
      this.getRegisteredStudentsCount();
      this.getTeacherCount();
      // this.getStudentCountGender();
      // this.getTeacherCountGender();
      // await this.getSummerSchools();
      // await this.getActiveCirculars();
      if (this.role == 2 || this.role == 8) {
        this.fk_governorate = localStorage.getItem("governorate");
      } else if (this.role == 3) {
        this.fk_directorate = localStorage.getItem("directorate");
      } else if (this.role == 4 || this.role == 5) {
        this.fk_summer = localStorage.getItem("schoolsummer");
      }
      // await this.getUserProfile();
      // await this.getCountries();
      // await this.getGovernorates();
      // await this.getDirectorate();
      // await this.getData();
      // await this.getCalendar();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      fk_governorate: null,
      fk_directorate: null,
      fk_summer: null,
      dates: [],
      circulars: [],
      date: null,
      teachers: {},
      students: {},
      activities: 0,
      subjects: 0,
      school_count: {},
      summer_schools: [],
      statistics: {},
      user_profile: {},
    };
  },
  computed: {
    ...mapState({
      // countries: (state) => state.school.countries,
      // governorates: (state) => state.school.governorates,
      // directorates: (state) => state.school.directorate,
      // role: (state) => state.role,
    }),
    // LocationBreadcrumb() {
    //   const list_data = [];
    //   if (this.user_profile.governorate)
    //     list_data.push(this.user_profile.governorate);
    //   if (this.user_profile.directorate)
    //     list_data.push(this.user_profile.directorate);
    //   if (this.user_profile.date_today)
    //     list_data.push(this.user_profile.date_today);
    //   return list_data;
    // },
    ...mapGetters({
      // filterGovernorates: "school/getGovernoratesByCountry",
      // filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    // role() {
    //   return localStorage.getItem("role");
    // },
    // students_data() {
    //   return this.students;
    // },
    // CheckGetPermission() {
    //   return (prem) => {
    //     return this.$store.getters.checkpermission(prem);
    //   };
    // },
  },
  methods: {
    ...mapActions({
      // getCountries: "school/getCountries",
      // getGovernorates: "school/getGovernorates",
      // getDirectorate: "school/getDirectorate",
    }),
    printName() {
      // console.log("shehab alfareh");
    },
    // async getActiveCirculars() {
    //   this.axios(
    //     `${this.base_url}/school/statistics/generalization/active-circulars/`,
    //     {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     }
    //   ).then((res) => {
    //     this.$store.commit("setCirculars", res.data);
    //   });
    // },
    // async getCalendar() {
    //   await this.axios
    //     .get(this.base_url + "school/statistics/calendar", {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     })
    //     .then((response) => {
    //       if (response.data) {
    //         this.dates = response.data.results;
    //       }
    //     });
    // },
    // async getUserProfile() {
    //   await this.axios
    //     .get(`${this.base_url}api/user/profile`, {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     })
    //     .then((response) => {
    //       this.user_profile = response.data;
    //       if (this.user_profile?.role) {
    //         this.$store.commit("setRole", this.user_profile.role);
    //       }
    //     });
    // },
    // async getStudentsCount() {
    //   if (this.CheckGetPermission("summer_center.view_studentsummer")) {
    //     await this.axios
    //       .get(`${this.base_url}school/statistics/student-count`, {
    //         params: {
    //           fk_governorate: this.fk_governorate,
    //           fk_directorate: this.fk_directorate,
    //           fk_summer: this.fk_summer,
    //           date: this.date,
    //         },
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //       })
    //       .then((response) => {
    //         this.students = response.data;
    //       });
    //   }
    // },
    // async getTeachersCount() {
    //   if (this.CheckGetPermission("summer_center.view_employee")) {
    //     await this.axios
    //       .get(`${this.base_url}school/statistics/teacher-count`, {
    //         params: {
    //           fk_governorate: this.fk_governorate,
    //           fk_directorate: this.fk_directorate,
    //           fk_summer: this.fk_summer,
    //           date: this.date,
    //         },
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //       })
    //       .then((response) => {
    //         this.teachers = response.data;
    //       });
    //   }
    // },
    // async getActivitiesCount() {
    //   if (this.CheckGetPermission("summer_center.view_activityoperation")) {
    //     await this.axios
    //       .get(`${this.base_url}school/statistics/executed-activities-count`, {
    //         params: {
    //           fk_governorate: this.fk_governorate,
    //           fk_directorate: this.fk_directorate,
    //           fk_summer: this.fk_summer,
    //           date: this.date,
    //         },
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //       })
    //       .then((response) => {
    //         this.activities = response.data;
    //       });
    //   }
    // },
    // async getSubjectsCount() {
    //   if (this.CheckGetPermission("summer_center.view_lecturelog")) {
    //     await this.axios
    //       .get(`${this.base_url}school/statistics/subjects-count`, {
    //         params: {
    //           fk_governorate: this.fk_governorate,
    //           fk_directorate: this.fk_directorate,
    //           fk_summer: this.fk_summer,
    //           date: this.date,
    //         },
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //       })
    //       .then((response) => {
    //         this.subjects = response.data;
    //       });
    //   }
    // },
    // async getSchoolSummerCount() {
    //   if (this.role >= 0 && this.role < 4) {
    //     await this.axios(`${this.base_url}school/statistics/summer-count`, {
    //       params: {
    //         governorate: this.fk_governorate,
    //         directorate: this.fk_directorate,
    //       },
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     }).then((response) => {
    //       this.school_count = response.data;
    //     });
    //   }
    // },
    // async getStudentStatistics() {
    //   await this.axios
    //     .get(`${this.base_url}school/statistics/students-statistics`, {
    //       params: {
    //         governorate: this.fk_governorate,
    //         directorate: this.fk_directorate,
    //         fk_summer: this.fk_summer,
    //       },
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     })
    //     .then((response) => {
    //       this.statistics = response.data;
    //     });
    // },
    async getData() {
      // await this.getStudentsCount();
      // await this.getTeachersCount();
      // await this.getActivitiesCount();
      // await this.getSubjectsCount();
      // await this.getSchoolSummerCount();
      // await this.getStudentStatistics();
    },
    async getStudentsCount() {
      await this.axios
        .get(`${this.base_url}school/statistics/studentcount`, {
          // params: {
          //   directorate: this.fk_directorate,
          //   governorate: this.fk_governorate,
          // },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.school_count.students = response.data;
        });
    },
    async getRegisteredStudentsCount() {
      await this.axios
        .get(`${this.base_url}school/statistics/Registered_studentscount`, {
          // params: {
          //   directorate: this.fk_directorate,
          //   governorate: this.fk_governorate,
          // },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.school_count.registered_students = response.data;
        });
    },
    async getTeacherCount() {
      await this.axios
        .get(`${this.base_url}school/statistics/teachercount`, {
          // params: {
          //   directorate: this.fk_directorate,
          //   governorate: this.fk_governorate,
          // },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.school_count.teacher_count = response.data;
        });
    },
    // async getStudentCountGender() {
    //   await this.axios
    //     .get(`${this.base_url}school/statistics/studentcount_by_class`, {
    //       // params: {
    //       //   directorate: this.fk_directorate,
    //       //   governorate: this.fk_governorate,
    //       // },
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       this.school_count.teacher = response.data;
    //     });
    // },
  },
  components: {
    PieChart,
    BarChart,
    GradientStackedAreaChart,
    StackedLine,
  },
};
</script>
