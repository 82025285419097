<style scoped>
/* Add custom CSS styles */
/* Add custom CSS styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 5px; /* Add padding to th and td */
}

th.border,
td.border {
  border: 1px solid black; /* Add border to th and td */
}

th.merged-header {
  vertical-align: middle;
}
</style>

<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist && getUserRole < 2">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>

  <v-card
    class="justify-center"
    id="lectures-report"
    v-if="exist || getUserRole > 1"
  >
    <report-header
      :rep_en="getUserRole > 1"
      :data="filter_data"
    ></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        :back_button="getUserRole < 2"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table>
            <!-- <thead>
              <tr>
                <th class="border" colspan="6">
                  {{ $t("school.reports.teacher_info") }}
                </th>
                <th class="border" colspan="6"></th>
              </tr>
            </thead> -->
            <thead>
              <tr>
                <th
                  v-for="header in selectedHeaders.filter(
                    (head) => head.sub !== true
                  )"
                  :key="header?.key"
                  :rowspan="header?.row"
                  :colspan="header?.col"
                >
                  {{ header.title }}
                </th>
                <!-- <th :rowspan="2">
                  {{ selectedHeaders[1].title }}
                </th>
                <th :rowspan="2">
                  {{ selectedHeaders[2].title }}
                </th> -->
                <!-- <th colspan="2" class="border">
                  {{ $t("school.reports.teacher_location") }}
                </th>
                <th colspan="2" class="border">
                  {{ $t("school.reports.teacher_status") }}
                </th>
                <th rowspan="2" class="border">
                  {{ $t("school.reports.teacher_specail") }}
                </th> -->
              </tr>
              <tr>
                <th
                  v-for="header in selectedHeaders.filter(
                    (head) => head.sub == true
                  )"
                  :key="header.key"
                  class="border"
                >
                  {{ header.title }}
                </th>
              </tr>
              <!-- <tr>
                <th rowspan="2" class="border">
                  {{ $t("school.reports.teacher_name") }}
                </th>
                <th rowspan="2" class="border">
                  {{ $t("school.reports.phone_number") }}
                </th>
                <th colspan="2" class="border">
                  {{ $t("school.reports.teacher_status") }}
                </th>
                <th colspan="2" class="border">
                  {{ $t("school.reports.teacher_location") }}
                </th>
                <th rowspan="2" class="border merged-header">
                  {{ $t("school.reports.teacher_specail") }}
                </th>
              </tr>
              <tr>
                <th class="border">{{ $t("school.reports.governorate") }}</th>
                <th class="border">{{ $t("school.reports.directorate") }}</th>
                <th class="border">{{ $t("school.reports.single") }}</th>
                <th class="border">{{ $t("school.reports.married") }}</th>
              </tr> -->
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  v-for="(header, index) in selectedHeaders.filter(
                    (head) => head.key !== 'teacher_location'
                  )"
                  :class="`border px-3 ${
                    header.key == 'student_name' ? '' : 'text-center'
                  }`"
                  :key="index"
                >
                  <template v-if="index === 0">
                    {{ (page - 1) * perPage + key + 1 }}
                  </template>
                  <!-- <template v-else-if="header.key == 'marital_status'">
                    {{
                      item[header.key] == 2
                        ? "متزوج"
                        : item[header.key] == 1
                        ? "أعزب"
                        : item[header.key] == 3
                        ? "مطلق"
                        : "-"
                    }}
                  </template> -->
                  <template v-else>
                    {{ item[header.key] ? item[header.key] : "-" }}
                  </template>
                </td>
              </tr>
              <!-- <tr v-for="item in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('name_ar')"
                >
                  {{ item?.name_ar ?? "-" }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('phone_number')"
                >
                  {{ item?.phone_number ?? "-" }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('address')"
                >
                  {{ item?.address ?? "-" }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('place')"
                >
                  {{ item?.place ?? "-" }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('governorate')"
                >
                  {{ item?.governorate ?? "-" }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('marital_status')"
                >
                  {{ getSinglePerson(item.marital_status) }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('marital_status')"
                >
                  {{ getMaridPerson(item.marital_status) }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('job_title')"
                >
                  {{ item?.job_title ?? "-" }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        this.getData();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // current_year: "",
      // parents: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: [
        "no",
        "name_ar",
        "phone_number",
        "job_id",
        // "teacher_location",
        "address",
        "children",
        "birthdate",
        "date_of_employment",
        "gender",
        "home_number",
        "service_status",
        "work_period",
        "gender",
        "place",
        "type",
        "marital_status",
        "teacher_address",
        "job_title",
      ],

      headers: [
        { title: "#", key: "no", row: 2 },
        {
          title: this.$t("school.reports.teacher_name"),
          key: "name_ar",
          row: 2,
        },
        {
          title: this.$t("school.reports.teacher_type"),
          key: "type",
          row: 2,
        },

        {
          title: this.$t("school.reports.jop_number"),
          key: "job_id",
          row: 2,
        },

        // {
        //   title: this.$t("school.reports.teacher_location"),
        //   key: "teacher_location",
        //   col: 2,
        // },
        {
          title: this.$t("school.reports.birth_place"),
          key: "address",
        },
        {
          title: this.$t("school.reports.current_place"),
          key: "place",
        },
        {
          title: this.$t("school.reports.marital_status"),
          key: "marital_status",
          row: 2,
        },
        {
          title: this.$t("school.reports.children"),
          key: "children",
          row: 2,
        },
        {
          title: this.$t("school.reports.gender"),
          key: "gender",
          row: 2,
        },
        {
          title: this.$t("school.reports.phone_number"),
          key: "phone_number",
          row: 2,
        },
        {
          title: this.$t("school.reports.home_number"),
          key: "home_number",
          row: 2,
        },
        {
          title: this.$t("school.reports.work_period"),
          key: "work_period",
          row: 2,
        },
        {
          title: this.$t("school.reports.service_status"),
          key: "service_status",
          row: 2,
        },

        // {
        //   title: this.$t("school.reports.address"),
        //   key: "teacher_address",
        //   row: 2,
        // },
        {
          title: this.$t("school.reports.teacher_specail"),
          key: "job_title",
          row: 2,
        },
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 5, text: "5" },
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        fk_parent: null,
      },
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
    }),
    getSinglePerson() {
      return (maritalStatus) => {
        return maritalStatus === 1 ? this.$t("school.reports.yes") : "";
      };
    },
    getMaridPerson() {
      return (maritalStatus) => {
        return maritalStatus === 2 ? this.$t("school.reports.yes") : "";
      };
    },
    ...mapState({
      // user: (state) => state.User,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      let filtered = this.selectedHead.filter((header) => {
        if (header === "branch_name") {
          return localStorage?.getItem("role") < 2
            ? !this.filter_data.fk_branch
            : false;
        }
        return true;
      });
      return this.headers.filter((header) => filtered.includes(header.key));
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
    }),
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // async getParents() {
    //   await this.axios
    //     .get(`${this.base_url}parent/`, {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //       params: {
    //         page_size: 1000,
    //         page: 1,
    //       },
    //     })
    //     .then((response) => {
    //       this.parents = response.data.results;
    //     });
    // },

    // checkFilterData(){
    //     this.v$.filter_data.$validate()
    //     if(!this.v$.filter_data.$error){
    //         this.getData()
    //     }
    // },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/school/report/teachers`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data?.results;
            this.totalItems = response.data?.pagination?.count;
            // this.length=response.data.pagination.num_pages
            // this.pagination=response.data.pagination
            // this.itemsPerPage[4]['value']=this.totalItems
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       fk_parent: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
