import VueAxios from "vue-axios";
import { createStore, Store } from "vuex";
import Vuex from "vuex";
import host from "./../main";
import auth from "./auth";

import router from "@/router";
import financial from "./Financial/financial.module";
import globals from "./Globals/globals.module";
import school from "./School/school.module";
import users from "./Users/users.module";
import axios from "axios";
import jwt_decode from "jwt-decode";

const store = new Vuex.Store({
  state: {
    Bearer: undefined,
    User: undefined,
    permissions: ["school.dashboard"],
    lang: "ar",
    direction: "rtl",
    expire: undefined,
    isAuthenticated: undefined,
    username: undefined,
    login_error: false,
    loading: false,
    drawer: true,
    role_list: [
      "programmer",
      "big_admin",
      "admin",
      "user",
      "teacher",
      "student",
      "parent",
    ],
  },
  mutations: {
    updateBreadcrumbValue(state, value) {
      state.breadcrumb = value;
    },
    updateLoadingValue(state, value) {
      state.loading = value;
    },
    updateDrawer(state, value) {
      state.drawer = value;
    },
    setAuthenticated(state, bearer) {
      state.Bearer = bearer;
    },
    setPermission(state, permission) {
      state.permissions = permission;
    },
    setUser(state, user) {
      state.User = user;
    },
    logout(state, value) {
      state.Bearer = "";
      // state.permissions=value
      state.User = "";
    },
    setlang(state, lang) {
      state.lang = lang;
    },
    setdir(state, dir) {
      state.direction = dir;
    },
    setExpire(state, expire) {
      state.expire = expire;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setLoginError(state) {
      state.login_error = true;
    },
  },
  getters: {
    getbrebar: (state) => state.Bearer,
    getpermission: (state) => state.permissions,
    getuser: (state) => state.User,
    getdrawer: (state) => state.drawer,
    checkrole:
      (state) =>
      (role = []) =>
        role.includes(state.role_list[localStorage.getItem("role")]),
    checkpermission: (state) => (perm) => state.permissions.includes(perm),
    getlang: (state) => state.lang,
    getexpire: (state) => state.expire,
    getdir: (state) => {
      console.log(state.direction);
      return state.direction;
    },

    header(state) {
      return "Bearer " + state.Bearer;
    },
  },
  actions: {
    async setAuthenticated({ commit, getters, state, dispatch }, response) {
      if (response && response.data) {
        const token = response.data.access;
        const email = response.data.email;

        const decoded = jwt_decode(token);

        commit("setAuthenticated", token);
        commit("setAuthenticated", email);
        commit("setExpire", decoded.exp);
        commit("setUser", decoded.user_id); ///////repat
        commit("setPermission", response.data.permission);
        localStorage.setItem("token", token);
        // localStorage.setItem('role',decoded.role)
        const user = await axios
          .get(host() + "api/user-info/", {
            headers: { Authorization: "Bearer " + token },
            params: {
              user: decoded.user_id,
            },
          })
          .catch((error) => {
            commit("setLoginError");
          });
        if (user && user.data && user.data.username) {
          localStorage.setItem("username", user.data.username);
          localStorage.setItem("email", user.data.email);
          localStorage.setItem("role", user.data.role);
          localStorage.setItem("branch_ar", user.data.branch_ar);
          localStorage.setItem("branch_en", user.data.branch_en);
          localStorage.setItem("year_m", user.data.year_m);
          localStorage.setItem("year_h", user.data.year_h);
          localStorage.setItem("semester_ar", user.data.semester_ar);
          localStorage.setItem("semester_en", user.data.semester_en);
          localStorage.setItem("month_h", user.data.month_h);
          localStorage.setItem("month_m", user.data.month_m);
          localStorage.setItem(
            "date",
            user.data.use_hijri_calendar ? "h" : "m"
          );
          localStorage.setItem(
            "attendance",
            user.data.attendance_twice ? "2" : "1"
          );
          if (!localStorage.getItem("user-locale"))
            localStorage.setItem("user-locale", "ar");
          commit("setUsername", user.data.username);
        }
        setTimeout(() => {
          dispatch("logout");
          window.location.reload();
        }, (store.getters.getexpire - Date.now() / 1000) * 1000);
        // await axios.get(host()+'api/academic-years/current-year/',{headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }}).then(res=>{
        //     localStorage.setItem('current_year',res.data.id)
        //     localStorage.setItem('current_year_name_h',res.data.name_h)

        // })
        localStorage.setItem("userinfo", response.data.permission);
        router.push("/");
      }
    },
    async updatePermission({ commit }, response) {
      try {
        commit("setPermission", response);
      } catch (error) {
        commit("setLoginError");
      }
    },
    async updateAuthenticated({ commit }, response) {
      try {
        const decoded = jwt_decode(response);
        commit("setExpire", decoded.exp);
        commit("setAuthenticated", response);
        commit("setUser", decoded.user_id);
      } catch (error) {
        commit("setLoginError");
      }
    },
    updatelanguage({ commit }, value) {
      commit("setlang", value);
    },
    updatedirection({ commit, state }, value) {
      commit("setdir", value);
    },
    logout({ commit }) {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userinfo");
      localStorage.removeItem("role");
      localStorage.removeItem("branch_ar");
      localStorage.removeItem("branch_en");
      localStorage.removeItem("year_m");
      localStorage.removeItem("year_h");
      localStorage.removeItem("semester_ar");
      localStorage.removeItem("semester_en");
      localStorage.removeItem("month_h");
      localStorage.removeItem("month_m");
      commit("logout");
    },
  },
  modules: {
    financial,
    globals,
    school,
    users,
  },
});

export default store;
