<template>
  <v-card class="justify-center">
    <v-card-text class="card-fields">
      <v-form
        v-if="
          (update && CheckGetPermission('school.change_fees')) ||
          CheckGetPermission('school.add_fees')
        "
        ref="form"
      >
        <v-row>
          <v-col cols="10" md="7">
            <v-autocomplete
              v-model="fees.fk_fees_type"
              clearable
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-cash-100"
              :label="$t('school.fees.fee_types')"
              :items="fee_type_used_list"
              :rules="[$required]"
            >
            </v-autocomplete>
          </v-col>
          <v-col v-if="CheckGetPermission('school.add_feetype')" cols="2">
            <v-btn
              @click="add_dialog = true"
              class="bg-indigo"
              density="default"
              variant="tonal"
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="fees.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              clearable
              :items="classes"
              :label="$t('school.class.select')"
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-card-item class="pa-0">
              <v-text-field
                v-model="fees.fee"
                :label="$t('school.fees.fee')"
                density="compact"
                prepend-inner-icon="mdi-account-cash-outline"
                type="number"
                clearable
                :rules="[$required, $numeric, $max_length(7)]"
              >
              </v-text-field>
            </v-card-item>
          </v-col>
          <v-col cols="12" md="8">
            <v-textarea
              v-model="fees.note"
              hide-details="auto"
              density="compact"
              counter="250"
              rows="3"
              clearable
              prepend-inner-icon="mdi-note-outline"
              :label="$t('global.note')"
              :rules="[$max_length(250)]"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions
      v-if="
        (update && CheckGetPermission('school.change_fees')) ||
        CheckGetPermission('school.add_fees')
      "
      class="px-8"
    >
      <v-btn
        v-if="!update && CheckGetPermission('school.add_fees')"
        @click="saveFees()"
        :loading="is_loading"
        class="bg-indigo"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="update && CheckGetPermission('school.change_fees')"
        @click="updateFees(selectedFees)"
        :loading="is_loading"
        class="bg-success"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ !update ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
          :color="!update ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
    </v-card-actions>
    <!-- ##########    data Table Server ########### -->
    <!-- <v-row justify="center" v-if="alertMessage" class="mb-4">
      <v-col cols="9">
        <v-alert
          closable
          density="compact"
          border="start"
          variant="outlined"
          color="success"
          :icon="alertIcon"
          :title="alertTitle"
          :text="alertMessage"
        >
        </v-alert>
      </v-col>
    </v-row> -->
    <v-divider class="mt-5" />
    <v-card class="pa-2">
      <!-- ######## Header Of Data Table Server ####### -->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <!-- <v-btn v-bind="props" variant="text" size="small" @click="getFees">
          <v-icon>mdi-refresh</v-icon>
        </v-btn> -->
        <div style="width: 100%">
          <v-text-field
            v-model="txt_search"
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            class="search"
            hide-details
            append-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        density="compact"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_fees')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editFees(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_fees')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="(selectedFees = item.raw.id), (del_dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (selectedFees = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteFees(selectedFees)">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
  <v-dialog dir="rtl" v-model="add_dialog" width="500" class="text-right">
    <v-card>
      <v-card-title class="text-right">
        <v-icon icon="mdi-cash-100" />
        <h3 class="d-inline" style="padding: 0 4px">
          {{ $t("school.fees.fee_types") }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </v-card-title>
      <v-card-text>
        <v-form ref="form2">
          <v-text-field
            v-model="fee_types.name"
            counter="100"
            type="input"
            density="compact"
            clearable
            prepend-inner-icon="mdi-text-box"
            :label="$t('school.fees.name')"
            :rules="[$required, $max_length(100)]"
          />
          <VAutocomplete
            v-model="fee_types.subscribe_type"
            item-value="id"
            item-title="name"
            :items="subscribsType_list"
            prepend-inner-icon="mdi-currency-usd"
            :label="$t('school.fees.subscribe_type')"
            persistent-hint
            density="compact"
            clearable
            :rules="[(value) => checkSubscribtion(value), $required]"
          >
          </VAutocomplete>
          <div class="d-flex">
            <v-checkbox
              v-model="fee_types.reducible"
              :label="$t('school.fees.reducible')"
              color="indigo"
              density="compact"
            />
            <v-checkbox
              v-model="fee_types.installment_available"
              :label="$t('school.fees.installment_available')"
              color="indigo"
              density="compact"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          class="bg-indigo"
          @click="saveFeeTypes"
          size="small"
          :loading="is_loading2"
          ><span class="text-white">{{ $t("global.save") }}</span></v-btn
        >
        <v-btn @click="cleanFeeTypesForm">{{ $t("global.cancel") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Fees",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.classes.length) await this.getClasses();
      if (!this.fees_list.length) await this.getFees();
      if (!this.fee_types_list.length) await this.getFeeTypes();
      if (!this.subscribsType_list.length) await this.getSubscribsType();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      //   v$: useValidate(),
      index: "",
      documents: [],
      del_dialog: false,
      add_dialog: false,
      overlay: false,
      update: false,
      is_loading: false,
      is_loading2: false,
      selectedHead: ["fk_class", "fk_fees_type", "fee", "note", "actions"],
      fees: {
        fk_class: undefined,
        fk_fees_type: undefined,
        fee: undefined,
        note: undefined,
        // data_entry: undefined,
        // updated_by: undefined,
      },
      fee_types: {
        name: undefined,
        reducible: undefined,
        installment_available: undefined,
        subscribe_type: null,
        data_entry: undefined,
      },
      txt_search: undefined,
      selectedFees: undefined,
      headers: [
        { title: this.$t("school.class.name"), key: "fk_class" },
        { title: this.$t("school.fees.fee_type"), key: "fk_fees_type" },
        { title: this.$t("school.fees.fee"), key: "fee" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      search: null,
      perPage: 10,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      fees_list: (state) => state.school.fees,
      fee_types_list: (state) => state.school.fee_types,
      subscribsType_list: (state) => state.globals.subscribsType,
      // user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      this.fees_list.forEach((element) => {
        let classes = this.classes.find((item) => item.id == element.fk_class);
        let fee_types = this.fee_types_list.find(
          (item) => item.id == element.fk_fees_type
        );
        if (classes != undefined && fee_types != undefined)
          list.push({
            fk_class: classes.name_ar,
            fk_fees_type: fee_types.name,
            fee: element.fee,
            note: element.note,
            id: element.id,
          });
      });
      return list;
    },
    fee_type_used_list() {
      let list = [];
      this.fee_types_list.forEach((element) => {
        if (element.subscribe_type == 4 || element.subscribe_type == 2)
          list.push(element);
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getFees: "school/getFees",
      getFeeTypes: "school/getFeeTypes",
      getClasses: "school/getClasses",
      getSubscribsType: "globals/getSubscribsType",
    }),
    editFees(id) {
      this.selectedFees = id;
      // this.axios.get(`${this.base_url}api/class-documents/${id}/`).then(response=>{
      this.update = true;
      //     this.fees=response.data
      // })
      let fees = this.fees_list.find((item) => item.id == id);
      if (fees) this.fees = { ...fees };
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveFees() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}fees/`, this.fees, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.resetForm();
            this.getFees();
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.non_field_errors
            ) {
              this.$emit(
                "errorAlert",
                this.$t("global.error.validation.fee_class_exceist")
              );
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        this.is_loading = false;
      }
    },
    async saveFeeTypes() {
      const { valid } = await this.$refs.form2.validate();
      if (valid) {
        this.is_loading2 = true;
        await this.axios
          .post(`${this.base_url}fee-types/`, this.fee_types, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));
            this.$refs.form2.reset();
            this.getFeeTypes();
            this.add_dialog = false;
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.non_field_errors
            ) {
              this.$emit(
                "errorAlert",
                this.$t("global.error.validation.fee_class_exceist")
              );
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        this.is_loading2 = false;
      }
    },
    resetForm() {
      this.$refs.form.reset();
      this.update = false;
    },
    cleanFeeTypesForm() {
      this.$refs.form2.reset();
      this.add_dialog = false;
    },
    checkSubscribtion(value) {
      if (value == 1) {
        let type = this.fee_types_list.find(
          (item) => item.subscribe_type == value
        );
        if (type == undefined) return true;
        else {
          if (type.id == this.selected_fee_type) return true;
          else return this.$t("global.error.validation.fee_types_bus_error");
        }
      }
      return true;
    },
    async updateFees(id) {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.is_loading = true;
        this.fees.updated_by = this.user;
        await this.axios
          .put(`${this.base_url}fees/${id}/`, this.fees, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));

            this.resetForm();
            this.getFees();
          })
          .catch((error) => {
            console.log(error);
            if (
              error.response &&
              error.response.data.non_field_errors &&
              error.response.data.non_field_errors.length
            ) {
              this.$emit(
                "errorAlert",
                this.$t("global.error.validation.fee_class_exceist")
              );
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else if (error.response.data.used) {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.cant_update_assigned_fee")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    deleteFees(id) {
      this.axios
        .delete(`${this.base_url}fees/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.del_dialog = false;
          this.selectedFees = "";
          this.$emit("successAlert", this.$t("global.success.data_deleted"));
          this.getFees();
        })
        .catch((error) => {
          this.del_dialog = false;
          this.selectedFees = "";
          if (error.response && error.response.data) {
            if (error.response.data == "not_your_branch")
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.cant_delete")
              );
            else if (error.response.data == "no_branch")
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.user_error")
              );
            else if (error.response.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          } else
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
        });
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
