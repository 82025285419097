<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            return-object
            clearable
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes_by_branch"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:model-value="filter_data.fk_subject = null"
          />
          <!-- @update:model-value="
              (filter_data.fk_division = null),
                getDivisions(filter_data.fk_class?.id)
            " -->
        </v-col>

        <!-- <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-chair-school"
            :items="class_divisions"
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->

        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_subject"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-bookshelf"
            :items="class_subjects"
            :label="$t('school.reports.select_subject')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>

        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <tr>
                <th v-for="header in selectedHeaders" :key="header.key">
                  {{ header.title }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  v-for="(header, index) in selectedHeaders"
                  :class="`border px-3 ${
                    header.key == 'student_name' ? '' : 'text-center'
                  }`"
                  :key="index"
                >
                  <template v-if="index === 0">
                    {{ (page - 1) * perPage + key + 1 }}
                  </template>
                  <template v-else>
                    {{ item[header.key] != null ? item[header.key] : "-" }}
                  </template>
                </td>
              </tr>
              <!-- <tr v-for="(item, index) in items" :key="item.id">
                <td
                  v-if="selectedHead.includes('no')"
                  class="text-center border"
                >
                  {{ (page - 1) * perPage + index + 1 }}
                </td>
                <td
                  v-for="header in selectedHeaders?.filter(
                    (head) => head.key != 'no'
                  )"
                  :key="header.key"
                  class="text-center border"
                >
                  {{ item[header.key] ? item[header.key] : "-" }}
                </td>
              </tr> -->
              <!-- <tr v-for="(item, key) in items" :key="key">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_id')"
                >
                  {{ item.fk_student }}
                </td>

                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student.name_ar }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('final_exam')"
                >
                  {{ item.final_exam }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('note')"
                >
                  {{ item.note }}
                </td>
              </tr> -->
              <!-- <td class="text-center border" v-if="selectedHead.includes('secretnumber')">{{item.secretnumber}}</td> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBranchClasses();
      await this.getBrenches({});
      await this.getClassAssignHall();
      await this.getDivisions({});
      await this.getSubjects({});
      // await this.getattendances();
      // await this.getSemesters();
      // await this.getYears();
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      // this.current_year = this.getYearName(
      //   localStorage.getItem("current_year")
      // );
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // current_year: "",
      branches: [],
      // years: [],
      // months: [],
      // attendances: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: [
        "no",
        "student_name",
        "branch_name",
        // "collector",
        "final_exam",
        // "total",
        "estimate",
        // "note",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: this.$t("school.reports.branch"), key: "branch_name" },
        // { title: this.$t("school.reports.collector"), key: "collector" },
        { title: this.$t("school.reports.final_exam"), key: "final_exam" },
        // { title: this.$t("school.reports.total"), key: "total" },
        { title: this.$t("school.reports.estimate"), key: "estimate" },
        // { title: this.$t("school.reports.note"), key: "note" },
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 5, text: "5" },
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      classes_by_branch: (state) => state.school.classes_by_branch,
      class_assign_hall: (state) => state.school.class_assign_hall,
      divisions: (state) => state.school.divisions,
      semesters: (state) => state.school.semesters,
      subjects: (state) => state.school.subjects,
      branch: (state) => state.school.branch,
      // user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    class_subjects() {
      if (this.filter_data.fk_class?.id) {
        var class_data = this.classes_by_branch.find(
          (item) => item.id == this.filter_data.fk_class?.id
        );
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },
    class_divisions() {
      if (this.filter_data.fk_class?.id) {
        return this.getClassDivisions(this.filter_data.fk_class?.id);
      }
    },
    selectedHeaders() {
      let filtered = this.selectedHead.filter((header) => {
        if (header === "branch_name") {
          return localStorage?.getItem("role") < 2
            ? !this.filter_data.fk_branch
            : false;
        }
        // if (header === "class_name") {
        //   return !this.filter_data.fk_class;
        // }
        return true;
      });
      return this.headers.filter((header) => filtered.includes(header.key));
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      getClassAssignHall: "school/getClassAssignHall",
      getSubjects: "school/getSubjects",
      getBrenches: "school/getBrenches",
    }),
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // async getbranchest() {
    //   await this.axios
    //     .get(`${this.base_url}branches/`, {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     })
    //     .then((response) => {
    //       this.branches = response.data;
    //     });
    // },

    // async getattendances() {
    //   await this.axios
    //     .get(`${this.base_url}`, {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     })
    //     .then((response) => {
    //       this.attendances = response.data.results;
    //     });
    // },
    // getClassName(class_id) {
    //   if (class_id) {
    //     const classObject = this.classes.find(
    //       (classObject) => classObject.id === class_id
    //     );
    //     return classObject.name_ar;
    //   }
    //   return "===========";
    // },
    // getDivisionName(division_id) {
    //   if (division_id) {
    //     const division = this.divisions.find(
    //       (division) => division.id === division_id
    //     );
    //     return division.name;
    //   }
    //   return "===========";
    // },
    getSubjectName(subject_id) {
      if (subject_id) {
        const subject = this.subjects.find(
          (subject) => subject.id === subject_id
        );
        return subject?.name_ar;
      }
      return "===========";
    },
    // getBranchName(branch_id) {
    //   if (branch_id) {
    //     const branch = this.branches.find((branch) => branch.id === branch_id);
    //     return branch.name_ar;
    //   }
    //   return "===========";
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/students/subjects_semester_marks`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            // fk_year: localStorage.getItem("current_year"),
            fk_class: this.filter_data.fk_class?.id,
            // fk_division: this.filter_data.fk_division?.id,
            fk_subject: this.filter_data.fk_subject?.id,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data?.results ?? response.data;
            this.totalItems = response.data?.pagination?.count;
            // this.length=response.data.pagination.num_pages
            // this.pagination=response.data.pagination
            // this.itemsPerPage[4]['value']=this.totalItems
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       class: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       division: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },

  //       fk_subject: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },

  //       fk_branches: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
