<template>
  <VCard class="justify-center">
    <VForm
      ref="form"
      v-if="
        (checkRole(['programmer', 'big_admin']) &&
          CheckGetPermission('school.add_document')) ||
        (is_update && CheckGetPermission('school.change_document'))
      "
    >
      <VCard-text class="card-fields">
        <VRow>
          <VCol cols="12" xs="12" sm="12" md="8" lg="8">
            <VCardItem class="pa-0">
              <VTextField
                v-model="document.name"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('school.document.name')"
                :placeholder="$t('school.document.name_placeholder')"
                density="compact"
                type="text"
                clearable
                :rules="[
                  $required,
                  $max_length(),
                  $min_length(3),
                  $ar_letters_only,
                  (value) =>
                    !tableList?.find(
                      (item) =>
                        item.id !== document?.id &&
                        item.name?.trim() == value?.trim()
                    ) || this.$t('global.error.validation.unique'),
                ]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" xs="12" sm="12" md="8" lg="8">
            <VCardItem class="pa-0">
              <VTextarea
                v-model="document.note"
                clearable
                prepend-inner-icon="mdi-note-outline"
                :label="$t('global.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
              >
              </VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VCard-text>
    </VForm>
    <VCardActions
      v-if="
        (checkRole(['programmer', 'big_admin']) &&
          CheckGetPermission('school.add_document')) ||
        (is_update && CheckGetPermission('school.change_document'))
      "
      class="px-8 d-flex flex-wrap"
      elevation="4"
    >
      <VBtn
        v-if="!is_update"
        class="bg-indigo"
        @click="saveDocument"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update"
        class="bg-success"
        @click="updateDocument"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
          :color="!is_update ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ##########    data Table  ########### -->
    <v-divider class="mt-5" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span class="me-1" style="color: #e5b254; border-radius: 4px">
                  <v-icon
                    v-if="checkRole(['programmer', 'big_admin'])"
                    @click="editDocument(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span style="color: #d05251; border-radius: 4px">
                  <v-icon
                    v-if="checkRole(['programmer', 'big_admin'])"
                    @click="
                      (document_del_id = item.raw.id), (del_dialog = true)
                    "
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (document_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteDocument">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      // v$: useValidate(),
      document: {
        name: undefined,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      is_update: false,
      is_loading: false,
      del_dialog: false,
      document_del_id: undefined,
      // process_alert:undefined,
      // icon_color:undefined,
      // icon:undefined,
      // alert_title:undefined,
      // alert_message:undefined,
      headers: [
        { title: this.$t("school.document.name"), key: "name" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: ["name", "note", "actions"],
      txt_search: null,
      search: null,
      perPage: 10,
            // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      // name_rule:[
      //     (value) => !!value || this.$t('globals.required_field'),
      //     (value) => (/^[\u0621-\u064A ]+$/.test(value)) || this.$t('globals.must_be_letters'),
      //     (value) => (value && value.length <= 100) || `${this.$t('globals.biggest_number_of_character')} 100`,
      //     (value) => (value && value.length >= 3) || `${this.$t('globals.lowest_number_of_character')} 3`,
      // ],
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.documents.length) await this.getAllDocuments();
      if (this.getUserRole >= 2) {
        this.headers.pop();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      // user: (state) => state.User,
      documents: (state) => state.school.documents,
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    tableList() {
      let list = [];
      this.documents.forEach((element) => {
        list.push({
          name: element.name,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getAllDocuments: "school/getAllDocuments",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.document.id = undefined;
    },
    editDocument(data) {
      let documents = this.documents.find((item) => item.id == data.id);
      if (documents != undefined) {
        for (const key in documents) this.document[key] = documents[key];
        this.documents.data_entry = undefined;
        this.documents.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveDocument() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.document.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/document/", this.document, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));

            // this.icon_color="success"
            // this.icon="check-circle"
            // this.alert_title=this.$t('globals.success')
            // this.alert_message=this.$t('globals.data_added')
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );

            // this.icon_color="error"
            // this.icon="cancel"
            // this.alert_title=this.$t('globals.failure')
            // this.alert_message=this.$t('globals.error_in_data')
          });
        // this.process_alert=true
        this.resetForm();
        this.getAllDocuments();
        // setTimeout(()=>{
        //     this.process_alert=false
        // },1500)
      }
      this.is_loading = false;
    },
    async updateDocument() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.document.id != undefined) {
        this.is_loading = true;
        this.document.updated_by = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}/document/${this.document.id}/`,
            this.document,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        // this.process_alert=true
        this.resetForm();
        this.getAllDocuments();
        // setTimeout(()=>{
        //     this.process_alert=false
        // },1500)
      }
      this.is_loading = false;
    },
    async deleteDocument() {
      if (this.document_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}/document/${this.document_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.resetForm();
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.document_del_id = undefined;
        // this.process_alert=true
        this.getAllDocuments();
        // setTimeout(()=>{
        //     this.process_alert=false
        // },1500)
      }
    },
  },
};
</script>
