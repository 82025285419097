<template>
  <VCard class="justify-center">
    <!-- ######## Header Of Data Table  ####### -->

    <v-card class="pa-2">
      <!-- <div > -->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2">
                mdi-eye-outline
              </v-icon>
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-text-field
          color="indigo"
          v-model="text_search"
          @keyup.enter="getAssignedHalls((page = 1))"
          @input="!text_search ? getAssignedHalls((page = 1)) : ''"
          class="search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          :placeholder="$t('global.data_table.search_here')"
          density="compact"
          variant="text"
        >
        </v-text-field>
        <div>
          <VBtn
            v-if="CheckGetPermission('school.add_classdivisionhall')"
            class="bg-success"
            @click="assignNewHall"
          >
            <span class="text-white">{{ $t("global.create") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
        </div>
      </div>
      <!-- </div> -->
      <!-- ######## End Header Of Data Table Server ####### -->
      <!-- <v-progress-linear
        color="indigo"
        class="my-1"
        :indeterminate="loading"
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear> -->
      <v-data-table-server
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        fixed-footer
        density="compact"
        v-model:sort-by="sortBy"
        @update:options="getAssignedHalls((page = 1))"
        class="elevation-1"
        :loading="loading"
      >
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_classdivisionhall')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editAssignHall(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_classdivisionhall')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="(assign_del_id = item.raw.id), (del_dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex">
                        <v-pagination  v-model="page" :length="length"  ></v-pagination>
                        <div class="d-flex align-center">
                            <span>{{$t('globals.per-page')}} </span>
                            <v-select v-model="perPage" :items="itemsPerPage"  item-value="value" item-title="text" variant="solo"></v-select>
                        </div>
                    </div> -->
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ totalItems }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table-server>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (assign_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteAssignHall">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      assigned_halls: [],
      del_dialog: false,
      assign_del_id: undefined,
      perPage: 10,
      // length: 0,
      page: 1,
      sortBy: [{ key: "fk_hall", order: "asc" }],
      // pagination: {},
      totalItems: null,
      loading: false,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 20, text: "20" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: this.$t("global.data_table.all") },
      // ],
      headers: [
        {
          title: this.$t("school.buldings_data.building_name"),
          key: "fk_building",
        },
        { title: this.$t("school.assign_hall_data.hall_name"), key: "fk_hall" },
        { title: this.$t("school.class.name"), key: "fk_class" },
        { title: this.$t("school.division.name"), key: "fk_division" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "fk_building",
        "fk_hall",
        "fk_class",
        "fk_division",
        "note",
        "actions",
      ],
      text_search: undefined,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getAssignedHalls();
      if (!this.divisions.length) await this.getDivisions({ all: true });
      if (!this.classes.length) await this.getClasses();
      await this.getBuilding({ all: true });
      if (!this.halls.length) await this.getAllHalls();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      halls: (state) => state.school.hall,
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      building: (state) => state.school.building,
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      this.assigned_halls.forEach((element) => {
        let hall = this.halls.find((item) => item.id == element.fk_hall);
        let classe = this.classes.find((item) => item.id == element.fk_class);
        let division = this.divisions.find(
          (item) => item.id == element.fk_division
        );
        try {
          let building = this.building.find(
            (item) => item.id == hall.fk_building
          );
          list.push({
            fk_hall: hall.name,
            fk_class: classe.name_ar,
            fk_building: building.name,
            fk_division: division.name,
            note: element.note,
            id: element.id,
          });
        } catch (e) {}
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getBuilding: "school/getBuilding",
      getClasses: "school/getClasses",
      getAllHalls: "school/getAllHalls",
      changeCurrentPage: "school/changeCurrentPage",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    assignNewHall() {
      this.changeCurrentPage({ page: this.length, count: this.perPage });
      this.$router.push("add");
    },
    editAssignHall(id) {
      this.changeCurrentPage({ page: this.page, count: this.perPage });
      this.$router.push(`${id}/edit/`);
    },
    async deleteAssignHall() {
      if (this.assign_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}assign-hall/${this.assign_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getClassAssignHall();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.del_dialog = false;
        this.assign_del_id = undefined;
        this.getAssignedHalls();
      }
    },
    async getAssignedHalls(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}assign-hall/`, {
        params: {
          search: this.text_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.assigned_halls = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getAssignedHalls();
    },
    page() {
      this.getAssignedHalls(this.page);
    },
  },
};
</script>
