<template>
  <VCard class="justify-center">
    <VCardText
      class="card-fields"
      v-if="
        CheckGetPermission('school.add_classdivisionhall') ||
        (id && CheckGetPermission('school.change_classdivisionhall'))
      "
    >
      <VForm ref="form">
        <VRow>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="building"
                v-model="fk_building"
                prepend-inner-icon="mdi-office-building"
                item-title="name"
                item-value="id"
                :label="$t('school.buldings_data.building_name')"
                persistent-hint
                density="compact"
                clearable
                @update:modelValue="assign.fk_hall = undefined"
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="getBuildingsHalls(fk_building)"
                v-model="assign.fk_hall"
                prepend-inner-icon="mdi-floor-plan"
                item-title="name"
                item-value="id"
                :label="$t('school.assign_hall_data.hall_name')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required, duplicate('fk_hall')]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol class="pt-0 px-3" cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                v-model="assign.fk_class"
                :items="classes"
                prepend-inner-icon="mdi-google-classroom"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.class.select')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
                @update:model-value="
                  assign.fk_division ? $refs.division.validate() : ''
                "
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                ref="division"
                :items="divisions"
                v-model="assign.fk_division"
                prepend-inner-icon="mdi-format-list-bulleted"
                item-title="name"
                item-value="id"
                :label="$t('school.division.select')"
                persistent-hint
                density="compact"
                :no-data-text="$t('school.division.not_found')"
                clearable
                :rules="[$required, uniqueDivision]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="pa-0 ma-0" md="8" lg="8">
            <VCardItem class="px-3">
              <VTextarea
                v-model="assign.note"
                clearable
                :label="$t('global.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
                prepend-inner-icon="mdi-note-outline"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VForm>
    </VCardText>
    <VCardActions
      class="px-8"
      v-if="
        CheckGetPermission('school.add_classdivisionhall') ||
        (id && CheckGetPermission('school.change_classdivisionhall'))
      "
    >
      <VBtn
        v-if="!id && CheckGetPermission('school.add_classdivisionhall')"
        class="bg-indigo"
        @click="saveAssignedHall"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="id && CheckGetPermission('school.change_classdivisionhall')"
        class="bg-success"
        @click="updateAssignedHall"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      assign: {
        fk_hall: undefined,
        fk_class: undefined,
        fk_division: undefined,
        note: undefined,
        id: undefined,
      },
      original: undefined,
      fk_building: undefined,
      assigned_halls: [],
      is_loading: false,
      // hall_rule: (value) =>
      //   this.checkIfHallExcist(value) ||
      //   this.$t("global.error.failure.hall_excist"),
      // (value) => value==3 || this.$t('globals.hall_excist'),
      // division_rule: (value) =>
      //   this.checkIfDivisionExcist(value) || this.$t("globals.division_excist"),
      duplicate: (field) => (value) => {
        return (
          !value ||
          !this.assigned_halls.find((item) =>
            this.id
              ? item[`${field}`] != this.original[`${field}`] &&
                item[`${field}`] == value
              : item[`${field}`] == value
          ) ||
          this.$t("global.error.failure.hall_excist")
        );
      },
      uniqueDivision: (value) => {
        var assign_hall = this.assigned_halls.find((item) =>
          this.id
            ? (this.assign.fk_division != this.original.fk_division ||
                this.assign.fk_class != this.original.fk_class) &&
              item.fk_class == this.assign.fk_class &&
              item.fk_division == value
            : item.fk_class == this.assign.fk_class && item.fk_division == value
        );
        return (
          !assign_hall || this.$t("global.error.validation.division_exists")
          // +
          //   this.getBuildingsHalls(this.fk_building)?.find(
          //     (item) => item.id == assign_hall.fk_hall
          //   )?.name
        );
      },
      classes: [],
    };
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      await this.getAllHalls();
      await this.getAssignedHalls();
      await this.getBuilding({});
      if (!this.classes.length) await this.getClasses();
      await this.getClass();
      if (!this.divisions.length) await this.getDivisions({});

      if (this.id != undefined)
        await this.axios
          .get(`${this.base_url}/assign-hall/${this.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.assign = response.data;
            this.original = { ...response.data };
            let hall = this.halls.find(
              (item) => item.id == this.assign.fk_hall
            );
            if (hall != undefined) {
              let building = this.building.find(
                (item) => item.id == hall.fk_building
              );
              if (building != undefined) this.fk_building = building.id;
              this.getAllHalls(this.fk_building);
            }
          });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      halls: (state) => state.school.hall,
      building: (state) => state.school.building,
      // user: (state) => state.User,
    }),
    ...mapGetters({
      getBuildingsHalls: "school/getBuildingsHalls",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getAllHalls: "school/getAllHalls",
      getDivisions: "school/getDivisions",
      getBuilding: "school/getBuilding",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "assign-halllist" });
      } else {
        this.$refs.form.reset();
        this.is_update = false;
      }
    },
    async getClass() {
      await this.axios
        .get(`${this.base_url}api/classes/select-list/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.classes = response.data;
        });
    },
    async saveAssignedHall() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(this.base_url + "assign-hall/", this.assign, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.getClasses();
            this.getClassAssignHall();
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.fk_hall)
              this.$emit(
                "errorAlert",
                this.$t("school.assign_hall_data.hall_is_already_exists")
              );
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
      }
      this.is_loading = false;
    },
    async updateAssignedHall() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.assign.id != undefined) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}assign-hall/${this.assign.id}/`, this.assign, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.getClasses();
            this.getClassAssignHall();
            this.resetForm();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        // setTimeout(() => {
        //   this.$router.push({ name: "assign-halllist" });
        // }, 2000);
      }
      this.is_loading = false;
    },
    async getAssignedHalls(page = 1) {
      return await this.axios(`${this.base_url}assign-hall/`, {
        params: {
          page: 1,
          page_size: 1000000000,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }).then((response) => {
        this.assigned_halls = response.data.results;
      });
    },
    // checkIfHallExcist(value) {
    //   let bool = true;
    //   if (this.original != undefined)
    //     this.assigned_halls.forEach((element) => {
    //       if (element.fk_hall == value) {
    //         if (value != this.original.fk_hall) bool = false;
    //       }
    //     });
    //   return bool;
    // },
    // checkIfDivisionExcist(value) {
    //   let bool = true;
    //   let hall = this.getBuildingsHalls(this.fk_building).find(
    //     (item) => item.id == this.assign.fk_hall
    //   );
    //   // let hall2=this.getBuildingsHalls(this.fk_building).find(item=>item.id==this.original.fk_hall)
    //   if (hall == undefined) return false;
    //   if (this.original != undefined)
    //     if (
    //       hall.fk_branch != this.original.fk_branch ||
    //       this.assign.fk_class != this.original.fk_class ||
    //       value != this.original.fk_division
    //     )
    //       this.assigned_halls.forEach((element) => {
    //         if (element.fk_branch == hall.fk_branch)
    //           if (element.fk_class == this.assign.fk_class)
    //             if (element.fk_division == value) bool = false;
    //       });
    //   return bool;
    // },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
