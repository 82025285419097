<template>
  <v-card class="pt-2" :loading="!items.length" v-if="items.length > 0">
    <table
      density="compact"
      class="text-center"
      style="width: 100%; border-collapse: collapse"
    >
      <thead>
        <th v-for="header in headers" :key="header.key">
          {{ header.title }}
        </th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.id">
          <td class="text-center border">
            {{ (page - 1) * perPage + index + 1 }}
          </td>
          <td
            v-for="header in filteredHeaders"
            :key="header.key"
            class="text-center border"
          >
            {{ item[header.key] }}
          </td>
        </tr>
        <tr v-if="totalColumns && totalColumns.length">
          <td v-for="header in headers" :key="header.key">
            <span v-if="totalColumns.includes(header.key)">
              {{ calculateTotal(header.key) }}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
    <pagination
      v-model:page="page"
      v-model:perPage="perPage"
      :length="items?.length"
    ></pagination>
    <!-- <div class="d-flex" id="pagination-bar">
      <v-pagination
        v-model="page"
        :length="length"
        density="compact"
        show-first-last-page
      >
      </v-pagination>
      <div class="d-flex">
        <v-select
          v-model="perPage"
          class="pa-0"
          :items="itemsPerPage"
          item-value="value"
          item-title="text"
          density="compact"
          hide-details
          variant="text"
        >
        </v-select>
        <span class="mt-2 px-2"
          >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
          {{ totalItems }} ] {{ $t("globals.item") }}</span
        >
      </div>
    </div> -->
  </v-card>
</template>

<script>
export default {
  name: "Dynamic",

  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    totalColumns: [],
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => item.key !== "no");
    },
  },
  methods: {
    calculateTotal(column) {
      return this.items.reduce((total, row) => {
        return total + (Number(row[column]) || 0);
      });
    },
  },
  data() {
    return {
      totalItems: 0,
      itemsPerPage: [
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: "all" },
      ],
      page: 1,
      pagination: {},
    };
  },
};
</script>
