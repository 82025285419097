<template>
  <v-card class="justify-center">
    <v-card-text class="card-view">
      <!-- <v-card class="pa-2 ma-auto" > -->
      <v-form
        ref="form"
        @submitStudentHomeworkForm.prevent="saveStudentHomework"
        v-if="CheckGetPermission('school.view_studenthomework')"
      >
        <v-row >
          <v-col cols="12" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              
              clearable
              :items="classes"
              :label="$t('school.class.select')"
              :rules="[$required]"
              @update:modelValue="(fk_division = null), (fk_subject = null)"
            />
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_division"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-format-list-bulleted"
              
              clearable
              :items="class_divisions"
              :label="$t('school.division.select')"
              :no-data-text="$t('school.division.not_found')"
            />
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_subject"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-book-open-variant"
              
              clearable
              :items="class_subjects"
              :label="$t('school.subject.select')"
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="4" md="1">
            <v-btn
              @click="getHomeworks()"
              class="bg-indigo"
              size="small"
              :loading="loading"
            >
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
              <!-- <v-icon icon="mdi-content-save" color="white" end></v-icon> -->
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="row-nm pt-5 pb-2" v-if="exist">
          <v-col cols="12" md="6">
            <v-autocomplete
              clearable
              v-model="fk_homework"
              density="compact"
              item-title="name"
              item-value="id"
              hide-details
              prepend-inner-icon="mdi-text-box"
              :label="$t('school.homework.name')"
              :no-data-text="$t('global.error.failure.not_found')"
              :items="class_homeworks"
              @update:modelValue="getStudenthHomework()"
              :rules="[$required]"
              :loading="loading"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>

      <!-- </v-card> -->
    </v-card-text>
    <v-expand-transition>
      <div v-show="exist && fk_homework && !loading" class="card-table">
        <v-card>
          <v-table v-if="exist && fk_homework" density="compact">
            <thead>
              <tr>
                <th style="width: 3%">{{ $t("global.number") }}</th>
                <th style="width: 40%">
                  {{ $t("global.personal_information.name") }}
                </th>
                <th style="width: 27%">
                  <v-checkbox
                    color="indigo"
                    v-model="masterChecked.is_checked"
                    :indeterminate="isIndeterminate.is_checked"
                    density="compact"
                    hide-details
                    :label="$t('school.homework.checked')"
                    style="min-width: 100px"
                    @change="toggleAll(masterChecked.is_checked, 'is_checked')"
                    :disabled="!student_homeworks.length"
                  >
                  </v-checkbox>
                </th>
                <th style="width: 30%">{{ $t("global.note") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(student_homework, index) in student_homeworks"
                :key="student_homework"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ student_homework.fk_student }}</td>
                <td>
                  <v-checkbox
                    hide-details
                    color="indigo"
                    density="compact"
                    v-model="student_homework.is_checked"
                    @change="updateMaster('is_checked')"
                  >
                  </v-checkbox>
                </td>
                <td>
                  <v-text-field
                    v-model="student_homework.note"
                    density="compact"
                    :placeholder="$t('global.note')"
                    counter="100"
                    hide-details
                    class="my-1"
                    :rules="max_entry"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr v-if="!student_homeworks.length">
                <td colspan="4" class="text-center">
                  {{ this.$t("global.error.failure.no_students") }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card>

        <v-card-actions
          v-if="
            exist &&
            fk_homework &&
            (CheckGetPermission('school.add_studenthomework') ||
              CheckGetPermission('school.change_studenthomework'))
          "
          class="px-8"
        >
          <v-btn
            v-if="
              CheckGetPermission('school.add_studenthomework') ||
              CheckGetPermission('school.change_studenthomework')
            "
            @click="saveStudentHomework"
            :loading="is_loading"
            :disabled="!student_homeworks.length"
            class="bg-indigo"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.save") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <!-- <v-btn v-if="update &&CheckGetPermission('school.change_homework')" @click="updateHomework(id)"   class=" bg-success"    size="small">
                <span class="text-white">
                    {{$t('global.edit')}}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn> -->
          <v-btn @click="cleanStudentHomeworkForm" class="mx-3" size="small">
            <span>
              {{ $t("global.clear") }}
            </span>
            <v-icon icon="mdi-broom" color="golden" end></v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
  <div></div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "CorrectionHomework",
  props: {
    id: String,
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      if (!this.classes.length) await this.getClasses();
      if (!this.subjects.length) await this.getSubjects({});
      if (!this.teachers.length) await this.getAllTeachers();
      if (!this.divisions.length) await this.getDivisions({});
      await this.getClassAssignHall();
      if (!this.semesters.length) await this.getSemesters();
      if (!this.academic_years.length) await this.getAcademicYears();

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      select_all_students: false,
      schedules_data: [],
      update: false,
      user: null,
      exist: false,
      student_homework: [],
      user: null,
      fk_class: undefined,
      fk_division: undefined,
      // fk_semester:undefined,
      fk_subject: undefined,
      class_homeworks: [],
      homeworks: [],
      fk_homework: undefined,
      studentHomework: {
        is_checked: false,
        fk_student: undefined,
        note: "",
      },
      student_homeworks: [],
      max_entry: [(value) => value == null || value.length < 250 || ""],
      is_loading: false,
      loading: false,
      masterChecked: {
        is_checked: false,
      },
      isIndeterminate: {
        is_checked: false,
      },
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      semesters: (state) => state.school.semesters,
      divisions: (state) => state.school.divisions,
      teachers: (state) => state.school.teachers,
      subjects: (state) => state.school.subjects,
      academic_years: (state) => state.school.academic_years,
    }),
    ...mapGetters({
      getSubjectName: "school/getSubjectName",
      getClassDivisions: "school/getClassDivisions",
      getSubjectsByClass: "school/getSubjectsByClass",
    }),
    class_divisions() {
      if (this.fk_class) {
        return this.getClassDivisions(this.fk_class);
      }
    },
    class_subjects() {
      if (this.fk_class) {
        // var class_data = this.classes.find((item) => item.id == this.fk_class);
        // var subjects_data = [];
        // class_data.subjects.forEach((element) => {
        //   subjects_data.push({
        //     id: element.id,
        //     name: element.name_ar,
        //   });
        // });
        return this.getSubjectsByClass(this.fk_class);
      }
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getSemesters: "school/getSemesters",
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getAllTeachers: "school/getAllTeachers",
      getAcademicYears: "school/getAcademicYears",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    async getStudenthHomework() {
      if (this.fk_homework) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}api/student-homework/list/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              homework: this.fk_homework,
            },
          })
          .then((response) => {
            this.student_homeworks = response.data.map((item) => ({
              ...item,
              homework : this.fk_homework,
            }));
            this.updateMaster("is_checked");
          });
      } else {
        this.student_homeworks = [];
      }
      this.loading = false;
    },
    async getHomeworks() {
      this.student_homeworks = [];
      this.class_homeworks = [];
      this.fk_homework = null;
      this.exist = false;
      const { valid } = await this.$refs.form.validate();

      // this.v$.$validate()
      if (valid) {
        this.loading = true;
        await this.axios(`${this.base_url}/api/homework/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            search: this.txt_search,
            page_size: 100000000,
            class_id: this.fk_class,
            division: this.fk_division,
            // semester:this.fk_semester,
            subject: this.fk_subject,
          },
        })
          .then((response) => {
            this.class_homeworks = response.data.results;
            if (this.class_homeworks.length > 0) {
              this.exist = true;
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.no_homeworks")
              );
              this.exist = false;
            }
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("global.error.failure.not_found"));
            this.exist = false;
          });
      }
      this.loading = false;
    },

    async saveStudentHomework() {
      const valid = await this.$refs.form.validate();
      const studentHomeworks = this.student_homeworks.map((item) => {
        return {
          id: item.id,
          is_checked: item.is_checked,
          homework: item.homework,
          note: item.note,
          fk_student:item.fk_std_id,
          updated_by: this.user,
        };
      });
      if (valid) {
        (this.is_loading = true),
          await this.axios
            .post(
              `${this.base_url}api/student-homework/multiple-update/`,
              studentHomeworks,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              this.$emit("successAlert", this.$t("global.success.data_saved"));

              this.getStudenthHomework();
            })
            .catch((error) => {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            });
      }
      this.is_loading = false;
    },

    // selectAllStudents(value) {
    //   this.student_homeworks.forEach((student) => {
    //     student.is_checked = value;
    //   });
    // },

    toggleAll(All, checkedField) {
      this.student_homeworks.forEach((item) => {
        item[checkedField] = All;
      });
      this.updateMaster(checkedField);
    },
    updateMaster(checkedField) {
      const data = this.student_homeworks;

      const totalChecked = data.reduce((total, item) => {
        return total + (item[checkedField] ? 1 : 0);
      }, 0);
      const totalItems = data.length;
      this.masterChecked[checkedField] = totalChecked === totalItems;
      this.isIndeterminate[checkedField] =
        totalChecked > 0 && totalChecked < totalItems;
    },
    cleanStudentHomeworkForm() {
      this.$refs.form.reset();
      this.exist = false;
      this.student_homeworks = [];
      this.class_homeworks = [];
    },
  },
};
</script>
