<template>
  <VCard class="justify-center">
    <VForm ref="form" v-if="CheckGetPermission('school.add_teachersubjectclass') || 
                      (id && CheckGetPermission('school.change_teachersubjectclass'))">
      <v-card-text class="card-fields">
        <VRow>
          <VCol cols="12" md="8">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="teachers"
                v-model="assign.fk_teacher"
                prepend-inner-icon="mdi-account-tie"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.teacher.select')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="classes"
                v-model="assign.fk_class"
                prepend-inner-icon="mdi-google-classroom"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.class.select')"
                persistent-hint
                density="compact"
                clearable
                @update:modelValue="assign.fk_division = undefined, assign.fk_subject = undefined"
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="getClassByDivisions(assign.fk_class)"
                v-model="assign.fk_division"
                prepend-inner-icon="mdi-text"
                item-title="name"
                item-value="id"
                :label="$t('school.division.select')"
                :no-data-text="$t('school.division.not_found')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="class_subjects()"
                :loading="class_subjects()?.length < 1 && assign.fk_class"
                v-model="assign.fk_subject"
                prepend-inner-icon="mdi-book-open-variant"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.subject.select')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="semesters"
                v-model="assign.fk_semester"
                prepend-inner-icon="mdi-calendar-month"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.semester.select')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="pa-0 ma-0" md="8">
            <VCardItem class="px-3">
              <VTextarea
                v-model="assign.note"
                clearable
                :label="$t('global.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                prepend-inner-icon="mdi-note-outline"
                :rules="[$max_length(250)]"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </v-card-text>
    </VForm>
    <VCardActions class="mx-5" v-if="CheckGetPermission('school.add_teachersubjectclass') || 
                      (id && CheckGetPermission('school.change_teachersubjectclass'))">
      <VBtn
        v-if="!id && CheckGetPermission('school.add_teachersubjectclass')"
        class="bg-indigo"
        @click="saveAssignedTeacher"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="id && CheckGetPermission('school.change_teachersubjectclass')"
        class="bg-success"
        @click="updateAssignedTeacher"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      assign: {},
      is_loading:false
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.subjects.length == 0) await this.getSubjects({});
      if (this.classes.length == 0) await this.getClasses();
      if (this.semesters.length == 0) await this.getSemesters();
      if (this.teachers.length == 0) await this.getAllTeachers({});
      if (this.divisions.length == 0) await this.getDivisions({});
      if (this.class_assign_hall.length == 0) await this.getClassAssignHall();
      if (this.id != undefined)
        await this.axios
          .get(`${this.base_url}/assign-teacher/${this.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.assign = response.data;
          });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      subjects: (state) => state.school.subjects,
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      semesters: (state) => state.school.semesters,
      teachers: (state) => state.school.teachers,
      class_assign_hall: (state) => state.school.class_assign_hall,
      user: (state) => state.User,
    }),
    ...mapGetters({
      getClassByDivisions: "school/getClassDivisions",
      getSubjectsByClass: "school/getSubjectsByClass",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getSubjects: "school/getSubjects",
      getClasses: "school/getClasses",
      getSemesters: "school/getSemesters",
      getAllTeachers: "school/getAllTeachers",
      getDivisions: "school/getDivisions",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "assignteacherview" });
      } else {
        this.$refs.form.reset();
        this.is_update = false;
      }
    },
    class_subjects() {
      // let subjects_data = [];
      if (this.assign.fk_class) {
        // var class_data = this.classes.find(
        //   (item) => item.id == this.assign.fk_class
        // );
        // if (class_data)
        //   class_data.subjects.forEach((element) => {
        //     subjects_data.push({
        //       id: element.id,
        //       name: element.name_ar,
        //     });
        //   });
        return this.getSubjectsByClass(this.assign.fk_class);
      }
      // return subjects_data;
    },
    async saveAssignedTeacher() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true
        await this.axios
          .post(this.base_url + "assign-teacher/", this.assign, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.resetForm();
          })
          .catch((error) => {
            if (error.response && error.response.data)
              this.$emit(
                "errorAlert",
                this.$t("school.assign_teacher_data.teacher_class_subject")
              );
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });

        // this.$router.push({name:"assignteacherview"})
      }
      this.is_loading = false
    },
    async updateAssignedTeacher() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.assign.id != undefined) {
        this.is_loading = true
        await this.axios
          .put(
            `${this.base_url}assign-teacher/${this.assign.id}/`,
            this.assign,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            // setTimeout(() => {
            //   this.$router.push({ name: "assignteacherview" });
            // }, 2000);
          })
          .catch((error) => {
            if (error.response && error.response.data)
              this.$emit(
                "errorAlert",
                this.$t("school.assign_teacher_data.teacher_class_subject")
              );
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
      }
      this.is_loading = false
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
