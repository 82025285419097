export default {
  "school": {
    "side_bar": {
      "system_initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System initialize"])},
      "new_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Registration"])},
      "show_registered_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered Students"])},
      "add_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Parent"])},
      "view_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Parent"])},
      "school_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School data"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
      "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject initialize"])},
      "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate "])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Document"])},
      "document_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document initialize"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorates"])},
      "directorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorates"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
      "days_gaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days gaid"])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study preiods"])},
      "financial-affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Affairs"])},
      "fee_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Type"])},
      "fees_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Items"])},
      "discount_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Categories"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buildings"])},
      "view_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view halls"])},
      "halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halls"])},
      "student_affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student affairs"])},
      "registration_addmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Admission"])},
      "school-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School calender"])},
      "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
      "divisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisions"])},
      "general-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General initialize"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
      "students_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Accounts"])},
      "add_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add student fee"])},
      "edit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit student fee"])},
      "add_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add payment"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year Guide"])},
      "semesters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semesters Guide"])},
      "preparing_homeworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing homeworks"])},
      "daily_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily follow up"])},
      "control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control"])},
      "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams"])},
      "examination_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination tables"])},
      "examination_~periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination periods "])},
      "add_exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add exam schedule"])},
      "exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam schedule"])},
      "add_committee_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add committees plan"])},
      "committee_plan_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees plan list"])},
      "distribute_students_on_committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute students on committees"])},
      "secret_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Seat numbers"])},
      "seat_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Seat Numbers"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
      "add_new_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new activity"])},
      "student_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Students activities "])},
      "monthly_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Grades"])},
      "semester_grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Grades"])},
      "score_recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score recording"])},
      "seat_number_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is a duplicated number"])},
      "add_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Teacher"])},
      "teacher_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Teachers Information"])},
      "faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty"])},
      "bus_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus system"])},
      "weekly_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study schedules"])},
      "add_weekly_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add study schedule"])},
      "view_weekly_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Study Schedules"])},
      "classes_inti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes initialize"])},
      "add_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add halls"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Users"])},
      "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
      "users_adminstration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users Administration"])},
      "lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lectures"])},
      "lectures_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectures List"])},
      "lecture_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add lecture"])},
      "student_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student status"])},
      "student_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Fees Bus"])},
      "add_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Hall to the Plan"])},
      "assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Halls Plan"])},
      "show_homeworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Homeworks"])},
      "homework_correction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct Homeworks"])},
      "homework_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Homework"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "daily_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily attendance"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DashBoard"])},
      "financial_affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Affairs"])},
      "school_calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Calendar"])},
      "examination_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination Periods"])},
      "general_initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Initialize"])},
      "title_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination committees"])},
      "add_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add committee"])},
      "add_seat_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Seat Numbers"])},
      "homeworks_correction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HomeWorks Correction"])},
      "committeesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees list"])},
      "Rest_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rest Fees"])},
      "Student_Account_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Account Statement"])},
      "Student_Installments_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Installments Statement"])},
      "Students_without_Study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students without Study Fees "])},
      "Students_without_Buses_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students without Buses Fees"])},
      "Students_Subscribed_Buses_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Subscribed Buses Statement"])},
      "Student_Statement_Fee_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Statement Fee Clause"])},
      "Student_Statement_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Statement Clause"])},
      "Detailed_Statement_study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed Statement Study Fees"])},
      "Statement_Paid_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement Paid Fees"])},
      "students_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Reports"])},
      "Students_by_Year_Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students by Class"])},
      "Students_by_Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students by Governorate"])},
      "Students_by_Nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students by Nationality"])},
      "Students_by_Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students by Parent"])},
      "Students_Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Age"])},
      "Students_Users_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Users Name"])},
      "Students_Images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Images"])},
      "New_Enrolled_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Enrolled Students"])},
      "Walk_out_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Walkout Students"])},
      "parents_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents Reports"])},
      "parent_by_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent Reports by Student"])},
      "personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
      "parents_usernames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents Usernames"])},
      "parents_contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents Contacts"])},
      "subscribers_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed in Activities"])},
      "Monthly_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Reports"])},
      "Monthly_Deficiencies_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Deficiencies Marks"])},
      "Monthly_Marks_statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Marks Statement "])},
      "Specific_Month_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Month Marks"])},
      "Month_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Certificate"])},
      "Month_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Certificate EN"])},
      "First_Class_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Place Student"])},
      "Students_Estimation_by_Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Estimation by Month"])},
      "Final_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Reports"])},
      "Subjects_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects not Assign"])},
      "Marks_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marks not Assign"])},
      "Specific_Years_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Certificate"])},
      "Specific_Years_English_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Certificate EN"])},
      "Years_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year First Place Students"])},
      "Year_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Marks"])},
      "Daily_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance Report"])},
      "Semester_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Reports"])},
      "Semester_Optional_Subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Optional Subjects"])},
      "Specific_Semester_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Certificate"])},
      "Specific_Semester_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Certificate EN"])},
      "Semester_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Marks"])},
      "Final_Exams_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Exam Marks"])},
      "Total_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Marks"])},
      "Subjects_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects First Place Students"])},
      "Exams_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams Reports"])},
      "Student_Statement_Seat_Secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Seat and Secret Number"])},
      "Student_Without_Seat_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Students Without Seat or Secret Number"])},
      "Committees_Distribution_Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees Distribution Plan"])},
      "Secret_No_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Numbers"])},
      "Student_Seat_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Numbers"])},
      "Exams_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams Schedule"])},
      "Correction_by_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction by Secret Number"])},
      "Committees_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees Statement"])},
      "Schedule_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule Reports"])},
      "Study_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Schedule"])},
      "Study_Schedule_by_Teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Schedule by Teacher"])},
      "Teachers_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers Information"])},
      "Students_Subscribed_Buses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Subscribed in Buses"])},
      "Buses_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buses Information"])},
      "System_Users_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Users"])},
      "Specific_Group_Members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Group Members"])},
      "Specific_Group_Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Group Permissions"])}
    },
    "student": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student name"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
      "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Student"])},
      "students_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students information"])},
      "student_ar_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student arabic name"])},
      "student_en_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student english name"])},
      "parent_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent relationship"])},
      "parent_relation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student parent relationship"])},
      "student_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Image"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch"])},
      "registration_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration form"])},
      "parent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent information"])},
      "student_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student information"])},
      "student_academic_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic info"])},
      "student_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student documents"])},
      "students_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student count"])},
      "student_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by student name"])},
      "students_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Student count"])},
      "is_regestered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regestered ?"])},
      "is_continued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continued ?"])},
      "student_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
      "study_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Mode"])}
    },
    "school_data": {
      "governmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governmental"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Note"])},
      "school-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Data"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
      "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorates"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorates"])},
      "school_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School data"])},
      "school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School name"])},
      "placeholder_school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter school name"])},
      "school_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School english name"])},
      "placeholder_school_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter school english name"])},
      "establish_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establish date"])},
      "ministry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry name"])},
      "placeholder_ministry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter ministry name"])},
      "education_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education office"])},
      "placeholder_education_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter education office"])},
      "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License number"])},
      "placeholder_license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter license number"])},
      "school_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School type"])},
      "civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civil"])},
      "headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headmaster"])},
      "placeholder_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the headmaster name"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School location"])},
      "placeholder_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the school location"])},
      "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School coordinates"])},
      "placeholder_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter school coordinates"])},
      "coordinates_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location image"])},
      "students_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students type"])},
      "school_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School logo"])},
      "boys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boys"])},
      "girls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girls"])},
      "main_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main data"])},
      "location_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location data"])},
      "ministry_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry data"])},
      "contact_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact data"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "contact_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Type"])},
      "license_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License image"])},
      "select_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select image"])}
    },
    "branch_data": {
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Note"])},
      "location_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location data"])},
      "license_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License image"])},
      "coordinates_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location image"])},
      "students_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students type"])},
      "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License number"])},
      "placeholder_license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter license number"])},
      "contact_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact data"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "contact_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact type"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorates"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
      "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorates"])},
      "main_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main branch"])},
      "main_branch_must_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It must be one main branch"])},
      "placeholder_branch_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the branch coordinates"])},
      "branch_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch coordinates"])},
      "placeholder_branch_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the branch place"])},
      "branch_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch place"])},
      "is_main_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is the main branch"])},
      "placeholder_branch_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the branch english name"])},
      "branch_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch english name"])},
      "placeholder_branch_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the branch arabic name"])},
      "branch_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch arabic name"])},
      "branch_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch image"])},
      "branch_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch data"])},
      "add_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add branch"])},
      "placeholder_branch_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the branch headmaster name"])},
      "branch_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch headmaster"])},
      "main_branch_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main branch data"])},
      "boys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boys"])},
      "girls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girls"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select branch"])}
    },
    "buldings_data": {
      "building_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of floors"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Note"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branches"])},
      "add_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add building"])},
      "placeholder_building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter building name"])},
      "building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["building name"])},
      "placeholder_building_caftira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the number of caftiras"])},
      "building_caftira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of caftiras"])},
      "placeholder_building_ws_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the number of WS"])},
      "building_ws_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of WS"])},
      "placeholder_building_rooms_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the number of rooms"])},
      "building_rooms_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of rooms"])},
      "placeholder_building_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the building place"])},
      "building_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["building place"])},
      "building_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Building"])}
    },
    "schedule_data": {
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study preiods"])},
      "add_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add period"])},
      "schedule_quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["period"])},
      "placeholder_schedule_quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter study period name"])},
      "schedule_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["period"])},
      "morning_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["morning period"])},
      "evening_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evening period"])},
      "schedule_start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["period start time"])},
      "schedule_end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["period end time"])},
      "schedule_timing_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start time can't be bigger than or equal end time"])},
      "schedule_timing_error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["periods time should not be interfering"])},
      "schedule_timing_error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["periods time shouldnot pass one and helf in morning period"])},
      "schedule_timing_error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["periods time shouldnot be less eight in morning period"])}
    },
    "hall_data": {
      "hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hall name"])},
      "edit_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit hall"])},
      "add_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add hall"])},
      "placeholder_hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter The Hall Name"])},
      "placeholder_hall_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the floor number"])},
      "hall_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["floor number"])},
      "placeholder_hall_seats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the number of seats"])},
      "hall_seats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seats number"])}
    },
    "assign_hall_data": {
      "hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hall name"])},
      "add_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Hall to the Plan"])},
      "edit_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change halls plan"])},
      "hall_is_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Hall Is Already Exists"])},
      "assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halls plan"])}
    },
    "weekly_schedule_data": {
      "add_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Weekly Schedule"])},
      "schedule_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["period"])}
    },
    "teacher_data": {
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arabic teacher name"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english teacher name"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nationality"])},
      "marital_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marital Status"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Place"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Children"])},
      "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
      "work_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Period"])},
      "job_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job ID"])},
      "date_of_employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Employment"])},
      "basic_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Teacher"])},
      "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Status"])},
      "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopped"])},
      "continus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continus"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "employment_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment Data"])},
      "personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
      "contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Data"])},
      "user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Data"])}
    },
    "assign_teacher_data": {
      "add_assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add teacher to the plan"])},
      "edit_assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change teachers plan"])},
      "teacher_class_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this teacher has been assigned to this class with this subject"])},
      "assign_teacher_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Teaching Plans"])}
    },
    "driver_data": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivers"])},
      "add_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add driver"])},
      "edit_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit_driver"])},
      "driversList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver information"])},
      "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver name"])},
      "driver_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver name"])},
      "driver_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Driver phone"])},
      "driver_phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver phone"])},
      "driver_home_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home number"])},
      "driver_home_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter home number"])},
      "card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card type"])},
      "card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
      "card_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter card number"])},
      "card_issuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Card issuance"])},
      "driver_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Age"])},
      "driver_age_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver age"])},
      "driver_martial_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martial status"])},
      "driver_martial_status_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter martial age"])},
      "driver_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "driver_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
      "card_issuance_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter card issuance place"])},
      "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card expiration"])},
      "card_issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card issuer"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter address"])}
    },
    "bus_data": {
      "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buses"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select bus"])},
      "add_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add bus"])},
      "edit_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit bus"])},
      "bus_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus information"])},
      "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
      "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
      "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Road lin"])},
      "driver_road_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver road"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus type"])},
      "type_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bus type"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus number"])},
      "number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bus number"])},
      "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat number"])},
      "seat_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter seat number"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
      "color_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter color"])},
      "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car card"])},
      "car_card_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter car card number"])},
      "teacher_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher is exist"])},
      "driver_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver is exist"])}
    },
    "student_bus_data": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students with Buses"])},
      "add_student_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add student to bus"])},
      "chose_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chose student"])},
      "chose_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chose bus"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
      "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Road lin"])},
      "driver_road_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver road"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus type"])},
      "type_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bus type"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus number"])},
      "number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bus number"])},
      "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat number"])},
      "seat_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter seat number"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
      "color_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter color"])},
      "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car card"])},
      "car_card_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter car card number"])},
      "add_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add bus"])},
      "edit_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit bus"])},
      "bus_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus information"])},
      "bus_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus fee"])},
      "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
      "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
      "is_subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribed ?"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity the students participants in activity"])},
      "max_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participant students number is max than required number for this activity"])},
      "student_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Activities"])}
    },
    "academic_year": {
      "for-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for Study Year"])},
      "current-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Year"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year"])},
      "name-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hijri Year"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Year"])},
      "select-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Hijri Year"])},
      "select-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Gregorian Year"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Academic Year "])},
      "name-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gregorian Year"])},
      "placeholder-year-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example 1444/45  "])},
      "placeholder-year-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example 2023/24  "])}
    },
    "parent": {
      "add_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Parent"])},
      "edit_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Parent"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor name"])},
      "select_parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select parent"])},
      "parent_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student parent name"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["job"])},
      "job_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter parent job"])},
      "parent_id_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent identity"])},
      "parent_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent phone number"])},
      "parentList_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ParentList information"])}
    },
    "class": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class name"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic class name"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English class name"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Type "])},
      "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Phase"])},
      "elementary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ُElementary"])},
      "preparatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparatory"])},
      "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary"])},
      "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
      "dedicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedicated"])},
      "ministerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is this class ministerial ?"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Select class"])}
    },
    "division": {
      "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["division"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Add New Division"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division Name"])},
      "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division Symbol"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Division "])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisions not found"])}
    },
    "document": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Document "])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Name"])},
      "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter document name"])},
      "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document initialize"])},
      "place_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Issued"])},
      "place_issued_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Place Issued"])},
      "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release Date"])},
      "current_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current file"])},
      "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no document ! "])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The document is optional for the class ? "])},
      "add_to_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add class documents  "])}
    },
    "semester_data": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add New Semester "])},
      "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester "])},
      "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arabic semester name"])},
      "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english semester name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" select semester  "])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" semester months "])},
      "num-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of months"])},
      "current_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current semester"])}
    },
    "month": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Months Guide"])},
      "add_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add month"])},
      "edit_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit month"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months"])},
      "name_h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Hijri Month"])},
      "name_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Gregorian Month"])},
      "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter month name"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month name"])},
      "cureent_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current month"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" select month "])},
      "cureent_month_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there must be one current month"])}
    },
    "day": {
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
      "day_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day off"])},
      "add_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add day"])},
      "day_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the day name"])},
      "day_overload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days count can't exceed seven days"])}
    },
    "subject": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select subject "])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add School Subjects"])},
      "subjects_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study subjects "])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject type"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic subject name"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English subject name"])},
      "max_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Max score "])},
      "min_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Min score "])},
      "add_to_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add subject to the total ?"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Optional"])},
      "obligatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatory"])},
      "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects not found "])}
    },
    "teacher": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select teacher"])}
    },
    "semester": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select semester"])}
    },
    "lecture": {
      "preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson preparing"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture name"])},
      "select_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select teacher"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select lecture"])}
    },
    "homework": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new homework"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View HomeWorks"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homework name"])},
      "select_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select semester"])},
      "select_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select subject"])},
      "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time "])},
      "select_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select teacher"])},
      "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked ?"])},
      "correction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correcting homeworks "])}
    },
    "activity": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new activity "])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity name "])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select activity"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity type"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start at"])},
      "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end at"])},
      "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity responsible"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Location"])},
      "is_subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribed ?"])},
      "is_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Paid ؟"])}
    },
    "monthly_marks": {
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
      "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oral"])},
      "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigments"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
      "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])}
    },
    "semester_marks": {
      "collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collector"])},
      "final_exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Exam"])}
    },
    "estimate": {
      "add_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add estimate"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic estimate name"])},
      "name_ar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter estimate"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English estimate name"])},
      "name_en_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter estimate "])},
      "degree_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAx score"])},
      "degree_max_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter max score"])},
      "degree_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min score"])},
      "degree_min_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter min score"])}
    },
    "country": {
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic country name"])},
      "placeholder_country_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter country arabic name"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English country name"])},
      "placeholder_country_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter country english name"])},
      "nationality_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality arabic name"])},
      "placeholder_nationality_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter nationality arabic Name"])},
      "nationality_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality english name"])},
      "placeholder_nationality_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter nationality english name"])},
      "add_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add country"])}
    },
    "governorate": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Add new governorate"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate name"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic governorate name"])},
      "placeholder_governorate_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter governorate arabic name"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English governorate name"])},
      "placeholder_governorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter governorate english name"])}
    },
    "directorate": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add directorate"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Directorate Name"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic directorate name"])},
      "placeholder_directorate_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter directorate arabic name"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English directorate name"])},
      "placeholder_directorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter directorate english name"])}
    },
    "fees": {
      "installment_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installmentable"])},
      "subscribe_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe type"])},
      "reducible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reducible"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "fee_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee types"])},
      "fees_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee items"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity fee"])},
      "add_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add discount categories"])},
      "discount_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount category"])},
      "placeholder_discount_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a discount category"])},
      "discount_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount type"])},
      "discount_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount amount"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])}
    },
    "student_fees": {
      "add_student_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add fees to the student"])},
      "days_between_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of days between installments "])},
      "days_between_installments_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter number of days"])},
      "installments_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installments number"])},
      "installments_number_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter installments number"])},
      "installment_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay student's installment"])},
      "installment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment date"])},
      "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installments"])},
      "installment_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid amount"])},
      "installment_remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remain amount"])},
      "add_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add payment"])},
      "show_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show payments"])},
      "payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment amount"])},
      "who_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement"])},
      "no_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fees have been assigned to the student"])},
      "installment_still": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining cash on the student is "])}
    },
    "student_attendance": {
      "permission_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Data"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
      "is_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present ?"])},
      "absent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absent ?"])},
      "is_permitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Permission ?"])},
      "permission_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Applicant"])},
      "permission_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Mobile"])},
      "permission_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Reason"])},
      "permission_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Date"])},
      "permission_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Type"])},
      "after_brake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance After Brake"])}
    },
    "exams": {
      "examPeriod_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam period"])},
      "examPeriod_start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam start at"])},
      "examPeriod_start_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time of exam"])},
      "examPeriod_end_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam end at"])},
      "examPeriod_end_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time of exam"])},
      "schedule_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
      "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam date"])},
      "exam_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam day"])},
      "title_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam committees"])},
      "add_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add committee"])},
      "committeesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees list"])}
    },
    "seat_number": {
      "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat number"])},
      "public_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public number"])},
      "dist_dynamically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute dynamically"])},
      "secret_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret number"])}
    },
    "weekly_schedule": {
      "pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM"])},
      "am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AM"])},
      "no_periods_am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no morning periods "])},
      "no_periods_pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no evening periods "])}
    },
    "settings": {
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "school_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School system settings"])},
      "school_system_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System for schools"])},
      "summer_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer center settings"])},
      "summer_system_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System for Summer centers"])},
      "current_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current year"])},
      "current_year_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the button to add new current year"])},
      "current_year_placholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select current year"])},
      "main_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main settings"])},
      "date_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date settings"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Settings"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Type"])},
      "attendance_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Attendance per Day"])},
      "hijri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hijri"])},
      "gregorian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gregorian"])},
      "once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once"])},
      "twice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twice"])}
    },
    "committee": {
      "committee_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee hall"])},
      "committee_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter committee name"])},
      "committee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee name"])},
      "halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halls"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select committee "])}
    },
    "elevation": {
      "student_elevate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student elevate"])},
      "current_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current grade"])},
      "next_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next grade"])},
      "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimate"])},
      "registeration_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registeration status"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student status"])},
      "study_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study status"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total grades"])},
      "elevate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elevate"])}
    },
    "dashboard": {
      "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Students"])},
      "student_reg_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Registered Students"])},
      "teacher_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Teachers"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
      "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
      "students_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Statistics"])},
      "students_stat_by_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Statistics by Classes"])},
      "teachers_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Statistics"])},
      "save-as-png": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save as png"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Males"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Females"])}
    },
    "reports": {
      "in_the_name_of_allah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In The Name Of Allah"])},
      "rep_of_yemen_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of Yemen"])},
      "prime_minister_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Ministry of Education"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
      "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["showing"])},
      "select_criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Criteria"])},
      "select_parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select parent"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])},
      "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print the Report"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "student_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["class"])},
      "fee_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Type"])},
      "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Fee"])},
      "installment_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment Paid"])},
      "installment_remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment Remain"])},
      "debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit"])},
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
      "select_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select class"])},
      "select_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select student"])},
      "select_fee_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select fee type"])},
      "select_division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select division"])},
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Statement"])},
      "installment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment Date"])},
      "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installments"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "still": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Still"])},
      "total_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Paid"])},
      "clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clause"])},
      "parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Name"])},
      "study_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Year"])},
      "parent_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Phone Number"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "select_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select bus"])},
      "bus_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus Fee"])},
      "required_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Required"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
      "paid_from_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid From Installment"])},
      "total_after_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total after Discount"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "class_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Name"])},
      "division_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division Name"])},
      "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus/Bus Route"])},
      "for_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for Year"])},
      "for_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for Class"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate"])},
      "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Country"])},
      "select_governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Governorate"])},
      "select_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Nationality"])},
      "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Age"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Email"])},
      "student_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Image"])},
      "New_Enrolled_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Enrolled Students"])},
      "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Initialize"])},
      "home_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Number"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "activity_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Name"])},
      "select_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Month"])},
      "subjects_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Subjects "])},
      "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
      "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])},
      "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oral"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
      "select_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Subject"])},
      "select_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select semester"])},
      "collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collector"])},
      "final_exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Exam"])},
      "writing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["writing"])},
      "count_of_actual_lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classes"])},
      "count_of_attendence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
      "count_of_absentce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absent"])},
      "count_of_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days with Permission"])},
      "count_of_not_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days without Permission"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
      "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate "])},
      "total_degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Degree"])},
      "attendance_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance Marks"])},
      "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marks"])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Name"])},
      "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester "])},
      "select_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Estimate"])},
      "student_name_male/female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
      "attendance_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance Date"])},
      "is_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is Attend?"])},
      "is_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is Permitted ?"])},
      "Month_Certificate_En_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Certificate"])},
      "Specific_Year_Certificate_En_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year Certificate"])},
      "Specific_Semester_Certificate_En_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Certificate"])},
      "mark_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Marks"])},
      "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division"])},
      "study_year_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Year"])},
      "semester_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester"])},
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
      "select_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Branch"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
      "student_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student ID"])},
      "select_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Committee"])},
      "committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee"])},
      "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Number"])},
      "secret_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Number"])},
      "committee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee Name"])},
      "student_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Number"])},
      "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
      "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
      "select_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Teacher"])},
      "teacher_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Information"])},
      "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" First with Surname"])},
      "teacher_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" teacher_status"])},
      "teacher_specail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Expertise"])},
      "teacher_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Address"])},
      "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorate"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single"])},
      "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Married"])},
      "bus_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus Number"])},
      "driver_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Driver Phone"])},
      "bus_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus Type"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
      "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plate Number"])},
      "teacher_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Phone"])},
      "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver Name"])},
      "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus Route"])},
      "bus_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus Color"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name "])},
      "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Active?"])},
      "date_joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Joined"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Group"])},
      "permission_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Name"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "attend_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance Date"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natioanality"])},
      "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jop"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "subject_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject_Name"])},
      "net_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Total"])},
      "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Students"])},
      "general_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Estimate"])},
      "lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost"])},
      "activity_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Location"])},
      "activity_responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Responsible"])},
      "numbering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
      "seat_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Seats"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Period"])},
      "study_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student State"])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "save_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and send"])},
      "update_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update and send"])}
    }
  },
  "global": {
    "error": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error !"])},
      "validation": {
        "must_be_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be Arabic letters only !"])},
        "max_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the maximum number"])},
        "min_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the minimum number"])},
        "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be only numeric entered "])},
        "numbers_and_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this field must contain letters and numbers"])},
        "max-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure that the field does not exceed the maximum characters limit ."])},
        "min-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure that the field is not below the minimum characters limit ."])},
        "arabic-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only Arabic letter must br entered"])},
        "english-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only English letter must br entered"])},
        "required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Field"])},
        "must_be_ar_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be arabic letters only !"])},
        "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the maximum number of characters"])},
        "must_be_en_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be english letters only !"])},
        "max_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the maximum date allowed is "])},
        "min_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the minimum date allowed is "])},
        "must_be_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be numbers only !"])},
        "max_numbers_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the maximum value"])},
        "min_numbers_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the minimum value"])},
        "lowest_number_of_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum number of characters is "])},
        "biggest_number_of_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum number of characters is "])},
        "max_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure that the field does not exceed the maximum characters limit ."])},
        "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start date greater than the end date ."])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end date less than the start date ."])},
        "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This records is already exists"])},
        "unique_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This schedule has been added before"])},
        "degree_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum degree can't be more than minimum degree"])},
        "already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter non exist data"])},
        "fee_types_bus_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't add more than one bus fee type"])},
        "percentage_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage must not exceed 100"])},
        "discount_is_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the discount in not correct"])},
        "max_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure that the field exceed the number of months"])},
        "sequential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should the months sequential"])},
        "deadline_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The deadline date must be more than todays date"])},
        "ExamPeriod_timing_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter the time correctly"])},
        "fee_class_exceist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this class and fee type already exist"])},
        "imageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Image Size Should be Is"])},
        "expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the expiration date must not less than the issuer date "])},
        "issuer_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the issuer date must not exceed the expiration date "])},
        "interfering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimate values must not interfere with estimate"])},
        "group_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group with this name already exists"])},
        "division_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this division and class has already assigned"])}
      },
      "failure": {
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
        "error_in_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, review inserted Data"])},
        "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is not found record with this data"])},
        "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No connection with the database"])},
        "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry failed"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Faield "])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Faield "])},
        "cureent_month_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There must be one current month"])},
        "errpr_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't delete the current month"])},
        "fill_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fil previous field"])},
        "user_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this email. already used"])},
        "main_branch_must_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It must be one main branch"])},
        "user-logged-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! user is already logged in "])},
        "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email or password error"])},
        "parent_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this parent can't be deleted because there are students connected to him"])},
        "data_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can't delete this data because it connected to existing data"])},
        "branch_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Month Is not Connected to the Current Semester"])},
        "month_not_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Month Is Not Connected to the Semester"])},
        "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! Not Have Branch With The User"])},
        "cant_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permission to update this record"])},
        "cant_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have permission to delete this record"])},
        "user_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in the user account"])},
        "protected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record can't be deleted because it's used in other places"])},
        "period_is_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this period is used before"])},
        "committee_excest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This committee and class are already used"])},
        "unique_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["committee already exists"])},
        "hall_excist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this hall is already used"])},
        "hall_exist_in_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this hall is already used in this building"])},
        "student_number_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the maximum number of students for this class"])},
        "seat_number_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is a duplicated number"])},
        "secret_number_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is a duplicated number"])},
        "must_be_insert_seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must enter a seating numbers first"])},
        "three_choices_cannot_be_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you don't have three choices"])},
        "no_homeworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is no homeworks"])},
        "no_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is no students in the class"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no data"])},
        "has_marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can't delete a student because he has marks"])},
        "cant_update_assigned_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't change this fee because it was assigned to a student"])}
      },
      "warning": {
        "not_all_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not save all data due to duplication"])}
      }
    },
    "alert": {
      "documents_per_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for this class you need these documents"])},
      "student_count_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have exceeded the number of student allowed in this division"])},
      "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure of deleting this record ?"])},
      "can_not_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can Not be Deleted"])},
      "can_not_delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are Some Records Depend on this Record"])},
      "show_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Details"])},
      "hide_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Details"])}
    },
    "success": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success  ^_^"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success "])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry failed"])},
      "data_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data is updated Successfully"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion Faield "])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving data is successfully "])},
      "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure in create backup "])},
      "data_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data is added successfully"])},
      "data_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data is saved successfully"])},
      "data_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data is deleted successfully"])},
      "student_elevated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students are elevated successfully"])}
    },
    "warning": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
      "max_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant students number is max than required number for this activity "])}
    },
    "personal_information": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic name"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English name"])},
      "name_ar_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter arabic name"])},
      "name_en_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter english name"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "phone_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter phone number"])},
      "home_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home number"])},
      "home_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter home number"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natioanality"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate"])},
      "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter address"])},
      "id_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity type"])},
      "id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity number"])},
      "id_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Identity number"])},
      "birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth place"])},
      "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])}
    },
    "data_table": {
      "search_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Search Here"])},
      "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items per page "])},
      "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "student_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student not found with this name "])}
    },
    "model": {
      "Lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectures"])},
      "TeacherSubjectClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers Plan"])},
      "Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
      "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
      "Teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
      "Building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buildings"])},
      "ClassDivisionHall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halls Plan"])},
      "Homework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homework"])},
      "ExamsPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Periods"])},
      "ExamSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Schedules"])},
      "DiscountCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Categories"])},
      "FeeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Types"])},
      "Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
      "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
      "Committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees"])},
      "SeatNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student connections to committees"])},
      "SchoolWeeklySchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Schedules"])},
      "StudentSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Marks"])},
      "StudentHomework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homework Marks"])},
      "Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Marks"])},
      "StudentFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Fees"])},
      "InstallmentsPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installments payments"])},
      "PaymentsReciept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Receipt"])},
      "StudentDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Documents"])},
      "Driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivers"])},
      "Bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buses"])},
      "SettingsStudentCommittees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees Plans"])},
      "StudentBus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Participated in Bus"])},
      "Hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halls"])},
      "ClassDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Documents"])},
      "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorates"])},
      "StudentAttendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Attendance"])}
    },
    "month": {
      "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يناير"])},
      "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فبراير"])},
      "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مارس"])},
      "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابريل"])},
      "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مايو"])},
      "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يونيو"])},
      "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوليو"])},
      "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اغسطس"])},
      "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبتمير"])},
      "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتوبر"])},
      "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوفمبر"])},
      "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ديسمبر"])},
      "Muharram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محرم"])},
      "Safar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفر"])},
      "Rabi al-Awwal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربيع اول"])},
      "Rabi al-Thani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربيع ثاني"])},
      "Jumada al-Awwal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جماد اول"])},
      "Jumada al-Thani": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جماد ثاني"])},
      "Rajab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجب"])},
      "Shaban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعبان"])},
      "Ramadan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمضان"])},
      "Shawwal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شوال"])},
      "Dhu al-Qadah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذو القعدة"])},
      "Dhu al-Hijjah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذو الحجة"])}
    },
    "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers"])},
    "letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letters"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "notes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Notes"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attention"])},
    "current-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be there is one current year"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
    "view_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view filters"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" loading ..."])},
    "confirm-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this record ?"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
    "pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM"])},
    "am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AM"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
    "remember_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN"])},
    "exit_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do You Want to Sign Out from School Management System ?"])},
    "login_account_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Login To Your School Account"])}
  },
  "users": {
    "user": {
      "adduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
      "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
      "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name "])},
      "mname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Middle Name"])},
      "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name  "])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "superuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superuser"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name"])},
      "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
      "placeholder_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
      "date_joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of joined"])},
      "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "is_deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    },
    "group": {
      "add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manage groups"])}
    },
    "permission": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groups"])},
      "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activity"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branches"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buildings"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classes"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
      "classdivisionhall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classes plan"])},
      "classdocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["class documents"])},
      "classstudentyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["students elevate"])},
      "classsubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam committees"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countries"])},
      "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["directorates"])},
      "discountcategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount"])},
      "distributionofseatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seat numbers settings"])},
      "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisions"])},
      "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document"])},
      "examschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Schedule"])},
      "examsperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam periods"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee plan"])},
      "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total fee"])},
      "feetype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee types"])},
      "paymentsreciept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catch receipt"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governorates"])},
      "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halls"])},
      "homework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["homework"])},
      "installmentspayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installments"])},
      "lectureattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lesson preparation"])},
      "marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly grades"])},
      "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parents"])},
      "parentmessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent messages"])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study preiods"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school data"])},
      "schoolweeklyschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weekly schedule"])},
      "seatnumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seat numbers"])},
      "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semester"])},
      "settingsstudentcommittees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["committees plan"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student"])},
      "studentactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activity enrollment"])},
      "studentattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student attendance"])},
      "studentdocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student document"])},
      "studentfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assing fees"])},
      "studenthomework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check homeworks"])},
      "studentsubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semester grades"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subjects"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teachers"])},
      "teachersubjectclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teachers plan"])},
      "yearofstudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study years"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])},
      "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drivers"])},
      "studentbus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bus subscription"])},
      "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buses"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study months"])},
      "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimations"])},
      "is_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
      "is_permitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission name"])},
      "lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectures"])}
    }
  }
}