<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <!-- <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_group"
            density="compact"
            item-title="name"
            item-value="pk"
            prepend-inner-icon="mdi-calendar"
            :items="groups"
            :label="$t('school.reports.select_group')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            @update:model-value="
              (filter_data.fk_group = null),
                getAllGroups(filter_data.fk_branch?.id)
            "
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_group"
            density="compact"
            item-title="name"
            item-value="pk"
            prepend-inner-icon="mdi-calendar"
            :items="
              getUserRole < 2 ? (filter_data.fk_branch ? groups : []) : groups
            "
            :label="$t('school.reports.select_group')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <!-- print data  -->
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu @toggle-visibility="() => (exist = !exist)"></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <tr>
                <th rowspan="2" class="border">#</th>
                <th rowspan="2" class="border">
                  {{ $t("school.reports.permission_name") }}
                </th>
                <th colspan="4" class="border">
                  {{ $t("school.reports.permissions") }}
                </th>
              </tr>
              <tr>
                <th class="border">{{ $t("school.reports.add") }}</th>
                <th class="border">{{ $t("school.reports.change") }}</th>
                <th class="border">{{ $t("school.reports.delete") }}</th>
                <th class="border">{{ $t("school.reports.view") }}</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead?.includes('no')"
                >
                  {{ (page - 1) * perPage + key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead?.includes('name')"
                >
                  <!-- {{ item.model_name }} -->
                  {{ $t("users.permission." + removeSpace(item.model_name)) }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead?.includes('permissions')"
                >
                  <div class="d-flex align-center justify-center">
                    <v-checkbox
                      disabled
                      color="indigo"
                      hide-details="true"
                      density="compact"
                      v-bind:model-value="
                        getIsHasPermission(item.permission[0].id)
                      "
                    ></v-checkbox>
                  </div>
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead?.includes('permissions')"
                >
                  <div class="d-flex align-center justify-center">
                    <v-checkbox
                      disabled
                      color="indigo"
                      hide-details="true"
                      density="compact"
                      v-bind:model-value="
                        getIsHasPermission(item.permission[1].id)
                      "
                    ></v-checkbox>
                  </div>
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead?.includes('permissions')"
                >
                  <div class="d-flex align-center justify-center">
                    <v-checkbox
                      disabled
                      color="indigo"
                      hide-details="true"
                      density="compact"
                      v-bind:model-value="
                        getIsHasPermission(item.permission[2].id)
                      "
                    ></v-checkbox>
                  </div>
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead?.includes('permissions')"
                >
                  <div class="d-flex align-center justify-center">
                    <v-checkbox
                      disabled
                      color="indigo"
                      hide-details="true"
                      density="compact"
                      v-bind:model-value="
                        getIsHasPermission(item.permission[3].id)
                      "
                    ></v-checkbox>
                  </div>
                </td>
                <!-- <td
                  class="text-center border"
                  v-if="selectedHead?.includes('permissions')"
                >
                  <div v-for="permission in item.permission" :key="permission.id">
                    {{ getIsHasPermission(permission.id) }}
                  </div>
                </td> -->
                <!-- <td class="text-center border" v-if="selectedHead?.includes('role')">{{item.role}}</td> -->
              </tr>
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updateAuthenticated",
        localStorage.getItem("token")
      );
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        await this.getAllGroups();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      permissions: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: ["no", "name", "permissions"],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("permission.name"), key: "name" },
        { title: this.$t("permission.permissions"), key: "permissions" },
        // {title:this.$t('user.role'),key:"role"},
        // {title:this.$t('user.email'),key:"email"},
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        fk_group: null,
      },
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
      groups: (state) => state.school.groups,
      // user: (state) => state.User,
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead?.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },

  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
      getAllGroups: "school/getAllGroups",
    }),
    // getGroupName(group_id) {
    //   const group = this.groups.find((group) => group.pk === group_id);
    //   return group.name;
    // },
    removeSpace(words) {
      return words.replace(/\s/g, "");
    },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    getGroupPermission(group_id) {
      if (group_id) {
        const group = this.groups.find((group) => group.pk === group_id);
        this.permissions = group.permissions;
      }
    },
    getIsHasPermission(permission_id) {
      return this.permissions.includes(permission_id);
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },

    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/permession_report`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // search: this.txt_search,
            page: page,
            page_size: this.perPage,
            group: this.filter_data.fk_group?.pk,
            fk_branch: this.filter_data?.fk_branch?.id,
          },
        })
        .then((response) => {
          if (response.data.results) {
            this.items = response.data.results;
            this.exist = true;
            this.totalItems = response.data.pagination.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
            this.getGroupPermission(this.filter_data.fk_group?.pk);
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },

  // validations() {
  //   return {
  //     filter_data: {
  //       fk_group: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
td .v-input__control {
  justify-content: center !important;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
