<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '5' : '2'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_month"
            density="compact"
            :item-title="date_type == 'm' ? 'name_m' : 'name_h'"
            item-value="id"
            prepend-inner-icon="mdi-calendar"
            :items="getCurrentSemesterMonths"
            :label="$t('school.reports.select_month')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '4' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes_by_branch"
            :label="$t('school.reports.select_class')"
            @update:model-value="filter_data.fk_subject = null"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>

        <!-- <v-col cols="6" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_semester"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-book-education"
            :items="semesters"
            :label="$t('school.reports.select_semester')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->

        <v-col cols="6" :md="getUserRole < 2 ? '4' : '3'" sm="12">
          <!-- :style="getUserRole < 2 ? '' : 'flex:0 0 24%;max-width:24%'" -->
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_subject"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-bookshelf"
            :items="class_subjects"
            :label="$t('school.reports.select_subject')"
            hide-details="auto"
            return-object
            clearable
          />
        </v-col>

        <v-col cols="6" md="2" sm="12">
          <!-- <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_subject"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-bookshelf"
            :items="class_subjects"
            :label="$t('school.reports.select_subject')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          /> -->
          <v-text-field
            v-model="perPage"
            hide-details="auto"
            type="number"
            density="compact"
            clearable
            :rules="[$max_value(20)]"
            :label="$t('school.reports.student_count')"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="key">
                <td
                  class="text-center border"
                  v-for="(header, index) in selectedHeaders"
                  :key="index"
                >
                  <template v-if="index === 0">
                    {{ key + 1 }}
                  </template>
                  <template v-else>
                    {{ item[header.key] != null ? item[header.key] : "-" }}
                  </template>
                  <!-- <template v-if="header.key == 'no' && key > 0">
                    {{
                      items[key - 1]["total_marks"] !== item["total_marks"]
                        ? key + 1
                        : key
                    }}
                  </template> -->
                </td>
              </tr>
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('name_ar')"
                >
                  {{ item.name_ar }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('total_marks')"
                >
                  {{ item.total_marks }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('note')"
                >
                  {{ item.note }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBranchClasses();
      await this.getSemesters();
      await this.getSubjects({});
      await this.getAllMonths();
      await this.getBrenches({});
      //   await this.getattendances();
      //   await this.getDivisions({});

      //   await this.axios
      //     .get(`${this.base_url}school-data/`, {
      //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //     })
      //     .then((response) => {
      //       this.school = response.data;
      //     });
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      //   school: [],
      //   branch: {},
      //   attendances: [],
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      date_type: localStorage.getItem("date"),
      //   txt_search: "",
      selectedHead: [
        "no",
        "name_ar",
        "total_marks",
        "rank",
        `${localStorage.getItem("role") < 2 ? "branch_name" : ""}`,
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "name_ar" },
        { title: this.$t("school.reports.branch"), key: "branch_name" },
        { title: this.$t("school.reports.mark_total"), key: "total_marks" },
        { title: this.$t("school.reports.order"), key: "rank" },
      ],
      perPage: 10,
      //   length: 0,
      totalItems: 0,
      //   itemsPerPage: [
      //     {
      //       value: 5,
      //       text: "5",
      //     },
      //     {
      //       value: 10,
      //       text: "10",
      //     },
      //     {
      //       value: 25,
      //       text: "25",
      //     },
      //     {
      //       value: 50,
      //       text: "50",
      //     },
      //     {
      //       value: 75,
      //       text: "75",
      //     },
      //     {
      //       value: 100,
      //       text: "100",
      //     },
      //     {
      //       value: "",
      //       text: "all",
      //     },
      //   ],
      page: 1,
      //   pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      //   class_assign_hall: (state) => state.school.class_assign_hall,
      //   divisions: (state) => state.school.divisions,
      classes_by_branch: (state) => state.school.classes_by_branch,
      semesters: (state) => state.school.semesters,
      months: (state) => state.school.months,
      subjects: (state) => state.school.subjects,
      branch: (state) => state.school.branch,
    }),

    ...mapGetters({
      getSubjectName: "school/getSubjectName",
      getCurrentSemesterMonths: "school/getCurrentSemesterMonths",
      //   getClassDivisions: "school/getClassDivisions",
    }),
    class_subjects() {
      if (this.filter_data.fk_class?.id) {
        var class_data = this.classes_by_branch.find(
          (item) => item.id == this.filter_data.fk_class?.id
        );
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },

    selectedHeaders() {
      let filtered = this.selectedHead.filter((header) => {
        if (header === "branch_name") {
          return localStorage?.getItem("role") < 2
            ? !this.filter_data.fk_branch
            : false;
        }
        return true;
      });
      return this.headers.filter((header) => filtered.includes(header.key));
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      //   getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      //   getClassAssignHall: "school/getClassAssignHall",
      getSubjects: "school/getSubjects",
      getAllMonths: "school/getAllMonths",
      getBrenches: "school/getBrenches",
    }),
    // async getattendances() {
    //   await this.axios
    //     .get(`${this.base_url}school/report/student/absent`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       this.attendances = response.data.results;
    //     });
    // },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getClassName(fk_class) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name_ar;
    // },
    // getSemesterName(fk_semester) {
    //   const year = this.semesters.find((year) => year.id === fk_semester);
    //   return year.name_ar;
    // },
    // getSubjectName(fk_subject) {
    //   const year = this.subjects.find((year) => year.id === fk_subject);
    //   return year.name_ar;
    // },
    // getMonthName(fk_subject) {
    //   const month = this.months.find((month) => month.id === fk_subject);
    //   return month.name;
    // },

    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/firstearlyinmonth/marks`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            limit: this.perPage ? this.perPage : null,
            fk_month: this.filter_data.fk_month?.id,
            fk_class: this.filter_data.fk_class?.id,
            fk_subject: this.filter_data.fk_subject?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // semester: this.filter_data.fk_semester?.id,
          },
        })
        .then((response) => {
          this.exist = true;

          if (response.data) {
            let count = 1;
            let rank = this.$t(`global.order.1`);
            this.items = response.data?.map((student, index, array) => {
              if (
                index > 0 &&
                array[index - 1]["total_marks"] !== student["total_marks"]
              ) {
                count++;
                rank = this.$t(`global.order.${count}`);
              } else if (index > 0) {
                rank = this.$t(`global.order.${count}`) + " " + "مكرر";
              }
              return { ...student, rank };
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // watch: {
  //   perPage() {
  //     this.getData();
  //   },
  //   page() {
  //     this.getData(this.page);
  //   },
  // },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
