<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <!-- <v-col cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes_by_branch"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:model-value="
              combinedSubject(), (filter_data.fk_subject = null)
            "
          />
        </v-col> -->
        <v-col v-if="getUserRole < 2" cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            @update:model-value="filter_data.fk_subject = null"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_subject"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-bookshelf"
            :items="
              !!filter_data?.fk_class
                ? getSubjectsByClass(
                    filter_data?.fk_class?.id,
                    classes_by_branch
                  )
                : []
            "
            :label="$t('school.reports.select_subject')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <template v-for="(item, index) in items" :key="item.id">
                <tr v-for="(sub, subIndex) in item?.data" :key="sub">
                  <template v-if="subIndex === 0">
                    <td
                      v-if="selectedHead.includes('no')"
                      :rowspan="item?.data?.length"
                      class="text-center border"
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      v-if="selectedHead.includes('class_number')"
                      :rowspan="item?.data?.length"
                      class="text-center border"
                    >
                      {{ item.class_number }}
                    </td>
                    <td
                      v-if="selectedHead.includes('subject_name')"
                      :rowspan="item?.data?.length"
                      class="text-center border"
                    >
                      {{ item.subject_name }}
                    </td>
                  </template>

                  <td
                    v-for="header in selectedHeaders?.filter(
                      (head) =>
                        head.key != 'no' &&
                        head.key != 'class_number' &&
                        head.key != 'subject_name'
                    )"
                    :key="header.key"
                    class="text-center border"
                  >
                    {{ sub[header.key] != null ? sub[header.key] : "-" }}
                  </td>
                </tr>
                <tr></tr>
              </template>
              <!-- <tr v-for="(item, index) in items[0].data" :key="index">
                <td class="text-center border">
                  {{
                    parseInt(item.secretnumber) >= 0
                      ? item.secretnumber
                      : $i18n.locale == "ar"
                      ? "لا يوجد"
                      : "NaN"
                  }}
                </td>
                <td class="text-center border">
                  {{
                    parseInt(item.mark) >= 0
                      ? item.mark
                      : $i18n.locale == "ar"
                      ? "لا يوجد"
                      : "NaN"
                  }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updateAuthenticated",
        localStorage.getItem("token")
      );
      await this.getBranchClasses();
      await this.getSubjects({});
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // school: [],
      // process_alert: false,
      // subject_data: [],
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: [
        "no",
        "secretnumber",
        "mark",
        "subject_name",
        "class_number",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.class"), key: "class_number" },
        { title: this.$t("school.reports.subject_name"), key: "subject_name" },
        { title: this.$t("school.reports.secret_number"), key: "secretnumber" },
        { title: this.$t("school.reports.mark"), key: "mark" },
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 5, text: "5" },
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      classes_by_branch: (state) => state.school.classes_by_branch,
      subjects: (state) => state.school.subjects,
      branch: (state) => state.school.branch,
    }),

    ...mapGetters({
      getSubjectsByClass: "school/getSubjectsByClass",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getSubjects: "school/getSubjects",
      getBrenches: "school/getBrenches",
    }),
    // addLineBreak(text) {
    //   var formattedText = text.replace(/@/g, "<br>");
    //   return formattedText;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // combinedSubject() {
    //   var foundobjectdata = this.classes.find(
    //     (obj) => obj.id == this.filter_data.fk_class?.id
    //   );
    //   this.subject_data = [];
    //   for (var i = 0; i < foundobjectdata.subjects.length; i++) {
    //     var currentItem = foundobjectdata.subjects[i];
    //     var matchingObject = this.subjects.find(function (obj) {
    //       return obj.id === currentItem;
    //     });
    //     if (matchingObject) {
    //       this.subject_data.push(matchingObject);
    //     }
    //   }
    // },

    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/correction-with-secretNumber`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: page,
            page_size: this.perPage,
            class_number: this.filter_data.fk_class?.id,
            subject: this.filter_data.fk_subject?.id,
          },
        })
        .then((response) => {
          if (response.data?.results) {
            this.items = response.data?.results;
            this.totalItems = response.data?.pagination?.count;
            this.exist = true;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       fk_class: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       subjects: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
