<template>
  <VCard class="justify-center">
    <!-- ##########    data Table  ########### -->

    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-text-field
          color="indigo"
          v-model="text_search"
          @keyup.enter="getHalls((page = 1))"
          @input="!text_search ? getHalls((page = 1)) : ''"
          class="search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          :placeholder="$t('global.data_table.search_here')"
          density="compact"
          variant="text"
        >
        </v-text-field>
        <div>
          <VBtn
            v-if="CheckGetPermission('school.add_hall')"
            class="bg-success"
            @click="addHall"
          >
            <span class="text-white">{{ $t("global.create") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
        </div>
      </div>
      <!-- </div> -->
      <!-- <v-progress-linear
        color="indigo"
        class="my-1"
        :indeterminate="loading"
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear> -->
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table-server
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        fixed-footer
        :items-per-page="perPage"
        v-model:sort-by="sortBy"
        @update:options="getHalls((page = 1))"
        density="compact"
        class="elevation-1"
        :loading="loading"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_hall')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editHall(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_hall')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="(hall_del_id = item.raw.id), (del_dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ totalItems }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table-server>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (hall_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteHall">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      del_dialog: false,
      hall_del_id: undefined,
      perPage: 10,
      // length: 0,
      halls: [],
      page: 1,
      // pagination: {},
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 20, text: "20" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 200, text: this.$t("global.data_table.all") },
      // ],
      sortBy: [{ key: "id", order: "asc" }],
      headers: [
        {
          title: this.$t("school.buldings_data.building_name"),
          key: "fk_building",
        },
        {
          title: this.$t("school.hall_data.placeholder_hall_name"),
          key: "name",
        },
        {
          title: this.$t("school.hall_data.hall_seats_number"),
          key: "seats_number",
        },
        {
          title: this.$t("school.hall_data.hall_floor_number"),
          key: "floor_number",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "fk_building",
        "name",
        "seats_number",
        "floor_number",
        "note",
        "actions",
      ],
      text_search: undefined,
      loading: false,
      totalItems: null,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.building.length) await this.getBuilding({ all: true });
      await this.getHalls();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      building: (state) => state.school.building,
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      this.halls.forEach((element) => {
        let fk_building = this.building.find(
          (item) => item.id == element.fk_building
        );
        if (fk_building != undefined) fk_building = fk_building.name;
        list.push({
          fk_building: fk_building,
          name: element.name,
          seats_number: element.seats_number,
          floor_number: element.floor_number,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getBuilding: "school/getBuilding",
      changeCurrentPage: "school/changeCurrentPage",
      // getHall: "school/getHall",
    }),
    addHall() {
      this.changeCurrentPage({ page: this.length, count: this.perPage });
      this.$router.push("add");
    },
    editHall(id) {
      this.changeCurrentPage({ page: this.page, count: this.perPage });
      this.$router.push(`${id}/edit/`);
    },
    async deleteHall() {
      if (this.hall_del_id != undefined) {
        await this.axios
          .delete(`${this.base_url}/hall/${this.hall_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getHalls();
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.hall_del_id = undefined;
      }
    },
    async getHalls(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}/hall/`, {
        params: {
          search: this.text_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.halls = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getHalls();
    },
    page() {
      this.getHalls(this.page);
    },
  },
};
</script>
