<template>
  <VCard class="justify-center">
    <VForm
      ref="form"
      v-if="
        CheckGetPermission('school.add_discountcategories') ||
        (is_update && CheckGetPermission('school.change_discountcategories'))
      "
    >
      <!-- <VCard-title class="my-4">
                <VIcon icon="mdi-percent-outline"/>
                <h2 class="d-inline text-grey" style="padding:0 4px;" >{{$t('school.fees.add_discount')}}</h2>
                <v-divider style="border-width:1px; opacity: 0.5;" class="my-2"/>
            </VCard-title> -->
      <VCard-text class="card-fields">
        <VRow >
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="discount.category"
                prepend-inner-icon="mdi-format-text"
                :label="$t('school.fees.discount_category')"
                :placeholder="$t('school.fees.placeholder_discount_category')"
                density="compact"
                clearable
                :rules="[$required, $max_length()]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VRadioGroup
                class="radio-mt-0"
                inline
                :label="$t('school.fees.discount_type')"
                density="compact"
                v-model="discount.amount_type"
                @change="discount.amount ? $refs.discount.validate() : ''"
              >
                <VRadio :label="$t('school.fees.percentage')" :value="1" class="mx-4" ></VRadio>
                <VRadio :label="$t('school.fees.amount')" :value="2" class="mx-4"></VRadio>
              </VRadioGroup>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                ref="discount"
                v-model="discount.amount"
                :label="$t('school.fees.discount_amount')"
                density="compact"
                prepend-inner-icon="mdi-cash-multiple"
                :append-inner-icon="
                  discount.amount_type == 1
                    ? 'mdi-percent-outline'
                    : 'mdi-currency-rial'
                "
                type="number"
                clearable
                :rules="[
                  $required,
                  $min_value(0),
                  discount.amount_type != 1
                    ? $max_length(7)
                    : discount_amount_rule,
                  $numeric,
                ]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        
        <VRow>
          <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="9" lg="9">
            <VCardItem class="px-3">
              <VTextarea
                v-model="discount.note"
                clearable
                :label="$t('global.note')"
                prepend-inner-icon="mdi-note-outline"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VCard-text>
    </VForm>
    <VCardActions
      class="px-8"
      v-if="
        CheckGetPermission('school.add_discountcategories') ||
        (is_update && CheckGetPermission('school.change_discountcategories'))
      "
    >
      <VBtn
        v-if="!is_update && CheckGetPermission('school.add_discountcategories')"
        class="bg-indigo"
        @click="saveDiscount"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="
          is_update && CheckGetPermission('school.change_discountcategories')
        "
        class="bg-success"
        @click="updateDiscount"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
          :color="!is_update ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ##########    data Table  ########### -->
    <v-divider class="mt-5" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_discountcategories')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editDiscount(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_discountcategories')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="
                      (discount_del_id = item.raw.id), (del_dialog = true)
                    "
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (discount_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteDiscount">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      // v$: useValidate(),
      discount: {
        category: undefined,
        amount_type: 1,
        amount: undefined,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      is_update: false,
      is_loading: false,
      del_dialog: false,
      discount_del_id: undefined,

      headers: [
        { title: this.$t("school.fees.discount_category"), key: "category" },
        { title: this.$t("school.fees.discount_type"), key: "amount_type" },
        { title: this.$t("school.fees.discount_amount"), key: "amount" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: ["category", "amount_type", "amount", "note", "actions"],
      txt_search: undefined,
      search: null,
      perPage: 10,
      page: 1,
      discount_amount_rule: (value) =>
        (this.discount.amount_type == 1 && value <= 100) ||
        this.discount.amount_type != 1 ||
        this.$t("global.error.validation.percentage_error"),
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.discounts.length) await this.getDiscount();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      discounts: (state) => state.school.discounts,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      this.discounts.forEach((element) => {
        list.push({
          category: element.category,
          amount: element.amount,
          amount_type:
            element.amount_type == 1
              ? this.$t("school.fees.percentage")
              : this.$t("school.fees.amount"),
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getDiscount: "school/getDiscount",
    }),
    resetForm() {
      this.$refs.form.reset();
      // this.v$.discount.$reset()
      this.is_update = false;
      this.discount.amount_type = 1;
    },
    editDiscount(data) {
      let discount = this.discounts.find((item) => item.id == data.id);
      if (discount != undefined) {
        for (const key in discount) this.discount[key] = discount[key];
        this.discount.data_entry = undefined;
        this.discount.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveDiscount() {
      // this.v$.$validate()
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        // this.discount.data_entry=this.user
        await this.axios
          .post(this.base_url + "/discount/", this.discount, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        // this.process_alert=true
        this.resetForm();
        this.getDiscount();
        // setTimeout(()=>{
        //     this.process_alert=false
        // },2000)
      }
      this.is_loading = false;
    },
    async updateDiscount() {
      // this.v$.$validate()
      const { valid } = await this.$refs.form.validate();
      if (valid && this.discount.id != undefined) {
        this.is_loading = true;
        // this.discount.updated_by=this.user
        await this.axios
          .put(
            `${this.base_url}/discount/${this.discount.id}/`,
            this.discount,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        // this.process_alert=true
        this.resetForm();
        this.getDiscount();
        // setTimeout(()=>{
        //     this.process_alert=false
        // },2000)
      }
      this.is_loading = false;
    },
    async deleteDiscount() {
      if (this.discount_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}/discount/${this.discount_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.resetForm();
          })
          .catch((error) => {
            console.log(error)
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.discount_del_id = undefined;
        this.getDiscount();
      }
    },
  },
};
</script>
