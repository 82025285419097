import Countries from "../views/School/init/Countries.vue";
import store from "@/store/index";
import CollectorMarkStudent from "../views/Report/CollectorMarkStudent.vue";
import ParentsInfo from "../views/Report/ParentsInfo";
import CollectorMarkStudentYear from "../views/Report/CollectorMarkStudentYear";
import CollectorMarkStudentMonth from "../views/Report/CollectorMarkStudentMonth";
import finalexammarksreport from "../views/Report/finalexammarksreport.vue";
import FirstStudentForMonth from "../views/Report/FirstStudentForMonth.vue";
import FirstStudentMonthforSubject from "../views/Report/FirstStudentMonthforSubject.vue";
import marksreportnyyear from "../views/Report/marksreportnyyear.vue";
import StudentWithParent from "../views/Report/StudentWithParent";
import ParentwithStudent from "../views/Report/ParentwithStudent";
import ParentsWithUser from "../views/Report/ParentsWithUser";
import ParentsWithContact from "../views/Report/ParentsWithContact.vue";
import NewEnrolledStudent from "../views/Report/NewEnrolledStudent";
import CollectorMarkStudentMonthEstimate from "../views/Report/CollectorMarkStudentMonthEstimate";
import StudentWithParentFees from "../views/Report/StudentWithParentFees";
import StudentWithFees from "../views/Report/StudentWithFees";
import StudentWithFeesInstallment from "../views/Report/StudentWithFeesInstallment";
import StudentWithoutFee from "../views/Report/StudentWithoutFee.vue";
import StudentWithoutFeeInBus from "../views/Report/StudentWithoutFeeInBus";
import StudentsByClassAndYear from "../views/Report/StudentByClassAndYear";
import StudentByGovernorate from "../views/Report/StudentByGovernorate";
import StudentByNationality from "../views/Report/StudentByNationality";
import StudentImages from "../views/Report/StudentImages";
import StudentsAllData from "../views/Report/StudentsAllData";
import StudentsAccounts from "../views/Report/StudentsAccounts";
import StudentsAges from "../views/Report/StudentsAges";
import TeacherInfo from "../views/Report/TeacherInfo.vue";
import attendensbymonth from "../views/Report/attendensbymonth.vue";
import fristsbyyear from "../views/Report/fristsbyyear.vue";
// yousef
import collectormarksreport from "../views/Report/collectormarksreport.vue";
import businfo from "../views/Report/busInfo.vue";
import StudentsBus from "../views/Report/StudentsBus.vue";
import StudentsActivities from "../views/Report/StudentsActivities.vue";
import StudentWithFeesWithoutRole from "../views/Report/StudentWithFeesWithoutRole";
import ReportBYFees from "../views/Report/ReportBYFees";
import Studentsubscribeinbus from "../views/Report/Studentsubscribeinbus.vue";
import MonthlyDeficiencies from "../views/Report/MonthlyDeficiencies";
// fares
import SpicificEstimateInSpicificMonth from "../views/Report/SpicificEstimateInSpicificMonth";
import SpicificEstimateInSpicificSemester from "../views/Report/SpicificEstimateInSpicificSemester.vue";
import CommissionDistrPlan from "../views/Report/CommissionDistrPlan.vue";
import OptionalSubject from "../views/Report/OptionalSubject.vue";
import OptionalSubjectStudentDegree from "../views/Report/OptionalSubjectStudentDegree";
import Certificate from "../views/Report/Certificate.vue";
import GradeReport from "../views/Report/GradeReport";
import CertificateYear from "../views/Report/CertificateYear.vue";
import GradeSemesterReport from "../views/Report/GradeSemesterReport.vue";
import MonthExamResult from "../views/Report/MonthExamResult";
import MonthExamResultAll from "../views/Report/MonthExamResultAll.vue";
import CertificateEn from "../views/Report/CertificateEn.vue";
import CertificateYearEn from "../views/Report/CertificateYearEn.vue";
import MonthExamResultEn from "../views/Report/MonthExamResultEn";

// wael
import StudentWithoutSecretOrSeatnumber from "../views/Report/StudentWithoutSecretOrSeatnumber.vue";

// wael
import StudentWithSecretAndSeatNumber from "../views/Report/StudentWithoutSecretOrSeatnumber.vue";
import SubjectNotAssigned from "../views/Report/SubjectNotAssigned.vue";
import MarkNotAssigned from "../views/Report/MarkNotAssigned.vue";
import StudentWalkout from "../views/Report/StudentWalkout.vue";
import TeacherSchedual from "../views/Report/TeacherSchedual.vue";
import ExamScheduleReport from "../views/Report/ExamScheduleReport.vue";
import DaysStudentAttendance from "../views/Report/DaysStudentAttendance.vue";
import MonthlyStudentAttendance from "../views/Report/MonthlyStudentAttendance.vue";
import RingedStudentAttendance from "../views/Report/RingedStudentAttendance.vue";
import SteudentWithSeatNumber from "../views/Report/SteudentWithSeatNumber.vue";
import StudentWithSecretNumber from "../views/Report/StudentWithSecretNumber.vue";
import CorrectWithSecretNumber from "../views/Report/CorrectWithSecretNumber.vue";
import CommitteeWithStudent from "../views/Report/CommitteeWithStudent.vue";

import WeeklyScheduleReport from "../views/Report/WeeklyScheduleReport.vue";
import Users from "../views/Report/Users.vue";
import GroupWithMembers from "../views/Report/GroupWithMembers.vue";
import GroupWithPermissions from "../views/Report/GroupWithPermissions.vue";
import StudentWithSeatAndSecretNumber from "../views/Report/StudentWithSeatAndSecretNumber.vue";
import DetailedStatementOfFees from "../views/Report/DetailedStatementOfFees.vue";
import StudentPayedReport from "../views/Report/StudentPayedReport.vue";

const hasPermission = (prem) => {
  store.dispatch("updatePermission", localStorage.getItem("token"));
  return store.getters.checkpermission(prem);
};
const requirePermission = (perm) => {
  return (to, from, next) => {
    if (hasPermission(perm)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
export default [
  {
    path: "student-by-class-and-year",
    name: "report_StudentsByClassAndYear",
    component: StudentsByClassAndYear,
  },
  {
    path: "student-by-governorate",
    name: "report_StudentByGovernorate",
    component: StudentByGovernorate,
  },
  {
    path: "student-by-nationality",
    name: "report_StudentByNationality",
    component: StudentByNationality,
  },
  {
    path: "student-Images",
    name: "report_StudentImages",
    component: StudentImages,
  },
  {
    path: "student-All-Data",
    name: "report_StudentsAllData",
    component: StudentsAllData,
  },
  {
    path: "student-Accounts",
    name: "report_StudentsAccounts",
    component: StudentsAccounts,
  },
  {
    path: "student-Ages",
    name: "students_ages",
    component: StudentsAges,
  },
  {
    path: "TeacherInfo",
    name: "teachers_info",
    component: TeacherInfo,
  },
  {
    path: "attendensbymonth",
    name: "report",
    component: attendensbymonth,
  },
  {
    path: "fristsbyyear",
    name: "fristsbyyear",
    component: fristsbyyear,
  },

  {
    path: "marksreportnyyear",
    name: "marksreportnyyear",
    component: marksreportnyyear,
  },
  {
    path: "finalexammarksreport",
    name: "finalexammarksreport",
    component: finalexammarksreport,
  },
  {
    path: "StudentWithParent",
    name: "student_with_parent",
    component: StudentWithParent,
  },
  {
    path: "ParentwithStudent",
    name: "ParentwithStudent",
    component: ParentwithStudent,
  },
  {
    path: "ParentsInfo",
    name: "ParentsInfo",
    component: ParentsInfo,
  },
  {
    path: "ParentsWithUser",
    name: "ParentsWithUser",
    component: ParentsWithUser,
  },
  {
    path: "ParentsWithContact",
    name: "ParentsWithContact",
    component: ParentsWithContact,
  },
  {
    path: "NewEnrolledStudent",
    name: "new_rnrolles_student",
    component: NewEnrolledStudent,
  },
  {
    path: "CollectorMarkStudent",
    name: "CollectorMarkStudent",
    component: CollectorMarkStudent,
  },
  {
    path: "CollectorMarkStudentMonth",
    name: "CollectorMarkStudentMonth",
    component: CollectorMarkStudentMonth,
  },
  {
    path: "CollectorMarkStudentYear",
    name: "CollectorMarkStudentYear",
    component: CollectorMarkStudentYear,
  },
  {
    path: "CollectorMarkStudentMonthEstimate",
    name: "CollectorMarkStudentMonthEstimate",
    component: CollectorMarkStudentMonthEstimate,
  },
  {
    path: "FirstStudentMonthforSubject",
    name: "FirstStudentMonthforSubject",
    component: FirstStudentMonthforSubject,
  },
  {
    path: "FirstStudentForMonth",
    name: "first_studetns_for_month",
    component: FirstStudentForMonth,
  },
  {
    path: "StudentWithParentFees",
    name: "StudentWithParentFees",
    component: StudentWithParentFees,
  },
  {
    path: "StudentWithFees",
    name: "student_with_fee",
    component: StudentWithFees,
  },
  {
    path: "student-fee-installment",
    name: "student_fee_installment",
    component: StudentWithFeesInstallment,
  },
  {
    path: "student-without-fees",
    name: "student_without_fees",
    component: StudentWithoutFee,
  },
  {
    path: "student-without-fee-bus",
    name: "student_without_fee_bus",
    component: StudentWithoutFeeInBus,
  },
  // fares
  {
    path: "report/spicific-estimate-in-spicific-month",
    name: "spicific_estimate_in_spicific_month",
    component: SpicificEstimateInSpicificMonth,
  },
  {
    path: "report/spicific-estimate-in-spicific-semester",
    name: "spicific_estimate_in_spicific_semester",
    component: SpicificEstimateInSpicificSemester,
  },
  {
    path: "report/commission-distr-plan",
    name: "commission_distr_plan",
    component: CommissionDistrPlan,
  },
  {
    path: "report/optional-subject",
    name: "optional_subject",
    component: OptionalSubject,
  },
  {
    path: "report/optional-subject-student-degree",
    name: "optional_subject_student_degree",
    component: OptionalSubjectStudentDegree,
  },
  {
    path: "report/certificate",
    name: "monthly_certificate",
    component: Certificate,
  },
  {
    path: "report/grade-report",
    name: "grade_report",
    component: GradeReport,
  },
  {
    path: "report/certificate-year",
    name: "certificate_year",
    component: CertificateYear,
  },
  {
    path: "report/grade-semester-report",
    name: "grade_semester_report",
    component: GradeSemesterReport,
  },
  // wael
  {
    path: "student-withot-seatnumber-or-secretnumber",
    name: "student_withot_seatnumber_or_secretnumber",
    component: StudentWithoutSecretOrSeatnumber,
  },
  {
    path: "student-with-seatnumber-or-secretnumber",
    name: "student_with_seatnumber_or_secretnumber",
    component: StudentWithSecretAndSeatNumber,
  },
  {
    path: "subject-not-assigned",
    name: "subject_not_assigned",
    component: SubjectNotAssigned,
  },
  {
    path: "mark-not-assigned",
    name: "mark_not_assigned",
    component: MarkNotAssigned,
  },
  {
    path: "student-walkout",
    name: "student_walkout",
    component: StudentWalkout,
  },
  {
    path: "teacher-schedual",
    name: "teacher_schedual",
    component: TeacherSchedual,
  },
  // yousef
  {
    path: "collectormarksreport",
    name: "collectormarksreport",
    component: collectormarksreport,
  },
  {
    path: "bus-info",
    name: "busInfo",
    component: businfo,
  },
  {
    path: "StudentsBus",
    name: "StudentsBus",
    component: StudentsBus,
  },
  {
    path: "StudentsActivities",
    name: "StudentsActivities",
    component: StudentsActivities,
  },
  {
    path: "StudentWithFeesWithoutRole",
    name: "StudentWithFeesWithoutRole",
    component: StudentWithFeesWithoutRole,
  },
  {
    path: "ReportBYFees",
    name: "ReportBYFees",
    component: ReportBYFees,
  },
  {
    path: "Studentsubscribeinbus",
    name: "Studentsubscribeinbus",
    component: Studentsubscribeinbus,
  },
  {
    path: "MonthlyDeficiencies",
    name: "MonthlyDeficiencies",
    component: MonthlyDeficiencies,
  },
  {
    path: "exam-schedule-report",
    name: "exam_schedule_report",
    component: ExamScheduleReport,
  },
  {
    path: "days-student-attendance",
    name: "days_student_attendance",
    component: DaysStudentAttendance,
  },
  {
    path: "months-student-attendance",
    name: "months_student_attendance",
    component: MonthlyStudentAttendance,
  },
  {
    path: "ring-student-attendance",
    name: "ring_student_attendance",
    component: RingedStudentAttendance,
  },
  {
    path: "steudent-with-seatnumber",
    name: "steudent_with_seatnumber",
    component: SteudentWithSeatNumber,
  },
  {
    path: "student-with-secret-number",
    name: "student_with_secret_number",
    component: StudentWithSecretNumber,
  },
  {
    path: "weekly-schedule-report",
    name: "weekly_schedule_report",
    component: WeeklyScheduleReport,
  },

  {
    path: "marksreportnyyear",
    name: "marksreportnyyear",
    component: marksreportnyyear,
  },
  {
    path: "finalexammarksreport",
    name: "finalexammarksreport",
    component: finalexammarksreport,
  },
  {
    path: "StudentWithParent",
    name: "student_with_parent",
    component: StudentWithParent,
  },
  {
    path: "ParentwithStudent",
    name: "ParentwithStudent",
    component: ParentwithStudent,
  },
  {
    path: "ParentsInfo",
    name: "ParentsInfo",
    component: ParentsInfo,
  },
  {
    path: "ParentsWithUser",
    name: "ParentsWithUser",
    component: ParentsWithUser,
  },
  {
    path: "ParentsWithContact",
    name: "ParentsWithContact",
    component: ParentsWithContact,
  },
  {
    path: "NewEnrolledStudent",
    name: "new_rnrolles_student",
    component: NewEnrolledStudent,
  },
  // {
  //   path: "CollectorMarkStudent",
  //   name: "CollectorMarkStudent",
  //   component: CollectorMarkStudent,
  // },
  {
    path: "CollectorMarkStudentMonth",
    name: "CollectorMarkStudentMonth",
    component: CollectorMarkStudentMonth,
  },
  {
    path: "CollectorMarkStudentYear",
    name: "CollectorMarkStudentYear",
    component: CollectorMarkStudentYear,
  },
  {
    path: "CollectorMarkStudentMonthEstimate",
    name: "CollectorMarkStudentMonthEstimate",
    component: CollectorMarkStudentMonthEstimate,
  },
  {
    path: "FirstStudentMonthforSubject",
    name: "FirstStudentMonthforSubject",
    component: FirstStudentMonthforSubject,
  },
  {
    path: "FirstStudentForMonth",
    name: "first_studetns_for_month",
    component: FirstStudentForMonth,
  },
  {
    path: "StudentWithParentFees",
    name: "StudentWithParentFee",
    component: StudentWithParentFees,
  },
  {
    path: "StudentWithFees",
    name: "student_with_fee",
    component: StudentWithFees,
  },
  // {
  //   path: "student-without-fees",
  //   name: "student_without_fees",
  //   component: StudentWithoutFee,
  // },
  // {
  //   path: "student-without-fee-bus",
  //   name: "student_without_fee_bus",
  //   component: StudentWithoutFeeInBus,
  // },
  // fares
  {
    path: "spicific-estimate-in-spicific-month",
    name: "spicific_estimate_in_spicific_month",
    component: SpicificEstimateInSpicificMonth,
  },
  {
    path: "spicific-estimate-in-spicific-semester",
    name: "spicific_estimate_in_spicific_semester",
    component: SpicificEstimateInSpicificSemester,
  },
  {
    path: "commission-distr-plan",
    name: "commission_distr_plan",
    component: CommissionDistrPlan,
  },
  {
    path: "optional-subject",
    name: "optional_subject",
    component: OptionalSubject,
  },
  {
    path: "optional-subject-student-degree",
    name: "optional_subject_student_degree",
    component: OptionalSubjectStudentDegree,
  },
  {
    path: "certificate",
    name: "monthly_certificate",
    component: Certificate,
  },
  {
    path: "grade-report",
    name: "grade_report",
    component: GradeReport,
  },
  {
    path: "certificate-year",
    name: "certificate_year",
    component: CertificateYear,
  },
  {
    path: "grade-semester-report",
    name: "grade_semester_report",
    component: GradeSemesterReport,
  },
  {
    path: "month-exam-result",
    name: "month_exam_result",
    component: MonthExamResult,
  },
  {
    path: "month-exam-result-all",
    name: "month_exam_result_all",
    component: MonthExamResultAll,
  },
  {
    path: "certificate-en",
    name: "certificate_en",
    component: CertificateEn,
  },
  {
    path: "certificate-year-en",
    name: "certificate_year_en",
    component: CertificateYearEn,
  },
  {
    path: "month-exam-result-en",
    name: "month_exam_result_en",
    component: MonthExamResultEn,
  },
  // wael

  // yousef
  {
    path: "collectormarksreport",
    name: "collectormarksreport",
    component: collectormarksreport,
  },
  {
    path: "bus-info",
    name: "busInfo",
    component: businfo,
  },
  {
    path: "StudentsActivities",
    name: "StudentsActivities",
    component: StudentsActivities,
  },
  {
    path: "StudentWithFeesWithoutRole",
    name: "StudentWithFeesWithoutRole",
    component: StudentWithFeesWithoutRole,
  },
  {
    path: "ReportBYFees",
    name: "ReportBYFees",
    component: ReportBYFees,
  },
  {
    path: "Studentsubscribeinbus",
    name: "Studentsubscribeinbus",
    component: Studentsubscribeinbus,
  },
  {
    path: "MonthlyDeficiencies",
    name: "MonthlyDeficiencies",
    component: MonthlyDeficiencies,
  },
  // {
  //     path:'teacher-schedual',
  //     name:'teacher_schedual',
  //     component: TeacherSchedual,
  // },
  // {
  //   path:'exam-schedule-report',
  //   name:'exam_schedule_report',
  //   component: ExamScheduleReport,
  // },
  // {
  //   path:'days-student-attendance',
  //   name:'days_student_attendance',
  //   component: DaysStudentAttendance,
  // },
  {
    path: "Users",
    name: "Users",
    component: Users,
  },
  {
    path: "GroupWithMembers",
    name: "GroupWithMembers",
    component: GroupWithMembers,
  },
  {
    path: "GroupWithPermissions",
    name: "GroupWithPermissions",
    component: GroupWithPermissions,
  },

  //wael

  {
    path: "correct-with-secretNumber",
    name: "correct_with_secretNumber",
    component: CorrectWithSecretNumber,
  },

  {
    path: "committee-with-student",
    name: "committee_with_student",
    component: CommitteeWithStudent,
  },
  {
    path: "student-with-seat-and-secret-number",
    name: "student_with_seat_and_secret_number",
    component: StudentWithSeatAndSecretNumber,
  },
  {
    path: "detailed-statement-of-fees",
    name: "detailed_statement_of_fees",
    component: DetailedStatementOfFees,
  },
  {
    path: "student-payed-report",
    name: "student_payed_report",
    component: StudentPayedReport,
  },
];
