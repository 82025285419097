<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_division = null),
                getClassAssignHall(filter_data.fk_branch?.id),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="filter_data.fk_division = null"
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            :no-data-text="$t('school.division.not_found')"
            prepend-inner-icon="mdi-chair-school"
            :items="
              filter_data.fk_class?.id
                ? getClassDivisions(filter_data.fk_class?.id, getUserRole < 2)
                : []
            "
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_month"
            density="compact"
            :item-title="date_type == 'm' ? 'name_m' : 'name_h'"
            item-value="id"
            prepend-inner-icon="mdi-calendar"
            :items="getCurrentSemesterMonths"
            :label="$t('school.reports.select_month')"
            :disabled="filter_data.day_date"
            @update:model-value="filter_data.day_date = null"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <!-- <v-col cols="6" md="5" sm="4">
          <v-text-field
            v-model="filter_data.day_date"
            density="compact"
            class="cursor-pointer"
            :placeholder="$t('school.reports.date')"
            clearable
            hide-details="auto"
            prepend-inner-icon="mdi-calendar-clock-outline"
            :disabled="filter_data.fk_month"
            @update:model-value="filter_data.fk_month = null"
            id="min"
          >
          </v-text-field>
          <Datetime
            style="cursor-pointer"
            type="date"
            element="min"
            density="compact"
            :color="'#0747a6'"
            :allowed-dates="allowedDates()"
            @input="(e) => getPermissionTime(e)"
            :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
            :placeholder="$t('school.student_attendance.permission_time')"
            :calendar="date_type == 'h' ? 'hijri' : ''"
          >
          </Datetime>
        </v-col> -->
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>

        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu @toggle-visibility="() => (exist = !exist)"></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <tr>
                <th rowspan="2">
                  {{ this.$t("school.reports.student_name") }}
                </th>
                <th :colspan="attendance_number?.length">
                  {{ this.$t("school.reports.attend_date") }}
                </th>
              </tr>
              <tr>
                <th v-for="attend in attendance_number" :key="attend">
                  {{ attend.slice(-2) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center border">
                  {{ item.student }}
                </td>
                <!-- <pre>{{ item?.attendance }}</pre> -->
                <td
                  v-for="attend in attendance_number"
                  :key="attend"
                  class="text-center border"
                >
                  <template v-if="item?.attendance[attend]">
                    <v-icon
                      style="color: #4caf50"
                      v-if="
                        item?.attendance[attend]?.attend ||
                        item?.attendance[attend]?.after_brake
                      "
                      >mdi mdi-check</v-icon
                    >
                    <v-icon
                      v-else-if="item?.attendance[attend]?.permit"
                      class="text-info"
                      >mdi-exclamation-thick</v-icon
                    >
                    <v-icon
                      style="color: #ff0000"
                      v-else-if="
                        !item?.attendance[attend]?.attend &&
                        !item?.attendance[attend]?.after_brake
                      "
                      >mdi-close</v-icon
                    >
                  </template>
                  <!-- <v-icon size="x-small">mdi-window-minimize</v-icon> -->
                  <span v-else>-</span>
                  <!-- <v-icon style="color: #ff0000" v-else class="me-2"
                    >mdi mdi-close-circle</v-icon
                  > -->
                  <!-- {{
                    attend?.attend ??
                    attend?.after_brake ??
                    attend?.permit ??
                    "-"
                  }} -->
                </td>
                <!-- <td class="text-center border">
                  {{ item.attendance_date }}
                </td>
                <td class="text-center border">
                  <v-icon
                    style="color: #4caf50"
                    v-if="item.is_attendance"
                    class="me-2"
                    >mdi mdi-check-circle</v-icon
                  >
                  <v-icon style="color: #ff0000" v-else class="me-2"
                    >mdi mdi-close-circle</v-icon
                  >
                </td> -->
                <!-- <td class="text-center border">
                  <v-icon
                    style="color: #4caf50"
                    v-if="item.is_permission"
                    class="me-2"
                    >mdi mdi-check-circle</v-icon
                  >
                  <v-icon style="color: #ff0000" v-else class="me-2"
                    >mdi mdi-close-circle</v-icon
                  >
                </td> -->
              </tr>
            </tbody>
          </table>
          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
import { now } from "moment";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updateAuthenticated",
        localStorage.getItem("token")
      );
      if (this.getUserRole > 1) {
        await this.getBranchClasses();
      }
      await this.getClassAssignHall();
      await this.getBrenches({});
      await this.getBranchClasses();
      await this.getDivisions({});
      await this.getAllMonths();
      await this.getSemesters();
      // await this.dateToday();

      //   await this.axios
      //     .get(`${this.base_url}school-data/`, {
      //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //     })
      //     .then((response) => {
      //       this.school = response.data;
      //     });
      // this.getTeachers();
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  components: { Datetime },
  data() {
    return {
      //   teachers: [],
      //   school: [],
      //   day_count: 7,
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   committees: [],
      //   v$: useValidate(),
      exist: false,
      items: [],
      attendance_number: [],
      loading: false,
      date_type: localStorage.getItem("date"),
      //   txt_search: "",
      selectedHead: [
        "student_name",
        "attendance_date",
        "is_attendance",
        "is_permission",
      ],
      headers: [
        {
          title: this.$t("school.reports.student_name"),
          key: "student_name",
        },
        {
          title: this.$t("school.reports.attendance_date"),
          key: "attendance_date",
        },
        {
          title: this.$t("school.reports.is_attendance"),
          key: "is_attendance",
        },
        {
          title: this.$t("school.reports.is_permission"),
          key: "is_permission",
        },
      ],
      // perPage: 10,
      //   length: 0,
      // totalItems: 0,
      //   itemsPerPage: [
      //     {
      //       value: 10,
      //       text: "10",
      //     },
      //     {
      //       value: 25,
      //       text: "25",
      //     },
      //     {
      //       value: 50,
      //       text: "50",
      //     },
      //     {
      //       value: 100,
      //       text: "100",
      //     },
      //     {
      //       value: "",
      //       text: "all",
      //     },
      //   ],
      page: 1,
      //   pagination: {},
      filter_data: {
        // class_number: null,
        // day_date: null,
        // fk_division: null,
        // type: "day",
      },
    };
  },

  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      classes_by_branch: (state) => state.school.classes_by_branch,
      months: (state) => state.school.months,
      divisions: (state) => state.school.divisions,
      branch: (state) => state.school.branch,

      //   user: (state) => state.User,
    }),
    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
      getCurrentSemesterMonths: "school/getCurrentSemesterMonths",
    }),
    // dateToday() {
    //   var date = new Date().toJSON().slice(0, 10);
    //   this.filter_data.day_date = date;
    //   return date;
    // },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
    // currentSemesterMonths() {
    //   return this.getCurrentSemesterMonths;
    // },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getDivisions: "school/getDivisions",
      getAllMonths: "school/getAllMonths",
      getSemesters: "school/getSemesters",
      getBrenches: "school/getBrenches",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    // async getTeachers() {
    //   await this.axios
    //     .get(`${this.base_url}api/teachers/data/`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       this.teachers = response.data;
    //     });
    // },
    // getPermissionTime(e, id) {
    //   let date = e;
    //   if (this.$i18n.locale == "ar") {
    //     date = this.toEnglishString(e);
    //   }
    //   this.filter_data.day_date = date;
    // },
    // allowedDates() {
    //   const year = 2024;
    //   const month = 8;
    //   const start_month = new Date(year, month, 1);
    //   const end_month = new Date(year, month, 1, 0);
    //   console.log(start_month);
    //   console.log(end_month);
    // },
    // getClassName(class_id) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === class_id
    //   );
    //   return objectClass.name_ar;
    // },
    // addLineBreak(text) {
    //   var formattedText = text.replace(/@/g, "<br>");
    //   return formattedText;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        if (!this.filter_data.fk_month && !this.filter_data.day_date) {
          this.filter_data.fk_month =
            this.date_type == "m"
              ? { name_m: localStorage.getItem("month_m") }
              : { name_h: localStorage.getItem("month_h") };
        } else if (this.filter_data.day_date) {
          this.filter_data.fk_month = null;
        }
        this.getData();
      }
    },
    // getSchdual(subjects, id) {
    //   for (let key in subjects) {
    //     if (id == subjects[key].quotas_id) {
    //       return `${subjects[key].fk_subject} <br> ${subjects[key].fk_division} <br> ${subjects[key].fk_class} `;
    //     } else {
    //       continue;
    //     }
    //   }
    // },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/days_student_attendance`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            // page: page,
            // page_size: this.perPage,
            class_number: this.filter_data.fk_class?.id,
            // day_date: this.filter_data.day_date,
            division: this.filter_data.fk_division?.id,
            fk_month: this.filter_data.fk_month?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // type: this.filter_data.type,
          },
        })
        .then((response) => {
          if (response.data.results) {
            this.items = response.data.results;
            this.exist = true;
            // this.totalItems = response.data.pagination.count;
            if (this.items?.length) {
              // console.log(this.items[0]?.attendance);
              this.attendance_number = Object.keys(
                this.items[0]?.attendance
              )?.sort();
            }
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>

<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}

th {
  font-size: 14px;
  padding-bottom: 2px;
}

.cursor_pointer {
  cursor: pointer !important;
}
</style>
