<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
      <v-col cols="6" md="4" sm="12" class="pb-3">
        <v-autocomplete
          ref="select"
          color="indigo"
          v-model="filter_data.year"
          density="compact"
          item-title="year"
          item-value="id"
          prepend-inner-icon="mdi-calendar"
          :items="academic_years"
          :label="$t('year.name')"
          hide-details
          :error-messages="v$.filter_data.year.$errors.map((e) => e.$message)"
          @update:model-value="(filter_data.class = null), (filter_data.division = null)"
        />
      </v-col>
      <v-col cols="6" md="4" sm="12" class="pb-3">
        <v-autocomplete
          color="indigo"
          v-model="filter_data.class"
          density="compact"
          item-title="name_ar"
          item-value="id"
          prepend-inner-icon="mdi-google-classroom"
          :items="classes"
          :label="$t('class.select')"
          hide-details
          :error-messages="v$.filter_data.class.$errors.map((e) => e.$message)"
          @update:model-value="filter_data.division = null"
        />
      </v-col>
      <v-col cols="6" md="4" sm="12" class="pb-3">
        <v-autocomplete
          color="indigo"
          v-model="filter_data.division"
          density="compact"
          item-title="name"
          item-value="id"
          prepend-inner-icon="mdi-chair-school"
          :items="class_divisions"
          :label="$t('division.select')"
          hide-details
          :error-messages="v$.filter_data.division.$errors.map((e) => e.$message)"
        />
      </v-col>
      <div>
        <v-btn
          density="compact"
          class="mx-1 mt-2 mr-3"
          color="indigo"
          @click="checkFilterData"
        >
          <span>{{ $t("globals.show") }}</span>
        </v-btn>
      </div>
    </v-row>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <v-row class="my-4 text-grey-darken-2">
      <v-col cols="4" class="text-center">
        <h2>الجمهورية اليمنية</h2>
        <h3 v-if="school.length > 0">
          {{ school[0].ministry_name ? school[0].ministry_name : "" }}
        </h3>
        <h5 v-if="school.length > 0">{{ school[0].arabic_name }}</h5>
      </v-col>
      <v-col cols="4" class="text-center">
        <h4>بسم الله الرحمن الرحيم</h4>
        <v-responsive v-if="school.length > 0">
          <v-avatar size="80">
            <v-img :src="school[0].logo"></v-img>
          </v-avatar>
        </v-responsive>
      </v-col>
      <v-col cols="4" class="text-center">
        <div>{{ getClassName(filter_data.class) }}</div>
        <div>{{ getDivisionName(filter_data.division) }}</div>
      </v-col>
    </v-row>
    <h3 class="text-center text-grey-darken-2">
      <div>نتيجة امتحانات</div>
      <span
        >للعام الدراسي
        {{ getYearName(filter_data.year) }}
      </span>
    </h3>
    <v-card-text class="mb-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1"></div>
        <!-- search box  -->
        <div class="d-flex justify-center" style="flex: 3">
          <div
            class="w-100 d-flex border rounded-pill"
            style="font-size: 12px !important"
          >
            <v-icon color="icon-color" class="mt-1 mx-1">mdi-magnify</v-icon>
            <input
              type="text"
              v-model="txt_search"
              @input="getData((page = 1))"
              class="flex-fill search my-1"
              :placeholder="$t('global.data_table.search_here')"
            />
          </div>
        </div>
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list id="print-menu" density="compact">
              <v-list-item @click="printTable((all = false))">{{
                $t("globals.shown-records")
              }}</v-list-item>
              <v-list-item @click="printTable((all = true))">{{
                $t("globals.all-records")
              }}</v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
          >
            <v-icon color="icon-color">mdi-file-excel</v-icon>
            <v-tooltip activator="parent" class="tooltip" location="top">
              <small>{{ $t("export.excel") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn
            density="compact"
            class="mx-1"
            @click="
              (exist = false),
                (filter_data.class = null),
                (filter_data.year = null),
                (filter_data.division = null),
                (exist = false),
                v$.filter_data.$reset()
            "
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <tr>
                <th rowspan="2" class="border background-cell" style="width: 150px">
                  {{ $t("school.student-name") }}
                </th>
                <th
                  v-for="item in items[0].subjects"
                  :key="item"
                  class="border background-cell"
                  :style="items[0].subjects.length >= 8 ? '' : 'width:0'"
                >
                  {{ item[0].subject_name }}
                  {{ item[0].subject_optional == true ? "*" : "" }}
                </th>
                <th rowspan="2" class="border background-cell">
                  <div class="vertical-text" v-if="items[0].subjects.length >= 8">
                    {{ $t("report.total") }}
                  </div>
                  <div v-else>{{ $t("report.total") }}</div>
                </th>
                <th rowspan="2" class="border background-cell">
                  <div class="vertical-text" v-if="items[0].subjects.length >= 8">
                    {{ $t("report.percentage") }}
                  </div>
                  <div v-else>{{ $t("report.percentage") }}</div>
                </th>
                <th rowspan="2" class="border background-cell">
                  <div class="vertical-text" v-if="items[0].subjects.length >= 8">
                    {{ $t("report.result") }}
                  </div>
                  <div v-else>{{ $t("report.result") }}</div>
                </th>
              </tr>
              <tr>
                <th
                  v-for="item in items[0].subjects"
                  :key="item"
                  class="border background-cell"
                  :style="items[0].subjects.length >= 8 ? '' : 'width:0'"
                >
                  <div class="">
                    <span class="d-flex justify-content-between">
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0].subjects.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                      >
                        <div
                          :class="
                            items[0].subjects.length >= 8 ? 'vertical-text-span' : ''
                          "
                        >
                          محصله اولى
                        </div>
                      </span>
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0].subjects.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0].subjects.length >= 8 ? 'vertical-text-span' : ''
                          "
                        >
                          نص العام
                        </div></span
                      >
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0].subjects.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0].subjects.length >= 8 ? 'vertical-text-span' : ''
                          "
                        >
                          محصله ثانيه
                        </div></span
                      >
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0].subjects.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0].subjects.length >= 8 ? 'vertical-text-span' : ''
                          "
                        >
                          نهاية العام
                        </div></span
                      >
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0].subjects.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0].subjects.length >= 8 ? 'vertical-text-span' : ''
                          "
                        >
                          المجموع الكلي
                        </div></span
                      >
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="item in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student_name }}
                </td>
                <td
                  v-for="subject in item.subjects"
                  :key="subject"
                  class="border text-center"
                >
                  <div>
                    <span class="d-flex justify-content-between">
                      <span
                        class="d-flex justify-center align-center"
                        :class="
                          items[0].subjects.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span'
                        "
                        >{{ subject[0].collector }}</span
                      >
                      <span
                        class="d-flex justify-center align-center"
                        :class="
                          items[0].subjects.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span'
                        "
                        >{{ subject[0].final_exam }}</span
                      >
                      <span
                        class="d-flex justify-center align-center"
                        :class="
                          items[0].subjects.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span'
                        "
                        >{{ subject[1] ? subject[1].collector : "" }}</span
                      >
                      <span
                        class="d-flex justify-center align-center"
                        :class="
                          items[0].subjects.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span'
                        "
                        >{{ subject[1] ? subject[1].final_exam : "" }}</span
                      >
                      <span
                        class="d-flex justify-center align-center"
                        :class="[
                          items[0].subjects.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span',
                        ]"
                        >{{ getSubjectsTotal(subject) }}</span
                      >
                    </span>
                  </div>
                </td>
                <td :class="['text-center border', item.total < 50 ? 'failed' : '']">
                  {{ item.total }}
                </td>
                <td class="text-center border">
                  {{ getPercentageValue(item.total, subjectsCount) }}%
                </td>
                <td class="text-center border">{{ item.status ? "ناجح" : "راسب" }}</td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-autocomplete
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-autocomplete>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import tafqeet from "@/assets/Tafqeet";
export default {
  async created() {
    try {
      await this.$store.commit('updateLoadingValue',true)
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
      await this.getAcademicYears();
      await this.getClasses();
      await this.getClassAssignHall();
      await this.axios
        .get(`${this.base_url}school-data/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.school = response.data;
        });
      await this.getDivisions();
      this.$store.commit('updateLoadingValue',false)
    } catch (error) {
      this.$store.commit('updateLoadingValue',false)
      
    }
  },

  data() {
    return {
      school: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["student_name", "collector", "final_exam"],
      headers: [
        { title: this.$t("student.student_name"), key: "student_name" },
        { title: this.$t("class-marks.collector"), key: "collector" },
        { title: this.$t("class-marks.final-exam"), key: "final_exam" },
        { title: this.$t("report.total"), key: "total" },
        { title: this.$t("report.writing"), key: "writing" },
        { title: "", key: "empty" },
        {
          title: this.$t("report.count-of-actual-lecture"),
          key: "count_of_actual_lecture",
        },
        { title: this.$t("report.count-of-attendence"), key: "count_of_attendence" },
        { title: this.$t("report.count-of-absentce"), key: "count_of_absentce" },
        { title: this.$t("report.count-of-permission"), key: "count_of_permission" },
        {
          title: this.$t("report.count-of-not-permission"),
          key: "count_of_not_permission",
        },
      ],
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: "all" },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
      subjectsTotal: 0,
      subjectsCount: 0,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      academic_years: (state) => state.school.academic_years,
      divisions: (state) => state.school.divisions,
      user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    class_divisions() {
      if (this.filter_data.class) {
        return this.getClassDivisions(this.filter_data.class);
      }
    },

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    tafqeet,
    getPercentageValue(sum, count) {
      const total = (sum / count) * 100;
      return parseFloat(total.toFixed(2) / 100).toFixed(2);
    },
    getSubjectsTotal(subjects) {
      const { total, count } = subjects.reduce(
        (accumulator, obj) => {
          return {
            total: accumulator.total + obj.total,
            count: accumulator.count + 1,
          };
        },
        { total: 0, count: 0 }
      );
      return total;
    },
    getSubjectsCount(subjects) {
      const count = subjects.reduce((accumulator, obj) => {
        if (obj[0].subject_optional === false) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);
      this.subjectsCount = count;
    },
    getYearName(year_id) {
      if (year_id) {
        const year = this.academic_years.find((year) => year.id === year_id);
        return year.year;
      }
      return "-------------";
    },
    getClassName(class_id) {
      const objectClass = this.classes.find((objectClass) => objectClass.id === class_id);
      return objectClass.name_ar;
    },
    getDivisionName(division_id) {
      const division = this.divisions.find((division) => division.id === division_id);
      return division.name;
    },
    ...mapActions({
      getClasses: "school/getClasses",
      getAcademicYears: "school/getAcademicYears",
      getClassAssignHall: "school/getClassAssignHall",
      getAllMonths: "school/getAllMonths",
      getAllEstimations: "school/getAllEstimations",
      getDivisions: "school/getDivisions",
    }),
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.getData();
      }
      window.print();
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/certificate_year`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: this.txt_search,
            page: page,
            page_size: this.perPage,
            class_number: this.filter_data.class,
            year: this.filter_data.year,
            division: this.filter_data.division,
          },
        })
        .then((response) => {
          if (response.data.results) {
            this.items = response.data.results;
            this.exist = true;
            this.totalItems = response.data.pagination.count;
            this.length = response.data.pagination.num_pages;
            this.pagination = response.data.pagination;
            this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
            this.getSubjectsCount(this.items[0].subjects);
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        class: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        year: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        division: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  font-size: 10px;
}
th,
th div {
  font-size: 10px;
  padding-bottom: 2px;
}
@media print {
  thead tr th:nth-of-type(4),
  thead tr th:nth-of-type(5),
  .background-cell {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
.background-cell {
  background: #b1cdb6;
}
table thead tr:last-of-type div span:not(:last-of-type),
table tbody td div span span:not(:last-of-type) {
  border-left: 1px solid;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}
.total {
  background: rgb(248, 238, 103);
}
.failed {
  background: #e57373;
}
.vertical-text,
.vertical-text-span {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.vertical-text-span {
  font-size: 7px;
}
table thead tr:last-of-type div span div {
  font-size: 6px;
  padding: 0.1rem;
}
.vertical-span {
  font-size: 7px;
  padding: 10px 0;
  width: 14px;
  writing-mode: vertical-rl;
}
.vertical-td-span {
  font-size: 7px;
  padding: 10px 0;
  width: calc(100% / 5);
}
.horizontal-span {
  font-size: 5px;
  width: calc(100% / 5);
}
.horizontal-td-span {
  font-size: 7px;
  padding: 10px 0;
  width: calc(100% / 5);
}
</style>

<style>
.search:focus {
  outline: unset;
}

@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
