<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <!-- <v-col cols="6" md="3" sm="12">
                    <v-autocomplete color="indigo" v-model="filter_data.fk_class" density="compact" item-title="name_ar"
                        item-value="id" prepend-inner-icon="mdi-google-classroom" :items="classes"
                        :label="$t('school.reports.select_class')" hide-details="auto" :rules="[$required]"
                        return-object @update:model-value="(filter_data.fk_student = null), getStudents()" />
                </v-col> -->
        <v-col v-if="getUserRole < 2" cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_student = null),
                (students = []),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="(filter_data.fk_student = null), getStudents()"
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_student"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-school"
            :items="students"
            :label="$t('school.reports.select_student')"
            hide-details
            return-object
            clearable
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="
          () => (
            (exist = !exist),
            filter_data.fk_student?.name_en
              ? (filter_data.fk_student = null)
              : ''
          )
        "
      ></filter-menu>
      <div id="myTable">
        <v-card
          v-if="items[0]?.fee?.length < 1 ?? items.length < 1"
          class="pa-2"
        >
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card
          :loading="loading"
          v-if="items[0]?.fee?.length > 0 ?? items?.length > 0"
        >
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <template v-for="(item, index) in items" :key="item.id">
                <tr v-for="(sub, subIndex) in item?.fee" :key="sub">
                  <template v-if="subIndex === 0">
                    <td
                      v-if="selectedHead.includes('no')"
                      :rowspan="item?.fee?.length"
                      class="text-center border"
                    >
                      {{ (page - 1) * perPage + index + 1 }}
                    </td>
                    <td
                      v-if="selectedHead.includes('student_name')"
                      :rowspan="item?.fee?.length"
                      class="text-center border"
                    >
                      {{ item.student_name }}
                    </td>
                    <!-- <td
                      v-if="selectedHead.includes('class_name')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ item.class_name }}
                    </td> -->
                  </template>

                  <td
                    v-for="header in selectedHeaders?.filter(
                      (head) => head.key != 'no' && head.key != 'student_name'
                    )"
                    :key="header.key"
                    class="text-center border"
                  >
                    {{
                      sub[header.key] != null
                        ? sub[header.key]
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-"
                    }}
                  </td>
                </tr>
                <tr></tr>
              </template>
              <!-- <template v-for="(item, i) in items" :key="i">
                <tr v-for="(fee, index) in item.fee" :key="index">
                  <td
                    class="text-center border"
                    v-if="selectedHead.includes('no') && index === 0"
                    :rowspan="item.fee.length"
                  >
                    {{ i + 1 }}
                  </td>
                  <td
                    class="text-center border"
                    v-if="selectedHead.includes('student_name') && index === 0"
                    :rowspan="item.fee.length"
                  >
                    {{ item.student_name }}
                  </td>
                  <td
                    class="text-center border"
                    v-if="selectedHead.includes('fee_type')"
                  >
                    {{ fee.fee_type }}
                  </td>
                  <td
                    class="text-center border"
                    v-if="selectedHead.includes('fee')"
                  >
                    {{ fee.fee }}
                  </td>
                  <td
                    class="text-center border"
                    v-if="selectedHead.includes('discount')"
                  >
                    {{ fee.discount }}
                  </td>
                  <td
                    class="text-center border"
                    v-if="selectedHead.includes('total')"
                  >
                    {{ fee.total }}
                  </td>
                  <td
                    class="text-center border"
                    v-if="selectedHead.includes('paid')"
                  >
                    {{ fee.paid }}
                  </td>
                  <td
                    class="text-center border"
                    v-if="selectedHead.includes('still')"
                  >
                    {{ fee.still }}
                  </td>
                </tr>
              </template> -->
              <tr>
                <th
                  :colspan="colSpan()"
                  class="px-2 text-center"
                  v-if="colSpan()"
                >
                  {{ $t("school.reports.total") }}
                </th>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('fee')"
                >
                  {{ getTotalFee()?.toLocaleString("en-US") }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('discount')"
                >
                  {{ getTotalDiscount()?.toLocaleString("en-US") }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('total')"
                >
                  {{ (getTotalFee() - getTotalDiscount())?.toLocaleString("en-US") }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('paid')"
                >
                  {{ getTotalCredit()?.toLocaleString("en-US") }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('still')"
                >
                  {{ getTotalDebit()?.toLocaleString("en-US") }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="d-flex" id="pagination-bar">
                        <v-pagination v-model="page" :length="length" density="compact" show-first-last-page>
                        </v-pagination>
                        <div class="d-flex">
                            <span class="mt-2">{{ $t("school.reports.showing") }}</span>
                            <v-autocomplete v-model="perPage" class="pa-0" :items="itemsPerPage" item-value="value"
                                item-title="text" density="compact" hide-details variant="text">
                            </v-autocomplete>
                            <span class="mt-2">{{ " " + $t("school.reports.from") + " " }} [
                                {{ items?.length }} ]
                                {{ " " + $t("school.reports.item") + " " }}</span>
                        </div>
                    </div> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      //   this.getYears();
      //   await this.getAcademicYears();
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        await this.getBranchClasses();
      }
      //   await this.getDivisions({});
      //   await this.getFeeTypes();
      //   await this.getSemesters();
      //   await this.getSubjects();
      //   await this.axios
      //     .get(`${this.base_url}school-data/`, {
      //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //     })
      //     .then((response) => {
      //       this.school = response.data;
      //     });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      fees_types: [],
      students: [],
      school: [],
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      //   txt_search: "",
      selectedHead: [
        "no",
        "student_name",
        "fee_type",
        "fee",
        "discount",
        "total",
        "paid",
        "still",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: this.$t("school.reports.fee_type"), key: "fee_type" },
        { title: this.$t("school.reports.fee"), key: "fee" },
        { title: this.$t("school.reports.discount"), key: "discount" },
        { title: this.$t("school.reports.required_total"), key: "total" },
        { title: this.$t("school.reports.paid"), key: "paid" },
        { title: this.$t("school.reports.still"), key: "still" },
      ],
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: 1000000, text: this.$t("school.reports.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {
        fk_class: null,
        // fk_year: 3,
        // fk_fee: null,
        fk_student: undefined,
      },
    };
  },
  computed: {
    ...mapState({
      classes_by_branch: (state) => state.school.classes_by_branch,
      branch: (state) => state.school.branch,
      //   classes: (state) => state.school.classes,
      //   class_assign_hall: (state) => state.school.class_assign_hall,
      //   divisions: (state) => state.school.divisions,
      //   semesters: (state) => state.school.semesters,
      //   subjects: (state) => state.school.subjects,
      //   user: (state) => state.User,
    }),

    ...mapGetters({
      //   getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getBrenches: "school/getBrenches",
      //   getAcademicYears: "school/getAcademicYears",
      //   getDivisions: "school/getDivisions",
      //   getSemesters: "school/getSemesters",
      //   getClassAssignHall: "school/getClassAssignHall",
      //   getSubjects: "school/getSubjects",
      //   getFeeTypes: "school/getFeeTypes",
    }),
    getTotalDebit() {
      let total = 0;
      this.items.forEach((element) => {
        if (element.fee != undefined) {
          element.fee.forEach((fee_element) => {
            total += fee_element.still;
          });
        }
      });
      return total;
    },
    getTotalCredit() {
      let total = 0;
      this.items.forEach((element) => {
        if (element.fee != undefined) {
          element.fee.forEach((fee_element) => {
            total += fee_element.paid;
          });
        }
      });
      return total;
    },
    getTotalFee() {
      let total = 0;
      this.items.forEach((element) => {
        if (element.fee != undefined) {
          element.fee.forEach((fee_element) => {
            total += fee_element.fee;
          });
        }
      });
      return total;
    },
    getTotalDiscount() {
      let total = 0;
      this.items.forEach((element) => {
        if (element.fee != undefined) {
          element.fee.forEach((fee_element) => {
            total += fee_element.discount;
          });
        }
      });
      return total;
    },
    async getStudents() {
      await this.axios
        .get(`${this.base_url}student-list/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // page_size: this.perPage,
            // page: 1,
            class: this.filter_data.fk_class?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // year: this.filter_data.fk_year,
          },
        })
        .then((response) => {
          this.students = response.data;
        });
    },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getStudentName(fk_year) {
    //   if (this.filter_data.fk_student != undefined) {
    //     const year = this.students.find((year) => year.id === fk_year);
    //     return year.name_ar;
    //   } else {
    //     return "كل الطلاب";
    //   }
    // },
    // getClassName(fk_class) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name_ar;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/school/report/students/by_fee_type`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            fk_class: this.filter_data.fk_class?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            fk_student: this.filter_data.fk_student?.id ?? null,
            // fk_year: this.filter_data.fk_year,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            if (!this.filter_data.fk_student?.id) {
              this.filter_data.fk_student = {
                name_ar: "(كل الطلاب)",
                name_en: "(All Students)",
              };
            }
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
    colSpan() {
      const wordsToCheck = ["no", "student_name", "fee_type"];
      const matchWords = wordsToCheck.filter((word) =>
        this.selectedHead.includes(word)
      );

      return matchWords?.length ?? null;
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
