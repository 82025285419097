<template>
  <div class="d-flex" id="pagination-bar">
    <v-pagination
      v-model="page_model"
      :length="Math.ceil(length / perPage)"
      :total-visible="3"
      density="compact"
      show-first-last-page
      :next-icon="
        $i18n.locale == 'ar' ? 'mdi-chevron-left' : 'mdi-chevron-right'
      "
      :prev-icon="
        $i18n.locale == 'ar' ? 'mdi-chevron-right' : 'mdi-chevron-left'
      "
      :last-icon="$i18n.locale == 'ar' ? 'mdi-page-first' : 'mdi-page-last'"
      :first-icon="$i18n.locale == 'ar' ? 'mdi-page-last' : 'mdi-page-first'"
      @update:model-value="emitChanges(false)"
    >
    </v-pagination>
    <div class="d-flex">
      <span class="mt-2">{{ $t("school.reports.showing") }}</span>
      <v-select
        v-model="perPage"
        class="pa-0"
        :items="itemsPerPage"
        item-value="value"
        item-title="text"
        density="compact"
        hide-details
        variant="text"
        @update:model-value="emitChanges(true)"
      >
      </v-select>
      <span class="mt-2"
        >{{ " " + $t("school.reports.from") + " " }} [ {{ length }} ]
        {{ " " + $t("school.reports.item") + " " }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    length: {
      type: String,
      default: "",
    },
    newItem: {
      type: Number,
      default: null,
    },
    page: {
      type: Number,
      default: 1,
    },
    // page: {
    //   type: Number,
    //   default: 1,
    // },
    // perPage: {
    //   type: Number,
    //   default: 25,
    // },
  },
  data() {
    return {
      page_model: this.page,
      perPage: 25,
      itemsPerPage: [
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: 1000000, text: this.$t("school.reports.all") },
      ],
    };
  },
  created() {
    this.checkNewItem();
    this.emitChanges(true);
  },
  // computed:{
  //   update(){
  //     this.$emit("update:page", this.page);
  //   }
  // },
  watch: {
    page(newValue) {
      this.page_model = newValue;
    },
    // page(newValue){
    // console.log(newValue,'88888888888');
    // }
  },
  mounted() {
    this.$watch("page_model", this.updatePage);
  },
  methods: {
    updatePage(value) {
      this.$emit("update:page", value);
    },
    emitChanges(init = false) {
      let per_index = 0;
      if (init) {
        this.page_model = 1;
        // per_index = this.itemsPerPage.findIndex(
        //   (item) => item.value == this.perPage
        // );
        per_index = this.itemsPerPage.findIndex((item) => item.value == 25);
      }
      // console.log(
      //   this.itemsPerPage[0]?.value < this.itemsPerPage[per_index]?.value
      // );
      if (
        this.itemsPerPage[0]?.value < this.itemsPerPage[per_index]?.value ||
        this.length > this.itemsPerPage[per_index]?.value
      ) {
        // console.log(this.perPage);
        this.$emit("update:perPage", this.perPage);
      }
      this.$emit("update:page_model", this.page_model);
    },
    checkNewItem() {
      const item = parseInt(this.newItem);
      if (!isNaN(item)) {
        let array2Objects = this.newItem.map((num) => ({
          value: num,
          text: `${num}`,
        }));
        this.itemsPerPage = this.itemsPerPage
          .concat(array2Objects)
          .filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.value === obj.value)
          )
          .sort((a, b) => a.value - b.value);
        this.perPage = this.itemsPerPage[0]?.value;
      }
    },
  },
};
</script>
