<template>
  <v-card class="card-table">
    <div>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" color="#ccc">
            <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
            <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header.key"
              :label="header.title"
              :value="header.key"
              color="indigo"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-text-field
        color="indigo"
        v-model="text_search"
        class="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :placeholder="$t('global.data_table.search_here')"
        @input="!text_search ? getTeacher((page = 1)) : ''"
        @keyup.enter="getTeacher((page = 1))"
        density="compact"
        variant="text"
      >
      </v-text-field>
      <div
        v-if="CheckGetPermission('school.add_teacher')"
        class="d-flex justify-end"
        style="flex: 1"
      >
        <VBtn class="bg-success" @click="$router.push('add')">
          <span class="text-white">{{ $t("global.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <!-- <v-card class="pa-2"> -->
      <v-data-table-server
        :headers="selectedHeaders"
        :items="teachers"
        v-model:sort-by="sortBy"
        density="compact"
        class="elevation-1"
        hide-default-header
        @update:options="getTeacher((page = 1))"
        :loading="loading"
      >
        <template v-slot:header="{ props }">
          <th v-for="head in props.headers" :key="head">
            {{ head.text }}
          </th>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="(val, key) in item.columns" :key="key">
              <span v-if="key === 'gender'">
                <v-chip density="compact" v-if="val == 1">
                  <span>{{ $t("school.teacher_data.male") }}</span>
                </v-chip>
                <v-chip density="compact" v-if="val == 2">
                  <span>{{ $t("school.teacher_data.female") }}</span>
                </v-chip>
              </span>
              <span v-else-if="key === 'nationality'">
                <v-chip density="compact">
                  <span>{{ getNationalityName(val) }}</span>
                </v-chip>
              </span>
              <span v-else-if="key === 'marital_status'">
                <v-chip density="compact">
                  <span>{{ getMaritalStatusName(val) }}</span>
                </v-chip>
              </span>
              <span v-else-if="key == 'type'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else-if="key == 'work_period'">
                <v-chip density="compact" v-if="val == true">
                  <span>{{ $t("global.am") }}</span>
                </v-chip>
                <v-chip density="compact" v-if="val == false">
                  <span>{{ $t("global.pm") }}</span>
                </v-chip>
              </span>
              <span v-else-if="key === 'service_status'">
                <v-chip density="compact" v-if="val == 1">
                  <span>{{ $t("school.teacher_data.continus") }}</span>
                </v-chip>
                <v-chip density="compact" v-if="val == 2" color="error">
                  <span>{{ $t("school.teacher_data.stopped") }}</span>
                </v-chip>
              </span>
              <span v-else-if="key !== 'image' && key !== 'actions'">{{
                val
              }}</span>
              <v-avatar v-if="key === 'image'" size="small">
                <v-img :src="val" />
              </v-avatar>

              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  v-if="CheckGetPermission('school.change_teacher')"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="teacherEdit(item.raw.id)"
                >
                  <v-icon color="warnning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  v-if="CheckGetPermission('school.delete_teacher')"
                  size="small"
                  class="v-btn-icon"
                  @click="(selectedTeacher = item.raw), (dialog = true)"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex">
                <v-pagination  v-model="page" :length="length"  ></v-pagination>
                <div class="d-flex align-center">
                    <span>{{$t('globals.per-page')}} </span>
                    <v-select v-model="perPage" :items="itemsPerPage"  item-value="value" item-title="text" variant="solo"  ></v-select>
                </div>
            </div> -->
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ totalItems }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table-server>
    <!-- </v-card> -->
    <!-- #################################################### -->
    <!-- Dialog Delete  -->
    <!-- #################################################### -->
    <v-dialog v-model="dialog" transition="dialog-top-transition" width="auto">
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(dialog = false), (selectedTeacher = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteTeacher">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TeachersList",
  data() {
    return {
      // marital_status: [],
      // nationalities: [],
      header: undefined,
      selectedTeacher: "",
      overlay: false,
      selectedHead: [
        "name_ar",
        "name_en",
        "nationality",
        "birthdate",
        "address",
        "service_status",
        "actions",
      ],
      selectItems: "",
      text_search: "",
      dialog: false,
      alert: false,
      perPage: 10,
      length: 0,
      imageRender: "",
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: "all" },
      // ],
      page: 1,
      pagination: {},
      teachers: [],
      totalItems: null,
      loading: false,
    };
  },

  computed: {
    ...mapState({
      nationalities: (state) => state.school.countries,
      maritalStatus: (state) => state.globals.maritalStatus,
    }),
    headers() {
      return [
        { title: this.$t("school.teacher_data.name_ar"), key: "name_ar" },
        { title: this.$t("school.teacher_data.name_en"), key: "name_en" },
        { title: this.$t("school.teacher_data.basic_teacher"), key: "type" },
        {
          title: this.$t("school.teacher_data.nationality"),
          key: "nationality",
        },
        { title: this.$t("school.teacher_data.birth_date"), key: "birthdate" },
        { title: this.$t("school.teacher_data.address"), key: "address" },
        { title: this.$t("school.teacher_data.mobile"), key: "phone_number" },
        { title: this.$t("school.teacher_data.phone"), key: "home_number" },
        { title: this.$t("school.teacher_data.children"), key: "children" },
        {
          title: this.$t("school.teacher_data.service_status"),
          key: "service_status",
        },
        { title: this.$t("school.teacher_data.place"), key: "place" },
        {
          title: this.$t("school.teacher_data.date_of_employment"),
          key: "date_of_employment",
        },
        { title: this.$t("school.teacher_data.job_title"), key: "job_title" },
        {
          title: this.$t("school.teacher_data.work_period"),
          key: "work_period",
        },
        {
          title: this.$t("school.teacher_data.marital_status"),
          key: "marital_status",
        },
        { title: this.$t("school.teacher_data.gender"), key: "gender" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ];
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getTeacher();
      if (!this.nationalities.length)
        await this.getNationalities({ all: true });
      if (!this.maritalStatus.length) await this.getMaritalStatus();
      // await this.setDelay(4000)
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  methods: {
    ...mapActions({
      getNationalities: "school/getCountries",
      getMaritalStatus: "globals/getMaritalStatus",
    }),
    setDelay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    // async getNationalities() {
    //   await this.axios(`${this.base_url}/nationalities/`, {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   }).then((response) => {
    //     this.nationalities = response.data;
    //   });
    // },
    // async getMaritalStatus() {
    //   await this.axios(`${this.base_url}/marital-status/`, {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   }).then((response) => {
    //     this.marital_status = response.data;
    //   });
    // },
    getNationalityName(id) {
      var nationality = this.nationalities.find((el) => el.id == id);
      if (nationality) {
        return nationality.name_ar;
      }
    },
    getMaritalStatusName(id) {
      var marital_status = this.maritalStatus.find((el) => el.id == id);
      if (marital_status) {
        return marital_status.name;
      }
    },
    selecthead() {
      this.selectedHeaders = this.headers;
    },

    teacherEdit(id) {
      this.$router.push(`${id}/edit/`);
    },
    selectImage(e) {
      this.teacher.image = e;
    },
    async getTeacher(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/teachers/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.text_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      })
        .then((response) => {
          this.teachers = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
        })
        .catch((error) => {});
      this.loading = false;
    },

    async deleteTeacher() {
      await this.axios
        .delete(`${this.base_url}/api/teachers/${this.selectedTeacher.id}/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.getTeacher();
          this.dialog = false;
          this.$emit("successAlert", this.$t("global.success.data_deleted"));
        })
        .catch((error) => {
          this.dialog = false;
          if (error.response?.status == 418) {
            this.$emit("protectedAlert", error.response?.data?.data);
          } else {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          }
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getTeacher();
    },
    page() {
      this.getTeacher(this.page);
    },
  },
};
</script>
<style scoped>
span {
  font-size: 11px;
}
</style>
