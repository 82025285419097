<template>
  <VCard class="justify-center">
    <!-- ##########    data Table  ########### -->
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="card-table">
      <!-- <VCard-text> -->
        <div>
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc">
                <v-icon style="color: #5e7e96" class="me-2"
                  >mdi-eye-outline</v-icon
                >
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-text-field
            color="indigo"
            v-model="txt_search"
            @input="!txt_search ? (search = null) : ''"
            @keyup.enter="txt_search ? (search = txt_search) : ''"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
          <div
            v-if="CheckGetPermission('school.add_committees')"
            class="d-flex justify-end"
            style="flex: 1"
          >
            <VBtn class="bg-success" @click="addCommittee">
              <span class="text-white">{{ $t("global.create") }}</span>
              <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
            </VBtn>
          </div>
        </div>
        <!-- <v-progress-linear
          color="indigo "
          class="mb-1"
          :indeterminate="loading"
          height="2px"
          :reverse="$i18n.locale == 'ar'"
        >
        </v-progress-linear> -->
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table
          :headers="selectedHeaders"
          :items="tableList"
          item-value="name"
          item-key="id"
          fixed-footer
          :search="search"
          :items-per-page="perPage"
          density="compact"
          class="elevation-1"
          v-model:sort-by="sortBy"
          @update:options="getCommittees((page = 1))"
        >
          <template v-slot:no-data class="text-center">
            <td :colspan="selectedHeaders.length" class="text-center">
              <h3>{{ $t("global.error.failure.no_data") }}</h3>
            </td>
          </template>
          <template v-slot:no-results>
            <td :colspan="selectedHeaders.length" class="text-center">
              <h3>{{ $t("global.error.failure.no_data") }}</h3>
            </td>
          </template>
          <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox
                  v-if="key == 'checkbox'"
                  hide-details
                  density="compact"
                >
                </v-checkbox>
                <span> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <span
                    v-if="CheckGetPermission('school.change_committees')"
                    class="me-1"
                    style="color: #e5b254; border-radius: 4px"
                  >
                    <v-icon
                      @click="editCommittee(item.raw.id)"
                      size="small"
                      style="cursor: pointer"
                      icon="mdi-pencil-outline"
                    />
                  </span>
                  <span
                    v-if="CheckGetPermission('school.delete_committees')"
                    style="color: #d05251; border-radius: 4px"
                  >
                    <v-icon
                      @click="
                        (committee_del_id = item.raw.id), (del_dialog = true)
                      "
                      size="small"
                      style="cursor: pointer"
                      icon="mdi-trash-can-outline"
                    />
                  </span>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[perPage]"
          ></pagination>
            <!-- <div class="d-flex">
                        <v-pagination  v-model="page" :length="length"  ></v-pagination>
                        <div class="d-flex align-center">
                            <span>{{$t('globals.per-page')}} </span>
                            <v-select v-model="perPage" :items="itemsPerPage"  item-value="value" item-title="text" variant="solo"></v-select>
                        </div>
                    </div> -->
            <!-- <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="length"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("global.data_table.per_page") }}
                  {{ $t("global.from") }} [ {{ totalItems }} ]
                  {{ $t("global.data_table.item") }}</span
                >
              </div>
            </div> -->
          </template>
        </v-data-table>
      <!-- </VCard-text> -->
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (committee_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteCommittee">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
  <!-- loading bar -->
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      del_dialog: false,
      committee_del_id: undefined,
      perPage: 10,
      length: 0,
      committees: [],
      loading: false,
      page: 1,
      totalItems: null,
      pagination: {},
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      sortBy: [{ key: "committee_name", order: "asc" }],
      headers: [
        { title: this.$t("school.committee.halls"), key: "fk_hall" },
        {
          title: this.$t("school.committee.committee_name"),
          key: "committee_name",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: ["fk_hall", "committee_name", "actions"],
      txt_search: undefined,
      search: null,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.hall.length) await this.getAllHalls();
      this.page = this.current_page;
      // this.perPage = this.items_count;
      await this.getCommittees(this.current_page);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      hall: (state) => state.school.hall,
      current_page: (state) => state.school.current_page,
      items_count: (state) => state.school.items_count,
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      this.committees.forEach((element) => {
        let fk_hall = this.hall.find((item) => item.id == element.fk_hall);
        if (fk_hall != undefined) fk_hall = fk_hall.list_name;
        list.push({
          fk_hall: fk_hall,
          committee_name: element.name,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAllHalls: "school/getAllHalls",
      getAllCommittees: "school/getAllCommittees",
      changeCurrentPage: "school/changeCurrentPage",
    }),
    addCommittee() {
      this.changeCurrentPage({ page: this.length, count: this.perPage });
      this.$router.push("add");
    },
    editCommittee(id) {
      this.changeCurrentPage({ page: this.page, count: this.perPage });
      this.$router.push(`${id}/edit/`);
    },
    async deleteCommittee() {
      if (this.committee_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}/committees/${this.committee_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getAllCommittees({});
            this.getCommittees();
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.committee_del_id = undefined;
      }
    },
    async getCommittees(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}/committees/`, {
        params: {
          search: this.txt_search,
          sort_by: this.order_data,
          page: page,
          page_size: this.perPage,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.committees = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getCommittees();
    },
    page() {
      this.getCommittees(this.page);
    },
  },
};
</script>
