<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
      <v-col cols="6" md="4" sm="12">
        <v-autocomplete
          ref="select"
          color="indigo"
          v-model="filter_data.year"
          density="compact"
          item-title="year"
          item-value="id"
          prepend-inner-icon="mdi-calendar"
          :items="academic_years"
          :label="$t('year.name')"
          hide-details
        />
      </v-col>

      <v-col cols="6" md="4" sm="12">
        <v-autocomplete
          color="indigo"
          v-model="filter_data.semester"
          density="compact"
          item-title="name_ar"
          item-value="id"
          prepend-inner-icon="mdi-calendar"
          :items="semesters"
          :label="$t('semester.name-ar')"
          hide-details
        />
      </v-col>
      <v-col cols="6" md="4" sm="12">
        <v-autocomplete
          color="indigo"
          v-model="filter_data.class"
          density="compact"
          item-title="name_ar"
          item-value="id"
          prepend-inner-icon="mdi-google-classroom"
          :items="classes"
          :label="$t('class.select')"
          hide-details
          :error-messages="v$.filter_data.class.$errors.map((e) => e.$message)"
        />
      </v-col>
      <div>
        <v-btn
          density="compact"
          class="mx-3 mt-2"
          color="indigo"
          @click="checkFilterData"
        >
          <span>{{ $t("globals.show") }}</span>
        </v-btn>
      </div>
    </v-row>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <v-row class="my-4 text-grey-darken-2">
      <v-col cols="4" class="text-center">
        <h2>الجمهورية اليمنية</h2>
        <h3 v-if="school.length > 0">
          {{ school[0].ministry_name ? school[0].ministry_name : "" }}
        </h3>
        <h5 v-if="school.length > 0">{{ school[0].arabic_name }}</h5>
      </v-col>
      <v-col cols="4" class="text-center">
        <h4>بسم الله الرحمن الرحيم</h4>
        <v-responsive v-if="school.length > 0">
          <v-avatar size="80">
            <v-img :src="school[0].logo"></v-img>
          </v-avatar>
        </v-responsive>
      </v-col>
      <v-col cols="4" class="text-center">
        <div>{{ getclassName(filter_data.class) }}</div>
        
        <div>
          {{ $t("semester.name-ar") }} : {{ getSemesterName(filter_data.semester) }}
        </div>
      </v-col>
    </v-row>
    <h3 class="text-center text-grey-darken-2">
      {{ $t("report.optional-subject-student-degree") }}
      {{ getclassName(filter_data.class) }}
      <div>للسنه الدراسية {{ getYearName(filter_data.year) }}</div>
    </h3>
    <v-card-text class="mb-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="px-1"
                style="min-width: 30px"
              >
                <v-icon color="icon-color">mdi-eye</v-icon>
                <v-icon color="icon-color">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
          >
            <v-icon color="icon-color">mdi-refresh</v-icon>
          </v-btn>
        </div>
        <!-- search box  -->
        <div class="d-flex justify-center" style="flex: 3">
          <div
            class="w-100 d-flex border rounded-pill"
            style="font-size: 12px !important"
          >
            <v-icon color="icon-color" class="mt-1 mx-1">mdi-magnify</v-icon>
            <input
              type="text"
              v-model="txt_search"
              @input="getData((page = 1))"
              class="flex-fill search my-1"
              :placeholder="$t('global.data_table.search_here')"
            />
          </div>
        </div>
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list id="print-menu" density="compact">
              <v-list-item @click="printTable((all = false))">{{
                $t("globals.shown-records")
              }}</v-list-item>
              <v-list-item @click="printTable((all = true))">{{
                $t("globals.all-records")
              }}</v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
          >
            <v-icon color="icon-color">mdi-file-excel</v-icon>
            <v-tooltip activator="parent" class="tooltip" location="top">
              <small>{{ $t("export.excel") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn
            density="compact"
            class="mx-1"
            @click="
              (exist = false),
                (filter_data.class = null),
                (filter_data.year = null),
                (filter_data.semester = null),
                (exist = false),
                v$.filter_data.$reset()
            "
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td class="text-center border" v-if="selectedHead.includes('no')">
                  {{ (page - 1) * perPage + key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student_name }}
                </td>
                <td class="text-center border" v-if="selectedHead.includes('subject')">
                  {{ item.subject_name }}
                </td>
                <td class="text-center border" v-if="selectedHead.includes('collector')">
                  {{ item.collector }}
                </td>
                <td class="text-center border" v-if="selectedHead.includes('final_exam')">
                  {{ item.final_exam }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-autocomplete
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-autocomplete>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit('updateLoadingValue',true)
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
      await this.getSemesters();
      await this.getAcademicYears();
      await this.getClasses();
      await this.axios
        .get(`${this.base_url}school-data/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.school = response.data;
        });
      this.$store.commit('updateLoadingValue',false)
    } catch (error) {
      this.$store.commit('updateLoadingValue',false)
      
    }
    
  },

  data() {
    return {
      school: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["no", "student_name", "subject", "collector", "final_exam"],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.student-name"), key: "student_name" },
        { title: this.$t("subject.subject"), key: "subject" },
        { title: this.$t("class-marks.collector"), key: "collector" },
        { title: this.$t("class-marks.final-exam"), key: "final_exam" },
      ],
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: "all" },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      academic_years: (state) => state.school.academic_years,
      semesters: (state) => state.school.semesters,
      user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    getYearName(year_id) {
      if (year_id) {
        const year = this.academic_years.find((year) => year.id === year_id);
        return year.year;
      }
      return "-------------";
    },
    getclassName(class_id) {
      const objectClass = this.classes.find((objectClass) => objectClass.id === class_id);
      return objectClass.name_ar;
    },
    getSemesterName(semester_id) {
      if (semester_id) {
        const objectSemester = this.semesters.find(
          (objectSemester) => objectSemester.id === semester_id
        );
        return objectSemester.name_ar;
      }
      return "-------------";
    },
    ...mapActions({
      getClasses: "school/getClasses",
      getAcademicYears: "school/getAcademicYears",
      getClassAssignHall: "school/getClassAssignHall",
      getAllMonths: "school/getAllMonths",
      getSemesters: "school/getSemesters",
      getAllEstimations: "school/getAllEstimations",
    }),
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.getData();
      }
      window.print();
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/optional_subject_student_degree`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: this.txt_search,
            page: page,
            page_size: this.perPage,
            class_number: this.filter_data.class,
            year: this.filter_data.year,
            semester: this.filter_data.semester,
          },
        })
        .then((response) => {
          if (response.data.results) {
            this.items = response.data.results;
            this.exist = true;
            this.totalItems = response.data.pagination.count;
            this.length = response.data.pagination.num_pages;
            this.pagination = response.data.pagination;
            this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        class: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>

<style>
.search:focus {
  outline: unset;
}

@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
