<template>
  <VCard class="justify-center">
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="card-table">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-text-field
          color="indigo"
          v-model="txt_search"
          @input="!txt_search ? (search = null) : ''"
          @keyup.enter="txt_search ? (search = txt_search) : ''"
          class="search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          :placeholder="$t('global.data_table.search_here')"
          density="compact"
          variant="text"
        >
        </v-text-field>
        <div
          v-if="CheckGetPermission('school.add_settingsstudentcommittees')"
          class="d-flex justify-end"
          style="flex: 1"
        >
          <VBtn class="bg-success" @click="addCommitteePlan">
            <span class="text-white">{{ $t("global.create") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <!-- <v-progress-linear
        color="indigo "
        class="mb-1"
        :indeterminate="loading"
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear> -->
      <v-data-table
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        fixed-footer
        :search="search"
        :items-per-page="perPage"
        density="compact"
        class="elevation-1"
        v-model:sort-by="sortBy"
        @update:options="getCommitteesPlan((page = 1))"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template>
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="
                    CheckGetPermission(
                      'school.change_settingsstudentcommittees'
                    )
                  "
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editCommitteePlan(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="
                    CheckGetPermission(
                      'school.delete_settingsstudentcommittees'
                    )
                  "
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="
                      (committeePlan_del_id = item.raw.id), (del_dialog = true)
                    "
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex">
                  <v-pagination  v-model="page" :length="length"  ></v-pagination>
                  <div class="d-flex align-center">
                      <span>{{$t('globals.per-page')}} </span>
                      <v-select v-model="perPage" :items="itemsPerPage"  item-value="value" item-title="text" variant="solo"></v-select>
                  </div>
              </div> -->
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ totalItems }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (committeePlan_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteCommitteePlan">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      del_dialog: false,
      // loadingPage: true,
      committeePlan_del_id: undefined,
      perPage: 10,
      length: 0,
      committeesPlans: [],
      page: 1,
      pagination: {},
      loading: false,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      totalItems: null,
      sortBy: [{ key: "id", order: "asc" }],
      headers: [
        { title: this.$t("school.class.name"), key: "fk_class" },
        {
          title: this.$t("school.committee.committee_name"),
          key: "fk_committee",
        },
        {
          title: this.$t("school.student.students_count"),
          key: "student_no_class",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: ["fk_class", "fk_committee", "student_no_class", "actions"],
      txt_search: undefined,
      search: null,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.classes.length) await this.getClasses();
      await this.getAllCommittees({});
      this.page = this.current_page;
      // this.perPage = this.items_count;
      await this.getCommitteesPlan(this.current_page);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      committee: (state) => state.school.committee,
      classes: (state) => state.school.classes,
      current_page: (state) => state.school.current_page,
      items_count: (state) => state.school.items_count,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    tableList() {
      let list = [];
      this.committeesPlans.forEach((element) => {
        let fk_committee = this.committee.find(
          (item) => item.id == element.fk_committee
        );
        if (fk_committee != undefined) fk_committee = fk_committee.name;

        let fk_classes = this.classes.find(
          (item) => item.id == element.fk_class
        );
        if (fk_classes != undefined) fk_classes = fk_classes.name_ar;

        list.push({
          fk_class: fk_classes,
          fk_committee: fk_committee,
          student_no_class: element.student_no_class,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAllCommittees: "school/getAllCommittees",
      changeCurrentPage: "school/changeCurrentPage",
      getClasses: "school/getClasses",
    }),
    addCommitteePlan() {
      this.changeCurrentPage({ page: this.length, count: this.perPage });
      this.$router.push("add");
    },
    editCommitteePlan(id) {
      this.changeCurrentPage({ page: this.page, count: this.perPage });
      this.$router.push(`${id}/edit/`);
    },
    async deleteCommitteePlan() {
      if (this.committeePlan_del_id != undefined) {
        let result = await this.axios
          .delete(
            `${this.base_url}/committees-plan/${this.committeePlan_del_id}/`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));

            this.getCommitteesPlan();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.del_dialog = false;
        this.committeePlan_del_id = undefined;
      }
    },
    async getCommitteesPlan(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}/committees-plan/`, {
        params: {
          search: this.txt_search,
          sort_by: this.order_data,
          page: page,
          page_size: this.perPage,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.committeesPlans = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.loading = false;
          this.totalItems = response.data.pagination.count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getCommitteesPlan();
    },
    page() {
      this.getCommitteesPlan(this.page);
    },
  },
};
</script>
