<template>
  <th class="border line">
    <div class="cell">
      <span class="cell--topRight text-white">{{ leftLabel }}</span>
      <span class="cell--bottomLift text-white">{{ rightLabel }}</span>
    </div>
  </th>
</template>

<script>
export default {
  name: "DiagonalHeader",
  props: {
    leftLabel: {
      type: String,
      required: true,
    },
    rightLabel: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.borders {
  border-width: thin !important;
  border-style: solid !important;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}
.cell {
  position: relative;
  display: grid;
  min-width: max-content;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  overflow: hidden;
}
.cell::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0 !important;
  background: linear-gradient(
    to bottom right,
    transparent 49%,
    white,
    transparent 51%
  );
  z-index: 0;
}
.cell--topRight,
.cell--bottomLift {
  position: relative;
  z-index: 1;
}
.cell--topRight {
  grid-column-start: 2;
  text-align: center;
}
.cell--bottomLift {
  grid-column-start: 1;
}
</style>
