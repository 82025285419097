import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Globals/HomeView.vue";
import Login from "../views/Globals/Login.vue";
import School from "./School";
import BusSystem from "./BusSystem";
import Users from "./Users";
import Globals from "./GlobalsRoutes";
import Report from "./Report";
import example from "../views/Examples/Dashboard";
import axios from "axios";
import NotFoundView from "../views/Globals/NotFoundView.vue";
import i18n from "@/i18n";
import store from "@/store";

import { api_url } from "@/main";
import jwt_decode from "jwt-decode";

i18n.global.locale = localStorage.getItem("user-locale");
// const sys_type=localStorage.getItem('sys_type')

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "/",
    component: Home,
    children: [
      ...School,
      {
        path: "report",
        name: "report",
        children: [...Report],
      },
      {
        path: "busSystem",
        name: "busSystem",
        children: [...BusSystem],
      },
      {
        path: "user",
        children: [...Users],
      },
      {
        path: "globals",
        children: [...Globals],
      },
    ],
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "notFound",
  //   component: NotFoundView,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const user_info = localStorage.getItem("userinfo");
  const username = localStorage.getItem("username");
  if (!token || !username) {
    if (to.path !== "/login") {
      next("/login");
    } else {
      next();
    }
  } 
else if (token && to.path === "/login") {
    next("/");
  } else {
    const decode = jwt_decode(token);
    if(decode.exp < Date.now() / 1000) {
      window.location.reload();
      store.dispatch("logout");
      axios(`${api_url}api/delete/session/`, {
        params: {
          user: username,
        },
      });
    }
    store.dispatch("updateAuthenticated", token);
    store.dispatch("updatePermission", user_info);
    next();
  }
});

export default router;
