<template>
  <VCard class="justify-center">
    <v-expand-transition v-if="getUserRole < 2">
      <div v-show="(update && getUserRole < 2) || getUserRole < 2">
        <VForm
          ref="form"
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            (CheckGetPermission('school.add_schedule') ||
              (is_update && CheckGetPermission('school.change_schedule')))
          "
        >
          <VCard-text class="card-fields">
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="schedule.quotas"
                    prepend-inner-icon="mdi-calendar-clock"
                    :label="$t('school.schedule_data.schedule_quotas')"
                    :placeholder="
                      $t('school.schedule_data.placeholder_schedule_quotas')
                    "
                    density="compact"
                    counter="100"
                    clearable
                    :rules="[$required, $max_length(100)]"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                <VCardItem class="pa-0">
                  <VRadioGroup
                    inline
                    :label="$t('school.schedule_data.schedule_period')"
                    v-model="schedule.period"
                    density="compact"
                    hide-details="auto"
                  >
                    <VRadio
                      :label="$t('school.schedule_data.morning_period')"
                      :value="true"
                    ></VRadio>
                    <VRadio
                      :label="$t('school.schedule_data.evening_period')"
                      :value="false"
                    ></VRadio>
                  </VRadioGroup>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <v-col cols="12" md="8" class="px-0">
                <form ref="form2" class="d-flex flex-wrap">
                  <VCol cols="12" md="6" class="flex-grow-1">
                    <VTextField
                      ref="min"
                      v-model="schedule.quotas_start_time"
                      :label="$t('school.schedule_data.schedule_start_time')"
                      prepend-inner-icon="mdi-timer-sand"
                      density="compact"
                      type="time"
                      clearable
                      :rules="[$required, ...start_time_rule]"
                      @input="validate()"
                    >
                    </VTextField>
                  </VCol>
                  <VCol cols="12" xs="12" sm="12" md="6">
                    <VTextField
                      ref="max"
                      v-model="schedule.quotas_end_time"
                      :label="$t('school.schedule_data.schedule_end_time')"
                      prepend-inner-icon="mdi-timer-sand mdi-rotate-180"
                      density="compact"
                      type="time"
                      clearable
                      :rules="[$required, ...end_time_rule]"
                      @input="validate()"
                    >
                    </VTextField>
                  </VCol>
                </form>
              </v-col>
            </VRow>
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="8">
                <VTextarea
                  v-model="schedule.note"
                  clearable
                  :label="$t('global.note')"
                  counter="250"
                  no-resize
                  density="compact"
                  rows="3"
                  :rules="[$max_length(250)]"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
              </VCol>
            </VRow>
          </VCard-text>
        </VForm>
        <VCardActions
          class="px-8"
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            (CheckGetPermission('school.add_schedule') ||
              (is_update && CheckGetPermission('school.change_schedule')))
          "
        >
          <VBtn
            v-if="
              !is_update &&
              checkRole(['programmer', 'big_admin']) &&
              CheckGetPermission('school.add_schedule')
            "
            class="bg-indigo"
            @click="saveSchedule"
            :loading="is_loading"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn
            v-if="
              is_update &&
              checkRole(['programmer', 'big_admin']) &&
              CheckGetPermission('school.change_schedule')
            "
            class="bg-success"
            @click="updateSchedule"
            :loading="is_loading"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm">
            {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
              :color="!is_update ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
        </VCardActions>
        <v-divider class="mt-5" />
      </div>
    </v-expand-transition>
    <!-- ##########    data Table  ########### -->
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div class="d-flex py-2" style="gap: 1rem">
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-text-field
          color="indigo"
          v-model="text_search"
          @input="!text_search ? (search = null) : ''"
          @keyup.enter="search = text_search"
          class="search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          :placeholder="$t('global.data_table.search_here')"
          density="compact"
          variant="text"
        >
        </v-text-field>
      </div>

      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :headers="selectedHeaders"
        :items="tableList"
        :items-per-page="perPage"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('school.change_schedule')
                  "
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editSchedule(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('school.delete_schedule')
                  "
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="
                      (schedule_del_id = item.raw.id), (del_dialog = true)
                    "
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (schedule_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteSchedule">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      schedule: {
        period: true,
      },
      perPage: 10,
      page: 1,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 20, text: "20" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 200, text: this.$t("global.data_table.all") },
      // ],
      is_update: false,
      is_loading: false,
      del_dialog: false,
      schedule_del_id: undefined,
      headers: [
        {
          title: this.$t("school.schedule_data.schedule_quotas"),
          key: "quotas",
        },
        {
          title: this.$t("school.schedule_data.schedule_period"),
          key: "period",
        },
        {
          title: this.$t("school.schedule_data.schedule_start_time"),
          key: "quotas_start_time",
        },
        {
          title: this.$t("school.schedule_data.schedule_end_time"),
          key: "quotas_end_time",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "quotas",
        "period",
        "quotas_start_time",
        "quotas_end_time",
        "note",
        "actions",
      ],
      text_search: undefined,
      search: undefined,
      start_time_rule: [
        (value) =>
          !value ||
          this.schedule.quotas_end_time == undefined ||
          value < this.schedule.quotas_end_time ||
          this.$t("school.schedule_data.schedule_timing_error"),
        (value) =>
          this.checkTime(value) ||
          this.$t("school.schedule_data.schedule_timing_error2"),
      ],
      end_time_rule: [
        (value) =>
          !value ||
          this.schedule.quotas_end_time > "1.5pm" ||
          this.schedule.quotas_start_time == undefined ||
          value > this.schedule.quotas_start_time ||
          this.$t("school.schedule_data.schedule_timing_error"),
        (value) =>
          this.checkTime(value) ||
          this.$t("school.schedule_data.schedule_timing_error2"),
      ],
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.getUserRole >= 2) {
        this.headers.pop();
      }
      if (!this.schedules.length) await this.getSchedule(true);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      schedules: (state) => state.school.schedules,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      this.schedules.forEach((element) => {
        list.push({
          quotas: element.quotas,
          quotas_start_time: element.quotas_start_time,
          quotas_end_time: element.quotas_end_time,
          period: element.period
            ? this.$t("school.schedule_data.morning_period")
            : this.$t("school.schedule_data.evening_period"),
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
  },
  methods: {
    ...mapActions({
      getSchedule: "school/getSchedule",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.schedule.id = undefined;
      this.schedule.period = true;
    },
    editSchedule(data) {
      let schedule = this.schedules.find((item) => item.id == data.id);
      if (schedule != undefined) {
        for (const key in schedule) this.schedule[key] = schedule[key];
        this.schedule.data_entry = undefined;
        this.schedule.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    checkTime(value) {
      let result = true;
      this.schedules.forEach((element) => {
        if (
          element.quotas_start_time < value &&
          element.quotas_end_time.slice(0, -3) > value &&
          element.id != this.schedule.id
        )
          result = false;
      });
      return result;
    },
    async saveSchedule() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        let result = await this.axios
          .post(this.base_url + "/schedule/", this.schedule, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_added"));
            this.resetForm();
            this.getSchedule(true);
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
      }
    },
    async updateSchedule() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.schedule.id != undefined) {
        this.is_loading = true;
        let result = await this.axios
          .put(
            `${this.base_url}/schedule/${this.schedule.id}/`,
            this.schedule,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            this.getSchedule(true);
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
      }
    },
    async deleteSchedule() {
      if (this.schedule_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}/schedule/${this.schedule_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getSchedule(true);
            this.resetForm();
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.del_dialog = false;
        this.schedule_del_id = undefined;
      }
    },
    validate() {
      if (this.schedule.quotas_start_time && this.schedule.quotas_end_time) {
        this.$refs.max.validate();
        this.$refs.min.validate();
      }
    },
  },
};
</script>
