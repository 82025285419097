<template>
  <VCard class="justify-center">
    <VForm
      v-if="
        CheckGetPermission('school.add_examschedule') ||
        (id && CheckGetPermission('school.change_examschedule'))
      "
      ref="form"
    >
      <VCard-text class="card-fields">
        <VRow>
          <VCol cols="12" xs="12" sm="12" md="6">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="classes"
                v-model="exam_schedule.fk_class"
                prepend-inner-icon="mdi-google-classroom"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.class.name')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
                @update:model-value="
                  (edit_exam_schedule.fk_subject = null),
                    (exam_schedule.exam_details = [{}])
                "
              ></VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow v-if="id">
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="
                  exam_schedule.fk_class
                    ? getSubjectsByClass(exam_schedule.fk_class)
                    : []
                "
                v-model="edit_exam_schedule.fk_subject"
                prepend-inner-icon="mdi-book-open-variant"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.subject.name')"
                persistent-hint
                density="compact"
                :rules="[$required]"
                :error-messages="message[0]"
                @update:model-value="
                  message[0] ? (message[0] = undefined) : true
                "
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="period_list"
                v-model="edit_exam_schedule.fk_exam_period"
                prepend-inner-icon="mdi-timetable"
                item-title="name"
                item-value="id"
                :label="$t('school.exams.schedule_period')"
                persistent-hint
                density="compact"
                :rules="[$required]"
                clearable
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
            <VCardItem class="pa-0">
              <v-text-field
                v-model="edit_exam_schedule.exam_date"
                density="compact"
                :placeholder="$t('school.exams.exam_date')"
                clearable
                prepend-inner-icon="mdi-calendar-clock-outline"
                id="exam_date"
                readonly
                :rules="[$required]"
              >
              </v-text-field>
              <Datetime
                v-model="date_start"
                type="date"
                element="exam_date"
                :color="'#0747a6'"
                @input="(e) => getExamDateUpdate(e)"
                :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                :placeholder="$t('school.homework.deadline')"
                :calendar="dateType"
              >
              </Datetime>
              <!-- <VTextField
                v-model="edit_exam_schedule.exam_date"
                :label="$t('school.exams.exam_date')"
                density="compact"
                type="date"
                :rules="[$required]"
              >
              </VTextField> -->
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="day"
                v-model="edit_exam_schedule.day"
                prepend-inner-icon="mdi-calendar-today"
                item-title="name"
                item-value="id"
                :label="$t('school.exams.exam_day')"
                persistent-hint
                density="compact"
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
            <VCardItem class="pa-0">
              <VTextField
                v-model="edit_exam_schedule.note"
                :label="$t('global.note')"
                density="compact"
                counter="250"
                type="text"
                :rules="[$max_length(250)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VCard
          :disabled="!exam_schedule.fk_class"
          v-if="!id"
          class="justify-center mt-2"
        >
          <VCard-text class="mt-4">
            <VRow
              class="px-2 pb-0"
              v-for="(item, index) in exam_schedule.exam_details"
              :key="index"
            >
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="
                      exam_schedule.fk_class
                        ? getSubjectsByClass(exam_schedule.fk_class)
                        : []
                    "
                    v-model="item.fk_subject"
                    prepend-inner-icon="mdi-book-open-variant"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('school.subject.name')"
                    persistent-hint
                    density="compact"
                    :rules="[$required]"
                    clearable
                    :error-messages="message[index]"
                    @update:model-value="
                      message[index] ? (message[index] = undefined) : true
                    "
                  ></VAutocomplete>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="period_list"
                    v-model="item.fk_exam_period"
                    prepend-inner-icon="mdi-timetable"
                    item-title="name"
                    item-value="id"
                    :label="$t('school.exams.schedule_period')"
                    persistent-hint
                    density="compact"
                    :rules="[$required]"
                  ></VAutocomplete>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                  <v-text-field
                    v-model="item.exam_date"
                    density="compact"
                    :placeholder="$t('school.exams.exam_date')"
                    clearable
                    prepend-inner-icon="mdi-calendar-clock-outline"
                    :id="'exam_date' + index"
                    readonly
                    :rules="[$required]"
                  >
                  </v-text-field>
                  <Datetime
                    type="date"
                    :element="'exam_date' + index"
                    :color="'#0747a6'"
                    @input="(e) => getExamDate(e, index)"
                    :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                    :placeholder="$t('school.homework.deadline')"
                    :calendar="dateType"
                  >
                  </Datetime>
                  <!-- <VTextField
                    v-model="item.exam_date"
                    :label="$t('school.exams.exam_date')"
                    density="compact"
                    type="date"
                    :rules="[$required]"
                  >
                  </VTextField> -->
                </VCardItem>
              </VCol>
              <!-- <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="day"
                    v-model="item.day"
                    prepend-inner-icon="mdi-calendar-today"
                    item-title="name"
                    item-value="id"
                    :label="$t('school.exams.exam_day')"
                    persistent-hint
                    density="compact"
                    :rules="[$required]"
                  ></VAutocomplete>
                </VCardItem>
              </VCol> -->
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="item.note"
                    :label="$t('global.note')"
                    density="compact"
                    type="text"
                    counter="250"
                    :rules="[$max_length(250)]"
                    prepend-inner-icon="mdi-note-outline"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow class="my-1 mx-8 d-flex flex-wrap" elevation="4">
              <VBtn
                variant="text"
                size="x-small"
                class="text-success ma-3 danger"
                icon="mdi-plus-thick"
                @click.prevent="addRow()"
              >
                <!-- icon="mdi-plus-thick" -->
                <v-icon color="success"></v-icon>
              </VBtn>
              <VBtn
                v-if="exam_schedule.exam_details.length > 1"
                variant="text"
                size="x-small"
                class="text-error ma-3 danger"
                icon="mdi-minus-thick"
                @click.prevent="removeRow()"
              >
              </VBtn>
              <!--               
              <VBtn
                variant="elevated"
                size="small"
                elevation="4"
                color="grey-lighten-2"
                class="ma-3 danger"
                @click.prevent="addRow()"
              >
                <VIcon icon="mdi-plus" color="grey-darken-2"></VIcon>
              </VBtn>
              <VBtn
                variant="elevated"
                size="small"
                elevation="4"
                color="grey-lighten-2"
                class="ma-3 danger"
                @click.prevent="removeRow()"
              >
                <VIcon icon="mdi-minus" color="grey-darken-2"></VIcon>
              </VBtn> -->
            </VRow>
          </VCard-text>
        </VCard>
      </VCard-text>
    </VForm>
    <VCardActions
      v-if="
        CheckGetPermission('school.add_examschedule') ||
        (id && CheckGetPermission('school.change_examschedule'))
      "
      class="px-8 d-flex flex-wrap"
    >
      <VBtn
        v-if="!id && CheckGetPermission('school.add_examschedule')"
        class="bg-indigo"
        :loading="is_loading"
        @click="saveExamSchedule"
        size="small"
        :disabled="exam"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="id && CheckGetPermission('school.change_examschedule')"
        class="bg-success"
        :loading="is_loading"
        @click="updateExamSchedule"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Datetime from "vue3-datetime-js";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  components: { Datetime },
  data() {
    return {
      exam_schedule: {
        // fk_branch: undefined,
        fk_class: undefined,
        // fk_semester: undefined,
        exam_details: [{}],
        data_entry: undefined,
      },
      edit_exam_schedule: {},
      is_loading: false,
      message: [],
      duplicate: (value) => {
        const list = this.exam_schedule.exam_details.filter(
          (item) => item.fk_subject == value
        );
        if (list?.length > 1) {
          return this.$t("global.error.validation.unique");
        } else {
          return true;
        }
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.branch.length) await this.getBrenches({});
      if (!this.semesters.length) await this.getSemesters();
      if (!this.classes.length) await this.getClasses();
      if (!this.exam_period.length) await this.getExamPeriods();
      if (!this.period.length) await this.getPeriod();
      if (!this.subjects.length) await this.getSubjects({});
      if (!this.day.length) await this.getDay();
      if (this.id != undefined)
        await this.axios
          .get(`${this.base_url}exam-schedule/${this.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.edit_exam_schedule = response.data;
            // this.exam_schedule.fk_branch = this.edit_exam_schedule.fk_branch;
            this.exam_schedule.fk_class = this.edit_exam_schedule.fk_class;
            // this.exam_schedule.fk_semester =
            // this.edit_exam_schedule.fk_semester;
          });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
      semesters: (state) => state.school.semesters,
      classes: (state) => state.school.classes,
      subjects: (state) => state.school.subjects,
      exam_period: (state) => state.school.exam_period,
      period: (state) => state.globals.period,
      day: (state) => state.globals.day,
      user: (state) => state.User,
    }),

    ...mapGetters({
      getSubjectsByClass: "school/getSubjectsByClass",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    dateType() {
      return localStorage.getItem("date") == "h" ? "hijri" : "";
    },
    period_list() {
      let list = [];
      this.exam_period.forEach((element) => {
        let period = this.period.find((item) => item.id == element.period);
        if (period != undefined) period = period.name;
        list.push({
          id: element.id,
          name: period,
        });
      });
      return list;
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
      getSemesters: "school/getSemesters",
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getExamPeriods: "school/getExamPeriods",
      getPeriod: "globals/getPeriod",
      getDay: "globals/getDay",
    }),

    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "exam-schedule-view" });
      } else {
        this.$refs.form.reset();
        this.is_update = false;
      }
    },
    getExamDateUpdate(e) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.edit_exam_schedule.exam_date = date;
    },
    getExamDate(e, index) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.exam_schedule.exam_details[index].exam_date = date;
    },
    addRow() {
      this.exam_schedule.exam_details.push({
        fk_subject: undefined,
        fk_exam_period: undefined,
        exam_date: undefined,
        day: undefined,
        note: undefined,
      });
    },
    removeRow() {
      this.exam_schedule.exam_details.pop();
    },
    async saveExamSchedule() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.exam_schedule.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "exam-schedule/", this.exam_schedule, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));
            this.resetForm();
          })
          .catch((error) => {
            this.message = error.response.data.map((item) => {
              if (item.non_field_errors) {
                return this.$t("global.error.validation.unique");
              } else {
                return true;
              }
            });
            if (
              error.response.data?.find((item) => item.non_field_errors)?.non_field_errors
            ) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.unique")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async updateExamSchedule() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.edit_exam_schedule.id != undefined) {
        this.is_loading = true;
        // this.edit_exam_schedule.fk_branch = this.exam_schedule.fk_branch;
        this.edit_exam_schedule.fk_class = this.exam_schedule.fk_class;
        // this.edit_exam_schedule.fk_semester = this.exam_schedule.fk_semester;
        this.edit_exam_schedule.updated_by = this.user;
        this.edit_exam_schedule.data_entry = undefined;
        await this.axios
          .put(
            `${this.base_url}exam-schedule/${this.edit_exam_schedule.id}/`,
            this.edit_exam_schedule,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));

            this.resetForm();
            // this.$router.push({ name: "exam-schedule-view" });
          })
          .catch((error) => {
            this.message[0] = error.response.data?.non_field_errors
              ? this.$t("global.error.validation.unique")
              : true;
            if (error.response.data?.non_field_errors) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.unique")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
