<template>
  <v-card class="justify-center">
    <v-card-text class="card-view">
      <VForm ref="form">
        <VRow>
          <VCol cols="12" lg="4" md="4">
            <VAutocomplete
              color="indigo"
              v-model="student_installments.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              :items="classes"
              clearable
              :label="$t('school.class.select')"
              @update:modelValue="fk_division = undefined"
              :rules="[$required]"
            ></VAutocomplete>
          </VCol>
          <VCol cols="12" lg="4" md="4">
            <VAutocomplete
              color="indigo"
              v-model="fk_division"
              density="compact"
              item-title="name"
              :no-data-text="$t('school.division.not_found')"
              item-value="id"
              clearable
              prepend-inner-icon="mdi-format-list-bulleted"
              :items="getDivisionsByClass(student_installments.fk_class)"
              :label="$t('school.division.select')"
              @update:modelValue="getStudents()"
              :rules="[$required]"
            ></VAutocomplete>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" lg="4" md="4">
            <VAutocomplete
              color="indigo"
              v-model="student_installments.fk_student"
              density="compact"
              item-title="name_ar"
              item-value="id"
              :items="student_list"
              clearable
              @update:modelValue="
                getFeeTypes(),
                  (fk_fee_type = undefined),
                  (installments_list = [])
              "
              prepend-inner-icon="mdi-school"
              :label="$t('school.student.name')"
              :rules="[$required]"
            />
          </VCol>
          <VCol cols="12" lg="4" md="4">
            <VAutocomplete
              color="indigo"
              v-model="fk_fee_type"
              density="compact"
              item-title="name"
              item-value="id"
              :items="fee_type_list"
              clearable
              @update:modelValue="
                student_installments.fk_fee_type = fk_fee_type
              "
              prepend-inner-icon="mdi-cash"
              :label="$t('school.fees.fee_type')"
              :rules="[$required]"
            />
          </VCol>
          <v-col cols="6" sm="2">
            <v-btn
              density="comfortable"
              color="indigo"
              @click="getInstallments()"
              :loading="loading.show"
            >
              <span>{{ $t("global.show") }}</span>
            </v-btn>
          </v-col>
        </VRow>
      </VForm>
      <!-- </v-card> -->
    </v-card-text>
    <v-expand-transition>
      <div v-show="installments_list.length" class="card-table">
        <VCard class="mt-4">
          <VTable>
            <thead>
              <tr>
                <th>{{ $t("school.student_fees.installment_date") }}</th>
                <th>{{ $t("school.student_fees.installment") }}</th>
                <th>{{ $t("school.student_fees.installment_paid") }}</th>
                <th>{{ $t("school.student_fees.installment_remain") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in installments_list" :key="payment">
                <td v-if="payment.installment_date != undefined">
                  {{ payment.installment_date }}
                </td>
                <td v-else>{{ payment.first_installments_date }}</td>

                <td v-if="payment.installment_date != undefined">
                  {{}}
                  {{
                    parseFloat(payment.installment_number)
                      ?.toFixed(2)
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                </td>
                <td v-else>
                  {{
                    (
                      parseFloat(payment.paid_amount) +
                      parseFloat(payment.still)
                    )
                      ?.toFixed(2)
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                </td>

                <td v-if="payment.installment_date != undefined">
                  {{ payment.paid_mount }}
                </td>
                <td v-else>{{ payment.paid_amount }}</td>

                <td v-if="payment.installment_date != undefined">
                  {{
                    parseFloat(payment.installment_number - payment.paid_mount)
                      ?.toFixed(2)
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                </td>
                <td v-else>
                  {{
                    parseFloat(payment.still)
                      ?.toFixed(2)
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                </td>
              </tr>
            </tbody>
          </VTable>
        </VCard>
      </div>
    </v-expand-transition>
    <v-card-actions class="px-8" v-if="installments_list.length">
      <v-btn
        v-if="CheckGetPermission('school.add_installmentspayments')"
        @click="cleanDialog(), (show_add_dialog = true)"
        class="bg-indigo"
        size="small"
      >
        <span class="text-white">
          {{ $t("school.student_fees.add_payment") }}
        </span>
        <v-icon icon="mdi-plus" color="white" end></v-icon>
      </v-btn>
      <v-btn
        @click="getPayments()"
        class="bg-success"
        size="small"
        :loading="loading.show_pay"
      >
        <span class="text-white">
          {{ $t("school.student_fees.show_payment") }}
        </span>
        <v-icon icon="mdi-pencil" color="white" end></v-icon>
      </v-btn>
      <v-btn @click="cleanMarks" class="mx-3" size="small">
        <span>
          {{ $t("global.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="show_add_dialog"
      :dir="this.$i18n.locale === 'ar' ? 'rtl' : 'ltr'"
      width="600"
      class="text-right"
    >
      <v-card>
        <v-card-title class="text-right">
          <v-icon icon="mdi-text-box-plus" />
          <h3 class="d-inline" style="padding: 0 4px">
            {{ $t("school.student_fees.add_payment") }}
          </h3>
          <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
        </v-card-title>
        <v-card-text>
          <VForm ref="form2">
            <VRow class="">
              <VCol cols="12" md="6">
                <VTextField
                  v-model="student_installments.payment"
                  type="number"
                  density="compact"
                  clearable
                  prepend-inner-icon="mdi-numeric"
                  :label="$t('school.student_fees.payment_amount')"
                  :rules="[
                    $required,
                    paid_still !== null
                      ? $max_value(
                          paid_still,
                          $t('school.student_fees.installment_still')
                        )
                      : '',
                    $min_value(1),
                  ]"
                />
              </VCol>
              <VCol cols="12" md="6">
                <VAutocomplete
                  color="indigo"
                  v-model="student_installments.fk_fee_type"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  :items="fee_type_list"
                  clearable
                  @update:modelValue="getInstallments()"
                  prepend-inner-icon="mdi-cash"
                  :label="$t('school.fees.fee_type')"
                  :rules="[$required, $numeric]"
                ></VAutocomplete>
              </VCol>
              <VCol cols="12">
                <VTextField
                  v-model="student_installments.paid_by"
                  density="compact"
                  clearable
                  prepend-inner-icon="mdi-file"
                  :label="$t('school.student_fees.who_pay')"
                  :rules="[$required, $max_length(100)]"
                />
              </VCol>
            </VRow>
          </VForm>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            v-if="!is_update"
            class="bg-indigo"
            @click="savePayment()"
            size="small"
            :loading="loading.save"
            ><span class="text-white">{{ $t("global.save") }}</span></v-btn
          >
          <v-btn @click="cleanDialog">{{ $t("global.cancel") }}</v-btn>
          <v-btn
            v-if="is_update"
            class="bg-success"
            @click="updatePayment()"
            size="small"
            :loading="loading.save"
            ><span class="text-white">{{ $t("global.edit") }}</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="show_dialog" width="600" style="direction: rtl">
      <v-card>
        <v-card-title class="d-flex">
          <div>المدفوعات</div>
          <v-spacer></v-spacer>
          <v-icon icon="mdi-close" @click="show_dialog = false"></v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <VTable density="compact" class="rounded">
            <thead>
              <tr>
                <th>{{ $t("school.fees.fee_type") }}</th>
                <th>{{ $t("school.student_fees.payment_amount") }}</th>
                <th>{{ $t("school.student_fees.who_pay") }}</th>
                <th>{{ $t("global.actions") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in payments_list" :key="payment">
                <td>{{ payment.fk_student_fee_name }}</td>
                <td>{{ payment.paid_mount }}</td>
                <td>{{ payment.paid_by }}</td>
                <td>
                  <span>
                    <span
                      v-if="
                        CheckGetPermission('school.change_installmentspayments')
                      "
                      class="me-1"
                      style="color: #e5b254; border-radius: 4px"
                    >
                      <v-icon
                        @click="editPayment(payment)"
                        size="small"
                        style="cursor: pointer"
                        icon="mdi-pencil-outline"
                      />
                    </span>
                    <span
                      v-if="
                        CheckGetPermission('school.delete_installmentspayments')
                      "
                      style="color: #d05251; border-radius: 4px"
                    >
                      <v-icon
                        @click="
                          (payment_del_id = payment.id), (del_dialog = true)
                        "
                        size="small"
                        style="cursor: pointer"
                        icon="mdi-trash-can-outline"
                      />
                    </span>
                    <span
                      v-if="
                        CheckGetPermission('school.view_installmentspayments')
                      "
                      class="mx-1"
                      @click="
                        bill.payments = [
                          payments_list.find((item) => item.id == payment.id),
                        ]
                      "
                      style="color: #5251d0; border-radius: 4px"
                    >
                      <!-- bill.payments.push(
                      payments_list.find((item) => item.id == payment.id)
                    ),
                  (plus = !plus) -->
                      <v-icon
                        size="small"
                        style="cursor: pointer"
                        :icon="'mdi-eye'"
                      />
                      <!-- :icon="plus ? 'mdi-eye' : 'mdi-minus'" -->
                    </span>
                    <span
                      v-if="
                        bill.payments?.length ||
                        (false &&
                          CheckGetPermission(
                            'school.view_installmentspayments'
                          ))
                      "
                      class="mx-1"
                      @click="
                        bill.payments = bill.payments.filter(
                          (item) => item.id != payment.id
                        )
                      "
                      style="color: #5251d0; border-radius: 4px"
                    >
                      <!-- bill.payments.find((item) => item.id == payment.id)
                      ? (bill.payments = bill.payments.filter(
                          (item) => item.id != payment.id
                        ))
                      : bill.payments.push(
                          payments_list.find((item) => item.id == payment.id)
                        ),
                      (plus = !plus) -->
                      <!-- <v-icon
                    size="small"
                    style="cursor: pointer"
                    :icon="
                      bill.payments.find((item) => item.id == payment.id)
                        ? 'mdi-minus'
                        : 'mdi-plus'
                    "
                  /> -->
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </VTable>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (payment_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deletePayment">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  <v-dialog
    ref="invoice"
    v-if="bill.payments.length"
    v-model="bill.payments.length"
    width="65rem"
    style="direction: rtl"
  >
    <v-sheet class="bill_dialog" width="100%">
      <!-- color="#cfe5e2" -->
      <div class="d-flex justify-end">
        <!-- <v-btn density="compact"> -->
        <v-icon color="icon-color" @click="print()"
          >mdi-printer
          <v-tooltip class="tooltip" activator="parent" location="top">
            <small>{{ $t("global.print") }}</small>
          </v-tooltip>
        </v-icon>
        <!-- </v-btn> -->
      </div>
      <div class="header">
        <v-container>
          <v-row class="justify-space-between">
            <v-col cols="auto">
              <span class="title">{{ school_data?.name_ar }}</span>
              <h5><span>فاتورة :</span>{{ bill.payments[0]?.id }} #</h5>
              <h5><span>تاريخ :</span>{{ $date }}</h5>
              <!-- <h3>{{ $branch_ar }}</h3>
            <h3>قطع بواسطة : {{ $username }}</h3> -->
            </v-col>

            <v-col cols="2">
              <div class="logo">
                <img
                  class="w-100 h-100"
                  style="object-fit: cover"
                  :src="school_data?.logo"
                  alt=""
                />
                <!-- <h5>
                رقم {{ bill.payments.find((item) => item.id)?.id || "Nan" }}
              </h5> -->
              </div>
            </v-col>
          </v-row>
        </v-container>

        <!-- <v-container>
        <v-row>
          <v-col cols="4" class="d-flex flex-column" style="gap: 0.3rem">
          </v-col>
          <v-col cols="4" class="text-center">
          </v-col>
          <v-col cols="4" class="text-center">
          </v-col>
        </v-row>
      </v-container> -->
      </div>
      <div class="body">
        <v-container>
          <v-table
            class="bill-info"
            density="compact"
            style="border-bottom: 1px solid #959595"
          >
            <!-- <caption>
            <h3 class="text-center mb-2">ضريب تسديد قسط</h3>
          </caption> -->
            <thead style="border-top: 1px solid #959595">
              <tr>
                <th>اسم الطالب</th>
                <th>الصف</th>
                <th>نوع الرسوم</th>
                <th>دفع بواسطة</th>
                <th>المبلغ المدفوع</th>
                <!-- <th>المبلغ المتبقي</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="bill in bill.payments" :key="bill">
                <td>
                  {{
                    student_list.find(
                      (item) => item.id === student_installments.fk_student
                    )?.name_ar || "لا يوجد اسم"
                  }}
                </td>
                <td>
                  {{
                    classes.find(
                      (item) => item.id === student_installments.fk_class
                    )?.name_ar || "لا يوجد اسم"
                  }}
                </td>
                <td>{{ bill.fk_student_fee_name }}</td>
                <td>{{ bill.paid_by }}</td>
                <td>{{ bill.paid_mount }}</td>
                <!-- <td>{{ installments_list[0]?.still || "لايوجد" }}</td> -->
              </tr>
            </tbody>
          </v-table>
        </v-container>
        <!-- <v-container class="d-flex justify-end pt-0">
        <div class="text-start ml-3">
          <div
            class="d-flex justify-space-between my-1"
            style="min-width: 150px; gap: 4px"
          >
            <span style="width: 50px"> القسط: </span
            ><span>{{
              Number(installments_list[0]?.paid_amount) +
                Number(installments_list[0]?.still) || "لايوجد"
            }}</span
            ><span>ريال</span>
          </div>
          <div
            class="d-flex justify-space-between my-1 align-content-center"
            style="min-width: 150px; gap: 4px"
          >
            <span style="width: 50px"> المبلغ المدفوع: </span
            ><span class="text-center">{{
              installments_list[0]?.paid_amount || "لايوجد"
            }}</span
            ><span>ريال</span>
          </div>
          <v-divider></v-divider>
          <div
            class="d-flex justify-space-between my-1"
            style="min-width: 150px; gap: 4px"
          >
            <span style="width: 50px"> المتبقي: </span
            ><span>{{ installments_list[0]?.still || "لايوجد" }}</span
            ><span>ريال</span>
          </div>
        </div>
      </v-container> -->
      </div>
      <div class="footer">
        <v-container>
          <div>
            <v-icon icon="mdi-map-marker-radius"></v-icon>
            <span>{{
              bill.information.place +
              "، " +
              bill.information.directorate_name_ar +
              "، " +
              bill.information.governorate_name_ar +
              "، " +
              bill.information.country_name_ar
            }}</span>
          </div>
          <div>
            <v-icon icon="mdi-phone-in-talk"></v-icon>
            <span>{{ 773584109 }} </span>
          </div>
          <div>
            <v-icon icon="mdi-map-marker-radius"></v-icon>
            <span>{{ $branch_ar }}</span>
          </div>
        </v-container>
      </div>
    </v-sheet>
  </v-dialog>
  <v-sheet id="bill" style="gap: 2.5rem" width="100%">
    <!-- color="#cfe5e2" -->
    <div class="header">
      <v-container>
        <v-row class="justify-space-between">
          <v-col cols="auto">
            <span class="title">{{ school_data?.name_ar }}</span>
            <h5><span>فاتورة :</span>{{ bill.payments[0]?.id }} #</h5>
            <h5><span>تاريخ :</span>{{ $date }}</h5>
            <!-- <h3>{{ $branch_ar }}</h3>
            <h3>قطع بواسطة : {{ $username }}</h3> -->
          </v-col>

          <v-col cols="2">
            <div class="logo">
              <img
                class="w-100 h-100"
                style="object-fit: cover"
                :src="school_data?.logo"
                alt=""
              />
              <!-- <h5>
                رقم {{ bill.payments.find((item) => item.id)?.id || "Nan" }}
              </h5> -->
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- <v-container>
        <v-row>
          <v-col cols="4" class="d-flex flex-column" style="gap: 0.3rem">
          </v-col>
          <v-col cols="4" class="text-center">
          </v-col>
          <v-col cols="4" class="text-center">
          </v-col>
        </v-row>
      </v-container> -->
    </div>
    <div class="body">
      <v-container>
        <v-table
          class="bill-info"
          density="compact"
          style="border-bottom: 1px solid #959595"
        >
          <!-- <caption>
            <h3 class="text-center mb-2">ضريب تسديد قسط</h3>
          </caption> -->
          <thead style="border-top: 1px solid #959595">
            <tr>
              <th>اسم الطالب</th>
              <th>الصف</th>
              <th>نوع الرسوم</th>
              <th>دفع بواسطة</th>
              <th>المبلغ المدفوع</th>
              <!-- <th>المبلغ المتبقي</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="bill in bill.payments" :key="bill">
              <td>
                {{
                  student_list.find(
                    (item) => item.id === student_installments.fk_student
                  )?.name_ar || "لا يوجد اسم"
                }}
              </td>
              <td>
                {{
                  classes.find(
                    (item) => item.id === student_installments.fk_class
                  )?.name_ar || "لا يوجد اسم"
                }}
              </td>
              <td>{{ bill.fk_student_fee_name }}</td>
              <td>{{ bill.paid_by }}</td>
              <td>{{ bill.paid_mount }}</td>
              <!-- <td>{{ installments_list[0]?.still || "لايوجد" }}</td> -->
            </tr>
          </tbody>
        </v-table>
      </v-container>
      <!-- <v-container class="d-flex justify-end pt-0">
        <div class="text-start ml-3">
          <div
            class="d-flex justify-space-between my-1"
            style="min-width: 150px; gap: 4px"
          >
            <span style="width: 50px"> القسط: </span
            ><span>{{
              Number(installments_list[0]?.paid_amount) +
                Number(installments_list[0]?.still) || "لايوجد"
            }}</span
            ><span>ريال</span>
          </div>
          <div
            class="d-flex justify-space-between my-1 align-content-center"
            style="min-width: 150px; gap: 4px"
          >
            <span style="width: 50px"> المبلغ المدفوع: </span
            ><span class="text-center">{{
              installments_list[0]?.paid_amount || "لايوجد"
            }}</span
            ><span>ريال</span>
          </div>
          <v-divider></v-divider>
          <div
            class="d-flex justify-space-between my-1"
            style="min-width: 150px; gap: 4px"
          >
            <span style="width: 50px"> المتبقي: </span
            ><span>{{ installments_list[0]?.still || "لايوجد" }}</span
            ><span>ريال</span>
          </div>
        </div>
      </v-container> -->
    </div>
    <div class="footer">
      <v-container>
        <div>
          <v-icon icon="mdi-map-marker-radius"></v-icon>
          <span>{{
            bill.information.place +
            "، " +
            bill.information.directorate_name_ar +
            "، " +
            bill.information.governorate_name_ar +
            "، " +
            bill.information.country_name_ar
          }}</span>
        </div>
        <div>
          <v-icon icon="mdi-phone-in-talk"></v-icon>
          <span>{{ 773584109 }}</span>
        </div>
        <div>
          <v-icon icon="mdi-map-marker-radius"></v-icon>
          <span>{{ 0 }}</span>
        </div>
      </v-container>
    </div>
  </v-sheet>
</template>

<script>
// import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
// import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      if (!this.classes.length) await this.getClasses();
      this.getSchoolData();
      this.getBranchData();
      //   await this.getClasses();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      //   v$: useValidate(),
      fk_division: undefined,
      show_add_dialog: false,
      show_dialog: false,
      is_update: false,
      payment_del_id: undefined,
      del_dialog: false,
      fk_fee_type: undefined,
      student_installments: {
        fk_student: undefined,
        fk_class: undefined,
        // fk_year: undefined,
        fk_fee_type: undefined,
        payment: undefined,
        paid_by: undefined,
        // data_entry: undefined,
      },
      student_list: [],
      fee_type_list: [],
      installments_list: [],
      payments_list: [],
      bill: {
        information: {},
        payments: [],
      },
      plus: true,
      installment_remain: null,
      school_data: null,
      paid_still: null,
      loading: {},
      //   process_alert: undefined,
      //   icon_color: undefined,
      //   icon: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      user: (state) => state.User,
    }),
    ...mapGetters({
      getDivisionsByClass: "school/getClassDivisions",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    editPayment(payment) {
      (this.student_installments.fk_fee_type = payment.fk_student_fee),
        (this.student_installments.payment = payment.paid_mount),
        (this.student_installments.paid_by = payment.paid_by),
        (this.student_installments.id = payment.id),
        (this.paid_still = this.paid_still + parseInt(payment.paid_mount));
      this.is_update = true;
      this.show_add_dialog = true;
      this.show_dialog = false;
    },
    async getStudents() {
      this.student_installments.fk_student = undefined;
      //   this.v$.student_installments.fk_student.$reset();
      this.fk_fee_type = undefined;
      this.installments_list = [];
      //   if (localStorage.getItem("current_year") != undefined) {
      let list = await this.axios
        .get(`${this.base_url}student-list/`, {
          params: {
            class: this.student_installments.fk_class,
            division: this.fk_division,
            // year: localStorage.getItem("current_year"),
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.student_list = response.data;
        });
      //   }
    },
    async getFeeTypes() {
      this.student_installments.fk_fee_type = undefined;
      if (this.student_installments.fk_student != undefined) {
        let list = await this.axios
          .get(`${this.base_url}student-fee-types/`, {
            params: {
              student: this.student_installments.fk_student,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.fee_type_list = response.data;
          });
      }
    },
    async getInstallments() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading.show = true;
        let list = await this.axios
          .get(`${this.base_url}installments/`, {
            params: {
              student: this.student_installments.fk_student,
              fee_type: this.fk_fee_type,
              year: localStorage.getItem("current_year"),
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.installments_list = response.data;
            if (this.installments_list?.length == 1) {
              if (this.installments_list[0].still)
                this.paid_still = parseInt(this.installments_list[0].still);
              else
                this.paid_still =
                  parseInt(this.installments_list[0].installment_number) -
                  parseInt(this.installments_list[0].paid_mount);
            } else
              this.paid_still = this.installments_list?.reduce(
                (total, item) => {
                  return (
                    total +
                    (item.installment_number - parseInt(item.paid_mount))
                  );
                },
                0
              );
          });
      }
      this.loading.show = false;
    },
    async getPayments() {
      if (
        (localStorage.getItem("current_year") != undefined,
        this.student_installments.fk_student != undefined)
      ) {
        this.loading.show_pay = true;
        await this.axios
          .get(`${this.base_url}payments-list/`, {
            params: {
              student: this.student_installments.fk_student,
              year: localStorage.getItem("current_year"),
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.payments_list = response.data;
            this.show_dialog = true;
          });
      }
      this.loading.show_pay = false;
    },
    async getBranchData() {
      await this.axios
        .get(`${this.base_url}branches/school-with-branch-data/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.bill.information = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },

    async getSchoolData() {
      await this.axios
        .get(`${this.base_url}school-data/logo/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.school_data = response.data;
        });
    },

    async savePayment() {
      const { valid } = await this.$refs.form2.validate();
      if (valid) {
        this.loading.save = true;
        await this.axios
          .post(`${this.base_url}installments/`, this.student_installments, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });

        this.show_add_dialog = false;
        this.getInstallments();
      }
      this.loading.save = false;
    },
    async updatePayment() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading.save = true;
        this.student_installments.updated_by = this.user;
        await this.axios
          .put(
            `${this.base_url}installments/${this.student_installments.id}/`,
            this.student_installments,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        this.cleanDialog();
        this.getPayments();
        this.getInstallments();
      }
      this.loading.save = false;
    },
    async deletePayment() {
      if (this.payment_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}installments/${this.payment_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        this.del_dialog = false;
        this.show_dialog = false;
        this.payment_del_id = undefined;
        this.getPayments();
        this.getInstallments();
      }
    },
    cleanMarks() {
      this.student_list = [];
      this.$refs.form.reset();
      //   this.v$.student_installments.$reset();
    },
    cleanDialog() {
      this.is_update = false;
      this.show_add_dialog = false;
      // this.$refs.form2.reset();
      //   this.v$.student_installments.$reset();
      this.student_installments.payment = undefined;
      this.student_installments.paid_by = undefined;
      // this.student_installments.fk_fee_type=undefined
    },
    print() {
      window.print();
    },
  },
};
</script>
<style scoped>
#bill {
  /* padding: 5rem; */
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  display: none;
}

.bill_dialog {
  padding: 2rem;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  font-size: 3em;
  color: #3f85c6;
}

.v-col h5:has(span) {
  display: flex !important;
  justify-content: space-between;
  margin-block: 0.5px;
  padding-inline: 2px;
  align-items: center;
}

.bill-info thead th {
  border-inline: 3px solid white;
  background-color: #009bdd;
  color: white !important;
}

/* .header {
  margin-bottom: auto;
} */
/* .footer {
  display: flex;
  justify-content: end;
} */

.body th:not(:first-child),
.body td:not(:first-child) {
  text-align: center;
}
</style>
