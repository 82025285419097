<template>
  <v-dialog
    v-model="value"
    transition="dialog-top-transition"
    scrollable
    :dir="$i18n.locale == 'en' ? 'ltr' : 'rtl'"
    width="350"
    elevation="12"
  >
    <v-card>
      <v-card-title>
        <h3>
          <v-icon
            class="me-1 pb-1"
            color="error"
            icon="mdi-alert"
            size="18"
          ></v-icon>
          {{ $t("global.alert.can_not_delete") }}
        </h3>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-item>
        <template v-slot:subtitle>
          <!-- <v-icon
            class="me-1 pb-1"
            color="error"
            icon="mdi-alert"
            size="18"
          ></v-icon> -->
          {{ $t("global.alert.can_not_delete_message") }}
        </template>
      </v-card-item>
      <!-- <v-card-text class="py-0">
        <v-row align="center" no-gutters>
          <v-col class="text-h2" cols="8">
            <h6>&sim;64 <span>سجل</span></h6></v-col
          >
          <v-col class="text-right" cols="4">
            <v-icon
              color="error"
              icon="mdi-apple-keyboard-option"
              size="60"
            ></v-icon>
          </v-col>
        </v-row>
      </v-card-text> -->
      <!-- <div class="d-flex py-3 justify-space-between">
        <v-list-item
          density="compact"
          prepend-icon="mdi-weather-windy"
        ></v-list-item>
      </div> -->
      <!-- <v-divider></v-divider> -->
      <v-list density="compact" class="py-0">
        <v-list-item
          @click="expand = !expand"
          :append-icon="expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          density="compact"
          class="py-0"
        >
          <span style="font-size: 0.7rem">{{
            !expand
              ? $t("global.alert.show_details")
              : $t("global.alert.hide_details")
          }}</span>
        </v-list-item>
      </v-list>
      <!-- <v-card-actions> -->
      <!-- <v-btn
          @click="expand = !expand"
          color="primary"
        > {{ !expand ? 'full' : 'hide' }}</v-btn> -->
      <!-- </v-card-actions> -->
      <v-divider v-if="expand"></v-divider>
      <v-container class="overflow-auto pa-0" style="max-height: 115px">
        <v-expand-transition>
          <div v-if="expand">
            <!-- <div class="py-2">
            <v-slider
              v-model="time"
              :max="6"
              :step="1"
              class="mx-4"
              color="primary"
              density="compact"
              show-ticks="always"
              thumb-size="10"
              hide-details
            ></v-slider>
          </div> -->
            <v-table density="compact">
              <tbody>
                <tr v-for="item in filteredData" :key="item">
                  <td class="px-0 w-0" style="ma">
                    <span class="list-item-number mx-5">{{ item.count }}</span>
                  </td>
                  <td class="px-2">
                    <span style="font-size: 0.8rem">{{
                      $t(`global.model.${item.model}`)
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </v-table>
            <!-- <v-list class="bg-transparent pa-0" density="compact">
            <v-container class="pa-0">
              <v-list-item
                v-for="item in data_message"
                :key="item"
                density="compact"
                class="pa-0"
              >
                <span style="font-size: 0.8rem">{{ item.model }}</span>
                <template v-slot:prepend>
                  <span class="list-item-number mx-5">{{ item.count }}</span>
                </template>
              </v-list-item>
            </v-container>
          </v-list> -->
          </div>
        </v-expand-transition>
      </v-container>
      <v-card-actions class="py-0 px-4">
        <v-btn
          class="mr-auto"
          size="small"
          color="indigo"
          @click="value = !value"
        >
          {{ $t("global.ok") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- <v-dialog
        v-model="value"
        transition="dialog-top-transition"
        dir="rtl"
        scrollable
        width="350"
        elevation="12"
    >
      <v-sheet class="pa-2"> 
          <div class="text-center pa-2">
            <VIcon
                class="mb-1"
                color="error"
                icon="mdi-cancel"
                size="55"
            ></VIcon>
            <h2 class="  mb-2 text-blue-grey-darken-4 " >{{$t('globals.failure')}}</h2>
            <h4 class=" mb-6 text-blue-grey-darken-5 " >{{$t('alert.failure.cannot-delete')}} </h4>
            <v-divider/>
            <v-expansion-panels >
              <v-expansion-panel >
                <v-expansion-panel-title >{{$t('globals.details')}}</v-expansion-panel-title>
                <v-expansion-panel-text class="scroll">
                  <v-list-item v-for="data in data_message" :key="data" class="text-start" prepend-icon="mdi-circle-small">
                    <span style="font-size:0.8rem">{{data}}</span>
                  </v-list-item>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-btn size="small" @click="value=false" variant="text" color="indigo">{{$t('globals.ok')}}</v-btn>
      </v-sheet>
  </v-dialog> -->
</template>

<script>
export default {
  props: {
    data_message: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      protected_records: false,
      expand: false,
      exclude: [
        "ClassStudentYear",
        "UserAuthority",
        "GroupExtra",
        "Contact",
        "BranchMonth",
        "BranchSemester",
        "PaymentsReciept",
        "Month",
        "Class",
      ],
      replacements: {},
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.expand = value ? false : null;
      },
    },

    filteredData() {
      // without combining the count
      //   const unique_data = [
      //     ...new Set(this.data_message.map((item) => item.model)),
      //   ].map(model => {
      //     return this.data_message.find(item => item.model === model)
      //   });

      // with combining the count
      const unique_data = Object.values(
        this.data_message.reduce((acc, item) => {
          // item.model = this.replacements[item.model] || item.model;
          if (!acc[item.model]) {
            acc[item.model] = { ...item };
          } else {
            acc[item.model].count += item.count;
          }
          return acc;
        }, {})
      );
      return unique_data.filter((item) => !this.exclude.includes(item.model));
    },
  },
};
</script>
<style scoped>
.scroll {
  max-height: 200px;
  overflow: auto;
}
.v-table--density-compact > .v-table__wrapper > table > tbody > tr > td,
.v-table--density-compact > .v-table__wrapper > table > thead > tr > td,
.v-table--density-compact > .v-table__wrapper > table > tfoot > tr > td {
  height: 25px !important;
}

.v-card .v-card-title {
  line-height: 2rem;
}
.v-card .v-card-title > h3 {
  padding-top: 2px;
}

/* .v-card-actions{
    min-height: 3rem !important;
} */
</style>
