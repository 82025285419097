<template>
  <v-expansion-panels class="mb-2" v-if="getUserRole < 2">
    <v-expansion-panel elevation="1">
      <v-expansion-panel-title class="px-4">
        <span>
          <!-- <v-icon>mdi-filter</v-icon>  -->
          {{ $t("global.view_filters") }}</span
        >
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pt-4">
        <!-- <v-fade-transition leave-absolute> -->
        <VForm ref="form">
          <VRow class="">
            <VCol
              v-if="getUserRole < 2"
              class="pt-0 px-3"
              cols="12"
              xs="12"
              sm="12"
              md="3"
              lg="3"
            >
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="branch"
                  v-model="fk_branch"
                  prepend-inner-icon="mdi-office-building"
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('school.student.branch')"
                  persistent-hint
                  density="compact"
                  clearable
                ></VAutocomplete>
                <!-- @update:modelValue="!fk_branch ? getStudentList() : ''"
                    :rules="[$required]" -->
              </VCardItem>
            </VCol>

            <VCol class="mt-2" cols="12" xs="12" sm="12" md="2" lg="2">
              <VBtn
                class="bg-blue"
                @click="getParent((page = 1))"
                size="small"
                :loading="loading"
              >
                <span class="text-white">{{ $t("global.filter") }}</span>
                <VIcon icon="mdi-filter" color="white" end></VIcon>
              </VBtn>
              <!-- <VBtn class="bg-indigo" size="small">
                    <span class="text-white">
                      {{ $t("globals.show") }}
                    </span>
                    <VIcon icon="mdi-presentation" color="white" end></VIcon>
                  </VBtn> -->
            </VCol>
          </VRow>
        </VForm>
        <!-- </v-fade-transition> -->
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card class="card-table">
    <div>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" color="#ccc">
            <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
            <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header.key"
              :label="header.title"
              :value="header.key"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-text-field
        v-model="txt_search"
        class="search"
        @input="!txt_search ? getParent((page = 1)) : ''"
        hide-details
        append-inner-icon="mdi-magnify"
        single-line
        @keyup.enter="getParent((page = 1))"
        :placeholder="$t('global.data_table.search_here')"
        density="compact"
        variant="text"
      >
      </v-text-field>
      <div
        v-if="CheckGetPermission('school.add_parent')"
        class="d-flex justify-end"
        style="flex: 1"
      >
        <VBtn class="bg-success" @click="$router.push('add')">
          <span class="text-white">{{ $t("global.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <!-- <v-progress-linear
      color="indigo"
      class="my-1"
      :indeterminate="loading"
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear> -->
    <v-data-table-server
      :headers="selectedHeaders"
      :items="tableList"
      id="dataTable"
      density="compact"
      class="elevation-1"
      :loading="loading"
      hide-default-header
      v-model:sort-by="sortBy"
      @update:options="getParent((page = 1))"
    >
      <template v-slot:no-data class="text-center">
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <template v-slot:no-results>
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <template v-slot:header="{ props }">
        <th v-for="head in props.headers" :key="head">
          {{ head.text }}
        </th>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td v-for="(val, key) in item.columns" :key="key">
            <span v-if="key !== 'identity_image' && key !== 'actions'">{{
              val
            }}</span>
            <span v-if="key == 'identity_image'">
              <button @click="showImage(val)">
                <v-avatar
                  :image="
                    val != null
                      ? val.search(base_url) != -1
                        ? val
                        : base_url + val
                      : false
                  "
                  :icon="val == null ? 'mdi-image-off-outline' : ''"
                  class=""
                  size="small"
                ></v-avatar>
              </button>
            </span>
            <span v-if="key === 'actions'">
              <span
                v-if="CheckGetPermission('school.change_parent')"
                class="me-1"
                style="color: #e5b254; border-radius: 4px"
              >
                <v-icon
                  @click="parentEdit(item.raw.id)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-pencil-outline"
                />
              </span>
              <span
                v-if="CheckGetPermission('school.delete_parent')"
                style="color: #d05251; border-radius: 4px"
              >
                <v-icon
                  @click="(parent_del_id = item.raw.id), (del_dialog = true)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-trash-can-outline"
                />
              </span>
            </span>
          </td>
        </tr>
      </template>

      <template v-slot:bottom>
        <pagination
          v-model:page="page"
          v-model:perPage="perPage"
          :length="totalItems"
          :newItem="[perPage]"
        ></pagination>
        <!-- <div class="d-flex">
                    <v-pagination  v-model="page" :length="length"  ></v-pagination>
                    <div class="d-flex align-center">
                        <span>{{$t('globals.per-page')}} </span>
                        <v-select v-model="perPage" :items="itemsPerPage"  item-value="value" item-title="text" variant="solo"  ></v-select>
                    </div>
                </div> -->
        <!-- <div class="d-flex" id="pagination-bar">
          <v-pagination
            v-model="page"
            :length="length"
            density="compact"
            show-first-last-page
          >
          </v-pagination>
          <div class="d-flex">
            <v-select
              v-model="perPage"
              class="pa-0"
              :items="itemsPerPage"
              item-value="value"
              item-title="text"
              density="compact"
              hide-details
              variant="text"
            >
            </v-select>
            <span class="mt-2 px-2"
              >{{ $t("global.data_table.per_page") }} {{ $t("global.from") }} [
              {{ totalItems }} ] {{ $t("global.data_table.item") }}</span
            >
          </div>
        </div> -->
      </template>
    </v-data-table-server>
    <!-- #################################################### -->
    <!-- Dialog Delete  -->
    <!-- #################################################### -->
    <v-dialog
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (parent_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteParent">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  <VDialog v-model="showImg_dialog" max-width="400px" max-height="400px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <VCardItem align="center">
        <VImg :src="dialog_image" max-height="300px"></VImg>
      </VCardItem>
      <VCardText>
        <tr v-for="(item, index) in dialog_text" :key="index">
          <td class="text-grey-darken-1 font-weight-bold">{{ item.type }} :</td>
          <td>{{ item.contact }}</td>
        </tr>
      </VCardText>
      <VCardActions>
        <VBtn class="bg-info" @click="showImg_dialog = false">{{
          $t("global.ok")
        }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ParentsList",
  data() {
    return {
      selectedParent: "",
      overlay: false,
      selectedHead: [
        "name",
        "identity_type",
        "identity_number",
        "identity_image",
        "job",
        "address",
        "phone_number",
        "home_number",
        "actions",
      ],
      selectItems: "",
      txt_search: "",
      fk_branch: "",
      parent_del_id: undefined,
      del_dialog: false,
      showImg_dialog: false,
      dialog_image: undefined,
      dialog_title: undefined,
      dialog_text: undefined,
      alert: false,
      totalItems: null,
      // alertTitle:'',
      // alertMessage:'',
      // alertIcon:'',
      perPage: 10,
      length: 0,
      sortBy: [{ key: "id", order: "asc" }],
      imageRender: "",
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      pagination: {},
      parents: [],
      loading: false,
    };
  },
  components: {},
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.branch.length) await this.getBrenches({});
      if (!this.identify_type.length) await this.getIdentifyType();
      await this.getParent();

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      identify_type: (state) => state.globals.identify_type,
      branch: (state) => state.school.branch,
    }),
    headers() {
      return [
        { title: this.$t("school.parent.supervisor"), key: "name" },
        {
          title: this.$t("global.personal_information.id_type"),
          key: "identity_type",
        },
        {
          title: this.$t("global.personal_information.id_number"),
          key: "identity_number",
        },
        {
          title: this.$t("school.parent.parent_id_image"),
          key: "identity_image",
        },
        { title: this.$t("school.parent.job"), key: "job" },
        {
          title: this.$t("global.personal_information.address"),
          key: "address",
        },
        {
          title: this.$t("global.personal_information.phone_number"),
          key: "phone_number",
        },
        {
          title: this.$t("global.personal_information.home_number"),
          key: "home_number",
        },
        { title: this.$t("global.username"), key: "fk_user" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ];
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
    tableList() {
      let list = [];
      this.parents.forEach((element) => {
        let identity = this.identify_type.find(
          (item) => item.id == element.identity_type
        );
        if (identity != undefined) identity = identity.name;
        list.push({
          name: element.name,
          identity_type: identity,
          identity_number: element.identity_number,
          identity_image: element.identity_image,
          job: element.job,
          address: element.address,
          phone_number: element.phone_number,
          home_number: element.home_number,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getIdentifyType: "globals/getIdentifyType",
      getBrenches: "school/getBrenches",
    }),
    // selecthead(){
    //     this.selectedHeaders=this.headers
    // },
    parentEdit(id) {
      this.$router.push(`${id}/edit/`);
    },
    // selectImage(e){
    //     this.parent.image=e
    // },
    showImage(value) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1)
        this.dialog_image = value;
      else if (value?.search(this.base_url) == -1)
        this.dialog_image = this.base_url + value;
      else this.dialog_image = "";
      this.dialog_title = this.$t("school.parent.parent_id_image");
      this.showImg_dialog = true;
    },
    async getParent(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}/parent/`, {
        params: {
          branch: this.fk_branch,
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.parents = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async deleteParent() {
      if (this.parent_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}parent/${this.parent_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getParent();
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.parent_del_id = undefined;
      }
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getParent();
    },
    page() {
      this.getParent(this.page);
    },
  },
};
</script>
<style scoped>
#dataTable span {
  font-size: 12px;
}
</style>
