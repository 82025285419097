<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
      <v-col cols="6" md="4" sm="12">
        <v-autocomplete
          color="indigo"
          v-model="filter_data.class_number"
          density="compact"
          item-title="name_ar"
          item-value="id"
          prepend-inner-icon="mdi-google-classroom"
          :items="classes"
          :label="$t('class.select')"
          hide-details
          :error-messages="
            v$.filter_data.class_number.$errors.map((e) => e.$message)
          "
          @update:modelValue="filter_data.subject=null"
        />
      </v-col>

      <v-col cols="6" md="4" sm="12">
        <v-autocomplete
          color="indigo"
          v-model="filter_data.semester"
          density="compact"
          item-title="name_ar"
          item-value="id"
          prepend-inner-icon="mdi-book-education"
          :items="semesters"
          :label="$t('semester.select')"
          hide-details
          :error-messages="
            v$.filter_data.semester.$errors.map((e) => e.$message)
          "
        />
      </v-col>

      <v-col cols="6" md="4" sm="12">
        <v-autocomplete
          color="indigo"
          v-model="filter_data.subject"
          density="compact"
          item-title="name"
          item-value="id"
          prepend-inner-icon="mdi-bookshelf"
          :items="class_subjects"
          :label="$t('subject.select')"
          hide-details
          :error-messages="
            v$.filter_data.subject.$errors.map((e) => e.$message)
          "
        />
      </v-col>

      <v-col cols="6" sm="2" class="d-flex align-center mt-2">
        <v-btn
          density="comfortable"
          append-icon="mdi-presentation"
          class="w-100"
          color="indigo"
          @click="checkFilterData"
        >
          <span>{{ $t("globals.show") }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <!-- <v-row class="mt-4 text-grey-darken-2">
      <v-col cols="4" class="text-center">
        <h2>الجمهورية اليمنية</h2>
        <h3 v-if="school.length > 0">
          {{ school[0].ministry_name ? school[0].ministry_name : "" }}
        </h3>
        <h5 v-if="school.length > 0">{{ school[0].arabic_name }}</h5>
      </v-col>
      <v-col cols="4" class="text-center">
        <h4>بسم الله الرحمن الرحيم</h4>
        <v-responsive v-if="school.length > 0">
          <v-avatar size="80">
            <v-img :src="school[0].logo"></v-img>
          </v-avatar>
        </v-responsive>
      </v-col>
      <v-col cols="4" class="text-center">
        <h3>الصف : {{ getClassName(filter_data.class_number) }}</h3>
        <h3>الفصل : {{ getSemesterName(filter_data.semester) }}</h3>
      </v-col>
    </v-row> -->
    <cliche>
      <template v-slot:left >
          <div class="text-start ma-auto"  style="width: fit-content;">
            <div>
              <span class="head">
                {{ $t("semester.semester") }} :
              </span>
             <span class="text">
               {{ getSemesterName(filter_data.semester) }}
             </span>
            </div>
            <div>
              <span class="head">
                {{ $t("class.name") }} :
              </span>
              <span class="text">
                {{ getClassName(filter_data.class_number) }}
              </span>
            </div>
            <div>
              <span class="head">
                {{ $t("subject.name") }} :
              </span>
              <span class="text">
                {{ getSubjectName(filter_data.subject) }}
              </span>
            </div>
            
          </div>
      </template>
    </cliche>
    <div class="text-center text-grey-darken-2">
      <h3>{{ $t("report.subject") }} {{ getSubjectName(filter_data.subject) }}</h3>
      <h4>{{ $t("year.for-year")}} {{ year_data }}</h4>
    </div>
    <v-card-text class="mb-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <!-- <div class="d-flex justify-start" style="flex: 1">
        </div> -->
        <div class="d-flex justify-start" style="flex: 1">
           <v-btn
            density="compact"
            @click="
              (exist = false),
                v$.filter_data.$reset()
            "
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="px-1 mx-1"
                style="min-width: 30px"
              >
                <v-icon color="icon-color">mdi-eye</v-icon>
                <v-icon color="icon-color">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0"
                style="min-width: 25px"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip
                  class="tooltip"
                  activator="attendance"
                  location="top"
                >
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list id="print-menu" density="compact">
              <v-list-item @click="printTable((all = false))">{{
                $t("globals.shown-records")
              }}</v-list-item>
              <v-list-item @click="printTable((all = true))">{{
                $t("globals.all-records")
              }}</v-list-item>
            </v-list>
          </v-menu>
         
        </div>
      </div>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                {{ (page - 1) * perPage + key + 1 }}
                </td>

                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('collector')"
                >
                  {{ item.subject[0].collector }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('final_exam')"
                >
                  {{ item.subject[0].final_exam }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('total')"
                >
                  {{
                    calculateTotal(
                      item.subject[0].collector | NumberFormat,
                      item.subject[0].final_exam | NumberFormat
                    )
                  }}
                </td>
                <!-- <td class="text-center border" v-if="selectedHead.includes('secretnumber')">{{item.secretnumber}}</td> -->
              </tr>
            </tbody>
          </table>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-autocomplete
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-autocomplete>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>
    
    <script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  async created() {
    try {
      await this.$store.commit('updateLoadingValue',true)
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
      await this.getClasses();
      await this.getSemesters();
      await this.getSubjects();
      await this.getattendances();
      await this.getDivisions();
      this.$store.commit('updateLoadingValue',false)
    } catch (error) {
      this.$store.commit('updateLoadingValue',false)
    }
  },

  data() {
    return {
      school: [],
      branch: {},
      months: [],
      year_data:localStorage.getItem('current_year_name_h'),
      attendances: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["no", "student_name", "collector", "final_exam", "total"],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.student_name"), key: "student_name" },
        { title: this.$t("class-marks.collector"), key: "collector" },
        { title: this.$t("class-marks.final-exam"), key: "final_exam" },
        { title: this.$t("class-marks.mark_total"), key: "total" },
      ],
      perPage: 10,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: "", text: "all" },
      ],
      page: 1,
      pagination: {},
      filter_data: {
        year: localStorage.getItem('current_year'),
        class_number: null,
        subject: null,
        semester: null,
      },
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      class_assign_hall: (state) => state.school.class_assign_hall,
      academic_years: (state) => state.school.academic_years,
      divisions: (state) => state.school.divisions,
      semesters: (state) => state.school.semesters,
      subjects: (state) => state.school.subjects,
      user: (state) => state.User,
    }),
    class_subjects() {
      if (this.filter_data.class_number) {
        var class_data = this.classes.find(
          (item) => item.id == this.filter_data.class_number
        );
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },
    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      getClassAssignHall: "school/getClassAssignHall",
      getSubjects: "school/getSubjects",
    }),
    calculateTotal(paidAmount, still) {
      return paidAmount + still;
    },
    async getattendances() {
      await this.axios
        .get(`${this.base_url}school/report/student/absent`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.attendances = response.data.results;
        });
    },
    
    getClassName(fk_class) {
      const objectClass = this.classes.find(
        (objectClass) => objectClass.id === fk_class
      );
      return objectClass.name_ar;
    },
    getSemesterName(fk_semester) {
      const semester = this.semesters.find((semester) => semester.id === fk_semester);
      return semester.name_ar;
    },
    getSubjectName(fk_subject) {
      const subject = this.subjects.find((subject) => subject.id === fk_subject);
      return subject.name_ar;
    },
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.getData();
      }
      window.print();
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/subject_review`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            year: this.filter_data.year,
            class_number: this.filter_data.class_number,
            subject: this.filter_data.subject,
            semester: this.filter_data.semester,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data.results;
            this.totalItems=response.data.pagination.count
            this.length=response.data.pagination.num_pages
            this.pagination=response.data.pagination
            this.itemsPerPage[4]['value']=this.totalItems
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        class_number: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        subject: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        semester: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
    <style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
.head{
  font-size: 1.1rem;
}
.text{
  font-size: 0.85rem;
}
</style>
    
    <style >
.search:focus {
  outline: unset;
}

@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
    
    