<template>
    <v-card  class=" w-100 h-100  " style=""  @click="choseImage" >
        <span v-if="!image&&!imageRender" class="w-100 h-100  d-flex justify-center align-center bg-ccc">{{$t('libraries.cs-image')}} </span>
        <input type="file" ref="imageInput"  class="d-none" @input="selectImage">
        <img v-if="imageRender" :src="imageRender"  class="w-100 h-100" />
        <img v-if="image&&!imageRender"   :src="image"  class="w-100 h-100" />
    </v-card>
</template>
<script>
export default {
    name:'ImageInput',
    props:['image'],
    data(){
        return{
            imageRender:'',
            
        }
    },
    methods:{
        choseImage(){
            this.$refs.imageInput.click()
        },
        selectImage(e){
            const input=this.$refs.imageInput
            const files=input.files
            if(files&&files[0]){
                const reader=new FileReader
                reader.onload=e=>{
                    this.imageRender=e.target.result
                    this.$emit('ImageSelected',files[0])
                }
                reader.readAsDataURL(files[0])
            }

        },
    }
}
</script>
