<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_division = null),
                getClassAssignHall(filter_data.fk_branch?.id),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="filter_data.fk_division = null"
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            :no-data-text="$t('school.division.not_found')"
            prepend-inner-icon="mdi-chair-school"
            :items="
              filter_data.fk_class?.id
                ? getClassDivisions(filter_data.fk_class?.id, getUserRole < 2)
                : []
            "
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <!-- <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:model-value="filter_data.fk_division = null"
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-chair-school"
            :items="class_divisions"
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col cols="12" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_semester"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-book-education"
            :items="semesters"
            :label="$t('school.reports.select_semester')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <!-- <v-col cols="12" md="5" sm="12">
          <v-autocomplete
            ref="select"
            color="indigo"
            v-model="filter_data.fk_student"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-school"
            :items="student_list"
            :label="$t('school.reports.select_student')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items.length < 1 || items[0]?.subjects < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th
                v-for="header in selectedHeaders"
                :key="header.key"
                class="border backgrounds pa-1"
              >
                {{ header.title }}
              </th>
              <!-- <tr>
                <th
                  rowspan="2"
                  class="border background-cell"
                  style="width: 100px"
                >
                  {{ $t("school.reports.student_name") }}
                </th>
                <th
                  v-for="item in items[0]?.subjects"
                  :key="item"
                  class="border background-cell"
                  :style="items[0]?.subjects?.length >= 8 ? '' : 'width:0'"
                >
                  {{ item.subject_name }}
                  {{ item.subject_optional == true ? "*" : "" }}
                </th>
                <th
                  rowspan="2"
                  class="border background-cell"
                  style="width: 40px"
                >
                  <div
                    :class="
                      items[0]?.subjects?.length >= 8 ? 'vertical-text' : ''
                    "
                  >
                    {{ $t("school.reports.total") }}
                  </div>
                </th>
                <th
                  rowspan="2"
                  class="border background-cell"
                  style="width: 40px"
                >
                  <div
                    :class="
                      items[0]?.subjects?.length >= 8 ? 'vertical-text' : ''
                    "
                  >
                    {{ $t("school.reports.percentage") }}
                  </div>
                </th>
                <th
                  rowspan="2"
                  class="border background-cell"
                  style="width: 40px"
                >
                  <div
                    :class="
                      items[0]?.subjects?.length >= 8 ? 'vertical-text' : ''
                    "
                  >
                    {{ $t("school.reports.result") }}
                  </div>
                </th>
              </tr>
              <tr>
                <th
                  v-for="item in items[0]?.subjects"
                  :key="item"
                  class="border background-cell"
                  :style="items[0]?.subjects?.length >= 8 ? 'width: 0' : ''"
                >
                  <div class="" v-if="filter_data.semester == 1">
                    <span class="d-flex justify-content-between">
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0]?.subjects?.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0]?.subjects?.length >= 8
                              ? 'vertical-text-span d-flex justify-center align-center vertical-span'
                              : ''
                          "
                        >
                          محصله اولى
                        </div></span
                      >
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0]?.subjects?.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0]?.subjects?.length >= 8
                              ? 'vertical-text-span d-flex justify-center align-center vertical-span'
                              : ''
                          "
                        >
                          نص العام
                        </div></span
                      >
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0]?.subjects?.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0]?.subjects?.length >= 8
                              ? 'vertical-text-span d-flex justify-center align-center vertical-span'
                              : ''
                          "
                        >
                          المجموع الكلي
                        </div></span
                      >
                    </span>
                  </div>
                  <div class="" v-else>
                    <span class="d-flex justify-content-between">
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0]?.subjects?.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0]?.subjects?.length >= 8
                              ? 'vertical-text-span d-flex justify-center align-center vertical-span'
                              : ''
                          "
                        >
                          محصله ثانية
                        </div></span
                      >
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0]?.subjects?.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0]?.subjects?.length >= 8
                              ? 'vertical-text-span d-flex justify-center align-center vertical-span'
                              : ''
                          "
                        >
                          نهاية العام
                        </div></span
                      >
                      <span
                        :class="[
                          'background-cell d-flex justify-center align-center',
                          items[0]?.subjects?.length >= 8
                            ? 'vertical-span'
                            : 'horizontal-span',
                        ]"
                        ><div
                          :class="
                            items[0]?.subjects?.length >= 8
                              ? 'vertical-text-span d-flex justify-center align-center vertical-span'
                              : ''
                          "
                        >
                          المجموع الكلي
                        </div></span
                      >
                    </span>
                  </div>
                </th>
              </tr> -->
            </thead>
            <tbody v-if="!loading">
              <template v-for="(item, index) in items" :key="item.id">
                <tr v-for="(sub, subIndex) in item?.subjects" :key="sub">
                  <template v-if="subIndex === 0">
                    <td
                      v-if="selectedHead.includes('no')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ (page - 1) * perPage + index + 1 }}
                    </td>
                    <td
                      v-if="selectedHead.includes('student_name')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ item.student_name }}
                    </td>
                    <!-- <td
                      v-if="selectedHead.includes('class_name')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ item.class_name }}
                    </td> -->
                  </template>

                  <td
                    v-for="header in selectedHeaders?.filter(
                      (head) => head.key != 'no' && head.key != 'student_name'
                    )"
                    :key="header.key"
                    class="text-center border"
                  >
                    {{
                      header.key == "writing"
                        ? tafqeet(sub?.total)
                          ? tafqeet(sub?.total) + " " + "درجة"
                          : "-"
                        : sub[header.key] != null
                        ? sub[header.key]
                        : "-"
                    }}
                  </td>
                </tr>
                <tr></tr>
              </template>
              <!-- <tr v-for="item in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student_name }}
                </td>
                <td
                  v-for="subject in item.subject_data"
                  :key="subject"
                  class="border py-0"
                >
                  <div>
                    <span class="d-flex justify-content-between">
                      <span
                        :class="[
                          'd-flex justify-center align-center',
                          items[0]?.subjects?.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span',
                        ]"
                      >
                        {{ subject.collector }}
                      </span>
                      <span
                        :class="[
                          'd-flex justify-center align-center',
                          items[0]?.subjects?.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span',
                        ]"
                      >
                        {{ subject.final_exam }}
                      </span>
                      <span
                        :class="[
                          'd-flex justify-center align-center',
                          items[0]?.subjects?.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span',
                        ]"
                        >{{
                          getTotal(subject.collector, subject.final_exam)
                        }}</span
                      >
                    </span>
                  </div>
                </td>
                <td
                  :class="[
                    'text-center border',
                    item.total < 50 ? 'failed' : '',
                  ]"
                >
                  {{ item.total }}
                </td>
                <td class="text-center border">
                  {{ getPercentageValue(item.total, subjectsCount) }}%
                </td>
                <td class="text-center border">
                  {{ item.status ? "ناجح" : "راسب" }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[10]"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
import tafqeet from "@/assets/Tafqeet";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBrenches({});
      await this.getSemesters();
      await this.getBranchClasses();
      // await this.getYears();
      // await this.getStudents();
      await this.getClassAssignHall();
      await this.getDivisions({});
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      // this.current_year = this.getYearName(
      //   localStorage.getItem("current_year")
      // );
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // current_year: "",
      // school: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: [
        "no",
        "student_name",
        "collector",
        "final_exam",
        "subject_name",
        "total",
        "estamate_subject_ar",
        "writing",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: this.$t("school.reports.subject_name"), key: "subject_name" },
        { title: this.$t("school.reports.collector"), key: "collector" },
        { title: this.$t("school.reports.final_exam"), key: "final_exam" },
        { title: this.$t("school.reports.total"), key: "total" },
        { title: this.$t("school.reports.writing"), key: "writing" },
        {
          title: this.$t("school.reports.estimate"),
          key: "estamate_subject_ar",
        },
        // { title: "", key: "empty" },
        // {
        //   title: this.$t("report.count-of-actual-lecture"),
        //   key: "count_of_actual_lecture",
        // },
        // {
        //   title: this.$t("report.count-of-attendence"),
        //   key: "count_of_attendence",
        // },
        // {
        //   title: this.$t("report.count-of-absentce"),
        //   key: "count_of_absentce",
        // },
        // {
        //   title: this.$t("report.count-of-permission"),
        //   key: "count_of_permission",
        // },
        // {
        //   title: this.$t("report.count-of-not-permission"),
        //   key: "count_of_not_permission",
        // },
      ],
      perPage: 10,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 5, text: "5" },
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      // student_list: [],
      filter_data: {},
      // subjectsTotal: 0,
      subjectsCount: 0,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      semesters: (state) => state.school.semesters,
      classes_by_branch: (state) => state.school.classes_by_branch,
      branch: (state) => state.school.branch,
      // user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    class_divisions() {
      if (this.filter_data.fk_class?.id) {
        return this.getClassDivisions(this.filter_data.fk_class?.id);
      }
    },

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    tafqeet,
    getTotal(collector, finalExam) {
      return collector + finalExam;
    },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    getPercentageValue(sum, count) {
      const total = ((sum * 2) / count) * 100;
      return parseFloat(total.toFixed(2) / 100).toFixed(2);
    },
    // getSemesterName(semester_id) {
    //   if (semester_id) {
    //     const objectSemester = this.semesters.find(
    //       (objectSemester) => objectSemester.id === semester_id
    //     );
    //     return objectSemester.name_ar;
    //   }
    //   return "-------------";
    // },
    // getSubjectsTotal(subjects) {
    //   const { total, count } = subjects.reduce(
    //     (accumulator, obj) => {
    //       return {
    //         total: accumulator.total + obj.total,
    //         count: accumulator.count + 1,
    //       };
    //     },
    //     { total: 0, count: 0 }
    //   );
    //   return total;
    // },
    // getSubjectsCount(subjects) {
    //   const count = subjects.reduce((accumulator, obj) => {
    //     if (obj.subject_optional === false) {
    //       return accumulator + 1;
    //     }
    //     return accumulator;
    //   }, 0);
    //   this.subjectsCount = count;
    // },
    // getClassName(class_id) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === class_id
    //   );
    //   return objectClass.name_ar;
    // },
    // getDivisionName(division_id) {
    //   const division = this.divisions.find(
    //     (division) => division.id === division_id
    //   );
    //   return division.name;
    // },
    // async getStudents() {
    //   if (this.filter_data.fk_class?.id && this.filter_data.fk_division?.id) {
    //     let list = await this.axios.get(`${this.base_url}student-list/`, {
    //       params: {
    //         class: this.filter_data.fk_class?.id,
    //         division: this.filter_data.fk_division?.id,
    //         // year: localStorage.getItem("current_year"),
    //       },
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     });
    //     this.student_list = list.data;
    //   }
    // },
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getClassAssignHall: "school/getClassAssignHall",
      // getAllEstimations: "school/getAllEstimations",
      getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      getBrenches: "school/getBrenches",
    }),
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/certificate_all_student`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // search: this.txt_search,
            page: page,
            page_size: this.perPage,
            class_number: this.filter_data.fk_class?.id,
            // year: localStorage.getItem("current_year"),
            // student: this.filter_data.fk_student?.id,
            division: this.filter_data.fk_division?.id,
            semester: this.filter_data.fk_semester?.id,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          if (response) {
            this.items = response?.data?.results ?? response?.data;
            this.totalItems =
              response.data?.pagination?.count ?? this.items?.length;
            this.exist = true;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
            // this.getSubjectsCount(this.items[0]?.subjects);
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       class: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       division: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       semester: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  font-size: 10px;
}
th,
th div {
  font-size: 10px;
  padding-bottom: 2px;
}
table thead tr:last-of-type div span span div {
  font-size: 5px;
}
/* @media print {
  thead tr th:nth-of-type(4),
  thead tr th:nth-of-type(5),
  .background-cell,
  .failed {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
} */
.background-cell {
  background: #b1cdb6;
}
table thead tr:last-of-type div span:not(:last-of-type),
table tbody td div span span:not(:last-of-type) {
  border-left: 1px solid;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}
.total {
  background: rgb(248, 238, 103);
}
.failed {
  background: #e57373;
}
.vertical-text,
.vertical-text-span {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.vertical-text-span {
  font-size: 7px;
}
.vertical-span {
  font-size: 7px;
  padding: 2px 0;
  width: calc(100% / 3);
  writing-mode: vertical-rl;
}
.vertical-td-span {
  font-size: 7px;
  padding: 10px 0;
  width: calc(100% / 3);
}
.horizontal-span {
  font-size: 5px;
  width: calc(100% / 3);
}
.horizontal-td-span {
  font-size: 7px;
  padding: 10px 0;
  width: calc(100% / 3);
}
</style>
