<template>
  <v-card>
    <v-expand-transition v-if="getUserRole < 2">
      <div v-show="(update && getUserRole != 0) || getUserRole == 0">
        <v-card-text
          class="card-fields"
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            ((update && CheckGetPermission('school.change_class')) ||
              CheckGetPermission('school.add_class'))
          "
        >
          <v-form ref="form" @submit.prevent="saveClass">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  color="indigo"
                  v-model="classData.name_ar"
                  clearable
                  counter="100"
                  :rules="[
                    $required,
                    $max_length(100),
                    $ar_letters_only,
                    uniqueclass,
                  ]"
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('school.class.name_ar')"
                  :readonly="!checkRole(['programmer'])"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  color="indigo"
                  v-model="classData.name_en"
                  clearable
                  counter="100"
                  :rules="[
                    $required,
                    $max_length(100),
                    $en_letters_only,
                    uniqueclass,
                  ]"
                  density="compact"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('school.class.name_en')"
                  :readonly="!checkRole(['programmer'])"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="2">
                <v-autocomplete
                  color="indigo"
                  v-model="classData.type"
                  density="compact"
                  item-title="val"
                  item-value="id"
                  clearable
                  :items="[
                    { id: 1, val: $t('school.class.regular') },
                    { id: 2, val: $t('school.class.dedicated') },
                  ]"
                  :label="$t('school.class.type')"
                  :rules="[$required]"
                  prepend-inner-icon="mdi-format-list-bulleted-type"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="2">
                <v-autocomplete
                  color="indigo"
                  v-model="classData.phase"
                  density="compact"
                  item-title="val"
                  item-value="id"
                  clearable
                  :items="[
                    { id: 1, val: $t('school.class.elementary') },
                    { id: 2, val: $t('school.class.preparatory') },
                    { id: 3, val: $t('school.class.secondary') },
                  ]"
                  :label="$t('school.class.phase')"
                  :rules="[$required]"
                  prepend-inner-icon="mdi-stairs"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="4">
                <v-autocomplete
                  color="indigo"
                  multiple
                  v-model="classData.subjects"
                  density="compact"
                  :item-title="subjectsName"
                  item-value="id"
                  prepend-inner-icon="mdi-text-box-multiple-outline"
                  clearable
                  :items="subjects"
                  :label="$t('school.subject.select')"
                  :rules="[$required]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox
                  :label="$t('school.class.ministerial')"
                  color="indigo"
                  v-model="classData.ministerial"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="10">
                <v-textarea
                  color="indigo"
                  v-model="classData.note"
                  hide-details="auto"
                  density="compact"
                  counter="250"
                  clearable
                  rows="3"
                  prepend-inner-icon="mdi-note-outline"
                  :label="$t('global.note')"
                  :rules="[$max_length(250)]"
                />
              </v-col>
            </v-row>
          </v-form>
          <!-- </v-card>
        </v-col>
      </v-row> -->
        </v-card-text>
        <v-card-actions
          class="px-8"
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            ((update && CheckGetPermission('school.change_class')) ||
              CheckGetPermission('school.add_class'))
          "
        >
          <v-btn
            v-if="
              !update &&
              checkRole(['programmer']) &&
              CheckGetPermission('school.add_class')
            "
            @click="saveClass"
            class="bg-indigo"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <v-btn
            v-if="
              update &&
              checkRole(['programmer', 'big_admin']) &&
              CheckGetPermission('school.change_class')
            "
            @click="updateClass(selectedClass)"
            class="bg-success"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <VBtn class="mx-3" size="small" @click="cleanClassForm">
            {{ !update ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
              :color="!update ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
          <!-- <v-btn @click="cleanClassForm" class="mx-3" size="small">
            <span>
              {{ $t("global.clear") }}
            </span>
            <v-icon icon="mdi-broom" color="golden" end></v-icon>
          </v-btn> -->
        </v-card-actions>
        <v-divider class="mt-5"></v-divider>
      </div>
    </v-expand-transition>
    <!-- ##########    data Table Server ########### -->
    <v-card class="pa-2">
      <!-- ######## Header Of Data Table Server ####### -->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <!-- <v-btn v-bind="props" variant="text" size="small">
          <v-icon>mdi-refresh</v-icon>
        </v-btn> -->
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="classes || []"
        item-value="name_en"
        item-key="id"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span v-else-if="key == 'phase'">
                <v-chip
                  size="default"
                  density="compact"
                  v-if="val == 1"
                  class="bg-success"
                  label=""
                  variant="flat"
                >
                  <span class="text-white">{{
                    $t("school.class.elementary")
                  }}</span>
                </v-chip>
                <v-chip
                  size="default"
                  density="compact"
                  v-else-if="val == 2"
                  class="bg-warnning"
                  label=""
                  variant="flat"
                >
                  <span class="text-white">{{
                    $t("school.class.preparatory")
                  }}</span>
                </v-chip>
                <v-chip
                  size="default"
                  density="compact"
                  v-else-if="val == 3"
                  class="bg-error"
                  label=""
                  variant="flat"
                >
                  <span>{{ $t("school.class.secondary") }}</span>
                </v-chip>
              </span>
              <span v-else-if="key == 'subjects'">
                <v-chip
                  v-for="subject in val"
                  size="small"
                  class="mx-1"
                  :key="subject"
                  density="compact"
                >
                  <span>{{ getSubjectName(subject) }}</span>
                </v-chip>
              </span>
              <span v-else-if="key == 'ministerial'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else-if="key == 'type'">
                <!-- <v-chip size="small" density="compact"> -->
                <span v-if="val == 1">{{ $t("school.class.regular") }}</span>
                <!-- </v-chip> -->
                <!-- <v-chip size="small" density="compact" > -->
                <span v-else-if="val == 2">{{
                  $t("school.class.dedicated")
                }}</span>
                <!-- </v-chip> -->
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('school.change_class')
                  "
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="selectClass(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="
                    checkRole(['programmer']) &&
                    CheckGetPermission('school.delete_class')
                  "
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="(selectedClass = item.raw.id), (dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="classes.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(classes.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ classes.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="info" @click="(dialog = false), (selectedClass = '')">{{
            $t("global.cancel")
          }}</v-btn>
          <v-btn color="red" @click="deleteClass(selectedClass)">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";

export default {
  name: "Classes",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // this.$store.dispatch("updatePermission",localStorage.getItem("userinfo"));
      // this.user = this.$store.getters.getuser;
      if (this.getUserRole >= 2) {
        this.headers.pop();
      }
      await this.getClass();
      if (!this.subjects.length) await this.getSubjects({});
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      //   v$: useValidate(),
      is_loading: false,
      is_update: false,
      name: "",
      search: null,
      txt_search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      user: null,
      alert: false,
      classes: [],
      selectedHead: [
        "name_ar",
        "name_en",
        "subjects",
        "phase",
        "type",
        "ministerial",
        "actions",
      ],
      selectedClass: null,
      classData: {
        phase: null,
        name_ar: "",
        name_en: "",
        type: null,
        ministerial: false,
        subjects: null,
        note: "",
      },
      defaultClass: {
        phase: null,
        name_ar: "",
        name_en: "",
        type: null,
        ministerial: false,
        subjects: null,
        note: "",
      },
      perPage: 10,
            // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      uniqueclass: (value) => {
        if (
          !this.update &&
          this.classes.some(
            (item) =>
              item.name_ar.trim() === value.trim() ||
              item.name_en.trim() === value.trim()
          )
        ) {
          return this.$t("global.error.validation.unique");
        }
        return true;
      },
      headers: [
        { title: this.$t("school.class.name_ar"), key: "name_ar" },
        { title: this.$t("school.class.name_en"), key: "name_en" },
        { title: this.$t("school.class.phase"), key: "phase" },
        { title: this.$t("school.class.type"), key: "type" },
        { title: this.$t("school.subject.subjects_study"), key: "subjects" },
        { title: this.$t("school.class.ministerial"), key: "ministerial" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    // classesNames(){
    //     return this.classes.map(item=>item.name_ar)
    // },
    subjectsName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    // classesNamesEn(){
    //     return this.classes.map(item=>item.name_en)
    // },
    ...mapState({
      // classes: (state) => state.school.classes,
      subjects: (state) => state.school.subjects,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },

  methods: {
    ...mapActions({
      getSubjects: "school/getSubjects",
      // getClasses: "school/getClasses",
    }),
    getSubjectName(id) {
      var item = this.subjects.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    // },
    async getClass() {
      await this.axios
        .get(`${this.base_url}api/classes/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.classes = response.data;
        });
    },
    selectClass(item) {
      this.selectedClass = item.id;
      //   this.name = item.name_ar.trim();
      //   this.name_en = item.name_en.trim().toLowerCase();
      //   delete item.id;
      this.classData = Object.assign({}, item);
      this.update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    // submitClassForm(){
    //     this.v$.$validate()
    // },
    async saveClass() {
      const { valid } = await this.$refs.form.validate();
      // this.submitClassForm()
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}api/classes/`, this.classData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));
            this.cleanClassForm();
            // this.getClasses();
            this.getClass();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
      }
    },
    cleanClassForm() {
      this.update = false;
      this.$refs.form.reset();
      this.classData.ministerial = false;
      // this.$nextTick(()=>{
      //     this.classData=Object.assign({},this.defaultclass)
      // })
    },
    async updateClass(id) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}api/classes/${id}/`, this.classData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));

            // this.getClasses();
            this.getClass();
            this.cleanClassForm();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    deleteClass(id) {
      this.axios
        .delete(`${this.base_url}api/classes/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.dialog = false;
          this.$emit("successAlert", this.$t("global.success.data_deleted"));

          // this.getClasses();
          this.getClass();
          this.cleanClassForm();
        })
        .catch((error) => {
          this.$emit(
            "errorAlert",
            this.$t("global.error.failure.error_in_data")
          );
        });
    },
  },
};
</script>

<style>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
