<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes_by_branch"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4 print-content">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div
        id="myTable"
        class="d-flex px-5 flex-wrap justify-center"
        style="gap: 3rem"
      >
        <!-- style="gap: 1.5rem" -->
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <template v-for="(item, index) in items" :key="index">
          <v-card
            width="160"
            :class="`d-flex flex-column card-img ${
              (index + 1) % 20 == 0 && index > 0 ? 'print-break' : ''
            }`"
          >
            <!-- width="160" -->
            <!-- height="155px"
            max-height="155px" -->
            <v-img
              style="height: 155px"
              class="object-cover"
              :src="
                item.student_image
                  ? base_url + item.student_image
                  : require('@/assets/img/default_7.jpg')
              "
              cover
            />
            <!-- : require('@/assets/summer.jpg') -->

            <!-- <v-divider></v-divider> -->
            <v-card-text class="py-2 px-1">
              <div class="d-flex" style="gap: 0.3rem">
                <v-icon
                  size="small"
                  style="opacity: var(--v-medium-emphasis-opacity)"
                  >mdi-account-outline</v-icon
                >
                <h5>{{ item.name_ar }}</h5>
              </div>
            </v-card-text>
            <v-divider v-if="getUserRole < 2"></v-divider>
            <v-card-subtitle v-if="getUserRole < 2" class="text-center py-1">
              <h5 style="font-size: 10px">
                {{ item.branch }}
              </h5>
            </v-card-subtitle>

            <!-- <v-card-title class="d-flex justify-center">
            <v-avatar size="100">
              <v-img
                :src="
                  item.student_image
                    ? base_url + item.student_image
                    : require('@/assets/summer.jpg')
                "
                alt="Student Image"
              ></v-img>
            </v-avatar>
          </v-card-title>
          <v-card-subtitle class="text-h5 text-center">
            {{ item.name_ar }}
          </v-card-subtitle>
          <v-card-text class="text-center">{{ item.branch }}</v-card-text> -->
          </v-card>
          <v-col
            cols="12"
            class="header-repeater d-none"
            v-if="(index + 1) % 20 == 0 && index > 0"
          >
            <report-header :data="filter_data"></report-header>
          </v-col>
        </template>
        <!-- <v-card class="pt-2" :loading="loading" v-if="items.length > 0"> -->
        <!-- <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('name_ar')"
                >
                  {{ item.student["name_ar"] }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_image')"
                >
                  <v-avatar
                    v-if="item.student['student_image'] != null"
                    rounded="0"
                    size="20"
                    style="width: 40px; height: 60px"
                  >
                    <img
                      style="
                        object-fit: cover !important;
                        width: 100%;
                        height: 100%;
                      "
                      :src="`${this.base_url}${item.student['student_image']}`"
                    />
                  </v-avatar>
                  <v-icon v-else color="icon-color" size="20"
                    >mdi-account-circle</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination> -->
        <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-autocomplete
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-autocomplete>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }}
                ]
                {{ $t("globals.item") }}</span
              >
            </div>
          </div> -->
        <!-- </v-card> -->
      </div>
    </v-card-text>
    <v-card-actions class="px-10">
      <pagination
        v-model:page="page"
        v-model:perPage="perPage"
        :length="totalItems"
        :newItem="[12]"
      ></pagination>
    </v-card-actions>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBranchClasses();
      await this.getBrenches({});
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // committees: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      // year_data: localStorage.getItem("current_year_name_h"),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      // selectedHead: ["no", "name_ar", "student_image"],
      // headers: [
      //   { title: "#", key: "no" },
      //   { title: this.$t("school.reports.student_name"), key: "name_ar" },
      //   {
      //     title: this.$t("school.reports.student_image"),
      //     key: "student_image",
      //   },
      // ],
      perPage: 12,
      length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   {
      //     value: 5,
      //     text: "5",
      //   },
      //   {
      //     value: 10,
      //     text: "10",
      //   },
      //   {
      //     value: 25,
      //     text: "25",
      //   },
      //   {
      //     value: 50,
      //     text: "50",
      //   },
      //   {
      //     value: 75,
      //     text: "75",
      //   },
      //   {
      //     value: 100,
      //     text: "100",
      //   },
      //   {
      //     value: "",
      //     text: "all",
      //   },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        // fk_year: null,
        fk_class: null,
      },
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      classes_by_branch: (state) => state.school.classes_by_branch,
      branch: (state) => state.school.branch,

      // user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getBrenches: "school/getBrenches",
    }),
    // getClassName(id) {
    //   var classes = this.classes.find((el) => el.id == id);
    //   if (classes) {
    //     return classes.name_ar;
    //   }
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable() {
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/students_by_images`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: page,
            page_size: this.perPage,
            fk_class: this.filter_data.fk_class?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // fk_year: localStorage.getItem('current_year_name_h'),
          },
        })
        .then((response) => {
          if (response.data) {
            this.items = response.data?.results ?? response.data;
            this.totalItems = response.data?.pagination?.count;
            this.exist = true;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}

th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
