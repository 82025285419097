<template>
  <VCard class="justify-center">
    <VForm v-if="CheckGetPermission('school.add_seatnumbers')" ref="form">
      <VCard-text class="card-fields">
        <VRow class="justify-center">
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="committee"
                v-model="committee_students.fk_committee"
                prepend-inner-icon="mdi-group"
                item-title="name"
                item-value="id"
                :label="$t('school.committee.select')"
                persistent-hint
                density="compact"
                @update:modelValue="getClasses(), getCommitteeStudents()"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="1" lg="1">
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="classes"
                v-model="fk_class"
                prepend-inner-icon="mdi-google-classroom"
                item-title="name"
                item-value="id"
                :label="$t('school.class.select')"
                persistent-hint
                density="compact"
                clearable
                :loading="loading_commit"
                @update:modelValue="getStudents()"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow class="justify-center">
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
            <div
              :class="`border select-container ${
                !committee_student.length ? 'd-flex' : ''
              }`"
              style=""
            >
              <v-progress-linear
                v-if="loading_commit"
                color="indigo"
                indeterminate
                height="2px"
                :reverse="$i18n.locale == 'ar'"
              >
              </v-progress-linear>
              <div
                v-if="
                  !committee_student.length &&
                  !loading_commit &&
                  committee_students.fk_committee
                "
                class="align-self-center mx-auto"
              >
                اللجنة فارغة
              </div>
              <v-table v-else density="compact">
                <tbody>
                  <tr v-for="item in committee_student" :key="item">
                    <td>
                      <v-checkbox
                        color="indigo"
                        density="compact"
                        hide-details
                        :label="
                          item.number +
                          ' - ' +
                          item.student_name +
                          ' / ' +
                          item.class
                        "
                        @change="committeeCheck($event, item)"
                      >
                      </v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </VCol>
          <VCol
            class="pt-0 px-3 my-auto"
            cols="12"
            xs="12"
            sm="12"
            md="1"
            lg="1"
          >
            <div class="d-flex align-center flex-column">
              <VBtn
                class="bg-indigo mb-3"
                size="small"
                color="white"
                density="default"
                variant="tonal"
                @click="addToCommittee()"
              >
                <v-icon color="white">mdi-arrow-right</v-icon>
              </VBtn>
              <VBtn
                class="bg-indigo mb-3"
                size="small"
                color="white"
                density="default"
                variant="tonal"
                @click="removeFromCommittee()"
              >
                <v-icon color="white">mdi-arrow-left</v-icon>
              </VBtn>
            </div>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
            <div
              :class="`border select-container ${
                !class_student.length ? 'd-flex' : ''
              }`"
              style=""
            >
              <v-progress-linear
                v-if="loading_student"
                color="indigo"
                indeterminate
                height="2px"
                :reverse="$i18n.locale == 'ar'"
              >
              </v-progress-linear>
              <div
                v-if="!class_student.length && !loading_student && fk_class"
                class="align-self-center mx-auto"
              >
                الفصل فارغ
              </div>
              <v-table
                v-if="!loading_student && class_student.length"
                density="compact"
              >
                <tbody>
                  <tr v-for="item in class_student" :key="item">
                    <td>
                      <v-checkbox
                        color="indigo"
                        density="compact"
                        hide-details
                        :label="item.number + ' - ' + item.student_name"
                        @change="classCheck($event, item)"
                      >
                      </v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </VCol>
        </VRow>
      </VCard-text>
    </VForm>
    <VCardActions
      v-if="CheckGetPermission('school.add_seatnumbers') || CheckGetPermission('school.change_seatnumbers')"
      class="px-8 d-flex flex-wrap"
      elevation="4"
    >
      <!-- v-if="!is_update && CheckGetPermission('school.add_seatnumbers')" -->
      <VBtn
        v-if="CheckGetPermission('school.add_seatnumbers') || CheckGetPermission('school.change_seatnumbers')"
        class="bg-indigo"
        @click="updateCommitteeStudents"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.save") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <!-- <VBtn
        v-if="
          is_update &&
          CheckGetPermission('school.change_seatnumbers')
        "
        class="bg-indigo"
        @click="updateCommitteeStudents"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.save") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn> -->
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (hall_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteHall">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      //   fk_year: undefined,
      fk_class: undefined,
      committee_students: {
        fk_committee: undefined,
        add_students: [],
        remove_students: [],
        updated_by: undefined,
      },
      is_update: false,
      is_full: false,
      class_student: [],
      committee_student: [],
      classes: [],
      selected_from_class: [],
      selected_from_committee: [],
      is_loading: false,
      loading_student: false,
      loading_commit: false,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getAllCommittees({});
      //   this.fk_year = localStorage.getItem("current_year");
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }

    // if(this.id!=undefined)
    //     await this.axios.get(`${this.base_url}/committees-plan/${this.id}/`,
    //     {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})
    //     .then((response)=>{
    //         this.committee_students=response.data
    //     })
  },
  computed: {
    ...mapState({
      committee: (state) => state.school.committee,
      // user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAllCommittees: "school/getAllCommittees",
    }),
    resetForm() {
      this.$refs.form.reset();
      // this.v$.committee_students.$reset()
      // this.is_update=false
    },
    addToCommittee() {
      this.selected_from_committee.forEach((element) => {
        if (!this.committee_student.includes(element))
          this.committee_student.push(element);
        // this.committee_student.push(element)
        if (this.class_student.includes(element))
          this.class_student.splice(this.class_student.indexOf(element), 1);
      });

      this.selected_from_committee = [];
    },
    removeFromCommittee() {
      let fk_class = this.classes.find((item) => item.id == this.fk_class);
      this.selected_from_class.forEach((element) => {
        if (
          fk_class != undefined &&
          fk_class.name == element.class &&
          !this.class_student.includes(element)
        )
          this.class_student.push(element);
        if (this.committee_student.includes(element))
          this.committee_student.splice(
            this.committee_student.indexOf(element),
            1
          );
      });
      this.selected_from_class = [];
      // this.updateCommitteeStudents(false);
    },
    committeeCheck(event, data) {
      if (event.target.checked == true) {
        this.removeStudenFromCommittee(data.fk_student);
        this.removeStudenFromCommitteeList(data);
      } else {
        this.addStudentToCommittee(data.fk_student);
        this.addStudentToCommitteeList(data);
      }
    },
    classCheck(event, data) {
      if (event.target.checked == true) {
        this.addStudentToCommittee(data.fk_student);
        this.addStudentToCommitteeList(data);
      } else {
        this.removeStudenFromCommittee(data.fk_student);
        this.removeStudenFromCommitteeList(data);
      }
      // data.value=!this.is_full
      if (this.is_full) {
        this.$emit(
          "warningAlert",
          this.$t("global.error.failure.student_number_exceeded")
        );
        this.is_full = false;
      }
    },

    addStudentToCommittee(id) {
      let fk_class = this.classes.find((item) => item.id == this.fk_class);
      if (fk_class != undefined) {
        this.committee_student = this.committee_student
          ? this.committee_student
          : [];
        let list = this.committee_student?.filter(
          (item) => item.class == fk_class.name
        );

        if (
          list.length + this.selected_from_committee.length + 1 >
          fk_class.students_number
        ) {
          this.is_full = true;
        } else {
          if (this.committee_students.remove_students.includes(id))
            this.committee_students.remove_students.splice(
              this.committee_students.remove_students.indexOf(id),
              1
            );
          if (!this.committee_students.add_students.includes(id))
            this.committee_students.add_students.push(id);
        }
      }
    },
    removeStudenFromCommittee(id) {
      if (this.committee_students.add_students.includes(id))
        this.committee_students.add_students.splice(
          this.committee_students.add_students.indexOf(id),
          1
        );
      if (!this.committee_students.remove_students.includes(id))
        this.committee_students.remove_students.push(id);
    },
    addStudentToCommitteeList(record) {
      let fk_class = this.classes.find((item) => item.id == this.fk_class);
      if (fk_class != undefined) {
        let list = this.committee_student.filter(
          (item) => item.class == fk_class.name
        );
        if (
          list.length + this.selected_from_committee.length + 1 >
          fk_class.students_number
        ) {
          this.is_full = true;
        } else {
          if (this.selected_from_class.includes(record)) {
            this.selected_from_class.splice(
              this.selected_from_class.indexOf(record),
              1
            );
          }
          if (!this.selected_from_committee.includes(record)) {
            this.selected_from_committee.push(record);
          }
        }
      }
    },
    removeStudenFromCommitteeList(record) {
      if (this.selected_from_committee.includes(record))
        this.selected_from_committee.splice(
          this.selected_from_committee.indexOf(record),
          1
        );
      if (!this.selected_from_class.includes(record))
        this.selected_from_class.push(record);
    },
    async updateCommitteeStudents(alert = true) {
      //   this.v$.$validate();
      //   if (!this.v$.committee_students.$error) {
      // this.committee_students.updated_by = this.user;
      this.is_loading = true;
      await this.axios
        .post(
          `${this.base_url}api/exam/add-student-committee/`,
          this.committee_students,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((responce) => {
          if (alert) {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
          }
        })
        .catch((error) => {
          if (error.response.data.branch) {
            this.$emit("warningAlert", this.$t("global.error.failure.branch"));
          } else if (error.response.data.semester) {
            this.$emit(
              "warningAlert",
              this.$t("global.error.failure.branch_semester")
            );
          } else {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          }
        });
      // if (alert) {
      //   this.process_alert = true;
      //   setTimeout(() => {
      //     this.process_alert = false;
      //   }, 2000);
      // }
      //   }
      this.is_loading = false;
    },
    async getClasses() {
      let list = await this.axios.get(
        `${this.base_url}api/exam/commitee-classes/`,
        {
          params: {
            committee: this.committee_students.fk_committee,
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.classes = list.data;
      this.class_student = [];
      this.fk_class = undefined;
    },
    async getCommitteeStudents() {
      this.loading_commit = true;
      await this.axios
        .get(`${this.base_url}api/exam/commitee-students/`, {
          params: {
            committee: this.committee_students.fk_committee,
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.committee_student = response.data;
          if (this.committee_student.length > 0) this.is_update = true;
          this.committee_students.add_students = [];
          this.committee_students.remove_students = [];
          this.loading_commit = false;
        });
    },
    async getStudents() {
      this.class_student = [];
      this.loading_student = true;
      //   if (this.fk_year != undefined) {
      await this.axios
        .get(`${this.base_url}api/exam/students-rest/`, {
          params: {
            class: this.fk_class,
            //   year: this.fk_year,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // try {
          // let is_selected = false;
          // response.data.forEach((element) => {
          //   this.committee_student.forEach((item) => {
          //     if (item.fk_student == element.fk_student) {
          //       is_selected = true;
          //     }
          //   });
          // if (!is_selected) {
          this.class_student = response.data;
          // is_selected = false;
          this.loading_student = false;
          // }
          // });
          // } catch {
          //   this.class_student = [];
          //   this.loading_student = false
          // }
        })
        .catch(() => {
          this.loading_student = false;
        });
      // this.class_student=response.data
      this.selected_from_class = [];
      this.selected_from_committee = [];
      //   }
    },
  },
};
</script>
<style scoped>
.select-container {
  height: 280px;
  box-shadow: rgb(0, 0, 0) -5px 5px 3px -5px inset;
  overflow-y: auto;
}
td {
  padding: 0 !important;
}
</style>
