<template>
  <!-- <pre>{{parents}}   </pre> -->

  <!-- filter data  -->

  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_activity = null),
                getActivities(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_activity"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-soccer"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? activities
                  : []
                : activities
            "
            :label="$t('school.activity.select')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <!-- <h2 class="text-center text-grey-darken-2">
      {{ $t("report.student_activity") }}
    </h2> -->

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>

      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items?.length > 0">
          <v-row
            v-if="items?.length > 0"
            class="mb-1 mt-2 text-grey-darken-2 borders pt-2 pb-2 mr-1 ml-1"
          >
            <v-col cols="4">
              <h3 class="fontsize text-grey-darken-2">
                {{ $t("school.reports.activity") }} :
                {{ items[0].activity_data.activity_name }}
              </h3>
              <!-- <h3 class="fontsize text-grey-darken-2">
            {{ $t("school.reports.driver_phone") }} :
            {{ items[0].activity_data.driver_phone }}
          </h3> -->
            </v-col>

            <v-col cols="4">
              <h3 class="fontsize text-grey-darken-2">
                {{ $t("school.reports.activity_location") }} :
                {{ items[0].activity_data.location }}
              </h3>
              <!-- <h3 class="fontsize">
            {{ $t("school.reports.supervisor") }} :
            {{ items[0].activity_data.teacher_name }}
          </h3> -->
            </v-col>

            <v-col cols="4">
              <h3 class="fontsize text-grey-darken-2">
                {{ $t("school.reports.activity_responsible") }} :
                {{ items[0].activity_data.responsible }}
              </h3>
              <!-- <h3 class="fontsize">
            {{ $t("school.reports.teacher_phone") }} :
            {{ items[0].activity_data.teacher_phone }}
          </h3> -->
            </v-col>

            <!-- <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2">
            {{ $t("school.reports.driver_name") }} :
            {{ items[0].bus_data.driver_name }}
          </h3>
          <h3 class="fontsize text-grey-darken-2">
            {{ $t("school.reports.driver_road") }} :
            {{ items[0].bus_data.itinerary }}
          </h3>
        </v-col> -->
          </v-row>
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items[0]?.student_data" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ (page - 1) * perPage + key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('name_ar')"
                >
                  {{ item.student_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('name_en')"
                >
                  {{ item.class_number }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('phone')"
                >
                  {{ item.phone }}
                </td>
              </tr>
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      if (this.getUserRole > 1) {
        await this.getActivities();
      }
      await this.getBrenches({});
      //   this.getYears();
      //   await this.axios
      //     .get(`${this.base_url}school-data/`, {
      //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //     })
      //     .then((response) => {
      //       this.school = response.data;
      //     });
      //   this.current_year = localStorage.getItem("current_year");
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      //   current_year: "",
      //   fees_types: [],
      //   sub_list: [],
      //   drivers: [],
      //   parents: [],
      //   sub_list_sub: [],
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      //   txt_search: "",
      selectedHead: ["no", "name_ar", "name_en", "phone"],
      headers: [
        {
          title: "#",
          key: "no",
        },
        {
          title: this.$t("school.reports.student_name"),
          key: "name_ar",
        },
        {
          title: this.$t("school.reports.class"),
          key: "name_en",
        },
        {
          title: this.$t("school.reports.phone_number"),
          key: "phone",
        },
      ],
      perPage: 25,
      //   length: 0,
      totalItems: 0,
      //   itemsPerPage: [
      //     {
      //       value: 25,
      //       text: "25",
      //     },
      //     {
      //       value: 50,
      //       text: "50",
      //     },
      //     {
      //       value: 75,
      //       text: "75",
      //     },
      //     {
      //       value: 100,
      //       text: "100",
      //     },
      //     {
      //       value: "",
      //       text: "all",
      //     },
      //   ],
      page: 1,
      //   pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      //   classes: (state) => state.school.classes,
      //   class_assign_hall: (state) => state.school.class_assign_hall,
      //   academic_years: (state) => state.school.academic_years,
      //   divisions: (state) => state.school.divisions,
      //   semesters: (state) => state.school.semesters,
      activities: (state) => state.school.activities,
      branch: (state) => state.school.branch,
      //   subjects: (state) => state.school.subjects,
      //   user: (state) => state.User,
    }),

    ...mapGetters({
      //   getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      //   getClasses: "school/getClasses",
      //   getAcademicYears: "school/getAcademicYears",
      //   getDivisions: "school/getDivisions",
      //   getSemesters: "school/getSemesters",
      //   getClassAssignHall: "school/getClassAssignHall",
      //   getSubjects: "school/getSubjects",
      getActivities: "school/getActivities",
      getBrenches: "school/getBrenches",
    }),
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getYearName(year_id) {
    //   year_id = 2;
    //   if (year_id) {
    //     const year = this.academic_years.find(
    //       (year) => year.id === Number(year_id)
    //     );
    //     return year.year;
    //   }
    //   return "-------------";
    // },
    // async getParents() {
    //   await this.axios
    //     .get(`${this.base_url}api/activities/list/`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       this.parents = response.data;
    //     });
    // },
    // async getDrivers(page = 1) {
    //   await this.axios
    //     .get(`${this.base_url}driver/`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //       params: {
    //         page: page,
    //         page_size: this.perPage,
    //       },
    //     })
    //     .then((response) => {
    //       this.drivers = response.data.results;
    //     });
    // },

    // getdriverName(driver_id) {
    //   if (driver_id) {
    //     const driver = this.drivers.find((driver) => driver.id === driver_id);
    //     return driver.driver_name;
    //   }
    //   return "===========";
    // },
    // getParentName(fk_year) {
    //   const year = this.fees_types.find((year) => year.id === fk_year);
    //   return year.name;
    // },
    // getBusNumber(fk_class) {
    //   const objectClass = this.fees_types.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name_en;
    // },
    // getFeeTypeName(fk_class) {
    //   const objectClass = this.fees_types.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/student_activity_class`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: page,
            page_size: this.perPage,
            acivity_number: this.filter_data.fk_activity?.id,
            // year: localStorage.getItem("current_year"),
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data?.results;
            this.totalItems = response.data?.pagination?.count;
            // this.length=response.data.pagination.num_pages
            // this.pagination=response.data.pagination
            // this.itemsPerPage[4]['value']=this.totalItems
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  //   validations() {
  //     return {
  //       filter_data: {
  //         fk_division: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //       },
  //     };
  //   },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>

<style scoped>
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
.fontsize {
  font-size: 14px;
  text-align: center;
}
td {
  padding: 2px;
  font-size: 12px;
}

th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
