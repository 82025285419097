<template>
  <VCard class="justify-center">
    <v-card-text class="card-fields" v-if="CheckGetPermission('bus_system.add_driver') || (id && CheckGetPermission('bus_system.change_driver'))">
      <VForm ref="form">
        <VRow class="">
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="9" lg="9">
            <VCardItem class="pa-0">
              <VTextField
                v-model="driver.driver_name"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('school.driver_data.driver_name')"
                :placeholder="$t('school.driver_data.driver_name_placeholder')"
                density="compact"
                type="text"
                clearable
                :rules="[$required, $max_length(100)]"
                counter="100"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow class="">
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="driver.phone_number"
                prepend-inner-icon="mdi-cellphone-text"
                :label="$t('school.driver_data.driver_phone')"
                :placeholder="$t('school.driver_data.driver_phone_placeholder')"
                density="compact"
                type="number"
                clearable
                :rules="[$required, $numeric, $max_length(14), $min_length(9)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="driver.home_number"
                prepend-inner-icon="mdi-phone-classic"
                :label="$t('school.driver_data.driver_home_no')"
                :placeholder="
                  $t('school.driver_data.driver_home_no_placeholder')
                "
                density="compact"
                type="number"
                clearable
                :rules="[$required, $numeric, $max_length(14), $min_length(6)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="driver.age"
                prepend-inner-icon="mdi-account-alert"
                :label="$t('school.driver_data.driver_age')"
                :placeholder="$t('school.driver_data.driver_age_placeholder')"
                density="compact"
                type="number"
                clearable
                :rules="[$required, $max_value(100), $min_value(18)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow class="">
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="maritalStatus"
                v-model="driver.martial_status"
                prepend-inner-icon="mdi-account-alert"
                item-title="name"
                item-value="id"
                :label="$t('school.driver_data.driver_martial_status')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="driver.email"
                prepend-inner-icon="mdi-cellphone-text"
                :label="$t('school.driver_data.driver_email')"
                :placeholder="$t('school.driver_data.driver_email_placeholder')"
                density="compact"
                type="text"
                clearable
                :rules="[$email, $max_length(64)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="driver.address"
                prepend-inner-icon="mdi-map-marker"
                :label="$t('school.driver_data.address')"
                :placeholder="$t('school.driver_data.address_placeholder')"
                density="compact"
                type="text"
                clearable
                :rules="[$required, $max_length(100)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow class="">
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="identify_type"
                v-model="driver.identity_type"
                prepend-inner-icon="mdi-card-account-details"
                item-title="name"
                item-value="id"
                :label="$t('school.driver_data.card_type')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="driver.identity_number"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.driver_data.card_no')"
                :placeholder="$t('school.driver_data.card_no_placeholder')"
                density="compact"
                type="number"
                clearable
                :rules="[$required, $max_length(14)]"
                counter="14"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <VCardItem class="pa-0">
              <VTextField
                v-model="driver.identity_issuance"
                prepend-inner-icon="mdi-map-marker"
                :label="$t('school.driver_data.card_issuance')"
                :placeholder="
                  $t('school.driver_data.card_issuance_placeholder')
                "
                density="compact"
                type="text"
                clearable
                :rules="[$required, $max_length(100)]"
                counter="100"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow class="">
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <v-text-field
              ref="date"
              v-model="driver.identity_issuer"
              density="compact"
              :placeholder="$t('school.driver_data.card_issuer')"
              clearable
              prepend-inner-icon="mdi-calendar-clock-outline"
              id="identity_issuer"
              readonly
              :rules="issuer_date_rule"
            >
            </v-text-field>
            <Datetime
              type="date"
              element="identity_issuer"
              :color="'#0747a6'"
              @input="(e) => getPermissionTime(e, 'identity_issuer')"
              :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
              :placeholder="$t('school.teacher_data.date_of_employment')"
              :calendar="date_type == 'h' ? 'hijri' : ''"
            ></Datetime>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
            <v-text-field
              ref="date1"
              v-model="driver.identity_expiration"
              density="compact"
              :placeholder="$t('school.driver_data.card_expiration')"
              clearable
              prepend-inner-icon="mdi-calendar-clock-outline"
              id="identity_expiration"
              readonly
              :rules="expiration_date_rule"
            >
            </v-text-field>
            <Datetime
              type="date"
              element="identity_expiration"
              :color="'#0747a6'"
              @input="(e) => getPermissionTime(e, 'identity_expiration')"
              :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
              :placeholder="$t('school.teacher_data.date_of_employment')"
              :calendar="date_type == 'h' ? 'hijri' : ''"
            ></Datetime>
            <!-- <VCardItem class="pa-0">
              <VTextField
                v-model="driver.identity_expiration"
                :label="$t('school.driver_data.card_expiration')"
                density="compact"
                type="date"
                clearable
                :rules="expiration_date_rule"
              >
              </VTextField>
            </VCardItem> -->
          </VCol>
        </VRow>
        <VRow class="">
          <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="9" lg="9">
            <VCardItem class="px-3">
              <VTextarea
                v-model="driver.note"
                prepend-inner-icon="mdi-note-outline"
                clearable
                :label="$t('global.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VForm>
    </v-card-text>
    <VCardActions class="px-8 d-flex flex-wrap" v-if="CheckGetPermission('bus_system.add_driver') || (id && CheckGetPermission('bus_system.change_driver'))">
      <VBtn
        v-if="!id && CheckGetPermission('bus_system.add_driver')"
        class="bg-indigo"
        @click="saveDriver"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="id && CheckGetPermission('bus_system.change_driver')"
        class="bg-success"
        @click="updateDriver"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (driver_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteDriver">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      driver: {},
      is_loading: false,
      date_type: localStorage.getItem("date"),
      expiration_date_rule: [
        (value) => !!value || this.$t("global.error.validation.required_field"),
        (value) =>
          this.driver.identity_issuer == undefined ||
          value > this.driver.identity_issuer ||
          this.$t("global.error.validation.expiration_date"),
      ],
      issuer_date_rule: [
        (value) => !!value || this.$t("global.error.validation.required_field"),
        (value) =>
          this.driver.identity_expiration == undefined ||
          value < this.driver.identity_expiration ||
          this.$t("global.error.validation.issuer_date"),
      ],
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getIdentifyType();
      await this.getMaritalStatus();
      if (this.id != undefined)
        await this.axios
          .get(`${this.base_url}driver/${this.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.driver = response.data;
            this.driver.identity_issuer = new Date(
              response.data.identity_issuer
            )
              .toISOString()
              .split("T")[0];
            this.driver.identity_expiration = new Date(
              response.data.identity_expiration
            )
              .toISOString()
              .split("T")[0];
          });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      identify_type: (state) => state.globals.identify_type,
      maritalStatus: (state) => state.globals.maritalStatus,
      user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getIdentifyType: "globals/getIdentifyType",
      getMaritalStatus: "globals/getMaritalStatus",
    }),
    resetForm() {
      if (this.id) {
        this.$router.push({ name: "driverlist" });

        // this.$router.go(-1);
      } else {
        this.$refs.form.reset();
        this.is_update = false;
      }
    },
    getPermissionTime(e, field) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      switch (field) {
        case "identity_issuer":
          this.driver.identity_issuer = date;
          break;
        case "identity_expiration":
          this.driver.identity_expiration = date;
          break;
      }
      if (this.driver.identity_issuer && this.driver.identity_expiration) {
        this.$refs.date.validate();
        this.$refs.date1.validate();
      }
    },
    async saveDriver() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(this.base_url + "driver/", this.driver, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.resetForm();
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async updateDriver() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.driver.id != undefined) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}/driver/${this.driver.id}/`, this.driver, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            // setTimeout(() => {
            //   this.$router.push({ name: "driverlist" });
            // }, 2000);
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
<style scoped>
/* fieldset {
  border-radius: 0.4rem;
} */
</style>
