<template>
  <v-card class="justify-center" :loading="is_loading">
    <v-card-text class="card-fields">
      <!-- <v-card class="pa-2 ma-auto" > -->
      <VForm ref="form">
        <VRow>
          <VCol cols="12" md="4">
            <VSelect
              color="indigo"
              v-model="student_fee.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              :items="classes"
              @update:modelValue="getStudents(), (fk_division = undefined)"
              :label="$t('school.class.select')"
              :rules="[$required]"
            />
          </VCol>
          <VCol cols="6" md="4">
            <VSelect
              color="indigo"
              v-model="fk_division"
              density="compact"
              item-title="name"
              :no-data-text="$t('school.division.not_found')"
              item-value="id"
              prepend-inner-icon="mdi-format-list-bulleted"
              :items="getClassByDivisions(student_fee.fk_class)"
              :label="$t('school.division.select')"
              @update:modelValue="getStudents()"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="8">
            <VAutocomplete
              color="indigo"
              v-model="student_fee.fk_student"
              density="compact"
              item-title="name_ar"
              item-value="id"
              :items="student_list"
              clearable
              prepend-inner-icon="mdi-school"
              @update:modelValue="(is_selected = null), getStudentData()"
              :label="$t('school.student.name')"
              :rules="[$required]"
            />
          </VCol>
          <!-- <VCol cols="6" md="2">
                                <VBtn class="bg-indigo" @click="getStudentData" size="small">
                                    <span class="text-white">
                                        {{$t('globals.show')}}
                                    </span>
                                    <VIcon icon="mdi-presentation" color="white" end></VIcon>
                                </VBtn>
                            </VCol> -->
        </VRow>
        <v-expand-transition>
          <div v-show="is_selected">
            <VCard class="mt-4 pt-6 px-5">
              <h3 v-if="student_fee.fees.length == 0" class="my-3 text-center">
                {{ $t("school.student_fees.no_fees") }}
              </h3>
              <VRow
                class="pt-0 pb-0"
                v-for="(fee, index) in student_fee.fees"
                :key="fee"
              >
                <VCol
                  v-if="CheckGetPermission('school.delete_studentfee')"
                  class="pt-0 px-2 align-self-center mb-5"
                  cols="auto"
                >
                  <span
                    v-if="
                      fee.paid_amount == 0 &&
                      CheckGetPermission('school.delete_studentfee')
                    "
                    style="color: #d05251; border-radius: 4px"
                  >
                    <v-icon
                      @click="
                        (student_fee_del_id = fee.id), (del_dialog = true)
                      "
                      size="small"
                      style="cursor: pointer"
                      icon="mdi-trash-can-outline"
                    />
                  </span>
                </VCol>
                <VCol class="pt-0 px-2" cols="12" xs="12" sm="12" md="2" lg="2">
                  <VCardItem class="pa-0">
                    <VSelect
                      v-model="fee.fk_fees_type"
                      item-value="id"
                      item-title="name"
                      :items="fee_types_list"
                      :label="$t('school.fees.fee_type')"
                      persistent-hint
                      density="compact"
                      @update:modelValue="
                        getFeeValue(fee.fk_fees_type, index),
                          (fee.after_discount = null),
                          (fee.after_discount = fee.fee_amount)
                      "
                      :rules="[$required]"
                    >
                    </VSelect>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-2" cols="12" xs="12" sm="12" md="2" lg="2">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="fee.fee_amount"
                      :label="$t('school.fees.fee')"
                      density="compact"
                      disabled
                      :rules="[$required]"
                    />
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-2" cols="auto">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="fee.installments_number"
                      :label="$t('school.student_fees.installments_number')"
                      :placeholder="
                        $t(
                          'school.student_fees.installments_number_placehloder'
                        )
                      "
                      density="compact"
                      clearable
                      :disabled="!fee.installment_available"
                      :rules="intallment_rule"
                    >
                    </VTextField>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-2" cols="12" xs="12" sm="12" md="2" lg="2">
                  <VCardItem class="pa-0">
                    <VSelect
                      v-model="fee.fk_discount_category"
                      item-value="id"
                      item-title="category"
                      :items="discounts"
                      :label="$t('school.fees.discount_type')"
                      persistent-hint
                      clearable
                      :disabled="!fee.reducible"
                      @update:modelValue="
                        getDiscountValue(fee.fk_discount_category, index),
                          afterDiscount(fee)
                      "
                      density="compact"
                    ></VSelect>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-2" cols="12" xs="12" sm="12" md="2" lg="2">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="fee.discount_value"
                      :label="$t('school.fees.discount_amount')"
                      density="compact"
                      type="number"
                      clearable
                      :disabled="!fee.reducible"
                      :append-inner-icon="
                        fee.type == 1
                          ? 'mdi-percent-outline'
                          : 'mdi-currency-rial'
                      "
                      :rules="[
                        $numeric,
                        (value) =>
                          value == undefined ||
                          (value <= 100 && value >= 0 && fee.type == 1) ||
                          (value <= fee.fee_amount &&
                            value >= 0 &&
                            fee.type == 2) ||
                          this.$t('global.error.validation.discount_is_wrong'),
                      ]"
                      @update:model-value="afterDiscount(fee)"
                    >
                    </VTextField>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-2" cols="12" xs="12" sm="12" md="2" lg="2">
                  <!-- v-if="fee.discount_value" -->
                  <VTextField
                    v-model="fee.after_discount"
                    :label="$t('school.fees.after_discount')"
                    :append-inner-icon="'mdi-currency-rial'"
                    density="compact"
                    type="number"
                    clearable
                    disabled
                  >
                  </VTextField>
                </VCol>
                <!-- <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="1" lg="1">
                  <VCardItem class="pt-5 px-0">
                    <h3 v-if="fee.type == 1">%</h3>
                    <h3 v-if="fee.type == 2">YR</h3>
                  </VCardItem>
                </VCol> -->
              </VRow>
              <VRow
                v-if="student_fee.fees.length"
                class="mt-4 mx-10 d-flex flex-wrap"
                style="gap: 1.5rem"
                elevation="4"
              >
                <VCol class="pt-0 px-0" cols="12" xs="12" sm="12" md="5" lg="5">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="student_fee.days_between_installment"
                      prepend-inner-icon="mdi-numeric"
                      :label="
                        $t('school.student_fees.days_between_installments')
                      "
                      :placeholder="
                        $t(
                          'school.student_fees.days_between_installments_placehloder'
                        )
                      "
                      density="compact"
                      clearable
                      type="number"
                      :rules="[$min_value(1), $max_value(100)]"
                    >
                    </VTextField>
                  </VCardItem>
                </VCol>
              </VRow>
            </VCard>
          </div>
        </v-expand-transition>
      </VForm>
      <!-- </v-card> -->
    </v-card-text>
    <v-card-actions class="px-8">
      <v-btn
        v-if="CheckGetPermission('school.change_studentfee')"
        @click="updateStudentData"
        class="bg-success"
        size="small"
        :loading="is_loading"
        :disabled="!is_selected || student_fee.fees?.length == 0"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{
          !is_selected || student_fee.fees?.length == 0
            ? $t("global.clear")
            : $t("global.cancel")
        }}
        <VIcon
          :icon="
            !is_selected || student_fee.fees?.length == 0
              ? 'mdi-broom'
              : 'mdi-cancel'
          "
          :color="
            !is_selected || student_fee.fees?.length == 0 ? 'golden' : 'gray'
          "
          end
        ></VIcon>
      </VBtn>
      <!-- <v-btn @click="resetForm" class="mx-3" size="small">
        <span>
          {{ $t("global.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn> -->
    </v-card-actions>
  </v-card>
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="info"
          @click="(del_dialog = false), (student_fee_del_id = undefined)"
          >{{ $t("global.cancel") }}</v-btn
        >
        <v-btn color="red" @click="deleteStudentFee">{{
          $t("global.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      if (!this.classes.length) await this.getClasses();
      if (!this.fees.length) await this.getFees({});
      if (!this.fee_types.length) await this.getFeeTypes({});
      if (!this.discounts.length) await this.getDiscount({});
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      is_selected: false,
      fk_division: undefined,
      student_fee: {
        fk_class: undefined,
        fk_student: undefined,
        first_installments_date: undefined,
        days_between_installment: undefined,
        updated_by: undefined,
        fees: [],
      },
      student_list: [],
      // intallment_rule:[
      //     (value) => (value==undefined || /^[0-9]+$/.test(value)) || this.$t('globals.must_be_numbers'),
      //     (value) => (value==undefined || value <= 10) || `${this.$t('globals.biggest_number_of_character')} 10`,
      //     (value) => (value==undefined || value >= 0) || `${this.$t('globals.lowest_number_of_character')} 0`,
      //     ],
      student_fee_del_id: undefined,
      del_dialog: false,
      is_loading: false,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      fee_types: (state) => state.school.fee_types,
      fees: (state) => state.school.fees,
      discounts: (state) => state.school.discounts,
      user: (state) => state.User,
    }),
    ...mapGetters({
      getClassByDivisions: "school/getClassDivisions",
    }),
    fee_types_list() {
      let list = [];
      this.fee_types.forEach((element) => {
        if (element.subscribe_type >= 3) {
          let fee = this.fees.find(
            (item) =>
              item.fk_fees_type == element.id &&
              item.fk_class == this.student_fee.fk_class
          );
          if (fee && fee.fee) list.push(element);
        }
      });
      return list;
    },
    class_subjects() {
      if (this.student_fee.fk_class) {
        var class_data = this.classes.find(
          (item) => item.id == this.student_fee.fk_class
        );
        var subjects_data = [];
        if (class_data)
          class_data.subjects.forEach((element) => {
            subjects_data.push({
              id: element,
              name: this.getSubjectName(element),
            });
          });
      }
      return subjects_data;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getClassAssignHall: "school/getClassAssignHall",
      getFeeTypes: "school/getFeeTypes",
      getFees: "school/getFees",
      getDiscount: "school/getDiscount",
    }),
    getDiscountValue(id, index) {
      let discount = this.discounts.find((dic) => dic.id == id);
      if (discount) {
        this.student_fee.fees[index].discount_value = discount.amount;
        this.student_fee.fees[index].type = discount.amount_type;
      } else {
        this.student_fee.fees[index].discount_value = undefined;
        this.student_fee.fees[index].type = undefined;
      }
    },
    getFeeValue(id, index) {
      if (id && (index || index == 0)) {
        if (this.student_fee.fees.length == 0) return 0;
        let fee = this.fees.find(
          (fee) =>
            fee.fk_fees_type == id && fee.fk_class == this.student_fee.fk_class
        );
        let feetype = this.fee_types.find((fee) => fee.id == id);
        if (fee) this.student_fee.fees[index].fee_amount = fee.fee;
        else this.student_fee.fees[index].fee_amount = undefined;
        if (this.student_fee.fees[index] != undefined) {
          this.student_fee.fees[index].installment_available =
            feetype.installment_available;
          this.student_fee.fees[index].reducible = feetype.reducible;
        }
      }
    },
    async getStudents() {
      this.student_fee.fk_student = undefined;
      // if(localStorage.getItem('current_year')!=undefined)
      // {
      await this.axios
        .get(`${this.base_url}student-list/`, {
          params: {
            class: this.student_fee.fk_class,
            division: this.fk_division,
            // year:localStorage.getItem('current_year')
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.student_list = response.data;
        });
      // }
    },
    async getStudentData() {
      // const { valid } = await this.$refs.form.validate();
      if (this.student_fee.fk_class && this.student_fee.fk_student) {
        this.is_loading = true;
        await this.axios
          .get(`${this.base_url}/student-fee/`, {
            params: {
              fk_class: this.student_fee.fk_class,
              fk_student: this.student_fee.fk_student,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.student_fee.fees = [];
            if (response.data.length) {
              response.data.forEach((element) => {
                let fee = this.fees.find((fee) => fee.id == element.fk_fee);
                let fee_type = this.fee_types.find(
                  (item) => item.id == fee.fk_fees_type
                );
                this.student_fee.fees.push({
                  id: element.id,
                  paid_amount: element.paid_amount,
                  fk_discount_category: element.fk_discount_category,
                  fk_fees_type: fee.fk_fees_type,
                  installments_number: element.installments_number,
                  discount_value:
                    element.discount_value == 0
                      ? undefined
                      : element.discount_value,
                  fee_amount: fee.fee,
                  type: element.discount_type,
                  reducible: fee_type.reducible,
                  installment_available: fee_type.installment_available,
                  after_discount:
                    fee.fee -
                    (element.discount_type == 1
                      ? (fee.fee *
                          (element.discount_value == 0
                            ? undefined
                            : element.discount_value)) /
                        100
                      : element?.discount_value),
                });
                if (element.days_between_installment)
                  this.student_fee.days_between_installment =
                    element.days_between_installment;
              });
            }
          })
          .catch((error) => {
            if (error.response?.data?.non_field_errors) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.unique")
              );
            } else if (error.response?.data?.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response?.data?.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        this.is_selected = true;
      }
      this.is_loading = false;
    },
    async updateStudentData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .put(
            `${this.base_url}student-fee/${this.student_fee.fees[0].id}/`,
            this.student_fee,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
          })
          .catch((error) => {
            if (error.response.data.non_field_errors) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.unique")
              );
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        this.getStudentData();
      }
      this.is_loading = false;
    },
    async deleteStudentFee() {
      if (this.student_fee_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}student-fee/${this.student_fee_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
        this.del_dialog = false;
        this.student_fee_del_id = undefined;
        this.getStudentData();
      }
    },
    afterDiscount(item) {
      if (item.discount_value && item.fee_amount) {
        item.after_discount =
          item.fee_amount -
          (item.type == 1
            ? (item.fee_amount * item.discount_value) / 100
            : item.discount_value);
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
  },
};
</script>
