<template>
    <!-- filter data  -->
    <v-card class="pa-2" v-if="!exist">
        <h3 class="text-grey-darken-2 px-2">
            {{ $t("globals.select-criteria") }}
        </h3>
        <v-row class="my-2 mt-6">
            <v-col cols="6" md="3" sm="12" >
                <v-autocomplete
                    color="indigo"
                    v-model="filter_data.class_number"
                    density="compact"
                    item-title="name_ar"
                    item-value="id"
                    prepend-inner-icon="mdi-google-classroom"
                    :items="classes"
                    :label="$t('class.select')"
                    hide-details='auto'
                    @update:modelValue="
                            (filter_data.fk_division = null)
                    "
                    :error-messages="
                        v$.filter_data.class_number.$errors.map(
                            (e) => e.$message
                        )
                    "
                />
            </v-col>
            <v-col cols="6" md="3" sm="12" >
                <v-autocomplete
                    color="indigo"
                    v-model="filter_data.fk_division"
                    density="compact"
                    item-title="name"
                    item-value="id"
                    prepend-inner-icon="mdi-chair-school"
                    :no-data-text="$t('school.division.not_found')"
                    :items="getClassDivisions(filter_data.class_number)"
                    :label="$t('division.select')"
                    hide-details='auto'
                    :error-messages="
                        v$.filter_data.fk_division.$errors.map(
                            (e) => e.$message
                        )
                    "
                />
            </v-col>
            <v-col cols="6" md="3" sm="12">
                <v-autocomplete
                    color="indigo"
                    v-model="filter_data.fk_month" 
                    density="compact"
                    item-title="name"
                    item-value="id"
                    prepend-inner-icon="mdi-calendar-month"
                    :items="months"
                    :label="$t('month.month')"
                    :error-messages="v$.filter_data.fk_month.$errors.map(e=>e.$message)"
                    @input="v$.filter_data.fk_month.$touch"
                    @blur="v$.filter_data.fk_month.$touch"
                ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="2" class="d-flex align-center">
                <v-btn
                    density="comfortable"
                    append-icon="mdi-presentation"
                    class="w-100"
                    color="indigo"
                    @click="checkFilterData"
                    >
                    <span>{{ $t("globals.show") }}</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
    <v-card class="justify-center" id="lectures-report" v-if="exist">
        <v-row class="mt-4 text-grey-darken-2">
            <v-col cols="4" class="text-center">
                <h2>{{ $t('globals.republic_of_yemen')}}</h2>
                <h3 v-if="school.length > 0">
                    {{ school[0].ministry_name ? school[0].ministry_name : "" }}
                </h3>
                <h5 v-if="school.length > 0">{{ school[0].arabic_name }}</h5>
            </v-col>
            <v-col cols="4" class="text-center">
                <h4>{{ $t('in_the_name_of_allah') }}</h4>
                <v-responsive v-if="school.length > 0">
                    <v-avatar size="80">
                        <v-img :src="school[0].logo"></v-img>
                    </v-avatar>
                </v-responsive>
            </v-col>
            <v-col cols="4" class="text-center">
                <h3>{{ $t('month.month') }} : {{ getMonthName }}</h3>
                <!-- <h3>{{ $t('globals.to') }}  : {{ filter_data.max_date }}</h3> -->
                <h3>{{ $t('class.class') }}  : {{ getClassName(filter_data.class_number) }}</h3>
            </v-col>
        </v-row>
        <h2 class="text-center text-grey-darken-2 mt-2">
            {{ $t("report.attendance_month_report") }}
        </h2>
        <v-card-text class="mb-4">
            <div class="d-flex justify-space-between mb-2" id="tableHead">
                <div class="d-flex justify-start" style="flex: 1">
                    <v-menu :close-on-content-click="false">
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                density="compact"
                                class="px-1"
                                style="min-width: 30px"
                            >
                                <v-icon color="icon-color">mdi-eye</v-icon>
                                <v-icon color="icon-color"
                                    >mdi-chevron-down</v-icon
                                >
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-checkbox
                                    v-for="header in headers"
                                    :key="header"
                                    :label="header.title"
                                    :value="header.key"
                                    color="indigo"
                                    v-model="selectedHead"
                                    density="compact"
                                    hide-details
                                >
                                </v-checkbox>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <v-btn
                        v-bind="props"
                        density="compact"
                        class="pa-0 mx-1"
                        style="min-width: 25px"
                    >
                        <v-icon color="icon-color">mdi-refresh</v-icon>
                    </v-btn>
                </div>
                <div class="d-flex justify-end" style="flex: 1">
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                density="compact"
                                class="pa-0 mx-1"
                                style="min-width: 25px"
                            >
                                <v-icon color="icon-color">mdi-printer</v-icon>
                                <v-tooltip
                                    class="tooltip"
                                    activator="parent"
                                    location="top"
                                >
                                    <small>{{
                                        $t("globals.print-file")
                                    }}</small>
                                </v-tooltip>
                            </v-btn>
                        </template>
                        <v-list id="print-menu" density="compact">
                            <v-list-item @click="printTable((all = false))">{{
                                $t("globals.shown-records")
                            }}</v-list-item>
                            <v-list-item @click="printTable((all = true))">{{
                                $t("globals.all-records")
                            }}</v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        density="compact"
                        class="mx-1"
                        @click="(exist = false), v$.filter_data.$reset()"
                    >
                        <span class="text-indigo">{{
                            $t("globals.back")
                        }}</span>
                    </v-btn>
                </div>
            </div>
            <div id="myTable">
                <v-card v-if="items.length < 1" class="pa-2">
                    <h3 class="text-center text-grey-darken-2">
                        {{ $t("globals.not-found-items") }}
                    </h3>
                </v-card>
                <v-card class="pt-2" :loading="loading" v-if="items.length > 0">
                    <table style="width: 100%; border-collapse: collapse">
                        <thead>
                            <th
                                class="bold"
                                v-for="header in selectedHeaders"
                                :key="header.key"
                            >
                                {{ header.title }}
                            </th>
                        </thead>
                        <tbody v-if="!loading">
                            <tr v-for="(item, index) in items" :key="index">
                                <td class="text-center border">
                                    {{ item.student_name }}
                                </td>
                                <td class="text-center border">
                                    {{ item.attendance_date }}
                                </td>
                                <td class="text-center border">
                                    <v-icon
                                        style="color: #4caf50"
                                        v-if="item.is_attendance"
                                        class="me-2"
                                        >mdi mdi-check-circle</v-icon
                                    >
                                    <v-icon
                                        style="color: #ff0000"
                                        v-else
                                        class="me-2"
                                        >mdi mdi-close-circle</v-icon
                                    >

                                    <!-- {{ =true?"" }} -->
                                </td>
                                <td class="text-center border">
                                    <v-icon
                                        style="color: #4caf50"
                                        v-if="item.is_permission"
                                        class="me-2"
                                        >mdi mdi-check-circle</v-icon
                                    >
                                    <v-icon
                                        style="color: #ff0000"
                                        v-else
                                        class="me-2"
                                        >mdi mdi-close-circle</v-icon
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex" id="pagination-bar">
                        <v-pagination
                            v-model="page"
                            :length="length"
                            density="compact"
                            show-first-last-page
                        >
                        </v-pagination>
                        <div class="d-flex">
                            <v-autocomplete
                                v-model="perPage"
                                class="pa-0"
                                :items="itemsPerPage"
                                item-value="value"
                                item-title="text"
                                density="compact"
                                hide-details
                                variant="text"
                            >
                            </v-autocomplete>
                            <span class="mt-2 px-2"
                                >{{ $t("globals.per-page") }}
                                {{ $t("globals.from") }} [ {{ totalItems }} ]
                                {{ $t("globals.item") }}</span
                            >
                        </div>
                    </div>
                </v-card>
            </div>
        </v-card-text>
    </v-card>
    <Alert
        v-model="process_alert"
        :iconColor="icon_color"
        :icon="icon"
        :title="alert_title"
        :message="alert_message"
    >
    </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
import { required, helpers } from "@vuelidate/validators";
export default {
    async created() {
        try {
            await this.$store.commit('updateLoadingValue',true)
            this.$store.dispatch(
                "updateAuthenticated",
                localStorage.getItem("token")
            );
            await this.getClasses();
            this.getClassAssignHall();
            this.getDivisions()
            this.getMonths()
            await this.axios.get(`${this.base_url}school-data/`,{
                    headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
                }).then(response=>{
                    this.school=response.data
                })
            // this.getTeachers();
            this.$store.commit('updateLoadingValue',false)
        } catch (error) {
            this.$store.commit('updateLoadingValue',false)
            
        }
    },
    components:{Datetime},
    data() {
        return {
            teachers: [],
            school: [],
            day_count: 7,
            process_alert: false,
            icon_color: undefined,
            alert_title: undefined,
            alert_message: undefined,
            committees: [],
            v$: useValidate(),
            exist: false,
            items: [],
            loading: false,
            txt_search: "",
            selectedHead: [
                "student_name",
                "attendance_date",
                "is_attendance",
                "is_permission",
            ],
            headers: [
                {
                    title: this.$t("school.student_name"),
                    key: "student_name",
                },
                {
                    title: this.$t("permission.attendance_date"),
                    key: "attendance_date",
                },
                {
                    title: this.$t("permission.is_attendance"),
                    key: "is_attendance",
                },
                {
                    title: this.$t("permission.is_permission"),
                    key: "is_permission",
                },
            ],
            perPage: 5,
            length: 0,
            totalItems: 0,
            itemsPerPage: [
                {
                    value: 5,
                    text: "5",
                },
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 75,
                    text: "75",
                },
                {
                    value: 100,
                    text: "100",
                },
                {
                    value: "",
                    text: "all",
                },
            ],
            page: 1,
            pagination: {},
            filter_data: {
                class_number: null,
                fk_month: null,
                fk_division: null,
                type:"month"
            },
        };
    },

    computed: {
        ...mapState({
            classes: (state) => state.school.classes,
            divisions: (state) => state.school.divisions,
            months:(state)=>state.school.months,
            user: (state) => state.User,
        }),
        ...mapGetters({
            getClassDivisions: "school/getClassDivisions",
        }),
        dateToday() {
            var date = new Date().toJSON().slice(0, 10);
            return date;
        },
        selectedHeaders() {
            return this.headers.filter((header) =>
                this.selectedHead.includes(header.key)
            );
        },
        getMonthName(){
            let month=this.months.find(item=>item.id==this.filter_data.fk_month)
            if(month != undefined)
                return month.name
            return null
        }
    },
    methods: {
        ...mapActions({
            getClasses: "school/getClasses",
            getDivisions: "school/getDivisions",
            getClassAssignHall: "school/getClassAssignHall",
            getMonths:"school/getMonths",
        }),
        async getTeachers() {
            await this.axios
                .get(`${this.base_url}api/teachers/data/`, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((response) => {
                    this.teachers = response.data;
                });
        },
        getPermissionTime(e, id) {
            let date = e;
            if (this.$i18n.locale == "ar") {
                date = this.toEnglishString(e);
            }
            if (id == 1) {
                this.filter_data.min_date = date;
            } else {
                this.filter_data.max_date = date;
            }
        },
        getClassName(class_id) {
            const objectClass = this.classes.find(
                (objectClass) => objectClass.id === class_id
            );
            return objectClass.name_ar;
        },
        addLineBreak(text) {
            var formattedText = text.replace(/@/g, "<br>");
            return formattedText;
        },
        checkFilterData() {
            this.v$.filter_data.$validate();
            if (!this.v$.filter_data.$error) {
                this.getData();
            }
        },
        getSchdual(subjects, id) {
            for (let key in subjects) {
                if (id == subjects[key].quotas_id) {
                    return `${subjects[key].fk_subject} <br> ${subjects[key].fk_division} <br> ${subjects[key].fk_class} `;
                } else {
                    continue;
                }
            }
        },
        async printTable(all) {
            if (all) {
                this.txt_search = null;
                this.perPage = this.totalItems;
                await this.getData();
            }
            window.print();
        },
        async getData(page = 1) {
            this.loading = true;
            await this.axios
                .get(`${this.base_url}api/days_student_attendance`, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                    params: {
                        page: page,
                        page_size: this.perPage,
                        class_number: this.filter_data.class_number,
                        month: this.filter_data.fk_month,
                        division: this.filter_data.fk_division,
                        type:this.filter_data.type
                    },
                })
                .then((response) => {
                    if (response.data.results) {
                        this.items = response.data.results;
                        this.exist = true;
                        this.totalItems = response.data.pagination.count;
                        this.length = response.data.pagination.num_pages;
                        this.pagination = response.data.pagination;
                        this.itemsPerPage[4]["value"] = this.totalItems;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.icon = "cancel";
                    this.icon_color = "error";
                    if (error.message.includes("Network Error")) {
                        this.alert_title = this.$t("alert.failure.title");
                        this.alert_message = this.$t("alert.failure.connect");
                    } else {
                        this.alert_title = this.$t("alert.not-found.title");
                        this.alert_message = this.$t("alert.not-found.message");
                    }
                    this.process_alert = true;
                    setTimeout(() => {
                        this.process_alert = false;
                    }, 2000);
                });
        },
    },
    validations() {
        return {
            filter_data: {
                class_number: {
                    required: helpers.withMessage(
                        this.$t("errors.required"),
                        required
                    ),
                },
                fk_month: {
                    required: helpers.withMessage(
                        this.$t("errors.required"),
                        required
                    ),
                },
                fk_division: {
                    required: helpers.withMessage(
                        this.$t("errors.required"),
                        required
                    ),
                },
            },
        };
    },
    watch: {
        perPage() {
            this.getData();
        },
        page() {
            this.getData(this.page);
        },
    },
};
</script>

<style scoped>
td {
    padding: 2px;
    font-size: 12px;
}

th {
    font-size: 14px;
    padding-bottom: 2px;
}

.cursor_pointer {
    cursor: pointer !important;
}
</style><style>
.search:focus {
    outline: unset;
}
@media print {
    #sidebar,
    #header,
    #pagination-bar *,
    #tableHead,
    .tooltip,
    #print-menu {
        display: none !important;
    }

    #lectures-report {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
