<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_division = null),
                getClassAssignHall(filter_data.fk_branch?.id),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="filter_data.fk_division = null"
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            :no-data-text="$t('school.division.not_found')"
            prepend-inner-icon="mdi-chair-school"
            :items="
              filter_data.fk_class?.id
                ? getClassDivisions(filter_data.fk_class?.id, getUserRole < 2)
                : []
            "
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            ref="select"
            color="indigo"
            v-model="filter_data.fk_month"
            density="compact"
            :item-title="date_type == 'm' ? 'name_m' : 'name_h'"
            item-value="id"
            prepend-inner-icon="mdi-calendar"
            :items="getCurrentSemesterMonths"
            :label="$t('school.reports.select_month')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <!-- <v-col cols="12" md="3" sm="12" >
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes"
            :label="$t('school.reports.select_class')"
            @update:model-value="filter_data.fk_division = null"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12" >
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-chair-school"
            :items="class_divisions"
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items?.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th
                v-for="header in selectedHeaders"
                :key="header.key"
                class="border backgrounds pa-1"
              >
                {{ header.title }}
              </th>
              <!-- <tr>
                <th
                  rowspan="2"
                  class="border background-cell"
                  style="width: 150px"
                >
                  {{ $t("school.student-name") }}
                </th>
                <th
                  v-for="item in items[0].data"
                  :key="item"
                  class="border background-cell"
                  :style="items[0].data.length >= 8 ? '' : 'width:0'"
                >
                  {{ item[0].subject_name }}
                  {{ item[0].optional == true ? "*" : "" }}
                </th>
                <th
                  rowspan="2"
                  class="border background-cell"
                  style="width: 100px"
                >
                  <div class="vertical-text" v-if="items[0].data.length >= 8">
                    {{ $t("report.total") }}
                  </div>
                  <div v-else>{{ $t("report.total") }}</div>
                </th>
              </tr>
              <tr>
                <th
                  v-for="item in items[0].data"
                  :key="item"
                  class="py-0 background-cell border"
                  :style="items[0].data.length >= 8 ? 'width: 0' : ''"
                >
                  <div class="">
                    <span class="d-flex justify-content-between">
                      <span
                        class="background-cell d-flex justify-center align-center vertical-span"
                        v-if="items[0].data.length >= 8"
                      >
                        <div
                          class="vertical-text-span d-flex justify-center align-center vertical-span"
                        >
                          {{ $t("monthly_marks.exam") }}
                        </div>
                      </span>
                      <span
                        v-else
                        class="background-cell d-flex justify-center align-center horizontal-span"
                        >{{ $t("monthly_marks.exam") }}</span
                      >
                      <span
                        class="background-cell d-flex justify-center align-center vertical-span"
                        v-if="items[0].data.length >= 8"
                      >
                        <div
                          class="vertical-text-span d-flex justify-center align-center vertical-span"
                        >
                          {{ $t("monthly_marks.oral") }}
                        </div>
                      </span>
                      <span
                        v-else
                        class="background-cell d-flex justify-center align-center horizontal-span"
                      >
                        {{ $t("monthly_marks.oral") }}</span
                      >
                      <span
                        class="background-cell d-flex justify-center align-center vertical-span"
                        v-if="items[0].data.length >= 8"
                      >
                        <div
                          class="vertical-text-span d-flex justify-center align-center vertical-span"
                        >
                          {{ $t("report.students.assigments") }}
                        </div>
                      </span>
                      <span
                        v-else
                        class="background-cell d-flex justify-center align-center horizontal-span"
                        >{{ $t("report.students.assigments") }}</span
                      >
                      <span
                        class="background-cell d-flex justify-center align-center vertical-span"
                        v-if="items[0].data.length >= 8"
                      >
                        <div
                          class="vertical-text-span d-flex justify-center align-center vertical-span"
                        >
                          {{ $t("report.students.attendance_mark") }}
                        </div>
                      </span>
                      <span
                        v-else
                        class="background-cell d-flex justify-center align-center horizontal-span"
                      >
                        {{ $t("report.students.attendance_mark") }}</span
                      >
                      <span
                        class="background-cell d-flex justify-center align-center vertical-span"
                        v-if="items[0].data.length >= 8"
                      >
                        <div
                          class="vertical-text-span d-flex justify-center align-center vertical-span"
                        >
                          {{ $t("report.total") }}
                        </div>
                      </span>
                      <span
                        v-else
                        class="background-cell d-flex justify-center align-center horizontal-span"
                      >
                        {{ $t("report.total") }}</span
                      >
                    </span>
                  </div>
                </th>
              </tr> -->
            </thead>
            <tbody v-if="!loading">
              <template v-for="(item, index) in items" :key="item.id">
                <tr v-for="(sub, subIndex) in item?.data" :key="sub">
                  <template v-if="subIndex === 0">
                    <td
                      v-if="selectedHead.includes('no')"
                      :rowspan="item?.data?.length"
                      class="text-center border"
                    >
                      {{ (page - 1) * perPage + index + 1 }}
                    </td>
                    <td
                      v-if="selectedHead.includes('student_name')"
                      :rowspan="item?.data?.length"
                      class="text-center border"
                    >
                      {{ item.student_name }}
                    </td>
                    <!-- <td
                      v-if="selectedHead.includes('class_name')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ item.class_name }}
                    </td> -->
                  </template>

                  <td
                    v-for="header in selectedHeaders?.filter(
                      (head) => head.key != 'no' && head.key != 'student_name'
                    )"
                    :key="header.key"
                    class="text-center border"
                  >
                    {{ sub[header.key] != null ? sub[header.key] : "-" }}
                  </td>
                </tr>
                <tr></tr>
              </template>
              <!-- <tr v-for="item in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item[0].student_name }}
                </td>
                <td
                  v-for="subject in item[0].data"
                  :key="subject"
                  class="py-0 border"
                >
                  <div>
                    <span class="d-flex justify-content-between">
                      <span
                        class="d-flex justify-center align-center"
                        :class="
                          item[0].data.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span'
                        "
                        >{{ subject[0].exam }}</span
                      >
                      <span
                        class="d-flex justify-center align-center"
                        :class="
                          item[0].data.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span'
                        "
                        >{{ subject[0].oral }}</span
                      >
                      <span
                        class="d-flex justify-center align-center"
                        :class="
                          item[0].data.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span'
                        "
                        >{{ subject[0].assigments }}</span
                      >
                      <span
                        class="d-flex justify-center align-center"
                        :class="
                          item[0].data.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span'
                        "
                        >{{ subject[0].attendance_mark }}</span
                      >
                      <span
                        class="d-flex justify-center align-center"
                        :class="[
                          item[0].data.length >= 8
                            ? 'vertical-td-span'
                            : 'horizontal-td-span',
                          subject[0].total < 50 ? 'failed' : 'total',
                        ]"
                        >{{ subject[0].total }}</span
                      >
                    </span>
                  </div>
                </td>
                <td class="text-center border">
                  {{ getSubjectsTotal(item[0].data).sum() }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[10]"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
// import tafqeet from "@/assets/Tafqeet";
export default {
  async created() {
    try {
      await this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // this.getYears();
      if (this.getUserRole > 1) {
        await this.getBranchClasses();
      }
      await this.getBrenches({});
      await this.getSemesters();
      await this.getClassAssignHall();
      await this.getMonths();
      await this.getDivisions({});
      await this.getCuMonth();
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      //   tdRows: true,
      //   school: [],
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      date_type: localStorage.getItem("date"),
      //   txt_search: "",
      selectedHead: [
        "no",
        "student_name",
        // "class_name",
        "subject_name",
        "assignments",
        "oral",
        "exam",
        "attendance",
        "total",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        // { title: this.$t("school.reports.class_name"), key: "class_name" },

        {
          title: this.$t("school.reports.subject_name"),
          key: "subject_name",
        },
        { title: this.$t("school.reports.attendance"), key: "attendance" },
        { title: this.$t("school.reports.assigments"), key: "assignments" },
        { title: this.$t("school.reports.oral"), key: "oral" },
        { title: this.$t("school.reports.exam"), key: "exam" },
        { title: this.$t("school.reports.net_total"), key: "total" },
        ,
      ],
      // selectedHead: ["student_name", "collector", "final_exam"],
      // headers: [
      //   { title: this.$t("school.reports.student_name"), key: "student_name" },
      //   { title: this.$t("school.reports.collector"), key: "collector" },
      //   { title: this.$t("school.reports.final_exam"), key: "final_exam" },
      //   { title: this.$t("school.reports.total"), key: "total" },
      //   { title: this.$t("school.reports.writing"), key: "writing" },
      //   { title: "", key: "empty" },
      //   {
      //     title: this.$t("school.reports.count_of_actual_lecture"),
      //     key: "count_of_actual_lecture",
      //   },
      //   {
      //     title: this.$t("school.reports.count_of_attendence"),
      //     key: "count_of_attendence",
      //   },
      //   {
      //     title: this.$t("school.reports.count_of_absentce"),
      //     key: "count_of_absentce",
      //   },
      //   {
      //     title: this.$t("school.reports.count_of_permission"),
      //     key: "count_of_permission",
      //   },
      //   {
      //     title: this.$t("school.reports.count_of_not_permission"),
      //     key: "count_of_not_permission",
      //   },
      // ],
      perPage: 10,
      //   length: 0,
      totalItems: 0,
      //   itemsPerPage: [
      //     {
      //       value: 5,
      //       text: "5",
      //     },
      //     {
      //       value: 10,
      //       text: "10",
      //     },
      //     {
      //       value: 25,
      //       text: "25",
      //     },
      //     {
      //       value: 50,
      //       text: "50",
      //     },
      //     {
      //       value: 75,
      //       text: "75",
      //     },
      //     {
      //       value: 100,
      //       text: "100",
      //     },
      //     {
      //       value: "",
      //       text: "all",
      //     },
      //   ],
      page: 1,
      //   pagination: {},
      filter_data: {
        fk_month: null,
      },
      //   subjectsTotal: 0,
      //   subjectsCount: 0,
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      classes_by_branch: (state) => state.school.classes_by_branch,
      divisions: (state) => state.school.divisions,
      months: (state) => state.school.months,
      semesters: (state) => state.school.semesters,
      branch: (state) => state.school.branch,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
      getCurrentSemesterMonths: "school/getCurrentSemesterMonths",
    }),
    class_divisions() {
      if (this.filter_data.fk_class?.id) {
        return this.getClassDivisions(this.filter_data.fk_class?.id);
      }
    },

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    // tafqeet,
    // getPercentageValue(sum, count) {
    //   const total = (sum / count) * 100;
    //   return parseFloat(total.toFixed(2) / 100).toFixed(2);
    // },
    getSubjectsTotal(subjects) {
      const { sum, count, pass } = subjects.reduce(
        (accumulator, obj) => {
          if (obj[0].optional == false)
            return {
              sum: accumulator.sum + obj[0].total,
              count: accumulator.count + 1,
              pass: accumulator.pass & (obj[0].total > 50) ? true : false,
            };
          else
            return {
              sum: accumulator.sum,
              count: accumulator.count,
              pass: accumulator.pass,
            };
        },
        { sum: 0, count: 0, pass: true }
      );
      return {
        sum: () => sum,
        count: () => count,
        pass: () => pass,
      };
    },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getMonthName(month_id) {
    //   if (month_id) {
    //     const month = this.months.find((month) => month.id === month_id);
    //     return month.name;
    //   }
    //   return "-------------";
    // },
    // getClassName(class_id) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === class_id
    //   );
    //   return objectClass.name_ar;
    // },
    // getDivisionName(division_id) {
    //   const division = this.divisions.find(
    //     (division) => division.id === division_id
    //   );
    //   return division.name;
    // },
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getClassAssignHall: "school/getClassAssignHall",
      getMonths: "school/getMonths",
      //   getAllEstimations: "school/getAllEstimations",
      getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      getBrenches: "school/getBrenches",
    }),
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/month_exam_result_all`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            // search: this.txt_search,
            page: page,
            page_size: this.perPage,
            class_number: this.filter_data.fk_class?.id,
            // year: this.filter_data.year,
            month: this.filter_data.fk_month?.id,
            class_division: this.filter_data.fk_division?.id,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          if (response.data) {
            this.items = response.data?.results;
            this.totalItems = response.data?.pagination?.count;
            this.exist = true;
            // this.totalItems = response.data.pagination.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
    getCuMonth() {
      this.filter_data.fk_month = this.getCurrentSemesterMonths?.find(
        (month) =>
          month.name_h == localStorage.getItem("month_h") ||
          month.name_m == localStorage.getItem("month_m")
      );
    },
  },
  //   validations() {
  //     return {
  //       filter_data: {
  //         class: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //         year: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //         division: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //         month: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //       },
  //     };
  //   },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  font-size: 10px;
}
th,
th div {
  font-size: 10px;
  padding-bottom: 2px;
}
/* @media print {
  thead tr th:nth-of-type(4),
  thead tr th:nth-of-type(5),
  .background-cell,
  .failed {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
} */
.background-cell {
  background: #b1cdb6;
}
table thead tr:last-of-type div span:not(:last-of-type),
table tbody td div span span:not(:last-of-type) {
  border-left: 1px solid;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}
.total {
  background: rgb(248, 238, 103);
}
.failed {
  background: #e57373;
}
.vertical-text,
.vertical-text-span {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.vertical-text-span {
  font-size: 7px;
}
.vertical-span {
  font-size: 7px;
  padding: 10px 0;
  width: calc(100% / 5);
  writing-mode: vertical-rl;
}
.vertical-td-span {
  font-size: 7px;
  padding: 10px 0;
  width: calc(100% / 5);
}
.horizontal-span {
  font-size: 5px;
  width: calc(100% / 5);
}
.horizontal-td-span {
  font-size: 7px;
  padding: 10px 0;
  width: calc(100% / 5);
}
th,
td {
  padding: 0;
}
</style>
