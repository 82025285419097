<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <!-- <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:model-value="filter_data.fk_division = null"
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-chair-school"
            :items="class_divisions"
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col v-if="getUserRole < 2" cols="12" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_division = null),
                (filter_data.fk_student = null),
                (student_list = []),
                getClassAssignHall(filter_data.fk_branch?.id),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole > 1 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="
              (filter_data.fk_division = null),
                (filter_data.fk_student = null),
                (student_list = [])
            "
          />
        </v-col>
        <v-col cols="6" :md="getUserRole > 1 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            :no-data-text="$t('school.division.not_found')"
            prepend-inner-icon="mdi-chair-school"
            :items="class_divisions"
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" :md="getUserRole > 1 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.period_am"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-timeline-clock-outline"
            hide-details="auto"
            :items="[
              { id: 1, name: $t('school.weekly_schedule.am') },
              { id: 0, name: $t('school.weekly_schedule.pm') },
            ]"
            :label="$t('school.reports.select_period')"
            :rules="[$required]"
            return-object
          ></v-autocomplete>
          <!-- @update:modelValue="createTable" -->
        </v-col>
        <v-col cols="auto" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu @toggle-visibility="() => (exist = !exist)"></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <!-- <th>{{ $t("school.reports.day") }}</th> -->

              <tr>
                <diagonal-header
                  :leftLabel="$t('school.reports.lecture')"
                  :rightLabel="$t('school.reports.day')"
                ></diagonal-header>
                <th v-for="schedule in timetable_schedules" :key="schedule">
                  {{ schedule.quotas }}
                </th>
              </tr>
              <!-- <th v-for="(item, index) in items[0]?.schedule" :key="index">
                {{ filter_data.fk_period?.name }}
              </th> -->
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, index) in days_choices" :key="index">
                <th class="text-center border">
                  {{ item.name }}
                </th>
                <template v-for="quat in timetable_schedules" :key="quat?.id">
                  <td class="text-center border">
                    {{ findData(item?.id, quat?.id)?.fk_subject ?? "-" }}
                    <!-- <br />
                    <div class="d-flex justify-space-between">
                      <h6>{{ quat_data?.class_name }}</h6>
                      <h6>{{ quat_data?.division_name }}</h6>
                    </div> -->
                  </td>
                </template>
              </tr>
              <!-- <tr v-for="(item, index) in items" :key="index">
                <td class="text-center border">
                  {{ item.day_name }}
                </td>
                <template v-for="quat in timetable_schedules" :key="quat?.id">
                  <td class="text-center border">
                    {{
                      item?.schedule?.find(
                        (data) => data?.quotas_id == quat?.id
                      )?.fk_subject ?? "-"
                    }}
                  </td>
                </template>
                </tr> -->
              <!-- v-html="getSchdual(item.schedule, index+1)" -->
              <!-- <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 1)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 2)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 3)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 4)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 6)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 7)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 8)"
                ></td> -->
              <!-- </tr> -->
            </tbody>
          </table>

          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updateAuthenticated",
        localStorage.getItem("token")
      );
      await this.getBrenches({});
      await this.getBranchClasses();
      await this.getSchedule(true);
      // await this.getTeachers();
      await this.getClassAssignHall();
      await this.getDivisions({});
      await this.getDaysChoices();

      // await this.getPeriod();
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // days: ["السبت", "الاحد", "الاثنين", "الثلاثاء", "الاربعاء", "الخميس"],
      // quotas: [
      //   "الحصة الاولى",
      //   "الحصة الثانية",
      //   "الحصة الثالثة",
      //   "الحصة الرابعة",
      //   "الحصة الخامسة",
      //   "الحصة السادسة",
      //   "الحصة السابعه",
      // ],
      // teachers: [],
      // day_count: 7,
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // committees: [],
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: [
        "day_name",
        "first",
        "secound",
        "third",
        "fourth",
        "fivth",
        "sexth",
        "sevnth",
      ],
      // headers: [
      //   { title: "اليوم", key: "day_name" },
      //   { title: "الحصة الاولى", key: "first" },
      //   { title: "الحصة الثانية", key: "secound" },
      //   { title: "الحصة الثالثة", key: "third" },
      //   { title: "الحصة الرابعة", key: "fourth" },
      //   { title: "الحصة الخامسة", key: "fivth" },
      //   { title: "الحصة السادسة", key: "sexth" },
      //   { title: "الحصة السابعة", key: "sevnth" },
      // ],
      // perPage: 25,
      // length: 0,
      // totalItems: 0,
      // itemsPerPage: [
      //   { value: 5, text: "5" },
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      // page: 1,
      // pagination: {},
      // quat_data: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      class_assign_hall: (state) => state.school.class_assign_hall,
      divisions: (state) => state.school.divisions,
      // classes: (state) => state.school.classes,
      schedules: (state) => state.school.schedules,
      days_choices: (state) => state.school.days_choices,
      classes_by_branch: (state) => state.school.classes_by_branch,
      branch: (state) => state.school.branch,
      // period: (state) => state.globals.period,
      // user: (state) => state.User,
    }),
    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    timetable_schedules() {
      if (this.schedules) {
        return this.schedules.filter(
          (el) =>
            el.add_to_table == true &&
            el.period == this.filter_data.period_am?.id
        );
      }
    },
    class_divisions() {
      if (this.filter_data.fk_class?.id) {
        return this.getClassDivisions(
          this.filter_data.fk_class?.id,
          this.getUserRole < 2
        );
      }
    },

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getClassAssignHall: "school/getClassAssignHall",
      getDivisions: "school/getDivisions",
      getPeriod: "globals/getPeriod",
      getDaysChoices: "school/getDaysChoices",
      getSchedule: "school/getSchedule",
      getBrenches: "school/getBrenches",
    }),

    // async getTeachers() {
    //   await this.axios
    //     .get(`${this.base_url}api/teachers/data/`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       this.teachers = response.data;
    //     });
    // },
    // addLineBreak(text) {
    //   var formattedText = text.replace(/@/g, "<br>");
    //   return formattedText;
    // },
    findData(day_id, quat_id) {
      // console.log(day_id);
      // console.log(this.items.find((data) => data?.day_id == day_id));
      let quat_data = this.items
        ?.find((data) => data?.day_id == day_id)
        ?.schedule?.find((quat) => quat?.quotas_id == quat_id);
      return quat_data;
    },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // getSchdual(subjects, id) {
    //   console.log(id);
    //   for (let key in subjects) {
    //     if (id == subjects[key].quotas_id) {
    //       return `${subjects[key].fk_subject ? subjects[key].fk_subject : ""}`;
    //     } else {
    //       continue;
    //     }
    //   }
    // },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/weekly_schedule_report`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: page,
            page_size: this.perPage,
            class_number: this.filter_data.fk_class?.id,
            division: this.filter_data.fk_division?.id,
            period: this.filter_data.period_am?.id,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          if (response.data) {
            this.items = response.data?.results ?? response.data;
            this.exist = true;
            // this.totalItems = response.data.pagination.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       fk_class: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       division: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
