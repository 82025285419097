<template>
  <VCard class="justify-center">
    <v-expand-transition>
      <div v-show="show">
        <VCardText
          class="card-fields"
          v-if="CheckGetPermission('school.change_month')"
        >
          <VForm ref="form">
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                <VCardItem v-if="date_type == 'h'" class="pa-0">
                  <VTextField
                    v-model="month.name_h"
                    prepend-inner-icon="mdi-calendar-edit"
                    :label="$t('school.month.name')"
                    :placeholder="$t('school.month.name_placeholder')"
                    density="compact"
                    readonly
                    :rules="[$required, $max_length(10)]"
                    hide-details="auto"
                  >
                  </VTextField>
                </VCardItem>
                <VCardItem v-else class="pa-0">
                  <VTextField
                    v-model="month.name_m"
                    prepend-inner-icon="mdi-calendar-edit"
                    :label="$t('school.month.name')"
                    :placeholder="$t('school.month.name_placeholder')"
                    density="compact"
                    readonly
                    :rules="[$required, $max_length(10)]"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
                <VCardItem class="pa-0">
                  <VCheckbox
                    v-model="month.is_current"
                    :label="$t('school.month.cureent_month')"
                    color="blue"
                    density="compact"
                    @change="checkIfOnlyOneMain"
                  >
                  </VCheckbox>
                </VCardItem>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
        <VCardActions
          v-if="is_update && CheckGetPermission('school.change_month')"
          class="px-8 d-flex flex-wrap"
          elevation="4"
        >
          <VBtn
            v-if="is_update && CheckGetPermission('school.change_month')"
            class="bg-success"
            @click="updateMonth"
            size="small"
            :loading="is_loading"
          >
            <!-- :disabled="!month.is_current" -->
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm()">
            {{ $t("global.cancel") }}
            <VIcon icon="mdi-cancel" color="red" end></VIcon>
          </VBtn>
        </VCardActions>
      </div>
    </v-expand-transition>
    <!-- ##########    data Table  ########### -->
    <!-- ######## Header Of Data Table  ####### -->
    <v-divider
      v-if="is_update && CheckGetPermission('school.change_month')"
      style="border-width: -1px; opacity: 0.25"
      class="my-1"
    />
    <v-card class="pa-2">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="text_search"
            @input="!text_search ? (search = null) : ''"
            @keyup.enter="search = text_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span v-if="key == 'is_current'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else> {{ val }}</span>
              <span
                v-if="
                  key === 'actions' && CheckGetPermission('school.change_month')
                "
              >
                <span class="me-1" style="color: #e5b254; border-radius: 4px">
                  <v-icon
                    @click="(is_update = null), editMonth(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
            <pagination
              v-model:page="page"
              v-model:perPage="perPage"
              :length="tableList.length"
              :newItem="[perPage]"
            ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      month: {},
      is_update: false,
      is_loading: false,
      show: false,
      process_alert: undefined,
      perPage: 25,
      page: 1,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: this.$t("global.data_table.all") },
      ],
      text_search: undefined,
      search: undefined,
      headers: [
        { title: this.$t("school.month.name_h"), key: "name_h" },
        { title: this.$t("school.month.name_m"), key: "name_m" },
        { title: this.$t("school.month.cureent_month"), key: "is_current" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: ["name_m", "name_h", "is_current", "actions"],
      txt_search: undefined,
      date_type: localStorage.getItem("date"),
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getAllMonths();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      months: (state) => state.school.months,
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    tableList() {
      let list = [];
      this.months.forEach((element) => {
        list.push({
          name_m: element.name_m,
          name_h: element.name_h,
          is_current: element.is_current,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAllMonths: "school/getAllMonths",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.show = false;
      this.is_update = false;
      this.is_loading = false;
      this.updated_id = undefined;
    },
    editMonth(data) {
      let months = this.months.find((item) => item.id == data.id);
      if (months != undefined) {
        for (const key in months) this.month[key] = months[key];
        this.show = true;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async updateMonth() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.month.is_current) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}/months/${this.month.id}/`, this.month, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.getAllMonths();
            this.$emit("updateDate", {
              month_h: this.month.name_h,
              month_m: this.month.name_m,
            });
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.branch)
              this.$emit("errorAlert", this.$t("global.error.failure.branch"));
            else if (error.response.data.branch_semester)
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.branch_semester")
              );
            else if (error.response.data.month_not_semester)
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.month_not_semester")
              );
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
      } else
        this.$emit("errorAlert", this.$t("global.error.failure.error_in_data"));
      this.is_loading = false;
    },
    checkIfOnlyOneMain() {
      let month = this.months.find((item) => item.is_current == true);
      if (
        month &&
        this.month.id == month.id &&
        this.month.is_current == false
      ) {
        this.$emit(
          "errorAlert",
          this.$t("global.error.failure.cureent_month_error")
        );
      }
    },
  },
};
</script>
