import axios from "axios";
import i18n from '../../i18n'
import  host  from './../../main'

export default ({
    namespaced:true,
    state:{
        gender:[],
        identify_type:[],
        class_phase:[],
        class_type:[],
        subject_type:[],
        student_type:[],
        permission_type:[],
        mark_type:[],
        month:[],
        maritalStatus:[],
        estamision:[],
        period:[],
        subscribsType:[],
        day:[],
        contacts_type:[],
        activities_types:[],
        loading:true,
        drawer:undefined,
        study_mode:[],
        // host:'http://127.0.0.1:3000',
        
        // shehab:'http://127.0.0.1:5000/api/',
    },
    getters:{
        getDrawer(){
            return drawer
        }
    },
    actions:{
        change_drawer({commit},value){
            return commit('change_drawer',value)
        },
        async getPermissionType({commit}){
            let permission_type =(await axios.get(host()+'permission-type-choices/')).data
            return commit('getPermissionType',permission_type)
        },
        async getActivitiesTypes({commit}){
            let activities_types =(await axios.get(host()+'activities-types/')).data
            return commit('getActivitiesTypes',activities_types)
        },
        async getGender({commit}){
            let gender =(await axios.get(host()+'gender/')).data
            return commit('getGender',gender)
        },
        async getIdentifyType({commit}){
            let identify_type=(await axios.get(host()+'identify-type/')).data
            return commit('getIdentifyType',identify_type)
        },
        async getClassPhase({commit}){
            let class_phase=(await axios.get(host()+'class-phase/')).data
            return commit('getClassPhase',class_phase)
        },
        async getClassType({commit}){
            let class_type=(await axios.get(host()+'class-type/')).data
            return commit('getClassType',class_type)
        },
        async getSubjectType({commit}){
            let subject_type=(await axios.get(host()+'subject-type/')).data
            return commit('getSubjectType',subject_type)
        },
        async getStudentType({commit}){
            let student_type=(await axios.get(host()+'student-type/')).data
            return commit('getStudentType',student_type)
        },
        async getMarkType({commit}){
            let mark_type=(await axios.get(host()+'mark-type/')).data
            return commit('getMarkType',mark_type)
        },
        async getMonth({commit}){
            let month=(await axios.get(host()+'month/')).data
            return commit('getMonth',month)
        },
        async getMaritalStatus({commit}){
            let maritalStatus=(await axios.get(host()+'marital-status/')).data
            return commit('getMaritalStatus',maritalStatus)
        },
        async getEstamision({commit}){
            let estamision=(await axios.get(host()+'estamision/')).data
            return commit('getEstamision',estamision)
        },
        async getPeriod({commit}){
            let period=(await axios.get(host()+'period/')).data
            return commit('getPeriod',period)
        },
        async getSubscribsType({commit}){
            let subscribsType=(await axios.get(host()+'subscribe-type/')).data
            return commit('getSubscribsType',subscribsType)
        },
        async getDay({commit}){
            let day=(await axios.get(host()+'day/',{
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')},
            })).data
            return commit('getDay',day)
        },
        async getContactTypes({commit}){
            let contacts_type=(await axios.get(host()+'contact-type/')).data
            return commit('getContactTypes',contacts_type)
        },
        async getStudyMode({commit}){
            let study_mode=(await axios.get(host()+'study-mode/')).data
            return commit('getStudyMode',study_mode)
        },
        
        
    },
    mutations:{
        
        getPermissionType(state,permission_type){
            state.permission_type=permission_type
        },
        getActivitiesTypes(state,activities_types){
            state.activities_types=activities_types
        },
        getGender(state,gender){
            state.gender=gender
        },
        getIdentifyType(state,identify_type){
            state.identify_type=identify_type
        },
        getClassPhase(state,class_phase){
            state.class_phase=class_phase
        },
        getClassType(state,class_type){
            state.class_type=class_type
        },
        getSubjectType(state,subject_type){
            state.subject_type=subject_type
        },
        getStudentType(state,student_type){
            state.student_type=student_type
        },
        getMarkType(state,mark_type){
            state.mark_type=mark_type
        },
        getMonth(state,month){
            state.month=month
        },
        getMaritalStatus(state,maritalStatus){
            state.maritalStatus=maritalStatus
        },
        getEstamision(state,estamision){
            state.estamision=estamision
        },
        getPeriod(state,period){
            state.period=period
        },
        getSubscribsType(state,subscribsType){
            state.subscribsType=subscribsType
        },
        getDay(state,day){
            state.day=day
        },
        getContactTypes(state,contacts_type){
            state.contacts_type=contacts_type
        },
        getStudyMode(state,study_mode){
            state.study_mode=study_mode
        },
        change_drawer(state,value){
            state.drawer=value
        },
    }
})