<template>
  <VCard class="justify-center">
    <VCardText class="card-fields">
      <VForm
        class="container"
        ref="form"
        v-if="
          CheckGetPermission('school.add_building') ||
          (is_update && CheckGetPermission('school.change_building'))
        "
      >
        <VRow>
          <VCol cols="12" md="8" v-if="getUserRole < 2">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="branches?.length ? branches : []"
                v-model="building.fk_branch"
                prepend-inner-icon="mdi-store"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.buldings_data.branch')"
                persistent-hint
                density="compact"
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <div class="w-100"></div>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                v-model="building.name"
                prepend-inner-icon="mdi-office-building-outline"
                :label="$t('school.buldings_data.building_name')"
                :placeholder="
                  $t('school.buldings_data.placeholder_building_name')
                "
                density="compact"
                :rules="[$required, $max_length(100)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                v-model="building.place"
                prepend-inner-icon="mdi-office-building-marker"
                :label="$t('school.buldings_data.building_place')"
                :placeholder="
                  $t('school.buldings_data.placeholder_building_place')
                "
                density="compact"
                :rules="[$required, $max_length(100)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                v-model="building.floors_number"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.buldings_data.building_floor_number')"
                :placeholder="
                  $t('school.buldings_data.placeholder_building_floor_number')
                "
                density="compact"
                type="number"
                :rules="[$required, $max_value(100), $min_value(1), $numeric]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                v-model="building.rooms_number"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.buldings_data.building_rooms_number')"
                :placeholder="
                  $t('school.buldings_data.placeholder_building_rooms_number')
                "
                density="compact"
                type="number"
                :rules="[$required, $max_value(300), $min_value(3), $numeric]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                v-model="building.ws_number"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.buldings_data.building_ws_number')"
                :placeholder="
                  $t('school.buldings_data.placeholder_building_ws_number')
                "
                density="compact"
                type="number"
                :rules="[$required, $max_value(50), $min_value(1), $numeric]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                v-model="building.caftira"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.buldings_data.building_caftira')"
                :placeholder="
                  $t('school.buldings_data.placeholder_building_caftira')
                "
                density="compact"
                type="number"
                :rules="[$required, $max_value(20), $min_value(1), $numeric]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="pa-0 ma-0" md="8">
            <VCardItem class="px-3">
              <VTextarea
                v-model="building.note"
                clearable
                :label="$t('school.buldings_data.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
                prepend-inner-icon="mdi-note-outline"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VForm>
    </VCardText>
    <VCardActions
      class="px-8"
      v-if="
        CheckGetPermission('school.add_building') ||
        CheckGetPermission('school.change_building')
      "
    >
      <VBtn
        v-if="!is_update && CheckGetPermission('school.add_building')"
        class="bg-indigo"
        @click="saveBuilding"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && CheckGetPermission('school.change_building')"
        class="bg-success"
        @click="updateBuilding"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
          :color="!is_update ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ##########    data Table  ########### -->
    <v-divider class="my-4" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div class="d-flex justify-space-between">
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <!-- size="small" -->
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>

        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="text_search"
            @input="!text_search ? (search = null) : ''"
            @keyup.enter="search = text_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            variant="text"
            density="compact"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :headers="selectedHeaders"
        :items="tableList"
        :items-per-page="perPage"
        item-value="name"
        item-key="id"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length - 1" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length - 1" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_building')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editBuilding(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_building')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="
                      (building_del_id = item.raw.id), (del_dialog = true)
                    "
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (building_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteBuilding">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      building: {
        fk_branch: undefined,
        name: undefined,
        place: undefined,
        floors_number: undefined,
        rooms_number: undefined,
        ws_number: undefined,
        caftira: undefined,
        note: undefined,
        id: undefined,
      },
      perPage: 10,
      page: 1,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: this.$t("global.data_table.all") },
      ],
      is_update: false,
      is_loading: false,
      del_dialog: false,
      building_del_id: undefined,
      headers: [
        { title: this.$t("school.buldings_data.branch"), key: "fk_branch" },
        { title: this.$t("school.buldings_data.building_name"), key: "name" },
        { title: this.$t("school.buldings_data.building_place"), key: "place" },
        {
          title: this.$t("school.buldings_data.building_floor_number"),
          key: "floors_number",
        },
        {
          title: this.$t("school.buldings_data.building_rooms_number"),
          key: "rooms_number",
        },
        {
          title: this.$t("school.buldings_data.building_ws_number"),
          key: "ws_number",
        },
        {
          title: this.$t("school.buldings_data.building_caftira"),
          key: "caftira",
        },
        { title: this.$t("school.buldings_data.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "fk_branch",
        "name",
        "place",
        "floors_number",
        "rooms_number",
        "caftira",
        "actions",
      ],
      text_search: undefined,
      search: undefined,
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // if (!this.branches.length)
      await this.getBrenches({});
      await this.getBuilding({ all: true });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      branches: (state) => state.school.branch,
      building_list: (state) => state.school.building,
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      if (this.branches?.length && this.branches?.length > 0)
        this.building_list.forEach((element) => {
          let fk_branch = this.branches.find(
            (item) => item.id == element.fk_branch
          );
          if (fk_branch != undefined) fk_branch = fk_branch.name_ar;
          list.push({
            fk_branch: fk_branch,
            name: element.name,
            place: element.place,
            floors_number: element.floors_number,
            rooms_number: element.rooms_number,
            ws_number: element.ws_number,
            caftira: element.caftira,
            note: element.note,
            id: element.id,
          });
        });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
      getBuilding: "school/getBuilding",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
    },
    editBuilding(data) {
      let building = this.building_list.find((item) => item.id == data.id);
      if (building != undefined) {
        for (const key in building) this.building[key] = building[key];
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveBuilding() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(this.base_url + "/building/", this.building, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.resetForm();
            this.getBuilding({ all: true });
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async updateBuilding() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .put(
            `${this.base_url}/building/${this.building.id}/`,
            this.building,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            this.getBuilding({ all: true });
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async deleteBuilding() {
      if (this.building_del_id != undefined) {
        await this.axios
          .delete(`${this.base_url}/building/${this.building_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getBuilding({ all: true });
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.building_del_id = undefined;
      }
    },
  },
};
</script>
