<template>
  <v-card class="pa-2" v-if="!exist && getUserRole < 2">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <!-- <v-col cols="6" :md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="filter_data.fk_division = null"
          />
        </v-col> -->
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>

  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header
      :rep_en="getUserRole > 1"
      :data="filter_data"
    ></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        :back_button="getUserRole < 2"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <template v-for="(item, index) in items" :key="item.id">
                <tr v-for="(sub, subIndex) in item?.info" :key="sub">
                  <template v-if="subIndex === 0">
                    <td
                      v-if="selectedHead.includes('no')"
                      :rowspan="item?.info?.length"
                      class="text-center border"
                    >
                      {{ (page - 1) * perPage + index + 1 }}
                    </td>
                    <td
                      v-if="selectedHead.includes('committee_name')"
                      :rowspan="item?.info?.length"
                      class="text-center border"
                    >
                      {{ item.committee_name }}
                    </td>
                  </template>

                  <td
                    v-for="header in selectedHeaders?.filter(
                      (head) => head.key != 'no' && head.key != 'committee_name'
                    )"
                    :key="header.key"
                    class="text-center border"
                  >
                    {{ sub[header.key] != null ? sub[header.key] : "-" }}
                  </td>
                </tr>
                <tr></tr>
              </template>
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                 {{ (page - 1) * perPage + key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('committee_name')"
                >
                  {{ item.committee_name }}
                </td>

                <td
                  class="text-center border"
                  v-if="selectedHead.includes('class_name')"
                >
                  <span
                    v-for="info in item.info"
                    :key="info.id"
                    class="d-flex flex-column mx-2"
                  >
                    <span>
                      {{ info.class_name }}
                    </span>
                  </span>
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_number')"
                >
                  <span
                    v-for="info in item.info"
                    :key="info.id"
                    class="d-flex flex-column mx-2"
                  >
                    <span>
                      {{ info.student_no_class }}
                    </span>
                  </span>
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        this.getData();
      }
      // await this.getYears();
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // school: [],
      // current_year: "",
      // year_data: localStorage.getItem("current_year_name_h"),
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      filter_data: {},
      txt_search: "",
      selectedHead: ["no", "committee_name", "class_name", "student_no_class"],
      headers: [
        { title: "#", key: "no" },
        {
          title: this.$t("school.reports.committee_name"),
          key: "committee_name",
        },
        { title: this.$t("school.reports.class"), key: "class_name" },
        {
          title: this.$t("school.reports.student_number"),
          key: "student_no_class",
        },
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
      // user: (state) => state.User,
    }),

    ...mapGetters({
      // getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
    }),
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/commission_distr_plan`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // search: this.txt_search,
            page: page,
            page_size: this.perPage,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data?.results;
            this.totalItems = response.data?.pagination?.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
