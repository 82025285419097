import axios from "axios";

export default ({
    namespaced:true,
    state:{
        groups:[],  
    },
    getters:{
        getGroup(state,subjects){
            state.subjects=subjects
        },
    },
    actions:{
        async getGroup({state,commit}){
            let subjects=(await axios.get(host()+'api/groups/',
            {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})).data
            return commit('getGroup',groups)
        },
    },
    mutations:{
    
    }

})