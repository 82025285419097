<template>
  <v-card class="justify-center">
    <v-expand-transition>
      <div v-show="!(update && $role >= 2)">
        <v-card-text
          class="card-fields"
          v-if="
            CheckGetPermission('school.add_division') ||
            (update && CheckGetPermission('school.change_division'))
          "
        >
          <v-form ref="form" @submit.prevent="saveDivision">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  color="indigo"
                  v-model="division.name"
                  clearable
                  counter="30"
                  density="compact"
                  prepend-inner-icon="mdi-home-outline"
                  :label="$t('school.division.name')"
                  :rules="[$required, $max_length(30), uniqueDivision]"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  color="indigo"
                  v-model="division.symbol"
                  clearable
                  counter="10"
                  density="compact"
                  prepend-inner-icon="mdi-shape-outline"
                  :label="$t('school.division.symbol')"
                  :rules="[$required, $max_length(10), uniqueSymbol]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <v-textarea
                  color="indigo"
                  v-model="division.note"
                  hide-details="auto"
                  density="compact"
                  counter="250"
                  clearable
                  rows="3"
                  prepend-inner-icon="mdi-note-outline"
                  :label="$t('global.note')"
                  :rules="[$max_length(250)]"
                />
              </v-col>
            </v-row>
          </v-form>

          <!-- </v-card>
        </v-col>
      </v-row> -->
        </v-card-text>
        <v-card-actions
          class="px-8"
          v-if="
            CheckGetPermission('school.add_division') ||
            (update && CheckGetPermission('school.change_division'))
          "
        >
          <v-btn
            v-if="!update && CheckGetPermission('school.add_division')"
            @click="saveDivision"
            class="bg-indigo"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <v-btn
            v-if="
              update &&
              checkRole(['programmer', 'big_admin']) &&
              CheckGetPermission('school.change_division')
            "
            @click="updatedivision(selectedDivision)"
            class="bg-success"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <VBtn class="mx-3" size="small" @click="resetForm">
            {{ !update ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
              :color="!update ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
          <!-- <v-btn @click="resetForm" class="mx-3" size="small">
            <span>
              {{ $t("global.clear") }}
            </span>
            <v-icon icon="mdi-broom" color="golden" end></v-icon>
          </v-btn> -->
        </v-card-actions>

        <v-divider class="mt-5"></v-divider>
      </div>
    </v-expand-transition>

    <!-- ##########    data Table Server ########### -->

    <v-card class="pa-2" v-if="CheckGetPermission('school.view_division')">
      <!-- ######## Header Of Data Table Server ####### -->
      <div >
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="text_search"
            @input="!text_search ? (search = null) : ''"
            @keyup.enter="search = text_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :headers="selectedHeaders"
        :items="divisions"
        :items-per-page="perPage"
        item-value="symbol"
        item-key="id"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>

              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="selectDivision(item.raw)"
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('school.change_division')
                  "
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  @click="(selectedDivision = item.raw.id), (dialog = true)"
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('school.delete_division')
                  "
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="divisions.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(divisions.length / perPage)"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ divisions.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(dialog = false), (selectedDivision = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteClass(selectedDivision)">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Adddivision",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // this.getDivisions();
      await this.getDivision();
      if (this.getUserRole >= 2) {
        this.headers.pop();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      name: "",
      symbol: "",
      text_search: null,
      search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      divisions: [],
      perPage: 10,
      page: 1,
      is_loading: false,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: this.$t("global.data_table.all") },
      ],
      selectedHead: ["name", "symbol", "actions"],
      selectedDivision: null,
      division: {},
      uniqueDivision: (value) => {
        if (
          !this.update ||
          this.name != this.division.name.trim().toLowerCase()
        )
          return !this.divisionNames.includes(value.trim().toLowerCase())
            ? true
            : this.$t("global.error.validation.unique");
        return true;
      },
      uniqueSymbol: (value) => {
        const list = this.symbols.map((item) => item.toLowerCase());
        if (
          !this.update ||
          this.symbol != this.division.symbol.trim().toLowerCase()
        )
          return !list.includes(value.trim().toLowerCase())
            ? true
            : this.$t("global.error.validation.unique");
        return true;
      },
      headers: [
        { title: this.$t("school.division.name"), key: "name" },
        { title: this.$t("school.division.symbol"), key: "symbol" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    divisionNames() {
      return this.divisions.map((item) => item.name);
    },
    symbols() {
      return this.divisions.map((item) => item.symbol);
    },
    // ...mapState({
    //   divisions: (state) => state.school.divisions,
    // }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
    }),

    async getDivision() {
      await this.axios
        .get(`${this.base_url}api/divisions/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.divisions = response.data;
        });
    },

    selectDivision(item) {
      this.selectedDivision = item.id;
      this.name = item.name.trim();
      this.symbol = item.symbol.trim().toLowerCase();
      delete item.id;
      this.division = Object.assign({}, item);
      this.update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveDivision() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}api/divisions/`, this.division, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.save"));
            this.resetForm();
            this.getDivisions({});
            this.getDivision();
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    resetForm() {
      this.update = false;
      this.$refs.form.reset();
    },
    async updatedivision(id) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}api/divisions/${id}/`, this.division, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            this.getDivisions({});
            this.getDivision();
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    deleteClass(id) {
      this.axios
        .delete(`${this.base_url}api/divisions/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then(() => {
          this.$emit("successAlert", this.$t("global.success.data_deleted"));
          this.getDivisions({});
          this.getDivision();
        })

        .catch((error) => {
          if (error.response?.status == 418)
            this.$emit("protectedAlert", error.response?.data?.data);
          else
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
        });
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
