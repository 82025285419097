// import { createStore } from "vuex";
import axios from "axios";

export default ({
    namespaced:true,
    state:{
        banks:[],
        boxes:[],
        student_accounts:[],
        // analytic:[],
        accounts:[],
        analytic:[],
        noAnalyst:true,
        analystList:[],
        currency:[],
        doctype:[],
        accounts_by_analyst:[],

        // Alhasani 
        
    },
    getters:{

    },
    actions:{
        setAccounts({commit}){
            return commit('setReceiptAccounts')
        },
        selectAnalyst({commit},analyticId){
            return commit('filterAccountsByAnalyticId',analyticId)
        },
        getCurrencies({commit}){
            return commit('getCurrencies')
        },
        getBoxAnalyst({commit}){
            return commit('getBoxAnalyst')
        },
        getStudentAnalyst({commit}){
            return commit('getStudentAnalyst')
        },
        getBankAnalyst({commit}){
            return commit('getBankAnalyst')
        },
        getAccounts({commit},all='-1'){
            return commit('getAccounts',all)
        },
        getDocType({commit},type){
            return commit('getDocType',type)
        },
        getAccountByAnalust({commit},analyst){
            return commit('getAccountByAnalust',analyst)
        }

        // Alhasani
        
    },
    mutations:{
        async setReceiptAccounts(state){
            state.banks= (await axios.get('http://127.0.0.1:3000/bank/')).data
            state.boxes=(await axios.get('http://127.0.0.1:3000/box/')).data
            state.currency=(await axios.get('http://127.0.0.1:3000/currency/')).data
            state.accounts=(await axios.get('http://127.0.0.1:3000/accounts/')).data
            
        },
        async getCurrencies(state){            
            state.currency=(await axios.get('http://127.0.0.1:3000/currency/')).data 
        },
        async getBoxAnalyst(state){            
            state.boxes=(await axios.get('http://127.0.0.1:3000/box/')).data 
        },
        async getStudentAnalyst(state){            
            state.student_accounts=(await axios.get('http://127.0.0.1:3000/student-account/')).data 
        },
        async getBankAnalyst(state){            
            state.banks=(await axios.get('http://127.0.0.1:3000/bank/')).data 
        },
        async getAccounts(state,all=-1){
            state.accounts=(await axios.get(`http://127.0.0.1:3000/accounts/?all=${all}`)).data
        },
        
        async getDocType(state,type=-1){
            state.doctype=(await axios.get(`http://127.0.0.1:3000/doctype/?type=${type}`)).data
        },
        async filterAccountsByAnalyticId(state,analyticId){
            state.analytic=(await axios.get(`http://127.0.0.1:3000/getanalytic/?account_id=${analyticId}`)).data
            if(state.analytic=="")
            state.noAnalyst=true
            else
            state.noAnalyst=false
        },
        async getAccountByAnalust(state,analyst){
            state.accounts_by_analyst=(await axios.get(`http://127.0.0.1:3000/accounts-by-analyst/?analyst=${analyst}`)).data
        },

        // Alhasani
        async getAnalystAccount(state){
            state.accounts=(await axios.get('http://127.0.0.1:3000/analystaccount/')).data
        },
        
    }

})