<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="(filter_data.fk_teacher = null), getTeachers()"
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_teacher"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-account-tie"
            :items="teachers"
            :label="$t('school.reports.select_teacher')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.period_am"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-timeline-clock-outline"
            hide-details="auto"
            :items="[
              { id: 1, name: $t('school.weekly_schedule.am') },
              { id: 0, name: $t('school.weekly_schedule.pm') },
            ]"
            :label="$t('school.reports.select_period')"
            :rules="[$required]"
            return-object
          ></v-autocomplete>
          <!-- @update:modelValue="createTable" -->
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu @toggle-visibility="() => (exist = !exist)"></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <diagonal-header
                :leftLabel="$t('school.reports.lecture')"
                :rightLabel="$t('school.reports.day')"
              ></diagonal-header>
              <th v-for="(item, index) in timetable_schedules" :key="index">
                {{ item.quotas }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, index) in days_choices" :key="index">
                <td class="text-center border">
                  {{ item.name }}
                </td>
                <!-- v-for="quat in item.schedule.sort(
                    (a, b) => a.quotas_id - b.quotas_id
                  )" -->
                <template v-for="quat in timetable_schedules" :key="quat?.id">
                  <td class="text-center border">
                    <div class="d-flex flex-column" style="gap: 0.3rem">
                      <div>
                        <h3>
                          {{
                            findData(item?.id, quat?.id)?.subject_name ?? "-"
                          }}
                        </h3>
                      </div>
                      <div
                        v-if="quat_data"
                        class="d-flex justify-center"
                        style="gap: 0.2rem"
                      >
                        <!-- <h5>{{ findData(item?.id, quat?.id)?.class_name }}</h5> -->
                        <h5>{{ quat_data?.class_name }}</h5>
                        <span class="align-self-center">-</span>
                        <h5>{{ quat_data?.division_name }}</h5>
                      </div>
                    </div>
                    <!-- <br />
                    <div class="d-flex justify-space-between pt-3">
                      <h6>{{ quat_data?.class_name }}</h6>
                      <h6>{{ quat_data?.division_name }}</h6>
                    </div> -->
                  </td>
                </template>
              </tr>
              <!-- <tr v-for="(item, index) in items" :key="index">
                <td class="text-center border">
                  {{ item.day_name }}
                </td>
                <td
                  v-for="quat in item.schedule.sort(
                    (a, b) => a.quotas_id - b.quotas_id
                  )"
                  :key="quat"
                  class="text-center border"
                >
                  {{ quat?.fk_subject ?? "-" }}
                </td> -->
              <!-- <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 1)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 2)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 3)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 4)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 5)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 6)"
                ></td>
                <td
                  class="text-center border"
                  v-html="getSchdual(item.schedule, 7)"
                ></td> -->
              <!-- </tr> -->
            </tbody>
          </table>
          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination> -->
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-autocomplete
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-autocomplete>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updateAuthenticated",
        localStorage.getItem("token")
      );
      // await this.getClasses();
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        await this.getTeachers();
      }
      await this.getDaysChoices();
      await this.getSchedule(true);
      // this.getYears();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // current_year: "",
      // days: ["السبت", "الاحد", "الاثنين", "الثلاثاء", "الاربعاء", "الخميس"],
      // quotas: [
      //   "الحصة الاولى",
      //   "الحصة الثانية",
      //   "الحصة الثالثة",
      //   "الحصة الرابعة",
      //   "الحصة الخامسة",
      //   "الحصة السادسة",
      //   "الحصة السابعه",
      // ],
      teachers: [],
      // day_count: 7,
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // committees: [],
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      quat_data: {},
      // txt_search: "",
      // selectedHead: [
      //   "day_name",
      //   "first",
      //   "secound",
      //   "third",
      //   "fourth",
      //   "fivth",
      //   "sexth",
      //   "sevnth",
      // ],
      // headers: [
      //   { title: "اليوم", key: "day_name" },
      //   { title: "الحصة الاولى", key: "first" },
      //   { title: "الحصة الثانية", key: "secound" },
      //   { title: "الحصة الثالثة", key: "third" },
      //   { title: "الحصة الرابعة", key: "fourth" },
      //   { title: "الحصة الخامسة", key: "fivth" },
      //   { title: "الحصة السادسة", key: "sexth" },
      //   { title: "الحصة السابعة", key: "sevnth" },
      // ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 5, text: "5" },
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        teacher: null,
      },
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      class_assign_hall: (state) => state.school.class_assign_hall,
      days_choices: (state) => state.school.days_choices,
      schedules: (state) => state.school.schedules,
      branch: (state) => state.school.branch,
      // user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    timetable_schedules() {
      if (this.schedules) {
        return this.schedules.filter(
          (el) =>
            el.add_to_table == true &&
            el.period == this.filter_data.period_am?.id
        );
      }
    },

    getUserRole() {
      return localStorage.getItem("role");
    },

    // selectedHeaders() {
    //   return this.headers.filter((header) =>
    //     this.selectedHead.includes(header.key)
    //   );
    // },
  },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getClassAssignHall: "school/getClassAssignHall",
      getDaysChoices: "school/getDaysChoices",
      getSchedule: "school/getSchedule",
      getBrenches: "school/getBrenches",
    }),
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    findData(day_id, quat_id) {
      this.quat_data = this.items?.find(
        (data) => data?.day_id == day_id && data?.schedule_id == quat_id
      );
      return this.quat_data;
    },
    async getTeachers() {
      await this.axios
        .get(`${this.base_url}api/teachers/data/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_branch: this.filter_data?.fk_branch?.id,
          },
        })
        .then((response) => {
          this.teachers = response.data;
        });
    },
    // addLineBreak(text) {
    //   var formattedText = text.replace(/@/g, "<br>");
    //   return formattedText;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // getSchdual(subjects, id) {
    //   for (let key in subjects) {
    //     if (id == subjects[key].quotas_id) {
    //       if (subjects[key].fk_subject) {
    //         return `${subjects[key].fk_subject ?? ""} <br> ${
    //           subjects[key].fk_division ?? ""
    //         } <br> ${subjects[key].fk_class ?? ""} `;
    //       }
    //     } else {
    //       continue;
    //     }
    //   }
    // },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/weekly_schedule_teacher_report`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: page,
            page_size: this.perPage,
            teacher: this.filter_data.fk_teacher?.id,
            period: this.filter_data.period_am?.id,
            fk_branch: this.filter_data?.fk_branch?.id,
          },
        })
        .then((response) => {
          if (response.data.results) {
            this.items = response.data?.results ?? response.data;
            this.exist = true;
            // this.totalItems = response.data.pagination.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       teacher: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
