<template>
  <VCard class="card-table">
    <!-- ######## Header Of Data Table  ####### -->
      <div >
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-text-field
          color="indigo"
          v-model="text_search"
          @keyup.enter="text_search ? (search = text_search) : ''"
          @input="!text_search ? (search = text_search) : ''"
          class="search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          :placeholder="$t('global.data_table.search_here')"
          density="compact"
          variant="text"
        >
        </v-text-field>
        <div class="d-flex justify-end" style="flex: 1">
          <VBtn
            v-if="CheckGetPermission('bus_system.add_driver')"
            class="bg-success"
            @click="addDriver"
          >
            <span class="text-white">{{ $t("global.create") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <!-- <v-progress-linear
        color="indigo "
        class="mb-1"
        :indeterminate="loading"
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear> -->
      <v-data-table
        :items-per-page="perPage"
        v-model:sort-by="sortBy"
        @update:options="getDriver((page = 1))"
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('bus_system.change_driver')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editDriver(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('bus_system.delete_driver')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="(driver_del_id = item.raw.id), (del_dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ totalItems }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (driver_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteDriver">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      del_dialog: false,
      driver_del_id: undefined,
      perPage: 10,
      length: 0,
      drivers: [],
      page: 1,
      pagination: {},
      totalItems: null,
      loading: false,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: this.$t("global.data_table.all") },
      ],
      headers: [
        {
          title: this.$t("school.driver_data.driver_name"),
          key: "driver_name",
        },
        {
          title: this.$t("school.driver_data.driver_phone"),
          key: "phone_number",
        },
        { title: this.$t("school.driver_data.driver_age"), key: "age" },
        {
          title: this.$t("school.driver_data.driver_martial_status"),
          key: "martial_status",
        },
        { title: this.$t("school.driver_data.driver_email"), key: "email" },
        {
          title: this.$t("school.driver_data.card_type"),
          key: "identity_type",
        },
        {
          title: this.$t("school.driver_data.card_no"),
          key: "identity_number",
        },
        {
          title: this.$t("school.driver_data.driver_home_no"),
          key: "home_number",
        },
        {
          title: this.$t("school.driver_data.card_issuance"),
          key: "identity_issuance",
        },
        {
          title: this.$t("school.driver_data.card_issuer"),
          key: "identity_issuer",
        },
        {
          title: this.$t("school.driver_data.card_expiration"),
          key: "identity_expiration",
        },
        { title: this.$t("school.driver_data.address"), key: "address" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "driver_name",
        "identity_type",
        "identity_number",
        "phone_number",
        "actions",
      ],
      text_search: undefined,
      search: null,
      sortBy: [+{ key: "name", order: "asc" }],
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.identify_type.length == 0) await this.getIdentifyType();
      if (this.maritalStatus.length == 0) await this.getMaritalStatus();
      await this.getDriver();
      this.page = await this.current_page;
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      identify_type: (state) => state.globals.identify_type,
      maritalStatus: (state) => state.globals.maritalStatus,
      items_count: (state) => state.school.items_count,
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      this.drivers.forEach((element) => {
        let idintity = this.identify_type.find(
          (item) => item.id == element.identity_type
        );
        if (idintity != undefined) idintity = idintity.name;

        let martial = this.maritalStatus.find(
          (item) => item.id == element.martial_status
        );
        if (martial != undefined) martial = martial.name;
        list.push({
          driver_name: element.driver_name,
          address: element.address,
          phone_number: element.phone_number,
          home_number: element.home_number,
          email: element.email,
          age: element.age,
          martial_status: martial,
          identity_type: idintity,
          identity_number: element.identity_number,
          identity_issuance: element.identity_issuance,
          identity_issuer: element.identity_issuer,
          identity_expiration: element.identity_expiration,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getIdentifyType: "globals/getIdentifyType",
      getMaritalStatus: "globals/getMaritalStatus",
      changeCurrentPage: "school/changeCurrentPage",
    }),
    addDriver() {
      this.$router.push("add");
    },
    editDriver(id) {
      this.changeCurrentPage({ page: this.page, count: this.perPage });
      this.$router.push(`${id}/edit/`);
    },
    async deleteDriver() {
      if (this.driver_del_id != undefined) {
        await this.axios
          .delete(`${this.base_url}/driver/${this.driver_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.getDriver();
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.driver_del_id = undefined;
      }
    },
    async getDriver(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}driver/`, {
        params: {
          search: this.text_search,
          sort_by: this.order_data,
          page: page,
          page_size: this.perPage,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.drivers = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.loading = false;
          this.itemsPerPage[5].value = response.data.pagination.count;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getDriver();
    },
    page() {
      this.getDriver(this.page);
    },
  },
};
</script>
