<template>
  <VLocaleProvider :dir="$i18n.locale === 'en' ? 'ltr' : 'rtl'">
    <VApp>
      <router-view />
    </VApp>
  </VLocaleProvider>
</template>

<script>
export default {};
</script>
<style>
* {
  font-family: "Almarai" !important;
  box-sizing: border-box !important;
}
</style>
