export default {
  "school": {
    "side_bar": {
      "system_initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة النظام"])},
      "new_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل جديد"])},
      "show_registered_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المسجلين"])},
      "add_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة ولي أمر"])},
      "view_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض اولياء الامور"])},
      "school_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدرسة"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسة"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع"])},
      "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة المواد "])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعات"])},
      "days_gaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الايام"])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اوقات الحصص"])},
      "student_affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة شئون الطلاب"])},
      "registration_addmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل والقبول"])},
      "school_calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التقويم الدراسي"])},
      "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفوف"])},
      "divisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب"])},
      "general_initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التهيئة العامة"])},
      "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انظمة التقديرات"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الصف"])},
      "document_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة الوثائق"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
      "governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظات"])},
      "directorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديريات"])},
      "financial_affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشؤون المالية"])},
      "fee_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الرسوم"])},
      "fees_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنود الرسوم"])},
      "discount_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئات التخفيض"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المباني"])},
      "view_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض القاعات"])},
      "halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعات"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
      "students_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الطالب"])},
      "add_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة رسوم"])},
      "edit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل رسوم "])},
      "add_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع قسط"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل السنوات"])},
      "semesters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الاترام"])},
      "preparing_homeworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعداد الواجبات"])},
      "daily_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة يومية"])},
      "seat_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ارقام الجلوس"])},
      "control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكنترول"])},
      "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامتحانات"])},
      "examination_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الامتحانات  "])},
      "examination_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترات الامتحانات  "])},
      "add_exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة جدول امتحانات"])},
      "exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض جداول الإمتحانات"])},
      "title_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللجان الامتحانية"])},
      "add_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة لجنة "])},
      "committeesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات اللجان"])},
      "add_committee_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة خطة اللجان"])},
      "committee_plan_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض خطة اللجان"])},
      "distribute_students_on_committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع الطلاب على اللجان  "])},
      "secret_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  الارقام السرية"])},
      "add_seat_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" رقم الجلوس"])},
      "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ارقام الجلوس"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة"])},
      "add_new_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة نشاط جديد"])},
      "student_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشاركين"])},
      "monthly_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات الشهرية"])},
      "semester_grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات الترم"])},
      "score_recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدرجات"])},
      "add_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مدرس"])},
      "teacher_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" معلومات المدرسين"])},
      "faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هيئة التدريس"])},
      "bus_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام الباصات"])},
      "weekly_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الاسبوعي"])},
      "add_weekly_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة جدول اسبوعي"])},
      "view_weekly_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الجداول الاسبوعية"])},
      "classes_inti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة الصفوف"])},
      "add_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة قاعة"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمين"])},
      "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مستخدم"])},
      "users_adminstration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة المستخدمين"])},
      "lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدروس"])},
      "lectures_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الدروس"])},
      "lecture_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة درس"])},
      "student_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الطالب"])},
      "student_elevate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترفيع الطلاب"])},
      "student_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشتركين في الباصات"])},
      "add_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة قاعة للخطة"])},
      "assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض خطة القاعات"])},
      "show_homeworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الواجبات"])},
      "homeworks_correction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصحيح الواجبات"])},
      "homework_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة واجب"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعدادات"])},
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
      "daily_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحضير اليومي"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
      "Rest_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتبقي من الرسوم"])},
      "Student_Account_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب طالب"])},
      "Student_Installments_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب حسب الاقساط"])},
      "Students_without_Study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بدون رسوم "])},
      "Students_without_Buses_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب مشتركين في الباصات بدون رسوم"])},
      "Students_Subscribed_Buses_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب المشتركين بالباصات مع الرسوم"])},
      "Student_Statement_Fee_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف طلاب حسب بند الرسوم"])},
      "Student_Statement_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حساب حسب البند"])},
      "Detailed_Statement_study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف تفصيلي للرسوم الدراسية"])},
      "Statement_Paid_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف حسب المدفوع"])},
      "students_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الطلاب"])},
      "Students_by_Year_Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب الصف"])},
      "Students_by_Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب المحافظة"])},
      "Students_by_Nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب الجنسية"])},
      "Students_by_Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب حسب ولي الامر"])},
      "Students_Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعمار الطلاب"])},
      "Students_Users_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسماء المستخدمين (الطلاب)"])},
      "Students_Images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور الطلاب"])},
      "New_Enrolled_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المستجدين"])},
      "Walk_out_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المنسحبين"])},
      "parents_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير اولياء الامور"])},
      "parent_by_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير ولي الامر حسب الطالب"])},
      "personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات أولياء الأمور الشخصية"])},
      "parents_usernames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  تقرير اسماء المستخدمين لأولياء الامور"])},
      "parents_contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات تواصل اولياء الامور"])},
      "subscribers_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاركين بالانشطة"])},
      "Monthly_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الشهرية"])},
      "Monthly_Deficiencies_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نواقص الدرجات الشهرية"])},
      "Monthly_Marks_statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات الشهر "])},
      "Specific_Month_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات شهر معين"])},
      "Month_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة شهرية"])},
      "Month_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة شهرية انجليزية"])},
      "First_Class_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اوائل الشهر"])},
      "Students_Estimation_by_Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب بتقدير شهري "])},
      "Final_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير النهائية"])},
      "Subjects_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواد لم ترصد درجاتها"])},
      "Marks_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات لم ترصد"])},
      "Specific_Years_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة سنوية"])},
      "Specific_Years_English_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة سنوية انجليزي"])},
      "Years_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف اوائل السنة"])},
      "Year_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الدرجات السنوية"])},
      "Daily_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الحضور والغياب"])},
      "Semester_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الفصلية"])},
      "Semester_Optional_Subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواد الفصل الاختيارية"])},
      "Specific_Semester_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة ترم"])},
      "Specific_Semester_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة ترم انجليزي"])},
      "Semester_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات الترم"])},
      "Final_Exams_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات الامتحانات النهائية"])},
      "Total_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف درجات المحصلات"])},
      "Subjects_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف اوائل المادة"])},
      "Exams_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الامتحانات"])},
      "Student_Statement_Seat_Secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم السري و رقم الجلوس"])},
      "Student_Without_Seat_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلاب بدون رقم سري او جلوس"])},
      "Committees_Distribution_Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير خطة توزيع اللجان"])},
      "Secret_No_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف الأرقام السرية"])},
      "Student_Seat_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف ارقام الجلوس"])},
      "Exams_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الاختبارات"])},
      "Correction_by_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف التصحيح بالرقم السري"])},
      "Committees_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة كشوفات اللجان"])},
      "Schedule_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جداول الحصص"])},
      "Study_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الدراسي"])},
      "Study_Schedule_by_Teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الحصص حسب الأستاذ"])},
      "Teachers_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاساتذة"])},
      "Students_Subscribed_Buses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشتركين في الباصات"])},
      "Buses_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف بيانات الباصات"])},
      "System_Users_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدمين النظام"])},
      "Specific_Group_Members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعضاء مجموعة معينة"])},
      "Specific_Group_Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحيات المجموعة"])}
    },
    "school_data": {
      "coordinates_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الموقع"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ملاحظة"])},
      "school-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدرسة "])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
      "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديرية"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
      "school_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدرسة"])},
      "school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المدرسة عربي"])},
      "placeholder_school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المدرسة بالعربي"])},
      "school_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المدرسة انجليزي"])},
      "placeholder_school_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المدرسة بالانجليزي"])},
      "establish_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التأسيس"])},
      "ministry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوزارة"])},
      "placeholder_ministry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الوزارة"])},
      "education_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتب التربية"])},
      "placeholder_education_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم مكتب التربية"])},
      "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الترخيص"])},
      "placeholder_license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الترخيص"])},
      "school_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المدرسة"])},
      "civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اهلي"])},
      "governmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حكومي"])},
      "headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدير المدرسة"])},
      "placeholder_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم مدير المدرسة"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع المدرسة"])},
      "placeholder_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل مكان المدرسة"])},
      "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احداثيات المدرسة"])},
      "placeholder_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل احداثيات المدرسة"])},
      "students_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلاب"])},
      "school_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعار المدرسة"])},
      "boys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنين"])},
      "girls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنات"])},
      "main_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الاساسية"])},
      "location_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الموقع"])},
      "ministry_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الوزارة"])},
      "contact_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات التواصل"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسيلة التواصل"])},
      "contact_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع وسيلة التواصل"])},
      "license_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الترخيص"])},
      "select_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر صورة"])}
    },
    "branch_data": {
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ملاحظة"])},
      "location_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الموقع"])},
      "license_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الترخيص"])},
      "coordinates_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الموقع"])},
      "students_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلاب"])},
      "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الترخيص"])},
      "placeholder_license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الترخيص"])},
      "contact_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات التواصل"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسيلة التواصل"])},
      "contact_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع وسيلة التواصل"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
      "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديرية"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
      "main_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع الرئيسي"])},
      "main_branch_must_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون هناك فرع رئيسي"])},
      "placeholder_branch_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل احداثيات الفرع"])},
      "branch_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احداثيات الفرع"])},
      "placeholder_branch_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل موقع الفرع"])},
      "branch_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الفرع"])},
      "is_main_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع الرئيسي"])},
      "placeholder_branch_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الفرع بالانجليزي"])},
      "branch_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفرع بالانجليزي"])},
      "placeholder_branch_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الفرع بالعربي"])},
      "branch_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الفرع بالعربي"])},
      "branch_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الفرع"])},
      "branch_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الفرع"])},
      "add_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فرع"])},
      "placeholder_branch_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل اسم مدير الفرع"])},
      "branch_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدير الفرع"])},
      "main_branch_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الفرع الرئيسي"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الفرع"])}
    },
    "buldings_data": {
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ملاحظة"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفروع"])},
      "add_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مبنى"])},
      "placeholder_building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المبنى"])},
      "building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المبنى"])},
      "placeholder_building_caftira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الكافتريات"])},
      "building_caftira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الكافتريات"])},
      "placeholder_building_ws_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد دورات المياه"])},
      "building_ws_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد دورات المياه"])},
      "placeholder_building_rooms_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الغرف"])},
      "building_rooms_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الغرف"])},
      "placeholder_building_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل مكان المبنى"])},
      "building_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان المبنى"])},
      "placeholder_building_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الطوابق"])},
      "building_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطوابق"])},
      "building_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المبنى"])}
    },
    "schedule_data": {
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اوقات الحصص"])},
      "add_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة حصة"])},
      "schedule_quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصة"])},
      "placeholder_schedule_quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الحصة"])},
      "schedule_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة"])},
      "morning_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة الصباحية"])},
      "evening_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة المسائية"])},
      "schedule_start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت بداية الحصة"])},
      "schedule_end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت نهاية الحصة"])},
      "schedule_timing_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البداية لا يمكن ان يتجاوز او يساوي وقت النهاية"])},
      "schedule_timing_error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يجب ان تتداخل اوقات الفترات"])},
      "schedule_timing_error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت النهاية لايتجاوز الواحدة واالنصف بالفترة الصباحي"])},
      "schedule_timing_error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البداية لايقل عن الثامنة  بالفترة الصباحي"])}
    },
    "hall_data": {
      "hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القاعة"])},
      "edit_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل قاعة"])},
      "add_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة قاعة"])},
      "placeholder_hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم القاعة"])},
      "placeholder_hall_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الطابق"])},
      "hall_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطابق"])},
      "placeholder_hall_seats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الطاقة الاستيعابية للقاعة"])},
      "hall_seats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة الاستيعابية"])}
    },
    "assign_hall_data": {
      "hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القاعة"])},
      "add_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة قاعة للخطة"])},
      "edit_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير خطة القاعات"])},
      "hall_is_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعة موجودة من قبل"])},
      "assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة القاعات"])}
    },
    "weekly_schedule_data": {
      "add_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة جدول اسبوعي جديد"])},
      "schedule_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة"])}
    },
    "teacher_data": {
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الاستاذ عربي"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الاستاذ انجليزي"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
      "marital_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة الاجتماعية"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])},
      "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان الميلاد"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الابناء"])},
      "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوظيفة"])},
      "work_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترة العمل"])},
      "job_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الوظيفي"])},
      "date_of_employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تاريخ التوظيف"])},
      "basic_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استاذ أساسي"])},
      "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حالة الخدمة"])},
      "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوقف"])},
      "continus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستمر"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
      "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موبايل"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
      "employment_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات التوظيف"])},
      "personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات شخصية "])},
      "contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات التواصل"])},
      "user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المستخدم"])}
    },
    "assign_teacher_data": {
      "add_assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مدرس للخطة"])},
      "edit_assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير خطة المدرسين"])},
      "teacher_class_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تم اسناد هذا المدرس لهذا الفصل بهذه المادة"])},
      "assign_teacher_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض خطط التدريس "])}
    },
    "driver_data": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السائقين"])},
      "add_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة سائق"])},
      "edit_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل سائق"])},
      "driversList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات السائقين"])},
      "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم السائق"])},
      "driver_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم السائق"])},
      "driver_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" رقم التلفون"])},
      "driver_phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل رقم التلفون"])},
      "driver_home_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الثابت"])},
      "driver_home_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الثابت"])},
      "driver_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" العمر"])},
      "driver_age_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل العمر"])},
      "driver_martial_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحالة الاجتماعية"])},
      "driver_martial_status_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الحالة الاجتماعية"])},
      "driver_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايميل"])},
      "driver_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب الايميل"])},
      "card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الهوية"])},
      "card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهوية"])},
      "card_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الهوية"])},
      "card_issuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مكان الاصدار"])},
      "card_issuance_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل مكان الاصدار"])},
      "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
      "card_issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاصدار "])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
      "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل العنوان"])}
    },
    "bus_data": {
      "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباص"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباصات"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الباص"])},
      "add_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة باص"])},
      "edit_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل باص"])},
      "bus_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الباص"])},
      "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأستاذ"])},
      "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السائق"])},
      "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الرحلة"])},
      "driver_road_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل خط الرحلة"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الباص"])},
      "type_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل نوع الباص"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الباص"])},
      "number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الباص"])},
      "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المقاعد"])},
      "seat_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل عدد المقاعد"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
      "color_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اللون"])},
      "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم اللوحة"])},
      "car_card_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم اللوحة"])},
      "teacher_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الاستاذ مستخدم"])},
      "driver_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا السائق مستخدم"])},
      "number_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الرقم تم استخدامه"])}
    },
    "student_bus_data": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب مع الباصات"])},
      "add_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة باص"])},
      "edit_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل باص"])},
      "bus_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الباص"])},
      "bus_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم الباص"])},
      "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأستاذ"])},
      "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السائق"])},
      "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الرحلة"])},
      "driver_road_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل خط الرحلة"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الباص"])},
      "type_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل نوع الباص"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الباص"])},
      "number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الباص"])},
      "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المقاعد"])},
      "seat_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل عدد المقاعد"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
      "color_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اللون"])},
      "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم اللوحة"])},
      "car_card_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم اللوحة"])},
      "add_student_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الطلاب للباص"])},
      "chose_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الطلاب"])},
      "chose_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الباص"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشرف"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
      "is_subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشترك ؟"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد الطلاب المشاركين في النشاط"])},
      "max_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب المشاركين اكبر من العدد المطلوب لهذا النشاط"])},
      "student_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المشاركين"])}
    },
    "student": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب"])},
      "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة طالب"])},
      "students_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطلاب"])},
      "student_ar_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب عربي"])},
      "student_en_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب انجليزي"])},
      "parent_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلة القرابة مع ولي الامر"])},
      "parent_relation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل صلة القرابة"])},
      "student_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الطالب"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع"])},
      "registration_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة استمارة التسجيل"])},
      "parent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات ولي امر الطالب"])},
      "student_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطالب"])},
      "student_academic_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطالب الاكاديمية"])},
      "student_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الطالب"])},
      "student_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث باسم الطالب"])},
      "students_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل عدد الطلاب"])},
      "student_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب"])},
      "is_regestered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسجل ؟"])},
      "is_continued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستمر ؟"])},
      "students_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب"])},
      "study_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الطالب"])}
    },
    "parent": {
      "add_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة ولي أمر"])},
      "edit_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل ولي أمر"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم ولي الامر"])},
      "select_parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر ولي امر"])},
      "parent_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم ولي الامر"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظيفة"])},
      "job_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الوظيفة"])},
      "parent_id_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الهوية"])},
      "parent_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم هاتف ولي الامر"])},
      "parentList_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات اولياء الامور"])}
    },
    "class": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الصف"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الصف عربي"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الصف انجليزي"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الصف"])},
      "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرحلة الدراسية"])},
      "elementary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابتدائي"])},
      "preparatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادي"])},
      "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانوي"])},
      "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظامي"])},
      "dedicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخصص"])},
      "ministerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هذا الفصل وزاري ؟"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الصف"])}
    },
    "division": {
      "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعبة"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافة شعبة جديدة"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الشعبة "])},
      "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  رمز الشعبة"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   اختر الشعبة"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد شعب"])}
    },
    "document": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافة وثيقة"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوثيقة"])},
      "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الوثيقة"])},
      "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تهيئة الوثائق"])},
      "place_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مكان الاصدار"])},
      "place_issued_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ادخل مكان الإصدار"])},
      "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تاريخ الاصدار"])},
      "current_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الحالي"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ! لا توجد وثيقة "])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الوثيقة اختيارية للصف ؟ "])},
      "add_to_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة وثائق الصف"])}
    },
    "subject": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المادة"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اختر المادة"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة المواد الدراسية"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المادة"])},
      "subjects_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الدراسية"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اسم المادة عربي"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المادة انجليزي"])},
      "max_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة الكبرى"])},
      "min_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدرجة الصغرى"])},
      "add_to_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اضافة المادة الى المجموع الكلي ؟"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختياري"])},
      "obligatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجباري"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مواد"])}
    },
    "teacher": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المدرس"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مدرس"])}
    },
    "academic_year": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الدراسية"])},
      "name-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  السنة الهجرية"])},
      "name-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  السنة الميلادية"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر السنة"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة سنة دراسية جديدة"])},
      "select-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  اختر السنة الهجرية"])},
      "select-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اختر السنة الميلادية "])},
      "current-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الحالية"])},
      "placeholder-year-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال 1444/1445 "])},
      "placeholder-year-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال 2023/2024  "])}
    },
    "semester_data": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافة ترم جديد"])},
      "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترم"])},
      "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الترم عربي"])},
      "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الترم انجليزي"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   اختر الترم"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشهر الترم"])},
      "num-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عدد الأشهر "])},
      "current_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفصل الحالي"])}
    },
    "month": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الاشهر الدراسية"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اختر الشهر"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشهر"])},
      "cureent_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر الحالي"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشهر"])},
      "name_h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر الهجري"])},
      "name_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر الميلادي"])},
      "add_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة شهر"])},
      "edit_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل شهر"])},
      "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الشهر"])},
      "cureent_month_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون هناك شهر حالي"])}
    },
    "day": {
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
      "gaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الايام"])},
      "day_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم اجازة"])},
      "add_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة يوم"])},
      "day_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم اليوم"])}
    },
    "lecture": {
      "lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصة"])},
      "preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعداد الدروس"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدرس"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الدرس"])}
    },
    "semester": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الترم"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الترم"])}
    },
    "homework": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة واجب جديد"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الواجبات"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الواجب"])},
      "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد التسليم"])},
      "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التصحيح ؟"])},
      "correction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصحيح الواجبات"])}
    },
    "activity": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة نشاط جديد"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النشاط"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر النشاط"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع النشاط"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البداية"])},
      "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريح النهاية"])},
      "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسؤول النشاط"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع النشاط"])},
      "is_subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشترك ؟"])},
      "is_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدفوع ؟"])}
    },
    "monthly_marks": {
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
      "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شفهي"])},
      "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجبات"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضبة"])},
      "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحريري"])}
    },
    "semester_marks": {
      "collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحصلة"])},
      "final_exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الامتحان النهائي"])}
    },
    "estimate": {
      "add_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة تقدير"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التقدير عربي"])},
      "name_ar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم التقدير عربي"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم التقدير انجليزي "])},
      "name_en_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم التقدير انجليزي "])},
      "degree_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعلى درجة"])},
      "degree_max_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اعلى درجة"])},
      "degree_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادنى درجة"])},
      "degree_min_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل ادنى درجة"])}
    },
    "country": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدولة"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدولة عربي"])},
      "placeholder_country_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الدولة بالعربي"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدولة انجليزي"])},
      "placeholder_country_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الدولة بالانجليزي"])},
      "nationality_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجنسية عربي"])},
      "placeholder_nationality_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الجنسية بالعربي"])},
      "nationality_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجنسية انجليزي"])},
      "placeholder_nationality_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الجنسية بالانجليزي"])},
      "add_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة دولة"])}
    },
    "governorate": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافة محافظة جديد"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحافظة"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحافظة عربي"])},
      "placeholder_governorate_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المحافظة بالعربي"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحافظة انجليزي"])},
      "placeholder_governorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المحافظة بالانجليزي"])}
    },
    "directorate": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مديرية"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المديرية"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المديرية عربي"])},
      "placeholder_directorate_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المديرية بالعربي"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المديرية انجليزي"])},
      "placeholder_directorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم المديرية بالانجليزي"])}
    },
    "fees": {
      "installment_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قابل للتقسيط"])},
      "subscribe_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الاشتراك"])},
      "reducible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قابل للتخفيض"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
      "fee_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الرسوم"])},
      "fee_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الرسوم"])},
      "fees_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنود الرسوم"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
      "add_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فئات الخصم"])},
      "discount_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة الخصم"])},
      "placeholder_discount_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل فئة الخصم"])},
      "discount_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الخصم"])},
      "discount_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدار الخصم"])},
      "after_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم بعد الخصم"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة مئوية"])}
    },
    "student_fees": {
      "add_student_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة رسوم للطالب"])},
      "days_between_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الايام بين اللاقساط"])},
      "days_between_installments_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الايام"])},
      "installments_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاقساط"])},
      "installments_number_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عدد الاقساط"])},
      "installment_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع قسط طالب"])},
      "installment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ القسط"])},
      "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقساط"])},
      "installment_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المدفوع"])},
      "installment_remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المتبقي"])},
      "add_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع قسط"])},
      "show_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المدفوعات"])},
      "payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المدفوع"])},
      "who_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيان"])},
      "no_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم اسناد رسوم للطالب"])},
      "installment_still": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المتبقي على الطالب هو"])}
    },
    "student_attendance": {
      "permission_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاستئذان"])},
      "daily_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحضير اليومي"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور والغياب"])},
      "is_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاضر ؟"])},
      "absent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غائب"])},
      "is_permitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستأذن ؟"])},
      "permission_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدم الأذن"])},
      "permission_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم مقدم الأذن"])},
      "permission_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب الأذن"])},
      "permission_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاستئذان"])},
      "permission_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الأذن"])},
      "after_brake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحضير بعد الاستراحة"])}
    },
    "exams": {
      "examPeriod_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترة الامتحان"])},
      "examPeriod_start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية الامتحان"])},
      "examPeriod_start_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل بداية الامتحان"])},
      "examPeriod_end_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية الامتحان"])},
      "examPeriod_end_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل نهاية الامتحان"])},
      "schedule_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة"])},
      "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاختبار"])},
      "exam_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم الاختبار"])}
    },
    "seat_number": {
      "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجلوس "])},
      "public_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم عام"])},
      "dist_dynamically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع تلقائي"])},
      "secret_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم السري "])}
    },
    "weekly_schedule": {
      "pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساءً"])},
      "am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صباحاً"])},
      "no_periods_am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فترات صباحية"])},
      "no_periods_pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فترات مسائية"])}
    },
    "settings": {
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعدادات"])},
      "school_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستخدام نظام المدرسة"])},
      "school_system_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام خاص بالمدارس"])},
      "summer_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستخدام المركز الصيفي"])},
      "summer_system_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام خاص بالمراكز الصيفية"])},
      "current_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الحالية"])},
      "current_year_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط على زر التاريخ لإضافة تاريخ جديد"])},
      "current_year_placholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختار السنة الحالية"])},
      "main_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات النظام الرئيسية"])},
      "date_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات التاريخ"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات عامة"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع  التاريخ"])},
      "attendance_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحضير في اليوم"])},
      "hijri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هجري"])},
      "gregorian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميلادي"])},
      "once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرة واحدة"])},
      "twice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرتين"])},
      "system_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات النظام"])}
    },
    "committee": {
      "committee_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعة"])},
      "committee_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم اللجنة"])},
      "committee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم اللجنة"])},
      "halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعات"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اختر اللجنة"])}
    },
    "elevation": {
      "student_elevate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترفيع الطلاب"])},
      "current_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف الحالي"])},
      "next_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف التالي"])},
      "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدير"])},
      "registeration_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة التسجيل"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الطالب"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع الدرجات"])},
      "elevate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع"])}
    },
    "dashboard": {
      "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب"])},
      "student_reg_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب المسجلين"])},
      "teacher_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدرسين"])},
      "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب"])},
      "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسين"])},
      "students_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الطلاب"])},
      "students_stat_by_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات الطلاب حسب الفصول"])},
      "teachers_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات المدرسين"])},
      "save-as-png": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ كصورة"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الذكور"])},
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإناث"])}
    },
    "reports": {
      "in_the_name_of_allah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بسم الله الرحمن الرحيم"])},
      "in_the_name_of_allah_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In The Name Of Allah"])},
      "rep_of_yemen_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمهورية اليمنية"])},
      "rep_of_yemen_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of Yemen"])},
      "prime_minister_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وزارة التربية والتعليم"])},
      "prime_minister_office_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Ministry of Education"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي الكلي"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
      "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنصر"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
      "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم عرض "])},
      "select_criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معايير العرض"])},
      "select_parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر ولي امر"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد بيانات"])},
      "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة التقرير"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة"])},
      "student_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصف"])},
      "fee_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الرسوم"])},
      "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الرسوم"])},
      "installment_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المدفوع"])},
      "installment_remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المتبقي"])},
      "debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدين"])},
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دائن"])},
      "select_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الصف"])},
      "select_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الطالب"])},
      "select_fee_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع الرسوم"])},
      "select_division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الشعبة"])},
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البيان"])},
      "installment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ القسط"])},
      "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقساط"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المدفوع"])},
      "still": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المتبقي"])},
      "total_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي المدفوع"])},
      "clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البند"])},
      "parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم ولي الامر"])},
      "study_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة الدراسية"])},
      "parent_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هاتف ولي الامر"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
      "select_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر باص"])},
      "bus_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم الباص"])},
      "required_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي المطلوب"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخفيض"])},
      "paid_from_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوع من القسط"])},
      "total_after_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ بعد التخفيض"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
      "class_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الصف"])},
      "division_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشعبة"])},
      "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباص/طريق الباص"])},
      "for_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعام الدراسي"])},
      "for_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للصف"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
      "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الدولة"])},
      "select_governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المحافظة"])},
      "select_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الجنسية"])},
      "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد "])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" العمر"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الايميل"])},
      "student_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الطالب"])},
      "New_Enrolled_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب المستجدين"])},
      "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كشف مراجعة مادة"])},
      "home_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف الثابت"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
      "activity_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النشاط"])},
      "select_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر شهر"])},
      "subjects_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الدراسية  "])},
      "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجبات"])},
      "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحريري"])},
      "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شفهي"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواضبة"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
      "select_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المادة"])},
      "select_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الترم"])},
      "collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحصلة"])},
      "final_exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الامتحان النهائي"])},
      "writing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتابة"])},
      "count_of_actual_lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصص"])},
      "count_of_attendence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور"])},
      "count_of_absentce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغياب"])},
      "count_of_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايام بعذر"])},
      "count_of_not_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايام بغير عذر"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعدل"])},
      "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدير"])},
      "total_degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع الدرجات النهائي"])},
      "attendance_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواظبة"])},
      "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة "])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتيب"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المادة"])},
      "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترم"])},
      "select_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التقدير"])},
      "student_name_male/female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالبـ/ـة:"])},
      "attendance_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأريخ التحضير"])},
      "is_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاضر ؟"])},
      "is_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستأذن ؟"])},
      "Month_Certificate_En_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Certificate"])},
      "Specific_Year_Certificate_En_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year Certificate"])},
      "Specific_Semester_Certificate_En_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Certificate"])},
      "mark_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع الدرجات"])},
      "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعبة"])},
      "study_year_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Year"])},
      "semester_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester"])},
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتائج"])},
      "select_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الفرع"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرع"])},
      "student_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطالب"])},
      "select_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر اللجنة"])},
      "committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم اللجنة"])},
      "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجلوس "])},
      "secret_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم السري "])},
      "committee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم اللجنة"])},
      "student_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطالب"])},
      "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية الوقت"])},
      "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية الوقت"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
      "select_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مدرس"])},
      "teacher_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدرسين"])},
      "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الاسم مع اللقب"])},
      "teacher_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حالة المدرس"])},
      "teacher_specail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخصص المدرس"])},
      "teacher_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   السكن الحالي"])},
      "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديرية"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعزب"])},
      "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متزوج"])},
      "bus_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الباص"])},
      "driver_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف السائق"])},
      "bus_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الباص"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشرف"])},
      "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم اللوحة"])},
      "teacher_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف الاستاذ"])},
      "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم السائق"])},
      "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الرحلة"])},
      "bus_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لون الباص"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدور"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
      "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاول"])},
      "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هو نشط؟"])},
      "date_joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانظمام"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مجموعة"])},
      "permission_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الصلاحية"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])},
      "attend_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التحضير"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
      "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ولي الامر"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطالب"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظيفة"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
      "subject_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المادة"])},
      "net_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
      "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الأوائل"])},
      "general_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدير العام"])},
      "lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفقود"])},
      "activity_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع النشاط"])},
      "activity_responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسؤول النشاط"])},
      "numbering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقما"])},
      "seat_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المقاعد"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة"])},
      "study_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الطالب"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعدل"])},
      "all_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["( كل الفروع )"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرس"])},
      "period_am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة"])},
      "marital_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة الاجتماعية"])},
      "birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان الميلاد "])},
      "jop_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الوظيفي"])},
      "current_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان الحالي"])},
      "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الخدمة"])},
      "teacher_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المدرس"])},
      "work_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترة العمل"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الابناء"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
      "lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحصة"])},
      "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاختبار"])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
      "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
      "save_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ و إرسال"])},
      "update_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل و إرسال"])}
    }
  },
  "global": {
    "error": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ !"])},
      "validation": {
        "must_be_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يتكون من أحرف عربية فقط !"])},
        "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال ارقام  فقط"])},
        "required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقل إلزامي"])},
        "must_be_ar_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يتكون من أحرف عربية فقط !"])},
        "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تعديت الحد الاقصى لعدد الحروف"])},
        "must_be_en_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يتكون من أحرف إنجليزية فقط !"])},
        "max_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكبر تاريخ مسموح به هو "])},
        "min_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصغر تاريخ مسموح به هو "])},
        "numbers_and_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يتكون الحقل من حروف وارقام"])},
        "must_be_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال ارقام  فقط"])},
        "max_numbers_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تجاوزت اكبر رقم مسموح به"])},
        "max_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تجاوزت الحد الاعلى لعدد الارقام"])},
        "min_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تجاوزت الحد الادنى لعدد الارقام"])},
        "min_numbers_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تجاوزت اصغر رقم مسموح به"])},
        "lowest_number_of_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقل عدد ممكن هو "])},
        "biggest_number_of_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكبر عدد ممكن هو "])},
        "max_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكد ان الحقل لا يتجاوز الحد الاقصى"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البدايه اكبر من  تاريخ النهاية"])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النهاية اقل من  تاريخ البداية"])},
        "min-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تأكد ان الحقل لا يقل عن الحد الادنى"])},
        "arabic-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال احرف عربية فقط"])},
        "english-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال احرف انجليزية فقط"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" النص المدخل ليس عنوان ايميل صحيح "])},
        "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه القيمة موجدة بالفعل"])},
        "unique_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ادخال هذا الجدول بالفعل"])},
        "degree_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لايمكن ان تكون الدرجة الكبرى اقل من الدرجة الصغرى"])},
        "already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ادخال بيانات لم يتم ادخالها من قبل"])},
        "fee_types_bus_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن اختيار اكثر من نوع رسوم باص"])},
        "percentage_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يجب ان تتعدى النسبة عن 100"])},
        "discount_is_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الخصم غير صحيح"])},
        "max_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تأكد من الاشهر مساوي للعدد المطلوب "])},
        "sequential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان تكون الاشهر متوالية"])},
        "deadline_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التسليم يجب ان يكون اكبر من تاريخ اليوم"])},
        "ExamPeriod_timing_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال الاوقات بشكل صحيح"])},
        "fee_class_exceist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الفصل ونوع الرسوم مختار من قبل"])},
        "imageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الصورة يجب ان يكون"])},
        "expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء لا يجب ان يقل عن تاريخ الاصدار"])},
        "issuer_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاصدار لا يجب ان يتجاوز تاريخ الانتهاء"])},
        "interfering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يجب ان تتداخل قيم التقديرات مع تقدير"])},
        "group_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة بهذا الاسم موجودة من قبل"])},
        "division_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اسناد هذا الصف والشعبة من قبل"])}
      },
      "failure": {
        "day_overload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن ان يكون هناك اكثر من سبعة ايام"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل"])},
        "error_in_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى مراجعة البيانات المدخلة"])},
        "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد 404 "])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد سجل بهذه البيانات "])},
        "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد اتصال بقاعدة البيانات"])},
        "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في عملية الادخال"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في عملية التعديل"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل في عملية الحذف"])},
        "cureent_month_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون هناك شهر حالي"])},
        "errpr_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك حذف الشهر الحالي"])},
        "fill_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ملئ الحقل السابق"])},
        "user_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الايميل موجود من قبل"])},
        "main_branch_must_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون هناك فرع رئيسي"])},
        "user-logged-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عذراً المستخدم مسجل دخول بالفعل"])},
        "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*الايميل او كلمة المرور غير صحيحة"])},
        "parent_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ولي الامر هذا لا يمكن حذفه لوجود طلاب مرتبطين فيه"])},
        "data_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن حذف البيانات لوجود قيم مربوطة بها"])},
        "branch_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترم غير مربوط بالفصل الحالي"])},
        "month_not_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر غير مربوط بفصل"])},
        "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فرع مربوط بالمستخدم"])},
        "cant_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تتوفر الصلاحيات المطلوبة لتعديل هذا السجل"])},
        "cant_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تتوفر الصلاحيات المطلوبة لحذف هذا السجل"])},
        "user_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطاء في حساب المستخدم"])},
        "protected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن حذف السجل لانه مستخدم في شاشات اخرى"])},
        "protected_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن حذف السجل لانه مرتبط بسجلات اخرى"])},
        "period_is_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هذه الفترة مستخدمة من قبل"])},
        "committee_excest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه اللجنة والصف قد تم اختيارهم من قبل"])},
        "unique_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللجنة موجودة مسبقا"])},
        "hall_excist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا القاعة مستخدمة من قبل"])},
        "hall_exist_in_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعة موجودة مسبقا في هذا المبنى"])},
        "student_number_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تعديت الحد الاقصى لعدد الطلاب لهذا الفصل"])},
        "seat_number_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد تكرار في رقم الجلوس"])},
        "secret_number_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد تكرار في الرقم السري"])},
        "must_be_insert_seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال ارقام جلوس اولا"])},
        "three_choices_cannot_be_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن اختيار ثلاثه اختيارات"])},
        "no_homeworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد واجبات"])},
        "no_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد طلاب في الصف"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد بيانات"])},
        "has_marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن حذف الطالب لانه لديه درجات"])},
        "user with this email already exists.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايميل موجود من قبل"])},
        "cant_update_assigned_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تعديل الرسوم لانه قد تم اسنادها لطالب"])}
      },
      "warning": {
        "not_all_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم حفظ بعض البيانات بسبب التكرار"])}
      }
    },
    "alert": {
      "documents_per_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه الوثائق مطلوبة لهذا الفصل"])},
      "student_count_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تخطيت عدد الطلاب المسموح بهم بهذه الشعبة"])},
      "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متأكد أنك تريد حذف هذا السجل ؟"])},
      "can_not_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن الحذف"])},
      "can_not_delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هناك سجلات مرتبطة بهذا العنصر"])},
      "show_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])},
      "hide_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخفاء التفاصيل"])}
    },
    "success": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجاح  ^_^"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجاح "])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الادخال بنجاح "])},
      "data_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تعديل البيانات بنجاح"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحذف بنجاح "])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ البيانات بنجاح"])},
      "create-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم انشاء نسخة احتياطية بنجاح"])},
      "restore-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استعادة النسخة الاحتياطية بنجاح"])},
      "data_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت حفظ البيانات بنجاح"])},
      "data_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف البيانات بنجاح"])},
      "data_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت اضافة البيانات بنجاح"])},
      "student_elevated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ترفيع الطلاب الناجحين فقط"])}
    },
    "warning": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحذير"])},
      "max_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الطلاب المشاركين اكبر من العدد المطلوب لهذا النشاط"])},
      "no_period_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات في الفترة"])}
    },
    "personal_information": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم عربي"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم انجليزي"])},
      "name_ar_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الطالب بالعربي"])},
      "name_en_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم الطالب بالانجليزي"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
      "phone_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الجوال"])},
      "home_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف الثابت"])},
      "home_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الهاتف الثابت"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
      "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل العنوان"])},
      "id_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الهوية"])},
      "id_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهوية"])},
      "id_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رقم الهوية"])},
      "birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان الميلاد "])},
      "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد "])}
    },
    "data_table": {
      "search_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث هنا "])},
      "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عناصر في كل صفحة"])},
      "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنصر"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
      "student_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد طالب بهذا الاسم "])}
    },
    "model": {
      "Lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دروس"])},
      "TeacherSubjectClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة مدرسين"])},
      "Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اولياء أمور"])},
      "Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب"])},
      "Teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدرسين"])},
      "Building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مباني"])},
      "ClassDivisionHall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطط قاعات"])},
      "Homework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجبات"])},
      "ExamsPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترات امتحانية"])},
      "ExamSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جداول امتحانات"])},
      "DiscountCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئات خصم"])},
      "FeeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع رسوم"])},
      "Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنود رسوم"])},
      "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشطة"])},
      "Committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لجان"])},
      "SeatNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارتباطات للطلاب باللجان"])},
      "SchoolWeeklySchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جداول اسبوعية"])},
      "StudentSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات ترم"])},
      "StudentHomework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات واجبات"])},
      "Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجات شهرية"])},
      "StudentFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم طلاب"])},
      "InstallmentsPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع اقساط"])},
      "PaymentsReciept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سندات قبض"])},
      "StudentDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق طلاب"])},
      "Driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سائقين"])},
      "Bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باصات"])},
      "SettingsStudentCommittees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطط لجان"])},
      "StudentBus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاب مشتركين في الباصات"])},
      "Hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاعات"])},
      "ClassDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق صفوف"])},
      "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محافظات"])},
      "StudentAttendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حضور للطالب"])}
    },
    "month": {
      "يناير": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
      "فبراير": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
      "مارس": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
      "ابريل": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      "مايو": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
      "يونيو": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
      "يوليو": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
      "اغسطس": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      "سبتمير": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "اكتوبر": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
      "نوفمبر": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      "ديسمبر": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
      "محرم": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muharram"])},
      "صفر": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safar"])},
      "ربيع اول": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabi al-Awwal"])},
      "ربيع ثاني": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabi al-Thani"])},
      "جماد اول": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumada al-Awwal"])},
      "جماد ثاني": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumada al-Thani"])},
      "رجب": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rajab"])},
      "شعبان": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shaban"])},
      "رمضان": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ramadan"])},
      "شوال": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shawwal"])},
      "ذو القعدة": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dhu al-Qadah"])},
      "ذو الحجة": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dhu al-Hijjah"])}
    },
    "order": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأول"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثاني"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثالث"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابع"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخامس"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السادس"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابع"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثامن"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاسع"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العاشر"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحادي عشر"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثاني عشر"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثالث عشر"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابع عشر"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخامس عشر"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السادس عشر"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابع عشر"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثامن عشر"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاسع عشر"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العشرون"])}
    },
    "letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حرف"])},
    "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارقام"])},
    "letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احرف"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ملاحظة"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات"])},
    "notes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب هنا الملاحظات"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديد"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسناَ"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومة"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبية"])},
    "current-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون هناك سنة حالية"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلترة"])},
    "view_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلترات العرض"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكسل"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دخول"])},
    "sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خروج"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ... تحميل"])},
    "confirm-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متأكد أنك تريد حذف هذا السجل ؟"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة مئوية"])},
    "discount_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدار الخصم"])},
    "pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساءً"])},
    "am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صباحاً"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة"])},
    "remember_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تذكرني"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "exit_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متاكد انك تريد تسجيل الخروج من نظام ادارة المدارس؟"])},
    "login_account_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الدخول الى حسابك في نظام ادارة المدارس"])}
  },
  "users": {
    "user": {
      "user_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المستخدمين"])},
      "adduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مستخدم "])},
      "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مستخدم "])},
      "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاول"])},
      "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاخير"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الايميل"])},
      "superuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدير"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدور"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجموعة"])},
      "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشرف"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
      "placeholder_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور "])},
      "date_joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانظمام"])},
      "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
      "is_deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])}
    },
    "group": {
      "add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة المجموعات"])}
    },
    "permission": {
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات"])},
      "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])},
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصلاحيات"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانشطة"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفروع"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المباني"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفوف"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايام"])},
      "classdivisionhall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة الفصول"])},
      "classdocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الفصل"])},
      "classstudentyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترفيع الطلاب"])},
      "classsubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لجان الاختبارات"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائل الاتصال"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلدان"])},
      "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المديريات"])},
      "discountcategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصم"])},
      "distributionofseatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات ارقام الحلوس"])},
      "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعب"])},
      "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوثائق"])},
      "examschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواعيد الاختبارات"])},
      "examsperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترات الاختبارات"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة الرسوم"])},
      "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي الرسوم"])},
      "feetype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انواع الرسوم"])},
      "paymentsreciept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" فواتير القبض"])},
      "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظات"])},
      "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاعات"])},
      "homework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجبات"])},
      "installmentspayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقساط"])},
      "lectureattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الدروس"])},
      "marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات الشهرية"])},
      "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اولياء الامور"])},
      "parentmessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل اولياء الامور"])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فترات الحصص"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المدرسة"])},
      "schoolweeklyschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول الحصص الاسبوعي"])},
      "seatnumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارقام الجلوس"])},
      "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترم"])},
      "settingsstudentcommittees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة اللجان"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب"])},
      "studentactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراك بالانشطة"])},
      "studentattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحضير الطلاب"])},
      "studentdocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وثائق الطلاب"])},
      "studentfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم الطلاب"])},
      "studenthomework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واجبات الطلاب"])},
      "studentsubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات الفصلية"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسين"])},
      "teachersubjectclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطة المدرسين"])},
      "yearofstudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنوات الدراسية"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمين"])},
      "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السائقين"])},
      "studentbus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراك بالباص"])},
      "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباصات"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهور الدراسية"])},
      "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقديرات"])},
      "attendance_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأريخ التحضير"])},
      "is_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاضر"])},
      "is_permitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستأذن"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الصلاحية"])},
      "lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدروس"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشعارات"])},
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاخبار"])}
    }
  }
}