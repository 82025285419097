<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes_by_branch"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" md="2" sm="12">
          <v-text-field
            v-model="perPage"
            hide-details="auto"
            type="number"
            density="compact"
            :rules="[$max_value(30)]"
            :label="$t('school.reports.student_count')"
          ></v-text-field>
        </v-col>

        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <tr>
                <th v-for="header in selectedHeaders" :key="header.key">
                  {{ header.title }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="key">
                <td class="text-center border">{{ key + 1 }}</td>
                <td
                  class="text-center border"
                  v-for="(header, index) in selectedHeaders.slice(1)"
                  :key="index"
                >
                  {{ item[header.key] }}
                </td>
              </tr>
              <!-- <tr v-for="(item, key) in items" :key="key">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('division_names')"
                >
                  {{ item.division }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('total')"
                >
                  {{ item.total }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="items?.length"
          ></pagination> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBranchClasses();
      // await this.getClasses();
      await this.getSubjects({});
      await this.getBrenches({});
      // await this.getattendances();
      // await this.getDivisions();
      // await this.getSemesters();
      // await this.getSubjects();
      // this.current_year = localStorage.getItem("current_year");
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // current_year: "",
      // years: [],
      // months: [],
      // attendances: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: [
        "no",
        "branch_name",
        "student_name",
        "total",
        "division",
        "average",
        "rank",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: this.$t("school.reports.branch"), key: "branch_name" },
        { title: this.$t("school.reports.division"), key: "division" },
        { title: this.$t("school.reports.mark_total"), key: "total" },
        { title: this.$t("school.reports.average"), key: "average" },
        { title: this.$t("school.reports.order"), key: "rank" },
      ],
      perPage: 10,
      // length: 0,
      // totalItems: 0,
      // itemsPerPage: [
      //   { value: 5, text: "5" },
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      // page: 1,
      // pagination: {},
      filter_data: {
        // fk_year: null,
        fk_class: null,
      },
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      classes_by_branch: (state) => state.school.classes_by_branch,
      branch: (state) => state.school.branch,
      // user: (state) => state.User,
    }),
    ...mapGetters({
      getSubjectsByClass: "school/getSubjectsByClass",
    }),
    selectedHeaders() {
      let filtered = this.selectedHead.filter((header) => {
        if (header === "branch_name") {
          return localStorage?.getItem("role") < 2
            ? !this.filter_data.fk_branch
            : false;
        }
        // if (header === "class_name") {
        //   return !this.filter_data.fk_class;
        // }
        return true;
      });
      return this.headers.filter((header) => filtered.includes(header.key));
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      // getClasses: "school/getClasses",
      getBrenches: "school/getBrenches",
      getSubjects: "school/getSubjects",
    }),
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/higtestmarks/year`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // page: page,
            limit: this.perPage,
            // fk_year: localStorage.getItem("current_year"),
            fk_branch: this.filter_data.fk_branch?.id,
            fk_class: this.filter_data.fk_class?.id,
          },
        })
        .then((response) => {
          this.exist = true;

          if (response.data) {
            let count = 1;
            let rank = this.$t(`global.order.1`);
            this.items = response.data?.map((student, index, array) => {
              if (index > 0 && array[index - 1]["total"] !== student["total"]) {
                count++;
                rank = this.$t(`global.order.${count}`);
              } else if (index > 0) {
                rank = this.$t(`global.order.${count}`) + " " + "مكرر";
              }
              return {
                ...student,
                rank,
                average:
                  "%" +
                  parseFloat(
                    (student?.total /
                      (this.classes_by_branch.find(
                        (item) => item.id == this.filter_data?.fk_class?.id
                      )?.subjects?.length *
                        100)) *
                      100
                  ).toFixed(2),
              };
            });
            // this.totalItems = response.data?.pagination?.count;
            // this.length=response.data.pagination.num_pages
            // this.pagination=response.data.pagination
            // this.itemsPerPage[4]['value']=this.totalItems
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       fk_class: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  // watch: {
  //   perPage() {
  //     this.getData();
  //   },
  //   page() {
  //     this.getData(this.page);
  //   },
  // },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
