<template>
  <v-card class="justify-center">
    <v-card-text class="card-fields">
      <v-row>
        <v-col cols="10">
          <v-form
            ref="form"
            v-if="
              (update && CheckGetPermission('school.change_feetype')) ||
              CheckGetPermission('school.add_feetype')
            "
          >
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                <v-text-field
                  v-model="fee_types.name"
                  counter="100"
                  type="input"
                  density="compact"
                  clearable
                  prepend-inner-icon="mdi-rename-box"
                  :label="$t('school.fees.name')"
                  :rules="[$required, $max_length()]"
                />
              </v-col>
              <VCol cols="12" xs="12" sm="12" md="5" lg="5">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    v-model="fee_types.subscribe_type"
                    item-value="id"
                    item-title="name"
                    :items="subscribsType_list"
                    prepend-inner-icon="mdi-currency-usd"
                    :label="$t('school.fees.subscribe_type')"
                    persistent-hint
                    density="compact"
                    clearable
                    :rules="[(value) => checkSubscribtion(value), $required]"
                  >
                  </VAutocomplete>
                </VCardItem>
              </VCol>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                <v-checkbox
                  v-model="fee_types.reducible"
                  :label="$t('school.fees.reducible')"
                  color="indigo"
                  density="compact"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="5" lg="5">
                <v-checkbox
                  v-model="fee_types.installment_available"
                  :label="$t('school.fees.installment_available')"
                  color="indigo"
                  density="compact"
                />
              </v-col>
            </v-row>
            <VRow>
              <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="10" lg="10">
                <VCardItem class="px-3">
                  <VTextarea
                    v-model="fee_types.note"
                    clearable
                    :label="$t('global.note')"
                    prepend-inner-icon="mdi-note-outline"
                    counter="250"
                    no-resize
                    density="compact"
                    rows="3"
                    :rules="[$max_length(250)]"
                  >
                  </VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
          </v-form>
          <v-card-actions
            v-if="
              (update && CheckGetPermission('school.change_feetype')) ||
              CheckGetPermission('school.add_feetype')
            "
          >
            <v-btn
              v-if="!update && CheckGetPermission('school.add_feetype')"
              @click="saveFeeType"
              class="bg-indigo"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.add") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <v-btn
              v-if="update && CheckGetPermission('school.change_feetype')"
              @click="updateFeeType(selected_fee_type)"
              class="bg-success"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.edit") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <VBtn class="mx-3" size="small" @click="resetForm">
              {{ !update ? $t("global.clear") : $t("global.cancel") }}
              <VIcon
                :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
                :color="!update ? 'golden' : 'red'"
                end
              ></VIcon>
            </VBtn>
            <!-- <v-btn @click="resetForm" class="mx-3" size="small">
              {{ $t("global.clear") }}
              <v-icon icon="mdi-broom" color="golden" end></v-icon>
            </v-btn> -->
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- ##########    data Table Server ########### -->
    <!-- <v-row justify="center" v-if="alertMessage" class="mb-4 "> 
            <v-col cols="9">
                <v-alert 
                    closable
                    density="compact"
                    border="start"
                    variant="outlined"
                    color="success"
                    :icon="alertIcon"
                    :title="alertTitle"
                    :text="alertMessage"
                >
                </v-alert>
            </v-col>
        </v-row> -->
    <v-divider class="mt-5" />
    <v-card class="card-table">
      <!-- ######## Header Of Data Table Server ####### -->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <!-- <v-btn
                      v-bind="props"
                      variant="text"
                      size="small"
                      @click="getFees"
                      >
                      <v-icon>mdi-refresh</v-icon>
                  </v-btn> -->
        <!-- @input="getTeacher(page=1)" -->
        <div style="width: 100%">
          <v-text-field
            v-model="txt_search"
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            class="search"
            hide-details
            append-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span v-else-if="key == 'reducible'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else-if="key == 'installment_available'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_feetype')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editFeeType(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_feetype')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="getReadyToDelete(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (selected_fee_type = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteFeeType(selected_fee_type)">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "FeeType",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.subscribsType_list.length) await this.getSubscribsType();
      if (!this.fee_types_list.length) await this.getFeeTypes();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      index: "",
      del_dialog: false,
      add_dialog: false,
      overlay: false,
      update: false,
      alert: false,

      selectedHead: [
        "name",
        "reducible",
        "installment_available",
        "subscribe_type",
        "actions",
      ],

      fee_types: {
        name: undefined,
        reducible: undefined,
        installment_available: undefined,
        subscribe_type: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      selected_fee_type: undefined,
      txt_search: undefined,
      headers: [
        { title: this.$t("school.fees.name"), key: "name" },
        { title: this.$t("school.fees.reducible"), key: "reducible" },
        {
          title: this.$t("school.fees.installment_available"),
          key: "installment_available",
        },
        { title: this.$t("school.fees.subscribe_type"), key: "subscribe_type" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      search: null,
      perPage: 10,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      is_loading: false,
    };
  },
  computed: {
    ...mapState({
      fee_types_list: (state) => state.school.fee_types,
      subscribsType_list: (state) => state.globals.subscribsType,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    tableList() {
      let list = [];
      this.fee_types_list.forEach((element) => {
        let subscribe_type = this.subscribsType_list.find(
          (item) => item.id == element.subscribe_type
        );
        if (subscribe_type != undefined) subscribe_type = subscribe_type.name;
        list.push({
          name: element.name,
          reducible: element.reducible,
          installment_available: element.installment_available,
          subscribe_type: subscribe_type,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getFeeTypes: "school/getFeeTypes",
      getSubscribsType: "globals/getSubscribsType",
    }),
    resetForm() {
      this.$refs.form.reset();
      //   this.v$.fee_types.$reset();
      this.update = false;
      this.fee_types.reducible = false;
      this.fee_types.installment_available = false;
    },
    checkSubscribtion(value) {
      if (value == 1) {
        let type = this.fee_types_list.find(
          (item) => item.subscribe_type == value
        );
        if (type == undefined) return true;
        else {
          if (type.id == this.selected_fee_type) return true;
          else return this.$t("global.error.validation.fee_types_bus_error");
        }
      }
      return true;
    },
    editFeeType(id) {
      this.selected_fee_type = id;
      this.update = true;
      let fee_type = this.fee_types_list.find((item) => item.id == id);
      if (fee_type) {
        this.fee_types = { ...fee_type };
      }
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getReadyToDelete(id) {
      let fee_type = this.fee_types_list.find((item) => item.id == id);
      if (fee_type) {
        if (!fee_type.fk_branch == null) {
          this.$emit("errorAlert", this.$t("global.error.failure.cant_delete"));
        } else {
          this.selected_fee_type = id;
          this.del_dialog = true;
        }
      }
    },
    async saveFeeType() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}fee-types/`, this.fee_types, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.resetForm();
            this.getFeeTypes();
            this.add_dialog = false;
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async updateFeeType(id) {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.fee_types.id != undefined) {
        this.is_loading = true;
        // if(!this.v$.$error){
        // this.fee_types.updated_by = this.user;
        await this.axios
          .put(`${this.base_url}fee-types/${id}/`, this.fee_types, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            this.getFeeTypes();
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              if (error.response.data == "not_your_branch")
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.cant_update")
                );
              if (error.response.data == "no_branch")
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.user_error")
                );
            } else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
      }
      this.is_loading = false;
    },
    async deleteFeeType(id) {
      await this.axios
        .delete(`${this.base_url}fee-types/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.data_deleted"));
          this.getFeeTypes();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data == "not_your_branch")
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.cant_delete")
              );
            else if (error.response.data == "no_branch")
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.user_error")
              );
            else if (error.response.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
          } else
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
        });
      this.del_dialog = false;
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
