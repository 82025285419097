<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_division = null),
                getClassAssignHall(filter_data.fk_branch?.id),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="filter_data.fk_division = null"
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            :no-data-text="$t('school.division.not_found')"
            prepend-inner-icon="mdi-chair-school"
            :items="
              filter_data.fk_class?.id
                ? getClassDivisions(filter_data.fk_class?.id, getUserRole < 2)
                : []
            "
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <!-- <v-col cols="12" md="3" sm="12" >
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes"
            :label="$t('school.reports.select_class')"
            @update:modelValue="filter_data.fk_division = null"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <!-- <v-col cols="12" md="3" sm="12" >
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_month"
            density="compact"
            :item-title="date_type == 'm' ? 'name_m' : 'name_h'"
            item-value="id"
            prepend-inner-icon="mdi-calendar"
            :items="months"
            :label="$t('school.reports.select_month')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <!-- <v-col cols="12" md="3" sm="12" >
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-chair-school"
            :items="
              filter_data.fk_class?.id
                ? getClassDivisions(filter_data.fk_class?.id)
                : []
            "
            :no-data-text="$t('school.division.not_found')"
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th
                v-for="header in selectedHeaders"
                :key="header.key"
                class="border backgrounds pa-1"
              >
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <template v-for="(item, index) in items" :key="item.id">
                <tr v-for="(sub, subIndex) in item?.subjects" :key="sub">
                  <template v-if="subIndex === 0">
                    <td
                      v-if="selectedHead.includes('no')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ (page - 1) * perPage + index + 1 }}
                    </td>
                    <td
                      v-if="selectedHead.includes('student_name')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ item.student_name }}
                    </td>
                    <!-- <td
                      v-if="selectedHead.includes('class_name')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ item.class_name }}
                    </td> -->
                  </template>

                  <td
                    v-for="header in selectedHeaders?.filter(
                      (head) => head.key != 'no' && head.key != 'student_name'
                    )"
                    :key="header.key"
                    class="text-center border"
                  >
                    {{ sub[header.key] != null ? sub[header.key] : "-" }}
                  </td>
                </tr>
                <tr></tr>
              </template>
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('subject_name')"
                >
                  {{ item.subject_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('assigments')"
                >
                  {{ item.assigments }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('oral')"
                >
                  {{ item.oral }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('exam')"
                >
                  {{ item.exam }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('attendance')"
                >
                  {{ item.attendance }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[10]"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // await this.getAllMonths();
      // await this.getSemesters();
      if (this.getUserRole > 1) {
        await this.getBranchClasses();
      }
      await this.getBrenches({});
      await this.getDivisions({});
      await this.getClassAssignHall();
      // await this.getYears();
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // school: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      // year_data: null,
      date_type: localStorage.getItem("date"),
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: [
        "no",
        "student_name",
        // "class_name",
        "subject_name",
        "assignments",
        "oral",
        "exam",
        "attendance",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        // { title: this.$t("school.reports.class_name"), key: "class_name" },

        {
          title: this.$t("school.reports.subject_name"),
          key: "subject_name",
        },
        { title: this.$t("school.reports.attendance"), key: "attendance" },
        { title: this.$t("school.reports.assigments"), key: "assignments" },
        { title: this.$t("school.reports.oral"), key: "oral" },
        { title: this.$t("school.reports.exam"), key: "exam" },
      ],
      perPage: 10,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   {
      //     value: 5,
      //     text: "5",
      //   },
      //   {
      //     value: 10,
      //     text: "10",
      //   },
      //   {
      //     value: 25,
      //     text: "25",
      //   },
      //   {
      //     value: 50,
      //     text: "50",
      //   },
      //   {
      //     value: 75,
      //     text: "75",
      //   },
      //   {
      //     value: 100,
      //     text: "100",
      //   },
      //   {
      //     value: "",
      //     text: "all",
      //   },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        // year: null,
        class: null,
        fk_division: null,
      },
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      // months: (state) => state.school.months,
      classes_by_branch: (state) => state.school.classes_by_branch,
      divisions: (state) => state.school.divisions,
      branch: (state) => state.school.branch,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    // getMonthName(month_id) {
    //   const month = this.months.find((month) => month.id === month_id);
    //   return month.name;
    // },

    // getClassName(class_id) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === class_id
    //   );
    //   return objectClass.name_ar;
    // },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getDivisionName(division_id) {
    //   if (division_id) {
    //     const division = this.divisions.find(
    //       (division) => division.id === division_id
    //     );
    //     return division.name;
    //   }
    //   return "===========";
    // },
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      // getAllMonths: "school/getAllMonths",
      getDivisions: "school/getDivisions",
      getClassAssignHall: "school/getClassAssignHall",
      getBrenches: "school/getBrenches",
    }),
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/monthly_deficiencies`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // search: this.txt_search,
            page: page,
            page_size: this.perPage,
            class_number: this.filter_data?.fk_class?.id,
            // year: this.filter_data.year,
            division: this.filter_data?.fk_division?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // month: 2,
          },
        })
        .then((response) => {
          if (response.data?.results) {
            this.items = response.data?.results;
            this.totalItems = response.data?.pagination?.count;
            this.exist = true;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       year: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       month: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       class: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       fk_division: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
