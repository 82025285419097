<template>
  <v-card class="justify-center">
    <v-card-text class="card-fields">
      <v-row>
        <v-col cols="12" md="8">
          <v-form ref="form"
          v-if="(is_update && CheckGetPermission('usermanage.change_user')) ||CheckGetPermission('usermanage.add_user')">
            <v-row class="mt-4" v-if="!user_data.role || user_data.role < 5">
              <v-col cols="12" md="6">
                <v-text-field
                  color="indigo"
                  v-model="user_data.first_name"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('users.user.fname')"
                  :rules="[$required, $max_length(), $min_length(3)]"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  color="indigo"
                  v-model="user_data.last_name"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('users.user.lname')"
                  :rules="[$required, $max_length(), $min_length(3)]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12" md="6">
                <v-text-field
                  color="indigo"
                  v-model="user_data.email"
                  type="email"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('users.user.email')"
                  :rules="[$required, $email, $max_length(64)]"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  color="indigo"
                  v-model="user_data.username"
                  clearable
                  counter="40"
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('users.user.username')"
                  autocomplete="new-user"
                  :rules="[$required, $max_length(40)]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12" md="6">
                <v-text-field
                  color="indigo"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="user_data.password"
                  clearable
                  counter="20"
                  density="compact"
                  autocomplete="new-password"
                  :label="$t('users.user.password')"
                  :rules="
                    !is_update
                      ? [$required, $min_length(8), $max_length(20)]
                      : [$min_length(8), $max_length(20)]
                  "
                >
                  <template v-slot:prepend-inner>
                    <v-icon @click="showPassword = !showPassword">{{
                      showPassword ? "mdi-eye" : "mdi-eye-off"
                    }}</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="!user_data.role || user_data.role < 5"
              >
                <v-autocomplete
                  color="indigo"
                  multiple
                  v-model="user_data.groups"
                  density="compact"
                  item-title="name"
                  item-value="pk"
                  prepend-inner-icon="mdi-account-group"
                  clearable
                  :items="groups_list"
                  :rules="[$required]"
                  :label="$t('users.user.group')"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col
                cols="12"
                md="5"
                v-if="(!user_data.role || user_data.role < 5 ) && ((getUserRole == 1 && user_data.role != 1)|| getUserRole != 1)"
              >
                <v-autocomplete
                  color="indigo"
                  v-model="user_data.role"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  clearable
                  prepend-inner-icon="mdi-shape-outline"
                  :items="roleList"
                  :rules="[$required]"
                  :label="$t('users.user.role')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" md="2">
                <v-checkbox
                  :label="$t('users.user.active')"
                  color="indigo"
                  v-model="user_data.is_active"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-col>
              <v-col
                cols="9"
                md="5"
                v-if="
                  getUserRole < 2 && user_data.role > 1 && user_data.role < 5
                "
              >
                <v-autocomplete
                  :items="branch"
                  v-model="user_data.fk_branch"
                  prepend-inner-icon="mdi-store"
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('school.buldings_data.branch')"
                  persistent-hint
                  density="compact"
                  :rules="[$required]"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>

          <v-card-actions
            v-if="(is_update && CheckGetPermission('usermanage.change_user')) ||CheckGetPermission('usermanage.add_user')"
          >
            <v-btn
              v-if="!is_update && CheckGetPermission('usermanage.add_user')"
              @click="saveUser"
              class="bg-indigo"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.add") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <v-btn
              v-if="is_update && CheckGetPermission('usermanage.change_user')"
              @click="updateUser"
              class="bg-success"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.edit") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <VBtn class="mx-3" size="small" @click="resetForm()">
              {{ !id ? $t("global.clear") : $t("global.cancel") }}
              <VIcon
                :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
                :color="!id ? 'golden' : 'gray'"
                end
              ></VIcon>
            </VBtn>
            <!-- <v-btn  @click="resetForm" class="mx-3 "  size="small">
                        {{$t('global.clear')}}
                        <v-icon icon="mdi-broom" color="golden" end></v-icon>
                    </v-btn> -->
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    await this.axios.get(`${this.base_url}user-role/`).then((response) => {
      this.role_list = response.data;
    });
    await this.axios
      .get(`${this.base_url}groups/`, {
        params: {},
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        this.groups_list = response.data;
      });
    if (!this.branch.length) this.getBrenches({});
    if (this.id != undefined) {
      this.is_update = true;
      await this.axios
        .get(`${this.base_url}/userdata/${this.id}/`, {
          params: {},
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.user_data = response.data;
          this.user_data.password = undefined;
        });
    }
  },
  data() {
    return {
      role_list: [],
      groups_list: [],
      showPassword: false,
      user_data: {
        first_name: undefined,
        last_name: undefined,
        email: undefined,
        password: undefined,
        username: undefined,
        role: undefined,
        groups: undefined,
        is_active: false,
      },
      is_update: false,
      is_loading: false,
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
    }),
    roleList() {
      return this.role_list.filter(
        (item) => localStorage.getItem("role") < item.id && item.id < 4
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
    }),
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "school_user_list" });
      } else {
        this.$refs.form.reset();
        this.user_data.is_active = false;
        if (this.is_update) this.$router.push({ name: "school_add_user" });
      }
    },
    async saveUser() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}userdata/`, this.user_data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));
            this.resetForm();
          })
          .catch((error) => {
            try {
              if (error.response.data.email != undefined)
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.user_already_exists")
                );
              else
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.error_in_data")
                );
            } catch {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async updateUser() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.id != undefined) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}userdata/${this.id}/`, this.user_data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            setTimeout(() => {
              this.$router.push({ name: "school_user_list" });
            }, 1500);
          })
          .catch((error) => {
            try {
              if (error.response.data.email != undefined)
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.user_already_exists")
                );
              else
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.error_in_data")
                );
            } catch {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
