<template>
  <v-expansion-panels :model-value="0">
    <v-expansion-panel elevation="1">
      <v-expansion-panel-title class="px-4 text-black">
        <span>{{ $t("global.view_filters") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pt-4 bg-white">
        <v-form
          v-if="CheckGetPermission('school.add_studentattendance')"
          ref="form"
          @submit="getStudentsAttendance"
        >
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete
                color="indigo"
                v-model="filter_data.fk_class"
                density="compact"
                item-title="name_ar"
                item-value="id"
                clearable
                :items="classes"
                :label="$t('school.class.select')"
                prepend-inner-icon="mdi-google-classroom"
                @update:modelValue="
                  (filter_data.fk_division = null),
                    (filter_data.fk_subject = null)
                "
                :rules="[$required]"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                color="indigo"
                v-model="filter_data.fk_division"
                density="compact"
                item-title="name"
                item-value="id"
                clearable
                :items="class_divisions"
                :no-data-text="$t('school.division.not_found')"
                prepend-inner-icon="mdi-format-list-bulleted"
                :label="$t('school.division.select')"
                :rules="[$required]"
              />
            </v-col>
            <v-col cols="4" md="2" class="mt-2">
              <v-btn
                @click="getStudentsAttendance"
                class="bg-indigo"
                size="small"
                :loading="loading"
              >
                <span class="text-white">
                  {{ $t("global.filter") }}
                </span>
                <VIcon icon="mdi-filter" color="white" end></VIcon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card class="justify-center mt-2">
    <v-card-text>
      <v-card class="card-table">
        <v-table dense density="compact">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t("school.student.student_name") }}</th>
              <th>
                {{ $t("school.student_attendance.is_attendance") }}
              </th>
              <th v-if="attendanceTwice">
                {{ $t("school.student_attendance.after_brake") }}
              </th>
              <th>{{ $t("school.student_attendance.is_permitted") }}</th>
              <th>{{ $t("global.note") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="exist"
              v-for="(student, index) in students_attendance"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td style="min-width: 250px">{{ student.name }}</td>
              <td>
                <v-checkbox
                  style="min-width: 70px"
                  v-model="student.is_attendance"
                  color="indigo"
                  density="compact"
                  hide-details
                  @change="selectStudentData(index), getCheckThreeData(index)"
                >
                </v-checkbox>
                <!-- student.is_attendance ? cleanPermissionData() : true, -->
              </td>
              <td v-if="attendanceTwice">
                <v-checkbox
                  style="min-width: 70px"
                  v-model="student.after_brake"
                  color="indigo"
                  density="compact"
                  hide-details
                  @change="selectStudentData(index), getCheckThreeData(index)"
                >
                </v-checkbox>
                <!-- student.after_brake ? cleanPermissionData() : true, -->
              </td>
              <td>
                <div class="d-flex">
                  <v-checkbox
                    style="max-width: 40px"
                    v-model="student.is_permitted"
                    @click="
                      selectStudentData(index),
                        !student.is_permitted
                          ? (permission_dialog = true)
                          : false,
                        getCheckThreeData(index)
                    "
                    color="indigo"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                  <v-btn
                    v-if="student.is_permitted"
                    icon
                    variant="text"
                    density="compact"
                    class="mt-1"
                    @click="
                      setPermissionData(index),
                        (show_permission = true),
                        (permission_dialog = true)
                    "
                  >
                    <v-icon class="text-indigo">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </div>
              </td>
              <td>
                <v-text-field
                  style="min-width: 200px"
                  v-model="student.note"
                  density="compact"
                  :placeholder="$t('global.note')"
                  hide-details
                  class="my-1"
                  :rules="max_entry"
                >
                </v-text-field>
              </td>
            </tr>
            <tr else>
              <td colspan="5" class="text-center"></td>
            </tr>
          </tbody>
        </v-table>
      </v-card>

      <div
        class="text-center pa-2"
        v-if="exist && students_attendance.length == 0 && search_student != ''"
      >
        <span>{{ $t("global.student-not-found") }}</span>
      </div>
    </v-card-text>
    <v-card-actions
      v-if="
        exist &&
        students_attendance.length > 0 &&
        CheckGetPermission('school.add_studentattendance')
      "
      class="px-8"
    >
      <v-btn
        v-if="CheckGetPermission('school.add_studentattendance')"
        @click="saveStudentAttendance"
        class="bg-indigo"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.save") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn @click="cleanStudentAttendanceForm" class="mx-3" size="small">
        <span>
          {{ $t("global.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog
    v-model="permission_dialog"
    width="500"
    :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }"
    persistent
  >
    <v-card>
      <v-card-title>
        <h3 class="d-inline" style="padding: 0 4px">
          {{ $t("school.student_attendance.permission_data") }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </v-card-title>
      <!-- :style="{direction:$i18n.locale==='ar'?'rtl':'ltr'}" -->
      <v-card-text>
        <v-form @submit.prevent="savePermissionData" ref="permission">
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                style="min-width: 200px"
                :items="permission_type"
                prepend-inner-icon="mdi-text-short"
                color="indigo"
                v-model="permission_data.permission_type"
                :placeholder="$t('school.student_attendance.permission_type')"
                density="compact"
                item-title="name"
                item-value="id"
                clearable
                :rules="[$required]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                style="min-width: 200px"
                v-model="permission_data.permission_name"
                density="compact"
                prepend-inner-icon="mdi-account"
                :placeholder="$t('school.student_attendance.permission_name')"
                :rules="[$required, $max_length(100)]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                style="min-width: 150px"
                v-model="permission_data.permission_mobile"
                prepend-inner-icon="mdi-phone-classic"
                density="compact"
                :placeholder="$t('global.personal_information.phone_number')"
                :rules="[$required, $max_length(14), $numeric, $min_length(9)]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                style="min-width: 170px"
                v-model="permission_data.permission_reason"
                prepend-inner-icon="mdi-text-short"
                density="compact"
                :placeholder="$t('school.student_attendance.permission_reason')"
                :rules="[$required, $max_length(100)]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                style="min-width: 220px"
                v-model="permission_data.permission_time"
                density="compact"
                :placeholder="$t('school.student_attendance.permission_time')"
                clearable
                prepend-inner-icon="mdi-calendar-clock-outline"
                id="permission_time"
                :rules="[$required]"
              >
              </v-text-field>
              <Datetime
                type="datetime"
                element="permission_time"
                :color="'#0747a6'"
                @input="(e) => getPermissionTime(e)"
                :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                :placeholder="$t('school.student_attendance.permission_time')"
                :calendar="dateType"
              ></Datetime>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn class="bg-indigo" @click="savePermissionData()" size="small"
          ><span class="text-white">{{ $t("global.save") }}</span></v-btn
        >
        <v-btn v-if="show_permission" @click="permission_dialog = false">
          <span class="text-indigo">
            {{ $t("global.exit") }}
          </span>
        </v-btn>
        <v-btn
          v-if="!show_permission"
          @click="cleanPermissionData(), (permission_dialog = false)"
        >
          <span class="text-indigo">
            {{ $t("global.cancel") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
// import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
import { mapState, mapActions, mapGetters } from "vuex";
// const mobile_reg = helpers.regex(/^[0-9+]+$/);
export default {
  name: "StudentAttendance",
  props: {
    id: String,
  },
  components: { Datetime },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // if (!this.subjects.length) await this.getSubjects({});
      // await this.getAllTeachers();
      if (!this.classes.length) await this.getClasses();
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.academic_years.length) await this.getAcademicYears();
      if (!this.class_assign_hall.length) await this.getClassAssignHall(true);
      await this.getPermissionType();
      await this.getSemesters();
      // await this.getTeacherClass();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      after_break: false,
      permission_dialog: false,
      show_permission: false,
      schedules_data: [],
      v$: useValidate(),
      selected_student: null,
      showAlert: false,
      update: false,
      txt_search: null,
      search_student: "",
      user: null,
      exist: false,
      valid: false,
      date: null,
      user: null,
      filter_data: {},
      from_date: undefined,
      to_date: undefined,
      lectures_attendance: [],
      permission_data: {
        // permission_type: null,
        // permission_name: null,
        // permission_reason: null,
        // permission_mobile: null,
        // permission_time: null,
      },
      students: [],
      loading: false,
      is_loading: false,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      semesters: (state) => state.school.semesters,
      // teachers: (state) => state.school.teachers,
      // subjects: (state) => state.school.subjects,
      academic_years: (state) => state.school.academic_years,
      permission_type: (state) => state.globals.permission_type,
      // teacher_class: (state) => state.school.teacher_class,
      class_assign_hall: (state) => state.school.class_assign_hall,
    }),
    students_attendance() {
      if (this.search_student != "") {
        return this.students.filter((student) => {
          return student.name.includes(this.search_student);
        });
      }
      return this.students;
    },
    attendanceTwice() {
      return localStorage.getItem("attendance") == 2;
    },
    dateType() {
      return localStorage.getItem("date") == "h" ? "hijri" : "";
    },
    ...mapGetters({
      getDivisionName: "school/getDivisionName",
      getSubjectName: "school/getSubjectName",
      // getTeacherName: "school/getTeacherName",
      getClassDivisions: "school/getClassDivisions",
    }),
    // division_teacher() {
    //   if (this.filter_data.fk_division && this.filter_data.fk_class) {
    //     var teacher_id = this.teacher_class.results
    //       .filter((item) => {
    //         if (
    //           item.fk_class == this.filter_data.fk_class &&
    //           item.fk_division == this.filter_data.fk_division
    //         ) {
    //           return item;
    //         }
    //       })
    //       .map((item) => item.fk_teacher);
    //     var teacher_unique = [...new Set(teacher_id)];
    //     var teacher_data = [];
    //     teacher_unique.forEach((el) => {
    //       teacher_data.push({ id: el, name: this.getTeacherName(el) });
    //     });
    //   }
    //   return teacher_data;
    // },
    class_divisions() {
      if (this.filter_data.fk_class) {
        return this.getClassDivisions(this.filter_data.fk_class);
      }
    },
    //   teacher_subjects() {
    //     if (this.filter_data.fk_teacher) {
    //       var subject_id = this.teacher_class.results
    //         .filter(
    //           (item) =>
    //             item.fk_teacher === this.filter_data.fk_teacher &&
    //             item.fk_class == this.filter_data.fk_class
    //         )
    //         .map((item) => item.fk_subject);
    //       var subjects_uniqe = [...new Set(subject_id)];
    //       var subject_data = [];
    //       subjects_uniqe.forEach((el) => {
    //         subject_data.push({ id: el, name: this.getSubjectName(el) });
    //       });
    //     }
    //     return subject_data;
    //   },

    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      getClasses: "school/getClasses",
      // getSubjects: "school/getSubjects",
      // getAllTeachers: "school/getAllTeachers",
      // getTeacherClass: "school/getTeacherClass",
      getAcademicYears: "school/getAcademicYears",
      getPermissionType: "globals/getPermissionType",
      getClassAssignHall: "school/getClassAssignHall",
    }),

    getPermissionTime(e) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.permission_data.permission_time = date;
    },
    async savePermissionData() {
      const { valid } = await this.$refs.permission.validate();
      if (this.selected_student != null && valid) {
        this.selected_student.permission_type =
          this.permission_data.permission_type;
        this.selected_student.permission_name =
          this.permission_data.permission_name;
        this.selected_student.permission_reason =
          this.permission_data.permission_reason;
        this.selected_student.permission_time =
          this.permission_data.permission_time;
        this.selected_student.permission_mobile =
          this.permission_data.permission_mobile;
        this.permission_dialog = false;
        if (!this.attendanceTwice) this.selected_student.is_attendance = false;
        if (
          this.selected_student.is_attendance &
          this.selected_student.is_permitted &
          this.selected_student.after_brake
        ) {
          this.selected_student.is_permitted = false;
          this.selected_student.permission_type = null;
          this.selected_student.permission_name = null;
          this.selected_student.permission_reason = null;
          this.selected_student.permission_time = null;
          this.selected_student.permission_mobile = null;
          this.$emit(
            "errorAlert",
            this.$t("global.error.failure.three_choices_cannot_be_selected")
          );
        }
      }
    },
    setPermissionData(index) {
      this.selected_student = this.students[index];
      if (this.selected_student.is_permitted) {
        let student = this.students[index];
        this.permission_data.permission_type = student.permission_type;
        this.permission_data.permission_name = student.permission_name;
        this.permission_data.permission_time = student.permission_time;
        this.permission_data.permission_reason = student.permission_reason;
        this.permission_data.permission_mobile = student.permission_mobile;
      }
    },
    getCheckThreeData(index) {
      let item = this.students[index];
      if (
        item.is_attendance & item.is_permitted & item.after_brake ||
        item.is_attendance & item.is_permitted & !this.attendanceTwice
      ) {
        this.students[index].is_permitted = false;
        this.students[index].permission_type = null;
        this.students[index].permission_name = null;
        this.students[index].permission_reason = null;
        this.students[index].permission_time = null;
        this.students[index].permission_mobile = null;
        if (this.attendanceTwice)
          this.$emit(
            "errorAlert",
            this.$t("global.error.failure.three_choices_cannot_be_selected")
          );
      }
    },

    selectStudentData(index) {
      try {
        this.selected_student = this.students[index];
      } catch (error) {
        return 0;
      }
    },
    async getStudentsAttendance() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.lectures_attendance = [];
        await this.axios
          .get(`${this.base_url}api/student-attendance/list/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              fk_attendance: this.filter_data.fk_attendance,
              txt_search: this.txt_search,
              fk_class: this.filter_data.fk_class,
              fk_division: this.filter_data.fk_division,
            },
          })
          .then((response) => {
            this.students = response.data;
            // this.after_break = this.students.some((student) => student.is_attendance);
            response.data.forEach((el) => {
              this.lectures_attendance.push({ id: el.id, name: el.name });
            });
            this.exist = true;
          });
      } else {
        this.students = [];
      }
      this.loading = false;
    },
    cleanPermissionData() {
      if (this.selected_student != null) {
        this.selected_student.is_permitted = false;
        this.selected_student.permitted_after_break = false;
        this.selected_student.permission_type = null;
        this.selected_student.permission_mobile = null;
        this.selected_student.permission_time = null;
        this.selected_student.permission_name = null;
        this.selected_student.permission_reason = null;
      }
    },
    async saveStudentAttendance() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(
            `${this.base_url}api/student-attendance/multiple-update/`,
            this.students,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.insert"));
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("global.error.failure.insert"));
          });
      }
      this.is_loading = false;
    },
    cleanStudentAttendanceForm() {
      this.$refs.form.reset();
      this.exist = false;
    },
  },
  watch: {
    permission_dialog() {
      if (this.permission_dialog == false) {
        this.show_permission = false;
        this.permission_data = {};
      }
    },
  },
};
</script>
