<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes_by_branch"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_semester"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-book-education"
            :items="semesters"
            :label="$t('school.reports.select_semester')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
              <!-- <tr>
                <td>{{ $t("globals.number") }}</td>
                <td>{{ $t("school.student_name") }}</td>
                <td>{{ $t("class.class") }}</td>
                <td>{{ $t("subject.name") }}</td>
              </tr> -->
            </thead>
            <tbody v-if="!loading">
              <template v-for="(item, key) in items" :key="item.id">
                <tr
                  v-for="(subject, subIndex) in item?.subjects"
                  :key="subject"
                >
                  <template v-if="subIndex === 0">
                    <!-- <td
                      v-if="selectedHead.includes('no')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ index + 1 }}
                    </td> -->
                    <td
                      v-for="head in selectedHeaders?.slice(0, -1)"
                      :key="head.key"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      <template v-if="head.key == 'no'"
                        >{{ (page - 1) * perPage + key + 1 }}
                      </template>
                      <template v-else>
                        {{ item[head.key] ? item[head.key] : "-" }}
                      </template>
                    </td>
                    <!-- <td
                      v-if="selectedHead.includes('class_name')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ item?.class_name ?? "-" }}
                    </td>
                    <td
                      v-if="selectedHead.includes('student_name')"
                      :rowspan="item?.subjects?.length"
                      class="text-center border"
                    >
                      {{ item?.student_name ?? "-" }}
                    </td> -->
                  </template>

                  <td class="text-center border">
                    {{ subject ? subject : "-" }}
                  </td>
                </tr>
                <tr></tr>
              </template>
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student_name }}
                </td> -->
              <!-- <td
                  class="text-center border"
                  v-if="selectedHead.includes('class_name')"
                >
                  {{ item.class_name }}
                </td> -->
              <!-- <td class="border d-flex justify-center">
                  <span
                    v-for="(item2, index) in item.subjects"
                    :key="index"
                    class="d-block"
                    ><span class="px-1">{{ index === 0 ? "" : " - " }}</span
                    >{{ item2 }}</span
                  >
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[10]"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBrenches({});
      await this.getBranchClasses();
      //   await this.getYears();
      await this.getSemesters();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      //   school: [],
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   committees: [],
      //   v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      //   txt_search: "",
      selectedHead: [
        "no",
        "student_name",
        `${localStorage.getItem("role") < 2 ? "branch_name" : ""}`,
        "class_name",
        "subject_name",
        // "final_exam",
        // "collector",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.branch"), key: "branch_name" },
        { title: this.$t("school.reports.class_name"), key: "class_name" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: this.$t("school.reports.subject"), key: "subject_name" },
        // { title: this.$t("school.reports.final_exam"), key: "final_exam" },
        // { title: this.$t("school.reports.collector"), key: "collector" },
      ],
      perPage: 10,
      //   length: 0,
      totalItems: 0,
      //   itemsPerPage: [
      //     {
      //       value: 5,
      //       text: "5",
      //     },
      //     {
      //       value: 10,
      //       text: "10",
      //     },
      //     {
      //       value: 25,
      //       text: "25",
      //     },
      //     {
      //       value: 50,
      //       text: "50",
      //     },
      //     {
      //       value: 75,
      //       text: "75",
      //     },
      //     {
      //       value: 100,
      //       text: "100",
      //     },
      //     {
      //       value: "",
      //       text: "all",
      //     },
      //   ],
      page: 1,
      //   pagination: {},
      filter_data: {
        // year: null,
        // class_number: null,
        // semester: null,
      },
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      //   class_assign_hall: (state) => state.school.class_assign_hall,
      classes_by_branch: (state) => state.school.classes_by_branch,
      semesters: (state) => state.school.semesters,
      branch: (state) => state.school.branch,
      //   user: (state) => state.User,
    }),
    ...mapGetters({
      //   getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      let filtered = this.selectedHead.filter((header) => {
        if (header === "branch_name") {
          return localStorage?.getItem("role") < 2
            ? !this.filter_data.fk_branch
            : false;
        }
        if (header === "class_name") {
          return !this.filter_data.fk_class;
        }
        return true;
      });
      return this.headers.filter((header) => filtered.includes(header.key));
    },
    // filteredSelectedHeaders() {
    //   return this.selectedHeaders?.filter(
    //     (header) =>
    //       (this.filter_data.fk_class ? header.key != "class_name" : true) &&
    //       (this.filter_data.fk_branch ? header.key != "branch_name" : true)
    //   );
    // },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getSemesters: "school/getSemesters",
      getBrenches: "school/getBrenches",
    }),
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getClassName(class_id) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === class_id
    //   );
    //   return objectClass.name_ar;
    // },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    // getSemesterName(semester_id) {
    //   if (semester_id) {
    //     const objectSemester = this.semesters.find(
    //       (objectSemester) => objectSemester.id === semester_id
    //     );
    //     return objectSemester.name_ar;
    //   }
    //   return "-------------";
    // },
    async getData(page = 1) {
      // console.log('load data ')
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/subject_not_assigned`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: page,
            page_size: this.perPage,
            class_number: this.filter_data.fk_class?.id,
            semester: this.filter_data.fk_semester?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // year: localStorage.getItem("current_year"),
          },
        })
        .then((response) => {
          if (response.data) {
            this.items = response.data?.results ?? response.data;
            this.totalItems = response.data?.pagination?.count;
            this.exist = true;
            // this.totalItems = response.data.pagination.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
