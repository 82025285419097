<template>
  <VCard class="card-table">
    <!-- ##########    data Table  ########### -->
    <!-- ######## Header Of Data Table  ####### -->
      <div>
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="outlined"
                color="#ccc"
              >
                <v-icon style="color: #5e7e96" class="me-2"
                  >mdi-eye-outline</v-icon
                >
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-text-field
            color="indigo"
            v-model="text_search"
            @keyup.enter="text_search ? (search = text_search) : ''"
            @input="!text_search ? (search = text_search) : ''"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        <div class="d-flex justify-end" style="flex: 1">
          <VBtn
            v-if="CheckGetPermission('bus_system.add_bus')"
            class="bg-success"
            @click="addBus"
          >
            <span class="text-white">{{ $t("global.create") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <!-- <v-progress-linear
        color="indigo "
        class="mb-1"
        :indeterminate="loading"
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear> -->
      <v-data-table
        :items-per-page="perPage"
        v-model:sort-by="sortBy"
        @update:options="getBus((page = 1))"
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('bus_system.change_bus')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editBus(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('bus_system.delete_bus')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="(busPlan_del_id = item.raw.id), (del_dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ totalItems }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (busPlan_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteBus">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      del_dialog: false,
      busPlan_del_id: undefined,
      loading: false,
      perPage: 10,
      length: 0,
      buses: [],
      page: 1,
      pagination: {},
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: "all", text: "" },
      ],
      headers: [
        { title: this.$t("school.bus_data.teacher_name"), key: "fk_teacher" },
        { title: this.$t("school.bus_data.driver_name"), key: "fk_driver" },
        { title: this.$t("school.bus_data.driver_road"), key: "itinerary" },
        { title: this.$t("school.bus_data.type"), key: "bus_type" },
        { title: this.$t("school.bus_data.number"), key: "number" },
        { title: this.$t("school.bus_data.seat_number"), key: "seat_number" },
        { title: this.$t("school.bus_data.color"), key: "color" },
        { title: this.$t("school.bus_data.car_card"), key: "car_card" },
        {
          title: this.$t("school.driver_data.card_issuance"),
          key: "card_issuance",
        },
        {
          title: this.$t("school.driver_data.card_expiration"),
          key: "card_expiration",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "fk_teacher",
        "fk_driver",
        "itinerary",
        "bus_type",
        "number",
        "actions",
      ],
      text_search: undefined,
      search: null,
      sortBy: [{ key: "name", order: "asc" }],
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.teachers.length == 0) this.getAllTeachers();
      if (this.drivers.length == 0) this.getAllDrivers();
      await this.getBus();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      teachers: (state) => state.school.teachers,
      drivers: (state) => state.school.drivers,
      current_page: (state) => state.school.current_page,
      items_count: (state) => state.school.items_count,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    tableList() {
      let list = [];
      this.buses.forEach((element) => {
        let fk_teacher = this.teachers.find(
          (item) => item.id == element.fk_teacher
        );
        if (fk_teacher != undefined) fk_teacher = fk_teacher.name_ar;

        let fk_driver = this.drivers.find(
          (item) => item.id == element.fk_driver
        );
        if (fk_driver != undefined) fk_driver = fk_driver.driver_name;

        list.push({
          fk_teacher: fk_teacher,
          fk_driver: fk_driver,
          itinerary: element.itinerary,
          bus_type: element.bus_type,
          number: element.number,
          seat_number: element.seat_number,
          color: element.color,
          car_card: element.car_card,
          card_issuance: element.card_issuance,
          card_expiration: element.card_expiration,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAllTeachers: "school/getAllTeachers",
      getAllDrivers: "school/getAllDrivers",
      changeCurrentPage: "school/changeCurrentPage",
    }),
    addBus() {
      this.$router.push("add");
    },
    editBus(id) {
      this.changeCurrentPage({ page: this.page, count: this.perPage });
      this.$router.push(`${id}/edit/`);
    },
    async deleteBus() {
      if (this.busPlan_del_id != undefined) {
        await this.axios
          .delete(`${this.base_url}/bus/${this.busPlan_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getBus();
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.busPlan_del_id = undefined;
      }
    },
    async getBus(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}/bus/`, {
        params: {
          search: this.text_search,
          page: page,
          page_size: this.perPage,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.buses = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.loading = false;
          this.itemsPerPage[5].value = response.data.pagination.count;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getBus();
    },
    page() {
      this.getBus(this.page);
    },
  },
};
</script>
