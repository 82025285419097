<template>
  <v-card class="justify-center" :loading="is_loading">
    <v-card-text class="card-fields">
      <VForm v-if="CheckGetPermission('school.add_studentfee')" ref="form">
        <VRow>
          <VCol cols="12" md="4">
            <VAutocomplete
              color="indigo"
              v-model="student_fee.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              :items="classes"
              :label="$t('school.class.select')"
              @update:modelValue="(student_list = []),getstudetns(), (fk_division = null)"
              :rules="[$required]"
            ></VAutocomplete>
          </VCol>
          <VCol cols="12" md="4">
            <VAutocomplete
              color="indigo"
              v-model="fk_division"
              density="compact"
              item-title="name"
              :label="$t('school.division.select')"
              :no-data-text="$t('school.division.not_found')"
              item-value="id"
              prepend-inner-icon="mdi-format-list-bulleted"
              :items="getClassByDivisions(student_fee.fk_class)"
              @update:modelValue="(student_list = []), getstudetns()"
            ></VAutocomplete>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" md="8">
            <VAutocomplete
              color="indigo"
              v-model="student_fee.fk_student"
              density="compact"
              item-title="name_ar"
              item-value="id"
              :items="student_list"
              clearable
              prepend-inner-icon="mdi-school"
              :label="$t('school.student.name')"
              :rules="[$required]"
              @update:modelValue="(student_fee.fees =[{}]),(is_selected = false), getStudentData()"
            />
          </VCol>
          <!-- <VCol cols="6" md="2">
                                <VBtn class="bg-indigo" @click="getStudentData" size="small">
                                    <span class="text-white">
                                        {{$t('globals.show')}}
                                    </span>
                                    <VIcon icon="mdi-presentation" color="white" end></VIcon>
                                </VBtn>
                            </VCol> -->
        </VRow>
        <!-- <small v-if="!checkSchedules&&!update" >shehab alfareh</small> -->
        <v-expand-transition>
          <div v-show="student_fee.fk_student ? is_selected : false">
            <VCard class="mt-4 pt-6 px-5">
              <VRow
                class="pt-0 pb-0"
                v-for="(fee, index) in student_fee.fees"
                :key="fee"
              >
                <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
                  <VCardItem class="pa-0">
                    <VAutocomplete
                      v-model="fee.fk_fees_type"
                      item-value="id"
                      item-title="name"
                      :items="
                        // fee_types
                        //   ? fee_types.filter((type) => type.subscribe_type >= 3)
                        //   : []
                        fee_types_list
                      "
                      :label="$t('school.fees.fee_type')"
                      persistent-hint
                      density="compact"
                      :disabled="fee.id != undefined"
                      @update:modelValue="
                        (fee.installments_number = null),
                          (fee.fk_discount_category = null),
                          (fee.discount_value = null),
                          (fee.after_discount = null),
                          getFeeValue(fee.fk_fees_type, index),
                          (fee.after_discount = fee.fee_amount)
                      "
                      :rules="[$required]"
                    ></VAutocomplete>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="fee.fee_amount"
                      :label="$t('school.fees.fee')"
                      density="compact"
                      disabled
                      :rules="[$required]"
                    />
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="fee.installments_number"
                      :label="$t('school.student_fees.installments_number')"
                      :onkeypress="$only_numbers"
                      :placeholder="
                        $t(
                          'school.student_fees.installments_number_placehloder'
                        )
                      "
                      density="compact"
                      clearable
                      :disabled="
                        !fee.installment_available || fee.id != undefined
                      "
                      :rules="
                        fee.installment_available
                          ? [$required, $max_value(10)]
                          : ''
                      "
                    >
                    </VTextField>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
                  <VCardItem class="pa-0">
                    <VAutocomplete
                      v-model="fee.fk_discount_category"
                      item-value="id"
                      item-title="category"
                      :items="discounts"
                      :label="$t('school.fees.discount_type')"
                      persistent-hint
                      clearable
                      :disabled="!fee.reducible || fee.id != undefined"
                      @update:modelValue="
                        getDiscountValue(fee.fk_discount_category, index),
                          afterDiscount(fee)
                      "
                      density="compact"
                    ></VAutocomplete>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="fee.discount_value"
                      :label="$t('school.fees.discount_amount')"
                      density="compact"
                      type="number"
                      :disabled="!fee.reducible || fee.id != undefined"
                      :append-inner-icon="
                        fee.type == 1
                          ? 'mdi-percent-outline'
                          : 'mdi-currency-rial'
                      "
                      :rules="[
                        $numeric,
                        (value) =>
                          value == undefined ||
                          (value <= 100 && value >= 0 && fee.type == 1) ||
                          (value <= fee.fee_amount &&
                            value >= 0 &&
                            fee.type == 2) ||
                          this.$t('global.error.validation.discount_is_wrong'),
                      ]"
                      @update:model-value="afterDiscount(fee)"
                    >
                    </VTextField>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
                  <!-- v-if="fee.discount_value" -->
                  <VTextField
                    v-model="fee.after_discount"
                    :label="$t('school.fees.after_discount')"
                    :append-inner-icon="'mdi-currency-rial'"
                    density="compact"
                    type="number"
                    clearable
                    disabled
                  >
                  </VTextField>
                </VCol>
                <!-- <VCol class="pt-0 px-3" cols="1" xs="1" sm="1" md="1" lg="1">
                  <VCardItem class="pt-5 px-0">
                    <h3 v-if="fee.type == 1">%</h3>
                    <h3 v-if="fee.type == 2">YR</h3>
                  </VCardItem>
                </VCol> -->
              </VRow>
              <VRow
                class="mt-4 mx-10 d-flex flex-wrap"
                style="gap: 1.5rem"
                elevation="4"
              >
                <VBtn
                  variant="text"
                  size="x-small"
                  class="text-success"
                  icon="mdi-plus-thick"
                  @click.prevent="addFee()"
                >
                  <!-- icon="mdi-plus-thick" -->
                  <v-icon color="success"></v-icon>
                </VBtn>
                <VBtn
                  v-if="student_fee.fees?.length > 1"
                  variant="text"
                  size="x-small"
                  class="text-error"
                  icon="mdi-minus-thick"
                  @click.prevent="removeFee()"
                >
                </VBtn>
                <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="6" lg="6">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="student_fee.days_between_installment"
                      prepend-inner-icon="mdi-numeric"
                      :label="$t('school.student_fees.days_between_installments')"
                      :placeholder="$t('school.student_fees.days_between_installments_placehloder')"
                      density="compact"
                      clearable
                      type="number"
                      :rules="[$min_value(1), $max_value(100)]"
                    >
                    </VTextField>
                  </VCardItem>
                </VCol>
              </VRow>
            </VCard>
          </div>
        </v-expand-transition>
      </VForm>
    </v-card-text>
    <v-card-actions
      v-if="CheckGetPermission('school.add_studentfee')"
      class="px-8"
    >
      <v-btn
        v-if="CheckGetPermission('school.add_studentfee')"
        @click="saveStudentData"
        class="bg-indigo"
        size="small"
        :loading="is_loading"
        :disabled="!is_selected"
      >
        <span class="text-white">
          {{ $t("global.save") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <!-- <v-btn
        v-if="update"
        @click="updateMarks"
        class="bg-success"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn> -->
      <v-btn @click="cleanMarks" class="mx-3" size="small">
        <span>
          {{ $t("global.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
    <!-- </v-card> -->
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
    >
</Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      if (!this.classes.length) await this.getClasses();
      if (!this.fees.length) await this.getFees();
      if (!this.fee_types.length) await this.getFeeTypes();
      if (!this.discounts.length) await this.getDiscount();

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      // v$: useValidate(),
      is_selected: false,
      is_loading: false,
      fk_division: undefined,
      student_fee: {
        fk_class: undefined,
        fk_student: undefined,
        first_installments_date: undefined,
        days_between_installment: undefined,
        data_entry: undefined,
        fees: [
          {},
          //{
          // fk_discount_category:undefined,
          // fk_fees_type:undefined,
          // installments_number:undefined,
          // discount_value:undefined,
          // fee_amount:undefined,
          // type:undefined,}
        ],
      },
      after_discount: null,
      student_list: [],

      // intallment_rule:[
      //     // (value) => !!value || this.$t('globals.required_field'),
      //     (value) => (value==undefined || /^[0-9]+$/.test(value)) || this.$t('globals.must_be_numbers'),
      //     (value) => (value==undefined || value <= 10) || `${this.$t('globals.biggest_number_of_character')} 10`,
      //     (value) => (value==undefined || value >= 0) || `${this.$t('globals.lowest_number_of_character')} 0`,
      //     ],
      // amount_rule:[
      //     (value) => !!value || this.$t('globals.required_field'),
      //     ],
      // process_alert:undefined,
      // icon_color:undefined,
      // icon:undefined,
      // alert_title:undefined,
      // alert_message:undefined,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      fee_types: (state) => state.school.fee_types,
      fees: (state) => state.school.fees,
      discounts: (state) => state.school.discounts,
      // user: (state) => state.User,
    }),
    ...mapGetters({
      getClassByDivisions: "school/getClassDivisions",
    }),
    fee_types_list() {
      let list = [];
      this.fee_types.forEach((element) => {
        if (element.subscribe_type >= 3) {
          let fee = this.fees.find(
            (item) =>
              item.fk_fees_type == element.id &&
              item.fk_class == this.student_fee.fk_class
          );
          if (fee && fee.fee) list.push(element);
        }
      });
      return list;
    },
    class_subjects() {
      if (this.student_fee.fk_class) {
        var class_data = this.classes.find(
          (item) => item.id == this.student_fee.fk_class
        );
        var subjects_data = [];
        if (class_data)
          class_data.subjects.forEach((element) => {
            subjects_data.push({
              id: element,
              name: this.getSubjectName(element),
            });
          });
      }
      return subjects_data;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getClassAssignHall: "school/getClassAssignHall",
      getFeeTypes: "school/getFeeTypes",
      getFees: "school/getFees",
      getDiscount: "school/getDiscount",
    }),
    addFee() {
      this.student_fee.fees.push({
        fk_discount_category: undefined,
        fk_fees_type: undefined,
        installments_number: undefined,
        discount_value: undefined,
        fee_amount: undefined,
        type: undefined,
        reducible: true,
        installment_available: true,
      });
    },
    removeFee() {
      // if(this.student_fee.fees[this.student_fee.fees.length-1].fk_fees_type==undefined)
      if (this.student_fee.fees.length) this.student_fee.fees.pop();
    },
    getDiscountValue(id, index) {
      let discount = this.discounts.find((dic) => dic.id == id);
      if (discount) {
        this.student_fee.fees[index].discount_value = discount.amount;
        this.student_fee.fees[index].type = discount.amount_type;
      } else {
        this.student_fee.fees[index].discount_value = undefined;
        this.student_fee.fees[index].type = undefined;
      }
    },
    getFeeValue(id, index) {
      let fee = this.fees.find(
        (fee) =>
          fee.fk_fees_type == id && fee.fk_class == this.student_fee.fk_class
      );
      let feetype = this.fee_types.find((fee) => fee.id == id);
      if (fee) this.student_fee.fees[index].fee_amount = fee.fee;
      else this.student_fee.fees[index].fee_amount = undefined;
      if(this.student_fee.fees[index] && feetype){
      this.student_fee.fees[index].installment_available =
        feetype.installment_available;
      this.student_fee.fees[index].reducible = feetype.reducible;
    }
    },
    async getstudetns() {
      this.student_fee.fk_student = undefined;
      // if(localStorage.getItem('current_year')!=undefined)
      // {
      let list = await this.axios
        .get(`${this.base_url}student-list/`, {
          params: {
            class: this.student_fee.fk_class,
            division: this.fk_division,
            // year:localStorage.getItem('current_year')
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.student_list = response.data;
        });
      // }
    },
    async getStudentData() {
      // this.v$.$validate()
      this.is_loading = true;
      // let list = await this.axios.get(`${this.base_url}/student-fee/`, {
      //   params: {
      //     // fk_class:this.student_fee.fk_class,
      //     // fk_year:localStorage.getItem('current_year'),
      //     fk_student: this.student_fee.fk_student,
      //   },
      //   headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      // });
      // this.student_fee.fees = [];
      // if (list.length) {
      //   list.forEach((element) => {
      //     let fee = this.fees.find((fee) => fee.id == element.fk_fee);
      //     let fee_type = this.fee_types.map(
      //       (item) => item.id == fee.fk_fees_type
      //     );
      //     this.student_fee.fees.push({
      //       id: element.id,
      //       fk_discount_category: element.fk_discount_category,
      //       fk_fees_type: fee.fk_fees_type,
      //       installments_number: element.installments_number,
      //       discount_value:
      //         element.discount_value == 0 ? undefined : element.discount_value,
      //       fee_amount: fee.fee,
      //       type:
      //         element.discount_value == 0
      //           ? 0
      //           : element.discount_value > 100
      //           ? 2
      //           : 1,
      //       reducible: fee_type.reducible,
      //       installment_available: fee_type.installment_available,
      //     });
      //     if (element.days_between_installment)
      //       this.student_fee.days_between_installment =
      //         element.days_between_installment;
      //   });
      // }
      // if (!this.student_fee.fees.length)
      //   this.student_fee.fees.push({
      //     fk_discount_category: undefined,
      //     fk_fees_type: undefined,
      //     installments_number: undefined,
      //     discount_value: undefined,
      //     fee_amount: undefined,
      //     type: undefined,
      //     reducible: true,
      //     installment_available: true,
      //   });
      this.is_selected = true;
      this.is_loading = false;
    },
    async saveStudentData() {
      // this.v$.$validate()
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        // this.student_fee.data_entry=this.user
        // this.student_fee.fk_year=localStorage.getItem('current_year')
        await this.axios
          .post(`${this.base_url}student-fee/`, this.student_fee, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.getStudentData();
            this.cleanMarks()
          })
          .catch((error) => {
            if (error.response.data.non_field_errors) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.unique")
              );
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });

        this.is_loading = false;
      }
    },
    afterDiscount(item) {
      if (item.discount_value && item.fee_amount) {
        item.after_discount =
          item.fee_amount -
          (item.type == 1
            ? (item.fee_amount * item.discount_value) / 100
            : item.discount_value);
      }
    },
    cleanMarks() {
      // this.v$.student_fee.$reset()
      this.$refs.form.reset();
    },
  },
};
</script>
