<template>
  <!-- filter data  -->

  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-text-field
            color="indigo"
            v-model="filter_data.fk_paid"
            density="compact"
            item-value="id"
            prepend-inner-icon="mdi-cash"
            type="number"
            :label="$t('school.reports.amount')"
            hide-details="auto"
            :rules="[$required, $max_value(10000000)]"
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>
    <!-- <h2 class="text-center text-grey-darken-2">
      
    </h2> -->
    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card class="" :loading="loading" v-if="items.length > 0">
          <table
            style="width: 100%; border-collapse: collapse"
            class="table-striped"
          >
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  v-for="(header, index) in selectedHeaders"
                  :class="`border px-3 text-center`"
                  :key="index"
                >
                  <template v-if="index === 0">
                    {{ (page - 1) * perPage + key + 1 }}
                  </template>
                  <template v-else-if="header.key == 'class'">
                    {{ item.class }} - {{ item.division }}
                  </template>
                  <template v-else-if="header.key == 'total'">
                    {{ (item.still + item.paid_amount)?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                  </template>
                  <template v-else>
                    {{
                      item[header.key] || item[header.key] == 0
                        ? item[header.key]
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-"
                    }}
                  </template>
                </td>
              </tr>
              <!-- <tr v-for="(item, index) in items" :key="index">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ index + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('class')"
                >
                  {{ item.class }} - {{ item.division }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('fee')"
                >
                  {{ item.fee }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('total')"
                >
                  {{ item.still + item.paid_amount }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('paid_amount')"
                >
                  {{ item.paid_amount }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('still')"
                >
                  {{ item.still }}
                </td>
              </tr> -->
              <tr>
                <th
                  :colspan="colSpan()"
                  class="px-2 text-center"
                  v-if="colSpan()"
                >
                  {{ $t("school.reports.total") }}
                </th>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('fee')"
                >
                  {{ getTotalFee()?.toLocaleString("en-US") }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('total')"
                >
                  {{ getTotal()?.toLocaleString("en-US") }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('paid_amount')"
                >
                  {{ getTotalCredit()?.toLocaleString("en-US") }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('still')"
                >
                  {{ getTotalDebit()?.toLocaleString("en-US") }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <span class="mt-2">{{ $t("school.reports.showing") }}</span>
              <v-autocomplete
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-autocomplete>
              <span class="mt-2"
                >{{ " " + $t("school.reports.from") + " " }} [
                {{ items?.length }} ]
                {{ " " + $t("school.reports.item") + " " }}</span
              >
            </div>
          </div> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // await this.getBranchClasses();
      await this.getBrenches({});
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      //   fees_types: [],
      students: [],
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      //   txt_search: "",
      selectedHead: [
        "no",
        "student_name",
        "class",
        "paid_amount",
        "still",
        "total",
        "fee",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: this.$t("school.reports.class"), key: "class" },
        { title: this.$t("school.reports.fee"), key: "fee" },
        { title: this.$t("school.reports.total_after_discount"), key: "total" },
        { title: this.$t("school.reports.paid"), key: "paid_amount" },
        { title: this.$t("school.reports.still"), key: "still" },
      ],
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: 1000000, text: this.$t("school.reports.all") },
      ],
      page: 1,
      pagination: {},
      //   limit: 10000000,
      //   maxRules: [
      //     (v) => {
      //       if (v > this.limit) {
      //         return "error";
      //       }
      //     },
      //   ],
      filter_data: {
        // fk_class: null,
        // fk_year: null,
        // fk_fee: null,
        // fk_division: null,
        fk_paid: null,
      },
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
      //   classes: (state) => state.school.classes,
      //   class_assign_hall: (state) => state.school.class_assign_hall,
      //   academic_years: (state) => state.school.academic_years,
      //   divisions: (state) => state.school.divisions,
      //   semesters: (state) => state.school.semesters,
      //   subjects: (state) => state.school.subjects,
      //   user: (state) => state.User,
    }),

    ...mapGetters({
      //   getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
      //   getBranchClasses: "school/getBranchClasses",
      //   getAcademicYears: "school/getAcademicYears",
      //   getDivisions: "school/getDivisions",
      //   getClassAssignHall: "school/getClassAssignHall",
    }),

    getTotalDebit() {
      let total = 0;
      this.items.forEach((element) => {
        total += element.still;
      });
      return total;
    },
    getTotalCredit() {
      let total = 0;
      this.items.forEach((element) => {
        total += element.paid_amount;
      });
      return total;
    },
    getTotalFee() {
      let total = 0;
      this.items.forEach((element) => {
        total += element.fee;
      });
      return total;
    },
    getTotal() {
      let total = 0;
      this.items.forEach((element) => {
        total += element.paid_amount;
        total += element.still;
      });
      return total;
    },

    // getdivisionName(fk_division) {
    //   if (fk_division) {
    //     const division = this.divisions.find(
    //       (division) => division.id === fk_division
    //     );
    //     return division.name;
    //   }
    //   return "===========";
    // },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },

    // async getFeeType() {
    //   await this.axios
    //     .get(`${this.base_url}fee-types/`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       this.fees_types = response.data;
    //     })
    //     .catch((error) => {
    //       // console.log(error);
    //     });
    // },
    // getParentName(fk_year) {
    //   const year = this.parents.find((year) => year.id === fk_year);
    //   return year.name;
    // },
    // getClassName(fk_class) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name_en;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },

    async getData() {
      this.loading = true;

      await this.axios
        .get(`${this.base_url}school/report/students/by_amount`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: 1,
            page_size: this.perPage,
            // fk_year: this.filter_data.fk_year,
            paid_amount: this.filter_data.fk_paid,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
    colSpan() {
      const wordsToCheck = ["no", "student_name", "class"];
      const matchWords = wordsToCheck.filter((word) =>
        this.selectedHead.includes(word)
      );

      return matchWords?.length ?? null;
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
