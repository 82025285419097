<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="12" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes_by_branch"
            :label="$t('school.reports.select_class')"
            @update:modelValue="filter_data.fk_subject = null"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <!-- <v-col cols="12" :md="getUserRole < 2 ? '5' : '3'" sm="12">
                <v-autocomplete
                    color="indigo"
                    v-model="filter_data.fk_division"
                    density="compact"
                    item-title="name"
                    item-value="id"
                    prepend-inner-icon="mdi-chair"
                    :items="class_divisions"
                    :label="$t('division.select')"
                    hide-details
                    
                />
            </v-col> -->
        <v-col cols="12" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_subject"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-bookshelf"
            :items="class_subjects"
            :label="$t('school.reports.select_subject')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <!-- <v-col cols="12" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_semester"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-book-education"
            :items="semesters"
            :label="$t('school.reports.select_semester')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col cols="12" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_month"
            density="compact"
            :item-title="date_type == 'm' ? 'name_m' : 'name_h'"
            item-value="id"
            prepend-inner-icon="mdi-calendar-month"
            :items="getCurrentSemesterMonths"
            :label="$t('school.reports.select_month')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th
                v-for="header in selectedHeaders"
                :key="header.key"
                :style="{ fontSize: $i18n.locale === 'en' ? '0.9rem' : '' }"
              >
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  v-for="(header, index) in selectedHeaders"
                  :class="`border px-3 ${
                    header.key == 'student_name' ? '' : 'text-center'
                  }`"
                  :key="index"
                >
                  <template v-if="index === 0">
                    {{ (page - 1) * perPage + key + 1 }}
                  </template>
                  <template v-else>
                    {{ item[header.key] != null ? item[header.key] : "-" }}
                  </template>
                </td>
              </tr>
              <!-- <tr v-for="(item, index) in items" :key="item.id">
                <td
                  v-if="selectedHead.includes('no')"
                  class="text-center border"
                >
                  {{ (page - 1) * perPage + index + 1 }}
                </td>
                <td
                  v-for="header in selectedHeaders?.filter(
                    (head) => head.key != 'no'
                  )"
                  :key="header.key"
                  class="text-center border"
                >
                  {{ item[header.key] ? item[header.key] : "-" }}
                </td>
              </tr> -->
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student.name_ar }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('exam')"
                >
                  {{ item.exam }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('oral')"
                >
                  {{ item.oral }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('assigments')"
                >
                  {{ item.assigments }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('attendance')"
                >
                  {{ item.attendance }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('estimation')"
                >
                  {{ item.estimation }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('note')"
                >
                  {{ item.note }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // await this.getAcademicYears();
      await this.getBranchClasses();
      await this.getSemesters();
      await this.getSubjects({});
      await this.getBrenches({});
      // await this.getattendances();
      // await this.getDivisions({});
      await this.getAllMonths();
      // await this.getClassAssignHall();
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // school: [],
      // branch: {},
      // year_data: localStorage.getItem("current_year_name_h"),
      // current_month: null,
      // attendances: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      date_type: localStorage.getItem("date"),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: [
        "no",
        "student_name",
        "branch_name",
        "exam",
        "oral",
        "assignments",
        "attendance",
        "total",
        "estimation_name",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: this.$t("school.reports.branch"), key: "branch_name" },

        { title: this.$t("school.reports.attendance"), key: "attendance" },
        { title: this.$t("school.reports.assigments"), key: "assignments" },
        { title: this.$t("school.reports.oral"), key: "oral" },
        { title: this.$t("school.reports.exam"), key: "exam" },
        { title: this.$t("school.reports.mark_total"), key: "total" },
        { title: this.$t("school.reports.estimate"), key: "estimation_name" },
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   {
      //     value: 5,
      //     text: "5",
      //   },
      //   {
      //     value: 10,
      //     text: "10",
      //   },
      //   {
      //     value: 25,
      //     text: "25",
      //   },
      //   {
      //     value: 50,
      //     text: "50",
      //   },
      //   {
      //     value: 75,
      //     text: "75",
      //   },
      //   {
      //     value: 100,
      //     text: "100",
      //   },
      //   {
      //     value: "",
      //     text: "all",
      //   },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        // fk_division: null,
        fk_month: {
          name_m: localStorage.getItem("month_m"),
          name_h: localStorage.getItem("month_h"),
        },
        fk_class: null,
        fk_subject: null,
        fk_semester: null,
      },
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      // class_assign_hall: (state) => state.school.class_assign_hall,
      classes_by_branch: (state) => state.school.classes_by_branch,
      months: (state) => state.school.months,
      semesters: (state) => state.school.semesters,
      subjects: (state) => state.school.subjects,
      branch: (state) => state.school.branch,
      // months: (state) => state.school.months,
    }),
    // class_divisions() {
    //   if (this.filter_data.fk_class) {
    //     return this.getClassDivisions(this.filter_data.fk_class);
    //   }
    // },
    ...mapGetters({
      getCurrentSemesterMonths: "school/getCurrentSemesterMonths",
    }),

    selectedHeaders() {
      let filtered = this.selectedHead.filter((header) => {
        if (header === "branch_name") {
          return localStorage?.getItem("role") < 2
            ? !this.filter_data.fk_branch
            : false;
        }
        return true;
      });
      return this.headers.filter((header) => filtered.includes(header.key));
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
    class_subjects() {
      if (this.filter_data.fk_class?.id) {
        var class_data = this.classes_by_branch.find(
          (item) => item.id == this.filter_data.fk_class?.id
        );
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name_ar: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      // getAcademicYears: "school/getAcademicYears",
      // getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      // getClassAssignHall: "school/getClassAssignHall",
      getSubjects: "school/getSubjects",
      getAllMonths: "school/getAllMonths",
      getBrenches: "school/getBrenches",
    }),
    // getMonthName(id) {
    //   const month = this.months.find((el) => el.id == id);
    //   if (month) {
    //     return month.name;
    //   }
    // },
    // async getattendances() {
    //   await this.axios
    //     .get(`${this.base_url}school/report/student/absent`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       this.attendances = response.data.results;
    //     });
    // },

    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getClassName(fk_class) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name_ar;
    // },
    // getSemesterName(fk_semester) {
    //   const year = this.semesters.find((year) => year.id === fk_semester);
    //   return year.name_ar;
    // },
    // getSubjectName(fk_subject) {
    //   const year = this.subjects.find((year) => year.id === fk_subject);
    //   return year.name_ar;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        if (!this.filter_data.fk_month) {
          this.filter_data.fk_month = {
            name_m: localStorage.getItem("month_m"),
            name_h: localStorage.getItem("month_h"),
          };
        }
        this.getData();
      }
    },
    // async printTable() {
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/students/marks`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: page,
            page_size: this.perPage,
            fk_month: this.filter_data.fk_month?.id,
            fk_class: this.filter_data.fk_class?.id,
            fk_subject: this.filter_data.fk_subject?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // semester: this.filter_data.fk_semester?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data?.results;
            this.totalItems = response.data?.pagination?.count;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
    // async getCurrentManth() {
    //   await this.axios
    //     .get(`${this.base_url}api/get-month`, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       this.current_month = response.data.id;
    //     });
    // },
    getSubjectName(id) {
      var subject = this.subjects.find((item) => item.id == id);
      return subject?.name_ar;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       class_number: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       subject: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //       semester: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
.head {
  font-size: 1.1rem;
}
.text {
  font-size: 0.85rem;
}
</style>
