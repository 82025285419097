<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_division = null),
                (filter_data.fk_student = null),
                (student_list = []),
                getClassAssignHall(filter_data.fk_branch?.id),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="
              (filter_data.fk_division = null),
                (filter_data.fk_student = null),
                (student_list = [])
            "
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            :no-data-text="$t('school.division.not_found')"
            prepend-inner-icon="mdi-chair-school"
            :items="
              filter_data.fk_class?.id
                ? getClassDivisions(filter_data.fk_class?.id, getUserRole < 2)
                : []
            "
            :label="$t('school.reports.select_division')"
            @update:model-value="
              (filter_data.fk_student = null),
                (student_list = []),
                getStudents()
            "
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <!-- <v-col cols="12" md="3" sm="12" >
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:model-value="
              (filter_data.division = null), (filter_data.student = null)
            "
          />
        </v-col>
        <v-col cols="12" md="3" sm="12" >
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-chair-school"
            :items="class_divisions"
            :label="$t('school.reports.select_division')"
            @update:model-value="getStudents(), (filter_data.student = null)"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col cols="12" :md="getUserRole < 2 ? '5' : '3'" sm="12">
          <v-autocomplete
            ref="select"
            color="indigo"
            v-model="filter_data.fk_student"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-school"
            :items="student_list"
            :label="$t('school.reports.student_name')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header
      en
      rep_en
      :data="filter_data"
      :title="$t('school.reports.Specific_Year_Certificate_En_en')"
    ></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <v-row
            v-if="items?.length > 0"
            class="mb-1 mt-2 borders justify-center pt-2 pb-2 mr-1 ml-1"
          >
            <v-col cols="5">
              <h3 class="fontsize">
                Class :
                {{ items[0]?.class_name }}
              </h3>
            </v-col>

            <v-col cols="auto" class="flex-grow-1">
              <h3 class="fontsize">
                Student Name :
                {{ items[0]?.student_name }}
              </h3>
            </v-col>

            <!-- <v-col cols="4">
              <h3 class="fontsize">
                {{ $t("school.reports.division") }} :
                {{ filter_data.fk_division?.name }}
              </h3>
            </v-col> -->
          </v-row>
          <table style="width: 100%; border-collapse: collapse" dir="ltr">
            <thead>
              <tr>
                <th :rowspan="selectedHeaders[0].row">
                  {{ selectedHeaders[0].title }}
                </th>
                <th
                  v-for="semester in items[0]?.semester"
                  :key="semester?.semester_name"
                  colspan="3"
                  class="border"
                >
                  {{ semester?.semester_name }}
                </th>
                <!-- <th colspan="3" class="border">
                  {{ filter_data?.fk_semester?.name_ar }}
                </th> -->
                <th colspan="2" class="border">Total Marks</th>
                <th :rowspan="selectedHeaders[selectedHeaders?.length - 1].row">
                  {{ selectedHeaders[selectedHeaders?.length - 1].title }}
                </th>
              </tr>
              <tr>
                <template
                  v-for="semester in items[0]?.semester"
                  :key="semester?.semester_name"
                >
                  <th
                    v-for="header in sub_headers"
                    :key="header.key"
                    class="border"
                  >
                    {{ header.title }}
                  </th>
                </template>
                <th class="border">Number</th>
                <th class="border">Writing</th>
              </tr>
              <!-- <tr>
                <th colspan="3" class="background-cell py-1">
                  Student Name:
                  <span class="name">{{
                    filter_data.fk_student?.name_ar
                  }}</span>
                </th>
                <th colspan="6" class="background-cell py-1 px-1"></th>
                <th colspan="3" class="background-cell py-1 px-1">
                  {{ filter_data.fk_class?.name_en }}/
                  {{ filter_data.fk_division?.name }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="header in selectedHeaders"
                  :key="header.key"
                  class="border background-cell"
                >
                  {{ header.title }}
                </th>
              </tr> -->
            </thead>
            <tbody v-if="!loading">
              <tr
                v-for="(item, key) in items[0]?.semester[0]?.subjects"
                :key="key"
              >
                <td class="text-center border">{{ item?.subject_name }}</td>
                <template
                  v-for="semester in items[0]?.semester"
                  :key="semester"
                >
                  <td class="text-center border">
                    {{ semester?.subjects[key]?.collector ?? "-" }}
                  </td>
                  <td class="text-center border">
                    {{ semester?.subjects[key]?.final_exam ?? "-" }}
                  </td>
                  <td class="text-center border">
                    {{ semester?.subjects[key]?.total ?? "-" }}
                  </td>
                </template>
                <td class="text-center border">
                  {{
                    getTotalSubject(items[0]?.semester, key)
                      ? getTotalSubject(items[0]?.semester, key)
                      : "-"
                  }}
                </td>
                <td class="text-center border">
                  {{
                    getTotalSubject(items[0]?.semester, key)
                      ? ConvertNumberToWords(
                          getTotalSubject(items[0]?.semester, key)
                        )?.replace(/(?:^|\s|-)\S/g, (char) =>
                          char?.replace("-", " ").toUpperCase()
                        ) +
                        " " +
                        "Marks"
                      : "-"
                  }}
                </td>
                <td class="text-center border">
                  {{
                    getTotalSubject(items[0]?.semester, key)
                      ? estimateChecker(
                          getTotalSubject(items[0]?.semester, key)
                        )
                      : "-"
                  }}
                </td>
              </tr>
              <tr class="tfoot">
                <td class="text-center border background-cell">Grand Total</td>
                <td
                  :colspan="sub_headers?.length * items[0]?.semester?.length"
                  class="text-center border background-cell"
                >
                  {{ items[0]?.total }} <v-icon>mdi-circle-small</v-icon>
                  {{
                    ConvertNumberToWords(items[0]?.total)?.replace(
                      /(?:^|\s|-)\S/g,
                      (char) => char?.replace("-", " ").toUpperCase()
                    )
                  }}
                </td>
                <td class="text-center border background-cell">Average</td>
                <td class="text-center border background-cell">
                  %{{ getPercentageValue() }}
                </td>
                <td class="text-center border background-cell">
                  General Estimate :
                  {{ estimateChecker(getPercentageValue()) }}
                </td>
                <!-- <td class="text-center border background-cell">dsf</td> -->
              </tr>
              <!-- <tr v-for="item in items[0]?.subjects" :key="item">

                <td
                  class="text-center border"
                  v-if="selectedHead.includes('subject')"
                  style="padding: 0"
                >
                  <div v-for="(subject, index) in item" :key="subject">
                    <div v-if="index == 0 && subject.subject_optional == true">
                      *{{ subject.subject_name_en }}
                    </div>
                    <div v-else>
                      {{ subject.subject_name_en }}
                    </div>
                  </div>
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('collector')"
                >
                  <div v-for="(subject, index) in item" :key="subject">
                    <div v-if="index == 0">
                      <span>{{ subject.collector }}</span>
                    </div>
                  </div>
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('final_exam')"
                >
                  <div v-for="(subject, index) in item" :key="subject">
                    <div v-if="index == 0">
                      <span>{{ subject.final_exam }}</span>
                    </div>
                  </div>
                </td>
                <td
                  class="text-center border background-cell"
                  v-if="selectedHead.includes('first_total')"
                >
                  <div v-for="(subject, index) in item" :key="subject">
                    <div v-if="index == 0">
                      <span>{{ subject.total }}</span>
                    </div>
                  </div>
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('collector_2')"
                >
                  <div v-for="(subject, index) in item" :key="subject">
                    <div v-if="index == 1">
                      <span>{{ subject.collector }}</span>
                    </div>
                  </div>
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('final_exam_2')"
                >
                  <div v-for="(subject, index) in item" :key="subject">
                    <div v-if="index == 1">
                      <span>{{ subject.final_exam }}</span>
                    </div>
                  </div>
                </td>
                <td
                  class="text-center border background-cell"
                  v-if="selectedHead.includes('second_total')"
                >
                  <div v-for="(subject, index) in item" :key="subject">
                    <div v-if="index == 1">
                      <span>{{ subject.total }}</span>
                    </div>
                  </div>
                </td>
                <td
                  class="text-center border background-cell"
                  v-if="selectedHead.includes('final_total')"
                >
                  {{ getFinalTotal(item) }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('writing')"
                >
                  {{ ConvertNumberToWords(getFinalTotal(item)) }}
                </td>
                <td
                  class="text-center border background-cell"
                  v-if="selectedHead.includes('estimate')"
                >
                  <div v-for="(subject, index) in item" :key="subject">
                    <div v-if="index == 0">
                      <span>{{ subject.estamate_subject_en }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colspan="7"
                  class="text-center thead border background-cell"
                >
                  Final Total Score
                </td>
                <td class="text-center thead border background-cell">
                  {{ items[0]?.total }}
                </td>
                <td class="text-center thead border">
                  {{ ConvertNumberToWords(items[0]?.total) }}
                </td>

                <td class="border background-cell"></td>
              </tr>
              <tr>
                <td class="text-center border thead background-cell">
                  Average:
                  {{ getPercentageValue(items[0]?.total, subjectsCount) }}%
                </td>
                <td colspan="5" class="text-center border background-cell"></td>
                <td
                  colspan="3"
                  class="text-center thead border background-cell"
                >
                  General Grade
                </td>
                <td class="text-center border thead background-cell">
                  {{ items[0]?.estimate }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
// import tafqeet from "@/assets/Tafqeet";
import numberToWords from "@/assets/numberToWords.min";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // await this.getSemesters();
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBrenches({});
      await this.getBranchClasses();
      await this.getClassAssignHall();
      await this.getAllEstimations();
      await this.getDivisions({});
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      // this.current_year = this.getYearName(
      //   localStorage.getItem("current_year")
      // );
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // current_year: "",
      // year_data: localStorage.getItem("current_year_name_h"),
      // school: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      sub_headers: [
        { title: "Collector ", key: "collector" },
        { title: "Final Exam", key: "final_exam" },
        { title: "Total", key: "total" },
      ],
      selectedHead: [
        "subject",
        "collector",
        "final_exam",
        "first_total",
        "collector_2",
        "final_exam_2",
        "second_total",
        "final_total",
        "writing",
        "estimate",
      ],
      headers: [
        { title: "Subject", key: "subject", row: 2 },
        { title: "First Collector", key: "collector" },
        { title: "Half Year", key: "final_exam" },
        { title: "Total", key: "first_total" },
        { title: "Second Collector", key: "collector_2" },
        { title: "End Year", key: "final_exam_2" },
        { title: "Total", key: "second_total" },
        { title: "Final Total ", key: "final_total" },
        { title: "Writing", key: "writing" },
        { title: "Estimate", key: "estimate", row: 2 },
      ],
      // perPage: 25,
      // length: 0,
      // totalItems: 0,
      // itemsPerPage: [
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      // page: 1,
      // pagination: {},
      student_list: [],
      filter_data: {},
      // subjectsTotal: 0,
      subjectsCount: 0,
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      // semesters: (state) => state.school.semesters,
      classes_by_branch: (state) => state.school.classes_by_branch,
      divisions: (state) => state.school.divisions,
      estimates: (state) => state.school.estimates,
      branch: (state) => state.school.branch,

      // user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    class_divisions() {
      if (this.filter_data.fk_class?.id) {
        return this.getClassDivisions(this.filter_data.fk_class?.id);
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getAllEstimations: "school/getAllEstimations",
      getBrenches: "school/getBrenches",
    }),
    ConvertNumberToWords(number) {
      if (number) return numberToWords.toWords(number);
    },
    getPercentageValue() {
      const total = this.items[0]?.semester[0]?.subjects?.length;
      return parseFloat(this.items[0]?.total / total).toFixed(2);
    },
    estimateChecker(degree) {
      return this.estimates.find(
        (estimate) =>
          degree >= estimate.degree_min && degree <= estimate.degree_max
      )?.name_en;
    },
    getTotalSubject(data, key) {
      return data?.reduce((acc, item) => {
        return acc + item?.subjects[key]?.total;
      }, 0);
    },
    // tafqeet,
    // getPercentageValue(sum, count) {
    //   const total = (sum / count) * 100;
    //   return parseFloat(total.toFixed(2) / 100).toFixed(2);
    // },
    // getFinalTotal(subjects) {
    //   const total = subjects.reduce((accumulator, obj) => {
    //     return accumulator + obj.total;
    //   }, 0);
    //   return total;
    // },
    // getSubjectsCount(subjects) {
    //   const count = subjects.reduce((accumulator, obj) => {
    //     if (obj[0].subject_optional === false) {
    //       return accumulator + 1;
    //     }
    //     return accumulator;
    //   }, 0);
    //   this.subjectsCount = count;
    // },
    // getClassName(class_id) {
    //   if (class_id) {
    //     const objectClass = this.classes.find(
    //       (objectClass) => objectClass.id === class_id
    //     );
    //     return objectClass?.name_en;
    //   }
    // },
    // getSemesterName(semester_id) {
    //   if (semester_id) {
    //     const objectSemester = this.semesters.find(
    //       (objectSemester) => objectSemester.id === semester_id
    //     );
    //     return objectSemester.name_ar;
    //   }
    //   return "-------------";
    // },
    async getStudents() {
      if (this.filter_data.fk_class?.id && this.filter_data.fk_division?.id) {
        let list = await this.axios.get(`${this.base_url}student-list/`, {
          params: {
            class: this.filter_data.fk_class?.id,
            division: this.filter_data.fk_division?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // year: localStorage.getItem("current_year"),
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.student_list = list.data;
      }
    },
    // getDivisionName(division_id) {
    //   const division = this.divisions.find(
    //     (division) => division.id === division_id
    //   );
    //   return division.name;
    // },
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getClassAssignHall: "school/getClassAssignHall",
      // getSemesters: "school/getSemesters",
      getAllEstimations: "school/getAllEstimations",
      getDivisions: "school/getDivisions",
    }),
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable() {
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/certificate_year_en`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // search: this.txt_search,
            page: page,
            page_size: this.perPage,
            // class_number: this.filter_data.fk_class?.id,
            // year: localStorage.getItem("current_year"),
            // semester: this.filter_data.semester,
            student: this.filter_data.fk_student?.id,
            // division: this.filter_data.fk_division?.id,
          },
        })
        .then((response) => {
          if (response.data) {
            this.items = response.data;
            this.exist = true;
            // this.totalItems = response.data.pagination.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
            // this.getSubjectsCount(this.items[0]?.subjects);
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // watch: {
  //   perPage() {
  //     this.getData();
  //   },
  //   page() {
  //     this.getData(this.page);
  //   },
  // },
};
</script>
<style scoped>
.fontsize {
  font-size: 14px;
  text-align: center;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
#myTable th {
  height: 30px;
}
#myTable td {
  height: 30px;
}
#myTable .tfoot td {
  height: 40px;
}
/* .name {
  font-size: 1.1rem !important;
}
td,
table div span {
  font-size: 0.8rem;
}
.thead {
  font-size: 1rem !important;
}
th {
  font-size: 0.9rem;
  padding-bottom: 2px;
} */
/* @media print {
  thead tr th:nth-of-type(4),
  thead tr th:nth-of-type(5),
  .background-cell {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
} */
thead tr th:nth-of-type(4),
thead tr th:nth-of-type(5),
.background-cell {
  background: #b1cdb6;
}
</style>
