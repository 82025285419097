<template>
  <v-card>
    <v-card-text class="card-view pb-4">
      <v-form
        v-if="CheckGetPermission('school.view_studentactivity')"
        ref="form"
      >
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              color="indigo"
              v-model="student_activity.fk_activity"
              density="compact"
              item-title="name"
              item-value="id"
              :items="activities"
              prepend-inner-icon="mdi-soccer"
              :label="$t('school.activity.select')"
              :rules="[$required]"
            />
          </v-col>
          <v-col
            cols="4"
            md="4"
            sm="6"
            v-if="activityFee"
            class="align-self-center px-0"
          >
            <span class="text-blue-grey">
              {{ $t("school.activity.fee") }} : {{ activityFee }} ريال
            </span>
          </v-col>
        </v-row>
        <v-row class="row-nm pt-4">
          <v-col cols="12" md="8" class="ps-0">
            <fieldset class="px-4 border" style="border-radius: 5px">
              <legend class="v-label px-2">
                {{ $t("school.student.students") }}
              </legend>
              <v-row class="row-nm pt-3">
                <v-col cols="6" md="6" sm="6">
                  <v-autocomplete
                    color="indigo"
                    v-model="filter_data.fk_class"
                    density="compact"
                    item-title="name_ar"
                    item-value="id"
                    prepend-inner-icon="mdi-google-classroom"
                    clearable
                    :items="classes"
                    :label="$t('school.class.select')"
                    @update:modelValue="filter_data.fk_division = undefined"
                    :rules="[$required]"
                  />
                    <!-- hide-details="auto" -->
                </v-col>
                <v-col cols="6" md="6" sm="6">
                  <v-autocomplete
                    color="indigo"
                    v-model="filter_data.fk_division"
                    density="compact"
                    item-title="name"
                    item-value="id"
                    clearable
                    :items="class_divisions"
                    :no-data-text="$t('school.division.not_found')"
                    prepend-inner-icon="mdi-format-list-bulleted"
                    :label="$t('school.division.select')"
                  ></v-autocomplete>
                    <!-- hide-details="auto" -->
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col cols="2" class="my-auto pb-4 pt-6">
            <v-btn
              @click="getStudents()"
              class="bg-indigo"
              size="small"
              :loading="loading"
            >
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
              <!-- <v-icon icon="mdi-content-save" color="white" end></v-icon> -->
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-expand-transition>
      <div v-show="students.length > 0" class="card-table">
        <!-- <v-divider class="my-3"></v-divider> -->
        <v-card v-if="students.length > 0">
          <!-- <v-row class="mt-2 mx-1">
                        <v-col cols="12" md="6" sm="6">
                            <v-text-field
                                color="indigo"
                                v-model="search_student" 
                                @input="searchStudentActivity"
                                density="compact"
                                append-inner-icon="mdi-magnify"
                                prepend-inner-icon="mdi-school"
                                :label="$t('school.student.student_search')"
                            />
                        </v-col>
                    </v-row> -->
          <v-divider />
          <v-table dense density="compact">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <v-text-field
                    color="indigo"
                    v-model="search_student"
                    @input="!search_student ? searchStudentActivity() : ''"
                    @keyup.enter="searchStudentActivity()"
                    density="compact"
                    variant="solo-filled"
                    hide-details
                    clearable
                    single-line
                    append-inner-icon="mdi-magnify"
                    :label="$t('school.student.name')"
                  />
                </th>
                <th>
                  <v-checkbox
                    color="indigo"
                    v-model="masterChecked.subscribed"
                    :indeterminate="isIndeterminate.subscribed"
                    density="compact"
                    hide-details
                    :label="$t('school.activity.is_subscribed')"
                    style="min-width: 100px"
                    @change="
                      toggleAll(masterChecked.subscribed, 'subscribed'),
                        !masterChecked.subscribed
                          ? toggleAll(masterChecked.subscribed, 'is_paid')
                          : '',
                        updateMaster('is_paid')
                    "
                  >
                  </v-checkbox>
                </th>
                <th>
                  <v-checkbox
                    color="indigo"
                    v-model="masterChecked.is_paid"
                    :indeterminate="isIndeterminate.is_paid"
                    :disabled="!masterChecked.subscribed"
                    density="compact"
                    hide-details
                    :label="$t('school.activity.is_paid')"
                    style="min-width: 100px"
                    @change="toggleAll(masterChecked.is_paid, 'is_paid')"
                  >
                  </v-checkbox>
                </th>
                <th class="text-center">{{ $t("global.note") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(student, index) in student_activity.students"
                :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td style="min-width: 300px">{{ student.name_ar }}</td>
                <td class="text-center">
                  <v-checkbox
                    style="min-width: 70px"
                    v-model="student.subscribed"
                    color="indigo"
                    density="compact"
                    hide-details
                    @update:modelValue="
                      (student.is_paid = !student.subscribed
                        ? false
                        : student.is_paid),
                        updateMaster('is_paid'),
                        updateMaster('subscribed')
                    "
                  >
                  </v-checkbox>
                </td>
                <td class="text-center">
                  <v-checkbox
                    style="min-width: 70px"
                    v-model="student.is_paid"
                    color="indigo"
                    density="compact"
                    hide-details
                    :disabled="!student.subscribed"
                    @update:modelValue="updateMaster('is_paid')"
                  >
                    <!-- @change="
                      (select_all_students = false),
                        (student.subscribed = student.is_paid)
                    " -->
                  </v-checkbox>
                </td>
                <td>
                  <v-text-field
                    v-model="student.note"
                    style="min-width: 250px"
                    density="compact"
                    :placeholder="$t('global.note')"
                    hide-details
                    class="my-1"
                    :disabled="!student.subscribed"
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </v-table>

          <div
            class="ma-2 text-center"
            v-if="student_activity.students.length < 1"
          >
            <!-- <v-alert> -->
              <span>{{ $t("global.data_table.student_not_found") }}</span>
            <!-- </v-alert> -->
          </div>
        </v-card>
      </div>
    </v-expand-transition>
    <v-card-actions
      v-if="
        (CheckGetPermission('school.add_studentactivity') ||
          CheckGetPermission('school.change_studentactivity')) &&
        students.length > 0
      "
      class="px-8"
    >
      <v-btn
        v-if="
          CheckGetPermission('school.add_studentactivity') ||
          CheckGetPermission('school.change_studentactivity')
        "
        @click="checkWarning"
        :loading="is_loading"
        class="bg-indigo"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.save") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <!-- <v-btn v-if="update" @click="updateMarks"   class=" bg-success"    size="small">
                            <span class="text-white">
                                {{$t('global.edit')}}
                            </span>
                            <v-icon icon="mdi-content-save" color="white" end></v-icon>
                        </v-btn> -->
      <v-btn @click="cleanStudentActivityForm" class="mx-3" size="small">
        <span>
          {{ $t("global.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>

  <!-- ############### Dialog alert  ################### -->
  <v-dialog
    color="indigo"
    v-model="show_warning"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title class="text-center">
        <v-icon class="text-warnning px-2">mdi-alert</v-icon>
        <span style="font-size: 20px !important">{{
          $t("global.warnning.title")
        }}</span>
      </v-card-title>
      <v-card-text>
        <span class="v-label">{{ $t("global.warnning.max_student") }}</span>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="show_warning = false">{{ $t("global.cancel") }}</v-btn>
        <v-btn class="bg-indigo" size="small" @click="saveStudentActivity">{{
          $t("global.ok")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- ############### end Dialog alert  ################### -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  props: {
    id: String,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getActivities();
      if (!this.classes.length) await this.getClasses();
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      // if (!this.academic_years.length) await this.getAcademicYears();

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      show_warning: false,
      masterChecked: {
        subscribed: false,
        is_paid: false,
      },
      isIndeterminate: {
        subscribed: false,
        is_paid: false,
      },
      activities: [],
      students: [],
      filter_data: {
        // fk_year: localStorage.getItem("current_year"),
        fk_class: null,
        fk_division: null,
      },
      search_student: "",
      student_activity: {
        fk_activity: undefined,
        data_entry: undefined,
        students: [],
      },
      is_loading: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      academic_years: (state) => state.school.academic_years,
      // user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    activityFee() {
      const activity = this.activities.find(
        (el) => el.id == this.student_activity.fk_activity
      );
      return activity ? activity.fee : null;
    },

    class_divisions() {
      if (this.filter_data.fk_class) {
        return this.getClassDivisions(this.filter_data.fk_class);
      }
    },

    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      // getAcademicYears: "school/getAcademicYears",
      getClassAssignHall: "school/getClassAssignHall",
    }),

    searchStudentActivity() {
      if (this.search_student != "") {
        this.student_activity.students = this.students.filter((student) => {
          return student.name_ar.includes(this.search_student);
        });
      } else {
        this.student_activity.students = this.students;
      }
    },
    // selectAllStudents(value, sub = true) {
    //   if (sub) {
    //     this.students.forEach((student) => {
    //       student.subscribed = value;
    //     });
    //   } else {
    //     this.students.forEach((student) => {
    //       student.subscribed = value;
    //     });
    //     this.students.forEach((student) => {
    //       student.is_paid = value;
    //     });
    //   }
    // },
    // allChecked(){
    //   let paid =true
    //   let sub = true
    //   this.students.some(item => {
    //     if (item.is_paid == false) {
    //       paid = false
    //     }
    //     if(item.subscribed == false){
    //       sub = false
    //   }
    //   })
    //     this.paid_all_students = paid
    //     this.select_all_students = sub
    // },
    toggleAll(All, checkedField) {
      this.students.forEach((item) => {
        item[checkedField] = All;
      });
      this.updateMaster(checkedField);
    },
    updateMaster(checkedField) {
      const data = this.students;
      const totalChecked = data.reduce((total, item) => {
        return total + (item[checkedField] ? 1 : 0);
      }, 0);
      const totalItems = data.length;
      this.masterChecked[checkedField] = totalChecked === totalItems;
      this.isIndeterminate[checkedField] =
        totalChecked > 0 && totalChecked < totalItems;
      // this.all_registered = this.students_table.every(
      //   (item) => item.registeration_mode === true
      // );
      // this.all_continued = this.students_table.every(
      //   (item) => item.study_mode == true
      // );
      // const some_registered = this.students_table.some(
      //   (item) => item.registeration_mode === true
      // );
      // const some_continued = this.students_table.some(
      //   (item) => item.study_mode == true
      // );

      // this.all_registered = this.all_registered
      //   ? true
      //   : some_registered
      //   ? null
      //   : false;
      // this.all_continued = this.all_continued
      //   ? true
      //   : some_continued
      //   ? null
      //   : false;
    },
    async getStudents() {
      this.students = [];
      this.student_activity.students = [];
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}api/student-activity/list/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              // year: localStorage.getItem("current_year"),
              class: this.filter_data.fk_class,
              division: this.filter_data.fk_division,
              activity: this.student_activity.fk_activity,
            },
          })
          .then((response) => {
            this.students = response.data;
            this.student_activity.students = this.students;
            this.updateMaster("is_paid");
            this.updateMaster("subscribed");
            if (response.data.length < 1) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.message")
              );
            }
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.loading = false;
    },
    async getActivities() {
      await this.axios
        .get(`${this.base_url}api/activities/list/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.activities = response.data;
          this.updateMaster("subscribed");
          this.updateMaster("is_paid");
        });
    },
    // checkFilterData() {
    //   // this.v$.$validate();
    //   if (!this.v$.$error) {
    //     this.getStudents();
    //   }
    // },
    checkWarning() {
      let activity = this.activities.find(
        (el) => el.id == this.student_activity.fk_activity
      );
      if (
        activity.student_count <
        this.students.filter((el) => el.subscribed == true).length
      ) {
        this.show_warning = true;
      } else {
        this.saveStudentActivity();
      }
    },
    async saveStudentActivity() {
      this.show_warning = false;
      // this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      var student_activity_data = [];
      if (valid) {
        this.is_loading = true;
        this.students.forEach((student) => {
          if (student.subscribed || (student.id && !student.subscribed)) {
            student_activity_data.push({
              fk_activity: this.student_activity.fk_activity,
              fk_student: student.fk_student,
              note: student.note,
              data_entry: this.user,
              id: student.id,
              subscribed: student.subscribed,
              is_paid: student.is_paid,
            });
          }
        });
        await this.axios
          .post(
            `${this.base_url}api/student-activity/`,
            student_activity_data,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.getStudents();
            this.$emit("successAlert", this.$t("global.success.data_saved"));
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    cleanStudentActivityForm() {
      this.$refs.form.reset();
      this.student_activity.students = [];
      this.students = [];
      // this.v$.student_activity.$reset();
    },
  },
};
</script>
