<template>
  <v-card class="justify-center">
    <v-card-text class="card-view">
      <!-- <v-card class="pa-2 ma-auto" > -->
      <v-form
        class="d-flex flex-column"
        style="gap: 1.5rem"
        ref="form"
        @submitSchoolWeeklySchedule.prevent="saveSchoolWeeklySchedule"
      >
        <v-row>
          <v-col cols="12" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="filters.fk_class"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-google-classroom"
              clearable
              :items="classes"
              :label="$t('school.class.select')"
              :rules="[$required]"
              @update:modelValue="
                (filters.fk_division = null), getSubject()
              "
            />
          </v-col>
          <v-col cols="6" md="3" sm="4">
            <v-select
              color="indigo"
              v-model="filters.fk_division"
              density="compact"
              item-title="name"
              :no-data-text="$t('school.division.not_found')"
              item-value="id"
              prepend-inner-icon="mdi-format-list-bulleted"
              clearable
              :items="getClassByDivisions(filters.fk_class)"
              :label="$t('school.division.select')"
              :rules="[$required]"
            />
            <!-- @update:modelValue="getSubjectsWithTeacher" -->
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-select
              color="indigo"
              v-model="filters.period_am"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-timeline-clock-outline"
              clearable
              :items="[
                { id: true, name: $t('school.weekly_schedule.am') },
                { id: false, name: $t('school.weekly_schedule.pm') },
              ]"
              :label="$t('school.schedule_data.schedule_period')"
              :rules="[$required]"
            />
            <!-- @update:modelValue="createTable" -->
          </v-col>
          <v-col cols="6" md="1">
            <v-btn
              @click="searchSchedule()"
              class="bg-indigo"
              size="small"
              :loading="loading"
            >
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
              <!-- <v-icon icon="mdi-content-save" color="white" end></v-icon> -->
            </v-btn>
          </v-col>
        </v-row>
        <!-- <small v-if="!checkSchedules&&!update" >shehab alfareh</small> -->

        <!-- <div v-else class="text-center mt-2 mb-1 text-grey-darken-2">
          <span v-if="weekly_schedule.period_am == true">
            {{ $t("school.weekly_schedule.no_periods_am") }}
          </span>
          <span v-if="weekly_schedule.period_am == false">
            {{ $t("school.weekly_schedule.no_periods_pm") }}
          </span>
        </div> -->
      </v-form>
      <!-- </v-card> -->
    </v-card-text>
    <v-progress-linear
      v-if="loading"
      color="indigo"
      indeterminate
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear>
    <v-expand-transition>
      <div v-show="found" class="card-table">
        <v-divider></v-divider>
        <v-card class="mt-4">
          <v-table>
            <thead>
              <tr>
                <th>
                  {{ $t("school.day.day") }}/{{ $t("school.lecture.lecture") }}
                </th>
                <th v-for="schedule in timetable_schedules" :key="schedule">
                  {{ schedule.quotas }}
                  <!-- <small>
                            {{ schedule.quotas_start_time }}
                            -
                            {{ schedule.quotas_end_time }}
                          </small> -->
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="day in weekly_schedule.days" :key="day">
                <td>
                  <span>{{ getDayName(day.day) }}</span>
                </td>
                <!-- {{ day.subjects }} -->
                <td
                  v-for="subject in day.subjects"
                  :key="subject"
                  class="justify-center"
                >
                  <v-autocomplete
                    v-model="subject.fk_subject"
                    density="compact"
                    clearable
                    item-value="id"
                    item-title="name_ar"
                    :no-data-text="$t('school.subject.not_found')"
                    :items="subjects_list"
                    hide-details
                    :placeholder="$t('school.subject.select')"
                    variant="underlined"
                  >
                    <!-- @update:modelValue="
                              subject.fk_teacher = determineTeacher(
                                subject.fk_subject
                              )
                            " -->
                  </v-autocomplete>
                  <!-- <div class="d-none" v-if="subject.fk_subject!==null">
                                                {{subject.fk_teacher=determineTeacher(subject.fk_subject)}}
                                            </div> -->
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-card-actions v-if="CheckGetPermission('school.change_schoolweeklyschedule') || CheckGetPermission('school.delete_schoolweeklyschedule')">
            <v-btn
              @click="updateWeeklySchedule"
              v-if="CheckGetPermission('school.change_schoolweeklyschedule')"
              class="bg-success"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.edit") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <v-btn
              @click="dialog = true"
              v-if="CheckGetPermission('school.delete_schoolweeklyschedule')"
              class="bg-red"
              size="small"
            >
              <span class="text-white">
                {{ $t("global.delete") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <VBtn class="mx-3" size="small" @click="cleanSchoolWeeklySchedule">
              {{ $t("global.clear") }}
              <VIcon :icon="'mdi-broom'" :color="'golden'" end></VIcon>
            </VBtn>
            <!-- <v-btn @click="cleanSchoolWeeklySchedule" class="mx-3" size="small">
              <span>
                {{ $t("global.clear") }}
              </span>
              <v-icon icon="mdi-broom" color="golden" end></v-icon>
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </div>
    </v-expand-transition>
  </v-card>
  <v-dialog
    color="indigo"
    v-model="dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        <v-icon>mdi</v-icon>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="(dialog = false), (selectedClass = '')">{{
          $t("global.cancel")
        }}</v-btn>
        <v-btn color="red" @click="deleteWeeklySchedule()">{{
          $t("global.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <div></div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "SchoolWeeklyScheduleUpdate",
  mounted() {},
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getSchedule(true);
      if (!this.classes.length) await this.getClasses();
      if (!this.divisions.length) await this.getDivisions({ all: true });
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      if (!this.days_choices.length) await this.getDaysChoices();
      if (!this.subjects.length) await this.getSubjects({});

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      schedules_data: [],
      showAlert: false,
      update: false,
      user: null,
      dialog: false,
      found: false,
      loading: false,
      is_loading: false,
      subjects: [],
      user: null,
      weekly_schedule: {
        fk_class: null,
        fk_division: null,
        // fk_semester: null,
        period_am: null,
        // fk_year: localStorage.getItem("current_year"),
        days: [],
        note: "",
        // updated_by: undefined,
      },
      filters:{
        fk_class:null,
        fk_division:null,
        period_am:null,
      },
      subjects_list: [],
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      //   semesters: (state) => state.school.semesters,
      days_choices: (state) => state.school.days_choices,
      schedules: (state) => state.school.schedules,
    }),
    ...mapGetters({
      getClassByDivisions: "school/getClassDivisions",
      getSubjectsByClass: "school/getSubjectsByClass",
    }),
    timetable_schedules() {
      return this.schedules.filter(
        (el) =>
          el.add_to_table == true && el.period == this.filters.period_am
      );
    },
    scheduleData() {
      return [
        this.weekly_schedule.fk_class,
        this.weekly_schedule.fk_division,
        this.weekly_schedule.period_am,
        // this.weekly_schedule.fk_semester,
      ];
    },
    class_subjects() {
      if (this.weekly_schedule.fk_class) {
        var class_data = this.classes.find(
          (item) => item.id == this.weekly_schedule.fk_class
        );
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },

    classesName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    subjectsName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    // teachersName() {
    //   return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    // },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      //   getSemesters: "school/getSemesters",
      getClasses: "school/getClasses",
      getDaysChoices: "school/getDaysChoices",
      getSchedule: "school/getSchedule",
      getDivisions: "school/getDivisions",
      getSubjects: "school/getSubjects",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    getDayName(id) {
      const day = this.days_choices.find((el) => el.id == id);
      return day ? day.name : "";
    },
    async searchSchedule() {
      const { valid } = await this.$refs.form.validate();
      // this.submitSchoolWeeklySchedule();
      if (valid) {
        if (this.timetable_schedules.length) {
          this.loading = true;
          await this.axios
            .get(`${this.base_url}api/weekly-schedule/list/`, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                class: this.filters.fk_class,
                division: this.filters.fk_division,
                period_am: this.filters.period_am,
              },
            })
            .then((response) => {
              this.getSubject()
              this.weekly_schedule = response.data;
              // const subjects_id = new set(
              //   this.subjects_list.map((item) => item.id)
              // );
              // console.log(this.weekly_schedule.days);
              // this.weekly_schedule.days = this.weekly_schedule.days.map(
              //   (day) => {
              //     return {
              //       subjects: day.subjects.map((subItem) => ({
              //         ...subItem,
              //         fk_subject: subjects_id.has(
              //           subItem.fk_subject ? subItem.fk_subject : null
              //         ),
              //       })),
              //     };
              //   }
              // );
              this.found = true;
              this.loading = false;
            })
            .catch((error) => {
              if (error.response?.data == "not found") {
                this.$emit(
                  "warningAlert",
                  this.$t("global.warning.no_period_data")
                );
              } else if (error.response?.data.branch) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch")
                );
              } else if (error.response.data.semester) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch_semester")
                );
              } else {
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.error_in_data")
                );
              }
              this.loading = false;
              this.found = false;
            });
        } else {
          this.$emit("warningAlert", this.$t("global.warning.no_period_data"));
        }
      }
      this.loading = false;
    },
    getSubjectName(id) {
      var subject = this.subjects.find((item) => item.id == id);
      return subject.name_ar;
    },

    getClassName(id) {
      var item = this.classes.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    getSubjectName(id) {
      var item = this.subjects.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    // getTeacherName(id) {
    //   var item = this.teachers.find((item) => item.id === id);
    //   return item
    //     ? this.$i18n.locale == "ar"
    //       ? item.name_ar
    //       : item.name_en
    //     : "";
    // },

    submitSchoolWeeklySchedule() {
      this.v$.$validate();
    },
    cleanSchoolWeeklySchedule() {
      //   this.v$.weekly_schedule.$reset();
      let period_am = this.weekly_schedule.period_am;
      let fk_division = this.weekly_schedule.fk_division;
      let fk_class = this.weekly_schedule.fk_class;
      this.$refs.form.reset();
      this.found = false;
      this.weekly_schedule.period_am = period_am;
      this.weekly_schedule.fk_division = fk_division;
      this.weekly_schedule.fk_class = fk_class;
    },
    deleteWeeklySchedule() {
      this.axios
        .post(
          `${this.base_url}api/weekly-schedule/delete/`,
          {
            class: this.scheduleData[0],
            division: this.scheduleData[1],
            period_am: this.scheduleData[2],
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.dialog = false;
          this.$emit("successAlert", this.$t("global.success.data_deleted"));

          this.cleanSchoolWeeklySchedule();
        });
    },
    async updateWeeklySchedule() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.is_loading = true;
        var listSchedules = [];
        this.weekly_schedule.days.forEach((day) => {
          day.subjects.forEach((subject) => {
            listSchedules.push({
              id: subject.id,
              fk_subject: subject.fk_subject,
              data_updated: this.user,
              // fk_teacher: subject.fk_teacher,
              fk_year: localStorage.getItem("current_year"),
            });
          });
        });
        await this.axios
          .put(`${this.base_url}api/weekly-schedule/${null}/`, listSchedules, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
          })
          .catch((error) => {
            if (error.response.data.non_field_errors) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.validation.unique")
              );
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    getSubject() {
      if (this.filters.fk_class) {
        this.subjects_list = this.getSubjectsByClass(
          this.filters.fk_class
        );
      }
    },
  },
};
</script>
<style scoped>
.v-table th,
td {
  text-align: center !important;
  padding-inline: 10px !important;
}
</style>
