<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <!-- <v-col v-if="getUserRole < 2" cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col> -->
        <v-col v-if="getUserRole < 2" cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_subject = null),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            @update:model-value="filter_data.fk_subject = null"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_subject"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-bookshelf"
            :items="
              getSubjectsByClass(filter_data.fk_class?.id, classes_by_branch)
            "
            :label="$t('school.reports.select_subject')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>

        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <tr>
                <th rowspan="2">
                  {{ selectedHeaders[0].title }}
                </th>
                <th
                  v-for="semester in semesters"
                  :key="semester?.semester_name"
                  colspan="3"
                  class="border"
                >
                  {{ semester?.name_ar }}
                </th>
                <!-- <th colspan="3" class="border">
                  {{ filter_data?.fk_semester?.name_ar }}
                </th> -->
                <th colspan="2" class="border">المجموع</th>
                <th rowspan="2">
                  {{ selectedHeaders[selectedHeaders?.length - 1].title }}
                </th>
              </tr>
              <tr>
                <template
                  v-for="semester in semesters"
                  :key="semester?.semester_name"
                >
                  <th
                    v-for="header in sub_headers"
                    :key="header.key"
                    class="border"
                  >
                    {{ header.title }}
                  </th>
                </template>
                <th class="border">رقما</th>
                <th class="border">كتابة</th>
              </tr>
              <!-- <tr>
                <th v-for="header in selectedHeaders" :key="header.key">
                  {{ header.title }}
                </th>
              </tr> -->
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="key">
                <td class="text-center border">{{ item?.student_name }}</td>
                <template
                  v-for="semester in item?.semesters.slice(0, semesters.length)"
                  :key="semester"
                >
                  <td class="text-center border">
                    {{ semester?.collector ?? "-" }}
                  </td>
                  <td class="text-center border">
                    {{ semester?.final_exam ?? "-" }}
                  </td>
                  <td class="text-center border">
                    {{ semester?.total ?? "-" }}
                  </td>
                </template>
                <td class="text-center border">
                  {{
                    getTotalStudentMark(item?.semesters)
                      ? totalStudentMark
                      : "-"
                  }}
                </td>
                <td class="text-center border">
                  {{
                    totalStudentMark
                      ? tafqeet(totalStudentMark) + " " + "درجة"
                      : "-"
                  }}
                </td>
                <td class="text-center border">
                  {{
                    totalStudentMark ? estimateChecker(totalStudentMark) : "-"
                  }}
                </td>
              </tr>
              <!-- <tr v-for="(item, index) in items" :key="item.id">
                <td
                  v-if="selectedHead.includes('no')"
                  class="text-center border"
                >
                  {{ (page - 1) * perPage + index + 1 }}
                </td>
                <td
                  v-for="header in selectedHeaders?.filter(
                    (head) => head.key != 'no'
                  )"
                  :key="header.key"
                  class="text-center border"
                >
                  {{ item[header.key] ? item[header.key] : "-" }}
                </td>
              </tr> -->
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student.name_ar }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('total')"
                >
                  {{ item.total }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('estimate')"
                >
                  {{ item.estimate }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('note')"
                >
                  {{ item.note }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import tafqeet from "@/assets/Tafqeet";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getSemesters();
      await this.getBranchClasses();
      await this.getSubjects({});
      await this.getBrenches({});
      await this.getAllEstimations();
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      // this.current_year = localStorage.getItem("current_year");
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // current_year: "",
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      totalStudentMark: null,
      exist: false,
      items: [],
      // school: [],
      loading: false,
      // txt_search: "",
      sub_headers: [
        { title: "المحصلة ", key: "collector" },
        { title: "الاختبار النهائي", key: "final_exam" },
        { title: "الاجمالي", key: "total" },
      ],
      selectedHead: [
        // "no",
        "student_name",
        "collector",
        "final_exam",
        "total",
        "collector",
        "final_exam",
        "total",
        "writing",
        "final_total",
        "estimate",
        // "note",
      ],
      headers: [
        // { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: "كتابة", key: "writing" },
        { title: "التقدير", key: "estimate" },
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 5, text: "5" },
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        fk_class: null,
      },
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      // class_assign_hall: (state) => state.school.class_assign_hall,
      classes_by_branch: (state) => state.school.classes_by_branch,
      subjects: (state) => state.school.subjects,
      branch: (state) => state.school.branch,
      semesters: (state) => state.school.semesters,
      estimates: (state) => state.school.estimates,
      // user: (state) => state.User,
    }),
    ...mapGetters({
      getSubjectsByClass: "school/getSubjectsByClass",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },

    // class_subjects() {
    //   if (this.filter_data.fk_class?.id) {
    //     var class_data = this.classes.find(
    //       (item) => item.id == this.filter_data.fk_class?.id
    //     );
    //     var subjects_data = [];
    //     class_data.subjects.forEach((element) => {
    //       // console.log('element',element)
    //       subjects_data.push({
    //         id: element,
    //         name: this.getSubjectName(element),
    //       });
    //     });
    //   }
    //   return subjects_data;
    // },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      // getClassAssignHall: "school/getClassAssignHall",
      getSubjects: "school/getSubjects",
      getBrenches: "school/getBrenches",
      getSemesters: "school/getSemesters",
      getAllEstimations: "school/getAllEstimations",
    }),
    tafqeet,
    // getPercentageValue() {
    //   const total = this.items[0]?.semester[0]?.subjects?.length;
    //   return parseFloat(this.items[0]?.total / total).toFixed(2);
    // },
    estimateChecker(degree) {
      return this.estimates.find(
        (estimate) =>
          degree >= estimate.degree_min && degree <= estimate.degree_max
      )?.name_ar;
    },
    getTotalStudentMark(data) {
      this.totalStudentMark = null;
      this.totalStudentMark = data?.reduce((acc, item) => {
        return acc + item?.total;
      }, 0);
      return this.totalStudentMark;
    },
    // getSubjectName(subject_id) {
    //   if (subject_id) {
    //     let subject = this.subjects.find(
    //       (subject) => subject.id === subject_id
    //     );
    //     return subject.name_ar;
    //   }
    //   return "===========";
    // },
    // getClassName(class_id) {
    //   if (class_id) {
    //     const classObject = this.classes.find(
    //       (classObject) => classObject.id === class_id
    //     );
    //     return classObject.name_ar;
    //   }
    //   return "===========";
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/students/subjects_anuual_marks`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            fk_class: this.filter_data.fk_class?.id,
            fk_subject: this.filter_data.fk_subject?.id,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response?.data?.results;
            this.totalItems = response.data?.pagination?.count;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
