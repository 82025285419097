<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            @update:model-value="
              (filter_data.fk_group = null),
                getAllGroups(filter_data.fk_branch?.id)
            "
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_group"
            density="compact"
            item-title="name"
            item-value="pk"
            prepend-inner-icon="mdi-calendar"
            :items="
              getUserRole < 2 ? (filter_data.fk_branch ? groups : []) : groups
            "
            :label="$t('school.reports.select_group')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <!-- print data  -->
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  v-for="(header, index) in selectedHeaders"
                  :class="`border px-3 ${
                    header.key == 'student_name' ? '' : 'text-center'
                  }`"
                  :key="index"
                >
                  <template v-if="index === 0">
                    {{ (page - 1) * perPage + key + 1 }}
                  </template>

                  <template v-else>
                    {{ item[header.key] ? item[header.key] : "-" }}
                  </template>
                </td>
              </tr>
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ (page - 1) * perPage + key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('first_name')"
                >
                  {{ item.first_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('username')"
                >
                  {{ item.username }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('role')"
                >
                  {{ item.role }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('email')"
                >
                  {{ item.email }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updateAuthenticated",
        localStorage.getItem("token")
      );
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        await this.getAllGroups();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: ["no", "first_name", "username", "role", "email"],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.fname"), key: "first_name" },
        { title: this.$t("school.reports.username"), key: "username" },
        { title: this.$t("school.reports.role"), key: "role" },
        { title: this.$t("school.reports.email"), key: "email" },
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        fk_group: null,
      },
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
      groups: (state) => state.school.groups,
      // user: (state) => state.User,
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },

  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
      getAllGroups: "school/getAllGroups",
    }),
    // getParentName(fk_year) {
    //   const year = this.fees_types.find((year) => year.id === fk_year);
    //   return year.name;
    // },
    // getGroupName(group_id) {
    //   const group = this.groups.find((group) => group.pk === group_id);
    //   return group.name;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },

    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/group_report`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // search: this.txt_search,
            page: page,
            page_size: this.perPage,
            group: this.filter_data.fk_group?.pk,
            fk_branch: this.filter_data?.fk_branch?.id,
          },
        })
        .then((response) => {
          if (response.data.results) {
            this.items = response.data.results;
            this.exist = true;
            this.totalItems = response.data.pagination.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            // this.itemsPerPage[4]["value"] = this.totalItems;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },

  // validations() {
  //   return {
  //     filter_data: {
  //       fk_group: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
