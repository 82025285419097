<template>
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <!-- <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            @update:modelValue="(filter_data.fk_student = null), getStudents()"
            return-object
          />
        </v-col> -->
        <v-col v-if="getUserRole < 2" cols="12" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_fee = null),
                (filter_data.fk_student = null),
                (students = []),
                getBranchClasses(filter_data.fk_branch?.id),
                getFeeTypes(this.filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole > 1 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:modelValue="(filter_data.fk_student = null), getStudents()"
          />
        </v-col>
        <v-col cols="6" :md="getUserRole > 1 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_student"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-school"
            :items="students"
            :label="$t('school.reports.select_student')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>

        <v-col cols="6" :md="getUserRole > 1 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_fee"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-cash-100"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? fee_types
                  : []
                : fee_types
            "
            :label="$t('school.reports.select_fee_type')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>
    <!-- <h2 class="text-center text-grey-darken-2 mt-3">
      {{ $t("report.student_fees_Installment") }}
    </h2>
    <h3 class="text-center text-grey-darken-2">
      {{ $t("report.clause") }} : {{ getFeeTypeName(filter_data.fk_fee) }}
    </h3> -->
    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card
          v-if="(items[0]?.fees?.length ?? items.length) < 1"
          class="pa-2"
        >
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card
          :loading="loading"
          v-if="(items[0]?.fees?.length ?? items?.length) > 0"
        >
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <template v-for="(item, i) in items" :key="i">
                <template v-for="(fee, index) in item.fees" :key="index">
                  <tr
                    v-for="(installment, index2) in fee.installments"
                    :key="index2"
                  >
                    <!-- <td
                      class="text-center border"
                      v-if="selectedHead.includes('no') && index2 === 0"
                      :rowspan="fee.installments.length"
                    >
                      {{ i + 1 }}
                    </td> -->
                    <td
                      class="text-center border"
                      v-if="
                        selectedHead.includes('student_name') && index2 === 0
                      "
                      :rowspan="fee.installments.length"
                    >
                      {{ item.student_name }}
                    </td>
                    <!-- <td
                      class="text-center border"
                      v-if="selectedHead.includes('fee') && index2 === 0"
                      :rowspan="fee.installments.length"
                    >
                      {{ fee.fee }}
                    </td> -->
                    <td
                      class="text-center border"
                      v-if="selectedHead.includes('total_fee') && index2 === 0"
                      :rowspan="fee.installments.length"
                    >
                      {{ fee.total_fee.toLocaleString("en-US") }}
                    </td>
                    <td
                      class="text-center border"
                      v-if="selectedHead.includes('installment_date')"
                    >
                      {{ installment.installment_date }}
                    </td>
                    <td
                      class="text-center border"
                      v-if="selectedHead.includes('installment')"
                    >
                      {{ installment.installment.toLocaleString("en-US") }}
                    </td>
                    <td
                      class="text-center border"
                      v-if="selectedHead.includes('paid')"
                    >
                      {{ installment.paid.toLocaleString("en-US") }}
                    </td>
                    <td
                      class="text-center border"
                      v-if="selectedHead.includes('still')"
                    >
                      {{
                        (
                          installment.installment - installment.paid
                        ).toLocaleString("en-US")
                      }}
                    </td>
                    <td
                      class="text-center border"
                      v-if="selectedHead.includes('total_paid') && index2 === 0"
                      :rowspan="fee.installments.length"
                    >
                      {{
                        calcuteteAllAmount(fee.installments).toLocaleString("en-US")
                      }}
                    </td>
                  </tr>
                  <tr></tr>
                </template>
              </template>
            </tbody>
          </table>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <span class="mt-2">{{ $t("school.reports.showing") }}</span>
              <v-autocomplete
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-autocomplete>
              <span class="mt-2"
                >{{ " " + $t("school.reports.from") + " " }} [
                {{ items?.length }} ]
                {{ " " + $t("school.reports.item") + " " }}</span
              >
            </div>
          </div> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // await this.getYears()
      await this.getBranchClasses();
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        await this.getFeeTypes();
      }
      // await this.getDivisions()
      // await this.getFeeType();
      // await this.getSemesters()
      // await this.getSubjects()
      //   await this.axios
      //     .get(`${this.base_url}school-data/`, {
      //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //     })
      //     .then((response) => {
      //       this.school = response.data;
      //     });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      // console.log(error);
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // fees_types: [],
      students: [],
      //   year_data: null,
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   v$: useValidate(),
      exist: false,
      items: [],
      school: [],
      loading: false,
      //   txt_search: "",
      selectedHead: [
        // "no",
        "student_name",
        // "fee",
        "total_fee",
        "installment_date",
        "installment",
        "paid",
        "still",
        "total_paid",
      ],
      headers: [
        // { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        // { title: this.$t("school.reports.fee_type"), key: "fee" },
        { title: this.$t("school.reports.total_fee"), key: "total_fee" },
        {
          title: this.$t("school.reports.installment_date"),
          key: "installment_date",
        },
        { title: this.$t("school.reports.installment"), key: "installment" },
        { title: this.$t("school.reports.paid"), key: "paid" },
        { title: this.$t("school.reports.still"), key: "still" },
        { title: this.$t("school.reports.total_paid"), key: "total_paid" },
      ],
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: 1000000, text: this.$t("school.reports.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {
        fk_class: null,
        fk_year: null,
        fk_fee: null,
        fk_student: null,
      },
      return_object: false,
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      //   class_assign_hall: (state) => state.school.class_assign_hall,
      //   divisions: (state) => state.school.divisions,
      //   semesters: (state) => state.school.semesters,
      //   subjects: (state) => state.school.subjects,
      //   user: (state) => state.User,
      classes_by_branch: (state) => state.school.classes_by_branch,
      branch: (state) => state.school.branch,
      fee_types: (state) => state.school.fee_types,
    }),

    ...mapGetters({
      //   getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getBrenches: "school/getBrenches",
      getFeeTypes: "school/getFeeTypes",
      //   getAcademicYears: "school/getAcademicYears",
      //   getDivisions: "school/getDivisions",
      //   getSemesters: "school/getSemesters",
      //   getClassAssignHall: "school/getClassAssignHall",
      //   getSubjects: "school/getSubjects",
    }),
    calcuteteAllAmount(data) {
      let sum = data.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.paid;
      }, 0);
      return sum;
    },

    async getStudents() {
      await this.axios
        .get(`${this.base_url}student-list/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // page_size:1000,
            // page:1
            class: this.filter_data.fk_class?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // year: this.filter_data.fk_year,
          },
        })
        .then((response) => {
          this.students = response.data;
        });
    },
    // async getFeeType() {
    //   await this.axios
    //     .get(`${this.base_url}fee-types/`, {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     })
    //     .then((response) => {
    //       this.fees_types = response.data;
    //     })
    //     .catch((error) => {});
    // },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getStudentName(fk_year) {
    //   const year = this.students.find((year) => year.id === fk_year);
    //   return year.name_ar;
    // },
    // getClassName(fk_class) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name_ar;
    // },
    // getFeeTypeName(fk_class) {
    //   const objectClass = this.fees_types.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/students/installments`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            fk_class: this.filter_data.fk_class.id,
            // fk_year: this.filter_data.fk_year,
            fk_fees_type: this.filter_data.fk_fee.id,
            fk_student: this.filter_data.fk_student.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            // this.totalItems=response.data.pagination.count
            // this.length=response.data.pagination.num_pages
            // this.pagination=response.data.pagination
            // this.itemsPerPage[4]['value']=this.totalItems
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  //   validations() {
  //     return {
  //       filter_data: {
  //         fk_class: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //         fk_student: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //         fk_fee: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //       },
  //     };
  //   },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
