<template>
  <VCard class="card-table">
    <!-- ######## Header Of Data Table  ####### -->
    <div>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" color="#ccc">
            <v-icon style="color: #5e7e96" class="me-2"
              >mdi-eye-outline</v-icon
            >
            <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header.key"
              :label="header.title"
              :value="header.key"
              color="indigo"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-text-field
        color="indigo"
        v-model="text_search"
        @keyup.enter="getAssignedTeatchers((page = 1))"
        @input="!text_search ? getAssignedTeatchers((page = 1)) : ''"
        class="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :placeholder="$t('global.data_table.search_here')"
        density="compact"
        variant="text"
      >
      </v-text-field>
      <div
        v-if="CheckGetPermission('school.add_teachersubjectclass')"
        class="d-flex justify-end"
        style="flex: 1"
      >
        <VBtn class="bg-success" @click="assignNewTeacher">
          <span class="text-white">{{ $t("global.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <v-card>
      <!-- ######## End Header Of Data Table Server ####### -->
      <!-- <v-progress-linear
        color="indigo"
        class="my-1"
        :indeterminate="loading"
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear> -->
      <v-data-table-server
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        fixed-footer
        :items-per-page="perPage"
        id="vDataTable"
        v-model:sort-by="sortBy"
        @update:options="getAssignedTeatchers((page = 1))"
        density="compact"
        class="elevation-1"
        :loading="loading"
      >
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_teachersubjectclass')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editAssignTeacher(item.raw.id)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_teachersubjectclass')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="(assign_del_id = item.raw.id), (del_dialog = true)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex">
                        <v-pagination  v-model="page" :length="length"  ></v-pagination>
                        <div class="d-flex align-center">
                            <span>{{$t('globals.per-page')}} </span>
                            <v-select v-model="perPage" :items="itemsPerPage"  item-value="value" item-title="text" variant="solo"></v-select>
                        </div>
                    </div> -->
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ totalItems }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table-server>
      <!-- ############### Dialog Delete  ################### -->
    </v-card>
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (assign_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteAssignment">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      assigned_teachers: [],
      del_dialog: false,
      assign_del_id: undefined,
      perPage: 10,
      length: 0,
      page: 1,
      pagination: {},
      totalItems: null,
      sortBy: [{ key: "fk_teacher", order: "asc" }],
      loading: false,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 20, text: "20" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 200, text: this.$t("global.data_table.all") },
      // ],
      headers: [
        { title: this.$t("school.teacher.name"), key: "fk_teacher" },
        { title: this.$t("school.subject.name"), key: "fk_subject" },
        { title: this.$t("school.class.name"), key: "fk_class" },
        { title: this.$t("school.division.name"), key: "fk_division" },
        { title: this.$t("school.semester.name"), key: "fk_semester" },
        { title: this.$t("school.academic_year.name"), key: "fk_year" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "fk_teacher",
        "fk_subject",
        "fk_class",
        "fk_division",
        "fk_semester",
        "fk_year",
        "note",
        "actions",
      ],
      text_search: undefined,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.page = this.current_page;
      // this.perPage = this.items_count;
      if (!this.years.length) await this.getAcademicYears({});
      if (!this.classes.length) await this.getClasses();
      if (!this.subjects.length) await this.getSubjects({});
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.semesters.length) await this.getSemesters({});
      if (!this.teachers.length) await this.getAllTeachers({});
      await this.getAssignedTeatchers();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      subjects: (state) => state.school.subjects,
      classes: (state) => state.school.classes,
      semesters: (state) => state.school.semesters,
      divisions: (state) => state.school.divisions,
      teachers: (state) => state.school.teachers,
      years: (state) => state.school.academic_years,
      current_page: (state) => state.school.current_page,
      items_count: (state) => state.school.items_count,
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      this.assigned_teachers.forEach((element) => {
        let subject = this.subjects.find(
          (item) => item.id == element.fk_subject
        );
        let classe = this.classes.find((item) => item.id == element.fk_class);
        let semester = this.semesters.find(
          (item) => item.id == element.fk_semester
        );
        let year = this.years.find((item) => item.id == element.fk_year);
        let division = this.divisions.find(
          (item) => item.id == element.fk_division
        );
        let teacher = this.teachers.find(
          (item) => item.id == element.fk_teacher
        );
        try {
          list.push({
            fk_subject: subject.name_ar,
            fk_class: classe.name_ar,
            fk_semester: semester.name_ar,
            fk_year: year.year,
            fk_division: division.name,
            fk_teacher: teacher.name_ar,
            note: element.note,
            id: element.id,
          });
        } catch (e) {}
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getAllTeachers: "school/getAllTeachers",
      changeCurrentPage: "school/changeCurrentPage",
      getAcademicYears: "school/getAcademicYears",
    }),
    assignNewTeacher() {
      this.changeCurrentPage({ page: this.length, count: this.perPage });
      this.$router.push("add");
    },
    editAssignTeacher(id) {
      this.changeCurrentPage({ page: this.page, count: this.perPage });
      this.$router.push(`${id}/edit/`);
    },
    async deleteAssignment() {
      if (this.assign_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}assign-teacher/${this.assign_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getAssignedTeatchers();
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.del_dialog = false;
        this.assign_del_id = undefined;
      }
    },
    async getAssignedTeatchers(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}assign-teacher/`, {
        params: {
          search: this.text_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.assigned_teachers = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[5].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getAssignedTeatchers();
    },
    page() {
      this.getAssignedTeatchers(this.page);
    },
  },
};
</script>
<style scoped>
#vDataTable span {
  font-size: 11px;
}
</style>
