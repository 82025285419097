<template>
    <v-btn 
        icon="mdi-power" 
        color="error" 
        size="small" 
        variant="text"
        @click="overlay=!overlay">
    </v-btn>
    <v-overlay v-model="overlay"></v-overlay>
<v-card class="h-100">
    <v-card-title>
        <v-icon icon="mdi-account-plus"/>
        <h3 class="d-inline" style="padding:0 4px;" >{{$t('teacher.add')}}</h3>
        <v-divider style="border-width:1px; opacity: 0.5;" class="my-2"/>
    </v-card-title>
    <v-row justify="center" v-if="alertMessage" class="mb-4 "> 
        <v-col cols="9">
            <v-alert 
                closable
                density="compact"
                border="start"
                variant="outlined"
                color="success"
                :icon="alertIcon"
                :title="alertTitle"
                :text="alertMessage"
            >
            </v-alert>
        </v-col>
    </v-row> 
    <div justify="center" class="mb-4"  >
        <v-card title=" " class="w-100 pa-2">
            <v-form  ref="form" @submit.prevent="saveteacher" >
                <v-tabs
                v-model="tab"
                color="indigo"
            >
                <v-tab 
                    v-for="item in items"
                    :key="item.key"
                    :value="item.key"
                >
                {{item.value}}
                </v-tab>
                </v-tabs>
                <v-window v-model="tab">
                    <v-window-item
                        :value="items[0].key"
                    >
                        <v-card border="start" >
                            <v-card-title class=" mb-8 bg-ccc">{{$t('globals.personal-information')}}</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="9">
                                        <v-row>
                                            <v-col cols="4" md="6" sm="12" xs="12">  
                                                <v-text-field 
                                                counter="30"  
                                                type="input" 
                                                v-model="teacher.name_ar"  
                                                density="compact" clearable 
                                                :label="$t('globals.name-ar')" 
                                                :error-messages="v$.teacher.name_ar.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.name_ar.$touch"
                                                @blur="v$.teacher.name_ar.$touch"
                                                    />
                                            </v-col>
                                            <v-col cols="4" md="6" sm="12" xs="12">
                                                <v-text-field 
                                                    counter="30" v-model="teacher.name_en" 
                                                    hide-details="auto" 
                                                    density="compact" 
                                                    clearable 
                                                    :label="$t('globals.name-en')"
                                                    :error-messages="v$.teacher.name_en.$errors.map(e=>e.$message)" 
                                                    @input="v$.teacher.name_en.$touch"
                                                    @blur="v$.teacher.name_en.$touch"
                                                    />
                                            </v-col >
                                        </v-row>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-select
                                                    v-model="teacher.nationality" 
                                                    density="compact"
                                                    item-title="val"
                                                    item-value="id" 
                                                    :items="[{'id':0,'val':'يمني'},{'id':1,'val':'سعودي'}]"
                                                    :label="$t('globals.nationality')"
                                                    :error-messages="v$.teacher.nationality.$errors.map(e=>e.$message)" 
                                                    @input="v$.teacher.nationality.$touch"
                                                    @blur="v$.teacher.nationality.$touch"
                                                />
                                            </v-col>
                                            <v-col cols="3">
                                                <v-select
                                                    v-model="teacher.marital_status" 
                                                    density="compact"
                                                    item-title="val"
                                                    item-value="id" 
                                                    :items="[{'id':0,'val':$t('globals.single')},{'id':1,'val':$t('globals.married')}]"
                                                    :label="$t('globals.marital-status')"
                                                    :error-messages="v$.teacher.marital_status.$errors.map(e=>e.$message)" 
                                                    @input="v$.teacher.marital_status.$touch"
                                                    @blur="v$.teacher.marital_status.$touch"
                                                />
                                            </v-col>
                                            <v-col cols="6"> 
                                                <v-text-field
                                                    v-model="teacher.birthdate"
                                                    density="compact"
                                                    type="date"
                                                    :label="$t('globals.birthdate')"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row>
                                            <v-col cols="6">
                                                <v-text-field 
                                                counter="50"  
                                                type="input" 
                                                v-model="teacher.email"
                                                density="compact" clearable 
                                                :label="$t('globals.email')" 
                                                :error-messages="v$.teacher.email.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.email.$touch"
                                                @blur="v$.teacher.email.$touch"
                                                    />
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field 
                                                counter="100"  
                                                type="input" 
                                                v-model="teacher.website"  
                                                density="compact" clearable 
                                                :label="$t('globals.website')" 
                                                :error-messages="v$.teacher.website.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.website.$touch"
                                                @blur="v$.teacher.website.$touch"
                                                    />
                                            </v-col>
                                            
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-text-field 
                                                counter="10"  
                                                type="input" 
                                                v-model="teacher.phone"  
                                                density="compact" clearable 
                                                :label="$t('globals.phone')" 
                                                :error-messages="v$.teacher.phone.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.phone.$touch"
                                                @blur="v$.teacher.phone.$touch"
                                                    />
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field 
                                                counter="20"  
                                                type="input" 
                                                v-model="teacher.mobile"  
                                                density="compact" clearable 
                                                :label="$t('globals.mobile')" 
                                                :error-messages="v$.teacher.mobile.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.mobile.$touch"
                                                @blur="v$.teacher.mobile.$touch"
                                                    />
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field 
                                                counter="20"  
                                                type="input" 
                                                v-model="teacher.box_mail"  
                                                density="compact" clearable 
                                                :label="$t('globals.box-mail')" 
                                                :error-messages="v$.teacher.box_mail.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.box_mail.$touch"
                                                @blur="v$.teacher.box_mail.$touch"
                                                    />
                                            </v-col>
                                        </v-row> -->
                                    </v-col> 
                                    <v-col cols="3" >
                                        <h1>..</h1>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-window-item>
                    <v-window-item
                        :value="items[1].key"
                    >
                        <v-card border="start" >
                            <v-card-title class=" mb-8 bg-ccc">{{$t('globals.employment-information')}}</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="9">
                                        <v-row>
                                            <v-col cols="4" md="6" sm="12" xs="12">  
                                                <v-text-field 
                                                counter="30"  
                                                type="input" 
                                                v-model="teacher.job_title"  
                                                density="compact" clearable 
                                                :label="$t('globals.job-title')" 
                                                :error-messages="v$.teacher.job_title.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.job_title.$touch"
                                                @blur="v$.teacher.job_title.$touch"
                                                    />
                                            </v-col>
                                            <v-col cols="4" md="6" sm="12" xs="12">
                                                <v-text-field 
                                                    counter="30" v-model="teacher.service_status" 
                                                    hide-details="auto" 
                                                    density="compact" 
                                                    clearable 
                                                    :label="$t('globals.service-status')"
                                                    :error-messages="v$.teacher.service_status.$errors.map(e=>e.$message)" 
                                                    @input="v$.teacher.service_status.$touch"
                                                    @blur="v$.teacher.service_status.$touch"
                                                    />
                                            </v-col >
                                        </v-row>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-select
                                                    v-model="teacher.nationality" 
                                                    density="compact"
                                                    item-title="val"
                                                    item-value="id" 
                                                    :items="[{'id':0,'val':'يمني'},{'id':1,'val':'سعودي'}]"
                                                    :label="$t('globals.nationality')"
                                                    :error-messages="v$.teacher.nationality.$errors.map(e=>e.$message)" 
                                                    @input="v$.teacher.nationality.$touch"
                                                    @blur="v$.teacher.nationality.$touch"
                                                />
                                            </v-col>
                                            <v-col cols="3">
                                                <v-select
                                                    v-model="teacher.marital_status" 
                                                    density="compact"
                                                    item-title="val"
                                                    item-value="id" 
                                                    :items="[{'id':0,'val':$t('globals.single')},{'id':1,'val':$t('globals.married')}]"
                                                    :label="$t('globals.marital-status')"
                                                    :error-messages="v$.teacher.marital_status.$errors.map(e=>e.$message)" 
                                                    @input="v$.teacher.marital_status.$touch"
                                                    @blur="v$.teacher.marital_status.$touch"
                                                />
                                            </v-col>
                                            <v-col cols="6"> 
                                                <v-text-field
                                                    v-model="teacher.birthdate"
                                                    density="compact"
                                                    type="date"
                                                    :label="$t('globals.birthdate')"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row>
                                            <v-col cols="6">
                                                <v-text-field 
                                                counter="50"  
                                                type="input" 
                                                v-model="teacher.email"
                                                density="compact" clearable 
                                                :label="$t('globals.email')" 
                                                :error-messages="v$.teacher.email.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.email.$touch"
                                                @blur="v$.teacher.email.$touch"
                                                    />
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field 
                                                counter="100"  
                                                type="input" 
                                                v-model="teacher.website"  
                                                density="compact" clearable 
                                                :label="$t('globals.website')" 
                                                :error-messages="v$.teacher.website.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.website.$touch"
                                                @blur="v$.teacher.website.$touch"
                                                    />
                                            </v-col>
                                            
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-text-field 
                                                counter="10"  
                                                type="input" 
                                                v-model="teacher.phone"  
                                                density="compact" clearable 
                                                :label="$t('globals.phone')" 
                                                :error-messages="v$.teacher.phone.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.phone.$touch"
                                                @blur="v$.teacher.phone.$touch"
                                                    />
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field 
                                                counter="20"  
                                                type="input" 
                                                v-model="teacher.mobile"  
                                                density="compact" clearable 
                                                :label="$t('globals.mobile')" 
                                                :error-messages="v$.teacher.mobile.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.mobile.$touch"
                                                @blur="v$.teacher.mobile.$touch"
                                                    />
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field 
                                                counter="20"  
                                                type="input" 
                                                v-model="teacher.box_mail"  
                                                density="compact" clearable 
                                                :label="$t('globals.box-mail')" 
                                                :error-messages="v$.teacher.box_mail.$errors.map(e=>e.$message)" 
                                                @input="v$.teacher.box_mail.$touch"
                                                @blur="v$.teacher.box_mail.$touch"
                                                    />
                                            </v-col>
                                        </v-row> -->
                                    </v-col> 
                                    <v-col cols="3" >
                                        <h1>..</h1>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-window-item>
                </v-window>
            </v-form>
            <v-card-actions>

                <v-btn @click="handleFunction(teacher.id)" style="background:#4caf50;"   color="white" size="small">
                    {{$t('globals.save')}}
                    <v-icon icon="mdi-content-save" end></v-icon>
                </v-btn>
                <v-btn  @click="clean" class="bg-indigo"  size="small">
                    {{$t('globals.clear')}}
                    <v-icon icon="mdi-reload" end></v-icon>
                </v-btn>
                
            </v-card-actions>
        </v-card>
            
    </div>

</v-card>
</template>

<script>

import useValidate from '@vuelidate/core'
import {email,required,helpers,maxLength,url,alpha,alphaNum} from '@vuelidate/validators'
import ImageInput from '@/components/Libraries/ImageInput.vue'
const nameRegular=helpers.regex(/^[\u0621-\u064A\u0660-\u0669a-zA-Z\s]*$/)
const dateValidation=function(value){
    const date=new Date().toJSON().slice(0,10)
    return (value&&value>date)?true:false
}

export default{
    name:"AddTeacher",
    data(){
        return{
            v$: useValidate(),
            overlay:false,
            create:true,
            alert:false,
            alertTitle:'',
            alertMessage:'',
            alertIcon:'',
            imageRender:'',
            teacher:{
                name_ar:'',
                name_en:'',
                type:'',
                nationality:'',
                birthdate:'',
                address:'',
                phone_number:'',
                mobile_number:'',
                num_of_sons:'',
                num_of_girls:'',
                service_status:'',
                place:'',
                num_of_job:'',
                date_of_employment:'',
                job_title:'',
                work_time:'',
                marital_status:'',
                note:''
            },
            defaultTeacher:{
                name_ar:'',
                name_en:'',
                type:'',
                nationality:'',
                birthdate:'',
                address:'',
                phone_number:'',
                mobile_number:'',
                num_of_sons:'',
                num_of_girls:'',
                service_status:'',
                place:'',
                num_of_job:'',
                date_of_employment:'',
                job_title:'',
                work_time:'',
                marital_status:'',
                note:''
            },
            teachers:[],
            tab:null

        }
    },
    components:{
        ImageInput
    },
    computed:{
        items(){
            return[
                {key:'personal_information',value:this.$t('globals.personal-information')},
                {key:'employment_information',value:this.$t('globals.employment-information'),},
                
            ]
                
            
        }
    },

    validations(){
        return{
            teacher:{
                name_ar:{},
                name_en:{},
                type:{},
                nationality:{},
                birthdate:{},
                address:{},
                phone_number:{},
                mobile_number:{},
                num_of_sons:{},
                num_of_girls:{},
                service_status:{},
                place:{},
                num_of_job:{},
                date_of_employment:{},
                job_title:{},
                work_time:{},
                marital_status:{},
                note:{}
                

            },
            
        }
    },
    methods:{
        back(){
            this.addDialog=false
            this.create=true
            this.clean()
        },
        selectImage(e){
            this.teacher.image=e
        },
        submitForm(){
            this.v$.$validate()
        },
        handleFunction(id){
            this.submitForm()
            if(this.create){
                this.saveteacher()
            }else{
                this.updateteacher(id)
            }
        },
        async saveteacher(){
            
            if(!this.v$.$error){
                return await this.axios.post(`${this.base_url}/teachers/`,this.teacher,{
                headers:{
                        "Content-Type":"multipart/form-data",
                        "Accept-Language":"en-US;",
                    }
                })
                .then((response)=>{
                    this.back()
                    this.alertTitle=this.$t('globals.complete-add')
                    this.alertIcon="$success"
                    this.alertMessage=this.$t('globals.complete-add')
                }).catch((error)=>{
                })
            }
        },
        async updateteacher(id){
            
            
            if(typeof(this.teacher["image"])==="string"){
                delete this.teacher["image"]
            }
            if(!this.v$.$error){
                return await this.axios.put(`${this.base_url}/teachers/${id}/`,this.teacher,{
                headers:{
                        "Content-Type":"multipart/form-data"
                    }
                })
                .then((response)=>{
                    this.alertIcon='$success'
                    this.alertTitle=this.$t('globals.complete-update')
                    this.alertMessage=`${this.teacher.full_nam} ${this.$t('globals.complete-update')} `
                    this.back()

                }).catch((error)=>{
                    this.errors=error.response.data
                })
            }
        },
        clean(){
            this.v$.$reset()
            this.imageRender=''
            this.$errors={}
            this.$nextTick(()=>{
                this.teacher=Object.assign({},this.defaultteacher)
            })
            
       },
    },
    mounted(){
    },
    watch:{
        // overlay(val){
        //     val && setTimeout(() => {
        //         this.overlay=false
        //     }, 2000);
        // }

    }
    
}
</script>
<style >
div > input[type="date"]{
    padding: 0 !important;
    color: inherit;
}
.v-btn .v-icon{
    font-size: 18px !important;
}
</style>
