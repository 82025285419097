<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes_by_branch"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <!-- <cliche class="mb-2"></cliche> -->
    <!-- <h3 class="text-center text-grey-darken-2">
      <h3>{{ $t("report.student-by-class-year") }}</h3>
      <h4>
        {{ $t("school.reports.for_year") }} dfgdfgdfgdfgdfg {{ $t("school.reports.for_class") }}
        dfgdfgdfg
      </h4>
    </h3> -->
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  v-for="(header, index) in selectedHeaders"
                  :class="`border px-3 ${
                    header.key == 'student_name' ? '' : 'text-center'
                  }`"
                  :key="index"
                >
                  <template v-if="index === 0">
                    {{ (page - 1) * perPage + key + 1 }}
                  </template>
                  <template v-else>
                    {{ item[header.key] ? item[header.key] : "-" }}
                  </template>
                </td>
                <!-- <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td class="border px-3" v-if="selectedHead.includes('name_ar')">
                  {{ item.student["name_ar"] }}
                </td> -->
              </tr>
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // this.$store.dispatch(
      //   "updatePermission",
      //   localStorage.getItem("userinfo")
      // );
      await this.getBrenches({});
      await this.getBranchClasses();
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //     // console.log(this.school[0]);
      //   });
      // if (this.getUserRole > 1) {
      // }
      // await this.getYears();
      // await this.getDivisions();
      // await this.getSemesters();
      // await this.getSubjects();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // year_data: null,
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      page: 1,
      perPage: 25,
      selectedHead: [
        "no",
        "name_ar",
        `${localStorage.getItem("role") < 2 ? "branch" : ""}`,
      ],
      totalItems: null,
      headers: [
        {
          title: "#",
          key: "no",
        },
        {
          title: this.$t("school.reports.student_name"),
          key: "name_ar",
        },
        {
          title: this.$t("school.reports.branch"),
          key: "branch",
        },
      ],

      filter_data: {
        fk_branch: null,
        fk_class: null,
      },
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      classes_by_branch: (state) => state.school.classes_by_branch,
      // classes_by_branch: (state) => state.school.classes_by_branch,
      branch: (state) => state.school.branch,
      // user: (state) => state.User,
    }),
    selectedHeaders() {
      let filtered = this.selectedHead.filter((header) => {
        if (header === "branch") {
          return localStorage?.getItem("role") < 2
            ? !this.filter_data.fk_branch
            : false;
        }
        return true;
      });
      return this.headers.filter((header) => filtered.includes(header.key));
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBranchClasses: "school/getBranchClasses",
      getBrenches: "school/getBrenches",
    }),
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getClassName(id) {
    //   var classes = this.classes.find((el) => el.id == id);
    //   if (classes) {
    //     return classes.name_ar;
    //   }
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable() {
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/students_by_class`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: page,
            page_size: this.perPage,
            fk_class: this.filter_data.fk_class?.id,
            fk_branch: this.filter_data.fk_branch?.id,
            // fk_year: this.filter_data.fk_year,
          },
        })
        .then((response) => {
          if (response.data) {
            this.items = response.data?.results ?? response.data;
            this.totalItems = response.data?.pagination?.count;
            this.exist = true;

            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       fk_class: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>

<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}

th {
  font-size: 14px;
  padding-bottom: 2px;
}

.head {
  font-size: 1.1rem;
}

.text {
  font-size: 0.85rem;
}
</style>
