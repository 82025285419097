<template>
  <VCard class="justify-center">
    <VCardText
      class="card-fields"
      v-if="
        CheckGetPermission('school.add_hall') ||
        (is_update && CheckGetPermission('school.change_hall'))
      "
    >
      <VForm ref="form">
        <VRow>
          <VCol cols="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="building"
                v-model="hall.fk_building"
                prepend-inner-icon="mdi-office-building-outline"
                item-title="name"
                item-value="id"
                :label="$t('school.buldings_data.building_select')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
                @update:model-value="
                  hall?.name ? $refs.hall_name.validate() : ''
                "
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                ref="hall_name"
                v-model="hall.name"
                prepend-inner-icon="mdi-floor-plan"
                :label="$t('school.hall_data.hall_name')"
                :placeholder="$t('school.hall_data.placeholder_hall_name')"
                density="compact"
                clearable
                :rules="[
                  $required,
                  $max_length(100),
                  duplicate('name', 'fk_building', hall, halls_data),
                ]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol class="pt-0 px-3" cols="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                v-model="hall.floor_number"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.hall_data.hall_floor_number')"
                :placeholder="
                  $t('school.hall_data.placeholder_hall_floor_number')
                "
                density="compact"
                type="number"
                clearable
                :rules="[
                  $required,
                  $numeric,
                  $max_value(
                    this.hall.fk_building
                      ? this.building.find(
                          (build) => build.id == this.hall.fk_building
                        ).floors_number
                      : 30
                  ),
                ]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol class="pt-0 px-3" cols="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                v-model="hall.seats_number"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.hall_data.hall_seats_number')"
                :placeholder="
                  $t('school.hall_data.placeholder_hall_seats_number')
                "
                density="compact"
                type="number"
                clearable
                :rules="[$required, $numeric, $max_value(200)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="pa-0 ma-0" md="8">
            <VCardItem class="px-3">
              <VTextarea
                v-model="hall.note"
                clearable
                :label="$t('global.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                prepend-inner-icon="mdi-note-outline"
                :rules="[$max_length(250)]"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VForm>
    </VCardText>
    <VCardActions
      class="px-8"
      v-if="
        CheckGetPermission('school.add_hall') ||
        (is_update && CheckGetPermission('school.change_hall'))
      "
    >
      <VBtn
        v-if="!is_update && CheckGetPermission('school.add_hall')"
        class="bg-indigo"
        @click="saveHall"
        :loading="is_loading"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && CheckGetPermission('school.change_hall')"
        class="bg-success"
        @click="updateHall"
        size="small"
        :loading="is_loading"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (hall_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteHall">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      hall: {},
      is_update: false,
      is_loading: false,
      halls_data: null,
      hall_data_original: null,
      duplicate: (field1, field2, entry, table_data) => (value) => {
        return (
          !value ||
          !table_data.find(
            (item) =>
              (item.id !== entry?.id ?? false) &&
              item[field1] === entry[field1] &&
              item[field2] === entry[field2]
          ) ||
          this.$t("global.error.failure.hall_exist_in_building")
        );
      },
    };
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      await this.getBuilding({});
      await this.getHalls();
      if (this.id != undefined) {
        await this.axios
          .get(`${this.base_url}/hall/${this.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.hall = response.data;
            this.hall_data_original = response.data;
          });
        this.is_update = true;
      }
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      building: (state) => state.school.building,
      // user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getBuilding: "school/getBuilding",
    }),
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "hallslist" });
      } else {
        this.$router.push({ name: "hallsadd" });
        this.is_update = false;
        this.$refs.form.reset();
      }
    },
    async saveHall() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(this.base_url + "/hall/", this.hall, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_added"));
            this.resetForm();
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    async updateHall() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}/hall/${this.hall.id}/`, this.hall, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            // this.$router.push({ name: "hallslist" });
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            // setTimeout(() => {
            // }, 2000);
            this.resetForm();
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },

    async getHalls() {
      await this.axios(`${this.base_url}/hall/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.halls_data = response.data.results;
        })
        .catch((err) => {});
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
