<template>
  <v-row class="mt-4 text-grey-darken-2 report-header">
    <v-col cols="4" class="text-center right-header">
      <slot v-if="!en || true" name="right">
        <h1>
          {{ ar ? "الجمهورية اليمنية" : $t("school.reports.rep_of_yemen_ar") }}
        </h1>
        <h2>
          {{
            ar
              ? "وزارة التربية والتعليم"
              : $t("school.reports.prime_minister_office")
          }}
        </h2>
        <h3 v-if="school">
          {{
            ar
              ? school.arabic_name
              : $i18n.locale == "ar"
              ? school.arabic_name
              : school.english_name
          }}
        </h3>
        <h4 v-if="school">
          {{ school?.branch_name }}
        </h4>
      </slot>
      <!-- <slot v-else name="right">
        <h1>{{ $t("school.reports.rep_of_yemen_en") }}</h1>
        <h2>
          {{ $t("school.reports.prime_minister_office_en") }}
        </h2>
        <h3 v-if="school">
          {{ school?.english_name }}
        </h3>
        <h4 v-if="school">
          {{ school?.branch_name_en }}
        </h4>
      </slot> -->
    </v-col>
    <v-col cols="4" class="text-center">
      <h4 v-if="!en">
        {{
          ar
            ? "بسم الله الرحمن الرحيم"
            : $t("school.reports.in_the_name_of_allah")
        }}
      </h4>
      <h4 v-else>{{ $t("school.reports.in_the_name_of_allah_en") }}</h4>
      <v-responsive v-if="school">
        <v-avatar size="80">
          <img :src="school?.logo" class="object-cover" />
        </v-avatar>
      </v-responsive>
    </v-col>
    <v-col cols="4" :class="`text-center ${rep_en ? '' : 'mt-2'} left-header`">
      <slot v-if="rep_en" name="left">
        <h1>{{ $t("school.reports.rep_of_yemen_en") }}</h1>
        <h2>
          {{ $t("school.reports.prime_minister_office_en") }}
        </h2>
        <h3 v-if="school">
          {{ school.english_name }}
        </h3>
        <h4 v-if="school">
          {{ school?.branch_name_en }}
        </h4>
      </slot>
      <slot v-else name="left">
        <h3>
          <div>
            {{ $t("school.reports.study_year") }}
            :
            {{ getYears() }}
          </div>
        </h3>
        <h3 v-for="filter in left_header" :key="filter">
          <div v-if="data[filter]">
            {{ $t(`school.reports.${filter.replace("fk_", "")}`) }} :
            {{
              data.fk_student?.name_en && $i18n.locale == "en"
                ? data[filter]?.name_en ??
                  data[filter]?.name ??
                  data[filter]?.itinerary ??
                  data[filter]?.title ??
                  data[filter]
                : data[filter]?.name_ar ??
                  data[filter]?.name ??
                  data[filter]?.itinerary ??
                  data[filter]?.title ??
                  data[filter]
            }}
          </div>
          <div v-else-if="filter == 'fk_branch' && getUserRole < 2">
            {{ $t(`school.reports.${filter.replace("fk_", "")}`) }} :
            {{ $t(`school.reports.all_${filter.replace("fk_", "")}`) }}
          </div>
          <!-- <div v-if="filter == 'fk_branch' && data[filter] == null && getUserRole < 2">
            {{ $t(`school.reports.${filter.replace("fk_", "")}`) }} : (كل الفروع)
          </div> -->
        </h3>
        <!-- <h3 v-if="data.fk_semester">
          {{ $t("school.reports.semester") }} :
          {{ data.fk_semester?.name_ar ?? data.fk_semester?.name }}
        </h3>
        <h3 v-if="data.fk_country">
          {{ $t("school.reports.country") }} :
          {{ data.fk_country?.name_ar ?? data.fk_country?.name }}
        </h3>
        <h3 v-if="data.fk_governorate">
          {{ $t("school.reports.governorate") }} :
          {{ data.fk_governorate?.name_ar ?? data.fk_governorate?.name }}
        </h3>
        <h3 v-if="data.fk_branches">
          {{ $t("school.reports.branch") }} :
          {{ data.fk_branches?.name_ar ?? data.fk_branches?.name }}
        </h3>
        <h3 v-if="data.fk_class">
          {{ $t("school.reports.class_name") }} :
          {{ data.fk_class?.name_ar ?? data.fk_class?.name }}
        </h3>
        <h3 v-if="data.fk_division">
          {{ $t("school.reports.division_name") }} :
          {{ data.fk_division?.name_ar ?? data.fk_division?.name }}
        </h3>
        <h3 v-if="data.fk_subject">
          {{ $t("school.reports.subjects_name") }} :
          {{ data.fk_subject?.name_ar ?? data.fk_subject?.name }}
        </h3>
        <h3 v-if="data.fk_parent">
          {{ $t("school.reports.parent_name") }} :
          {{ data.fk_parent?.name_ar ?? data.fk_parent?.name }}
        </h3>
        <h3 v-if="data.fk_student">
          {{ $t("school.reports.student_name") }} :
          {{
            data.fk_student?.name_en && $i18n.locale == "en"
              ? data.fk_student?.name_en
              : data.fk_student?.name_ar ?? data.fk_student?.name
          }}
        </h3>
        <h3 v-if="data.fk_bus">
          {{ $t("school.reports.bus") }} :
          {{ data.fk_bus?.name_ar ?? data.fk_bus?.name ?? data.fk_bus.title }}
        </h3>
        <h3 v-if="data.fk_paid">
          {{ $t("school.reports.paid_from_installment") }} :
          {{ data.fk_paid ?? data.fk_paid }}
        </h3>
        <h3 v-if="data.fk_activity">
          {{ $t("school.reports.activity_name") }} :
          {{ data.fk_activity?.name_ar ?? data.fk_activity?.name }}
        </h3> -->
      </slot>
      <!-- <h4>
        {{ $t("school.reports.division") }} :
        {{ data?.name_ar ?? data.name }}
      </h4> -->
    </v-col>
  </v-row>
  <!-- text-grey-darken-2 -->
  <h2 class="text-center mt-3">{{ title ? title : HeaderTitle }}</h2>

  <slot v-if="!rep_en && !en">
    <!-- text-grey-darken-2 -->
    <div class="d-flex justify-center" style="gap: 0.5rem">
      <h3 v-if="data.fk_fee" class="text-center" name="bottom">
        {{ $t("school.reports.clause") }} :
        {{ data.fk_fee?.name_ar ?? data?.fk_fee?.name }}
      </h3>
      <h3 v-if="data.fk_month" class="text-center" name="bottom">
        {{ $t("school.reports.month") }} :
        {{ date_type == "h" ? data.fk_month?.name_h : data.fk_month?.name_m }}
      </h3>
      <h3 v-if="data.fk_month && data.fk_estimate">-</h3>
      <h3 v-if="data.fk_estimate" class="text-center" name="bottom">
        {{ $t("school.reports.estimate") }} :
        {{ data.fk_estimate?.name_ar ?? data.fk_estimate?.name }}
      </h3>
      <h3 v-if="data.day_date" class="text-center" name="bottom">
        {{ $t("school.reports.attendance_date") }} :
        {{ data.day_date }}
      </h3>
    </div>
  </slot>
  <slot v-else>
    <div v-if="en">
      <h3 class="text-center">
        {{ $t("school.reports.study_year_en") }}
        :
        {{ getYears() }}
      </h3>
      <!-- <h3 v-if="data.fk_semester" class="text-center" name="bottom">
        {{ $t("school.reports.semester_en") }} :
        {{ data.fk_semester?.name_en }}
      </h3> -->
    </div>
    <div v-else>
      <h3 class="text-center">
        {{ ar ? "السنة الدراسية" : $t("school.reports.study_year") }}
        :
        {{ getYears() }}
      </h3>
    </div>
    <!-- <h3 class="text-center text-grey-darken-2">
      <h3>{{ $t("report.student-by-class-year") }}</h3>
      <h4>
        {{ $t("school.reports.for_year") }} : {{ getYears() }} -
        {{ $t("school.reports.for_class") }} :
        {{ data?.fk_class?.name_ar ?? data?.fk_class?.name }}
      </h4>
    </h3> -->
  </slot>
  <!-- <v-row class="justify-center">
    <slot v-if="rep_en" class="d-flex" name="bottom">
      <h3>
        {{ $t("school.reports.study_year") }}
        :
        {{ getYears() }}
      </h3>
      <h3 v-if="data.fk_class">
        {{ $t("school.reports.class_name") }} :
        {{ data.fk_class?.name_ar ?? data.fk_class?.name }}
      </h3>
      <h3 v-if="data.fk_division">
        {{ $t("school.reports.division_name") }} :
        {{ data.fk_division?.name_ar ?? data.fk_division?.name }}
      </h3>
      <h3 v-if="data.fk_student">
        {{ $t("school.reports.student_name") }} :
        {{
          data.fk_student?.name_en && $i18n.locale == "en"
            ? data.fk_student?.name_en
            : data.fk_student?.name_ar ?? data.fk_student?.name
        }}
      </h3>
      <h3 v-if="data.fk_parent">
        {{ $t("school.reports.parent_name") }} :
        {{ data.fk_parent?.name_ar ?? data.fk_parent?.name }}
      </h3>
      <h3 v-if="data.fk_bus">
        {{ $t("school.reports.bus") }} :
        {{ data.fk_bus?.name_ar ?? data.fk_bus?.name ?? data.fk_bus.title }}
      </h3>
      <h3 v-if="data.fk_paid">
        {{ $t("school.reports.paid_from_installment") }} :
        {{ data.fk_paid ?? data.fk_paid }}
      </h3>
    </slot>
  </v-row> -->

  <!-- <div class="d-flex text-center pt-4">
    <div class="w-25">
      <slot name="header-start">
        <div class="d-flex align-center flex-column justify-center">
          <h2 class="text-h5">
            {{ $t('school.reports.rep_of_yemen_ar') }}
          </h2>
          <h3 class="text-h6 pt-1">
            {{ $t('school.reports.prime_minister_office') }}
          </h3>
          <h4 class="text-subtitle-1 pt-1">
            {{
              $i18n.locale == 'en' ? school.english_name : school.arabic_name
            }}
          </h4>
        </div>
      </slot>
    </div>
    <div class="flex">
      <slot name="header-center">
        <div class="d-flex align-center flex-column">
          <div class="pb-2">
            {{ $t('school.reports.in_the_name_of_allah') }}
          </div>
          <img :src="mainImage" alt="" style="width: 20%" />
        </div>
        <slot name="header-title">
          <div class="d-flex justify-center py-2">
            <div class="text-h5">
              {{ breadcrumb?.length ? breadcrumb[breadcrumb?.length - 1 ?? 2] : title }}
            </div>
          </div>
        </slot>
      </slot>
    </div>
    <div class="p-2 w-25">
      <slot name="header-end">
        <div class="d-flex align-center flex-column">
          <img :src="school.logo" alt="" style="width: 45%" />
        </div>
      </slot>
    </div>
  </div> -->
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: {},
    },
    rep_en: {
      type: Boolean,
      default: false,
    },
    en: {
      type: Boolean,
      default: false,
    },
    ar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainImage: require("@/assets/summer-report-image.png"),
      school: {},
      schoolLogo: null,
      HeaderTitle: this.title,
      date_type: localStorage.getItem("date"),
      left_header: [
        "fk_semester",
        "fk_country",
        "fk_governorate",
        "fk_branch",
        "fk_class",
        "fk_division",
        "fk_teacher",
        "fk_subject",
        "fk_parent",
        "fk_student",
        "fk_bus",
        "fk_paid",
        "fk_activity",
        "fk_committee",
        "fk_group",
        "fk_nationality",
        "period_am",
      ],
      subtitles: ["fk_semester", "fk_country", "fk_governorate"],
    };
  },
  computed: {
    ...mapState({
      breadcrumb: "breadcrumb",
    }),
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  async created() {
    await this.getFeeType;
    await this.updateHeader();
    await this.axios
      .get(`${this.base_url}school-data/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        this.school = response.data[0];
        this.school.branch_name = this.ar
          ? localStorage.getItem("branch_ar")
          : this.$i18n.locale == "ar"
          ? localStorage.getItem("branch_ar")
          : localStorage.getItem("branch_en");
        this.school.branch_name_en = localStorage.getItem("branch_en");
        this.schoolLogo = `${this.school.logo}`;
      });
  },
  methods: {
    getYears() {
      return localStorage.getItem("date") == "h"
        ? localStorage.getItem("year_h")
        : localStorage.getItem("year_m");
    },
    updateHeader() {
      this.HeaderTitle = this.breadcrumb?.length
        ? this.breadcrumb[this.breadcrumb?.length - 1 ?? 2]
        : this.title;
    },
    // async getFeeType() {
    //   await this.axios
    //     .get(`${this.base_url}fee-types/`, {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //     })
    //     .then((response) => {
    //       this.fees_types = response.data;
    //     })
    //     .catch((error) => {});
    // },
    // getFeeTypeName(fk_class) {
    //   const objectClass = this.fees_types.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name;
    // },
  },
  watch: {
    "$i18n.locale"() {
      this.updateHeader();
    },
  },
};
</script>

<style scoped>
.flex {
  flex: 1;
}
* {
  font-family: "Almarai" !important;
  box-sizing: border-box !important;
}
</style>
