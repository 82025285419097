<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist && getUserRole < 2">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            clearable
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>

  <v-card
    class="justify-center"
    id="lectures-report"
    v-if="exist || getUserRole > 1"
  >
    <report-header
      :data="filter_data"
      :rep_en="getUserRole > 1"
    ></report-header>
    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
        :back_button="getUserRole < 2"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  v-for="(header, index) in selectedHeaders"
                  :class="`border px-3 ${
                    header.key == 'student_name' ? '' : 'text-center'
                  }`"
                  :key="index"
                >
                  <template v-if="index === 0">
                    {{ (page - 1) * perPage + key + 1 }}
                  </template>
                  <template v-else>
                    {{ item[header.key] ? item[header.key] : "-" }}
                  </template>
                </td>
              </tr>
              <!-- <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                  {{ key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('name_ar')"
                >
                  {{ item.Student_name_ar }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('name_en')"
                >
                  {{ item.phone_number }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('address')"
                >
                  {{ item.address }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <!-- <div class="d-flex" id="pagination-bar">
                        <v-pagination v-model="page" :length="length" density="compact" show-first-last-page>
                        </v-pagination>
                        <div class="d-flex">
                            <span class="mt-2">{{ $t("school.reports.showing") }}</span>
                            <v-select v-model="perPage" class="pa-0" :items="itemsPerPage" item-value="value"
                                item-title="text" density="compact" hide-details variant="text">
                            </v-select>
                            <span class="mt-2">{{ " " + $t("school.reports.from") + " " }} [
                                {{ items?.length }} ]
                                {{ " " + $t("school.reports.item") + " " }}</span>
                        </div>
                    </div> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        this.getData();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      //   fees_types: [],
      //   students: [],
      exist: false,
      items: [],
      loading: false,
      selectedHead: [
        "no",
        "Student_name_ar",
        "itinerary",
        "phone_number",
        "address",
        "branch_name",
        "class_name",
        "division_name",
      ],
      headers: [
        { title: "#", key: "no" },
        {
          title: this.$t("school.reports.student_name"),
          key: "Student_name_ar",
        },
        { title: this.$t("school.reports.bus"), key: "itinerary" },
        { title: this.$t("school.reports.branch"), key: "branch_name" },
        { title: this.$t("school.reports.class"), key: "class_name" },
        { title: this.$t("school.reports.division"), key: "division_name" },
        { title: this.$t("school.reports.parent_phone"), key: "phone_number" },
        { title: this.$t("school.reports.address"), key: "address" },
      ],
      perPage: 25,
      //   length: 0,
      //   totalItems: 0,
      //   itemsPerPage: [
      //     { value: 25, text: "25" },
      //     { value: 50, text: "50" },
      //     { value: 75, text: "75" },
      //     { value: 100, text: "100" },
      //     { value: 1000000, text: this.$t("school.reports.all") },
      //   ],
      page: 1,
      //   pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
    }),
    selectedHeaders() {
      let filtered = this.selectedHead.filter((header) => {
        if (header === "branch_name") {
          return localStorage?.getItem("role") < 2
            ? !this.filter_data.fk_branch
            : false;
        }
        return true;
      });
      return this.headers.filter((header) => filtered.includes(header.key));
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
    }),
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}bus/api/student/without_bus_fees`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
