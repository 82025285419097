<template>
  <VCard class="justify-center">
    <VForm
      v-if="
        CheckGetPermission('school.add_settingsstudentcommittees') ||
        (id && CheckGetPermission('school.change_settingsstudentcommittees'))
      "
      ref="form"
    >
      <VCard-text class="card-fields">
        <VRow>
          <VCol cols="12" xs="12" sm="12" md="5" lg="5">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="classes"
                v-model="committeePlan.fk_class"
                prepend-inner-icon="mdi-google-classroom"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.class.select')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol cols="12" xs="12" sm="12" md="5" lg="5">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="committee"
                v-model="committeePlan.fk_committee"
                prepend-inner-icon="mdi-group"
                item-title="name"
                item-value="id"
                :label="$t('school.committee.select')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" xs="12" sm="12" md="5" lg="5">
            <VCardItem class="pa-0">
              <VTextField
                v-model="committeePlan.student_no_class"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.student.students_count')"
                :placeholder="$t('school.student.students_count_placeholder')"
                density="compact"
                type="number"
                clearable
                :rules="[$required, $min_value(1), $max_value(100)]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="10" lg="10">
            <VCardItem class="px-3">
              <VTextarea
                v-model="committeePlan.note"
                prepend-inner-icon="mdi-note-outline"
                clearable
                :label="$t('global.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VCard-text>
    </VForm>
    <VCardActions
      v-if="
        CheckGetPermission('school.add_settingsstudentcommittees') ||
        (id && CheckGetPermission('school.change_settingsstudentcommittees'))
      "
      class="px-8 d-flex flex-wrap"
      elevation="4"
    >
      <VBtn
        v-if="!id && CheckGetPermission('school.add_settingsstudentcommittees')"
        class="bg-indigo"
        :loading="is_loading"
        @click="saveCommitteePlan"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="
          id && CheckGetPermission('school.change_settingsstudentcommittees')
        "
        class="bg-success"
        :loading="is_loading"
        @click="updateCommitteePlan"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && CheckGetPermission('school.change_student')"
        class="bg-success"
        :loading="is_loading"
        @click="updateStudent"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("global.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn> -->
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (hall_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteHall">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      committeePlan: {
        fk_committee: undefined,
        fk_class: undefined,
        student_no_class: undefined,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      is_loading: false,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getAllCommittees({});
      if (!this.classes.length) await this.getClasses();
      if (this.id != undefined)
        await this.axios
          .get(`${this.base_url}/committees-plan/${this.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.committeePlan = response.data;
          });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      committee: (state) => state.school.committee,
      classes: (state) => state.school.classes,
      user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAllCommittees: "school/getAllCommittees",
      getClasses: "school/getClasses",
    }),
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "committeesPlanlist" });
      } else {
        this.$refs.form.reset();
        this.is_update = false;
      }
    },
    async saveCommitteePlan() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.committeePlan.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "committees-plan/", this.committeePlan, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_added"));

            this.resetForm();
          })
          .catch((error) => {
            if (error.response?.data?.non_field_errors) {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.committee_excest")
              );
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async updateCommitteePlan() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.committeePlan.id != undefined) {
        this.is_loading = true;
        this.committeePlan.updated_by = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}/committees-plan/${this.committeePlan.id}/`,
            this.committeePlan,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));

            this.resetForm();
            // this.$router.push({ name: "committeesPlanlist" });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.non_field_errors
            )
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.committee_excest")
              );

            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
