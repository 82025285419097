<template>
  <VCard class="justify-center">
    <VForm
      ref="form"
      v-if="
        (is_update && CheckGetPermission('school.change_examsperiod')) ||
        CheckGetPermission('school.add_examsperiod')
      "
    >
      <!-- <VCard-title class="my-4">
                <VIcon icon="mdi-text-box-plus"/>
                <h2 class="d-inline text-grey" style="padding:0 4px;" >{{$t('school.add_examPeriod')}}</h2>
                <v-divider style="border-width:1px; opacity: 0.5;" class="my-2"/>
            </VCard-title> -->
      <VCard-text class="card-fields">
        <VRow>
          <VCol cols="12" xs="12" sm="12" md="8" lg="8">
            <VCardItem class="pa-0">
              <VAutocomplete
                v-model="ExamPeriod.period"
                item-value="id"
                item-title="name"
                :items="period"
                prepend-inner-icon="mdi-timetable"
                :label="$t('school.exams.examPeriod_period')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required, uniquePeriod(ExamPeriod.period)]"
              >
              </VAutocomplete>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" xs="12" sm="12" md="4" lg="4">
            <VCardItem class="pa-0">
              <VTextField
                ref="startTime"
                v-model="ExamPeriod.start_at"
                :label="$t('school.exams.examPeriod_start_at')"
                :placeholder="
                  $t('school.exams.examPeriod_start_at_placeholder')
                "
                density="compact"
                clearable
                type="time"
                @input="validateTime()"
                :rules="[$required, start_at_rule,start_at_enterfire]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
          <VCol cols="12" xs="12" sm="12" md="4" lg="4">
            <VCardItem class="pa-0">
              <VTextField
                ref="endTime"
                v-model="ExamPeriod.end_at"
                :label="$t('school.exams.examPeriod_end_at')"
                :placeholder="$t('school.exams.examPeriod_end_at_placeholder')"
                density="compact"
                clearable
                type="time"
                @input="validateTime()"
                :rules="[$required, end_at_rule,end_at_enterfire]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="8" lg="8">
            <VCardItem>
              <VTextarea
                v-model="ExamPeriod.note"
                clearable
                :label="$t('global.note')"
                prepend-inner-icon="mdi-note-outline"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
              >
              </VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VCard-text>
    </VForm>
    <VCardActions
      v-if="
        (is_update && CheckGetPermission('school.change_examsperiod')) ||
        CheckGetPermission('school.add_examsperiod')
      "
      class="px-8 d-flex flex-wrap"
      elevation="4"
    >
      <VBtn
        v-if="!is_update && CheckGetPermission('school.add_examsperiod')"
        class="bg-indigo"
        :loading="is_loading"
        @click="saveExamPeriod"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && CheckGetPermission('school.change_examsperiod')"
        class="bg-success"
        :loading="is_loading"
        @click="updateExamPeriod"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
          :color="!is_update ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ##########    data Table  ########### -->
    <v-divider class="mt-5" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="card-table">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <v-progress-linear
        color="indigo"
        class="my-1"
        :indeterminate="period.length < 1"
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('school.change_examsperiod')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editExamPeriod(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('school.delete_examsperiod')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="
                      (ExamPeriod_del_id = item.raw.id), (del_dialog = true)
                    "
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (ExamPeriod_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteExamPeriod">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
// import useValidate from '@vuelidate/core'
export default {
  data() {
    return {
      // v$: useValidate(),
      ExamPeriod: {
        period: undefined,
        start_at: undefined,
        end_at: undefined,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      is_update: false,
      del_dialog: false,
      ExamPeriod_del_id: undefined,
      process_alert: undefined,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      headers: [
        { title: this.$t("school.exams.examPeriod_period"), key: "period" },
        { title: this.$t("school.exams.examPeriod_start_at"), key: "start_at" },
        { title: this.$t("school.exams.examPeriod_end_at"), key: "end_at" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: ["period", "start_at", "end_at", "actions"],
      txt_search: undefined,
      is_loading: false,
      search: null,
      perPage: 10,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      start_at_rule: (value) =>
        this.ExamPeriod.end_at == undefined ||
        value < this.ExamPeriod.end_at ||
        this.$t("global.error.validation.ExamPeriod_timing_error"),
      
      end_at_rule: (value) => {
        if (value && this.ExamPeriod.start_at != undefined) {
          return (
            value > this.ExamPeriod.start_at ||
            this.$t("global.error.validation.ExamPeriod_timing_error")
          );
        }
      },
      start_at_enterfire:(value) => {
        let temp = this.exam_period.find(element =>value >= element.start_at.slice(0,5) && value < element.end_at.slice(0,5) && element.id != this.ExamPeriod.id)
        return !temp || this.$t("school.schedule_data.schedule_timing_error2")
      },
      end_at_enterfire:(value) => {
        let temp = this.exam_period.find(element =>{ value <= element.end_at.slice(0,5) && value > element.start_at.slice(0,5) && element.id != this.ExamPeriod.id})
        return !temp || this.$t("school.schedule_data.schedule_timing_error2")
      },
      // this.ExamPeriod.start_at == undefined ||
      // value > this.ExamPeriod.start_at ||
      // this.$t("global.error.validation.ExamPeriod_timing_error"),
      except: null,
      uniquePeriod: (value) => {
        const period = this.period.find((item) => item.id === value);
        if (period) {
          if (
            !this.update &&
            this.tableList.some((item) => item.period === period.name) &&
            value !== this.except
          ) {
            return this.$t("global.error.validation.unique");
          }
        }
        return true;
      },
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.period.length) await this.getPeriod();
      await this.getExamPeriods(true);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      //   user: (state) => state.User,
      period: (state) => state.globals.period,
      exam_period: (state) => state.school.exam_period,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      this.exam_period.forEach((element) => {
        let period = this.period.find((item) => item.id == element.period);
        if (period != undefined) period = period.name;
        list.push({
          period: period,
          start_at: element.start_at,
          end_at: element.end_at,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getPeriod: "globals/getPeriod",
      getExamPeriods: "school/getExamPeriods",
    }),
    resetForm() {
      this.$refs.form.reset();
      //   this.v$.ExamPeriod.$reset();
      this.is_update = false;
    },
    editExamPeriod(data) {
      let exam_period = this.exam_period.find((item) => item.id == data.id);
      this.except = exam_period.period;
      if (exam_period != undefined) {
        for (const key in exam_period) {
          this.ExamPeriod[key] = exam_period[key];
        }
        // this.ExamPeriod.data_entry = undefined;
        // this.ExamPeriod.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveExamPeriod() {
      //   this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.ExamPeriod.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/examsPeriod/", this.ExamPeriod, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));

            this.resetForm();
            this.getExamPeriods(true);
          })
          .catch((error) => {
            try {
              if (error.response.data.branch) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch")
                );
              } else if (error.response.data.semester) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch_semester")
                );
              }
              if (error.response.data.period != undefined)
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.period_is_used")
                );
              else
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.error_in_data")
                );
            } catch {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async updateExamPeriod() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.ExamPeriod.id != undefined) {
        this.is_loading = true;
        this.ExamPeriod.updated_by = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}/examsPeriod/${this.ExamPeriod.id}/`,
            this.ExamPeriod,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));

            this.resetForm();
            this.getExamPeriods(true);
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async deleteExamPeriod() {
      if (this.ExamPeriod_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}/examsPeriod/${this.ExamPeriod_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));

            this.getExamPeriods(true);
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.ExamPeriod_del_id = undefined;
        // this.process_alert = true;
        // this.getExamPeriods();
        // setTimeout(() => {
        //   this.process_alert = false;
        // }, 2000);
      }
    },
    validateTime() {
      if (this.ExamPeriod.start_at && this.ExamPeriod.end_at) {
        this.$refs.startTime.validate();
        this.$refs.endTime.validate();
      }
    },
  },
};
</script>
