<template>
  <VCard class="justify-center">
    <VCardText class="card-fields">
      <VForm
        ref="form"
        v-if="
          CheckGetPermission('school.add_parent') ||
          (id && CheckGetPermission('school.change_parent'))
        "
      >
        <VRow>
          <VCol class="" cols="12" xs="12" sm="12" md="8" lg="8">
            <VRow>
              <VCol class="" cols="12" xs="12" sm="12" md="12" lg="12">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="parent.name"
                    prepend-inner-icon="mdi-abjad-arabic"
                    :label="$t('school.parent.supervisor')"
                    :placeholder="$t('school.parent.parent_name_placeholder')"
                    density="compact"
                    clearable
                    counter="100"
                    :rules="[
                      $required,
                      $max_length(),
                      $min_length(3),
                      $ar_letters_only,
                    ]"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <VCol class="" cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    v-model="parent.identity_type"
                    prepend-inner-icon="mdi-card-account-details"
                    item-value="id"
                    item-title="name"
                    :items="identify_type"
                    :label="$t('global.personal_information.id_type')"
                    persistent-hint
                    density="compact"
                    clearable
                    :rules="[$required]"
                  ></VAutocomplete>
                </VCardItem>
              </VCol>
              <VCol class="" cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="parent.identity_number"
                    prepend-inner-icon="mdi-numeric"
                    :label="$t('global.personal_information.id_number')"
                    :placeholder="
                      $t('global.personal_information.id_number_placeholder')
                    "
                    persistent-hint
                    density="compact"
                    clearable
                    type="number"
                    :rules="[
                      $required,
                      $max_length(14),
                      $min_length(3),
                      $numeric,
                    ]"
                  ></VTextField>
                </VCardItem>
              </VCol>
              <VCol class="" cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="parent.job"
                    prepend-inner-icon="mdi-account-hard-hat"
                    :label="$t('school.parent.job')"
                    :placeholder="$t('school.parent.job_placeholder')"
                    density="compact"
                    clearable=""
                    :rules="[$required, $max_length()]"
                  ></VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <VCol class="" cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="parent.phone_number"
                    prepend-inner-icon="mdi-cellphone-text"
                    :onkeypress="$only_phone_number"
                    :label="$t('global.personal_information.phone_number')"
                    :placeholder="
                      $t('global.personal_information.phone_number_placeholder')
                    "
                    density="compact"
                    clearable
                    :rules="[
                      $required,
                      $max_length(14),
                      $min_length(9),
                      $numeric,
                    ]"
                  ></VTextField>
                  <!-- oninput="this.value = this.value.replace(/[^0-9]/g, '')" -->
                </VCardItem>
              </VCol>
              <VCol class="" cols="12" xs="12" sm="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="parent.home_number"
                    prepend-inner-icon="mdi-phone-classic"
                    :label="$t('global.personal_information.home_number')"
                    :onkeypress="$only_phone_number"
                    :placeholder="
                      $t('global.personal_information.home_number_placeholder')
                    "
                    density="compact"
                    clearable
                    :rules="[
                      $required,
                      $max_length(14),
                      $min_length(6),
                      $numeric,
                    ]"
                  ></VTextField>
                  <!-- oninput="this.value = this.value.replace(/[^0-9]/g, '')" -->
                </VCardItem>
              </VCol>
              <VCol class="" cols="12" xs="12" sm="6" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="parent.address"
                    prepend-inner-icon="mdi-map-marker"
                    :label="$t('global.personal_information.address')"
                    :placeholder="
                      $t('global.personal_information.address_placeholder')
                    "
                    density="compact"
                    clearable
                    :rules="[$required, $max_length()]"
                  ></VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow> </VRow>
            <VRow>
              <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="12" lg="12">
                <VCardItem class="px-3">
                  <VTextarea
                    v-model="parent.note"
                    prepend-inner-icon="mdi-note-outline"
                    clearable
                    :label="$t('global.notes')"
                    :placeholder="$t('global.notes_placeholder')"
                    :rules="[$max_length(250)]"
                    counter="250"
                    no-resize
                    density="compact"
                    rows="3"
                  ></VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
          </VCol>
          <VCol class="" cols="12" xs="12" sm="12" md="3" lg="3">
            <ImageInput
              :reset="image_reset"
              :image="parent.identity_image"
              :text="$t('school.parent.parent_id_image')"
              @ImageSelected="saveIdImage"
            ></ImageInput>
          </VCol>
        </VRow>
      </VForm>
    </VCardText>
    <VCardActions
      class="px-8"
      v-if="
        CheckGetPermission('school.add_parent') ||
        (id && CheckGetPermission('school.change_parent'))
      "
    >
      <VRow class="d-flex flex-wrap" elevation="4">
        <VBtn
          v-if="!id && CheckGetPermission('school.add_parent')"
          variant="elevated"
          size="small"
          elevation="4"
          color="blue-darken-4"
          class="bg-indigo"
          :loading="is_loading"
          @click.prevent="saveParent()"
        >
          {{ $t("global.add") }}
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-if="id && CheckGetPermission('school.change_parent')"
          class="bg-success"
          :loading="is_loading"
          @click.prevent="updateParent(parent.id)"
          size="small"
        >
          <span class="text-white">
            {{ $t("global.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click.prevent="resetForm()">
          {{ !id ? $t("global.clear") : $t("global.cancel") }}
          <VIcon
            :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
            :color="!id ? 'golden' : 'gray'"
            end
          ></VIcon>
        </VBtn>
      </VRow>
    </VCardActions>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
import ImageInput from "@/components/Globals/ImageInput.vue";

export default {
  name: "ParentView",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getIdentifyType();
      if (this.id) {
        await this.getParent(this.id);
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      image: require("@/assets/avatar.jpg"),
      is_loading: false,
      parent: {
        name: "",
        identity_type: undefined,
        identity_number: undefined,
        identity_image: undefined,
        job: undefined,
        address: undefined,
        phone_number: undefined,
        home_number: undefined,
        note: undefined,
      },
      image_reset: 1,
      process_alert: false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      data_excist: true,
      updated_id: undefined,
    };
  },

  components: {
    ImageInput,
  },
  computed: {
    ...mapState({
      identify_type: (state) => state.globals.identify_type,
    }),

    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    getParent(id) {
      this.axios
        .get(`${this.base_url}/parent/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.parent = response.data;
          this.parent.identity_number = this.parent.identity_number.toString();
          this.parent.identity_image =
            this.parent.identity_image != null &&
            this.parent.identity_image.search(this.base_url) == -1
              ? this.base_url + this.parent.identity_image
              : this.parent.identity_image;
        });
    },
    ...mapActions({
      getIdentifyType: "globals/getIdentifyType",
    }),
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "parentlist" });
      }
      this.$refs.form.reset();
      this.parent.identity_image = undefined;
      this.image_reset++;
      this.is_loading = false;
    },
    saveIdImage(event) {
      this.parent.identity_image = event;
    },

    async saveParent() {
      const { valid } = await this.$refs.form.validate();
      this.parent.data_entry = this.user;
      if (valid) {
        this.is_loading = true;
        let result = this.axios
          .post(this.base_url + "parent/", this.parent, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));
            this.resetForm();
          })
          .catch((error) => {
            this.is_loading = false;
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
    },
    async updateParent(id) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        // if (typeof this.parent.identity_image == "string")
        //   this.parent.identity_image = undefined;
        this.axios
          .put(`${this.base_url}/parent/${id}/`, this.parent, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            // setTimeout(() => {
            //   this.$router.push({ name: "parentlist" });
            // }, 1500);
          })
          .catch((error) => {
            this.is_loading = false;
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
<style scoped>
.v-application__wrap {
  background-color: #999;
}
img {
  /* max-height: 150px; */
  max-width: 270px;
}
</style>
