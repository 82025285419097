<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <!-- <v-col cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_bus"
            density="compact"
            item-title="itinerary"
            item-value="id"
            prepend-inner-icon="mdi-bus-school"
            :items="buses"
            :label="$t('school.reports.select_bus')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            @update:model-value="(filter_data.fk_bus = null), getBuses()"
            :label="$t('school.reports.select_branch')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_bus"
            density="compact"
            item-title="itinerary"
            item-value="id"
            prepend-inner-icon="mdi-bus-side"
            :items="
              getUserRole < 2 ? (filter_data.fk_branch ? buses : []) : buses
            "
            :label="$t('school.reports.select_bus')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <report-header :data="filter_data"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>

      <v-row
        v-if="items.length > 0"
        class="mt-4 mb-5 text-grey-darken-2 borders pt-2 pb-2 mr-1 ml-1"
      >
        <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2">
            {{ $t("school.reports.bus_number") }} :
            {{ items[0].bus_data.buss_number }}
          </h3>
          <h3 class="fontsize text-grey-darken-2 mb-2">
            {{ $t("school.reports.driver_phone") }} :
            {{ items[0].bus_data.driver_phone }}
          </h3>
        </v-col>

        <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2">
            {{ $t("school.reports.bus_type") }} :
            {{ items[0].bus_data.buss_type }}
          </h3>
          <h3 class="fontsize">
            {{ $t("school.reports.supervisor") }} :
            {{ items[0].bus_data.teacher_name }}
          </h3>
        </v-col>

        <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2">
            {{ $t("school.reports.car_card") }} :
            {{ items[0].bus_data.buss_card }}
          </h3>
          <h3 class="fontsize">
            {{ $t("school.reports.teacher_phone") }} :
            {{ items[0].bus_data.teacher_phone }}
          </h3>
        </v-col>

        <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2">
            {{ $t("school.reports.driver_name") }} :
            {{ items[0].bus_data.driver_name }}
          </h3>
          <h3 class="fontsize text-grey-darken-2">
            {{ $t("school.reports.driver_road") }} :
            {{ items[0].bus_data.itinerary }}
          </h3>
        </v-col>
      </v-row>

      <div id="myTable">
        <v-card v-if="items?.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items?.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items[0]?.student_data" :key="item">
                <td
                  v-for="(header, index) in selectedHeaders"
                  :class="`border px-3 ${
                    header.key == 'student_name' ? '' : 'text-center'
                  }`"
                  :key="index"
                >
                  <template v-if="index === 0">
                    {{ (page - 1) * perPage + key + 1 }}
                  </template>
                  <template v-else>
                    {{ item[header.key] ? item[header.key] : "-" }}
                  </template>
                </td>
              </tr>
              <!-- <tr v-for="(item, key) in items[0]?.student_data" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                {{ (page - 1) * perPage + key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('student_name')"
                >
                  {{ item.student_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('class_name')"
                >
                  {{ item.class_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('phone')"
                >
                  {{ item.phone }}
                </td> -->
              <!-- <td
                  class="text-center border"
                  v-if="selectedHead.includes('address')"
                >
                  {{ item.student_address }}
                </td> -->
              <!-- </tr> -->
            </tbody>
          </table>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // await this.getClasses();
      await this.getBrenches({});
      if (this.getUserRole > 1) {
        await this.getBuses();
      } // await this.getDrivers();
      // await this.getYears();
      // await this.axios
      //   .get(`${this.base_url}school-data/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.school = response.data;
      //   });
      // this.current_year = this.getYearName(
      //   localStorage.getItem("current_year")
      // );
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      // school: [],
      // current_year: "",
      // fees_types: [],
      // sub_list: [],
      // drivers: [],
      buses: [],
      // sub_list_sub: [],
      // process_alert: false,
      // icon_color: undefined,
      // alert_title: undefined,
      // alert_message: undefined,
      // v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      // txt_search: "",
      selectedHead: ["no", "student_name", "class_name", "phone"],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: this.$t("school.reports.class"), key: "class_name" },
        { title: this.$t("school.reports.phone_number"), key: "phone" },
        // { title: this.$t("school.reports.address"), key: "address" },
      ],
      perPage: 25,
      // length: 0,
      totalItems: 0,
      // itemsPerPage: [
      //   { value: 5, text: "5" },
      //   { value: 10, text: "10" },
      //   { value: 15, text: "15" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: "", text: "all" },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
      // classes: (state) => state.school.classes,
      // class_assign_hall: (state) => state.school.class_assign_hall,
      // divisions: (state) => state.school.divisions,
      // semesters: (state) => state.school.semesters,
      // subjects: (state) => state.school.subjects,
      // user: (state) => state.User,
    }),

    ...mapGetters({
      // getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
      // getClasses: "school/getClasses",
      // getDivisions: "school/getDivisions",
      // getSemesters: "school/getSemesters",
      // getClassAssignHall: "school/getClassAssignHall",
      // getSubjects: "school/getSubjects",
    }),
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    async getBuses(page = 1) {
      await this.axios
        .get(`${this.base_url}bus/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            page: page,
            page_size: this.perPage,
            fk_branch: this.filter_data?.fk_branch?.id,
            // fk_parent:this.filter_data.fk_parent
          },
        })
        .then((response) => {
          if (response.data) {
            this.buses = response.data.results;
          }
        });
    },
    // async getDrivers(page = 1) {
    //   await this.axios
    //     .get(`${this.base_url}driver/`, {
    //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //       params: {
    //         page: page,
    //         page_size: this.perPage,
    //       },
    //     })
    //     .then((response) => {
    //       this.drivers = response.data.results;
    //     });
    // },

    // getdriverName(driver_id) {
    //   if (driver_id) {
    //     const driver = this.drivers.find((driver) => driver.id === driver_id);
    //     return driver.driver_name;
    //   }
    //   return "===========";
    // },
    // getParentName(fk_year) {
    //   const year = this.fees_types.find((year) => year.id === fk_year);
    //   return year.name;
    // },
    // getBusNumber(fk_class) {
    //   const objectClass = this.fees_types.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name_en;
    // },
    // getFeeTypeName(fk_class) {
    //   const objectClass = this.fees_types.find(
    //     (objectClass) => objectClass.id === fk_class
    //   );
    //   return objectClass.name;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/student_bus`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            // year: localStorage.getItem("current_year"),
            bus_number: this.filter_data.fk_bus?.id,
            fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response) {
            this.items = response.data?.results ?? response.data;
            this.totalItems = response.data?.pagination?.count;
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
  },
  // validations() {
  //   return {
  //     filter_data: {
  //       bus: {
  //         required: helpers.withMessage(this.$t("errors.required"), required),
  //       },
  //     },
  //   };
  // },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
