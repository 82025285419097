<template>
  <v-card class="justify-center">
    <v-card-text class="card-fields">
      <VForm
        v-if="CheckGetPermission('school.change_classstudentyear')"
        ref="form"
      >
        <VRow>
          <VCol cols="6" lg="4" md="4" sm="4" xs="4">
            <VAutocomplete
              :items="classes"
              v-model="fk_class"
              prepend-inner-icon="mdi-google-classroom"
              item-title="name_ar"
              item-value="id"
              :label="$t('school.class.select')"
              persistent-hint
              density="compact"
              clearable
              @update:modelValue="fk_division = undefined"
              :rules="[$required]"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" lg="4" md="4" sm="4" xs="4">
            <VAutocomplete
              color="indigo"
              v-model="fk_division"
              density="compact"
              clearable
              item-title="name"
              :no-data-text="$t('school.division.not_found')"
              item-value="id"
              prepend-inner-icon="mdi-format-list-bulleted"
              :items="getClassByDivisions(fk_class)"
              :label="$t('school.division.select')"
            ></VAutocomplete>
          </VCol>
          <v-col md="1" class="mt-2">
            <v-btn
              @click="getStudentsTable(), (show = false)"
              class="bg-indigo"
              size="small"
              :loading="loading"
            >
              <span class="text-white">
                {{ $t("global.show") }}
              </span>
              <!-- <v-icon icon="mdi-content-save" color="white" end></v-icon> -->
            </v-btn>
          </v-col>
        </VRow>
        <!-- <VRow  class="mt-4" >
                            <VCol cols="12" >
                                <VAutocomplete
                                    color="indigo"
                                    v-model="fk_student" 
                                    density="compact"
                                    item-title="name_ar"
                                    item-value="id"
                                    :items="student_list"
                                    clearable
                                    prepend-inner-icon="mdi-school"
                                    :label="$t('school.student_name')"
                                    @update:modelValue="getStudentsTable()"
                                />
                            </VCol>
                        </VRow> -->
        <!-- <small v-if="!checkSchedules&&!update" >shehab alfareh</small> -->
      </VForm>
      <!-- </v-card> -->
      <!-- </v-col>
      </v-row> -->
    </v-card-text>
    <v-expand-transition>
      <div v-show="show">
        <VCard class="card-table">
          <VTable>
            <thead>
              <tr>
                <th class="px-2 w-33">
                  <VAutocomplete
                    color="indigo"
                    v-model="fk_student"
                    density="compact"
                    item-title="student_name"
                    item-value="fk_student"
                    :items="student_list"
                    clearable
                    hide-details
                    variant="underlined"
                    prepend-inner-icon="mdi-school"
                    :label="$t('school.student.name')"
                    @update:modelValue="getStudentsTable()"
                    :loading="loading"
                  />
                </th>
                <th>
                  <v-checkbox
                    v-model="masterChecked.registeration_mode"
                    color="indigo"
                    density="compact"
                    hide-details
                    :indeterminate="isIndeterminate.registeration_mode"
                    @change="
                      toggleAll(
                        masterChecked.registeration_mode,
                        'registeration_mode'
                      ),
                        updateMaster('study_mode')
                    "
                  >
                    <template v-slot:label>
                      <div>
                        {{ $t("school.student.is_regestered") }}
                      </div>
                    </template>
                  </v-checkbox>
                </th>
                <th>
                  <v-checkbox
                    v-model="masterChecked.study_mode"
                    color="indigo"
                    density="compact"
                    hide-details
                    :indeterminate="isIndeterminate.study_mode"
                    :disabled="!masterChecked.registeration_mode"
                    @change="toggleAll(masterChecked.study_mode, 'study_mode')"
                  >
                    <template v-slot:label>
                      <div>
                        {{ $t("school.student.is_continued") }}
                      </div>
                    </template>
                  </v-checkbox>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="student in students_table" :key="student">
                <td>{{ student.student_name }}</td>
                <td>
                  <v-checkbox
                    v-model="student.registeration_mode"
                    color="indigo"
                    density="compact"
                    hide-details
                    @change="
                      updateMaster('registeration_mode'),
                        student.registeration_mode
                          ? ''
                          : ((student.study_mode = false),
                            updateMaster('study_mode'))
                    "
                  >
                  </v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-model="student.study_mode"
                    :disabled="!student.registeration_mode"
                    color="indigo"
                    density="compact"
                    hide-details
                    @change="updateMaster('study_mode')"
                  />
                </td>
              </tr>
            </tbody>
          </VTable>
          <v-card-actions
            v-if="CheckGetPermission('school.change_classstudentyear')"
            class="px-8"
          >
            <!-- v-if="CheckGetPermission('school.add_marks')" -->
            <v-btn
              v-if="CheckGetPermission('school.change_classstudentyear')"
              @click="saveStatus"
              class="bg-indigo"
              size="small"
              :loading="is_loading"
            >
              <span class="text-white">
                {{ $t("global.save") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <!-- <v-btn
                  v-if="update"
                  @click="updateMarks"
                  class="bg-success"
                  size="small"
                  :loading="is_loading"
                >
                  <span class="text-white">
                    {{ $t("global.edit") }}
                  </span>
                  <v-icon icon="mdi-content-save" color="white" end></v-icon>
                </v-btn> -->
            <v-btn @click="cleanMarks" class="mx-3" size="small">
              <span>
                {{ $t("global.clear") }}
              </span>
              <v-icon icon="mdi-broom" color="golden" end></v-icon>
            </v-btn>
          </v-card-actions>
        </VCard>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      if (!this.classes.length) await this.getClasses();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      fk_class: undefined,
      fk_division: undefined,
      fk_student: undefined,
      student_list: [],
      students_table: [],
      show: false,
      all_registered: false,
      all_continued: false,
      some_registered: false,
      some_continued: false,
      masterChecked: {
        registeration_mode: false,
        study_mode: false,
      },
      isIndeterminate: {
        registeration_mode: false,
        study_mode: false,
      },
      loading: false,
      is_loading: false,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      semesters: (state) => state.school.semesters,
      subjects: (state) => state.school.subjects,
      months: (state) => state.school.months,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      // user: (state) => state.User,
    }),
    ...mapGetters({
      getClassByDivisions: "school/getClassDivisions",
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    // async getStudentsList() {
    //   // if(this.fk_year==undefined){
    //   //     this.fk_year=this.academic_years.find(item=>item.curentyear==true)
    //   //     if(this.fk_year)
    //   //         this.fk_year=this.fk_year.id
    //   //     }
    //   if (this.fk_class != undefined) {
    //     let list = await this.axios
    //       .get(`${this.base_url}/student-list/`, {
    //         params: {
    //           class: this.fk_class,
    //         },
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //       })
    //       .then((response) => {
    //         this.student_list = response.data;
    //       });
    //   }
    // },
    async getStudentsTable() {
      const { valid } = await this.$refs.form.validate();

      if (valid || this.show) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}/student/regester-status/`, {
            params: {
              fk_class: this.fk_class,
              fk_division: this.fk_division,
              // fk_year:localStorage.getItem('current_year'),
              fk_student: this.fk_student,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.students_table = response.data;
              this.student_list = this.student_list.length
                ? this.student_list
                : response.data;
              this.show = true;
              this.updateMaster("registeration_mode");
              this.updateMaster("study_mode");
            } else {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.message")
              );
              this.show = false;
            }
          })
          .catch(() => {
            this.show = false;
          });
      }
      this.loading = false;
    },
    async saveStatus() {
      if (this.students_table.length > 0) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}student/change-status/`, this.students_table, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
          })
          .catch(() => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        // this.getStudentsList();
      }
      this.is_loading = false;
    },
    toggleAll(All, checkedField) {
      this.students_table.forEach((item) => {
        item[checkedField] = All;
      });
      this.updateMaster(checkedField);
      // if (column == "reg") {
      //   this.students_table.forEach((item) => {
      //     item.registeration_mode = All;
      //     item.study_mode = !All ? All : item.study_mode;
      //   });
      //   this.all_continued = !All ? All : this.all_continued;
      // } else if (column == "con") {
      //   this.students_table.forEach((item) => (item.study_mode = All));
      // }
    },
    updateMaster(checkedField) {
      const data = this.students_table;
      const totalChecked = data.reduce((total, item) => {
        return total + (item[checkedField] ? 1 : 0);
      }, 0);
      const totalItems = data.length;
      this.masterChecked[checkedField] = totalChecked === totalItems;
      this.isIndeterminate[checkedField] =
        totalChecked > 0 && totalChecked < totalItems;
      // this.all_registered = this.students_table.every(
      //   (item) => item.registeration_mode === true
      // );
      // this.all_continued = this.students_table.every(
      //   (item) => item.study_mode == true
      // );
      // const some_registered = this.students_table.some(
      //   (item) => item.registeration_mode === true
      // );
      // const some_continued = this.students_table.some(
      //   (item) => item.study_mode == true
      // );

      // this.all_registered = this.all_registered
      //   ? true
      //   : some_registered
      //   ? null
      //   : false;
      // this.all_continued = this.all_continued
      //   ? true
      //   : some_continued
      //   ? null
      //   : false;
    },
    cleanMarks() {
      //   this.$refs.form.reset();
      this.show = false;
      this.student_list = [];
      this.students_table = [];
      this.fk_student = [];
    },
  },
};
</script>
