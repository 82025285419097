// import Dashboard from '../views/Examples/Dashboard'
import SchoolUserView from '../views/Usermanage/SchoolUserView.vue'
import SchoolUserList from '../views/Usermanage/SchoolUserList.vue'
import SchoolGroupView from '../views/Usermanage/SchoolGroupView.vue'
import store from '@/store/index'

const hasPermission= (prem)=>{    
    store.dispatch('updatePermission',localStorage.getItem('userinfo'))   
    return  store.getters.checkpermission(prem);
     
};
const requirePermission=(perm)=>{
    return (to,from,next)=>{
        if(hasPermission(perm)){
            next()
        }else{
            next({
                path:"/"    ,
                query:{redirect:from.fullPath}           
            })
        }
    }
}
export default [
    {
        path: 'account/add', 
        name:'school_add_user',
        component: SchoolUserView,
        beforeEnter:requirePermission("usermanage.add_user"),
    },
    {
        path: 'account/list', 
        name:'school_user_list',
        component: SchoolUserList,
        beforeEnter:requirePermission("usermanage.view_user"),
    },
    {
        path: 'account/:id/edit',
        name:'school_user_edit',
        component: SchoolUserView,
        props:true,
        beforeEnter:requirePermission("usermanage.change_user"),
    },
    {
        path: 'group/add', 
        name:'school_add_group',
        component: SchoolGroupView,
        beforeEnter:requirePermission("auth.view_group"),
    },
]