<template>
  <SideBar
    :direction="$i18n.locale == 'en' ? 'ltr' : 'rtl'"
    :ripple="false"
    :drawer="drawer"
    @selectOption="onSelectOption"
    :location="location"
    id="sidebar"
  />
  <Header @handelDrawer="drawer = !drawer" id="header" />
  <VMain class="mainbg">
    <VContainer class="h-100" ref="homePage">
      <v-card
        v-if="breadcrumb ? breadcrumb.length > 0 && showBreadcrumb : false"
        id="breadCrumb"
      >
        <v-row class="d-flex py-2">
          <v-col cols="auto">
            <v-breadcrumbs class="bread-info" :items="breadcrumb">
              <template v-slot:divider>
                <v-icon class="" icon="mdi-chevron-left"></v-icon>
              </template>
            </v-breadcrumbs>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-breadcrumbs
              color="indigo-darken-4"
              class="d-flex justify-end"
              :items="year"
            ></v-breadcrumbs>
          </v-col>
        </v-row>
      </v-card>
      <br v-if="breadcrumb ? breadcrumb.length > 0 && showBreadcrumb : false" />
      <!-- <br> -->
      <router-view
        @errorAlert="setErrorAlert"
        @successAlert="setSuccessAlert"
        @warningAlert="setInfoAlert"
        @infoAlert="setInfoAlert"
        @ProtectedAlert="setProtectedAlert"
        @updateDate="updateDate"
      ></router-view>
    </VContainer>
  </VMain>
  <Alert
    v-model="show_alert"
    :iconColor="alert_icon_color"
    :icon="alert_icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
  <alert-protected-records
    v-model="protected_data"
    :data_message="protected_data"
  >
  </alert-protected-records>
  <v-dialog
    color="indigo"
    :close-on-content-click="false"
    v-model="loadingPage"
    persistentid="loadingPage"
    width="auto"
  >
    <h1 v-if="!loadingImage" class="text-white">l o a d i n g . . .</h1>
    <div style="width: 80px; height: 80px">
      <v-img :src="loadingImage"></v-img>
    </div>
  </v-dialog>
</template>

<script>
import Header from "@/components/Globals/Header.vue";
import SideBar from "@/components/Globals/SideBar.vue";
import i18n from "@/i18n";
import { mapGetters, mapActions, mapState } from "vuex";

//   changeLocale: locale => current.value = locale

export default {
  components: {
    Header,
    SideBar,
  },
  mounted() {
    //   this.$store.commit('updateLoadingValue',false)
    this.localStorageValue;
  },
  data() {
    return {
      drawer: true,
      isWithColor: true,
      show_alert: false,
      alert_icon_color: undefined,
      alert_icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      protected_data: null,
      year: [
        localStorage.getItem("semester_ar") !== "undefined"
          ? localStorage.getItem("semester_ar")
          : "يرجى اختيار ترم حالي",
        localStorage.getItem("date") == "h"
          ? localStorage.getItem("month_h") !== "undefined"
            ? localStorage.getItem("month_h")
            : "يرجى اختيار شهر حالي "
          : localStorage.getItem("month_m") !== "undefined"
          ? localStorage.getItem("month_m")
          : "يرجى اختيار شهر حالي ",
        localStorage.getItem("date") == "h"
          ? localStorage.getItem("year_h") !== "undefined"
            ? localStorage.getItem("year_h")
            : "يرجى اختيار سنة حالية "
          : localStorage.getItem("year_m") !== "undefined"
          ? localStorage.getItem("year_m")
          : "يرجى اختيار سنة حالية",
      ],
    };
  },

  methods: {
    onSelectOption(isColor) {
      isWithColor.value = isColor;
    },
    setErrorAlert(message) {
      this.alert_icon_color = "error";
      this.alert_icon = "cancel";
      this.alert_title = this.$t("global.error.failure.failure");
      this.alert_message = message;
      this.showAlert();
    },
    setSuccessAlert(message) {
      this.alert_icon_color = "success";
      this.alert_icon = "check-circle";
      this.alert_title = this.$t("global.success.success");
      this.alert_message = message;
      this.showAlert();
    },
    setWarningAlert(message) {
      this.alert_icon_color = "warning";
      this.alert_icon = "alert";
      this.alert_title = this.$t("global.warning.warning");
      this.alert_message = message;
      this.showAlert();
    },
    setInfoAlert(message) {
      this.alert_icon_color = "info";
      this.alert_icon = "information";
      // this.alert_title=this.$t('globals.info')
      this.alert_message = message;
      this.showAlert();
    },
    setProtectedAlert(protected_data) {
      this.protected_data = protected_data;
      //   this.showAlert();
    },
    showAlert() {
      this.show_alert = true;
      setTimeout(() => {
        this.show_alert = false;
      }, 2000);
    },
    updateDate(data = false, set_new = true) {
      if (data && set_new) {
        localStorage.setItem(
          "month_m",
          data?.month_m ?? localStorage.getItem("month_m")
        );
        localStorage.setItem(
          "month_h",
          data?.month_h ?? localStorage.getItem("month_h")
        );
        localStorage.setItem(
          "year_m",
          data?.year_m ?? localStorage.getItem("year_m")
        );
        localStorage.setItem(
          "year_h",
          data?.year_h ?? localStorage.getItem("year_h")
        );
        localStorage.setItem(
          "semester_ar",
          data?.semester_ar ?? localStorage.getItem("semester_ar")
        );
        localStorage.setItem(
          "semester_en",
          data?.semester_en ?? localStorage.getItem("semester_en")
        );
      }
      this.year = [
        localStorage.getItem("semester_ar") !== "undefined"
          ? localStorage.getItem("semester_ar")
          : "يرجى اختيار ترم حالي",
        localStorage.getItem("date") == "h"
          ? localStorage.getItem("month_h") !== "undefined"
            ? localStorage.getItem("month_h")
            : "يرجى اختيار شهر حالي "
          : localStorage.getItem("month_m") !== "undefined"
          ? localStorage.getItem("month_m")
          : "يرجى اختيار شهر حالي ",
        localStorage.getItem("date") == "h"
          ? localStorage.getItem("year_h") !== "undefined"
            ? localStorage.getItem("year_h")
            : "يرجى اختيار سنة حالية"
          : localStorage.getItem("year_m") !== "undefined"
          ? localStorage.getItem("year_m")
          : "يرجى اختيار سنة حالية",
      ];
    },
    handelKeydown(event) {
      const { ctrlKey, keyCode } = event;
      if (ctrlKey) {
        switch (keyCode) {
          case 36:
            this.sctollToTop();
            break;
          case 35:
            this.scrollToEnd();
            break;
        }
      }
    },
    sctollToTop() {
      const table = this.$refs.homePage.$el;
      table.scrollIntoView({ block: "start" });
    },
    scrollToEnd() {
      const table = this.$refs.homePage.$el;
      table.scrollIntoView({ block: "end" });
    },
  },
  created() {
    const dir = localStorage.getItem("user-locale") === "ar" ? "rtl" : "ltr";
    this.$store.dispatch("updatelanguage", localStorage.getItem("user-locale"));
    this.$store.dispatch("updatedirection", dir);
  },
  computed: {
    ...mapState({
      loadingPage: "loading",
      breadcrumb: "breadcrumb",
    }),

    showBreadcrumb() {
      if (this.$route.path == "/" || this.$route.path == "/summer/home") {
        // if(localStorage.getItem('role')==4)
        //     return true
        return false;
      }
      return true;
    },
    loadingImage() {
      try {
        return require("../../assets/loading.gif");
      } catch (error) {
        return false;
      }
    },
    ...mapGetters({ getdir: "getdir" }),
    location() {
      return this.$i18n.locale === "en" ? "left" : "right";
    },
    checkSemster() {},

    localStorageValue() {
      //   console.log(localStorage.getItem("role"), "999999999999999999999999999");
      return localStorage.getItem("year_h");
    },
  },

  watch: {
    loadingPage() {
      setTimeout(() => {
        this.$store.commit("updateLoadingValue", false);
      }, 60000);
    },
    year(newValue, oldValue) {
      //   console.log(newValue, "3222222222222222");
    },
  },
};
</script>
<style>
/* to change direction with rtl  */
[dir="rtl"] div {
  direction: rtl;
}

* {
  font-family: "Almarai" !important;
  box-sizing: border-box !important;
}

/* to change direction with rtl  */
/* .v-navigation-drawer .v-list-item:hover{
    background: #273c75 !important;
    border-radius: 10px !important;

} */
.v-navigation-drawer__content {
  /* overflow-x: scroll !important; */
  overflow-y: scroll !important;
}
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

.v-navigation-drawer {
  color: #42526e !important;
  font-weight: bold;
}
/* .v-icon:not(.v-checkbox-btn i){
    color: #42526e ;
} */
.v-navigation-drawer .v-list-item {
  overflow: hidden;
}

/* .v-navigation-drawer .vlistgroup:hover{
    background: #263238 !important;
    width: 250px !important ;
    border-radius: 10px !important;
    padding: 4px 0 !important;
} */
a {
  margin-left: 10px;
  text-decoration: none;
  color: #263238;
}
.v-navigation-drawer .vListHome {
  background-color: rgba(9, 30, 66, 0.48) !important;
  width: 250px !important ;
  border-radius: 10px !important;
}

.search input {
  padding-top: 2px;
}
.bread-info .v-breadcrumbs-item {
  color: #42526e;
  opacity: 0.38;
}
.v-breadcrumbs-item--disabled:not(.location-bread) {
  opacity: unset;
}
.location-bread li {
  font-size: 0.85rem !important;
}
</style>
