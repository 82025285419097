<template>
  <v-expansion-panels class="mb-2">
    <v-expansion-panel elevation="1">
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("global.view_filters") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pt-4 bg-white">
        <v-form
          ref="form"
          @submitStudentHomeworkForm.prevent="saveStudentHomework"
          class="mb-4"
        >
          <v-row class="" style="row-gap: 0.5rem">
            <v-col cols="6" md="3" sm="12">
              <v-autocomplete
                color="indigo"
                v-model="fk_class"
                density="compact"
                item-title="name_ar"
                item-value="id"
                clearable
                hide-details
                :items="classes"
                prepend-inner-icon="mdi-google-classroom"
                :label="$t('school.class.select')"
                @update:modelValue="(fk_division = null), (fk_subject = null)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="3" sm="12">
              <v-autocomplete
                color="indigo"
                v-model="fk_division"
                density="compact"
                item-title="name"
                item-value="id"
                clearable
                hide-details
                :no-data-text="$t('school.division.not_found')"
                :items="class_divisions"
                prepend-inner-icon="mdi-format-list-bulleted"
                :label="$t('school.division.select')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="3" sm="12">
              <v-autocomplete
                color="indigo"
                v-model="fk_subject"
                density="compact"
                item-title="name"
                item-value="id"
                clearable
                hide-details
                :items="class_subjects"
                prepend-inner-icon="mdi-book-open-variant"
                :label="$t('school.subject.select')"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col class="mt-5">
              <fieldset
                class="d-flex flex-wrap flex-grow-0 border pt-1 pb-0 rounded"
              >
                <legend class="v-label px-2">
                  {{ $t("school.homework.deadline") }}
                </legend>
                <div class="me-1 px-2 d-flex" style="flex: 1">
                  <label
                    for="from_date"
                    class="text-grey-darken-1 ma-2 text-center"
                    >{{ $t("global.from") }}</label
                  >
                  <!-- <div class="pa-1 bg-ccc"> -->
                  <!-- <input type="date"  v-model="from_date" @input="to_date=from_date"  > -->
                  <v-text-field
                    style="min-width: 220px"
                    v-model="from_date"
                    density="compact"
                    :placeholder="$t('school.homework.deadline')"
                    clearable
                    prepend-inner-icon="mdi-calendar-clock-outline"
                    id="from_date"
                    readonly
                  >
                  </v-text-field>
                  <Datetime
                    type="date"
                    element="from_date"
                    :color="'#0747a6'"
                    @input="(e) => getPermissionTime(e)"
                    :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                    :placeholder="$t('school.homework.deadline')"
                    :calendar="date_type == 'h' ? 'hijri' : ''"
                  ></Datetime>
                </div>
                <!-- </div> -->
                <div class="me-1 px-2 d-flex" style="flex: 1">
                  <label for="from_date" class="text-grey-darken-1 ma-2 mt-1">{{
                    $t("global.to")
                  }}</label>
                  <!-- <div class="pa-1 bg-ccc"> -->
                  <!-- <input type="date"  v-model="to_date" :min='from_date'  > -->
                  <v-text-field
                    style="min-width: 220px"
                    v-model="to_date"
                    density="compact"
                    :placeholder="$t('school.homework.deadline')"
                    clearable
                    prepend-inner-icon="mdi-calendar-clock-outline"
                    id="to_date"
                    readonly
                  >
                  </v-text-field>
                  <Datetime
                    type="date"
                    element="to_date"
                    :color="'#0747a6'"
                    @input="(e) => getPermissionTime(e, 'end')"
                    :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                    :placeholder="$t('school.homework.deadline')"
                    :calendar="date_type == 'h' ? 'hijri' : ''"
                  ></Datetime>
                  <!-- </div> -->
                </div>
              </fieldset>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="2"
              lg="2"
              class="mt-auto pb-4 pt-3"
            >
              <v-btn
                @click="getHomeworks((page = 1))"
                class="bg-indigo"
                size="small"
                :loading="loading"
              >
                <span class="text-white">
                  {{ $t("global.filter") }}
                </span>
                <VIcon icon="mdi-filter" color="white" end></VIcon>
              </v-btn>
            </v-col>
            <!-- <v-col cols="auto" class="mt-auto pb-4 pt-3">
                  <v-btn
                    @click="getHomeworks((page = 1))"
                    class="bg-indigo"
                    size="small"
                  >
                    <span class="text-white">
                      {{ $t("global.show") }}
                    </span>
                  </v-btn>
                </v-col> -->
          </v-row>
        </v-form>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <!-- ##########    data Table Server ########### -->

  <v-card class="card-table">
    <!-- ######## Header Of Data Table Server ####### -->
    <div>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" color="#ccc">
            <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
            <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header.key"
              :label="header.title"
              :value="header.key"
              color="indigo"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-text-field
        color="indigo"
        v-model="txt_search"
        @input="!txt_search ? getHomeworks((page = 1)) : ''"
        @keyup.enter="getHomeworks((page = 1))"
        class="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :placeholder="$t('global.data_table.search_here')"
        density="compact"
        variant="text"
      >
      </v-text-field>
      <div
        v-if="CheckGetPermission('school.add_homework')"
        class="d-flex justify-end"
        style="flex: 1"
      >
        <VBtn class="bg-success" @click="addHomework">
          <span class="text-white">{{ $t("global.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <!-- ######## End Header Of Data Table Server ####### -->
    <v-data-table-server
      :loading="loading"
      :headers="selectedHeaders"
      :items="tableList"
      v-model:sort-by="sortBy"
      item-value="name"
      item-key="id"
      id="vDataTable"
      fixed-footer
      @update:options="getHomeworks((page = 1))"
      density="compact"
      class="elevation-1"
    >
      <template v-slot:no-data class="text-center">
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <template v-slot:no-results>
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <template v-slot:column.checkbox="{ column }">
        <v-checkbox hide-details color="indigo" density="compact"> </v-checkbox>
      </template>
      <template v-slot:item="{ item }">
        <tr class="row-hover">
          <td v-for="(val, key) in item.columns" :key="key">
            <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
            </v-checkbox>
            <span v-else-if="key == 'status'">
              <v-icon v-if="val == true" color="success"
                >mdi-check-circle</v-icon
              >
              <v-icon v-if="val == false" color="error"
                >mdi-close-circle</v-icon
              >
            </span>

            <span v-else> {{ val }}</span>
            <span v-if="key === 'actions'">
              <v-btn
                variant="text"
                class="me-1 v-btn-icon"
                size="small"
                @click="editHomework(item.raw)"
                v-if="CheckGetPermission('school.change_homework')"
              >
                <v-icon color="warning">mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn
                variant="text"
                size="small"
                class="v-btn-icon"
                @click="(selectedSubject = item.raw.id), (dialog = true)"
                v-if="CheckGetPermission('school.delete_homework')"
              >
                <v-icon color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:bottom>
        <pagination
          v-model:page="page"
          v-model:perPage="perPage"
          :length="tableList.length"
          :newItem="[perPage]"
        ></pagination>
        <!-- <div class="d-flex" id="pagination-bar">
          <v-pagination
            v-model="page"
            :length="length"
            density="compact"
            show-first-last-page
          >
          </v-pagination>
          <div class="d-flex">
            <v-select
              v-model="perPage"
              class="pa-0"
              :items="itemsPerPage"
              item-value="value"
              item-title="text"
              density="compact"
              hide-details
              variant="text"
            >
            </v-select>
            <span class="mt-2 px-2"
              >{{ $t("global.data_table.per_page") }} {{ $t("global.from") }} [
              {{ totalItems }} ] {{ $t("global.data_table.item") }}</span
            >
          </div>
        </div> -->
      </template>
    </v-data-table-server>
  </v-card>
  <!-- ############### Dialog Delete  ################### -->
  <v-dialog
    color="indigo"
    v-model="dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="(dialog = false), (selectedSubject = '')">{{
          $t("global.cancel")
        }}</v-btn>
        <v-btn
          color="red"
          @click="deleteHomework(selectedSubject), (dialog = false)"
          >{{ $t("global.delete") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- ############### end Dialog Delete  ################### -->
  <div></div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Datetime from "vue3-datetime-js";
export default {
  name: "HomeworkList",
  props: {
    id: {
      type: Number,
    },
  },
  components: { Datetime },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getHomeworks();
      if (!this.classes.length) await this.getClasses();
      if (!this.subjects.length) await this.getSubjects({});
      if (!this.divisions.length)
        await this.getDivisions({ all: true }), await this.getClassAssignHall();
      if (!this.semesters.length) await this.getSemesters();

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      name: "",
      name_en: "",
      loading: false,
      txt_search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      homeworks: [],
      date_type: localStorage.getItem("date"),
      sortBy: [
        {
          key: "name",
          order: "asc",
        },
      ],
      selectedHead: [
        "name",
        "fk_subject",
        "fk_class",
        "status",
        "fk_semester",
        "fk_division",
        "deadline",
        "actions",
      ],
      selectedSubject: null,
      headers: [
        { title: this.$t("school.lecture.name"), key: "lecture_name" },
        { title: this.$t("school.homework.name"), key: "name" },
        { title: this.$t("school.subject.name"), key: "fk_subject" },
        { title: this.$t("school.class.name"), key: "fk_class" },
        { title: this.$t("school.homework.checked"), key: "status" },
        { title: this.$t("school.semester.name"), key: "fk_semester" },
        { title: this.$t("school.division.name"), key: "fk_division" },
        // { title: this.$t("school.academic_year.name"), key: "fk_year" },
        { title: this.$t("school.homework.deadline"), key: "deadline" },
        // { title: this.$t("school.teacher.name"), key: "fk_teacher" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      perPage: 10,
      length: 0,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      // ],
      page: 1,
      pagination: {},
      totalItems: null,
      fk_class: null,
      fk_division: null,
      fk_subject: null,
      from_date: undefined,
      to_date: undefined,
      date_type: localStorage.getItem("date"),
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      semesters: (state) => state.school.semesters,
      subjects: (state) => state.school.subjects,
    }),

    class_divisions() {
      if (this.fk_class) {
        return this.getClassDivisions(this.fk_class);
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },

    class_subjects() {
      if (this.fk_class) {
        var class_data = this.classes.find((item) => item.id == this.fk_class);
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },
    ...mapGetters({
      getSubjectName: "school/getSubjectName",
      getTeacherName: "school/getTeacherName",
      getClassName: "school/getClassName",
      getSemesterName: "school/getSemesterName",
      getDivisionName: "school/getDivisionName",
      getYearName: "school/getYearName",
      getClassDivisions: "school/getClassDivisions",
    }),
    tableList() {
      if (this.homeworks) {
        var list = JSON.parse(JSON.stringify(this.homeworks));
        list.forEach((element) => {
          let fk_class = element.fk_class;
          let fk_division = element.fk_division;
          //   let fk_semester = element.fk_semester;
          //   let fk_teacher = element.fk_teacher;
          let fk_subject = element.fk_subject;
          //   let fk_year = element.fk_year;
          element.fk_division = this.getDivisionName(fk_division);
          element.fk_semester = this.getSemesterName(element.fk_semester);
          //   element.fk_year = this.getYearName(fk_year);
          element.fk_class = this.getClassName(fk_class);
          element.fk_subject = this.getSubjectName(fk_subject);
          //   element.fk_teacher = this.getTeacherName(fk_teacher);
        });
        return list;
      }
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },

  methods: {
    ...mapActions({
      getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      // getAllTeachers:"school/getAllTeachers",
      // getAcademicYears: "school/getAcademicYears",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    getPermissionTime(e, type = "start") {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      if (type == "start") this.from_date = date;
      else this.to_date = date;
    },
    addHomework() {
      this.$router.push({ name: "homework_add" });
    },
    async getHomeworks(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/homework/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          class_id: this.fk_class,
          division: this.fk_division,
          //   semester: this.fk_semester,
          subject: this.fk_subject,
          from_date: this.from_date,
          to_date: this.to_date,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.homeworks = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.totalItems = response.data.pagination.count;
        this.loading = false;
      });
    },
    editHomework(item) {
      this.$router.push(`${item.id}/edit/`);
    },
    submitSubjectForm() {
      this.v$.$validate();
    },
    // saveSubject(){
    //     this.submitSubjectForm()
    //     if(!this.v$.$error){
    //         this.axios.post(`${this.base_url}api/subjects/`,this.subject,{headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }}).then(response=>{
    //         this.$emit("successAlert", this.$t("global.success.data_saved"));

    //         this.cleanSubjectForm()
    //         this.getSubjects()
    //     }).catch((error)=>{
    //         this.$emit('errorAlert',this.$t('global.error.failure.error_in_data'))

    //         })
    //     }
    //     // else{
    //     //     this.showAlert=true
    //     //     this.alert.color="error"
    //     //     this.alert.message=this.$t('alert.failure.insert')
    //     //     this.alert.title=this.$t('alert.failure.title')
    //     //     this.alert.icon="alert-circle-outline"
    //     //     setTimeout(()=>{
    //     //         this.showAlert=false
    //     //     },1500)
    //     // }

    // },
    cleanSubjectForm() {
      this.update = false;
      this.v$.subject.$reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.subject = Object.assign({}, this.defaultSubject);
      });
    },

    deleteHomework(id) {
      this.axios
        .delete(`${this.base_url}api/homework/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.data_deleted"));

          this.getHomeworks();
        })
        .catch((error) => {
          if (error.response?.status == 418)
            this.$emit("protectedAlert", error.response?.data?.data);
          else
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
        });
    },
  },
  mounted() {},
  watch: {
    perPage() {
      this.getHomeworks();
    },
    page() {
      this.getHomeworks(this.page);
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
</style>
