<template>
  <div class="d-flex justify-space-between mb-2" id="tableHead">
    <div class="d-flex justify-end" style="flex: 1">
      <v-menu v-if="headers && false" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            density="compact"
            class="px-1"
            style="min-width: 30px"
            append-icon="mdi-chevron-down"
          >
            <v-icon color="icon-color" class="px-2 pr-3">mdi-eye</v-icon>
            <!-- <v-icon color="icon-color" >mdi-chevron-down</v-icon> -->
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header"
              :label="header.title"
              :value="header.key"
              color="indigo"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-spacer></v-spacer>
      <!-- <v-menu>
        <template v-slot:activator="{ props }"> -->
      <v-btn
        v-bind="props"
        density="compact"
        :class="`pa-0 mx-1 ${
          back_button
            ? $i18n.locale == 'ar'
              ? 'rounded-bs-0 rounded-ts-0'
              : 'rounded-be-0 rounded-te-0'
            : ''
        } `"
        style="min-width: 25px"
        @click="printTable((all = true))"
        elevation="1"
      >
        <v-icon color="icon-color">mdi-printer</v-icon>
        <v-tooltip class="tooltip" activator="parent" location="top">
          <small>{{ $t("school.reports.print") }}</small>
        </v-tooltip>
      </v-btn>
      <!-- </template> -->
      <!-- <v-list id="print-menu" density="compact">
          <v-list-item @click="printTable((all = false))">{{
            $t("school.reports.")
          }}</v-list-item>
          <v-list-item @click="printTable((all = true))">{{
            $t("school.reports.")
          }}</v-list-item>
        </v-list>
      </v-menu> -->
      <v-btn
        v-if="back_button"
        elevation="1"
        density="compact"
        :class="`pa-0 ${
          $i18n.locale == 'en'
            ? 'rounded-bs-0 rounded-ts-0'
            : 'rounded-be-0 rounded-te-0'
        }`"
        :append-icon="
          $i18n.locale == 'ar' ? 'mdi-chevron-left' : 'mdi-chevron-right'
        "
        @click="toggleReportVisibility()"
      >
        <span class="text-indigo px-1">{{ $t("school.reports.back") }}</span>
      </v-btn>

      <v-btn
        v-if="false"
        v-bind="props"
        density="compact"
        class="pa-0 mx-1"
        style="min-width: 25px"
      >
        <v-icon color="icon-color">mdi-file-excel</v-icon>
        <v-tooltip activator="parent" class="tooltip" location="top">
          <small>{{ $t("export.excel") }}</small>
        </v-tooltip>
      </v-btn>
    </div>
  </div>
  <v-row
    v-if="false"
    class="mt-4 mb-5 text-grey-darken-2 borders pt-2 pb-2 mr-1 ml-1"
  >
    <v-col cols="4">
      <h3 class="fontsize text-grey-darken-2 mb-2">
        <!-- النشاط : {{ items[0].activity_data.acivity_name }} -->اسم النشاط
      </h3>
      <h3 class="fontsize text-grey-darken-2 mb-2">
        <!-- المكان : {{ items[0].activity_data.location }} -->المكان
      </h3>
    </v-col>

    <v-col cols="3">
      <h3 class="fontsize text-grey-darken-2 mb-2">
        <!-- نوع النشاط : {{ items[0].activity_data.activity_type }} -->نوع
        النشاط
      </h3>
      <h3 class="fontsize">
        <!-- اسم المشرف : {{ items[0].activity_data.responsible }} -->اسم المشرف
      </h3>
    </v-col>

    <v-col cols="5">
      <h3 class="fontsize">
        <!-- بداية النشاط : {{ items[0].activity_data.start_date }} -->بداية
        النشاط
      </h3>
      <h3 class="fontsize text-grey-darken-2">
        <!-- نهاية النشاط : {{ items[0].activity_data.end_date }} -->نهاية
        النشاط
      </h3>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    headers: [],
    modelValue: [],
    back_button: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedHead: this.modelValue ? [...this.modelValue] : "",
    };
  },
  computed: {
    selectedHead: {
      get() {
        return this.modelValue;
      },
      set(selectedHead) {
        this.$emit("update:modelValue", selectedHead);
      },
    },
  },
  methods: {
    async printTable() {
      await this.$emit("print", 2);
      window.print();
      // setTimeout(() => {
      //   window.print();
      // }, 1000);
    },
    toggleReportVisibility() {
      this.$emit("toggle-visibility");
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
  },
  //   watch: {
  //     modelValue(newVal) {

  //       this.selectedHead = [...newVal];
  //     },
  //     selectedHead(newVal) {
  //       this.$emit("update:modelValue", newVal);
  //     },
  //   },
};
</script>
