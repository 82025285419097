<template>
  <v-card class="justify-center">
    <v-expand-transition>
      <div>
        <!-- && (CheckGetPermission('school.add_classdocument') || CheckGetPermission('school.change_classdocument')) -->
        <v-card-text
          class="card-fields"
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            (CheckGetPermission('school.add_classdocument') ||
              (update && CheckGetPermission('school.change_classdocument')))
          "
        >
          <v-form ref="form" @submit.prevent="saveClassDocument">
            <v-row>
              <v-col cols="12" md="8">
                <v-autocomplete
                  v-model="classDocument.fk_document"
                  clearable
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="mdi-file-document-outline"
                  :label="$t('school.document.name')"
                  :no-data-text="$t('school.document.not_found')"
                  :items="documents"
                  :multiple="update ? false : true"
                  :error-messages="uniqueDocument"
                  :rules="[$required]"
                  @update:model-value="uniqueDocument = false"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-btn
                  @click="addDialog = true"
                  class="mt-1 bg-indigo"
                  density="default"
                  variant="tonal"
                  v-if="CheckGetPermission('school.add_document')"
                >
                  <v-icon color="white">mdi-text-box-plus-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="classDocument.fk_class"
                  density="compact"
                  :item-title="classesName"
                  item-value="id"
                  prepend-inner-icon="mdi-google-classroom"
                  clearable
                  :items="classes"
                  :label="$t('school.class.select')"
                  :error-messages="uniqueDocument"
                  :rules="[$required]"
                  @update:model-value="uniqueDocument = false"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox
                  v-model="classDocument.optional"
                  :label="$t('school.document.optional')"
                  color="indigo"
                />
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="classDocument.note"
                  hide-details="auto"
                  density="compact"
                  counter="250"
                  clearable
                  rows="4"
                  prepend-inner-icon="mdi-note-outline"
                  :label="$t('global.note')"
                  :rules="[$max_length(250)]"
                />
              </v-col>
            </v-row>
          </v-form>

          <!-- </v-card>
        </v-col>
      </v-row> -->
        </v-card-text>
        <v-card-actions
          class="px-8"
          v-if="
            checkRole(['programmer', 'big_admin']) &&
            (CheckGetPermission('school.add_classdocument') ||
              (update && CheckGetPermission('school.change_classdocument')))
          "
        >
          <v-btn
            v-if="!update && checkRole(['programmer', 'big_admin'])"
            @click="saveClassDocument"
            class="bg-indigo"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <v-btn
            v-if="update && checkRole(['programmer', 'big_admin'])"
            @click="updateClassDocument(selectedClassDocument)"
            class="bg-success"
            size="small"
            :loading="is_loading"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <VBtn class="mx-3" size="small" @click="cleanClassDocumentForm">
            {{ !update ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
              :color="!update ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
          <!-- <v-btn @click="cleanClassDocumentForm" class="mx-3" size="small">
            <span>
              {{ $t("global.clear") }}
            </span>
            <v-icon icon="mdi-broom" color="golden" end></v-icon>
          </v-btn> -->
        </v-card-actions>

        <v-divider class="mt-5"></v-divider>
      </div>
    </v-expand-transition>
    <!-- ##########    data Table Server ########### -->
    <!-- <v-row v-if="true" class="mb-4">
      <v-col cols="9">
        <v-alert
          closable
          density="compact"
          border="start"
          variant="outlined"
          color="success"
          :icon="alertIcon"
          :title="alertTitle"
          :text="alertMessage"
        >
        </v-alert>
      </v-col>
    </v-row> -->
    <v-card class="pa-2" v-if="CheckGetPermission('school.view_classdocument')">
      <!-- ######## Header Of Data Table Server ####### -->
      <div >
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            v-model="txt_search"
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            class="search"
            hide-details
            append-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :search="search"
        :headers="selectedHeaders"
        :items="tableList"
        item-value="name"
        item-key="id"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>

              <span v-else-if="key == 'optional'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  v-if="checkRole(['programmer', 'big_admin'])"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="editClassDocument(item)"
                >
                  <v-icon color="warnning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  v-if="checkRole(['programmer', 'big_admin'])"
                  class="v-btn-icon"
                  @click="
                    (selectedClassDocument = item.raw.id), (dialog = true)
                  "
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog v-model="dialog" transition="dialog-top-transition" width="auto">
      <!-- <v-sheet 
            rounded="lg"
            width="100%"
            class="pa-4 text-center mx-auto"
        >
            <v-icon
                class="mb-5"
                color="warning"
                icon="mdi-alert-circle-outline"
                size="40"
            ></v-icon>

            <h2 class="text-h5 mb-6 text-blue-grey-darken-4" style="font-family:'Almarai' !important;font-size:2.5rem !important"> {{ $t('globals.confirmation') }} </h2>
            <p class="mb-4 text-medium-emphasis text-body-2 text-blue-grey-darken-4"  style="font-family:'Almarai' !important;font-size:14px !important;">  {{ $t('globals.confirm-delete') }} </p>
            <div class="d-flex justify-space-between" >
                <v-btn   @click="dialog=false,selectedClassDocument=''" size="small" >
                    <span class="text-indigo">
                        {{$t('globals.cancel')}}
                    </span>
                </v-btn>
                <v-btn 
                    color="red"
                    size="small" 
                    @click="deleteClassDocument(selectedClassDocument)" 
                    >{{$t('globals.delete')}}
                </v-btn>
            </div>
        </v-sheet> -->
      <v-card class="text-center">
        <v-card-title>
          {{ $t("global.confirmation") }}
          <v-icon>mdi-alert-circle-outline</v-icon>
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions :class="$i18n.locale === 'ar' ? 'rtl' : 'ltr'">
          <v-btn
            color="red"
            @click="deleteClassDocument(selectedClassDocument)"
            >{{ $t("global.delete") }}</v-btn
          >
          <v-btn
            color="info"
            @click="(dialog = false), (selectedClassDocument = '')"
          >
            <span class="text-indigo">
              {{ $t("global.cancel") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
  <v-dialog v-model="addDialog" width="500" class="text-right">
    <v-card>
      <v-card-title class="text-right">
        <v-icon icon="mdi-text-box-plus" />
        <h3 class="d-inline" style="padding: 0 4px">
          {{ $t("school.document.add") }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </v-card-title>
      <v-card-text
        :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }"
      >
        <v-form ref="addDocument" @submit.prevent="saveDocument">
          <v-text-field
            v-model="document.name"
            counter="100"
            type="input"
            density="compact"
            clearable
            prepend-inner-icon="mdi-file-document-outline"
            :label="$t('school.document.name')"
            :rules="[
              $required,
              $max_length(),
              $min_length(3),
              $ar_letters_only,
            ]"
          />
          <v-textarea
            v-model="document.note"
            hide-details="auto"
            density="compact"
            counter="250"
            clearable
            prepend-inner-icon="mdi-note-outline"
            :label="$t('global.note')"
            :rules="[$max_length(250)]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="cleanDocumentForm">
          <span class="text-indigo">
            {{ $t("global.cancel") }}
          </span>
        </v-btn>
        <v-btn
          class="bg-indigo"
          @click="saveDocument()"
          :loading="is_loading"
          size="small"
          ><span class="text-white">{{ $t("global.save") }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <div></div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ImageInput from "@/components/Libraries/ImageInput.vue";
export default {
  name: "ClassDocument",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.documents.length) await this.getDocuments();
      await this.getClass();
      // if (!this.classes.length) await this.getClasses();
      await this.getClassDocuments(true);
      //   await this.getClasses();
      //   await this.getClassDocuments();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      // v$: useValidate(),
      index: "",
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,

      txt_search: null,
      search: null,
      selectedHead: ["document", "class", "optional", "actions"],
      document: {
        name: null,
        note: "",
      },
      classDocument: {
        fk_document: null,
        fk_class: null,
        optional: false,
        note: "",
      },
      selectedClassDocument: "",
      perPage: 10,
            // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      uniqueDocument: true,
      classes: [],
      is_loading: false,
    };
  },
  components: {
    ImageInput,
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      documents: (state) => state.school.documents,
      classDocuments: (state) => state.school.classDocuments,
    }),
    tableList() {
      var list = JSON.parse(JSON.stringify(this.classDocuments));

      list.forEach((element) => {
        let fk_document = element.fk_document;
        let fk_class = element.fk_class;
        element.document = this.getDocumentName(fk_document);
        element.class = this.getClassName(fk_class);
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    classesName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },

    documentsName() {
      return this.documents.map((item) => item.name);
    },
    headers() {
      let headers = [
        { title: this.$t("school.class.name"), key: "class" },
        { title: this.$t("school.document.name"), key: "document" },
        { title: this.$t("school.document.optional"), key: "optional" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ];

      if (this.getUserRole >= 2) {
        headers.pop();
      }
      return headers;
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },

  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getDocuments: "school/getDocuments",
      getClassDocuments: "school/getClassDocuments",
    }),
    async getClass() {
      await this.axios
        .get(`${this.base_url}api/classes/select-list/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.classes = response.data;
        });
    },
    getDocumentName(id) {
      var item = this.documents.find((item) => item.id === id);
      return item ? item.name : "";
    },
    getClassName(id) {
      var item = this.classes.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    editClassDocument(item) {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.selectedClassDocument = item.raw.id;
      this.classDocument = { ...item.raw };
      this.update = true;
      // this.axios
      //   .get(`${this.base_url}api/class-documents/${this.selectedClassDocument}/`, {
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   })
      //   .then((response) => {
      //     this.update = true;
      //     this.classDocument = response.data;
      //   });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    async saveClassDocument() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        var class_documents = [];
        this.classDocument.fk_document.forEach((el) => {
          class_documents.push({
            fk_class: this.classDocument.fk_class,
            fk_document: el,
            optional: this.classDocument.optional,
            note: this.classDocument.note,
          });
        });
        await this.axios
          .post(`${this.base_url}api/class-documents/`, class_documents, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));

            this.cleanClassDocumentForm();
            this.getClassDocuments(true);
          })
          .catch((error) => {
            if (error.message.includes("400")) {
              window.scrollTo({ top: 0, behavior: "smooth" });
              this.uniqueDocument = this.$t("global.error.validation.unique");
            } else if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async saveDocument() {
      const { valid } = await this.$refs.addDocument.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}api/documents/`, this.document, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));

            this.cleanDocumentForm();
            this.getDocuments();
          })
          .catch((error) => {
            // console.log(error);
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
      }
      this.is_loading = false;
    },
    // submitDocumentForm() {
    //   const { valid } = await this.$refs.form.validate();
    //   this.saveDocument();
    // },
    // submitClassDocumentForm() {
    //   this.v$.classDocument.$validate();
    // },
    cleanClassDocumentForm() {
      this.update = false;
      this.$refs.form.reset();
      this.classDocument.optional = false;
      //   this.v$.classDocument.$reset();
      //   this.$nextTick(() => {
      //     this.classDocument = Object.assign({}, this.defaultClassDocument);
      //   });
    },
    cleanDocumentForm() {
      this.addDialog = false;
      this.$refs.addDocument.reset();
      //   this.v$.document.$reset();
      //   this.$nextTick(() => {
      //     this.document = Object.assign({}, this.defaultDocument);
      //   });
    },

    async updateClassDocument(id) {
      //   this.submitClassDocumentForm();

      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .put(
            `${this.base_url}api/class-documents/${id}/`,
            this.classDocument,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));

            // this.showAlert = true;
            // this.alert.icon = "check-circle";
            // this.alert.color = "success";
            // this.alert.title = this.$t("alert.success.title");
            // this.alert.message = this.$t("alert.success.update");
            // setTimeout(() => {
            //   this.showAlert = false;
            // }, 1500);
            this.cleanClassDocumentForm();
            this.getClassDocuments(true);
          })
          .catch((error) => {
            try {
              if (error.message.includes("400")) {
                window.scrollTo({ top: 0, behavior: "smooth" });
                this.uniqueDocument = this.$t("global.error.validation.unique");
              } else if (error.response.data.branch) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch")
                );
              } else if (error.response.data.semester) {
                this.$emit(
                  "warningAlert",
                  this.$t("global.error.failure.branch_semester")
                );
              } else {
                this.$emit(
                  "errorAlert",
                  this.$t("global.error.failure.error_in_data")
                );
              }
            } catch (e) {}
          });
      }
      this.is_loading = false;
    },
    deleteClassDocument(id) {
      this.axios
        .delete(`${this.base_url}api/class-documents/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.dialog = false;
          this.$emit("successAlert", this.$t("global.success.data_deleted"));

          //   this.showAlert = true;
          //   this.alert.color = "success";
          //   this.alert.message = this.$t("alert.success.delete");
          //   this.alert.title = this.$t("alert.success.title");
          //   this.alert.icon = "check-circle";
          //   setTimeout(() => {
          //     this.showAlert = false;
          //   }, 1500);
          this.getClassDocuments(true);
        });
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
