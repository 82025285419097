<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
      
      <v-col cols="6" md="3" sm="12">
        <v-autocomplete
          color="indigo"
          v-model="filter_data.fk_class"
          density="compact"
          item-title="name_ar"
          item-value="id"
          prepend-inner-icon="mdi-google-classroom"
          :items="classes"
          :label="$t('class.select')"
          hide-details
          :error-messages="
            v$.filter_data.fk_class.$errors.map((e) => e.$message)
          "
        />
      </v-col>
      <v-col cols="6" md="3" sm="12">
        <v-autocomplete
          color="indigo"
          v-model="filter_data.fk_month"
          density="compact"
          item-title="name"
          item-value="id"
          prepend-inner-icon="mdi-calendar-month"
          :items="months"
          :label="$t('month.select')"
          hide-details
          :error-messages="
            v$.filter_data.fk_month.$errors.map((e) => e.$message)
          "
        />
      </v-col>

      <v-col cols="6" sm="2" class="d-flex align-center">
        <v-btn
          density="comfortable"
          append-icon="mdi-presentation"
          class="w-100"
          color="indigo"
          @click="checkFilterData"
        >
          <span>{{ $t("globals.show") }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
  <v-card class="justify-center" id="lectures-report" v-if="exist">
    <cliche class="mb-2"></cliche>
    <h3 class="text-center text-grey-darken-2">
      <h3>
        {{ $t("report.attendens_by_month") }} {{ getmonthName(filter_data.fk_month) }}
      </h3>
      <h4>{{ $t("year.for-year") }} {{ year_data }}</h4>
    </h3>
    <v-card-text class="mb-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1">
          <v-btn
            density="compact"
            @click="(exist = false), v$.filter_data.$reset()"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip
                  class="tooltip"
                  activator="attendance"
                  location="top"
                >
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list id="print-menu" density="compact">
              <v-list-item @click="printTable((all = false))">{{
                $t("globals.shown-records")
              }}</v-list-item>
              <v-list-item @click="printTable((all = true))">{{
                $t("globals.all-records")
              }}</v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('no')"
                >
                {{ (page - 1) * perPage + key + 1 }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('fk_student')"
                >
                  {{ item.student_name }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('attendance_date')"
                >
                  {{ item.attendance_date }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('is_attendancess')"
                >
                  {{ getSingleatteend(item.is_attendance) }}
                </td>
                <!-- <td class="text-center border" v-if="selectedHead.includes('secretnumber')">{{item.secretnumber}}</td> -->
              </tr>
            </tbody>
          </table>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-autocomplete
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-autocomplete>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>
    
    <script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getAcademicYears();
      await this.getClasses();
      await this.getMonths();
      await this.getattendances();
      await this.getDivisions();
      await this.getSemesters();
      await this.getSubjects({});
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      months: [],
      attendances: [],
      year_data:localStorage.getItem('current_year_name_h'),
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["no", "fk_student", "attendance_date", "is_attendancess"],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.student-name"), key: "fk_student" },
        {
          title: this.$t("globals.students-attendance"),
          key: "attendance_date",
        },
        {
          title: this.$t("globals.students-attendance"),
          key: "is_attendancess",
        },
      ],
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: "all" },
      ],
      page: 1,
      pagination: {},
      filter_data: {
        fk_month: null,
        fk_class: null,
      },
    };
  },
  computed: {
    getSingleatteend() {
      return (is_attendance) => {
        return is_attendance === true ? this.$t("globals.yes") : "absent";
      };
    },

    ...mapState({
      classes: (state) => state.school.classes,
      class_assign_hall: (state) => state.school.class_assign_hall,
      academic_years: (state) => state.school.academic_years,
      divisions: (state) => state.school.divisions,
      semesters: (state) => state.school.semesters,
      subjects: (state) => state.school.subjects,
      user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getAcademicYears: "school/getAcademicYears",
      getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      getClassAssignHall: "school/getClassAssignHall",
      getSubjects: "school/getSubjects",
    }),
    getmonthName(month_id) {
      if (month_id) {
        const month = this.months.find((month) => month.id === month_id);
        return month.name;
      }
      return "===========";
    },
    async getattendances() {
      await this.axios
        .get(`${this.base_url}school/report/student/absent`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.attendances = response.data.results;
        });
    },
    async getMonths() {
      await this.axios
        .get(`${this.base_url}month/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.months = response.data;
        });
    },
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.getData();
      }
      window.print();
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/student/absent`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // page:page,
            // page_size:this.perPage,
            fk_month: this.filter_data.fk_month,
            fk_class: this.filter_data.fk_class,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        fk_month: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_class: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
    <style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
</style>
    
    <style >
.search:focus {
  outline: unset;
}

@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
    
    