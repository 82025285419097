<template>
  {{ fields_data.book_file }}
  <VCard class="justify-center">
    <v-expand-transition>
      <div v-show="show">
        <VCardText
          class="card-fields"
          v-if="CheckGetPermission('school.change_month')"
        >
          <VForm ref="form">
            <VRow>
              <v-col cols="12" md="3" sm="12">
                <v-autocomplete
                  color="indigo"
                  v-model="fields_data.fk_class"
                  density="compact"
                  item-title="name_ar"
                  item-value="id"
                  prepend-inner-icon="mdi-google-classroom"
                  clearable
                  :items="classes"
                  :label="$t('school.class.select')"
                  :rules="[$required]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-autocomplete
                  color="indigo"
                  v-model="fields_data.fk_subject"
                  density="compact"
                  item-title="name_ar"
                  item-value="id"
                  prepend-inner-icon="mdi-book-open-variant"
                  clearable
                  :items="
                    fields_data.fk_subject
                      ? getSubjectsByClass(this.fields_data.fk_class)
                      : []
                  "
                  :label="$t('school.subject.select')"
                  :rules="[$required]"
                ></v-autocomplete>
              </v-col>
            </VRow>
          </VForm>
        </VCardText>
        <VCardActions
          v-if="is_update && CheckGetPermission('school.change_month')"
          class="px-8 d-flex flex-wrap"
          elevation="4"
        >
          <VBtn
            v-if="is_update && CheckGetPermission('school.change_month')"
            class="bg-success"
            @click="updateMonth"
            size="small"
            :loading="is_loading"
          >
            <!-- :disabled="!month.is_current" -->
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm()">
            {{ $t("global.cancel") }}
            <VIcon icon="mdi-cancel" color="red" end></VIcon>
          </VBtn>
        </VCardActions>
      </div>
    </v-expand-transition>
    <!-- ##########    data Table  ########### -->
    <!-- ######## Header Of Data Table  ####### -->
    <v-divider
      v-if="is_update && CheckGetPermission('school.change_month')"
      style="border-width: -1px; opacity: 0.25"
      class="my-1"
    />
    <v-card class="pa-2">
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="text_search"
            @input="!text_search ? (search = null) : ''"
            @keyup.enter="search = text_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <v-card v-if="items?.length < 1" class="pa-2">
        <h3 class="text-center text-grey-darken-2">
          {{ $t("school.reports.not_found") }}
        </h3>
      </v-card>

      <v-card max-width="200" style="border: none; box-shadow: none">
        <v-img
          height="20rem"
          :src="require('@/assets/img/default_7.jpg')"
          cover
        ></v-img>
        <v-card-title><h5>اللغة العربية</h5> </v-card-title>
        <v-card-actions>
          <v-btn
            color="pink"
            variant="text"
            class="w-100 h-100"
            @click="triggerFileInput"
            >Uploade <v-icon size="mini">mdi-file-upload-outline</v-icon></v-btn
          >
          <v-file-input
            ref="fileInput"
            v-model="fields_data.book_file"
            class="d-none"
          ></v-file-input>
        </v-card-actions>
      </v-card>
    </v-card>
  </VCard>
</template>
<script>
import ImageInput from "@/components/Globals/ImageInput.vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: { ImageInput },
  data() {
    return {
      month: {},
      fields_data: {},
      is_update: false,
      is_loading: false,
      show: false,
      process_alert: undefined,
      perPage: 25,
      page: 1,
      items: [1, 2, 3, 4, 5, 6, 7],
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: this.$t("global.data_table.all") },
      ],
      text_search: undefined,
      search: undefined,
      headers: [
        { title: this.$t("school.month.name_h"), key: "name_h" },
        { title: this.$t("school.month.name_m"), key: "name_m" },
        { title: this.$t("school.month.cureent_month"), key: "is_current" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: ["name_m", "name_h", "is_current", "actions"],
      txt_search: undefined,
      date_type: localStorage.getItem("date"),
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.classes.length) await this.getClasses();
      if (!this.subjects.length) await this.getSubjects({});
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
    }),
    ...mapGetters({
      getSubjectsByClass: "school/getSubjectsByClass",
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },

    tableList() {
      let list = [];
      this.months.forEach((element) => {
        list.push({
          name_m: element.name_m,
          name_h: element.name_h,
          is_current: element.is_current,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
    }),
    changeImage(event) {
      console.log(event);
      this.fields_data.book_image = event;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    resetForm() {
      this.$refs.form.reset();
      this.show = false;
      this.is_update = false;
      this.is_loading = false;
      this.updated_id = undefined;
    },
    editMonth(data) {
      let months = this.months.find((item) => item.id == data.id);
      if (months != undefined) {
        for (const key in months) this.month[key] = months[key];
        this.show = true;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async updateMonth() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.month.is_current) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}/months/${this.month.id}/`, this.month, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.getAllMonths();
            this.$emit("updateDate", {
              month_h: this.month.name_h,
              month_m: this.month.name_m,
            });
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.branch)
              this.$emit("errorAlert", this.$t("global.error.failure.branch"));
            else if (error.response.data.branch_semester)
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.branch_semester")
              );
            else if (error.response.data.month_not_semester)
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.month_not_semester")
              );
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
      } else
        this.$emit("errorAlert", this.$t("global.error.failure.error_in_data"));
      this.is_loading = false;
    },
    checkIfOnlyOneMain() {
      let month = this.months.find((item) => item.is_current == true);
      if (
        month &&
        this.month.id == month.id &&
        this.month.is_current == false
      ) {
        this.$emit(
          "errorAlert",
          this.$t("global.error.failure.cureent_month_error")
        );
      }
    },
  },
};
</script>
