<template>
  <VCard class="justify-center">
    <VForm
      v-if="
        CheckGetPermission('school.add_committees') ||
        (id && CheckGetPermission('school.change_committees'))
      "
      ref="form"
    >
      <VCard-text class="card-fields">
        <VRow>
          <VCol cols="12" xs="12" sm="12" md="4">
            <VCardItem class="pa-0">
              <VAutocomplete
                :items="hall"
                v-model="committee.fk_hall"
                prepend-inner-icon="mdi-bank-outline"
                item-title="list_name"
                item-value="id"
                :label="$t('school.committee.committee_hall')"
                persistent-hint
                density="compact"
                clearable
                :rules="[$required, validation.hall_rule]"
              ></VAutocomplete>
            </VCardItem>
          </VCol>
          <VCol cols="12" xs="12" sm="12" md="4">
            <VCardItem class="pa-0">
              <VTextField
                v-model="committee.name"
                prepend-inner-icon="mdi-group"
                :label="$t('school.committee.committee_name')"
                :placeholder="$t('school.committee.committee_name_placeholder')"
                density="compact"
                type="text"
                clearable
                :rules="[$required, $max_length(100), validation.unique]"
              >
              </VTextField>
            </VCardItem>
          </VCol>
        </VRow>
        <VRow>
          <!-- <VCol cols="12" xs="12" sm="12" md="4">
                    <VCardItem class="pa-0">
                        <VTextField
                            v-model="committee.name"
                            prepend-inner-icon="mdi-text"
                            :label="$t('school.committee_name')"
                            :placeholder="$t('school.committee_name_placeholder')"
                            density="compact"
                            type="text"
                            clearable
                            :error-messages="v$.committee.name.$errors.map(e=>e.$message)" 
                            @input="v$.committee.name.$touch"
                            @blur="v$.committee.name.$touch"
                            >
                            </VTextField>
                    </VCardItem>
                </VCol> -->
        </VRow>
        <VRow>
          <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="8">
            <VCardItem class="px-3">
              <VTextarea
                v-model="committee.note"
                prepend-inner-icon="mdi-note-outline"
                clearable
                :label="$t('global.note')"
                counter="250"
                no-resize
                density="compact"
                rows="3"
                :rules="[$max_length(250)]"
              ></VTextarea>
            </VCardItem>
          </VCol>
        </VRow>
      </VCard-text>
    </VForm>
    <VCardActions
      v-if="
        CheckGetPermission('school.add_committees') ||
        (id && CheckGetPermission('school.change_committees'))
      "
      class="px-8 d-flex flex-wrap"
      elevation="4"
    >
      <VBtn
        v-if="!id && CheckGetPermission('school.add_committees')"
        :loading="is_loading"
        class="bg-indigo"
        @click="saveCommittee"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="id && CheckGetPermission('school.change_committees')"
        :loading="is_loading"
        class="bg-success"
        @click="updateCommittee"
        size="small"
      >
        <span class="text-white">
          {{ $t("global.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ !id ? $t("global.clear") : $t("global.cancel") }}
        <VIcon
          :icon="!id ? 'mdi-broom' : 'mdi-cancel'"
          :color="!id ? 'golden' : 'gray'"
          end
        ></VIcon>
      </VBtn>
      <!-- <VBtn  class="mx-3" size="small" @click="resetForm">
                {{$t('global.clear')}}
                <VIcon icon="mdi-broom" color="golden" end></VIcon>
            </VBtn> -->
    </VCardActions>
    <!-- ###################  Dialog Delete  ################### --->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (hall_del_id = undefined)"
            >{{ $t("globals.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteHall">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      committee: {
        fk_hall: undefined,
        name: undefined,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      process_alert: undefined,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      validation: {
        hall_rule: (value) =>
          this.isUsed(value) || this.$t("global.error.failure.hall_excist"),
        unique: (value) =>
          !this.all_committee?.find(
            (item) =>
              item.id !== this.committee?.id &&
              item.name?.trim() == value?.trim()
          ) || this.$t("global.error.failure.unique_committee"),
      },
      is_loading: false,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getAllCommittees({});
      if (!this.hall.length) await this.getAllHalls();
      if (this.id != undefined)
        await this.axios
          .get(`${this.base_url}/committees/${this.id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.committee = response.data;
          });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      all_committee: (state) => state.school.committee,
      hall: (state) => state.school.hall,
      // user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getAllCommittees: "school/getAllCommittees",
      getAllHalls: "school/getAllHalls",
    }),
    resetForm() {
      if (this.id) {
        // this.$router.go(-1);
        this.$router.push({ name: "committeeslist" });
      } else {
        this.$refs.form.reset();
        // this.v$.committee.$reset()
        this.is_update = false;
      }
    },
    isUsed(value) {
      let is_new = true;
      this.all_committee.find((element) => {
        if (element.fk_hall == value && element.id != this.id) {
          is_new = false;
          return 0;
        }
      });
      return is_new;
    },
    async saveCommittee() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        // this.committee.data_entry=this.user
        let result = await this.axios
          .post(this.base_url + "/committees/", this.committee, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_saved"));
            this.getAllCommittees({});
            this.resetForm();
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.fk_hall
            )
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.hall_excist")
              );

            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async updateCommittee() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.committee.id != undefined) {
        this.is_loading = true;
        this.committee.updated_by = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}/committees/${this.committee.id}/`,
            this.committee,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.getAllCommittees({});
            this.resetForm();
            // this.$router.push({ name: "committeeslist" });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.fk_hall
            )
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.hall_excist")
              );

            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
  },
  watch: {
    id() {
      this.resetForm();
    },
  },
};
</script>
