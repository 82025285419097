<template>
  <v-card class="justify-center">
    <v-card-text class="card-view">
      <!-- <fieldset class="px-2 mt-6 border" style="border-radius: 5px">
        <legend class="v-label px-2">
          {{ $t("school.student.students") }}
        </legend> -->
      <v-row>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            ref="classField"
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes"
            clearable=""
            :label="$t('school.class.select')"
            :rules="[$required]"
            @update:model-value="filter_data.fk_division = null"
          ></v-autocomplete>
          <!-- hide-details="auto" -->
        </v-col>
        <v-col cols="6" md="3" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            clearable
            :items="class_divisions"
            :no-data-text="$t('school.division.not_found')"
            prepend-inner-icon="mdi-format-list-bulleted"
            :label="$t('school.division.select')"
          ></v-autocomplete>
          <!-- hide-details="auto" -->
        </v-col>
        <v-col cols="1">
          <v-btn
            size="small"
            class="text-small"
            color="indigo"
            @click="getStudents()"
            :loading="loading"
          >
            <span>{{ $t("global.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <!-- </fieldset> -->
    </v-card-text>
    <v-expand-transition>
      <div v-show="students_seat_numbers.length > 0" class="card-table">
        <v-card>
          <!-- class="mt-4 mx-1" -->
          <v-row class="row-nm pt-5">
            <v-col cols="12" md="3" sm="3">
              <v-text-field
                ref="public_number"
                v-model="public_number"
                color="indigo"
                density="compact"
                type="number"
                counter="10"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.seat_number.public_number')"
                :rules="[$max_length(10), $min_value(1), $numeric]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                size="small"
                class="mt-1"
                color="indigo"
                @click="destributeDynamically"
                elevation="0"
                rounded="0"
              >
                <span>{{ $t("school.seat_number.dist_dynamically") }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-row class="mt-2 mx-1">
                        <v-col cols="12" md="6" sm="12">
                            <v-text-field
                                color="indigo"
                                v-model="search_student" 
                                @input="searchStudent"
                                density="compact"
                                append-inner-icon="mdi-magnify"
                                prepend-inner-icon="mdi-school"
                                :label="$t('globals.student-search')"
                            />
                        </v-col>
                    </v-row> -->
          <v-form
            v-if="
              CheckGetPermission('school.add_seatnumbers') ||
              CheckGetPermission('school.change_seatnumbers') ||
              CheckGetPermission('school.delete_seatnumbers')
            "
            ref="form"
          >
            <v-divider />
            <v-table dense density="compact">
              <thead>
                <tr>
                  <th>#</th>
                  <th class="px-0">
                    <v-text-field
                      color="indigo"
                      v-model="search_student"
                      @input="!search_student ? searchStudent() : ''"
                      @keyup.enter="searchStudent()"
                      density="compact"
                      variant="solo-filled"
                      hide-details
                      single-line
                      append-inner-icon="mdi-magnify"
                      :label="$t('global.personal_information.name')"
                      prepend-inner-icon="mdi-account-search"
                    />
                  </th>
                  <th class="text-center">
                    {{ $t("school.seat_number.secret_number") }}
                  </th>
                  <th class="text-center">{{ $t("global.note") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student, index) in students" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td style="min-width: 300px">{{ student.name_ar }}</td>
                  <td>
                    <v-text-field
                      style="min-width: 150px"
                      v-model="student.secretnumber"
                      color="indigo"
                      density="compact"
                      type="number"
                      hide-details="auto"
                      :rules="[
                        rule ? $required : true,
                        $max_length(10),
                        $min_value(1),
                        $numeric,
                        duplicate,
                      ]"
                      @input="validate"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="student.note"
                      style="min-width: 250px"
                      density="compact"
                      :placeholder="$t('global.note')"
                      hide-details="auto"
                      class="my-1"
                      :rules="[$max_length(250)]"
                    >
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-table>
            <v-card-actions
              v-if="
                CheckGetPermission('school.add_seatnumbers') ||
                CheckGetPermission('school.change_seatnumbers') ||
                CheckGetPermission('school.delete_seatnumbers')
              "
            >
              <v-btn
                v-if="
                  deleteable
                    ? CheckGetPermission('school.change_seatnumbers')
                    : CheckGetPermission('school.add_seatnumbers')
                "
                @click="saveStudentsSeatNumbers"
                :loading="is_loading"
                :class="deleteable ? 'bg-success' : 'bg-indigo'"
                size="small"
              >
                <span class="text-white">
                  {{ deleteable ? $t("global.edit") : $t("global.save") }}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
              </v-btn>
              <v-btn
                v-if="
                  deleteable && CheckGetPermission('school.delete_seatnumbers')
                "
                @click="dialog_delete = true"
                class="bg-error"
                size="small"
              >
                <span class="text-white">
                  {{ $t("global.delete") }}
                </span>
                <v-icon icon="mdi-trash-can-outline" color="white" end></v-icon>
              </v-btn>
              <VBtn class="mx-3" size="small" @click="cleanStudentSeatNumber">
                {{ !deleteable ? $t("global.clear") : $t("global.cancel") }}
                <VIcon
                  :icon="!deleteable ? 'mdi-broom' : 'mdi-cancel'"
                  :color="!deleteable ? 'golden' : 'gray'"
                  end
                ></VIcon>
              </VBtn>
              <!-- <v-btn
              @click="cleanStudentSeatNumber"
              class="mx-3"
              size="small"
            >
              <span>
                {{ $t("global.clear") }}
              </span>
              <v-icon icon="mdi-broom" color="golden" end></v-icon>
            </v-btn> -->
            </v-card-actions>
          </v-form>
        </v-card>
      </div>
    </v-expand-transition>
  </v-card>
  <v-dialog
    color="indigo"
    v-model="dialog_delete"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="dialog_delete = false"
          >{{ $t("global.cancel") }}
        </v-btn>
        <v-btn
          color="red"
          @click="deleteStudentSeatNumbers(), (dialog_delete = false)"
          >{{ $t("global.delete") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  props: {
    id: String,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (!this.divisions.length) await this.getDivisions({});
      if (!this.class_assign_hall.length) await this.getClassAssignHall();
      if (!this.classes.length) await this.getClasses();
      // await this.getClasses()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      public_number: null,
      is_update: false,
      is_loading: false,
      loading: false,
      activities: [],
      deleteable: false,
      students: [],
      students_seat_numbers: [],
      filter_data: {
        fk_class: null,
        fk_division: null,
      },
      search_student: "",
      student_activity: {
        fk_activity: undefined,
        data_entry: undefined,
        students: [],
      },
      dialog_delete: false,
      duplicate: (value) =>
        !value ||
        this.students_seat_numbers.filter((item) => item.secretnumber == value)
          ?.length == 1 ||
        this.$t("global.error.failure.secret_number_exist"),
      // duplicate: (value) =>
      //   !value ||
      //   new Set(this.students_seat_numbers.map((item) => item.secretnumber))
      //     .size ===
      //     this.students_seat_numbers.map((item) => item.secretnumber).length ||
      //   this.$t("global.error.failure.secret_number_exist"),
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      divisions: (state) => state.school.divisions,
      class_assign_hall: (state) => state.school.class_assign_hall,
      //   user: (state) => state.User,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    class_divisions() {
      if (this.filter_data.fk_class) {
        return this.getClassDivisions(this.filter_data.fk_class);
      }
    },

    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    deleteAble(students) {
      if (students.filter((el) => el.secretnumber != null).length > 0) {
        this.deleteable = true;
      }
    },

    checkIsUpdate(data) {
      const student_with_id = data.filter((el) => {
        return Object.keys(el).includes("id");
      });
      if (student_with_id.length > 0) {
        this.is_update = true;
      }
    },
    async saveStudentsSeatNumbers() {
      //   this.students_seat_numbers.forEach((el) => {
      //     el["updated_by"] = this.user;
      //   });
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(
            `${this.base_url}api/exam/update-secret-number/`,
            this.students_seat_numbers,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if(response.data == "not_all"){
              this.$emit("warningAlert", this.$t("global.error.warning.not_all_saved"));
              this.getStudents()
            } else 
              this.$emit("successAlert", this.$t("global.success.data_saved"));
          })
          .catch((error) => {
            if (error.response.data?.secretnumber.includes("must be unique")) {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.secret_number_exist")
              );
            }
            if (error.response.data?.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data?.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async deleteStudentSeatNumbers() {
      await this.axios
        .post(
          `${this.base_url}api/exam/delete/`,
          {
            id_list: this.students_seat_numbers.map((el) => el.id),
            key: "secretnumber",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.data_deleted"));

          this.cleanStudentSeatNumber();
        })
        .catch((error) => {
          this.$emit(
            "errorAlert",
            this.$t("global.error.failure.error_in_data")
          );
        });
    },
    async destributeDynamically() {
      //   this.v$.public_number.$validate();
      const valid = await this.$refs.public_number.validate();
      if (!valid.length && this.public_number) {
        this.students_seat_numbers.forEach((el, index) => {
          el.secretnumber = `${Number(this.public_number) + index}`;
        });
      }
    },
    ...mapActions({
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getClassAssignHall: "school/getClassAssignHall",
    }),

    searchStudent() {
      if (this.search_student != "") {
        this.students = this.students_seat_numbers.filter((student) => {
          return student.name_ar.includes(this.search_student);
        });
      } else {
        this.students = this.students_seat_numbers;
      }
    },
    selectAllStudents(value) {
      this.students.forEach((studetn) => {
        studetn.subscribed = value;
      });
    },

    async getStudents() {
      const valid = await this.$refs.classField.validate();

      this.students = [];
      this.students_seat_numbers = [];
      this.is_update = false;
      if (!valid.length) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}api/exam/secret-numbers/list/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              //   year: localStorage.getItem("current_year"),
              class: this.filter_data.fk_class,
              division: this.filter_data.fk_division,
            },
          })
          .then((response) => {
            this.checkIsUpdate(response.data);
            if (response.data.length > 0) {
              this.students = response.data;
              this.students_seat_numbers = this.students;
              this.deleteAble(response.data);
            } else {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.must_be_insert_seat_number")
              );
            }
          })
          .catch((error) => {
            if (error.response.data?.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data?.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.loading = false;
    },
    async validate() {
      this.rule = false;
      await this.$refs.form.validate();
      this.rule = true;
      // setTimeout(() => {
      // }, 200);
    },

    cleanStudentSeatNumber() {
      this.$refs.form.reset();
      this.students_seat_numbers = [];
      this.students = [];
      this.is_update = false;
      this.deleteable = false;
    },
  },
};
</script>
