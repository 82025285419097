<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      sm="6"
      v-if="CheckGetPermission('school.view_student')"
    >
      <v-card class="px-2 pt-1 my-4">
        <div style="height: 150px">
          <chart :option="option_students" autoresize />
        </div>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6"
      sm="6"
      v-if="CheckGetPermission('school.view_teacher')"
    >
      <v-card class="px-2 pt-1 my-4">
        <div style="height: 150px">
          <chart :option="option_teachers" autoresize />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const colors = ["#536dfe", "#ff5252"];
const legend = {
  show: true,
  top: "10px",
  left: "left",
  orient: "vertical",
  textStyle: { fontFamily: "Almarai" },
};
const tooltip = {
  trigger: "item",
  formatter: function (params) {
    var name = params.name;
    var percent = params.percent;
    return params.marker + " " + name + " " + "(" + percent * 2 + "%)";
  },
};
export default {
  name: "Home",
  async mounted() {
    await this.axios
      .get(`${this.base_url}school/statistics/studentcount_gender`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        this.students = response.data;
      });
    // if(this.CheckGetPermission('school.view_teacher')){
    await this.axios
      .get(`${this.base_url}school/statistics/teachercount_gender`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        this.teachers = response.data;
      });
    // }
    this.series_students = [
      {
        top: "50px",
        type: "pie",
        radius: ["80%", "150%"],
        name: this.$t("school.dashboard.students"),
        center: ["50%", "70%"],
        startAngle: 180,
        data: [
          {
            value: this.students["Male"],
            name: this.$t("school.dashboard.male"),
          },
          {
            value: this.students["Female"],
            name: this.$t("school.dashboard.female"),
          },
          {
            value: this.students["Male"] + this.students["Female"],
            itemStyle: {
              color: "none",
              decal: {
                symbol: "none",
              },
            },
            label: {
              show: false,
            },
          },
        ],
        label: {
          show: true,
          formatter: "{b}:({c})",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: "10px",
          },
        },
      },
    ];
    this.series_teachers = [
      {
        top: "50",
        type: "pie",
        radius: ["80%", "150%"],
        name: this.$t("school.dashboard.teachers"),
        center: ["50%", "70%"],
        startAngle: 180,
        data: [
          {
            value: this.teachers["Male"],
            name: this.$t("school.dashboard.male"),
          },
          {
            value: this.teachers["Female"],
            name: this.$t("school.dashboard.female"),
          },
          {
            value: this.teachers["Male"] + this.teachers["Female"],
            itemStyle: {
              color: "none",
              decal: {
                symbol: "none",
              },
            },
            label: {
              show: false,
            },
          },
        ],
        label: {
          show: true,
          formatter: "{b}:({c})",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: "10px",
          },
        },
      },
    ];
    this.option_students = {
      // color:colors,
      title: [
        {
          text: this.$t("school.dashboard.students_stat"),
          left: "center",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: 12,
          },
        },
      ],
      legend: legend,
      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("school.dashboard.save-as-png"),
          },
        },
      },
      series: this.series_students,
      tooltip: tooltip,
    };
    this.option_teachers = {
      // color:colors,
      title: [
        {
          text: this.$t("school.dashboard.teachers_stat"),
          left: "center",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: 14,
          },
        },
      ],
      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("school.dashboard.save-as-png"),
          },
        },
      },
      series: this.series_teachers,
      legend: legend,
      tooltip: tooltip,
    };
  },
  props: {
    name: String,
  },
  data() {
    return {
      students: {},
      teachers: {},
      series_students: [],
      series_teachers: [],
      option_students: {},
      option_teachers: {},
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
};
</script>
