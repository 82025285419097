<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row class="my-2 mt-6"> -->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="
              (filter_data.fk_class = null),
                (filter_data.fk_division = null),
                (student_list = []),
                (filter_data.fk_student = null),
                getClassAssignHall(filter_data.fk_branch?.id),
                getBranchClasses(filter_data.fk_branch?.id)
            "
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="
              getUserRole < 2
                ? filter_data.fk_branch
                  ? classes_by_branch
                  : []
                : classes_by_branch
            "
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:model-value="
              (filter_data.fk_division = null),
                (student_list = []),
                (filter_data.fk_student = null)
            "
          />
        </v-col>
        <v-col cols="6" :md="getUserRole < 2 ? '3' : '5'" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            :no-data-text="$t('school.division.not_found')"
            prepend-inner-icon="mdi-chair-school"
            :items="
              filter_data.fk_class?.id
                ? getClassDivisions(filter_data.fk_class?.id, getUserRole < 2)
                : []
            "
            @update:model-value="(filter_data.fk_student = null), getStudents()"
            :label="$t('school.reports.select_division')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" md="5" sm="12">
          <v-autocomplete
            ref="select"
            color="indigo"
            v-model="filter_data.fk_student"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-school"
            :items="student_list"
            :label="$t('school.reports.select_student')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <v-col cols="6" md="5" sm="12">
          <v-autocomplete
            ref="select"
            color="indigo"
            v-model="filter_data.fk_month"
            density="compact"
            :item-title="date_type == 'm' ? 'name_m' : 'name_h'"
            item-value="id"
            prepend-inner-icon="mdi-calendar"
            :items="getCurrentSemesterMonths"
            :label="$t('school.reports.select_month')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <!-- <v-col cols="6" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_class"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-google-classroom"
            :items="classes"
            :label="$t('school.reports.select_class')"
            hide-details="auto"
            :rules="[$required]"
            return-object
            @update:model-value="filter_data.fk_division = null"
          />
        </v-col>
        <v-col cols="6" md="5" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-chair-school"
            :items="class_divisions"
            :label="$t('school.reports.select_division')"
            @update:model-value="(filter_data.fk_student = null), getStudents()"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col> -->
        <v-col cols="6" sm="2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card dir="rtl" class="justify-center" id="lectures-report" v-if="exist">
    <report-header ar rep_en :data="filter_data.fk_month"></report-header>

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="!items?.subject?.length ?? items?.length" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card
          :loading="loading"
          v-if="items?.subject?.length ?? items?.length"
        >
          <v-row class="mb-1 mt-2 borders justify-center pt-2 pb-2 mr-1 ml-1">
            <v-col cols="auto" class="flex-grow-1">
              <h3 class="fontsize">
                اسم الطالب :
                {{ items.student }}
              </h3>
            </v-col>

            <v-col cols="3">
              <h3 class="fontsize">
                الصف :
                {{ items.class }}
              </h3>
            </v-col>
            <v-col cols="3">
              <h3>
                الشعبة :
                {{ filter_data.fk_division?.name }}
              </h3>
            </v-col>
            <!-- <v-col cols="4">
              <h3 class="fontsize">
                {{ $t("school.reports.month") }} :
                {{
                  filter_data.fk_month?.name_m ?? filter_data.fk_month?.name_h
                }}
              </h3>
            </v-col> -->
          </v-row>
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <!-- <tr>
                <th class="py-1 px-2" :colspan="selectedHeaders?.length">
                  <div class="d-flex text-white">
                    <h3>
                      {{ $t("school.reports.student_name") }} :
                      {{ items.student }}
                    </h3>
                    <v-spacer></v-spacer>
                    <h3>
                      {{ $t("school.reports.class") }} : {{ items.class }}
                    </h3>
                    <v-spacer></v-spacer>
                    <h3>
                      {{ $t("school.reports.month") }} :
                      {{
                        filter_data.fk_month?.name_m ??
                        filter_data.fk_month?.name_h
                      }}
                    </h3>
                  </div>
                </th>
              </tr> -->
              <tr>
                <th rowspan="2">
                  {{ selectedHeaders[0].title }}
                </th>
                <th colspan="4" class="border">
                  {{
                    filter_data.fk_month?.name_m ?? filter_data.fk_month?.name_h
                  }}
                </th>
                <th colspan="2" class="border">مجموع الدرجات</th>
                <th rowspan="2">
                  {{ selectedHeaders[selectedHeaders?.length - 1].title }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="header in selectedHeaders.filter(
                    (head) =>
                      head.key != 'estimation' && head.key != 'fk_subject'
                  )"
                  :key="header.key"
                  class="border"
                >
                  {{ header.title }}
                </th>
              </tr>
              <!-- <tr>
                <th
                  v-for="header in selectedHeaders"
                  :key="header.key"
                  class="border background-cell"
                >
                  {{ header.title }}
                </th>
              </tr> -->
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(item, key) in items?.subject" :key="key">
                <template
                  v-for="(header, index) in selectedHeaders"
                  :key="index"
                >
                  <!-- <template v-if="index === 0">
                    <td class="text-center border">{{ key + 1 }}</td>
                  </template> -->
                  <template v-if="header.key === 'writing'">
                    <td class="text-center border">
                      {{
                        item?.total ? tafqeet(item.total) + " " + "درجة" : "-"
                      }}
                    </td>
                  </template>
                  <td
                    v-else
                    :class="`text-center border ${
                      index == selectedHeaders?.length - 2
                        ? ' background-cell'
                        : ''
                    }`"
                  >
                    {{ item[header.key] ? item[header.key] : "-" }}
                  </td>
                </template>
              </tr>
              <tr>
                <td class="text-center border background-cell">
                  الاجمالي الكلي
                </td>
                <td colspan="4" class="text-center border background-cell">
                  {{ totalMarks() }} <v-icon>mdi-circle-small</v-icon>
                  {{ tafqeet(totalMarks()) }}
                </td>
                <!-- <td class="text-center border background-cell">{{ $t("school.reports.percentage") }} %</td> -->
                <td class="text-center border background-cell">المعدل</td>
                <td class="text-center border background-cell">
                  %{{ getPercentageValue() }}
                </td>
                <td class="text-center border background-cell">
                  التقدير العام :
                  {{ estimateChecker(getPercentageValue()) }}
                </td>
                <!-- <td class="text-center border background-cell">dsf</td> -->
              </tr>
              <!-- <td colspan="4" class="text-center border background-cell">
                  {{ $t("school.reports.estimate") }}
                </td> -->
              <!-- {{ items.estimate }} -->
              <!-- <tr v-for="item in items[0].data" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('subject')"
                >
                  <div v-if="item[0]?.optional == false">
                    {{ item[0]?.subject_name }}
                  </div>
                  <div v-else>{{ item[0]?.subject_name }} *</div>
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('exam')"
                >
                  {{ item[0]?.exam }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('oral')"
                >
                  {{ item[0]?.oral }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('assigments')"
                >
                  {{ item[0]?.assigments }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('attendance_mark')"
                >
                  {{ item[0]?.attendance_mark }}
                </td>
                <td
                  :class="[
                    'text-center border',
                    item[0]?.total < 50 ? 'failed' : 'background-cell',
                  ]"
                  v-if="selectedHead.includes('total')"
                >
                  {{ item[0]?.total }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('writing')"
                >
                  {{ tafqeet(item[0]?.total) }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('estimation')"
                >
                  {{ item[0]?.estimation }}
                </td>
                <td v-if="selectedHead.includes('empty')"></td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_actual_lecture')"
                >
                  {{
                    item[0]?.attendance[0].subject_actual_count_of_attendance
                  }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_attendence')"
                >
                  {{ item[0]?.attendance[0].attendance_student_count }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_absentce')"
                >
                  {{ item[0]?.attendance[0].absence_student_count }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_permission')"
                >
                  {{ item[0]?.attendance[0].is_permission_count }}
                </td>
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('count_of_not_permission')"
                >
                  {{ item[0]?.attendance[0].is_not_permission_count }}
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-center border background-cell">
                  {{ $t("school.reports.total_degrees") }}
                </td>
                <td class="text-center border background-cell">
                  {{ subjectsTotal }}
                </td>
                <td colspan="9" class="text-center border">
                  {{ tafqeet(subjectsTotal) }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td class="text-center border background-cell">
                  {{ $t("school.reports.percentage") }}
                  {{ getPercentageValue(subjectsTotal, subjectsCount) }}
                </td>
                <td colspan="5" class="text-center border background-cell"></td>
                <td colspan="4" class="text-center border background-cell">
                  {{ $t("school.reports.estimate") }}
                </td>
                <td colspan="4" class="text-center border background-cell">
                  {{ items[0].estimate }}
                </td>
              </tr> -->
            </tbody>
          </table>
          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="items?.length"
          ></pagination> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
import tafqeet from "@/assets/Tafqeet";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      //   await this.getYears();
      await this.getClassAssignHall();
      await this.getMonths();
      if (this.getUserRole > 1) {
        await this.getBranchClasses();
      }
      await this.getBrenches({});
      await this.getSemesters();
      await this.getDivisions({});
      await this.getAllEstimations();
      await this.getCuMonth();
      //   await this.axios
      //     .get(`${this.base_url}school-data/`, {
      //       headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //     })
      //     .then((response) => {
      //       this.school = response.data;
      //     });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      //   school: [],
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   v$: useValidate(),
      exist: false,
      items: [{ data: [{}], student_name: null }],
      loading: false,
      date_type: localStorage.getItem("date"),
      //   txt_search: "",
      selectedHead: [
        "fk_subject",
        "exam",
        "oral",
        "attendance",
        "assignments",
        "total",
        "writing",
        "estimation",
      ],
      headers: [
        { title: "اسم المادة", key: "fk_subject" },
        { title: "المواضبة", key: "attendance" },
        { title: "واجبات", key: "assignments" },
        { title: "شفهي", key: "oral" },
        { title: "تحريري", key: "exam" },
        // {
        //   title: this.$t("school.reports.attendance_mark"),
        //   key: "attendance_mark",
        // },
        { title: "رقما", key: "total" },
        { title: "كتابة", key: "writing" },
        {
          title: "التقدير",
          key: "estimation",
        },
      ],
      // perPage: 25,
      //   length: 0,
      totalItems: 0,
      // page: 1,
      //   pagination: {},
      student_list: [],
      filter_data: {},
      subjectsTotal: 0,
      subjectsCount: 0,
    };
  },
  computed: {
    ...mapState({
      // classes: (state) => state.school.classes,
      classes_by_branch: (state) => state.school.classes_by_branch,
      divisions: (state) => state.school.divisions,
      months: (state) => state.school.months,
      estimates: (state) => state.school.estimates,
      branch: (state) => state.school.branch,
      classes_by_branch: (state) => state.school.classes_by_branch,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
      getCurrentSemesterMonths: "school/getCurrentSemesterMonths",
    }),

    class_divisions() {
      if (this.filter_data.fk_class?.id) {
        return this.getClassDivisions(this.filter_data.fk_class?.id);
      }
    },

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getAllEstimations: "school/getAllEstimations",
      getSemesters: "school/getSemesters",
      getBrenches: "school/getBrenches",
      getBranchClasses: "school/getBranchClasses",
      getClassAssignHall: "school/getClassAssignHall",
      getMonths: "school/getMonths",
      //   getAllEstimations: "school/getAllEstimations",
      getDivisions: "school/getDivisions",
    }),
    tafqeet,
    totalMarks() {
      return this.items?.subject.reduce(
        (sum, subject) => sum + subject.total,
        0
      );
    },
    getPercentageValue() {
      const total = this.items?.subject?.length;
      return parseFloat(this.totalMarks() / total).toFixed(2);
    },
    estimateChecker(degree) {
      return this.estimates.find(
        (estimate) =>
          degree >= estimate.degree_min && degree <= estimate.degree_max
      )?.name_ar;
    },
    // getDivisionName(division_id) {
    //   const division = this.divisions.find(
    //     (division) => division.id === division_id
    //   );
    //   return division.name;
    // },
    // getSubjectsTotal(subjects) {
    //   console.log(subjects);
    //   const { sum, count } = subjects.subject.reduce(
    //     (accumulator, obj) => {
    //       if (obj[0].optional == false)
    //         return {
    //           sum: accumulator.sum + obj[0].total,
    //           count: accumulator.count + 1,
    //         };
    //       else
    //         return {
    //           sum: accumulator.sum,
    //           count: accumulator.count,
    //         };
    //     },
    //     { sum: 0, count: 0 }
    //   );
    //   this.subjectsTotal = sum;
    //   this.subjectsCount = count;
    // },
    // getTotal(collector, finalExam) {
    //   return collector + finalExam;
    // },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    // getMonthName(month_id) {
    //   if (month_id) {
    //     const month = this.months.find((month) => month.id === month_id);
    //     return month.name;
    //   }
    //   return "-------------";
    // },
    // getClassName(class_id) {
    //   const objectClass = this.classes.find(
    //     (objectClass) => objectClass.id === class_id
    //   );
    //   return objectClass.name_ar;
    // },
    async getStudents() {
      if (this.filter_data.fk_division?.id != undefined) {
        let list = await this.axios.get(`${this.base_url}student-list/`, {
          params: {
            fk_branch: this.filter_data.fk_branch?.id,
            class: this.filter_data.fk_class?.id,
            division: this.filter_data.fk_division?.id,
            // year: this.filter_data.year,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.student_list = list.data;
      }
    },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    // async printTable(all) {
    //   if (all) {
    //     this.txt_search = null;
    //     this.perPage = this.totalItems;
    //     await this.getData();
    //   }
    //   window.print();
    // },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}/api/month_exam_result`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            // search: this.txt_search,
            page: page,
            page_size: this.perPage,
            // class_number: this.filter_data.fk_class?.id,
            // year: this.filter_data.year,
            month: this.filter_data.fk_month?.id,
            student: this.filter_data.fk_student?.id,
            // class_division: this.filter_data.fk_division?.id,
            // fk_branch: this.filter_data.fk_branch?.id,
          },
        })
        .then((response) => {
          if (response.data?.results) {
            this.items = response.data?.results;
            // this.totalItems = response.data?.pagination?.count;
            this.exist = true;
            // this.totalItems = response.data.pagination.count;
            // this.length = response.data.pagination.num_pages;
            // this.pagination = response.data.pagination;
            this.loading = false;
            // this.getSubjectsTotal(this.items);
          }
        })
        .catch((error) => {
          if (error.message.includes("Network Error")) {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
        });
      this.loading = false;
    },
    getCuMonth() {
      this.filter_data.fk_month = this.getCurrentSemesterMonths?.find(
        (month) =>
          month.name_h == localStorage.getItem("month_h") ||
          month.name_m == localStorage.getItem("month_m")
      );
    },
  },
  //   validations() {
  //     return {
  //       filter_data: {
  //         class: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //         year: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //         month: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //         student: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //         division: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //       },
  //     };
  //   },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
.fontsize {
  font-size: 14px;
  text-align: center;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
#myTable th {
  height: 30px;
}
#myTable td {
  height: 30px;
}
#myTable .tfoot td {
  height: 40px;
}
/* td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
}
.failed {
  background: #e57373;
} */
/* @media print {
  thead tr th:nth-of-type(4),
  thead tr th:nth-of-type(5),
  .background-cell,
  .failed {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
} */
thead tr th:nth-of-type(4),
thead tr th:nth-of-type(5),
.background-cell {
  background: #b1cdb6;
}
thead tr th:nth-of-type(n + 8) {
  width: 35px;
  font-size: 9px;
  font-weight: bold;
}
/* thead tr th:nth-of-type(2),
thead tr th:nth-of-type(3),
thead tr th:nth-of-type(4),
thead tr th:nth-of-type(5) {
  width: 70px;
  font-size: 0.8rem;
  font-weight: bold;
} */
thead tr th:nth-of-type(6)
/* thead tr th:nth-of-type(7) */ {
  width: 120px;
}
thead tr th:nth-last-child() {
  width: 170px;
}
/* thead tr th:nth-of-type(14) {
  width: 60px;
} */
</style>
