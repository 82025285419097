<template>
    
    <VDialog
        v-model="value"
        scrollable
        :overlay="false"
        max-width="350"
        
        elevation="12"
    >
        <VSheet 
            rounded="lg"
            width="100%"
            class="pa-4 text-center mx-auto"
        >
            <VIcon
                class="mb-5"
                :color="iconColor"
                :icon="`mdi-${icon}`"
                size="55"
            ></VIcon>

            <h2 class="text-h5 mb-6 text-blue-grey-darken-4" style="font-family:'Almarai' !important;font-size:2.5rem !important"> {{ title }} </h2>
            <p class="mb-4 text-medium-emphasis text-body-2 text-blue-grey-darken-4"  style="font-family:'Almarai' !important;font-size:14px !important;"> {{ message }} </p>

            <!-- <div class="text-end">
                <VBtn
                    class="text-none bg-white font-weight-bold"
                    color="blue"
                    @click.prevent="notSelectedAccountDialog = false"
                >
                    {{ $t("globals.back") }}
                </VBtn>
            </div> -->
        </VSheet>
    </VDialog>
</template>

<script>

export default{
    props:{
        modelValue:{
            type:Boolean,
            default: false,
        },
        iconColor:String,
        icon:String,
        title:String,
        message:String,
    },
    computed:{
        value: {
            get(){
                return this.modelValue
            },
            set(value){
                this.$emit('update:modelValue',value)
            }
        },
    }
}


</script>