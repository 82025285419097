<template>
  <v-card class="justify-center">
    <v-expand-transition
      v-if="
        update ||
        (checkRole(['programmer', 'big_admin']) &&
          (CheckGetPermission('school.add_semester') ||
            (update && CheckGetPermission('school.update_semester'))))
      "
    >
      <div v-show="update || getUserRole < 2">
        <v-card-text class="card-fields">
          <v-form
            ref="form"
            @submit.prevent="saveSemester()"
            @keydown.enter="handelKeyEnter()"
          >
            <v-row justify="start">
              <v-col cols="12" md="5">
                <v-text-field
                  color="indigo"
                  v-model="semester.name_ar"
                  clearable
                  counter="50"
                  :readonly="getUserRole > 1"
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('school.semester_data.name-ar')"
                  :rules="[
                    $required,
                    $max_length(50),
                    $ar_letters_only,
                    uniqueSemester,
                  ]"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  color="indigo"
                  v-model="semester.name_en"
                  clearable
                  counter="50"
                  :readonly="getUserRole > 1"
                  density="compact"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('school.semester_data.name-en')"
                  :rules="[
                    $required,
                    $max_length(50),
                    $en_letters_only,
                    uniqueSemesterEn,
                  ]"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  color="indigo"
                  v-model="semester.num_months"
                  clearable
                  :readonly="getUserRole > 1"
                  density="compact"
                  type="number"
                  prepend-inner-icon="mdi-numeric"
                  :label="$t('school.semester_data.months')"
                  :rules="[$required, $max_value(12), $numeric]"
                />
              </v-col>
              <v-col cols="12" md="5">
                <v-autocomplete
                  color="indigo"
                  v-model="semester.fk_month"
                  density="compact"
                  :item-title="date_type == 'h' ? 'name_h' : 'name_m'"
                  item-value="id"
                  multiple
                  clearable
                  :readonly="getUserRole > 1"
                  prepend-inner-icon="mdi-calendar-month"
                  :items="unusedMonths"
                  :label="$t('school.semester_data.months')"
                  :rules="months_list"
                ></v-autocomplete>
              </v-col>
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
                <VCardItem class="pa-0">
                  <VCheckbox
                    v-model="semester.is_current"
                    :label="$t('school.semester_data.current_semester')"
                    color="blue"
                    density="compact"
                  >
                  </VCheckbox>
                </VCardItem>
              </VCol>
            </v-row>
            <v-row justify="start">
              <v-col cols="12" md="10">
                <v-textarea
                  color="indigo"
                  v-model="semester.note"
                  hide-details="auto"
                  density="compact"
                  counter="250"
                  clearable
                  rows="3"
                  prepend-inner-icon="mdi-note-outline"
                  :label="$t('global.note')"
                  :rules="[$max_length(250)]"
                />
              </v-col>
            </v-row>
          </v-form>
          <!-- </v-card>
        </v-col>
      </v-row> -->
        </v-card-text>
        <v-card-actions
          class="px-8"
          v-if="update || checkRole(['programmer', 'big_admin'])"
        >
          <v-btn
            v-if="
              !update &&
              checkRole(['programmer', 'big_admin']) &&
              CheckGetPermission('school.add_semester')
            "
            @click="saveSemester"
            class="bg-indigo"
            :loading="is_loading"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <v-btn
            v-if="update && CheckGetPermission('school.change_semester')"
            @click="updateSemester(selectedSemester)"
            class="bg-success"
            :loading="is_loading"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <v-icon icon="mdi-content-save" color="white" end></v-icon>
          </v-btn>
          <VBtn class="mx-3" size="small" @click="cleanSemesterForm">
            {{ !update ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!update ? 'mdi-broom' : 'mdi-cancel'"
              :color="!update ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
          <!-- <v-btn @click="cleanSemesterForm" class="mx-3" size="small">
            <span>
              {{ $t("global.clear") }}
            </span>
            <v-icon icon="mdi-broom" color="golden" end></v-icon>
          </v-btn> -->
        </v-card-actions>

        <v-divider class="mt-5"></v-divider>
      </div>
    </v-expand-transition>

    <!-- ##########    data Table Server ########### -->
    <v-card class="pa-2" v-if="CheckGetPermission('school.view_semester')">
      <!-- ######## Header Of Data Table Server ####### -->
      <div>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="text_search"
            @input="!text_search ? (search = null) : ''"
            @keyup.enter="search = text_search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :headers="selectedHeaders"
        :items="tableList"
        :items-per-page="perPage"
        item-value="name_en"
        item-key="id"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length - 1" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length - 1" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <span v-if="key == 'is_current'">
                <v-icon v-if="val == true" color="success"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-if="val == false" color="error"
                  >mdi-close-circle</v-icon
                >
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="selectSemester(item.raw)"
                  v-if="CheckGetPermission('school.change_semester')"
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  @click="(selectedSemester = item.raw.id), (dialog = true)"
                  v-if="
                    checkRole(['programmer', 'big_admin']) &&
                    CheckGetPermission('school.delete_semester')
                  "
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.alert.confirm_delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(dialog = false), (selectedSemester = '')"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteSemester()">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Semester",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getSemesters(true);
      if (!this.months.length) await this.getMonths();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      name: "",
      name_en: "",
      text_search: null,
      search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      is_loading: false,
      date_type: localStorage.getItem("date"),

      selectedHead: [
        "name_ar",
        "name_en",
        "fk_month",
        "is_current",
        "num_months",
        "actions",
      ],
      perPage: 10,
      page: 1,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: this.$t("global.data_table.all") },
      ],
      selectedSemester: null,
      semester: {},
      uniqueSemester: (value) => {
        if (!this.update || this.name != this.semester.name_ar.trim())
          return !this.semesterNames.includes(value.trim())
            ? true
            : this.$t("global.error.validation.unique");
        return true;
      },
      months_list: [
        (value) => !!value || this.$t("global.error.validation.required_field"),
        (value) => {
          if (value) {
            if (value.length > 0) {
              // Extract month numbers from selected options
              let list = value.map((option) => option).sort((a, b) => a - b);
              if (this.validateChoice(list)) return true;
              return this.$t("global.error.validation.sequential");
            }
          }
          if (Object.keys(value).length == 0) {
            return this.$t("global.error.validation.required_field");
          }
        },
        (value) => {
          if (value) {
            if (value.length != this.semester.num_months)
              return `${
                this.$t("global.error.validation.max_months") +
                this.semester.num_months
              }`;
          }
          return true;
        },
      ],
      uniqueSemesterEn: (value) => {
        const list = this.semesterNamesEn.map((item) => item.toLowerCase());
        if (
          !this.update ||
          this.name_en != this.semester.name_en.trim().toLowerCase()
        )
          return !list.includes(value.trim().toLowerCase())
            ? true
            : this.$t("global.error.validation.unique");
        return true;
      },
      headers: [
        { title: this.$t("school.semester_data.name-ar"), key: "name_ar" },
        { title: this.$t("school.semester_data.name-en"), key: "name_en" },
        {
          title: this.$t("school.semester_data.num-months"),
          key: "num_months",
        },
        { title: this.$t("school.semester_data.months"), key: "fk_month" },
        {
          title: this.$t("school.semester_data.current_semester"),
          key: "is_current",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    semesterNames() {
      return this.semesters.map((item) => item.name_ar);
    },
    semesterNamesEn() {
      return this.semesters.map((item) => item.name_en);
    },
    ...mapState({
      semesters: (state) => state.school.semesters,
      months: (state) => state.school.months,
    }),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      if (this.semesters.length)
        this.semesters.forEach((element) => {
          let obj = element;
          let month = "";
          this.months.forEach((element2) => {
            if (element2.fk_semester == element.id)
              month +=
                (this.date_type == "h" ? element2.name_h : element2.name_m) +
                ",";
          });
          obj.fk_month = month;
          list.push(obj);
        });
      return list;
    },
    unusedMonths() {
      let list = [];
      this.months.forEach((element) => {
        if (
          element.fk_semester == undefined ||
          element.fk_semester == this.selectedSemester
        )
          list.push(element);
      });
      return list;
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },

  methods: {
    ...mapActions({
      getSemesters: "school/getSemesters",
      getMonths: "school/getMonths",
    }),
    validateChoice(choice) {
      if (!choice.length) return false;
      // Extract and order valid month IDs from this.months
      const validMonthIds = this.months
        .map((month) => month.id)
        .sort((a, b) => a - b);
      // set of valid months (1 to 12)
      const validMonths = new Set(validMonthIds);
      // check if all elements are within valid range (1-12)
      const allValid = choice.every((month) => validMonths.has(month));
      if (!allValid) return false;
      // Minimum and maximum chosen months
      const minMonth = Math.min(...choice);
      const maxMonth = Math.max(...choice);
      // Expected number of elements for consecutive selection
      const expectedCount = maxMonth - minMonth + 1;
      // check element count and gaps
      return (
        choice.length === expectedCount &&
        !choice.some((month, index) => month !== minMonth + index)
      );
    },
    handelKeyEnter() {
      if (this.update) {
        this.updateSemester(this.selectedSemester);
      } else {
        this.saveSemester();
      }
    },
    selectSemester(item) {
      this.selectedSemester = item.id;
      this.name = item.name_ar.trim();
      this.name_en = item.name_en.trim().toLowerCase();
      // delete item.id
      this.semester = Object.assign({}, item);
      this.semester.fk_month = [];
      this.months.forEach((element) => {
        if (element.fk_semester == this.selectedSemester)
          this.semester.fk_month.push(element.id);
      });
      this.update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cleanSemesterForm() {
      this.update = false;
      this.$refs.form.reset();
      this.semester = {};
      this.selectedSemester = null;
    },
    async saveSemester() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .post(`${this.base_url}api/semesters/`, this.semester, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("global.success.insert"));
            if (this.semester.is_current) {
              localStorage.setItem("month_h", undefined);
              localStorage.setItem("month_m", undefined);
              this.$emit("updateDate", {
                semester_ar: this.semester.name_ar,
                semester_en: this.semester.name_en,
              });
            }
            this.getSemesters(true);
            this.getMonths();
            this.cleanSemesterForm();
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },

    async updateSemester(id) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        await this.axios
          .put(`${this.base_url}api/semesters/${id}/`, this.semester, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            if (
              this.tableList.find((item) => item.is_current)?.id !=
              this.semester.id
            ) {
              localStorage.setItem("month_h", undefined);
              localStorage.setItem("month_m", undefined);
            }
            this.getSemesters(true);
            this.getMonths();
            this.$emit("updateDate", {
              semester_ar: this.semester.name_ar,
              semester_en: this.semester.name_en,
            });
            this.cleanSemesterForm();
          })
          .catch((error) => {
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            } else {
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            }
          });
      }
      this.is_loading = false;
    },
    async deleteSemester() {
      await this.axios
        .delete(`${this.base_url}api/semesters/${this.selectedSemester}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.$emit("successAlert", this.$t("global.success.delete"));
          if (
            this.tableList.find((item) => item.id == this.selectedSemester)
              ?.is_current
          ) {
            localStorage.setItem("month_h", undefined);
            localStorage.setItem("month_m", undefined);
            localStorage.setItem("semester_ar", undefined);
            localStorage.setItem("semester_en", undefined);
          }
          this.getSemesters(true);
          this.getMonths();
          this.$emit("updateDate");
          this.cleanSemesterForm();
          this.selectedSemester = null;
        })
        .catch((error) => {
          if (error.response.data.branch) {
            this.$emit("warningAlert", this.$t("global.error.failure.branch"));
          } else if (error.response.data.semester) {
            this.$emit(
              "warningAlert",
              this.$t("global.error.failure.branch_semester")
            );
          } else if (error.response?.status == 418) {
            this.$emit("protectedAlert", error.response?.data?.data);

            // this.$emit(
            //   "errorAlert",
            //   this.$t("global.error.failure.protected_record")
            // );
          } else {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          }
        });
      this.dialog = false;
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
