<template>
  <VCard>
    <!-- <VCard-title class="my-4">
                <VIcon icon="mdi-map-outline"/>
                <h2 class="d-inline text-grey" style="padding:0 4px;" >{{$t('school.directorate.add')}}</h2>
                <v-divider style="border-width:1px; opacity: 0.5;" class="my-2"/>
            </VCard-title> -->
    <v-expand-transition v-if="getUserRole < 2">
      <div v-show="(update && getUserRole < 2) || getUserRole < 2">
        <VCard-text class="card-fields">
          <VForm
            ref="form"
            class=""
            v-if="
              CheckGetPermission('globaltable.add_directorate') ||
              (is_update &&
                CheckGetPermission('globaltable.change_directorate'))
            "
          >
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="4">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="countries"
                    v-model="directorate.fk_country"
                    prepend-inner-icon="mdi-flag"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('school.country.name')"
                    persistent-hint
                    density="compact"
                    @update:modelValue="directorate.fk_governorate = undefined"
                    :rules="[$required]"
                  ></VAutocomplete>
                </VCardItem>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="4">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="filterGovernorates(directorate.fk_country)"
                    v-model="directorate.fk_governorate"
                    prepend-inner-icon="mdi-city"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('school.governorate.name')"
                    persistent-hint
                    density="compact"
                    @update:model-value="
                      directorate.name_ar ? $refs.directorate.validate() : ''
                    "
                    :rules="[$required]"
                  ></VAutocomplete>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" xs="12" sm="12" md="4">
                <VCardItem class="pa-0">
                  <VTextField
                    ref="directorate"
                    v-model="directorate.name_ar"
                    prepend-inner-icon="mdi-map-outline"
                    :label="$t('school.directorate.name_ar')"
                    :placeholder="
                      $t('school.directorate.placeholder_directorate_name_ar')
                    "
                    density="compact"
                    :rules="[
                      $required,
                      $max_length(50),
                      $min_length(2),
                      $ar_letters_only,
                      uniqueDirectorate,
                    ]"
                  >
                    <template v-slot:label>
                      {{ $t("school.directorate.name_ar") }}
                      <v-icon icon="mdi-abjad-arabic"></v-icon>
                    </template>
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="directorate.name_en"
                    prepend-inner-icon="mdi-map-outline"
                    :label="$t('school.directorate.name_en')"
                    :placeholder="
                      $t('school.directorate.placeholder_directorate_name_en')
                    "
                    density="compact"
                    :rules="[
                      $required,
                      $max_length(50),
                      $min_length(2),
                      $en_letters_only,
                      uniqueDirectorate,
                    ]"
                  >
                    <template v-slot:label>
                      {{ $t("school.directorate.name_en") }}
                      <v-icon icon="mdi-alpha-e"></v-icon>
                    </template>
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="8" lg="8">
                <VCardItem class="px-3">
                  <VTextarea
                    v-model="directorate.note"
                    clearable
                    :label="$t('global.note')"
                    prepend-inner-icon="mdi-note-outline"
                    :placeholder="$t('global.notes_placeholder')"
                    no-resize
                    counter="250"
                    density="compact"
                    rows="3"
                    :rules="[$max_length(250)]"
                  ></VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
          </VForm>
        </VCard-text>
        <VCardActions
          class="px-8"
          v-if="
            CheckGetPermission('globaltable.add_directorate') ||
            (is_update && CheckGetPermission('globaltable.change_directorate'))
          "
        >
          <VBtn
            v-if="
              !is_update && CheckGetPermission('globaltable.add_directorate')
            "
            class="bg-indigo"
            :loading="is_loading"
            @click="saveDirectorate()"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn
            v-if="
              is_update && CheckGetPermission('globaltable.change_directorate')
            "
            class="bg-success"
            :loading="is_loading"
            @click="updateDirectorate()"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm">
            {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
              :color="!is_update ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
        </VCardActions>
        <v-divider class="mt-5" />
      </div>
    </v-expand-transition>
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div >
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            class="search"
            hide-details
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }} </span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('globaltable.change_directorate')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editItem(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('globaltable.delete_directorate')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="deleteItem(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
  </VCard>

  <!-- ############### Dialog Delete  ################### -->
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="cancelDeleteItem">{{
          $t("global.cancel")
        }}</v-btn>
        <v-btn color="red" @click="deleteDirectorate">{{
          $t("global.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- 
  <VDialog v-model="del_dialog" scrollable :overlay="false" max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("global.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("global.alert.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteDirectorate"
        >
          {{ $t("global.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("global.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog> -->
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      // v$: useValidate(),
      directorate: {
        name_ar: undefined,
        name_en: undefined,
        country: undefined,
        fk_governorate: undefined,
        note: undefined,
        data_entry: undefined,
      },
      is_update: false,
      is_loading: false,
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,
      headers: [
        { title: this.$t("school.directorate.name_ar"), key: "name_ar" },
        { title: this.$t("school.directorate.name_en"), key: "name_en" },
        { title: this.$t("school.country.name"), key: "country" },
        { title: this.$t("school.governorate.name"), key: "governorate" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],

      selectedHead: [
        "name_ar",
        "name_en",
        "country",
        "fk_governorate",
        "note",
        "actions",
      ],
      txt_search: null,
      search: null,
      perPage: 10,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
      uniqueDirectorate: (value) => {
        if (
          this.tableList.some((item) => {
            if (
              item.fk_country == this.directorate.fk_country &&
              item.fk_governorate == this.directorate.fk_governorate
            ) {
              return (
                (item.name_ar.trim() === value.trim() ||
                  item.name_en.trim().toLowerCase() ===
                    value.trim().toLowerCase()) &&
                item.id !== this.directorate.id
              );
            }
          })
        ) {
          return this.$t("global.error.validation.unique");
        }
        return true;
      },
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      user: (state) => state.User,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      const list = [];
      let gov = undefined;
      this.directorates.forEach((element) => {
        gov = this.governorates.find((g) => g.id == element.fk_governorate);
        if (gov != undefined) {
          let country = this.countries.find((cun) => cun.id == gov.fk_country);
          list.push({
            governorate: gov.name_ar,
            fk_governorate: gov.id,
            country: country.name_ar,
            fk_country: country.id,
            name_ar: element.name_ar,
            name_en: element.name_en,
            note: element.note,
            id: element.id,
          });
        }
      });

      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.getUserRole >= 2) {
        this.headers.pop();
      }
      if (!this.countries.length) await this.getCountries();
      if (!this.governorates.length) await this.getGovernorates({});
      this.getDirectorate({ all: true });

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    resetForm() {
      this.$refs.form.reset();
      // this.v$.directorate.$reset()
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(data) {
      // for (const key in data) this.directorate[key] = data[key];
      this.directorate = { ...data };
      this.is_update = true;
      this.updated_id = data.id;
      // let country = this.countries.find((con) => con.name_ar == data.country);
      // this.directorate.country = country.id;
      // let governorate = this.governorates.find(
      // (gov) => gov.name_ar == data.governorate
      // );
      // this.directorate.fk_governorate = governorate.id;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    deleteItem(data) {
      this.delete_id = data.id;
      this.del_dialog = true;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async saveDirectorate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.directorate.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/directorate/", this.directorate, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));

            this.resetForm();
            this.getDirectorate({ all: true });
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
        // this.process_alert=true
        // setTimeout(()=>{
        //     this.process_alert=false
        // },2000)
      }
    },
    async updateDirectorate() {
      // this.v$.$validate()
      const { valid } = await this.$refs.form.validate();
      if (valid && this.directorate.id != undefined) {
        this.is_loading = true;
        // this.directorate.updated_by=this.user
        let result = await this.axios
          .put(
            `${this.base_url}/directorate/${this.directorate.id}/`,
            this.directorate,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            this.getDirectorate({ all: true });
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
        // this.process_alert=true
        // setTimeout(()=>{
        //     this.process_alert=false
        // },2000)
      }
    },
    async deleteDirectorate() {
      if (this.delete_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}/directorate/${this.delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.resetForm();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.del_dialog = false;
        this.delete_id = undefined;
        this.getDirectorate({ all: true });
        // this.process_alert=true
        // setTimeout(()=>{
        //     this.process_alert=false
        // },2000)
      }
    },
  },
};
</script>
<style>
.v-application__wrap {
  background-color: #999;
}
</style>
