<template>
  <!-- ##########    data Table  ########### -->
  <!-- <v-divider class="my-4" /> -->
  <!-- ######## Header Of Data Table  ####### -->
  <v-expansion-panels class="mb-2">
    <v-expansion-panel elevation="1">
      <v-expansion-panel-title class="px-4">
        <span>
          <!-- <v-icon>mdi-filter</v-icon>  -->
          {{ $t("global.view_filters") }}</span
        >
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pt-4">
        <!-- <v-fade-transition leave-absolute> -->
        <VForm ref="form">
          <VRow class="">
            <VCol
              v-if="2 > getUserRole && getUserRole >= 0"
              class="pt-0 px-3"
              cols="12"
              xs="12"
              sm="12"
              md="4"
              lg="4"
            >
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="branch"
                  v-model="fk_branch"
                  prepend-inner-icon="mdi-office-building"
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('school.student.branch')"
                  persistent-hint
                  density="compact"
                  clearable
                  @update:model-value="(fk_class = null), (fk_division = null)"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="classes"
                  v-model="fk_class"
                  prepend-inner-icon="mdi-google-classroom"
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('school.class.name')"
                  persistent-hint
                  density="compact"
                  clearable
                  @update:modelValue="fk_division = null"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="fk_class ? getClassDivisions(fk_class) : []"
                  v-model="fk_division"
                  prepend-inner-icon="mdi-format-list-bulleted"
                  item-title="name"
                  item-value="id"
                  :label="$t('school.division.name')"
                  :no-data-text="$t('school.division.not_found')"
                  persistent-hint
                  density="compact"
                  clearable
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="study_mode"
                  v-model="student_mode"
                  prepend-inner-icon="mdi-text-box-outline"
                  item-title="name"
                  item-value="id"
                  :label="$t(`school.student.study_mode`)"
                  persistent-hint
                  density="compact"
                  clearable
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol class="mt-2" cols="auto">
              <VBtn
                class="bg-blue"
                @click="getStudentList()"
                size="small"
                :loading="loading"
              >
                <span class="text-white">{{ $t("global.filter") }}</span>
                <VIcon icon="mdi-filter" color="white" end></VIcon>
              </VBtn>
              <!-- <VBtn class="bg-indigo" size="small">
                    <span class="text-white">
                      {{ $t("globals.show") }}
                    </span>
                    <VIcon icon="mdi-presentation" color="white" end></VIcon>
                  </VBtn> -->
            </VCol>
          </VRow>
        </VForm>
        <!-- </v-fade-transition> -->
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card class="card-table">
    <div>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" color="#ccc">
            <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
            <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header.key"
              :label="header.title"
              :value="header.key"
              color="indigo"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <!-- <v-btn v-bind="props" variant="text" size="small">
          <v-icon>mdi-refresh</v-icon>
        </v-btn> -->
      <v-text-field
        color="indigo"
        v-model="txt_search"
        @input="!txt_search ? getStudentList((page = 1)) : ''"
        @keyup.enter="getStudentList((page = 1))"
        class="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :placeholder="$t('global.data_table.search_here')"
        density="compact"
        variant="text"
      >
      </v-text-field>
      <div
        v-if="CheckGetPermission('school.add_student')"
        class="d-flex justify-end"
        style="flex: 1"
      >
        <VBtn class="bg-success" @click="addStudent">
          <span class="text-white">{{ $t("global.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <!-- ######## End Header Of Data Table Server ####### -->
    <!-- <v-progress-linear
      color="indigo"
      class="my-1"
      :indeterminate="loading"
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear> -->
    <v-data-table-server
      :headers="selectedHeaders"
      :items="student_list"
      :items-per-page="perPage"
      item-value="name"
      item-key="id"
      fixed-footer
      fixed-header
      density="compact"
      class="elevation-1"
      :loading="loading"
      v-model:sort-by="sortBy"
      @update:options="getStudentList"
    >
      <template v-slot:no-data class="text-center">
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <template v-slot:no-results>
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <!-- @update:options="getStudentList((page = 1))" -->
      <template v-slot:column.checkbox="{ column }">
        <v-checkbox hide-details density="compact"> </v-checkbox>
      </template>
      <template v-slot:item="{ item }">
        <tr class="row-hover">
          <td v-for="(val, key) in item.columns" :key="key">
            <!-- <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
            </v-checkbox> -->
            <span v-if="key === 'actions'">
              <span
                v-if="CheckGetPermission('school.change_student')"
                class="me-1"
                style="color: #e5b254; border-radius: 4px"
              >
                <v-icon
                  @click="editStudent(item.raw.id)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-pencil-outline"
                />
              </span>
              <span
                v-if="CheckGetPermission('school.delete_student')"
                style="color: #d05251; border-radius: 4px"
              >
                <v-icon
                  @click="(student_del_id = item.raw.id), (del_dialog = true)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-trash-can-outline"
                />
              </span>
            </span>
            <span v-else-if="key == 'student_image'">
              <button @click="showStudentImage(val)">
                <VAvatar
                  :image="
                    val != null
                      ? val.search(base_url) != -1
                        ? val
                        : base_url + val
                      : false
                  "
                  :icon="val == null ? 'mdi-image-off-outline' : ''"
                  class=""
                  size="small"
                ></VAvatar>
              </button>
            </span>
            <span v-else-if="key == 'registration_form'">
              <button @click="showRegestriationImage(val)">
                <VAvatar
                  :image="
                    val != null
                      ? val.search(base_url) != -1
                        ? val
                        : base_url + val
                      : false
                  "
                  :icon="val == null ? 'mdi-image-off-outline' : ''"
                  class=""
                  size="small"
                ></VAvatar>
              </button>
            </span>
            <!-- <span v-else-if="key == 'study_mode'">
             <v-icon v-if="val == 1" color="#008080">mdi-text-box-check-outline</v-icon>
             <v-icon v-if="val == 2" color="#800000">mdi-text-box-remove-outline</v-icon>
            </span> -->
            <span v-else> {{ val }}</span>
          </td>
        </tr>
      </template>
      <template v-slot:bottom>
        <pagination
          v-model:page="page"
          v-model:perPage="perPage"
          :length="totalItems"
          :newItem="[perPage]"
        ></pagination>
        <!-- <div class="d-flex">
                        <v-pagination  v-model="page" :length="length"></v-pagination>
                        <div class="d-flex align-center">
                            <span>{{$t('globals.per-page')}} </span>
                            <v-select v-model="perPage" :items="itemsPerPage"  item-value="value" item-title="text" variant="solo"></v-select>
                        </div>
                    </div> -->
        <!-- <div class="d-flex" id="pagination-bar">
          <v-pagination
            v-model="page"
            :length="length"
            density="compact"
            show-first-last-page
          >
          </v-pagination>
          <div class="d-flex">
            <v-select
              v-model="perPage"
              class="pa-0"
              :items="itemsPerPage"
              item-value="value"
              item-title="text"
              density="compact"
              hide-details
              variant="text"
            >
            </v-select>
            <span class="mt-2 px-2"
              >{{ $t("global.data_table.per_page") }} {{ $t("global.from") }} [
              {{ totalItems }} ] {{ $t("global.data_table.item") }}</span
            >
          </div>
        </div> -->
      </template>
    </v-data-table-server>
  </v-card>
  <!-- ############### Dialog Delete  ################### -->
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="info"
          @click="(del_dialog = false), (student_del_id = undefined)"
          >{{ $t("global.cancel") }}</v-btn
        >
        <v-btn color="red" @click="deleteStudent">{{
          $t("global.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </VCard> -->
  <VDialog v-model="dialog" max-width="500px" max-height="500px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <!-- <VImg :src="dialog_image" :width="400"></VImg> -->
      <VCardItem>
        <img :src="dialog_image" class="mx-auto w-75" />
      </VCardItem>
      <VCardActions>
        <VBtn class="bg-info" @click="dialog = false">{{
          $t("global.ok")
        }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      //   v$: useValidate(),
      student_list: [],
      fk_branch: null,
      fk_class: undefined,
      fk_division: undefined,
      student_mode: null,
      del_dialog: false,
      student_del_id: undefined,
      //   process_alert: undefined,
      //   icon_color: undefined,
      //   icon: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      dialog: false,
      dialog_image: undefined,
      dialog_title: undefined,
      dialog_text: undefined,
      perPage: 10,
      length: 0,
      page: 1,
      pagination: {},
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      totalItems: null,
      sortBy: [{ key: "id", order: "asc" }],
      loading: false,
      headers: [
        {
          title: this.$t("school.parent.supervisor"),
          key: "fk_parent",
        },
        {
          title: this.$t("school.parent.parent_phone_number"),
          key: "parent_phone",
        },
        { title: this.$t("global.username"), key: "fk_user" },
        { title: this.$t("school.student.student_ar_name"), key: "name_ar" },
        { title: this.$t("school.student.student_en_name"), key: "name_en" },
        { title: this.$t("global.personal_information.gender"), key: "gender" },
        { title: this.$t("school.class.name"), key: "fk_class" },
        { title: this.$t("school.division.name"), key: "fk_division" },
        {
          title: this.$t("school.student.study_mode"),
          key: "study_mode",
          align: "center",
        },
        {
          title: this.$t("global.personal_information.country"),
          key: "fk_country",
        },
        {
          title: this.$t("global.personal_information.governorate"),
          key: "fk_governorate",
        },
        {
          title: this.$t("global.personal_information.nationality"),
          key: "nationality",
        },
        {
          title: this.$t("global.personal_information.birth_date"),
          key: "birthdate",
        },
        {
          title: this.$t("global.personal_information.birth_place"),
          key: "birth_place",
        },
        {
          title: this.$t("school.student.parent_relation"),
          key: "parent_relation",
        },
        {
          title: this.$t("global.personal_information.address"),
          key: "address",
        },
        {
          title: this.$t("global.personal_information.phone_number"),
          key: "phone_number",
        },
        {
          title: this.$t("global.personal_information.home_number"),
          key: "home_number",
        },
        {
          title: this.$t("school.student.student_image"),
          key: "student_image",
          sortable: false,
        },
        {
          title: this.$t("school.student.registration_form"),
          key: "registration_form",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "name_ar",
        "gender",
        "nationality",
        "branch_name",
        "birthdate",
        "study_mode",
        "student_image",
        "birth_place",
        "address",
        "actions",
      ],
      txt_search: undefined,
      loading: false,
    };
  },
  // validations() {
  //   return {
  //     fk_branch: {
  //       required: helpers.withMessage(
  //         this.$t("global.error.validation.required_field"),
  //         required
  //       ),
  //     },
  //   };
  // },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.getUserRole < 2) {
        this.headers.splice(7, 0, {
          title: this.$t("school.student.branch"),
          key: "branch_name",
        });
      }
      if (!this.branch.length) await this.getBrenches({});
      if (!this.classes.length) await this.getClasses();
      await this.getClassAssignHall();

      if (!this.divisions.length) await this.getDivisions({});
      if (!this.study_mode.length) await this.getStudyMode();
      //   await this.getBrenches();
      //   await this.getClasses();
      //   await this.getAcademicYears();
      //   await this.getGender();
      //   await this.getIdentifyType();
      //   await this.getNationalities();
      await this.getStudentList();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
      divisions: (state) => state.school.divisions,
      classes: (state) => state.school.classes,
      study_mode: (state) => state.globals.study_mode,
      //   academic_years: (state) => state.school.academic_years,
      //   gender: (state) => state.globals.gender,
      //   identify_type: (state) => state.globals.identify_type,
      //   nationalities: (state) => state.school.nationalities,
      //   user: (state) => state.User,
    }),
    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
      getDivisions: "school/getDivisions",
      getClasses: "school/getClasses",
      getAcademicYears: "school/getAcademicYears",
      getGender: "globals/getGender",
      getIdentifyType: "globals/getIdentifyType",
      getNationalities: "school/getNationalities",
      getStudyMode: "globals/getStudyMode",
      getClassAssignHall: "school/getClassAssignHall",
    }),
    addStudent() {
      this.$router.push("add");
    },
    editStudent(id) {
      this.$router.push(`${id}/edit/`);
    },
    showStudentImage(value) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1)
        this.dialog_license = value;
      else if (value?.search(this.base_url) == -1)
        this.dialog_image = this.base_url + value;
      else this.dialog_image = "";
      this.dialog_title = this.$t("school.student.student_image");
      this.dialog = true;
    },
    showRegestriationImage(value) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1)
        this.dialog_Coordinates = value;
      else if (value?.search(this.base_url) == -1)
        this.dialog_image = this.base_url + value;
      else this.dialog_image = "";
      this.dialog_title = this.$t("school.student.registration_form");
      this.dialog = true;
    },
    async deleteStudent() {
      if (this.student_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}student/${this.student_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
            // else if (error.response.data.includes("student has marks")) {
            //   this.$emit(
            //     "errorAlert",
            //     this.$t("global.error.failure.has_marks")
            //   );
            // }
          });
        this.del_dialog = false;
        this.student_del_id = undefined;
        this.getStudentList();
      }
    },
    async getStudentList() {
      // const { valid } = await this.$refs.form.validate();
      // if (valid) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}student/`, {
          params: {
            branch: this.fk_branch,
            class: this.fk_class,
            division: this.fk_division,
            study_mode: this.student_mode,
            search: this.txt_search,
            page: this.page,
            page_size: this.perPage,
            sort_by: this.order_data,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.student_list = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
      //   } else {
      //     this.student_list = [];
      //   }
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getStudentList();
    },
    page() {
      this.getStudentList(this.page);
    },
  },
};
</script>
<style scoped></style>
