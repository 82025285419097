<template>
  <v-row class="mt-4 mb-2 text-grey-darken-2 " justify="space-around" align="center">
    <v-col cols="3" class="text-center " >
      <slot name="right" >
        <h2>{{$t('globals.rep-of-yemen-ar')}}</h2>
        <h4 v-if="school.length > 0">
          {{$t('globals.ministry-ar')}}
        </h4>
        <h5 v-if="school.length > 0">{{ $i18n.locale=='ar'?school[0].arabic_name:school[0].english_name }}</h5>
      </slot>
    </v-col>
    <v-col cols="3" class="mx-2 text-center ">
      <slot name="center">
        <h4>بسم الله الرحمن الرحيم</h4>
        <v-responsive v-if="school.length > 0" class="mt-2">
          <v-avatar size="80">
            <v-img v-if="school[0].logo==null" src='../../assets/opensoft.png'></v-img>
            <v-img v-if="school[0].logo!==null" :src="school[0].logo"></v-img>
          </v-avatar>
        </v-responsive>
      </slot>
    </v-col>
    <v-col cols="3" class="mx-2 text-center ">
      <slot name="left">
        <h2>{{$t('globals.rep-of-yemen-en')}}</h2>
        <h4>{{$t('globals.ministry-en')}}</h4>
        <h5 v-if="school.length > 0">{{ $i18n.locale=='ar'?school[0].english_name:school[0].arabic_name }}</h5>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      school: [],
      branch: {},
    };
  },
  async created() {
    await this.axios
      .get(`${this.base_url}school-data/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((response) => {
        this.school = response.data;
      });
  },
};
</script>
