<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <v-card-title class="pt-0">
      <span class="text-grey-darken-2">
        {{ $t("school.reports.select_criteria") }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-card-text
        class="d-flex flex-wrap align-center"
        style="row-gap: 1.5rem"
      >
        <!-- <v-row>-->
        <v-col v-if="getUserRole < 2" cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_branch"
            density="compact"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-office-building"
            :items="branch"
            :label="$t('school.reports.select_branch')"
            @update:model-value="(filter_data.fk_parent = null), getParents()"
            :rules="[$required]"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_parent"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-human-male-boy"
            :items="parents"
            :label="$t('school.reports.select_parent_name')"
            hide-details="auto"
            :rules="[$required]"
            return-object
          />
        </v-col>
        <!-- <v-col cols="6" md="3" sm="12" >
        <v-autocomplete
          ref="form"
          color="indigo"
          v-model="filter_data.fk_parent"
          density="compact"
          item-title="name"
          item-value="id"
          prepend-inner-icon="mdi-human-male-child"
          :items="parents"
          :label="$t('school.reports.select_parent_name')"
          hide-details="auto"
          :rules="[$required]"
          return-object
        />
      </v-col> -->
        <v-col cols="6" sm="2" class="d-flex align-center">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            color="indigo"
            @click="checkFilterData"
            :loading="loading"
          >
            <span class="px-1">{{ $t("school.reports.show") }}</span>
          </v-btn>
        </v-col>
        <!-- </v-row> -->
      </v-card-text>
    </v-form>
  </v-card>
  <v-card class="justify-center" v-if="exist">
    <!-- <v-row class="mt-4 text-grey-darken-2">
        <v-col cols="4" class="text-center">
            <h2>{{ $t('globals.rep-of-yemen-ar') }}</h2>
            <h3 v-if="school.length > 0">{{$i18n.locale=='ar' ? school[0].ministry_name ? school[0].ministry_name : '' :$t('globals.ministry-ar')}}</h3>
            <h5 v-if="school.length > 0">{{ $i18n.locale=='ar' ? school[0].arabic_name : school[0].english_name }}</h5>
        </v-col>
        <v-col cols="4" class="text-center mb-2">
            <h4>بسم الله الرحمن الرحيم</h4>
            <v-responsive v-if="school.length > 0">
                <v-avatar size="80">
                    <v-img :src="school[0].logo"></v-img>
                </v-avatar>
            </v-responsive>
        </v-col>
        <v-col cols="4" class="text-center">
            <h3>{{ $t('year.name') }}: {{ getYears() }}</h3>
            <h4>{{ $t('report.parent_remaining') }}: {{ getParentName(filter_data.fk_parent) }}</h4>
        </v-col>
    </v-row>    -->

    <report-header :data="filter_data"></report-header>

    <!-- <h2 class="text-center text-grey-darken-2">
      {{ $t("report.student") }} {{ getYears() }}
    </h2> -->

    <v-card-text class="mb-4">
      <filter-menu
        :headers="headers"
        v-model="selectedHead"
        @toggle-visibility="() => (exist = !exist)"
      ></filter-menu>
      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("school.reports.not_found") }}
          </h3>
        </v-card>
        <v-card :loading="loading" v-if="items.length > 0">
          <table
            class="text-center"
            style="width: 100%; border-collapse: collapse"
          >
            <thead>
              <th v-for="header in selectedHeaders" :key="header.key">
                {{ header.title }}
              </th>
            </thead>

            <tbody v-if="!loading">
              <template v-for="(item, index) in items" :key="item.id">
                <tr v-for="(sub, subIndex) in item?.fees" :key="sub">
                  <template v-if="subIndex === 0">
                    <td
                      v-for="head in selectedHeaders?.slice(
                        0,
                        -(selectedHeaders?.length - 3)
                      )"
                      :key="head.key"
                      :rowspan="item?.fees?.length"
                      class="text-center border"
                    >
                      <template v-if="head.key == 'no'">
                        {{ (page - 1) * perPage + index + 1 }}
                      </template>
                      <template v-else>
                        {{ item[head.key] ? item[head.key] : "-" }}
                      </template>
                    </td>
                  </template>
                  <td
                    v-for="header in selectedHeaders?.slice(3)"
                    :key="header.key"
                    class="text-center border"
                  >
                    <template
                      v-if="header.key == 'total' || header.key == 'debit'"
                    >
                      {{
                        (sub.still + sub.paid_amount)
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </template>
                    <template v-else-if="header.key == 'credit'">
                      {{
                        sub["paid_amount"] != null
                          ? sub["paid_amount"]
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "-"
                      }}
                    </template>
                    <template v-else>
                      {{
                        sub[header.key] != null
                          ? sub[header.key]
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "-"
                      }}
                    </template>
                  </td>
                </tr>
                <tr></tr>
              </template>
              <!-- <template v-for="(item, i) in items" :key="i">
                <tr v-for="(fee, index) in item.fees" :key="index">
                  <td
                    class="text-center border"
                    v-if="selectedHead.includes('no') && index === 0"
                    :rowspan="item.fees.length"
                  >
                    {{ i + 1 }}
                  </td>
                  <td
                    class="px-2 border"
                    v-if="selectedHead.includes('student_name') && index === 0"
                    :rowspan="item.fees.length"
                  >
                    {{ item.student_name }}
                  </td>
                  <td
                    class="px-2 border"
                    v-if="selectedHead.includes('class_name') && index === 0"
                    :rowspan="item.fees.length"
                  >
                    {{ item.class_name }}
                  </td>
                  <td
                    class="px-2 border"
                    v-if="selectedHead.includes('fees_type_name')"
                  >
                    {{ fee.fees_type_name }}
                  </td>
                  <td class="px-2 border" v-if="selectedHead.includes('total')">
                    {{ (fee.still + fee.paid_amount).toLocaleString("en-US") }}
                  </td>
                  <td
                    class="px-2 border"
                    v-if="selectedHead.includes('paid_amount')"
                  >
                    {{ fee.paid_amount.toLocaleString("en-US") }}
                  </td>
                  <td class="px-2 border" v-if="selectedHead.includes('still')">
                    {{ fee.still.toLocaleString("en-US") }}
                  </td>
                  <td class="px-2 border" v-if="selectedHead.includes('debit')">
                    {{ (fee.still + fee.paid_amount).toLocaleString("en-US") }}
                  </td>
                  <td
                    class="px-2 border"
                    v-if="selectedHead.includes('credit')"
                  >
                    {{ fee.paid_amount.toLocaleString("en-US") }}
                  </td>
                </tr>
              </template> -->
              <tr>
                <th
                  :colspan="colSpan()"
                  class="px-2 text-center"
                  v-if="colSpan()"
                >
                  {{ $t("school.reports.total") }}
                </th>
                <td class="px-2 border" v-if="selectedHead.includes('total')">
                  {{ getTotalDebit().toLocaleString("en-US") }}
                </td>
                <td
                  class="px-2 border"
                  v-if="selectedHead.includes('paid_amount')"
                >
                  {{ getTotalCredit().toLocaleString("en-US") }}
                </td>
                <td class="px-2 border" v-if="selectedHead.includes('still')">
                  {{ getTotalRemain().toLocaleString("en-US") }}
                </td>
                <td class="px-2 border" v-if="selectedHead.includes('debit')">
                  {{ getTotalDebit().toLocaleString("en-US") }}
                </td>
                <td class="px-2 border" v-if="selectedHead.includes('credit')">
                  {{ getTotalCredit().toLocaleString("en-US") }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="totalItems"
          ></pagination> -->
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <!-- <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert> -->
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
// import useValidate from "@vuelidate/core";
// import { required, helpers } from "@vuelidate/validators";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      // await this.getData()
      // await this.getYears()
      // await this.getClasses()
      if (this.getUserRole > 1) {
        await this.getParents();
      }
      await this.getBrenches({});
      // await this.getDivisions()
      // await this.getSemesters()
      // await this.getSubjects()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      parents: [],
      //   process_alert: false,
      //   icon_color: undefined,
      //   alert_title: undefined,
      //   alert_message: undefined,
      //   v$: useValidate(),
      exist: false,
      items: [],
      school: [],
      loading: false,
      txt_search: "",
      selectedHead: [
        "no",
        "student_name",
        "class_name",
        "total",
        "fees_type_name",
        "paid_amount",
        "still",
        "debit",
        "credit",
      ],
      headers: [
        { title: "#", key: "no" },
        { title: this.$t("school.reports.student_name"), key: "student_name" },
        { title: this.$t("school.reports.class"), key: "class_name" },
        { title: this.$t("school.reports.fee_type"), key: "fees_type_name" },
        { title: this.$t("school.reports.total_fee"), key: "total" },
        {
          title: this.$t("school.reports.installment_paid"),
          key: "paid_amount",
        },
        { title: this.$t("school.reports.installment_remain"), key: "still" },
        { title: this.$t("school.reports.debit"), key: "debit" },
        { title: this.$t("school.reports.credit"), key: "credit" },
      ],
      perPage: 25,
      // length: 0,
      // year_data: null,
      // totalItems: 0,
      // itemsPerPage: [
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 75, text: "75" },
      //   { value: 100, text: "100" },
      //   { value: 1000000, text: this.$t("school.reports.all") },
      // ],
      page: 1,
      // pagination: {},
      filter_data: {
        fk_parent: null,
        // fk_year: null,
      },
    };
  },
  computed: {
    ...mapState({
      //   classes: (state) => state.school.classes,
      //   class_assign_hall: (state) => state.school.class_assign_hall,
      //   divisions: (state) => state.school.divisions,
      //   semesters: (state) => state.school.semesters,
      //   subjects: (state) => state.school.subjects,
      //   user: (state) => state.User,
      branch: (state) => state.school.branch,
    }),

    ...mapGetters({
      //   getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
      //   getClasses: "school/getClasses",
      //   getAcademicYears: "school/getAcademicYears",
      //   getDivisions: "school/getDivisions",
      //   getSemesters: "school/getSemesters",
      //   getClassAssignHall: "school/getClassAssignHall",
      //   getSubjects: "school/getSubjects",
    }),
    getParentName(id) {
      let parent = this.parents.find((item) => item.id == id);
      return parent == undefined ? parent : parent.name;
    },
    getTotalDebit() {
      let total = 0;
      this.items.forEach((element) => {
        if (element.fees != undefined) {
          element.fees.forEach((fee_element) => {
            total += fee_element.paid_amount;
            total += fee_element.still;
          });
        }
      });
      return total;
    },
    getTotalCredit() {
      let total = 0;
      this.items.forEach((element) => {
        if (element.fees != undefined) {
          element.fees.forEach((fee_element) => {
            total += fee_element.paid_amount;
          });
        }
      });
      return total;
    },
    getTotalRemain() {
      let total = 0;
      this.items.forEach((element) => {
        if (element.fees != undefined) {
          element.fees.forEach((fee_element) => {
            total += fee_element.still;
          });
        }
      });
      return total;
    },
    colSpan() {
      const wordsToCheck = [
        "fees_type_name",
        "no",
        "student_name",
        "class_name",
      ];
      const matchWords = wordsToCheck.filter((word) =>
        this.selectedHead.includes(word)
      );

      return matchWords?.length ?? null;
    },
    async getParents() {
      await this.axios
        .get(`${this.base_url}parent/list/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            // page_size: 1000,
            // page: 1,
            branch: this.filter_data?.fk_branch?.id,
          },
        })
        .then((response) => {
          this.parents = response.data;
        });
    },
    // getYears() {
    //   this.year_data = localStorage.getItem("current_year_name_h");
    //   this.filter_data.fk_year = localStorage.getItem("current_year");
    //   return this.year_data;
    // },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    async getData(page = 1) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}school/report/student/fee_still`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: this.perPage,
            // this.filter_data.fk_parent
            fk_parent: this.filter_data.fk_parent.id,
            // this.filter_data.fk_year
            // fk_year: this.filter_data.fk_year,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          //   this.icon = "cancel";
          //   this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            // this.alert_title = this.$t("alert.failure.title");
            // this.alert_message = this.$t("alert.failure.connect");
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          } else {
            // this.alert_title = this.$t("alert.not-found.title");
            // this.alert_message = this.$t("alert.not-found.message");
            this.$emit("warningAlert", this.$t("school.reports.not_found"));
          }
          //   this.process_alert = true;
          //   setTimeout(() => {
          //     this.process_alert = false;
          //   }, 2000);
        });
      this.loading = false;
    },
  },
  //   validations() {
  //     return {
  //       filter_data: {
  //         fk_parent: {
  //           required: helpers.withMessage(this.$t("errors.required"), required),
  //         },
  //       },
  //     };
  //   },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 13px;
  padding-bottom: 2px;
  background-color: rgb(7, 71, 166, 55%);
  color: #fff;
  height: 25px !important;
}
</style>
