<template>
  <VCard class="justify-center">
    <!-- <VCard-title class="my-4">
                <VIcon icon="mdi-flag"/>
                <h2 class="d-inline text-grey" style="padding:0 4px;" >{{$t('school.country.add_country')}}</h2>
                <v-divider style="border-width:1px; opacity: 0.5;" class="my-2"/>
            </VCard-title> -->
    <v-expand-transition v-if="getUserRole < 2">
      <div v-show="(update && getUserRole < 2) || getUserRole < 2">
        <VCard-text class="card-fields">
          <VForm
            ref="form"
            v-if="
              CheckGetPermission('globaltable.add_country') ||
              (is_update && CheckGetPermission('globaltable.change_country'))
            "
          >
            <VRow>
              <VCol xs="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="country.name_ar"
                    prepend-inner-icon="mdi-flag"
                    :placeholder="
                      $t('school.country.placeholder_country_name_ar')
                    "
                    density="compact"
                    :rules="[
                      $required,
                      $max_length(50),
                      $min_length(2),
                      $ar_letters_only,
                      uniqueCountry,
                    ]"
                  >
                    <template v-slot:label>
                      {{ $t("school.country.name_ar") }}
                      <v-icon icon="mdi-abjad-arabic"></v-icon>
                    </template>
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol xs="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="country.name_en"
                    prepend-inner-icon="mdi-flag"
                    :placeholder="
                      $t('school.country.placeholder_country_name_en')
                    "
                    density="compact"
                    :rules="[
                      $required,
                      $max_length(50),
                      $min_length(2),
                      $en_letters_only,
                      uniqueCountry,
                    ]"
                  >
                    <template v-slot:label>
                      {{ $t("school.country.name_en") }}
                      <v-icon icon="mdi-alpha-e"></v-icon>
                    </template>
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <VCol class="pt-0 px-3" xs="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="country.nati_name_ar"
                    prepend-inner-icon="mdi-passport"
                    :placeholder="
                      $t('school.country.placeholder_nationality_name_ar')
                    "
                    density="compact"
                    :rules="[
                      $required,
                      $max_length(50),
                      $min_length(3),
                      $ar_letters_only,
                      uniqueNationality,
                    ]"
                  >
                    <template v-slot:label>
                      {{ $t("school.country.nationality_name_ar") }}
                      <v-icon icon="mdi-abjad-arabic"></v-icon>
                    </template>
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" xs="12" md="4" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="country.nati_name_en"
                    prepend-inner-icon="mdi-passport"
                    :placeholder="
                      $t('school.country.placeholder_nationality_name_en')
                    "
                    density="compact"
                    :rules="[
                      $required,
                      $max_length(50),
                      $min_length(3),
                      $en_letters_only,
                      uniqueNationality,
                    ]"
                  >
                    <template v-slot:label>
                      {{ $t("school.country.nationality_name_en") }}
                      <v-icon icon="mdi-alpha-e"></v-icon>
                    </template>
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" class="pa-0 ma-0" xs="12" md="8" lg="8">
                <VCardItem>
                  <VTextarea
                    v-model="country.note"
                    clearable
                    :label="$t('global.note')"
                    prepend-inner-icon="mdi-note-outline"
                    no-resize
                    counter="250"
                    density="compact"
                    :placeholder="$t('global.notes_placeholder')"
                    rows="3"
                    :rules="[$max_length(250)]"
                  ></VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
          </VForm>
        </VCard-text>
        <VCardActions
          class="px-8"
          v-if="
            CheckGetPermission('globaltable.add_country') ||
            (is_update && CheckGetPermission('globaltable.change_country'))
          "
        >
          <VBtn
            v-if="!is_update && CheckGetPermission('globaltable.add_country')"
            class="bg-indigo"
            :loading="is_loading"
            @click="saveCountry()"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn
            v-if="is_update && CheckGetPermission('globaltable.change_country')"
            class="bg-success"
            :loading="is_loading"
            @click="updateCountry()"
            size="small"
          >
            <span class="text-white">
              {{ $t("global.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm">
            {{ !is_update ? $t("global.clear") : $t("global.cancel") }}
            <VIcon
              :icon="!is_update ? 'mdi-broom' : 'mdi-cancel'"
              :color="!is_update ? 'golden' : 'gray'"
              end
            ></VIcon>
          </VBtn>
        </VCardActions>
        <v-divider class="mt-5" />
      </div>
    </v-expand-transition>
    <!-- ##########    data Table  ########### -->
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div >
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="outlined"
              color="#ccc"
            >
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>

        <div style="width: 100%">
          <v-text-field
            color="indigo"
            v-model="txt_search"
            class="search"
            hide-details
            @input="!txt_search ? (search = null) : ''"
            @keypress.enter="search = txt_search"
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('global.data_table.search_here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        v-model:page="page"
        :items-per-page="perPage"
        :headers="selectedHeaders"
        :items="tableList"
        fixed-footer
        :search="search"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:no-data class="text-center">
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <template v-slot:no-results>
          <td :colspan="selectedHeaders.length" class="text-center">
            <h3>{{ $t("global.error.failure.no_data") }}</h3>
          </td>
        </template>
        <!-- <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template> -->
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox
                v-if="key == 'checkbox'"
                hide-details
                density="compact"
              >
              </v-checkbox>
              <span> {{ val }} </span>
              <span v-if="key === 'actions'">
                <span
                  v-if="CheckGetPermission('globaltable.change_country')"
                  class="me-1"
                  style="color: #e5b254; border-radius: 4px"
                >
                  <v-icon
                    @click="editItem(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-pencil-outline"
                  />
                </span>
                <span
                  v-if="CheckGetPermission('globaltable.delete_country')"
                  style="color: #d05251; border-radius: 4px"
                >
                  <v-icon
                    @click="deleteItem(item.raw)"
                    size="small"
                    style="cursor: pointer"
                    icon="mdi-trash-can-outline"
                  />
                </span>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
          <!-- <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="Math.ceil(tableList.length / perPage)"
              density="compact"
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("global.data_table.per_page") }}
                {{ $t("global.from") }} [ {{ tableList.length }} ]
                {{ $t("global.data_table.item") }}</span
              >
            </div>
          </div> -->
        </template>
      </v-data-table>
    </v-card>
  </VCard>

  <!-- ############### Dialog Delete  ################### -->
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="cancelDeleteItem">{{
          $t("global.cancel")
        }}</v-btn>
        <v-btn color="red" @click="deleteCountry">{{
          $t("global.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- 
  <VDialog v-model="del_dialog" scrollable :overlay="false" max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("global.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("global.alert.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteCountry"
        >
          {{ $t("global.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("global.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog> -->
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      country: {
        name_ar: undefined,
        name_en: undefined,
        nati_name_en: undefined,
        nati_name_ar: undefined,
        note: undefined,
        data_entry: undefined,
      },
      is_update: false,
      is_loading: false,
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,
      headers: [
        { title: this.$t("school.country.name_ar"), key: "name_ar" },
        { title: this.$t("school.country.name_en"), key: "name_en" },
        {
          title: this.$t("school.country.nationality_name_ar"),
          key: "nati_name_ar",
        },
        {
          title: this.$t("school.country.nationality_name_en"),
          key: "nati_name_en",
        },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "name_ar",
        "name_en",
        "nati_name_ar",
        "nati_name_en",
        "note",
        "actions",
      ],
      uniqueCountry: (value) => {
        if (
          this.tableList.some(
            (item) =>
              (item.name_ar.trim() === value.trim() ||
                item.name_en.trim().toLowerCase() ===
                  value.trim().toLowerCase()) &&
              item.id !== this.country.id
          )
        ) {
          return this.$t("global.error.validation.unique");
        }
        return true;
      },
      uniqueNationality: (value) => {
        if (
          !this.is_update &&
          this.tableList.some(
            (item) =>
              item.nati_name_ar.trim() === value.trim() ||
              item.nati_name_en.trim().toLowerCase() ===
                value.trim().toLowerCase()
          )
        ) {
          return this.$t("global.error.validation.unique");
        }
        return true;
      },
      txt_search: null,
      search: null,
      perPage: 10,
            // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      page: 1,
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      //   user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      const list = [];
      this.countries.forEach((element) => {
        list.push({
          name_ar: element.name_ar,
          name_en: element.name_en,
          nati_name_ar: element.nati_name_ar,
          nati_name_en: element.nati_name_en,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    getUserRole() {
      return localStorage.getItem("role");
    },
  },

  async created() {
    // this.table=$(".table table").DataTable()
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.getUserRole >= 2) {
        this.headers.pop();
      }
      await this.getCountries(true);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      // setAccounts: "financial/setAccounts",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      // this.v$.country.$reset()
      this.updated_id = undefined;
    },
    editItem(data) {
      for (const key in data) this.country[key] = data[key];
      this.is_update = true;
      this.updated_id = data.id;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    deleteItem(data) {
      this.delete_id = data.id;
      this.del_dialog = true;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async saveCountry() {
      // this.v$.$validate()
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.is_loading = true;
        this.country.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/country/", this.country, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_added"));

            this.resetForm();
            this.getCountries(true);
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
      }
    },
    async deleteCountry() {
      if (this.delete_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}/country/${this.delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.resetForm();
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            else
              this.$emit(
                "errorAlert",
                this.$t("global.error.failure.error_in_data")
              );
          });
        this.del_dialog = false;
        this.delete_id = undefined;
        this.getCountries(true);
      }
    },
    async updateCountry() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.country.id != undefined) {
        this.is_loading = true;
        let result = await this.axios
          .put(`${this.base_url}/country/${this.country.id}/`, this.country, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_updated"));
            this.resetForm();
            this.getCountries(true);
            this.getGovernorates({});
            this.getDirectorate({});
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.is_loading = false;
      }
    },
  },
};
</script>
<style>
.v-application__wrap {
  background-color: #999;
}
</style>
