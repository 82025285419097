<template>
    <div>
      <div ref="chartContainer" style="width: 600px; height: 400px;"></div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    mounted() {
      // Initialize ECharts instance
      const chartContainer = this.$refs.chartContainer;
      const chart = echarts.init(chartContainer);
  
      // Define chart data
      const data = [
        { year: 'sat', st_1: 2, sd_2: 10, td_3: 1,fh_4: 3, fifrh_5: 6,sexth: 4, seventh: 8,eighth: 11,ninth: 3, tenth: 4,elenenth: 9, twilfth: 1, },
        { year: 'sun', st_1: 0, sd_2: 5, td_3: 2,fh_4: 10, fifrh_5: 7,sexth: 5, seventh: 6, eighth: 7,ninth: 0,tenth: 2,elenenth: 4, twilfth: 5,  },
        { year: 'mon', st_1: 2, sd_2: 2, td_3: 6 ,fh_4: 1, fifrh_5: 4,sexth: 4, seventh: 4, eighth: 5,ninth: 8, tenth: 4,elenenth: 6, twilfth: 2,  },
        { year: 'tue', st_1: 5, sd_2: 0, td_3: 2,fh_4: 5, fifrh_5: 2 ,sexth: 8, seventh: 8, eighth: 4,ninth: 6, tenth: 1,elenenth: 4, twilfth: 0, },
        { year: 'wen', st_1: 8, sd_2: 5, td_3: 5 ,fh_4: 2, fifrh_5: 0,sexth: 2, seventh: 9, eighth: 1,ninth: 7, tenth: 5,elenenth: 2, twilfth: 5,  },
        // Add more data points...
      ];
  
      // Extract series names dynamically
      const seriesNames = Object.keys(data[0]).filter(key => key !== 'year');
  
      // Configure chart options
      const options = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: seriesNames,
        },
        xAxis: {
          type: 'category',
          data: data.map(item => item.year),
        },
        yAxis: {
          type: 'value',
        },
        series: seriesNames.map(seriesName => ({
            name: seriesName,
            type: 'line',
            smooth: true,
            //   stack: 'stacked',
            // areaStyle: {
            //             opacity: 0.8,
            //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //             {
            //                 offset: 0,
            //                 color: 'rgb(0, 221, 255)'
            //             },
            //             {
            //                 offset: 1,
            //                 color: 'rgb(77, 119, 255)'
            //             }
            //             ])
            //         },
            //         emphasis: {
            //             focus: 'series'
            //         },
            data: data.map(item => item[seriesName]),
        })),
      };
  
      // Render the chart
      chart.setOption(options);
    },
  };
  </script>