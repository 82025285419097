<template>
  <!-- <v-container> -->
  <v-expansion-panels class="mb-2" :model-value="0">
    <v-expansion-panel elevation="1">
      <v-expansion-panel-title class="px-4">
        <span>
          <!-- <v-icon>mdi-filter</v-icon> -->
          {{ $t("global.view_filters") }}</span
        >
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pt-4">
        <v-form
          ref="form"
          @submitStudentHomeworkForm.prevent="saveStudentHomework"
          class="mb-4"
        >
          <VRow class="pt-0 pb-0">
            <!-- <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="branch"
                    v-model="fk_branch"
                    prepend-inner-icon="mdi-office-building"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('school.branch_data.select')"
                    persistent-hint
                    density="compact"
                    clearable
                    :rules="[$required]"
                  ></VAutocomplete>
                </VCardItem>
              </VCol> -->
            <!-- <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="3" lg="3">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="semesters"
                    v-model="fk_semester"
                    prepend-inner-icon="mdi-text"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('school.semester.select')"
                    persistent-hint
                    density="compact"
                    clearable
                    :rules="[$required]"
                  ></VAutocomplete>
                </VCardItem>
              </VCol> -->
            <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="classes"
                  v-model="fk_class"
                  prepend-inner-icon="mdi-google-classroom"
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('school.class.select')"
                  persistent-hint
                  density="compact"
                  clearable
                  :rules="[$required]"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol class="mt-3" cols="12" xs="12" sm="12" md="2" lg="2">
              <VBtn
                class="bg-blue"
                :loading="loading"
                @click="getExamSchedules()"
                size="small"
              >
                <span class="text-white">{{ $t("global.filter") }}</span>
                <VIcon icon="mdi-filter" color="white" end></VIcon>
              </VBtn>
              <!-- <VBtn class="bg-indigo" size="small">
                    <span class="text-white">
                      {{ $t("globals.show") }}
                    </span>
                    <VIcon icon="mdi-presentation" color="white" end></VIcon>
                  </VBtn> -->
            </VCol>
            <!-- <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="2" lg="2">
              <VBtn
                class="bg-indigo mt-3"
                :loading="loading"
                @click="getExamSchedules()"
                size="small"
              >
                <span class="text-white">
                  {{ $t("global.show") }}
                </span>
              </VBtn>
            </VCol> -->
          </VRow>
        </v-form>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <!-- </v-container> -->

  <!-- <VCard-title class="my-4">
                <VIcon icon="mdi-text-box-plus"/>
                <h2 class="d-inline text-grey" style="padding:0 4px;" >{{$t('school.exam_schedule')}}</h2>
                <v-divider style="border-width:1px; opacity: 0.5;" class="my-2"/>
            </VCard-title> -->

  <!-- ##########    data Table  ########### -->
  <!-- <v-divider class="my-4"/>  -->
  <!-- ######## Header Of Data Table  ####### -->
  <v-card class="card-table">
    <div>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" color="#ccc">
            <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
            <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header.key"
              :label="header.title"
              :value="header.key"
              color="indigo"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-text-field
        color="indigo"
        v-model="txt_search"
        @input="!txt_search ? (search = null) : ''"
        @keypress.enter="search = txt_search"
        class="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :placeholder="$t('global.data_table.search_here')"
        density="compact"
        variant="text"
      >
      </v-text-field>
      <div
        v-if="CheckGetPermission('school.add_examschedule')"
        class="d-flex justify-end"
        style="flex: 1"
      >
        <VBtn class="bg-success" @click="addExamSchedule">
          <span class="text-white">{{ $t("global.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <!-- ######## End Header Of Data Table Server ####### -->
    <v-data-table
      v-model:page="page"
      :items-per-page="perPage"
      :headers="selectedHeaders"
      :items="tableList"
      item-value="name"
      item-key="id"
      fixed-footer
      :search="search"
      density="compact"
      class="elevation-1"
    >
      <template v-slot:no-data class="text-center">
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <template v-slot:no-results>
        <td :colspan="selectedHeaders.length" class="text-center">
          <h3>{{ $t("global.error.failure.no_data") }}</h3>
        </td>
      </template>
      <template v-slot:column.checkbox="{ column }">
        <v-checkbox hide-details density="compact"> </v-checkbox>
      </template>
      <template v-slot:item="{ item }">
        <tr class="row-hover">
          <td v-for="(val, key) in item.columns" :key="key">
            <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
            </v-checkbox>
            <span> {{ val }}</span>
            <span v-if="key === 'actions'">
              <span
                v-if="CheckGetPermission('school.change_examschedule')"
                class="me-1"
                style="color: #e5b254; border-radius: 4px"
              >
                <v-icon
                  @click="editExamSchedule(item.raw.id)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-pencil-outline"
                />
              </span>
              <span
                v-if="CheckGetPermission('school.delete_examschedule')"
                style="color: #d05251; border-radius: 4px"
              >
                <v-icon
                  @click="(exam_del_id = item.raw.id), (del_dialog = true)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-trash-can-outline"
                />
              </span>
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:bottom>
        <pagination
            v-model:page="page"
            v-model:perPage="perPage"
            :length="tableList.length"
            :newItem="[perPage]"
          ></pagination>
        <!-- <div class="d-flex" id="pagination-bar">
          <v-pagination
            v-model="page"
            :length="Math.ceil(tableList.length / perPage)"
            density="compact"
          >
          </v-pagination>
          <div class="d-flex">
            <v-select
              v-model="perPage"
              class="pa-0"
              :items="itemsPerPage"
              item-value="value"
              item-title="text"
              density="compact"
              hide-details
              variant="text"
            >
            </v-select>
            <span class="mt-2 px-2"
              >{{ $t("global.data_table.per_page") }} {{ $t("global.from") }} [
              {{ tableList.length }} ] {{ $t("global.data_table.item") }}</span
            >
          </div>
        </div> -->
      </template>
    </v-data-table>
  </v-card>
  <!-- ############### Dialog Delete  ################### -->
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("global.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("global.alert.confirm_delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="info"
          @click="(del_dialog = false), (exam_del_id = undefined)"
          >{{ $t("global.cancel") }}</v-btn
        >
        <v-btn color="red" @click="deleteExamSchedule">{{
          $t("global.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      exam_schedules: null,
      fk_branch: undefined,
      fk_class: undefined,
      fk_semester: undefined,
      del_dialog: false,
      exam_del_id: undefined,
      loading: null,
      txt_search: null,
      search: null,
      perPage: 10,
      // itemsPerPage: [
      //   { value: 10, text: "10" },
      //   { value: 25, text: "25" },
      //   { value: 50, text: "50" },
      //   { value: 100, text: "100" },
      //   { value: 1000, text: this.$t("global.data_table.all") },
      // ],
      // ],
      page: 1,
      headers: [
        { title: this.$t("school.subject.name"), key: "fk_subject" },
        { title: this.$t("school.exams.exam_day"), key: "day" },
        {
          title: this.$t("school.exams.schedule_period"),
          key: "fk_exam_period",
        },
        { title: this.$t("school.exams.exam_date"), key: "exam_date" },
        { title: this.$t("global.note"), key: "note" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
      selectedHead: [
        "fk_subject",
        "day",
        "fk_exam_period",
        "exam_date",
        "actions",
      ],
      // selectedHead:['fk_semester','fk_class','fk_subject','day','fk_exam_period','exam_date','actions'],
      txt_search: undefined,
    };
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // await this.getExamSchedules(true)
      if (!this.branch.length) await this.getBrenches({});
      if (!this.semesters.length) await this.getSemesters();
      if (!this.classes.length) await this.getClasses();
      if (!this.exam_period.length) await this.getExamPeriods();
      if (!this.period.length) await this.getPeriod();
      if (!this.subjects.length) await this.getSubjects({});
      if (!this.day.length) await this.getDay();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
      semesters: (state) => state.school.semesters,
      classes: (state) => state.school.classes,
      subjects: (state) => state.school.subjects,
      exam_period: (state) => state.school.exam_period,
      period: (state) => state.globals.period,
      day: (state) => state.globals.day,
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      let list = [];
      if (this.exam_schedules && this.period) {
        this.exam_schedules.forEach((element) => {
          let subject = this.subjects.find(
            (item) => item.id == element.fk_subject
          );
          // let classe=this.classes.find(item=>item.id==element.fk_class)
          // let semester=this.semesters.find(item=>item.id==element.fk_semester)
          let exam_period = this.exam_period.find(
            (item) => item.id == element.fk_exam_period
          );
          let period = this.period.find(
            (item) => item.id == exam_period.period
          );
          let day = this.day.find((item) => item.id == element.day);
          try {
            list.push({
              fk_subject: subject.name_ar,
              // fk_class:classe.name_ar,
              // fk_semester:semester.name_ar,
              fk_exam_period: period.name,
              day: day.name,
              exam_date: element.exam_date,
              note: element.note,
              id: element.id,
            });
          } catch {}
        });
      }
      return list;
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
      getSemesters: "school/getSemesters",
      getClasses: "school/getClasses",
      getSubjects: "school/getSubjects",
      getDay: "globals/getDay",
      getExamPeriods: "school/getExamPeriods",
      getPeriod: "globals/getPeriod",
    }),
    addExamSchedule() {
      this.$router.push("add");
    },
    editExamSchedule(id) {
      this.$router.push(`${id}/edit/`);
    },
    async deleteExamSchedule() {
      if (this.exam_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}exam-schedule/${this.exam_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getExamSchedules();
          })
          .catch((error) => {
            this.$emit(
              "errorAlert",
              this.$t("global.error.failure.error_in_data")
            );
          });
        this.del_dialog = false;
        this.exam_del_id = undefined;
      }
    },
    async getExamSchedules(val = false) {
      const { valid } = !val ? await this.$refs.form.validate() : "";
      //   this.v$.$validate();
      if (valid || val) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}exam-schedule/`, {
            params: {
              // branch:this.fk_branch,
              class: this.fk_class,
              // semester:this.fk_semester
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            if (response.data.length < 1) {
              this.exam_schedules = [];
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.message")
              );
            } else {
              this.exam_schedules = response.data;
            }
          })
          .catch((error) => {
            this.exam_schedules = [];
            if (error.response.data.branch) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch")
              );
            } else if (error.response.data.semester) {
              this.$emit(
                "warningAlert",
                this.$t("global.error.failure.branch_semester")
              );
            }
          });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    // perPage(){
    //     this.getExamSchedules()
    // },
    // page(){
    //     this.getExamSchedules(this.page)
    // }
  },
};
</script>
