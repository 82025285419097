<template>
  <v-expansion-panels class="mb-2">
    <v-expansion-panel elevation="1">
      <v-expansion-panel-title class="px-4">
        <span>
          <!-- <v-icon>mdi-filter</v-icon>  -->
          {{ $t("global.view_filters") }}</span
        >
      </v-expansion-panel-title>
      <v-expansion-panel-text class="pt-4">
        <v-form ref="form" class="row">
          <v-row class="pt-3">
            <v-col cols="12" md="10">
              <v-row>
                <v-col cols="12" :md="getUserRole < 2 ? '4' : '6'">
                  <v-autocomplete
                    color="indigo"
                    v-model="fk_group"
                    density="compact"
                    item-title="name"
                    item-value="pk"
                    prepend-inner-icon="mdi-account-group"
                    clearable
                    :items="group_list"
                    :label="$t('users.user.group')"
                  ></v-autocomplete>
                  <!-- @update:modelValue="getUsers(page=1)" -->
                </v-col>
                <v-col cols="12" :md="getUserRole < 2 ? '4' : '6'">
                  <v-autocomplete
                    color="indigo"
                    v-model="fk_role"
                    density="compact"
                    item-title="name"
                    item-value="id"
                    prepend-inner-icon="mdi-shape-outline"
                    clearable
                    :items="role_list"
                    :label="$t('users.user.role')"
                  ></v-autocomplete>
                  <!-- @update:modelValue="getUsers(page=1)" -->
                </v-col>
                <v-col cols="12" md="4" v-if="getUserRole < 2">
                  <v-autocomplete
                    :items="branch"
                    v-model="fk_branch"
                    prepend-inner-icon="mdi-store"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('school.buldings_data.branch')"
                    persistent-hint
                    density="compact"
                    clearable
                  ></v-autocomplete>
                  <!-- @update:modelValue="getUsers(page=1)" -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2">
              <v-row justify="center" class="pt-1">
                <VBtn
                  class="bg-blue"
                  @click="getUsers((page = 1))"
                  size="small"
                  :loading="loading"
                >
                  <span class="text-white">{{ $t("global.filter") }}</span>
                  <VIcon icon="mdi-filter" color="white" end></VIcon>
                </VBtn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <VCard class="card-table">
    <!-- ##########    data Table Server ########### -->
    <!-- <VCard class="pa-2" > -->
    <div>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" color="#ccc">
            <v-icon style="color: #5e7e96" class="me-2"
              >mdi-eye-outline</v-icon
            >
            <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-checkbox
              v-for="header in headers"
              :key="header.key"
              :label="header.title"
              :value="header.key"
              color="indigo"
              v-model="selectedHead"
              density="compact"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-text-field
        color="indigo"
        v-model="txt_search"
        class="search"
        @input="!txt_search ? getUsers((page = 1)) : ''"
        @keyup.enter="txt_search ? getUsers((page = 1)) : ''"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :placeholder="$t('global.data_table.search_here')"
        density="compact"
        variant="text"
      >
      </v-text-field>
      <div class="d-flex justify-end" style="flex: 1">
        <VBtn
          v-if="CheckGetPermission('usermanage.add_user')"
          class="bg-success"
          @click="addUser"
        >
          <span class="text-white">{{ $t("global.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <!-- ######## End Header Of Data Table Server ####### -->
    <!-- <v-progress-linear
      color="indigo"
      class="my-1"
      :indeterminate="loading"
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear> -->
    <v-data-table-server
      :headers="selectedHeaders"
      :items="table_list"
      item-value="name"
      item-key="id"
      fixed-footer
      :items-per-page="perPage"
      density="compact"
      class="elevation-1"
      v-model:sort-by="sortBy"
      :loading="loading"
      @update:options="getUsers((page = 1))"
    >
      <template v-slot:column.checkbox="{ column }">
        <v-checkbox hide-details density="compact"> </v-checkbox>
      </template>
      <template v-slot:item="{ item }">
        <tr class="row-hover">
          <td v-for="(val, key) in item.columns" :key="key">
            <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
            </v-checkbox>
            <span v-if="key == 'groups'">
              <v-chip
                v-for="group in val"
                size="small"
                class="mx-1"
                :key="group"
                density="compact"
              >
                <span>{{ getGroupName(group) }}</span>
              </v-chip>
            </span>
            <span v-else-if="key == 'email'" dir="ltr"> {{ val }}</span>
            <span v-else> {{ val }}</span>
            <span v-if="key === 'actions'">
              <span
                v-if="CheckGetPermission('usermanage.change_user') && item.raw.role_id != getUserRole"
                class="me-1"
                style="color: #e5b254; border-radius: 4px"
              >
                <v-icon
                  @click="editUser(item.raw.id)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-pencil-outline"
                />
              </span>
              <span
                v-if="CheckGetPermission('usermanage.delete_user') && item.raw.role_id != getUserRole"
                style="color: #d05251; border-radius: 4px"
              >
                <v-icon
                  @click="(user_del_id = item.raw.id), (del_dialog = true)"
                  size="small"
                  style="cursor: pointer"
                  icon="mdi-trash-can-outline"
                />
              </span>
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:bottom>
        <pagination
          v-model:page="page"
          v-model:perPage="perPage"
          :length="totalItems"
          :newItem="[perPage]"
        ></pagination>
        <!-- <div class="d-flex">
                    <v-pagination  v-model="page" :length="length"  ></v-pagination>
                    <div class="d-flex align-center">
                        <span>{{$t('global.per-page')}} </span>
                        <v-select v-model="perPage" :items="itemsPerPage"  item-value="value" item-title="text" variant="solo"></v-select>
                    </div>
                </div> -->
        <!-- <div class="d-flex" id="pagination-bar">
          <v-pagination
            v-model="page"
            :length="length"
            density="compact"
            show-first-last-page
          >
          </v-pagination>
          <div class="d-flex">
            <v-select
              v-model="perPage"
              class="pa-0"
              :items="itemsPerPage"
              item-value="value"
              item-title="text"
              density="compact"
              hide-details
              variant="text"
            >
            </v-select>
            <span class="mt-2 px-2"
              >{{ $t("global.data_table.per_page") }} {{ $t("global.from") }} [
              {{ totalItems }} ] {{ $t("global.data_table.item") }}</span
            >
          </div>
        </div> -->
      </template>
    </v-data-table-server>
    <!-- </VCard> -->
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("global.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("global.confirm-delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (user_del_id = undefined)"
            >{{ $t("global.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteUser">{{
            $t("global.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  async created() {
    await this.axios.get(`${this.base_url}user-role/`).then((response) => {
      this.role_list = response.data.filter((item) => item.id != 0);
    });
    await this.axios
      .get(`${this.base_url}groups/`, {
        params: { system_type: 1 },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        this.group_list = response.data;
      });
    if (this.getUserRole < 2 && !this.branch.length) this.getBrenches({});
    this.getUsers();
  },
  data() {
    return {
      del_dialog: false,
      user_del_id: undefined,
      role_list: undefined,
      group_list: undefined,
      fk_group: undefined,
      fk_branch: undefined,
      fk_role: undefined,
      perPage: 10,
      length: 0,
      page: 1,
      totalItems: null,
      loading: false,
      totalItems: null,
      sortBy: [{ key: "id", order: "asc" }],
      pagination: {},
      users_list: [],
      txt_search: undefined,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
        { value: 100, text: this.$t("global.data_table.all") },
      ],
      selectedHead: [
        "first_name",
        "last_name",
        "username",
        "email",
        "role",
        "is_active",
        "actions",
      ],
      headers: [
        { title: this.$t("users.user.fname"), key: "first_name" },
        { title: this.$t("users.user.lname"), key: "last_name" },
        { title: this.$t("users.user.email"), key: "email" },
        { title: this.$t("users.user.group"), key: "groups" },
        { title: this.$t("users.user.username"), key: "username" },
        { title: this.$t("users.user.role"), key: "role" },
        { title: this.$t("users.user.active"), key: "is_active" },
        { title: this.$t("global.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      branch: (state) => state.school.branch,
    }),
    getUserRole() {
      return localStorage.getItem("role");
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
    table_list() {
      let list = [];
      if (this.role_list)
        this.users_list.forEach((element) => {
          let role = this.role_list.find((item) => item.id == element.role);
          if (role != undefined) role = role.name;
          list.push({
            date_joined: element.date_joined,
            email: element.email,
            first_name: element.first_name,
            groups: element.groups,
            id: element.id,
            is_active: element.is_active ? "مفعل" : "معطل",
            last_login: element.last_login,
            last_name: element.last_name,
            role: role,
            role_id:element.role,
            username: element.username,
          });
        });
      return list;
    },
  },
  methods: {
    ...mapActions({
      getBrenches: "school/getBrenches",
    }),
    getGroupName(id) {
      let group = this.group_list.find((item) => item.pk == id);
      if (group != undefined) return group.name;
      else return group;
    },
    editUser(id) {
      this.$router.push(`${id}/edit/`);
    },
    addUser() {
      // this.changeCurrentPage({page:this.length,count:this.perPage})
      this.$router.push("add");
    },
    async deleteUser() {
      if (this.user_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}userdata/${this.user_del_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("global.success.data_deleted"));
            this.getUsers();
          })
          .catch((error) => {
            if (error.response?.status == 418)
              this.$emit("protectedAlert", error.response?.data?.data);
            // this.$emit(
            //   "errorAlert",
            //   this.$t("global.error.failure.protected_record")
            // );
            else
              this.$emit("errorAlert", this.$t("global.error.failure.delete"));
          });
        this.del_dialog = false;
        this.user_del_id = undefined;
      }
    },
    async getUsers(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}userdata/`, {
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
          fk_group: this.fk_group,
          fk_branch: this.fk_branch,
          fk_role: this.fk_role,
          sys_type: 1,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.users_list = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.totalItems = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  watch: {
    perPage() {
      this.getUsers();
    },
    page() {
      this.getUsers(this.page);
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
