<template>
  <VAppBar density="compact" id="header">
    <template v-slot:prepend>
      <VAppBarNavIcon
        id="appIconMenu"
        @click="changeDrawer()"
        class="text-white"
      ></VAppBarNavIcon>
    </template>
    <v-breadcrumbs
      color="white"
      class="d-flex location-bread justify-end"
      :items="bread_info"
    ></v-breadcrumbs>
    <v-btn size="small" variant="text" @click="overlay = !overlay">
      <v-icon color="error">mdi-power</v-icon>
    </v-btn>
    <v-btn size="small" variant="text" @click="refreshPermissions">
      <v-icon color="error">mdi-sync</v-icon>
    </v-btn>
    <v-btn size="small" variant="text" @click="refreshPage">
      <v-icon color="error">mdi-refresh</v-icon>
    </v-btn>
    <v-overlay v-model="overlay"></v-overlay>
    <v-dialog
      v-model="exitDialog"
      width="300"
      :dir="direction"
      class="text-center"
    >
      <v-card class="ma-auto" width="300">
        <v-card-title class="bg-indigo text-white">
          <h3>
            {{ $t("global.sign_out") }}
          </h3>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-3 py-4">
          <small>{{ $t("global.exit_text") }}</small>
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="min-height: 40px; height: 40px"
          class="pa-0 ma-0 d-flex mx-3"
        >
          <v-btn
            @click="(exitDialog = false), logoutfromsys()"
            class="w-50"
            ripple
          >
            <span class="text-error">{{ $t("global.exit") }}</span>
          </v-btn>
          <v-divider vertical />
          <v-btn @click="exitDialog = false" class="w-50">
            <span class="text-indigo"> {{ $t("global.cancel") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog
      v-model="exitDialog"
      width="350"
      :dir="$i18n.locale == 'en' ? 'ltr' : 'rtl'"
    >
      <v-card>
        <v-card-title>
          <h3 class="d-inline" style="padding: 0 4px">
            {{ $t("global.sign_out") }}
          </h3>
          <v-icon icon="mdi-power" />
        </v-card-title>
        <v-card-text>
          {{ $t("global.exit_text") }}
        </v-card-text>
        <v-card-actions class="px-4">
          <v-btn
            class="bg-error"
            @click="logoutfromsys"
            size="small"
            :loading="is_loading"
            ><span class="text-white">{{ $t("global.exit") }}</span></v-btn
          >
          <v-btn @click="exitDialog = false">
            <span class="text-indigo">
              {{ $t("global.cancel") }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <VSpacer />
    <!-- <div v-if="!$vuetify.display.xs" class="d-flex justify-center mx-4" style="flex: 3">
      <div class="w-75 d-flex text-white border rounded-pill bg-search">
        <input
          type="text"
          class="flex-fill text-grey-lighten-1 mx-2"
          id="search"
          :placeholder="$t('global.data_table.search_here')"
        />
        <v-icon class="mt-1 mx-1">mdi-magnify</v-icon>
      </div>
    </div> -->
    <VSpacer />
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" icon>
          <!-- <v-img v-if="user_profile.image_user">
            <v-avatar
              size="20"
              :image="base_url + user_profile.image_user"
            ></v-avatar>
          </v-img> -->
          <v-icon size="large">mdi-account</v-icon>
        </v-btn>
      </template>
      <v-card :dir="'rtl'" rounded="lg">
        <v-layout>
          <v-list density="compact">
            <v-list-item
              density="compact"
              :title="user_profile?.username"
              :subtitle="user_profile?.email"
              :prepend-icon="'mdi-account'"
            >
            </v-list-item>
            <v-divider style="border-width: 1px; opacity: 0.5" />
            <v-list-item density="compact" @click="exitDialog = true">
              <template v-slot:prepend>
                <v-icon color="error">mdi-logout</v-icon>
              </template>
              <template v-slot:title>
                <!-- class="text-error" -->
                <div>{{ $t("global.sign_out") }}</div>
              </template>
            </v-list-item>
          </v-list>
        </v-layout>
      </v-card>
    </v-menu>

    <!-- <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" icon size="small">
          <v-icon style="color: #5e7e96">mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list class="pa-0">
          <v-list-item
            v-if="username"
            class="py-0 text-center"
            density="compact"
          >
            {{ username }}
          </v-list-item>
          <v-divider thickness="2" />
          <v-list-item
            @click="exitDialog = true"
            class="py-0"
            density="compact"
          >
            <v-icon color="error">mdi-power</v-icon>
            {{ $t("global.sign_out") }}
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu> -->
    <!-- <span class="text-grey-lighten-1 mt-1" style="font-size:10px">{{$i18n.locale=='ar'?'العربية':'English'}}</span> -->
    <v-menu>
      <template v-slot:activator="{ props }">
        <!-- <span class="text-grey-lighten-1 mt-1" style="font-size:10px">{{$i18n.locale=='ar'?'العربية':'English'}}</span> -->
        <v-btn
          v-bind="props"
          variant="text"
          append-icon="mdi-translate"
          size="small"
        >
          <span class="text-grey-lighten-1 mt-1" style="font-size: 10px">{{
            $i18n.locale == "ar" ? "العربية" : "English"
          }}</span>
          <!-- <v-icon>mdi-translate</v-icon> -->
        </v-btn>
      </template>
      <v-card width="100" rounded="lg" class="mx-2 px-2" elevation="2">
        <v-list v-model="language" density="compact">
          <v-list-item
            min-height="30px"
            density="compact"
            v-for="(item, i) in languages"
            :key="i"
            :title="languagename[item]"
            @click="changeLocale(item)"
          ></v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </VAppBar>
</template>
<script>
import i18n from "@/i18n";
import { mapActions, mapGetters, mapState } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      drawer: true,
      create_backup: false,
      exitDialog: false,
      user_profile: { email: localStorage?.getItem("email") },
      // username: undefined,
      overlay: false,
      language: null,
      languages: i18n.global.availableLocales,
      languagename: {
        ar: "العربية",
        en: "English",
      },
      is_loading: false,
      bread_info: [
        localStorage.getItem("branch_ar") == "undefined"
          ? ""
          : localStorage.getItem("branch_ar"),
      ],
    };
  },
  methods: {
    changeDrawer() {
      this.$store.commit("updateDrawer", !this.getdrawer);
    },
    CreateBackUp() {
      //   this.loading = true;
      this.axios(
        `${this.base_url}api/export-database?file_name=null$extension=null`
      )
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    async changeLocale(currentLocal) {
      localStorage.setItem("user-locale", currentLocal);
      const dir = localStorage.getItem("user-locale") === "en" ? "ltr" : "rtl";
      // if (i18n.global.locale != currentLocal) {
      //   await window.location.reload();
      // }
      i18n.global.locale = currentLocal;
      this.$store.dispatch(
        "updatelanguage",
        localStorage.getItem("user-locale")
      );
      this.$store.dispatch("updatedirection", dir);
    },
    async logoutfromsys() {
      // if (this.create_backup) {
      //     this.CreateBackUp();
      // }
      this.is_loading = true;
      await this.axios(`${this.base_url}api/delete/session/`, {
        params: {
          user: this.user_profile.username,
        },
      }).then((res) => {
        // console.log(res);
        this.logout;
        window.location.reload();
      });
      this.is_loading = false;
    },
    async refreshPermissions() {
      await this.axios(`${this.base_url}api/user/get-permission/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          localStorage.setItem("userinfo", response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refreshPage() {
      window.location.reload();
    },
  },

  created() {
    this.$store.dispatch("updatelanguage", localStorage.getItem("user-locale"));
    this.user_profile.username = localStorage.getItem("username");
  },
  provide() {
    return {};
  },
  computed: {
    ...mapGetters(["getlang", "getuser", "getdrawer"]),
    ...mapActions(["logout"]),
    // ...mapState({
    //     // username: (state) => state.username,
    //     // }),
  },
};
</script>

<style scoped>
#search {
  outline: unset;
  color: white;
}
</style>
